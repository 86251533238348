import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

function themed(WrappedComponent) {
  const { name, displayName } = WrappedComponent;
  function ThemedComponent(props) {
    return (
      <ThemeProvider theme={theme}>
        <WrappedComponent {...props} />
      </ThemeProvider>
    );
  }

  ThemedComponent.displayName = `Themed(${displayName || name})`;

  return ThemedComponent;
}

export default themed;
