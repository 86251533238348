import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Validate } from 'reactiverecord';
import SyntheticInput from './SyntheticInput';
import { PreventableEvent, without } from 'utilities';

const StateRadioGroup = forwardRef(
  ({ children, onChange, ...props }, forwardedRef) => (
    <Validate {...without.call(props, 'onBlur')} ref={forwardedRef}>
      {(validateProps, errorText, disabled) => (
        <SyntheticInput disabled={disabled} {...validateProps}>
          {(value, setValue) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const handleChange = useCallback(
              originalOnChange => event => {
                if (event.target.value !== value) {
                  setValue(event.target.value);
                  setTimeout(() => {
                    if (originalOnChange) {
                      originalOnChange(
                        new PreventableEvent(event.target.value),
                      );
                    }
                  }, 0);
                }
              },
              [setValue, value],
            );
            function options(optionValue) {
              return {
                checked: optionValue === value,
                uncheckedValue: optionValue,
                value: optionValue,
                disabled,
                onChange: handleChange(onChange),
                isStateManaged: true,
              };
            }
            return children(options, errorText);
          }}
        </SyntheticInput>
      )}
    </Validate>
  ),
);

StateRadioGroup.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  validators: PropTypes.object,
};
StateRadioGroup.nameCounter = 0;
StateRadioGroup.displayName = 'StateRadioGroup';

export default StateRadioGroup;
