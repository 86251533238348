import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Accordion from 'components/ui/Accordion';
import Checkbox from 'components/ui/inputs/Checkbox';
import RadioGroup from 'components/ui/inputs/RadioGroup';
import Radio from 'components/ui/inputs/Radio';
import { SalaryField, HourlyField } from 'components/shared/CompensationFields';
import { useFilterContext } from './FilterContext';
import { without } from 'utilities';

export const SalaryFieldsContainer = styled.div(
  ({ theme }) => `
  .salary-alignments {
    display: block !important;
  }
  label {
    width: auto;
    .label-text {
      position: relative;
      display: block;
      left: 0;
      right: unset;
      width: auto;
      color: ${theme.mediumGray};
      font-size: 0.875rem;
    }
    input {
      padding-right: 15px;
    }
  }
  .calculated-salary,
  .calculated-hourly {
    margin-left: 0 !important;
  }
`,
);

const PayRate = ({ onChange }) => {
  const { removeFilter, filters } = useFilterContext();
  const hasFilter = !!(
    filters.minimum_salary_cents ||
    filters.salary_cents ||
    filters.minimum_hourly_rate_cents ||
    filters.hourly_rate_cents ||
    filters.competitive
  );
  const salary_cents = filters.salary_cents;
  const salaryValue = salary_cents ? salary_cents / 100 : '';
  const hourly_cents = filters.hourly_rate_cents;
  const hourlyValue = hourly_cents ? hourly_cents / 100 : '';
  const display = {
    hourly: {
      value: hourlyValue.toString(),
      labelText: 'Rate',
      rateType: '/hour',
    },
    salary: {
      value: salaryValue.toString(),
      labelText: 'Rate',
      rateType: '/year',
    },
  };

  const [rate, setRate] = useState('hourly');

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
      onChange(e);
    }
  }

  function handleBlur(e) {
    onChange(e);
  }

  const handleRadioChange = type => {
    setRate(type);
    const filterToBeRemoved = filters.hasOwnProperty('salary_cents')
      ? 'salary_cents'
      : 'hourly_rate_cents';
    removeFilter(filterToBeRemoved);
  };

  return (
    <Accordion title="Preferred pay rate" defaultShow={hasFilter} dense>
      <SalaryFieldsContainer id="salaryFieldsContainer">
        <>
          <RadioGroup>
            {options => (
              <>
                <Radio
                  {...without.call(options(rate), 'defaultChecked')}
                  name="hourly"
                  value="hourly"
                  labelText="Hourly pay"
                  checked={rate === 'hourly'}
                  onChange={() => handleRadioChange('hourly')}
                />
                <Radio
                  {...without.call(options(rate), 'defaultChecked')}
                  name="salary"
                  value="salary"
                  labelText="Annual salary"
                  checked={rate === 'salary'}
                  onChange={() => handleRadioChange('salary')}
                />
              </>
            )}
          </RadioGroup>
          {rate === 'salary' ? (
            <SalaryField
              inputProps={display.salary}
              white="true"
              onKeyPress={handleKeyPress}
              onBlur={handleBlur}
            />
          ) : (
            <HourlyField
              inputProps={display.hourly}
              white="true"
              onKeyPress={handleKeyPress}
              onBlur={handleBlur}
            />
          )}
        </>
      </SalaryFieldsContainer>
      <Checkbox
        labelText="Include pay that's listed as competitive"
        onChange={onChange}
        checked={!!filters.competitive}
        name="competitive"
        id="competitive"
        labelTestId="competitive"
        value="on"
      />
    </Accordion>
  );
};

PayRate.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PayRate;
