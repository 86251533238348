/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { checkNylasToken } from 'components/containers/employer/projects/bids/requests';
import stored from 'components/hoc/stored';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import ConfirmReschedule from 'components/employer/interviews/RescheduleNylasInterview/ConfirmReschedule';
import NylasReauthentication from 'components/employer/interviews/NylasReauthentication';
import InterviewModalHeader from 'components/employer/interviews/InterviewModalHeader';

const ContentWrapper = styled.div`
  max-width: 500px;
`;

const RescheduleNylasInterview = ({
  handleOnClose,
  handleOnSuccess,
  mom,
  interview,
  secondaryButtonText,
}) => {
  const [loading, setLoading] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);

  const fetchTokenStatus = async () => {
    const token_response = await checkNylasToken();
    if (!token_response.token_valid) {
      setTokenValid(false);
    } else {
      setTokenValid(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTokenStatus();
  }, []);

  return (
    <div className="d-flex flex-column">
      {loading ? (
        <ContentWrapper className="d-flex align-items-center flex-column">
          <LoadingSpinner color={({ theme }) => theme.primaryBlue} size={50} />
        </ContentWrapper>
      ) : (
        <>
          <InterviewModalHeader
            centeredAndSpaced={tokenValid}
            mom={mom}
            heading={
              tokenValid
                ? 'Need to reschedule this interview?'
                : 'Reschedule an interview'
            }
          />
          {tokenValid ? (
            <ConfirmReschedule
              mom={mom}
              interview={interview}
              handleOnSuccess={handleOnSuccess}
              handleOnClose={handleOnClose}
              secondaryButtonText={secondaryButtonText}
            />
          ) : (
            <NylasReauthentication handleOnClose={handleOnClose} />
          )}
        </>
      )}
    </div>
  );
};

RescheduleNylasInterview.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleOnSuccess: PropTypes.func.isRequired,
  interview: PropTypes.object,
  mom: PropTypes.object.isRequired,
  secondaryButtonText: PropTypes.string,
};

export default stored(RescheduleNylasInterview);
