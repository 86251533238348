import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { Form } from 'reactiverecord';
import { AddSkillSearch, AddSkillTitle } from './styles';
import { colors } from 'styleGuide';

import ExpertisesFields from 'resources/expertises/ExpertisesFields';
import PlusCircleO from 'components/ui/icons/PlusCircleO';
import Button from 'components/ui/buttons/Button';

const handleBeforeSave = fields =>
  new Promise(resolve => {
    if (fields.expertises_attributes) {
      fields.expertises_attributes = fields.expertises_attributes.filter(
        expertise => {
          if (!expertise.emsi_skill_id && !expertise._destroy) {
            return false;
          }
          return true;
        },
      );
    }
    resolve(fields);
  });

const AddSkills = ({ currentMom }) => {
  const [counter, setCounter] = useState(0);
  const [clicked, setClicked] = useState(false);
  const handleAfterSave = () => setCounter(counter + 1);
  return (
    <>
      {!clicked ? (
        <div className="text-center">
          <PlusCircleO width="20" color={colors.gray.dark} />
          <AddSkillTitle
            onClick={() => setClicked(true)}
            className="btn btn-link"
          >
            Add more skills
          </AddSkillTitle>
        </div>
      ) : (
        <>
          <Row>
            <AddSkillSearch>
              <p className="text-alt">TYPE TO SEARCH FOR ADDITIONAL SKILLS</p>
            </AddSkillSearch>
          </Row>
          <Form
            for={currentMom}
            beforeSave={handleBeforeSave}
            afterSave={handleAfterSave}
          >
            {fields => {
              return (
                <ExpertisesFields
                  key={counter}
                  expertises={[]}
                  expertises_attributes={currentMom.expertises_attributes}
                  emsi_skill_ids={currentMom.emsi_skill_ids}
                  fields={fields}
                  fieldsFor={fields.fieldsFor}
                  suggestedExpertises={currentMom.expertises_with_unconfirmed}
                  type="talent"
                  button={
                    <Button
                      {...fields.submit}
                      secondary
                      noBorder
                      iconOnly
                      inline
                      size="xs"
                      type="submit"
                      className="ml-2 font-weight-bold rounded-0"
                      data-id="add-skills-onboarding"
                    >
                      Add
                    </Button>
                  }
                />
              );
            }}
          </Form>
        </>
      )}
    </>
  );
};

AddSkills.propTypes = {
  currentMom: PropTypes.object,
};

export default AddSkills;
