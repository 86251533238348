import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import Input from 'components/ui/inputs/Input';
import { Row, Col } from 'components/ui/grid';
import X from 'components/ui/icons/X';
import {
  LabelText,
  ExpertiseFormContainer,
  StyledFormSection,
  StyledPlusCircleButton,
  RemoveLabelHandle,
  Label,
  ListContainer,
  List,
} from './styles';
import ExpertiseListItem from './ExpertiseListItem';
import ReactiveRecord from 'reactiverecord';
import { without, debounce } from 'utilities';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import { getEmsiSkills, getSuggestedEmsiSkills } from 'requests';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { EXPERTISE_MAX_COUNT, EXPERTISE_MIN_CHAR_LENGTH } from 'consts';
import useRoveFocus from './supporting';

const ExpertisesFields = forwardRef(
  (
    {
      expertises,
      suggestedExpertises,
      emsi_skill_ids,
      fieldsFor,
      labelText,
      type,
      extractedExpertises,
      loading,
      button,
    },
    ref,
  ) => {
    const ExpertiseModel =
      type === 'talent'
        ? ReactiveRecord.model('MomExpertise')
        : ReactiveRecord.model('ProjectExpertise');
    let InputRef = useRef();

    // Initialized with a blank expertise to generate the blank form inputs for new Expertise
    const [stateExpertises, setStateExpertises] = useState({
      [Math.random()]: new ExpertiseModel(),
    });
    const [stateDeleteExpertises, setStateDeleteExpertises] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [errorText, setErrorText] = useState('');
    const [renderedList, setRenderedList] = useState([]);
    const [activeList, setActiveList] = useState(false);
    const [focusOnList, setFocusOnList] = useState(false);
    const listRef = useRef(null);
    const [listFocus, setListFocus] = useRoveFocus(
      renderedList.length,
      listRef,
    );
    const timerRef = useRef(null);
    // Non-persisted Expertises list is cleared when persisted expertises are updated.
    useDeepCompareEffect(
      () => setStateExpertises({ [Math.random()]: new ExpertiseModel() }),
      [ExpertiseModel, expertises],
    );

    /* Expertise are stored in a variety of places depending on their status
     *** (pulled from db, on local state, deleted on local state)
     *** this calculates the total count of ALL those expertise to get the real
     *** count of what the user will have when attempting to save
     */
    const finalExpertiseCount =
      expertises.filter(expertise => !expertise._destroy).length +
      Object.keys(stateExpertises).length -
      1 -
      Object.keys(stateDeleteExpertises).length;

    /* Specifically for the Job Creation flow. Expertises are extracted in JobDetails.js
     *** and passed down here. They need to be merged with StateExpertises to
     *** render in this component since they haven't been saved to a Project yet
     */
    useDeepCompareEffect(() => {
      if (extractedExpertises && Object.keys(extractedExpertises).length >= 1) {
        setStateExpertises(state => ({
          ...state,
          ...extractedExpertises,
          [Math.random()]: new ExpertiseModel(),
        }));
      }
    }, [ExpertiseModel, extractedExpertises]);

    useEffect(() => {
      if (searchTerm.length >= EXPERTISE_MIN_CHAR_LENGTH) {
        fetchSkills(searchTerm);
      } else {
        hideExpertisesList();
      }
    }, [fetchSkills, hideExpertisesList, searchTerm]);

    /*
     * When user input changes, fetch EMSI skills to populate suggestions.
     * Debounce call so an API request isn't made on every keystroke.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchSkills = useCallback(
      debounce.call(searchTerm => {
        getEmsiSkills(searchTerm)
          .then(expertises => {
            if (expertises.length >= 1) {
              setActiveList(true);
              setRenderedList(expertises);
              setErrorText('');
            } else {
              setErrorText(
                'We were unable to find any skills. Try a different phrase or word.',
              );
              setActiveList(false);
            }
          })
          .catch(error => {
            console.log('error', error);
            setErrorText(
              'We were unable to find any skills. Try a different phrase or word.',
            );
          });
      }, 200),
      [],
    );

    /*
     * Suggested skills are created with confirmed and unconfirmed skills from a resume
     * and then using the Emsi API, suggested skills are found related to them
     */
    const fetchSuggestedSkills = useCallback(suggestedExpertises => {
      const initialIds = suggestedExpertises.map(
        expertise => expertise.emsi_skill_id,
      );
      if (initialIds.length > 0) {
        getSuggestedEmsiSkills(initialIds)
          .then(expertises => {
            if (expertises.length >= 1) {
              setActiveList(true);
              setRenderedList(expertises);
              setErrorText('');
            } else {
              setErrorText('We were unable to find any suggested skills.');
              setActiveList(false);
            }
          })
          .catch(error => {
            console.log('error', error);
            setErrorText('We were unable to find any suggested skills.');
          });
      }
    }, []);

    const onChange = e => {
      e.preventDefault();
      setSearchTerm(e.target.value);
    };

    const onInputKeyDown = e => {
      if (activeList && e.key === 'ArrowDown') {
        setFocusOnList(true);
      }
    };

    const validation = useCallback(
      currentExpertiseId => {
        const stateExpertiseIdList = Object.values(stateExpertises).map(
          ({ emsi_skill_id }) => emsi_skill_id,
        );

        if (
          [...stateExpertiseIdList, ...emsi_skill_ids].includes(
            currentExpertiseId,
          )
        ) {
          setErrorText(
            'This skill is already on your list! Please enter another.',
          );
          return false;
        }
        return true;
      },
      [stateExpertises, emsi_skill_ids],
    );

    const hideExpertisesList = useCallback(() => {
      setActiveList(false);
      setFocusOnList(false);
      setRenderedList([]);
      setErrorText('');
    }, []);

    const handleOnClick = () => {
      if (suggestedExpertises && renderedList.length === 0) {
        fetchSuggestedSkills(suggestedExpertises);
      } else {
        setActiveList(true);
      }
    };

    useEffect(() => {
      // cleaning up the list timeout here
      return () => clearTimeout(timerRef.current);
    });

    const handleBlur = e => {
      const currentTarget = e.currentTarget;

      clearTimeout(timerRef.current);
      // Check the newly focused element in the next tick of the event loop
      timerRef.current = setTimeout(() => {
        // Check if the new activeElement is a child of the original container
        if (!currentTarget.contains(document.activeElement)) {
          setActiveList(false);
          setFocusOnList(false);
        }
      }, 0);
    };

    const handleListKeyDown = e => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        hideExpertisesList();
      }
    };

    const addStateExpertise = useCallback(
      (listItem, expertise) => () => {
        const isValid = validation(listItem.id);

        if (isValid) {
          expertise.label = listItem.formatted;
          expertise.emsi_skill_id = listItem.id;
          //Update state add a new blank expertise to keep the input displayed
          setStateExpertises({
            ...stateExpertises,
            [Math.random()]: new ExpertiseModel(),
          });
          hideExpertisesList();
        } else {
          setActiveList(false);
          setFocusOnList(false);
          setRenderedList([]);
        }
      },
      [validation, stateExpertises, ExpertiseModel, hideExpertisesList],
    );

    const removeExpertiseFromList = useCallback(
      key => () => setStateExpertises(without.call(stateExpertises, key)),
      [stateExpertises],
    );

    const markExpertiseForDeletion = useCallback(
      id => () => {
        setStateDeleteExpertises({ ...stateDeleteExpertises, [id]: true });
      },
      [stateDeleteExpertises],
    );

    return (
      <div ref={ref}>
        {labelText ? <LabelText className="mb-1">{labelText}</LabelText> : null}
        <ExpertiseFormContainer
          disabled={finalExpertiseCount >= EXPERTISE_MAX_COUNT || loading}
          onBlur={handleBlur}
        >
          {expertises.length > 0
            ? expertises.map(expertise => {
                if (stateDeleteExpertises[expertise.id]) {
                  return fieldsFor(
                    'expertises',
                    expertise.id,
                    new ExpertiseModel(expertise),
                  )(expertiseFields => (
                    <Input
                      key={expertise.id}
                      type="hidden"
                      value="true"
                      ref={expertiseFields._destroy.ref}
                    />
                  ));
                }
                return (
                  <Label className="mt-3" key={expertise.id} type={type}>
                    <span>{expertise.label}</span>
                    <RemoveLabelHandle
                      onClick={markExpertiseForDeletion(expertise.id)}
                    >
                      <X />
                    </RemoveLabelHandle>
                  </Label>
                );
              })
            : null}

          {Object.keys(stateExpertises).map((expertiseKey, index) =>
            fieldsFor(
              'expertises',
              expertiseKey,
              stateExpertises[expertiseKey],
            )(expertiseFields => {
              const expertise = stateExpertises[expertiseKey];
              if (index === Object.keys(stateExpertises).length - 1) {
                return (
                  <StyledFormSection key={expertiseKey} type={type}>
                    <Row>
                      <>
                        <Col lg={11} className="col-10 input-container">
                          <Input
                            data-testid="expertises"
                            placeholder={expertiseFields.label.labelText}
                            {...without.call(
                              expertiseFields.label,
                              'labelText',
                              'validators',
                            )}
                            onChange={onChange}
                            onClick={handleOnClick}
                            onKeyDown={onInputKeyDown}
                            ref={ref => {
                              InputRef.current = ref;
                              expertiseFields.label.ref(ref);
                            }}
                            disabled={
                              finalExpertiseCount >= EXPERTISE_MAX_COUNT ||
                              loading
                            }
                          />
                        </Col>
                        <Col
                          lg={1}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <StyledPlusCircleButton
                            disabled={
                              finalExpertiseCount >= EXPERTISE_MAX_COUNT ||
                              loading
                            }
                            data-id="btn-add-expertise"
                          >
                            <span className="sr-only" />
                          </StyledPlusCircleButton>
                          {button}
                        </Col>

                        <ListContainer lg={11} className="col-10">
                          <List
                            hidden={!activeList}
                            ref={listRef}
                            onKeyDown={handleListKeyDown}
                            data-testid="expertises-list"
                          >
                            {renderedList.map((listItem, index) => {
                              return (
                                <ExpertiseListItem
                                  addStateExpertise={addStateExpertise(
                                    listItem,
                                    expertise,
                                  )}
                                  expertise={expertise}
                                  focus={listFocus === index}
                                  key={listItem.id}
                                  setFocus={setListFocus}
                                  shouldFocus={focusOnList}
                                  skill={listItem}
                                />
                              );
                            })}
                          </List>
                        </ListContainer>
                      </>
                      {errorText ? (
                        <ErrorMessage>{errorText}</ErrorMessage>
                      ) : null}
                    </Row>
                  </StyledFormSection>
                );
              }
              if (expertise.label) {
                return (
                  <Label className="mt-3" key={expertiseKey} type={type}>
                    <span>{expertise.label}</span>
                    <input
                      type="hidden"
                      value={expertise.label}
                      ref={expertiseFields.label.ref}
                    />
                    <RemoveLabelHandle
                      onClick={removeExpertiseFromList(expertiseKey)}
                    >
                      <X />
                    </RemoveLabelHandle>
                  </Label>
                );
              }
            }),
          )}
        </ExpertiseFormContainer>
      </div>
    );
  },
);

ExpertisesFields.displayName = 'ExpertisesFields';

ExpertisesFields.propTypes = {
  expertises: PropTypes.array,
  expertises_attributes: PropTypes.array,
  extractedExpertises: PropTypes.object,
  fieldsFor: PropTypes.func,
  fields: PropTypes.object,
  emsi_skill_ids: PropTypes.array,
  labelText: PropTypes.string,
  loading: PropTypes.bool,
  button: PropTypes.object,
  suggestedExpertises: PropTypes.array,
  type: PropTypes.string,
};

export default ExpertisesFields;
