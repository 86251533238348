import React from 'react';
import VideoError from './VideoError';

const VideoNotFoundError = () => {
  return (
    <VideoError>
      <>
        <h4>It looks like we can&apos;t detect your audio or video devices.</h4>
        <p>
          Make sure you have a video camera connected to your computer or mobile
          device.
        </p>
      </>
    </VideoError>
  );
};

export default VideoNotFoundError;
