import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styleGuide';
import { Col, Row } from 'react-bootstrap';
import AddSkills from './AddSkills';
import { Box } from 'components/ui/Box';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import CheckboxGroup from 'components/ui/inputs/CheckboxGroup';
import CheckboxButton from 'components/ui/inputs/CheckboxButton';

const FoundSkills = ({ currentMom, backgroundColor, className, ...props }) => {
  const mapIds = skills => skills.map(skill => skill.id);
  const [stateAllExpertises, setStateAllExpertises] = useState([]);
  const [stateExpertisesIds, setStateExpertisesIds] = useState([]);
  const [stateAllExpertisesIds, setStateAllExpertisesIds] = useState([]);

  useEffect(() => {
    setStateAllExpertises(currentMom.expertises_with_unconfirmed);
    setStateExpertisesIds(mapIds(currentMom.expertises));
    setStateAllExpertisesIds(mapIds(currentMom.expertises_with_unconfirmed));
  }, [currentMom.expertises, currentMom.expertises_with_unconfirmed]);

  const saveSkill = e => {
    const user_confirmed = !e.target.checked;
    const emsi_skill_id = e.target.getAttribute('data-id');
    currentMom.updateAttributes({
      expertises_attributes: [
        {
          emsi_skill_id,
          user_confirmed,
        },
      ],
    });
  };

  return stateAllExpertisesIds.length ? (
    <Box backgroundColor={backgroundColor} className={className}>
      <div>
        <b>We found these skills on your resume.</b>
        <p>
          Increase the accuracy of your job search matches by adding your skills
        </p>
        <p>Which skills would you like to add to you profile?</p>
      </div>
      <CheckboxGroup
        defaultValue={stateExpertisesIds}
        {...stateAllExpertisesIds}
        onChange={e => {
          props.onChange('confirmed_skills', e.target.value);
        }}
      >
        {(register, errorText) => (
          <Row>
            {stateAllExpertises.map(skill => (
              <Col key={skill.id} className="mb-4" md="4" sm="6" xs="12">
                <CheckboxButton
                  key={skill.id}
                  labelText={skill.label}
                  data-id={skill.emsi_skill_id}
                  defaultChecked={skill.user_confirmed}
                  onClick={saveSkill}
                />
              </Col>
            ))}
            {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
          </Row>
        )}
      </CheckboxGroup>
      <hr />
      <AddSkills currentMom={currentMom} />
    </Box>
  ) : null;
};

FoundSkills.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  onChange: PropTypes.func,
  currentMom: PropTypes.object.isRequired,
};

FoundSkills.defaultProps = {
  backgroundColor: colors.blue.light,
};

export default FoundSkills;
