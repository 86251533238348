import React from 'react';
import PropTypes from 'prop-types';
import { projectApplicants } from 'components/employer/projects/ProjectSummary/supporting';
import {
  ApplicantCount,
  LowAppCount,
} from 'components/employer/projects/ProjectSummary/ProjectSummaryStyles';
import Profile from 'components/ui/icons/Profile';
import { IconWrapper } from './styles';
import themed from 'components/hoc/themed';

const ProjectApplicants = ({ talentDisplay, applicantCount }) => (
  <div className="applicant-count d-flex">
    <IconWrapper>
      <Profile width={22} className="align-top" />
    </IconWrapper>
    <div className="font-sm">
      <ApplicantCount className="d-inline-block">
        {projectApplicants(applicantCount)}
      </ApplicantCount>
      &ensp;
      {applicantCount <= 20 && talentDisplay && (
        <LowAppCount>Be one of the first to apply!</LowAppCount>
      )}
    </div>
  </div>
);

ProjectApplicants.propTypes = {
  talentDisplay: PropTypes.bool,
  applicantCount: PropTypes.number,
};

export default themed(ProjectApplicants);
