import React from 'react';

const ResumeRevBlueCurve = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="992"
      height="199"
      viewBox="0 0 992 199"
    >
      <defs>
        <path id="prefix__a" d="M0 0L1013 0 1013 199 0 199z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 1012 0)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          fill="#F3F7FF"
          d="M1013 199c-74.7-33.288-164.342-60.523-334.17-55.277-244.408 20.026-338.415-17.418-402.4-66.333C209.326-.06 103.625 2.107 0 0v199h1013z"
          mask="url(#prefix__b)"
        />
      </g>
    </svg>
  );
};

export default ResumeRevBlueCurve;
