import styled from 'styled-components';
import { colors, fonts } from 'styleGuide';

export const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
  font-family: ${fonts.primary};
  outline: 0;
  .infoText {
    padding-left: 35px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.mediumGray};
  }
  label {
    position: relative;
    padding-left: 35px;
    display: inline-block;
    &:before,
    &:after {
      position: absolute;
      border-radius: 50%;
    }
    &:before {
      content: '';
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      border: 1px solid ${({ theme }) => theme.darkGray};
      background: ${colors.white};
    }
    &:after {
      top: 5px;
      left: 5px;
      width: 15px;
      height: 15px;
      background-color: ${colors.blue.primary};
    }
  }
  input[type='radio']:checked + label:after {
    content: '';
  }
  input[type='radio']:focus + label:before {
    outline: rgb(59, 153, 252) auto 5px;
  }
  input[type='radio']:disabled + label {
    color: ${({ theme }) => theme.mediumGray};
  }
  input[type='radio']:disabled + label:after {
    background-color: ${({ theme }) => theme.lightGray};
  }
  input[type='radio']:disabled + label:before {
    border: 1px solid ${({ theme }) => theme.lightGray};
  }
`;

export const InputRadio = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
`;
InputRadio.defaultProps = { type: 'radio' };
