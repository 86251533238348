import React from 'react';
import PropTypes from 'prop-types';
import { GreetingRow, StyledAvatarContainer } from './styles';
import { TalentAvatar } from 'components/shared/TalentAvatar';

const PreviewParticipants = ({ roomParticipants }) => {
  if (!roomParticipants.length) {
    return null;
  }

  const participant = roomParticipants[0];

  return (
    <GreetingRow>
      <StyledAvatarContainer>
        <TalentAvatar
          photoUrl={participant.avatar}
          talentName={participant.name}
          size={'xxxs'}
        />
      </StyledAvatarContainer>
      <div>{`${participant.firstNameAndLastInitial} is in the room.`}</div>
    </GreetingRow>
  );
};

PreviewParticipants.propTypes = {
  roomParticipants: PropTypes.array,
};

export default PreviewParticipants;
