import React from 'react';
import PropTypes from 'prop-types';
import stored from 'components/hoc/stored';

import styled from 'styled-components';
import withTooltip from 'components/ui/withTooltip';
import Button from 'components/ui/buttons/Button';
import Trashcan from 'components/ui/icons/Trashcan';
import { hasActiveInterviews } from './supporting';

const TooltipContent = styled.div`
  min-width: 250px;
`;

const DeleteSchedulingPageButton = ({ handleClick, schedulingPage }) => {
  const Tooltip = withTooltip('div');

  const deleteButton = (
    <Button
      id="delete-scheduling-page-button"
      data-testid="delete-scheduling-page-button"
      onClick={handleClick}
      disabled={hasActiveInterviews(schedulingPage)}
      link
      inline
    >
      <Trashcan width="18px" height="18px" />
      <span className="ml-1">Delete</span>
    </Button>
  );
  return (
    <>
      {hasActiveInterviews(schedulingPage) ? (
        <Tooltip
          title={
            <TooltipContent>
              This is in use for interviews and cannot be deleted right now.
            </TooltipContent>
          }
        >
          {deleteButton}
        </Tooltip>
      ) : (
        deleteButton
      )}
    </>
  );
};

DeleteSchedulingPageButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  schedulingPage: PropTypes.object.isRequired,
};

export default stored(DeleteSchedulingPageButton);
