import React from 'react';
import PropTypes from 'prop-types';

/**
 * Sizes text based on the view height, within a given min/max size
 * @param {object} props Rest of props are given to component
 * @param {number} props.vh VH-based size of the text
 * @param {number} props.min Minimum pixel size of text
 * @param {number} props.max Maximum pixel size of text
 */
const ViewHeightText = ({ vh, min, max, ...props }) => (
  <span
    {...props}
    css={`
      @media (max-height: ${parseInt((100 / vh) * min - 1)}px) {
        font-size: ${min}px;
      }
      @media (min-height: ${parseInt(
          (100 / vh) * min,
        )}px) and (max-height: ${parseInt((100 / vh) * max - 1)}px) {
        font-size: ${vh}vh;
      }
      @media (min-height: ${parseInt((100 / vh) * max)}px) {
        font-size: ${max}px;
      }
    `}
  />
);

ViewHeightText.defaultProps = {
  vh: 2.5,
  min: 18,
  max: 36,
};

ViewHeightText.propTypes = {
  /** VH-based size of the text */
  vh: PropTypes.number,
  /** Minimum pixel size of text */
  min: PropTypes.number,
  /** Maximum pixel size of text */
  max: PropTypes.number,
};

export default ViewHeightText;
