import React from 'react';
import Icon from 'components/ui/Icon';
import publicTheme from 'publicTheme';

export default function MicOff(props) {
  return (
    <Icon viewBox="0 0 46 46" {...props}>
      <defs>
        <filter id="2i9hrtpwxa">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-398 -181) translate(398 181)">
            <circle cx="23" cy="23" r="23" fill={publicTheme.red.toString()} />
            <g filter="url(#2i9hrtpwxa)">
              <g fill={publicTheme.red.toString()} fillRule="nonzero">
                <g>
                  <path
                    d="M.035 6.247c0 2.937 2.345 5.679 5.235 6.097l.05-.347-.336-.102-.011.048c-.056.365.128.426.686.487.303.033.76.058 1.397.072.828-.017 1.642-.213 2.424-.529.285-.115.546-.239.778-.363.141-.075.243-.135.3-.171l-.379-.592c-.042.027-.128.078-.253.144-.21.113-.45.226-.71.332-.708.286-1.441.463-2.159.478-.604-.014-1.042-.038-1.322-.069-.143-.015-.227-.03-.257-.04.111.037.065-.002.19.358-.006.03.108-.342.108-.342l-.405-.059C2.828 11.281.738 8.837.738 6.247V0H.035v6.247z"
                    transform="translate(15.682 18.818)"
                  />
                  <path
                    d="M3.035 6.247c0 2.937 2.345 5.679 5.235 6.097l.05-.347-.336-.102-.011.048c-.056.365.128.426.686.487.303.033.76.058 1.397.072.828-.017 1.642-.213 2.424-.529.285-.115.546-.239.778-.363.141-.075.243-.135.3-.171l-.379-.592c-.042.027-.128.078-.253.144-.21.113-.45.226-.71.332-.708.286-1.441.463-2.159.478-.604-.014-1.042-.038-1.322-.069-.143-.015-.227-.03-.257-.04.111.037.065-.002.19.358-.006.03.108-.342.108-.342l-.405-.059c-2.543-.368-4.633-2.812-4.633-5.402V0h-.703v6.247z"
                    transform="translate(15.682 18.818) matrix(-1 0 0 1 16.593 0)"
                  />
                </g>
                <path
                  d="M2.671 16.295h-.175l.175.176v-.351l-.175.175H6.713v-3.661l-.176.175h.352l-.176-.175v3.661h4.569l-.176-.175v.35l.176-.175H6.713V16.494h.176v-.175h-.352v.175h.176V16.295H2.67zm3.866.176h-.176V16.67H7.064V16.47H6.89v.175h4.568v-.702H6.89l.175.176V12.458H6.361v3.661l.176-.175H2.32v.702H6.537v-.175z"
                  transform="translate(15.682 18.818)"
                />
              </g>
            </g>
            <rect
              width="8"
              height="16"
              x="18.5"
              y="11.5"
              stroke="#FFF"
              rx="4"
            />
            <path
              fill="#320303"
              fillRule="nonzero"
              stroke={publicTheme.red.toString()}
              strokeWidth="2"
              d="M30.469 33.163L30.657 33.457 31.261 33.089 31.072 32.795 16.553 10.123 16.364 9.828 15.761 10.196 15.949 10.491z"
            />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M30.469 33.163L30.657 33.457 31.261 33.089 31.072 32.795 16.553 10.123 16.364 9.828 15.761 10.196 15.949 10.491z"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
}

MicOff.propTypes = { ...Icon.propTypes };
