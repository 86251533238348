import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/saved_jobs';
class SavedJob extends Model {
  static routes = {
    only: 'index',
    index: collectionRoute,
  };

  static schema = {
    activated_at: String,
    company_name: String,
    created_at: String,
    id: Number,
    location_remote_or_city_state_list: String,
    marketplace_compensation: String,
    project_title: String,
    pin: Object,
    slug: String,
  };
}

export default ReactiveRecord.model('SavedJob', SavedJob);
