import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import PropTypes from 'prop-types';

import Flex from 'components/ui/Flex';
import RightArrow from 'components/ui/icons/RightArrow';
import LeftArrow from 'components/ui/icons/LeftArrow';
import LoadingSpinner from 'components/ui/LoadingSpinner';

const ResumeContainer = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }

  .PDFPageOne {
    margin-bottom: 25px;
  }

  .PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
  }
`;

const ResumeControlsContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  color: ${props => (props.disabled ? theme.mediumGray : theme.primaryBlue)};
`;

const PDFPreviewResume = props => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const isNextPage = numPages > pageNumber;
  const isPreviousPage = pageNumber !== 1;
  const resumeUrl =
    props.uploadedResumeUrl && props.context === 'uploaded'
      ? props.uploadedResumeUrl
      : '/mom/resumes/generated.pdf';

  return (
    <>
      <ResumeContainer>
        <Document
          className="PDFDocument"
          file={resumeUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <Flex
              direction="column"
              gap={12}
              className="text-primary text-center"
            >
              <LoadingSpinner size={50} />
              <div className="font-md">Generating PDF resume...</div>
            </Flex>
          }
        >
          <Page
            className="PDFPage"
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      </ResumeContainer>
      {numPages && (
        <ResumeControlsContainer>
          <PageButton
            data-id="preview-resume-back-btn"
            disabled={!isPreviousPage}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <LeftArrow fillColor="currentColor" />
          </PageButton>
          <span>
            Page {pageNumber} of {numPages}
          </span>
          <PageButton
            data-id="preview-resume-forward-btn"
            disabled={!isNextPage}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <RightArrow fillColor="currentColor" />
          </PageButton>
        </ResumeControlsContainer>
      )}
    </>
  );
};

PDFPreviewResume.propTypes = {
  uploadedResumeUrl: PropTypes.string,
  context: PropTypes.string,
};

export default PDFPreviewResume;
