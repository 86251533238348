import ReactiveRecord, { Model } from 'reactiverecord';

const sortByOrder = (a, b) => a.order - b.order;

class MomJobApplication extends Model {
  static routes = {
    only: 'index',
    index: ':prefix/moms/job-applications',
  };
  static scopes = {
    sortByOrder(collection) {
      return collection.slice().sort(sortByOrder);
    },
  };
  static schema = {
    application_id: Number,
    application_type: String,
    can_withdraw: Boolean,
    date: String,
    filter_type: String,
    human_status: String,
    order: Number,
    other: Array,
    project_link: String,
    project_status: String,
    project_title: String,
    status: String,
  };
}

export default ReactiveRecord.model('MomJobApplication', MomJobApplication);
