import React from 'react';
import Button from 'components/ui/buttons/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function UseGenerated(props) {
  const { onClick, useGenerated } = props;
  const classes = classNames('btn-existing-file', { selected: useGenerated });

  return (
    <Button
      data-id="use-tmp-resume-btn"
      className={classes}
      onClick={onClick}
      type="button"
      css={`
        line-height: 24px;
        font-weight: normal;
      `}
    >
      {useGenerated && <div className="round-check-icon"></div>}
      Use The Mom Project resume
    </Button>
  );
}

UseGenerated.propTypes = {
  onClick: PropTypes.func,
  useGenerated: PropTypes.bool,
};

export default UseGenerated;
