import React from 'react';

import Icon from '../Icon';

const Link = props => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="nonzero">
        <path d="M7.427 6.989l.115-.122 4.467-4.468a2.74 2.74 0 013.752-.13l.121.114 1.93 1.929c1.064 1.065 1.145 2.71.071 3.784l-2 2.001a.91.91 0 01-1.357-1.206l.07-.08 2.002-2c.33-.33.282-.773-.006-1.119l-.077-.084-1.929-1.93a.912.912 0 00-1.211-.065l-.08.072-4.468 4.467a.912.912 0 00-.075 1.211l.022.027 1.372 1.372a.92.92 0 01.072 1.219l-.072.08a.919.919 0 01-1.299 0l-1.321-1.322a2.737 2.737 0 01-.1-3.75z" />
        <path d="M12.767 13.205l-.115.122-4.467 4.468a2.74 2.74 0 01-3.752.13l-.121-.114-1.93-1.93c-1.064-1.064-1.145-2.71-.072-3.783l2.001-2.001a.91.91 0 011.356 1.206l-.07.08-2.001 2c-.33.33-.283.774.006 1.119l.077.084 1.929 1.929c.33.33.851.35 1.211.066l.08-.072 4.468-4.467a.912.912 0 00.075-1.212l-.022-.027-1.372-1.371a.919.919 0 01-.072-1.22l.071-.08a.918.918 0 011.3 0l1.321 1.322a2.737 2.737 0 01.1 3.75z" />
      </g>
    </Icon>
  );
};

Link.propTypes = { ...Icon.propTypes };
Link.defaultProps = { ...Icon.defaultProps };

export default Link;
