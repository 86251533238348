import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';

const Checkmark = ({ width, height, checked, color, ...props }) => {
  let fillColor;
  if (color) {
    fillColor = color;
  } else {
    fillColor = checked ? theme.primaryBlue : theme.lightGray;
  }

  return (
    <svg
      className="checkmark"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 21 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="main-container" transform="translate(-96.000000, -1556.000000)">
          <rect fill={theme.lightBlue} x="0" y="0" width="21" height="21" />
          <g id="group-2" transform="translate(45.000000, 1545.000000)">
            <g id="group-3" transform="translate(51.154785, 7.940979)">
              <g id="checkmark-icon" transform="translate(0.000000, 3.500000)">
                <g id="Group-11">
                  <circle
                    id="Oval-Copy-5"
                    fill={fillColor}
                    cx="10.0854187"
                    cy="10.2620635"
                    r="10"
                  />
                  <g
                    id="Group-Copy-4"
                    transform="translate(5.085419, 6.262063)"
                    fill={theme.white}
                  >
                    <polygon
                      id="Page-1-Copy"
                      points="8.77209977 0.666666667 4.24701054 5.0900749 2.03717206 3.04811127 0.833333333 4.16774568 4.27069011 7.33333333 10 1.78920262"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Checkmark.propTypes = {
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Checkmark.defaultProps = {
  width: 21,
  height: 21,
};

export default Checkmark;
