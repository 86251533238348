/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  rescheduleAppointment,
  checkNylasToken,
} from 'components/containers/employer/projects/bids/requests';
import InterviewModalHeader from 'components/employer/interviews/InterviewModalHeader';
import stored from 'components/hoc/stored';
import ButtonGroup from 'components/ui/buttons/ButtonGroup';
import Flex from 'components/ui/Flex';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { colors } from 'styleGuide';

const ContentWrapper = styled(Flex)`
  max-width: 500px;
  margin: 0 auto;
`;

const handleRescheduleRequest = async (appointmentId, handleOnSuccess) => {
  const response = await rescheduleAppointment(appointmentId);
  if (response.status === 202) {
    handleOnSuccess();
  } else {
    const message = `Error rescheduling the appointment ${appointmentId}`;
    throw new Error(message);
  }
};

const useCheckNylasToken = handleReauth => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchTokenStatus = async () => {
      const token_response = await checkNylasToken();
      if (!token_response.token_valid) {
        handleReauth();
      } else {
        setLoading(false);
      }
    };

    fetchTokenStatus();
  }, []);
  return loading;
};

const ConfirmRescheduleInterview = ({
  handleOnClose,
  handleOnSuccess,
  handleReauth,
  mom,
  interview,
  secondaryButtonText,
}) => {
  const loading = useCheckNylasToken(handleReauth);

  return (
    <Flex alignItems="stretch" direction="column" gap={72}>
      {loading ? (
        <ContentWrapper alignItems="center" direction="column" gap={72}>
          <LoadingSpinner color={colors.blue.primary} size={50} />
        </ContentWrapper>
      ) : (
        <>
          <ContentWrapper alignItems="center" direction="column" gap={72}>
            <InterviewModalHeader
              centeredAndSpaced
              mom={mom}
              heading="Need to reschedule this interview?"
            />
            <div className="text-center">
              No problem! We’ll ask the candidate to choose another time slot
              based on your availability. Your calendar will update with the
              time they select.
            </div>
          </ContentWrapper>
          <ButtonGroup
            id="confirm-request-sent-btns"
            data-id="confirm-request-sent-btns"
            primary={{
              id: 'view-applicants-btn',
              onClick: () =>
                handleRescheduleRequest(interview.id, handleOnSuccess),
              text: 'Send reschedule request',
            }}
            secondary={{
              id: 'got-it-btn',
              onClick: handleOnClose,
              text: secondaryButtonText || 'Cancel',
            }}
          />
        </>
      )}
    </Flex>
  );
};

ConfirmRescheduleInterview.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleOnSuccess: PropTypes.func.isRequired,
  handleReauth: PropTypes.func.isRequired,
  interview: PropTypes.object,
  mom: PropTypes.object.isRequired,
  secondaryButtonText: PropTypes.string,
};

export default stored(ConfirmRescheduleInterview);
