import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { hasActiveInterviews } from './../supporting';
import { openingHoursToFormValues } from './supporting';
import InterviewSchedulingPageUsage from '../SchedulingPage/InterviewSchedulingPageUsage';
import stored from 'components/hoc/stored';
import ScreenSizeContext from 'components/shared/ScreenSizeContext';
import Button from 'components/ui/buttons/Button';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/FlexItem';
import {
  FUTURE_DAYS_TO_TEXT,
  MILITARY_TIME_TO_READABLE_HOUR,
  MINUTES_TO_TEXT,
} from 'consts';
import { upperFirst, capitalize } from 'utilities';

const ViewInterviewSchedulingPage = ({
  handleBack,
  handleEdit,
  schedulingPage,
}) => {
  const { isSmallScreen } = useContext(ScreenSizeContext);
  const flexProps = {
    alignItems: isSmallScreen ? 'stretch' : null,
    className: 'w-100',
    direction: isSmallScreen ? 'column' : 'row',
    gap: 16,
    justifyContent: isSmallScreen ? null : 'flex-end',
  };

  const openingHours = () => {
    const openingHoursFormValues = openingHoursToFormValues(schedulingPage);
    const openingHoursObj = Object.keys(openingHoursFormValues).reduce(
      (accum, key) => {
        const timeRanges = openingHoursFormValues[key];
        if (timeRanges && timeRanges.value.length > 0) {
          accum[key] = timeRanges.value;
        }
        return accum;
      },
      {},
    );

    return Object.entries(openingHoursObj).map(([key, timeRanges]) => {
      return (
        <div key={key}>
          <div className="mb-2 mt-2 label-default">{capitalize(key)}</div>
          {timeRanges.map(({ startTime, endTime }) => (
            <div key={`${key}-${startTime}-${endTime}`}>
              {MILITARY_TIME_TO_READABLE_HOUR[startTime]}
              <span className="mx-4">-</span>
              {MILITARY_TIME_TO_READABLE_HOUR[endTime]}
            </div>
          ))}
        </div>
      );
    });
  };

  const renderField = (label, element) => {
    return (
      <FlexItem basis="45%" grow={1}>
        <Flex alignItems="flex-start" direction="column" gap={8}>
          <div className="label-default">{label}</div>
          <div>{element}</div>
        </Flex>
      </FlexItem>
    );
  };

  return (
    <>
      <Flex
        alignItems="stretch"
        direction="column"
        gap={isSmallScreen ? 16 : 24}
      >
        {hasActiveInterviews && (
          <InterviewSchedulingPageUsage schedulingPage={schedulingPage} />
        )}
        <Flex {...flexProps}>
          {renderField('Name of meeting template', schedulingPage.name)}
          {renderField(
            'Meeting length',
            MINUTES_TO_TEXT[schedulingPage.duration],
          )}
        </Flex>
        <Flex {...flexProps}>
          {renderField(
            'Type of interview',
            upperFirst(schedulingPage.interview_type),
          )}
          {renderField(
            'How far out to schedule',
            FUTURE_DAYS_TO_TEXT[schedulingPage.available_days_in_future],
          )}
        </Flex>
        <Flex alignItems="flex-start" direction="column" gap={8}>
          <div className="label-default">I can meet during these hours</div>
          We save your settings as a template for interview invites. Your
          calendar finds available times to send candidates.
          <Flex alignItems="flex-start" direction="column" gap={8}>
            {openingHours()}
          </Flex>
        </Flex>
        <Flex {...flexProps}>
          {handleBack !== null ? (
            <FlexItem order={isSmallScreen ? 3 : 1}>
              <Button
                block={isSmallScreen}
                className={isSmallScreen && 'w-100'}
                data-testid="go-back-btn"
                id="go-back-btn"
                onClick={handleBack}
                secondary
              >
                Go back
              </Button>
            </FlexItem>
          ) : null}
          <FlexItem order={2}>
            <Button
              className="w-100"
              data-testid="edit-from-view-button"
              id="edit-from-view-button"
              onClick={handleEdit}
              primary
            >
              Edit
            </Button>
          </FlexItem>
        </Flex>
      </Flex>
    </>
  );
};

ViewInterviewSchedulingPage.propTypes = {
  schedulingPage: PropTypes.object.isRequired,
  handleBack: PropTypes.func,
  handleContinue: PropTypes.func.isRequired,
  handleEdit: PropTypes.func,
  buttonText: PropTypes.string,
};

ViewInterviewSchedulingPage.defaultProps = {
  handleBack: null,
};

export default stored(ViewInterviewSchedulingPage);
