import React from 'react';
import PropTypes from 'prop-types';
import { AlignRow, AlignRowItem } from 'components/ui/AlignRow';
import AutoHellip from 'components/ui/AutoHellip';
import CircleCheck from 'components/ui/icons/CircleCheck';
import withTooltip from 'components/ui/withTooltip';
import { ButtonWrapper } from './ButtonWrapper';

const Tooltip = withTooltip('span');
Tooltip.defaultProps = { tabIndex: '0' };

const UserPill = ({ buttonWrapperProps, user }) => {
  return (
    <>
      <ButtonWrapper data-id="edit-user-btn-wrapper" {...buttonWrapperProps}>
        <AlignRow grow>
          {buttonWrapperProps.selected && (
            <AlignRowItem middle className="user__avatar-wrapper">
              <CircleCheck width={25} className="ml-2" />
            </AlignRowItem>
          )}
          <AlignRowItem grow middle className="user__name-wrapper pl-3">
            <AutoHellip className="user__user-name">
              <div
                className={
                  'title font-md' +
                  `${buttonWrapperProps.selected ? ' user_selected' : ''}`
                }
              >
                {user.full_name}
              </div>
            </AutoHellip>
            <AutoHellip className="user__user-email">{user.email}</AutoHellip>
          </AlignRowItem>
        </AlignRow>
      </ButtonWrapper>
    </>
  );
};

UserPill.propTypes = {
  buttonWrapperProps: PropTypes.object,
  user: PropTypes.object.isRequired,
};

export default UserPill;
