import styled from 'styled-components';
import { getPrimitives } from 'utilities';
import { getCsrfToken } from 'utilities/http';

export const animationTime = '200';

export const ResumeWrapper = styled.div`
  max-width: 50rem;
`;

export const DownloadLabel = styled.div(
  ({ theme }) => `
  display: flex;
  border: 1px solid ${theme.lightGray};
  &:focus-within {
    box-shadow: 0 0 0 2px ${theme.primaryBlue.alpha('0.25')};
  }
  .download-label__file {
    flex-grow: 1;
    padding: 0 1rem;
    font-weight: ${theme.fontWeights.fontHeavy};
    color: ${theme.darkGray};
    background-color: ${theme.cyanLight};
  }
  .download-label__remove {
    width: 50px;
    color: ${theme.primaryBlue};
    background-color: white;
    border: none;
    box-shadow: inset 1px 0 0 ${theme.lightGray};
    &:hover,
    &:focus {
      background-color: ${theme.lightBlue};
    }
  }
  `,
);
export const UploaderLabel = styled.div(
  ({ theme, progressWidth }) => `
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: ${theme.fontWeights.fontHeavy};
  line-height: 50px;
  color: ${theme.primaryBlue};
  text-align: center;
  cursor: pointer;
  background-color: ${theme.white};
  border-radius: 25px;
  box-shadow: inset 0 0 0 2px;
  transition: background-color ${animationTime}ms;
  @media (min-width: ${theme.screenSizes.tablet}) {
    max-width: ${theme.screenSizes.mobileL};
  }
  &:focus-within {
    box-shadow: inset 0 0 0 2px, 0 0 0 0.2rem ${theme.primaryBlue.alpha(
      '0.25',
    )};
  }
  &:hover,
  &:focus-within {
    background-color: ${theme.lightBlue};
  }
  &:before {
    position: absolute;
    display: block;
    width: ${progressWidth}%;
    height: 100%;
    pointer-events: none;
    content: "";
    background-color: ${theme.primaryBlue.alpha('0.2')};
    transition: width ${animationTime}ms;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0;
  }
`,
);

export const uploadFile = (
  file,
  uploaderAttribute,
  uploaderPath,
  progressCallback,
  parseSkills,
  bidRelated,
) => {
  const request = new XMLHttpRequest();
  return [
    request,
    new Promise((resolve, reject) => {
      const queryString = `?profile_visible=${bidRelated ? 'false' : 'true'}${
        parseSkills ? '&onboarding=true' : ''
      }`;
      const formData = new FormData();
      formData.append(uploaderAttribute, file);
      const defaultErrorMessage = `An error occurred while uploading ${file.name}.`;
      request.upload.addEventListener(
        'progress',
        ({ lengthComputable, loaded, total }) => {
          if (lengthComputable) {
            progressCallback(Math.floor((loaded / total) * 100).toString());
          }
        },
        false,
      );
      request.addEventListener('error', () => reject(defaultErrorMessage));
      request.addEventListener('abort', () =>
        reject(`Canceled uploading ${file.name}.`),
      );
      request.addEventListener('load', ({ target: { response } }) => {
        if (!/20[0-2]/.test(request.status)) {
          try {
            reject(
              getPrimitives(JSON.parse(response))[0] || defaultErrorMessage,
            );
          } catch (error) {
            console.log(error);
            reject(defaultErrorMessage);
          }
          return;
        }
        resolve(JSON.parse(response));
      });
      request.open('POST', `${uploaderPath}${queryString}`);
      request.setRequestHeader('X-CSRF-Token', getCsrfToken());
      request.send(formData);
    }),
  ];
};
