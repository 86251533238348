import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div(
  ({ width, height }) => `
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: ${(height / width) * 100}%;
  padding-top: 30px;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`,
);

const YouTubeEmbed = ({ src, title, ...props }) => (
  <Wrapper {...props}>
    <iframe
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      frameBorder="0"
      src={src}
      title={title}
    />
  </Wrapper>
);

YouTubeEmbed.defaultProps = {
  width: 560,
  height: 315,
};

YouTubeEmbed.propTypes = {
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
};

export default YouTubeEmbed;
