import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const BidModalStyles = styled.div`
  .title {
    font-size: 2.25rem;
    font-weight: ${({ theme }) => theme.fontWeights.fontMedium};
  }
`;

const BidModalWrapper = ({ children }) => (
  <BidModalStyles className="pt-5 pb-4 px-0 px-md-4 position-relative">
    {children}
  </BidModalStyles>
);

BidModalWrapper.propTypes = {
  children: PropTypes.node,
};

export default BidModalWrapper;
