import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'components/ui/Box';
import CloseButton from 'components/ui/buttons/CloseButton';
import { colors } from 'styleGuide';
import styled, { css } from 'styled-components';

export const StyledBox = styled(Box)(
  ({ theme }) => css`
    margin-left: 0;
    margin-right: 0;
    padding: 3rem 2rem 1rem;

    @media (min-width: ${theme.screenSizes.tablet}) {
      margin-left: -2rem;
      margin-right: -2rem;
      padding: 2rem;
    }

    h3 {
      font-family: ${theme.fonts.merriweather};
      font-size: ${theme.fontSizes.lg};
      margin-bottom: 0.7rem;
    }

    > p:last-of-type {
      margin-bottom: 0;
    }
  `,
);

const InlineAnnouncement = ({ children, onClose, show, title, ...props }) => {
  return (
    <>
      {show && (
        <StyledBox backgroundColor={colors.blue.light} {...props}>
          <CloseButton
            onClick={onClose}
            id="close-button"
            data-testid="close-button"
            color={colors.gray.medium}
          />
          <h3>{title}</h3>
          {children}
        </StyledBox>
      )}
    </>
  );
};

InlineAnnouncement.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default InlineAnnouncement;
