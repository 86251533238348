/*  eslint-disable react/prop-types */
import { forwardRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { validated } from 'reactiverecord';

const RadioGroup = forwardRef(
  (
    {
      children,
      defaultValue,
      errorText,
      name,
      onBlur,
      onChange,
      validating,
      noDefault,
    },
    forwardedRef,
  ) => {
    const generatedName = useMemo(
      () => `radio-group-${RadioGroup.nameCounter++}`,
      [],
    );
    const fields = [];
    useEffect(
      () => {
        forwardedRef({
          get value() {
            const [first] = fields.filter(field => field && field.checked);
            if (first) {
              return first.value;
            }
            return null;
          },
        });
        return () => {
          forwardedRef(null);
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const options = value => {
      return {
        value,
        name: name || generatedName,
        disabled: validating,
        onChange,
        onBlur,
        ref: ref => fields.push(ref),
        ...(!noDefault && { defaultChecked: value === defaultValue }),
      };
    };

    return children(options, errorText);
  },
);
RadioGroup.propTypes = {
  children: PropTypes.func.isRequired,
};
RadioGroup.nameCounter = 0;
RadioGroup.displayName = 'RadioGroup';

export default validated(RadioGroup);
