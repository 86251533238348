import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components/ui/inputs/Input/styles';
import { useFormikContext } from 'formik';
import Radio from 'components/ui/inputs/Radio';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import { noop } from 'utilities';

const RadioGroup = ({
  id,
  labelText,
  name,
  onChange = noop,
  values,
  ...props
}) => {
  const { touched, errors } = useFormikContext();
  const hasErrors = touched && touched[name] && errors && errors[name];

  return (
    <>
      <Label>
        <span id={`radio-group-${id}`} className="label-text mb-4">
          {labelText}
        </span>
      </Label>
      <div
        role="group"
        className="d-flex flex-column"
        aria-labelledby={`radio-group-${id}`}
      >
        {values.map(({ label, value, infoText }) => {
          return (
            <Radio
              key={value}
              labelText={label}
              name={name}
              onClick={e => onChange(e)}
              type="radio"
              value={value}
              data-testid={value}
              defaultChecked={props.value === value}
              infoText={infoText}
            />
          );
        })}
        {hasErrors && <ErrorMessage>{errors[name]}</ErrorMessage>}
      </div>
    </>
  );
};

RadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  // The selected value of the group. Provided by Formik
  value: PropTypes.any,
  // Array of objects that represent the radio options
  // {label: 'Label Text', value: 'value_of_radio_button', infoText: 'optional text for below button'}
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      infoText: PropTypes.string,
    }),
  ),
};

export default RadioGroup;
