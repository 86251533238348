import React from 'react';
import PropTypes from 'prop-types';
import { URLS } from 'consts';
import { useMediaSet } from 'use-media-set';
import { mediaSetQueries } from 'styleGuide';
import LinkButton from 'components/ui/buttons/LinkButton';

const ProjectButtonByCondition = ({
  onProjectView,
  talentBidStatus,
  talentBidAppliedOnBehalf,
  slug,
}) => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const getButtonText = talentBidStatus => {
    if (talentBidStatus) {
      const appliedLabel = talentBidAppliedOnBehalf ? 'Submitted' : 'Applied';
      return talentBidStatus === 'draft' ? 'Finish draft' : appliedLabel;
    } else {
      return 'View job details';
    }
  };

  const buttonText = getButtonText(talentBidStatus);
  const buttonColorIsDark =
    buttonText === 'Applied' || buttonText === 'Submitted';

  return (
    <LinkButton
      id={`project-button-${slug}`}
      data-testid={`project-button-${slug}`}
      className="d-block d-sm-inline-block flex-grow-1 px-sm-4 py-sm-3"
      href={URLS.project(slug)}
      dark={buttonColorIsDark}
      onClick={onProjectView}
      size={mediaStates.has('mobile') ? 'md' : 'sm'}
    >
      {buttonText}
    </LinkButton>
  );
};

ProjectButtonByCondition.propTypes = {
  onProjectView: PropTypes.func,
  slug: PropTypes.string,
  talentBidStatus: PropTypes.string,
  talentBidAppliedOnBehalf: PropTypes.bool,
};

export default ProjectButtonByCondition;
