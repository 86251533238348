import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Publication from './Publication';
import { TalentAvatar } from 'components/shared/TalentAvatar';
import { isVideoTrack } from './utilities';
import useIdentity from './useIdentity';
import styled from 'styled-components';

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  height: 100%;
  width: 100%;
`;

const RemoteParticipant = ({ participant }) => {
  const [remoteTracks, setRemoteTracks] = useState([]);
  const [videoDisabled, setVideoDisabled] = useState(false);
  const participantIdentity = useIdentity(participant);

  const setupParticipant = track => {
    setRemoteTracks(prev => [...prev, track]);

    if (isVideoTrack(track)) {
      track.on('disabled', () => setVideoDisabled(true));
      track.on('enabled', () => setVideoDisabled(false));
    }
  };

  useEffect(() => {
    participant.on('trackSubscribed', setupParticipant);
    return () => participant.off('trackSubscribed', setupParticipant);
  }, [participant]);

  useEffect(() => {
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        setRemoteTracks(prev => [...prev, publication.track]);

        if (isVideoTrack(publication.track)) {
          publication.track.on('disabled', () => setVideoDisabled(true));
        }
      }
    });
  }, [participant.tracks]);

  return remoteTracks.map((track, i) => {
    if (videoDisabled && isVideoTrack(track)) {
      return (
        <AvatarContainer key={i}>
          <TalentAvatar talentName={participantIdentity} />
        </AvatarContainer>
      );
    } else {
      return <Publication key={i} track={track} />;
    }
  });
};

RemoteParticipant.propTypes = {
  participant: PropTypes.object,
};

export default RemoteParticipant;
