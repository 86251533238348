import React from 'react';

const StarCheckBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="61"
      viewBox="0 0 60 61"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="30" cy="30.5" r="30" fill="#B0EEE3" />
        <path
          fill="#464A59"
          fillRule="nonzero"
          d="M28.48 13.856c.534-1.08 2.076-1.08 2.608 0l4.54 9.197 10.15 1.475c1.193.173 1.67 1.64.806 2.481l-7.344 7.158 1.734 10.11c.203 1.188-1.043 2.094-2.111 1.532l-9.078-4.772-9.078 4.772c-1.067.562-2.314-.344-2.11-1.533l1.734-10.109-7.344-7.158c-.864-.842-.387-2.308.805-2.481l10.15-1.475zm1.305 3.425l-4.045 8.197-9.046 1.315 6.546 6.38-1.545 9.009 8.09-4.253 8.09 4.253-1.544-9.01 6.545-6.38-9.046-1.314-4.045-8.197zm5.872 10.25c.605.606.605 1.588 0 2.193l-5.492 5.492c-.568.568-1.49.568-2.057 0l-2.834-2.833c-.567-.567-.602-1.466-.106-2.074l.106-.118c.607-.605 1.587-.605 2.192 0l1.67 1.67 4.329-4.33c.605-.604 1.587-.604 2.192 0z"
        />
      </g>
    </svg>
  );
};

export default StarCheckBlue;
