/* eslint react/prop-types: 0 */
import classNames from 'classnames';
import React from 'react';
import stored from 'components/hoc/stored';
import AnalyticsContext from 'components/shared/analytics/AnalyticsContext';
import AnalyticsProvider from 'components/shared/analytics/AnalyticsProvider';
import AnimateWrapper from 'components/ui/AnimateWrapper';
import BackButton from 'components/ui/buttons/BackButton';
import FlexFactors from 'components/shared/FlexFactors';
import Hero from 'components/ui/Hero';
import MultiStepForm from 'components/shared/MultiStepForm';
import { getCapabilitiesForFunction } from 'utilities';
import AboutMe from 'components/talent/onboarding/AboutMe';
import CareerStory from 'components/talent/onboarding/CareerStory';
import Welcome from 'components/talent/onboarding/Welcome';
import OnboardingProgressBar from 'components/talent/onboarding/OnboardingProgressBar';
import withErrorBoundary from 'components/hoc/withErrorBoundary';
import Button from 'components/ui/buttons/Button';
import { getCsrfToken } from 'utilities/http';

const talentSections = () => ['About Me', 'My Career Story', 'Work Goals'];

// Our page hero changes based on which step in our onboarding
const talentHeroContent = () => ({
  0: {
    heading: 'About Me',
    description:
      "What makes you special? What are you looking for?\n Let's get started!",
    id: 'aboutMeHero',
  },
  1: {
    heading: 'My Career Story',
    description:
      'Fill us in! The more we know about your work history and skills,\n the better we can match you to great opportunities to continue\n your career journey.',
    id: 'careerStoryHero',
  },
  2: {
    heading: 'Work Goals',
    description:
      "What matters most to you when it comes to flexibility?\n We'll use this to connect you to work that fits your life.",
    id: 'flexFactorsHero',
  },
});

class TalentOnboarding extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const valid = nextProps.validator && nextProps.validator(nextProps.values);
    return {
      ...prevState,
      valid,
    };
  }

  constructor(props) {
    super(props);
    this.heroContent = talentHeroContent();
    this.sections = talentSections();
    this.state = {
      valid: false,
    };
  }

  submit = () => {
    this.props.updateSaving(true);
    const section = this.props.values;
    const { index } = this.props;
    let data;
    switch (index) {
      default:
        data = {};
        break;
      case 0:
        data = {
          mom: {
            zip: section.zip_code,
            goals: section.goals.join(' '),
            ideal_outcomes: section.goals_other,
            current_work_status: section.current_work_status,
          },
        };
        break;
      case 1:
        data = {
          mom: {
            primary_capabilities_category_id: section.primaryFunction,
            capability_ids: section.capabilities.concat(
              section.capabilities_other,
            ),
            industry_ids: section.industries,
            level: section.experience_level,
            expertises_attributes: section.confirmed_skills,
          },
        };
        break;
      case 2:
        data = {
          mom: {
            availability: section.availability,
            one_to_twenty: section.preferred_hours.includes('upto20')
              ? true
              : false,
            twenty_one_to_thirty: section.preferred_hours.includes('upto30')
              ? true
              : false,
            thirty_one_to_forty: section.preferred_hours.includes('upto40')
              ? true
              : false,
            over_forty: section.preferred_hours.includes('morethan40')
              ? true
              : false,
            full_time: section.employment_status.includes('fulltime')
              ? true
              : false,
            project_based: section.employment_status.includes('freelance')
              ? true
              : false,
            maternityship: section.employment_status.includes('parttime')
              ? true
              : false,
            work_status_unsure: section.employment_status.includes('unemployed')
              ? true
              : false,
            contract_without_benefits: section.employment_status.includes(
              'contract_without_benefits',
            )
              ? true
              : false,
            contract_with_benefits: section.employment_status.includes(
              'contract_with_benefits',
            )
              ? true
              : false,
            prefered_arraignment: section.preferred_remote,
            exclude_by_min_salary: section.exclude_by_min_salary,
            minimum_hourly_rate_cents: section.minimum_hourly_rate_cents,
            minimum_salary_cents: section.minimum_salary_cents,
            onboarding_complete: true,
          },
        };
        break;
    }
    this.save(data);
  };

  save = obj => {
    const self = this;
    window.$.ajax({
      url: this.props.url,
      method: 'PATCH',
      headers: { 'X-CSRF-Token': getCsrfToken() },
      data: obj,
      dataType: 'json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          'X-CSRF-Token',
          window.$('meta[name="csrf-token"]').attr('content'),
        );
      },
      success: self.onSuccess,
      error: self.onError,
    });
  };

  onSuccess = () => {
    if (this.props.index < 4) {
      this.props.incrementStep();
      this.props.updateSaving(false);
    } else {
      window.location = this.props.redirect_path;
    }
  };

  onError = (data, status, errorThrown) => {
    // We are no longer handling an invalid email here, since there is no email in any of the onboarding forms.
    console.error(status + ' ' + errorThrown + ' ' + data);
  };

  getSection() {
    const actions = {
      incrementStep: this.props.incrementStep,
      decrementStep: this.props.decrementStep,
      save: this.save,
      submit: this.submit,
      onChange: this.props.onChange,
      state: this.props.values,
    };
    switch (this.props.index) {
      default:
      case 0:
        return <AboutMe {...actions} />;
      case 1:
        return (
          <CareerStory
            capabilities={this.props.capabilities}
            functions={this.props.functions}
            {...actions}
          />
        );
      case 2:
        return (
          <FlexFactors
            onChange={this.props.onChange}
            mode="talent"
            availability={this.props.values.availability}
            employment_status={this.props.values.employment_status}
            preferred_hours={this.props.values.preferred_hours}
            preferred_remote={this.props.values.preferred_remote}
            exclude_by_min_salary={this.props.values.exclude_by_min_salary}
            minimum_hourly_rate_cents={
              this.props.values.minimum_hourly_rate_cents
            }
            minimum_salary_cents={this.props.values.minimum_salary_cents}
          />
        );
      case 3:
        return <Welcome {...actions} />;
    }
  }

  render() {
    const { props, state, sections } = this;
    const maxIndex = 3;
    const {
      heading = '',
      description = '',
      id = '',
    } = this.heroContent[props.index] || {};
    const continueLabel = props.index === maxIndex ? 'Get Started' : 'Continue';
    const onboardingContainerClasses = classNames(
      'onboarding-layout',
      'talent',
    );
    const showBackButton = props.index > 0 && props.index < maxIndex;

    return (
      <div className={onboardingContainerClasses}>
        {props.index < maxIndex ? (
          <div style={{ paddingBottom: '40px' }}>
            <AnimateWrapper step={String(props.index)}>
              <Hero heading={heading} description={description} id={id}>
                {showBackButton && (
                  <BackButton
                    data-id="onboarding-back-btn"
                    fixed
                    action={() => props.decrementStep()}
                  />
                )}
              </Hero>
              {this.props.showProgressBar && (
                <OnboardingProgressBar
                  sections={sections}
                  active={props.index}
                  action={props.changeStep}
                />
              )}
              <div className="mt-5 pb-5">
                {this.getSection()}
                <div className="container-fluid">
                  <div className="row mt-5 justify-content-center">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                      <Button
                        data-id="submit-btn"
                        data-testid="submit-btn"
                        type="submit"
                        block
                        disabled={!state.valid || props.saving}
                        onClick={this.submit}
                      >
                        {props.saving ? 'One moment...' : continueLabel}
                      </Button>
                    </div>
                  </div>
                  {showBackButton && (
                    <div className="row mt-4 justify-content-center">
                      <BackButton
                        data-id="onboarding-back-btn"
                        inverse
                        action={() => props.decrementStep()}
                      />
                    </div>
                  )}
                </div>
              </div>
            </AnimateWrapper>
          </div>
        ) : (
          <AnimateWrapper step={String(props.index)}>
            {this.getSection()}
          </AnimateWrapper>
        )}
      </div>
    );
  }
}

function getInitialValues(props) {
  const mom_data = props.mom.data.attributes;
  return {
    0: {
      zip_code: mom_data.zip != null ? mom_data.zip : '',
      goals: mom_data.goals != null ? mom_data.goals.split(' ') : [],
      goals_other:
        mom_data.ideal_outcomes != null ? mom_data.ideal_outcomes : '',
      current_work_status: mom_data.current_work_status || '',
    },
    1: {
      primaryFunction: props.selected_function,
      function_options: props.functions,
      capabilities: getCapabilitiesForFunction(
        props.selected_function,
        props.capabilities,
        props.selected_capabilities,
        true,
      ),
      capabilities_other: getCapabilitiesForFunction(
        props.selected_function,
        props.capabilities,
        props.selected_capabilities,
      ),
      capabilities_options: props.capabilities,
      experience_level: mom_data.level != null ? mom_data.level : '',
      industries: props.selected_industries,
      industries_filter: '',
      industry_options: props.industries,
    },
    2: {
      availability: props.availability,
      employment_status: props.employment_status,
      preferred_hours: props.preferred_hours,
      preferred_remote: props.preferred_remote,
      exclude_by_min_salary: props.exclude_by_min_salary,
      minimum_hourly_rate_cents: props.minimum_hourly_rate_cents,
      minimum_salary_cents: props.minimum_salary_cents,
    },
  };
}

function validateAbout(values) {
  var bool = false;
  if (values.zip_code.length >= 5) {
    if (values.goals.length > 0 || values.goals_other.length > 0) {
      if (values.current_work_status.length > 0) {
        bool = true;
      }
    }
  }
  return bool;
}

function validateCareer(values) {
  var bool = false;
  if (values.primaryFunction > 0) {
    if (values.capabilities.length > 0) {
      if (values.industries.length > 0) {
        if (values.experience_level.length > 0) {
          bool = true;
        }
      }
    }
  }
  return bool;
}

function validateFlex(values) {
  var bool = false;
  const availabilityIsValid = values.availability.length > 0;
  let excludeByMinSalaryIsValid =
    typeof values.exclude_by_min_salary === 'boolean';
  if (excludeByMinSalaryIsValid && values.exclude_by_min_salary) {
    excludeByMinSalaryIsValid = false;
    if (
      values.minimum_hourly_rate_cents > 0 ||
      values.minimum_salary_cents > 0
    ) {
      excludeByMinSalaryIsValid = true;
    }
  }
  if (
    availabilityIsValid &&
    window.Utils.isValidArray(values.employment_status) &&
    window.Utils.isValidArray(values.preferred_hours) &&
    values.preferred_remote.length > 0 &&
    excludeByMinSalaryIsValid
  ) {
    bool = true;
  }
  return bool;
}

// This is implemented under the assumptiom that our system for form validations will be changing.
// For that reason, I left the valiators pretty loosely coupled from the actual multi-step form logic
const validators = {
  0: validateAbout,
  1: validateCareer,
  2: validateFlex,
};

function TalentOnboardingWrapper(props) {
  return (
    <AnalyticsProvider disableTracking>
      <MultiStepForm
        initialValues={getInitialValues(props)}
        initialIndex={props.initialIndex}
      >
        {formProps => (
          <AnalyticsContext.Consumer>
            {({ trackEvents }) => (
              <TalentOnboarding
                trackEvents={trackEvents}
                validator={validators[formProps.index]}
                {...props}
                {...formProps}
              />
            )}
          </AnalyticsContext.Consumer>
        )}
      </MultiStepForm>
    </AnalyticsProvider>
  );
}

const OnboardingComponent = props => {
  const component = () => <TalentOnboardingWrapper {...props} />;
  const context = {
    momId: props.mom.data.attributes.slug,
  };
  return withErrorBoundary(component, context);
};

export default stored(OnboardingComponent);
