import PropTypes from 'prop-types';
import React from 'react';
import { StyledInputContainer } from './styles';

const RadioInputWithImage = ({
  backgroundColor,
  imageAlt,
  imageSrc,
  imageHeight,
  imageWidth,
  labelText,
  name,
  onClick,
  value,
}) => {
  return (
    <StyledInputContainer style={{ backgroundColor: backgroundColor }}>
      <input
        className="radio-input"
        id={value}
        name={name}
        onClick={onClick}
        type="radio"
        value={value}
      />
      <label
        className="d-flex mb-0 py-md-5 px-4 w-100 align-items-center"
        htmlFor={value}
      >
        <span className="visual-radio mr-3"></span>
        {labelText}
        <img
          src={imageSrc}
          alt={imageAlt}
          width={imageHeight}
          height={imageWidth}
          className="ml-auto"
        />
      </label>
    </StyledInputContainer>
  );
};

RadioInputWithImage.propTypes = {
  backgroundColor: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

RadioInputWithImage.defaultProps = {
  imageHeight: '50',
  imageWidth: '50',
};

export default RadioInputWithImage;
