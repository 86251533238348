import { useState } from 'react';
import { activateLoader, deactivateLoader } from 'utilities';

const useLoader = (initialState = false) => {
  const [loading, setLoading] = useState(initialState);
  if (loading) {
    activateLoader();
  } else {
    deactivateLoader();
  }

  return [loading, setLoading];
};

export default useLoader;
