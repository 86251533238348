import React from 'react';

import Icon from '../Icon';

const Calendar = props => {
  return (
    <Icon viewBox="0 0 17.72 20" {...props}>
      <path
        fill="currentColor"
        d="M15.19,1.43h-.84V.84a.85.85,0,0,0-1.69,0v.59H5.06V.84a.84.84,0,1,0-1.68,0v.59H2.53A2.54,2.54,0,0,0,0,4v13.5A2.53,2.53,0,0,0,2.53,20h8a7.17,7.17,0,0,0,7.17-7.17V4A2.54,2.54,0,0,0,15.19,1.43ZM3,3.12h.43v1.1a.84.84,0,0,0,1.68,0V3.12h7.6v1.1a.85.85,0,0,0,1.69,0V3.12h.42A1.26,1.26,0,0,1,16,4.39V6.75H1.69V4.39A1.26,1.26,0,0,1,3,3.12ZM1.69,17.05V8.44H16v4a5,5,0,0,1,0,.67l-2.49-.62L13,12.33a1,1,0,0,0-1.19.71.7.7,0,0,0,0,.14c-.2,2.49-3.26,5.1-5.37,5.13H3A1.25,1.25,0,0,1,1.69,17.05Zm8.78,1.24a6.35,6.35,0,0,0,2.95-4.11l2.15.53A5.9,5.9,0,0,1,10.47,18.29Z"
      />
    </Icon>
  );
};

Calendar.propTypes = { ...Icon.propTypes };
Calendar.defaultProps = { ...Icon.defaultProps };

export default Calendar;
