import { useEffect, useState } from 'react';
import { AVAILABLE_APPOINTMENT_TIMES } from 'consts';
import { getFormattedDate } from 'utilities';
import { optionMap } from '../supporting';

const shortDateOptions = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
};

const timeOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};

export const useAvailableHours = appointmentDate => {
  const [availableHours, setAvailableHours] = useState(null);
  const today = getFormattedDate(new Date(), shortDateOptions);
  useEffect(() => {
    if (!!appointmentDate) {
      const formattedDate = getFormattedDate(
        new Date(appointmentDate + 'T00:00:00'),
        shortDateOptions,
      );
      if (formattedDate === today) {
        // if the provided date is the same as the current date,
        // we allow only times after the current one.
        const currentTime = getFormattedDate(new Date(), timeOptions);
        const availableHours = AVAILABLE_APPOINTMENT_TIMES.filter(
          timeSlot => timeSlot.time > currentTime,
        );
        setAvailableHours(
          optionMap(availableHours, 'from-time', 'id', 'time', 'displayTime'),
        );
      } else {
        setAvailableHours(
          optionMap(
            AVAILABLE_APPOINTMENT_TIMES,
            'from-time',
            'id',
            'time',
            'displayTime',
          ),
        );
      }
    }
  }, [appointmentDate, today]);
  return availableHours;
};
export const getAppointmentsFromRange = (
  startTimeId,
  endTimeId,
  appointmentDate,
) => {
  const timesInRange = AVAILABLE_APPOINTMENT_TIMES.filter(
    appointmentTime =>
      appointmentTime.id >= startTimeId && appointmentTime.id <= endTimeId,
  );
  return timesInRange.map(value => `${appointmentDate}T${value.time}`);
};
export const mergeTimeslots = (newTimeslots, existingTimeslots) => {
  // validNewTimeSlots will only include time slots that are not already included for that particular date
  const validNewTimeSlots = newTimeslots.filter(
    timeSlot => !isTimeslotInArray(timeSlot, existingTimeslots),
  );
  return existingTimeslots.concat(validNewTimeSlots); //
};

const isTimeslotInArray = (timeslot, array) =>
  !!array.find(element => element === timeslot);
