import React from 'react';
import PropTypes from 'prop-types';
import Elements from './Elements';

const Questions = ({ questions }) => {
  return (
    <>
      {questions.map(question => {
        const showLabel = question.label !== 'opt-out';
        const questionStyles = showLabel ? 'question' : 'question float-left';

        question.feedback_form_elements.sort((a, b) => {
          return a['display_order'] - b['display_order'];
        });

        return (
          <div
            className={questionStyles}
            id={`question${question.id}`}
            key={`question${question.id}`}
          >
            {showLabel && <p>{question.label}</p>}
            <Elements elements={question.feedback_form_elements} />
          </div>
        );
      })}
    </>
  );
};

Questions.propTypes = {
  questions: PropTypes.array,
};

export default Questions;
