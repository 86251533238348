import React from 'react';
import PropTypes from 'prop-types';
import IconLinkTooltip from 'components/shared/IconLinkTooltip';
import { TooltipWrapper } from './styles';
import Media from 'react-media';
import { screenSizeInt } from 'styleGuide';

const LabelWithTooltip = ({ labelText, tooltipText }) => (
  <>
    {labelText ? (
      <span className="label-text">
        {labelText}
        {tooltipText && (
          <Media query={`(min-width: ${screenSizeInt.tabletXS + 1}px)`}>
            <TooltipWrapper className="d-inline ml-1">
              <IconLinkTooltip
                title={tooltipText}
                trigger="MOUSEENTER"
                className="d-inline"
              />
            </TooltipWrapper>
          </Media>
        )}
      </span>
    ) : null}
  </>
);

LabelWithTooltip.propTypes = {
  labelText: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default LabelWithTooltip;
