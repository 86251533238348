import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import { colors } from 'styleGuide';

const SearchIcon = props => {
  return (
    <Icon title="Search" viewBox="0 0 32 32" {...props}>
      <path
        fill={props.disabled ? colors.gray.dark : props.color}
        d="M13.47 0.941c-1.13 0.002-2.276 0.161-3.415 0.491-5.501 1.593-9.098 6.692-9.114 12.225v0.075c0.003 1.143 0.16 2.304 0.484 3.456 1.901 6.759 8.866 10.688 15.526 8.758 1.164-0.337 2.372-0.912 3.514-1.696l7.723 6.457c0.293 0.237 0.645 0.352 0.994 0.352h0.003c0.473-0.001 0.942-0.21 1.257-0.613l0.272-0.348c0.228-0.292 0.341-0.641 0.345-0.989v-0.039c-0.006-0.474-0.212-0.942-0.604-1.257l-7.242-6.054c2.354-2.811 3.71-6.794 2.366-11.57-1.57-5.584-6.6-9.236-12.057-9.247h-0.053zM4.94 16.171c-1.35-4.799 1.39-9.801 6.118-11.171s9.657 1.409 11.007 6.208c1.35 4.8-1.389 9.801-6.118 11.172-0.817 0.236-1.64 0.349-2.449 0.349-3.876 0-7.442-2.588-8.559-6.559z"
      />
    </Icon>
  );
};

SearchIcon.propTypes = {
  ...Icon.propTypes,
  disabled: PropTypes.bool,
};
SearchIcon.defaultProps = {
  ...Icon.defaultProps,
  disabled: false,
  size: 22,
};

export default SearchIcon;
