import { useState, useEffect } from 'react';
import { createLocalTracks } from 'twilio-video';
import { MEDIA_ERRORS } from 'consts';
import Honeybadger from 'honeybadger-js';

const useLocalTracks = () => {
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [localVideoTrack, setLocalVideoTrack] = useState();
  const [permissionError, setPermissionError] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const [genericError, setGenericError] = useState(false);

  useEffect(() => {
    createLocalTracks({
      video: {
        name: `camera-${Date.now()}`,
      },
      audio: true,
    })
      .then(tracks => {
        const videoTrack = tracks.find(track => track.kind === 'video');
        const audioTrack = tracks.find(track => track.kind === 'audio');

        setLocalVideoTrack(videoTrack);
        setLocalAudioTrack(audioTrack);
      })
      .catch(err => {
        setGenericError(true);
        if (
          MEDIA_ERRORS.not_found.filter(message =>
            message.includes(err.message),
          )
        ) {
          setNotFoundError(true);
          setPermissionError(false);
        } else if (
          MEDIA_ERRORS.permission_denied.filter(message =>
            message.includes(err.message),
          )
        ) {
          setPermissionError(true);
          setNotFoundError(false);
        } else {
          Honeybadger.notify(
            `[video-interview] - unclassified error while attempting to create local tracks: ${err.message}`,
          );
        }
      });
  }, []);

  return {
    localVideoTrack,
    localAudioTrack,
    permissionError,
    notFoundError,
    genericError,
  };
};

export default useLocalTracks;
