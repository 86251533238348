import React from 'react';
import withTooltip from 'components/ui/withTooltip';
import Badge from 'components/ui/Badge';
import Button from 'components/ui/buttons/Button';
import styled from 'styled-components';
import { useAnnouncement } from 'utilities/hooks';

const Tooltip = withTooltip('span');
const StyledButton = styled(Button)`
  border: 1px solid var(--white);
  padding: 5px 15px;
`;

const RecommendedBadge = () => {
  const { announcement, dismiss } = useAnnouncement('recommended-job');

  return (
    <Tooltip
      trigger="CLICK"
      tooltipCSS="min-width: 300px"
      isOpen={!!announcement}
      title={
        <div
          css={`
            font-size: 0.85rem;
          `}
        >
          Based off of information collected from your profile, we think this
          job is a good fit!
          <div className="mt-3 d-flex justify-content-end">
            <StyledButton
              id="recommended-tooltip-button"
              data-testid="recommended-tooltip-button"
              size="xs"
              type="button"
              onClick={dismiss}
            >
              Got it
            </StyledButton>
          </div>
        </div>
      }
    >
      <Badge color="primary" variant="outlined">
        Recommended
      </Badge>
    </Tooltip>
  );
};

export default RecommendedBadge;
