import React from 'react';
import PropTypes from 'prop-types';
import ReactiveRecord, { Member } from 'reactiverecord';

const CurrentMomModel = ReactiveRecord.model('CurrentMom');

let requestedAtLeastOnce = false;
let loadedAtLeastOnce = false;

const CurrentMom = ({ children, noCache = false, ...props }) => {
  return (
    <Member
      for={CurrentMomModel}
      fetch={noCache || !requestedAtLeastOnce}
      {...props}
    >
      {currentUser => {
        requestedAtLeastOnce = true;
        if (/20[0-2]/.test(currentUser._request.status)) {
          loadedAtLeastOnce = true;
        }
        return children(currentUser, loadedAtLeastOnce);
      }}
    </Member>
  );
};

CurrentMom.propTypes = {
  children: PropTypes.func.isRequired,
  noCache: PropTypes.bool,
};

export default CurrentMom;
