import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styleGuide';

const UpCaret = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      viewBox="0 0 22 22"
    >
      <path
        fill={props.fillColor}
        d="M1.312 15.69c.416.416 1.091.416 1.507 0l8.103-8.104 8.104 8.104c.416.416 1.091.416 1.507 0 .416-.417.416-1.09 0-1.507L11.647 5.3c-.403-.403-1.054-.399-1.458.005l-8.877 8.878c-.416.416-.416 1.09 0 1.507"
      />
    </svg>
  );
};

UpCaret.propTypes = {
  fillColor: PropTypes.any,
  width: PropTypes.number,
};

UpCaret.defaultProps = {
  fillColor: colors.gray.dark,
  width: 14,
};

export default UpCaret;
