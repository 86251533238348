/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { coffee } from 'assets/illustrations';
import RequestInterviewError from './RequestInterviewError';
import { awaitNylasSync } from 'components/containers/employer/projects/bids/requests';

const InterviewSchedulingPageAccountLoading = ({
  handleAccountSynced,
  handleOptOut,
}) => {
  const [showError, setShowError] = useState(false);

  const getStatus = () => {
    setShowError(false);
    awaitNylasSync()
      .then(accountSynced => {
        if (accountSynced.success) {
          return handleAccountSynced();
        }
      })
      .catch(() => {
        setShowError(true);
      });
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <div className="d-flex flex-column" css="gap: 1.5rem;">
      {showError ? (
        <RequestInterviewError
          handleOptOut={handleOptOut}
          handleTryAgain={getStatus}
          buttonText="Go to Interviews"
        />
      ) : (
        <>
          <img
            className="m-auto"
            src={coffee}
            alt="coffee animation"
            width="140px"
          />
          <h1 className="text-center">Your calendar is connecting</h1>
          <p className="text-center">
            This might take a minute or two.
            <br />
            Now is a good time to grab coffee.
          </p>
        </>
      )}
    </div>
  );
};

InterviewSchedulingPageAccountLoading.propTypes = {
  handleAccountSynced: PropTypes.func.isRequired,
  handleOptOut: PropTypes.func.isRequired,
};

export default InterviewSchedulingPageAccountLoading;
