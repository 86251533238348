import React from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import { EMPLOYER_OFFSET_TOP } from 'consts';

const HeaderScroll = ({ sections }) => {
  const sectionLabels = [];

  const handleOnClick = event => {
    const scrollTo = event.target.dataset.scrollTo;
    const element = document.getElementById(scrollTo);
    const sectionLocation = element.offsetTop - EMPLOYER_OFFSET_TOP;
    const scrollToTop = scrollTo === sectionLabels[0];
    window.scrollTo({
      top: scrollToTop ? 0 : sectionLocation,
      behavior: 'smooth',
    });
  };

  const renderSections = sections => {
    let menuSections = sections.map(element => {
      sectionLabels.push(element.viewId);

      return (
        <li className="nav-item" key={element.viewId} ref={element.viewId}>
          <button
            data-id="header-scroll-btn"
            data-scroll-to={element.viewId}
            tabIndex={0}
            className="scroll-to-btn px-sm-3 py-2"
            onClick={handleOnClick}
          >
            {element.label}
          </button>
        </li>
      );
    });
    return menuSections;
  };

  const menuOptions = renderSections(sections);
  return (
    <Scrollspy
      items={sectionLabels}
      currentClassName="active"
      className="header-scroller"
      offset={-200}
    >
      {menuOptions}
    </Scrollspy>
  );
};
HeaderScroll.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default HeaderScroll;
