import React from 'react';
import PropTypes from 'prop-types';

const Profile = ({ width, height, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.04575 6.9201C7.04575 9.64959 8.81631 11.863 11.0001 11.863C13.1839 11.863 14.9544 9.64959 14.9544 6.9201C14.9544 4.19062 13.1839 1.97717 11.0001 1.97717C8.81631 1.97717 7.04575 4.19062 7.04575 6.9201ZM5.06858 6.9201C5.06858 3.09823 7.72491 0 11.0001 0C14.2763 0 16.9316 3.09823 16.9316 6.9201C16.9316 8.56497 16.4398 10.0758 15.6183 11.2633C19.3059 12.3601 22 15.8003 22 19.8847V20.2021V20.2179C21.9951 21.2193 21.1083 21.9963 20.1069 21.9963H19.6333C19.6353 21.9914 19.6363 21.9874 19.6383 21.9835H2.36272C2.36314 21.9852 2.36374 21.9868 2.36436 21.9886C2.36521 21.991 2.36611 21.9935 2.36668 21.9963H1.91094C0.904556 21.9963 0.0158174 21.2183 0 20.2129V20.2021V19.8847C0 15.8004 2.69398 12.3603 6.38228 11.2633C5.56062 10.0758 5.06858 8.56499 5.06858 6.9201ZM8.04801 12.9234C4.6616 13.3657 2.0336 16.4141 1.98212 20.0063H20.0179C19.9664 16.4142 17.3394 13.3659 13.9526 12.9235C13.0831 13.5067 12.075 13.8402 11.0001 13.8402C9.92545 13.8402 8.91743 13.5066 8.04801 12.9234Z"
        fill="currentColor"
      />
    </svg>
  );
};

Profile.propTypes = {
  size: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Profile.defaultProps = {
  size: '22',
};

export default Profile;
