import { useState } from 'react';

const useInputValue = () => {
  const [inputValue, setInputValue] = useState(null);
  const handleInputChange = value => {
    setInputValue(value);
  };
  const getInputValue = currentField => {
    if (inputValue === null) {
      return currentField.defaultValue;
    } else {
      return inputValue;
    }
  };
  return [handleInputChange, getInputValue];
};

export default useInputValue;
