import styled, { css } from 'styled-components';
import FilterHeader from 'components/ui/FilterHeader';

export const ModalContainer = styled.div`
  .interview-modal__end-date .label-text {
    opacity: 0;
  }
`;

export const TimeSlotsContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    .timeslots-container__date {
      font-weight: ${theme.fontWeights.fontHeavy};
      margin-bottom: 1.125rem;
    }
    @media (min-width: ${theme.screenSizes.tablet}) {
      padding-top: 0;
      .timeslots-container__times {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }
  `,
);
export const getTimeSlotSharedStyles = theme => css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 2px solid ${theme.lightGray};
  border-radius: 1.5rem;
  background-color: ${theme.white};
  svg {
    color: ${theme.black};
    border-radius: 50%;
  }
`;
export const StyledTimeSlot = styled.div(
  ({ theme }) => css`
    ${getTimeSlotSharedStyles(theme)}
    max-width: 252px;
    color: ${theme.primaryBlue};
    background-color: ${theme.white};
    transition: all 250ms;
  `,
);

export const StyledFilterHeader = styled(FilterHeader)(
  ({ theme }) => css`
    margin-top: 1rem;
    padding-top: 1.5rem;
    font-weight: ${theme.fontNormal};
  `,
);

export const SelectButton = styled.button(
  ({ theme }) => css`
    cursor: pointer;
    &:focus,
    &:hover {
      svg {
        background-color: ${theme.lightGray};
      }
    }
  `,
);
