import React from 'react';

export const Bloomberg = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="138.744"
      height="25.725"
      viewBox="0 0 138.744 25.725"
      {...props}
    >
      <title>Bloomberg</title>
      <g transform="translate(0)">
        <path
          fill="currentColor"
          d="M124.593,20.794h5.013a2,2,0,0,0,2.094,1.268c1.679,0,2.258-1.047,2.258-2.59v-1.1h-.111a4.458,4.458,0,0,1-3.553,1.68c-3.2,0-5.977-2.369-5.977-7,0-4.242,2.286-7.547,5.729-7.547a4.328,4.328,0,0,1,3.994,2.066h.082V5.949h4.958V18.784a6.963,6.963,0,0,1-2.065,5.316,7.691,7.691,0,0,1-5.233,1.625c-3.939,0-6.693-1.625-7.189-4.93m9.585-7.987c0-1.846-.881-3.332-2.4-3.332-1.46,0-2.4,1.322-2.4,3.332,0,1.983.936,3.415,2.4,3.415,1.515,0,2.4-1.459,2.4-3.415m-15.4-4.324h.082a4.512,4.512,0,0,1,4.269-2.837,2,2,0,0,1,.854.138v4.544h-.11c-3.057-.551-4.847.909-4.847,4.242v6.2h-5.206V5.95h4.958ZM.337,0H10.528A6.9,6.9,0,0,1,14.99,1.322a5.109,5.109,0,0,1,1.818,4.1,3.964,3.964,0,0,1-2.452,3.994V9.5a5,5,0,0,1,3.387,4.985A6.009,6.009,0,0,1,15.485,19.5a7.887,7.887,0,0,1-4.764,1.267H.337Zm9.7,16.167a1.987,1.987,0,0,0,2.147-2.12A2.007,2.007,0,0,0,10,11.926H5.984v4.241ZM9.509,8.125a1.714,1.714,0,0,0,1.9-1.818A1.709,1.709,0,0,0,9.482,4.544h-3.5v3.58H9.509Z"
          transform="translate(-0.337)"
        />
      </g>
      <path
        fill="currentColor"
        d="M330.438,0h5.343V20.767h-5.343ZM336.8,13.385c0-4.682,3.03-7.849,7.711-7.849s7.657,3.167,7.657,7.849c0,4.71-2.975,7.849-7.657,7.849S336.8,18.1,336.8,13.385m10.08,0c0-2.589-.827-4.214-2.424-4.214s-2.368,1.625-2.368,4.214c0,2.617.771,4.242,2.368,4.242s2.424-1.625,2.424-4.242m5.9,0c0-4.682,3.03-7.849,7.711-7.849s7.657,3.167,7.657,7.849c0,4.71-2.975,7.849-7.657,7.849s-7.711-3.139-7.711-7.849m10.08,0c0-2.589-.827-4.214-2.424-4.214s-2.368,1.625-2.368,4.214c0,2.617.771,4.242,2.368,4.242s2.424-1.625,2.424-4.242m6.308-7.436h4.958V8.18h.082a4.745,4.745,0,0,1,4.462-2.671,4.14,4.14,0,0,1,3.994,2.561h.082a4.973,4.973,0,0,1,4.6-2.561c3.443,0,4.93,2.534,4.93,5.949v9.309h-5.206V12.394c0-1.515-.468-2.507-1.79-2.507-1.294,0-1.956,1.212-1.956,2.81v8.07h-5.2V12.394c0-1.515-.468-2.507-1.79-2.507-1.3,0-1.956,1.212-1.956,2.81v8.07h-5.206Zm29.474,12.89h-.055v1.928h-4.958V0h5.206V7.492h.082a4.925,4.925,0,0,1,4.214-1.983c3.718,0,5.866,3.36,5.866,7.822,0,5.04-2.341,7.932-6.2,7.932a4.681,4.681,0,0,1-4.16-2.424m5.068-5.563c0-2.4-.881-3.939-2.452-3.939-1.6,0-2.616,1.542-2.616,3.939,0,2.368,1.019,3.938,2.616,3.938s2.452-1.543,2.452-3.938m5.95.055c0-4.627,3.057-7.849,7.492-7.849a7.205,7.205,0,0,1,5.206,1.928c1.708,1.652,2.479,4.241,2.423,7.3h-9.942c.221,1.846,1.1,2.782,2.671,2.782a2,2,0,0,0,2.039-1.4h5.012c-.881,3.387-3.5,5.095-7.215,5.095-4.6,0-7.685-3.112-7.685-7.849m5.178-1.542h4.738c-.084-1.79-1.075-2.727-2.315-2.727-1.431,0-2.23,1.019-2.423,2.727"
        transform="translate(-312.093)"
      />
    </svg>
  );
};
