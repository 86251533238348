import React from 'react';
import PropTypes from 'prop-types';
import VideoTrack from './VideoTrack';
import AudioTrack from './AudioTrack';

const Publication = props => {
  switch (props.track.kind) {
    case 'video':
      return <VideoTrack {...props} />;
    case 'audio':
      return <AudioTrack {...props} />;
    default:
      return null;
  }
};

Publication.propTypes = {
  track: PropTypes.object.isRequired,
};

export default Publication;
