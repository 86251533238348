// shows elements that should only be visible to the user, returns a ref (element)
// that must be set to said element.
import { useEffect, useRef, useState } from 'react';
/**
 * Returns a Ref and a boolean if the element is on screen
 * @param {float} threshold percentage or array of percentages represented in decimal values (1 for 100% .5 50% default is 0)
 * that indicate what percentage of the element should be visible of the containing element in order to be considered visible
 * A value of 0 means it will be available as soon as a pixel is on the screen.
 */
const useNearScreen = (threshold = 0) => {
  const element = useRef(null);
  const [show, setShow] = useState(false);
  const options = { threshold: threshold };
  useEffect(() => {
    Promise.resolve(
      typeof window.IntersectionObserver !== 'undefined'
        ? window.IntersectionObserver
        : import('intersection-observer'),
    ).then(() => {
      const observer = new window.IntersectionObserver(function (entries) {
        const { isIntersecting } = entries[0];
        if (isIntersecting) {
          setShow(true);
        } else {
          setShow(false);
        }
      }, options);
      observer.observe(element.current);
      return () => observer.disconnect();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);
  return [show, element];
};

export default useNearScreen;
