import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputPassword from 'components/ui/inputs/InputPassword';
import ShowToggle from 'components/ui/ShowToggle';
import FieldsValidator from 'components/ui/FieldsValidator';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import { without } from 'utilities';
import Checkmark from 'components/ui/icons/Checkmark';
import InputHelper from 'components/ui/inputs/InputHelper';
import { MIN_PASSWORD_LENGTH } from 'consts';

const handleHideHelper = (show, toggle, timeout = false) => {
  if (timeout) {
    setTimeout(() => {
      show ? toggle() : null;
    }, 300);
  } else {
    toggle();
  }
};

const handleShowHelper = (show, toggle) => (show ? null : toggle());
const PasswordComplexityHelper = ({ fields, ...props }) => {
  const [strength, setStrength] = useState({
    number: false,
    minLength: false,
    hasUppercase: false,
  });

  const passwordStrengthValidator = (fields, done) => {
    const minLength = fields.password.value.length >= MIN_PASSWORD_LENGTH;
    setStrength({ minLength: minLength });
    if (!minLength) {
      return done('Invalid password.');
    }
    return done();
  };

  return (
    <ShowToggle>
      {(show, toggle) => (
        <FieldsValidator
          {...fields.passwordStrength}
          validator={passwordStrengthValidator}
        >
          {(trigger, errorText) => (
            <>
              <InputPassword
                field={fields.password}
                helpText="at least 10 characters"
                onChange={trigger}
                onClick={() => handleShowHelper(show, toggle)}
                onFocus={() => handleShowHelper(show, toggle)}
                onBlur={() => handleHideHelper(show, toggle)}
                validators={without.call(
                  fields.password.validators,
                  'presence',
                )}
                {...props}
              />

              <InputHelper
                show={show}
                className="w-100"
                title="We care about your security!"
                css={({ theme }) => `background-color: ${theme.cyanLight};}`}
              >
                <div className="pl-4 pr-4 pb-4">
                  <div className="">
                    <Checkmark checked={strength.minLength} />
                    &nbsp;We require that your password be at least 10
                    characters
                  </div>
                </div>
              </InputHelper>
              {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
            </>
          )}
        </FieldsValidator>
      )}
    </ShowToggle>
  );
};

PasswordComplexityHelper.propTypes = {
  fields: PropTypes.object.isRequired,
  helpText: PropTypes.string,
};

export default PasswordComplexityHelper;
