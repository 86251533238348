import React from 'react';
import PropTypes from 'prop-types';
import OurClientBuildingLogo from 'components/ui/icons/Logos/OurClientBuildingLogo';
import {
  StyledProjectLogo,
  StyledGenericInitialLogo,
  StyledCompanyLogoImage,
} from './styles';
import themed from 'components/hoc/themed';

const ProjectLogo = ({ companyName, logoUrl }) => {
  const companyFirstInitial = companyName ? companyName.charAt(0) : '';
  const logoClassNames =
    'd-flex w-100 h-100 align-items-center justify-content-center';

  return (
    <StyledProjectLogo className="overflow-hidden">
      {companyName && logoUrl ? (
        <div className={logoClassNames}>
          <StyledCompanyLogoImage
            alt={companyName}
            src={logoUrl}
            title={companyName}
          />
        </div>
      ) : companyName && !logoUrl ? (
        <StyledGenericInitialLogo
          className={`${logoClassNames} font-heavy`}
          title={companyName}
        >
          {companyFirstInitial}
        </StyledGenericInitialLogo>
      ) : (
        <OurClientBuildingLogo />
      )}
    </StyledProjectLogo>
  );
};

ProjectLogo.propTypes = {
  companyName: PropTypes.string,
  logoUrl: PropTypes.string,
};

export default themed(ProjectLogo);
