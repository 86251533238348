import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/ui/Accordion';
import Checkbox from 'components/ui/inputs/Checkbox';
import { camelToKebabCase } from 'utilities';
import FilterCount from './FilterCount';

const CheckboxListFilter = ({
  id,
  options,
  filters,
  aggregations,
  title,
  onChange,
}) => {
  const hasFilter = !!options.some(option => filters[option.id]);
  const checkboxes = options.map(option => {
    const itemId = `${id}-${camelToKebabCase(option.id)}`;
    const checked = !!filters[option.id];
    const count =
      aggregations &&
      aggregations.find(aggregation => aggregation.key === option.id);
    return (
      <Checkbox
        id={itemId}
        key={option.id}
        onChange={onChange}
        disabled={!checked && aggregations && (!count || !count.doc_count)}
        checked={checked}
        labelText={
          <>
            {option.label}
            {aggregations && (
              <FilterCount count={count ? count.doc_count : 0} />
            )}
          </>
        }
        name={option.id}
        value={option.value || 'on'}
        labelTestId={itemId}
      />
    );
  });

  if (title) {
    return (
      <Accordion title={title} defaultShow={hasFilter} dense>
        {checkboxes}
      </Accordion>
    );
  }

  return <>{checkboxes}</>;
};

CheckboxListFilter.propTypes = {
  id: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  aggregations: PropTypes.array,
};

export default CheckboxListFilter;
