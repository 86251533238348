import ReactiveRecord from 'reactiverecord';
import DemographicSurvey from './DemographicSurvey';

class DemographicSurveyShowSurvey extends DemographicSurvey {
  static routes = {
    index: ':prefix/moms/demographic_surveys/show_survey',
  };

  static store = {
    singleton: true,
  };

  static schema = {
    show_demographic_survey: Boolean,
  };
}

export default ReactiveRecord.model(
  'DemographicSurveyShowSurvey',
  DemographicSurveyShowSurvey,
);
