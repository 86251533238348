import React from 'react';
import Layout from 'components/containers/shared/Layout';
import PropTypes from 'prop-types';

const ErrorPage = ({ error }) => {
  const errorName = error && error.name;
  const knownErrors = {
    ChunkLoadError: 'Please check your connection and try again.',
  };
  const errorMessage = knownErrors[errorName];

  return (
    <Layout title="Error">
      <div className="grow-in-column">
        <h1 className="display-4 text-center mt-5">
          Sorry, your request resulted in an error
        </h1>
        {errorMessage && <p className="mt-5 text-center">{errorMessage}</p>}

        <p className="mt-5 text-center">
          Return to <a href="/">home</a>
        </p>
      </div>
    </Layout>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object,
};

export default ErrorPage;
