import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsProvider from 'components/shared/analytics/AnalyticsProvider';
import Modal from 'components/ui/Modal';
import Button from 'components/ui/buttons/Button';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import themed from 'components/hoc/themed';
import XCircle from 'components/ui/icons/XCircle';
import ShowToggle from 'components/ui/ShowToggle';

const DeleteResume = props => {
  return (
    <AnalyticsProvider disableTracking>
      <ShowToggle>
        {(show, toggle) => (
          <>
            <ButtonLink
              id="delete-resume"
              className="font-heavy"
              onClick={toggle}
            >
              <XCircle width={20} className="mr-2" /> {props.linkText}
            </ButtonLink>
            <Modal
              titleAlignment="text-left"
              title="Are you sure you would like to delete this resume?"
              size="md"
              onHide={toggle}
              show={show}
            >
              <div className="request-withdraw">
                <p className="mb-4 px-4">
                  Deleting your resume may affect the number and relevancy of
                  your job search matches.
                </p>
                <div className="input-container mt-2 px-4 text-right">
                  <Button
                    id="cancel-delete-resume-btn"
                    data-testid="cancel-delete-resume-btn"
                    secondary
                    noBorder
                    onClick={toggle}
                  >
                    No, keep my resume on file
                  </Button>
                  <Button
                    id="delete-resume-btn"
                    data-testid="delete-resume-btn"
                    className="ml-3"
                    onClick={props.handleRemove}
                  >
                    Yes, delete resume on file
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        )}
      </ShowToggle>
    </AnalyticsProvider>
  );
};

DeleteResume.propTypes = {
  linkText: PropTypes.string,
  handleRemove: PropTypes.func,
};
DeleteResume.defaultProps = {
  linkText: 'Delete Resume',
};

export default themed(DeleteResume);
