import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { useMediaSet } from 'use-media-set';
import { mediaSetQueries } from 'styleGuide';
import Heart from 'components/ui/icons/Heart';
import Button from 'components/ui/buttons/Button';
import useProjectSave from './useProjectSave';

const HeartIcon = styled.div`
  &.animate.filled {
    transform: scale(1);
    transform-origin: center;
    animation: heartbeat 0.5s ease-in-out;
  }

  &:focus {
    box-shadow: none;
  }

  @keyframes heartbeat {
    from {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-out;
    }
    25% {
      transform: scale(0.91);
      animation-timing-function: ease-in;
    }
    50% {
      transform: scale(0.98);
      animation-timing-function: ease-out;
    }
    75% {
      transform: scale(0.87);
      animation-timing-function: ease-in;
    }
  }
`;

const ProjectSave = ({
  projectId,
  projectPin,
  onProjectSave,
  iconOnly,
  className = '',
}) => {
  const { pin, toggle, isSaving } = useProjectSave(projectPin, projectId);
  const [isClicked, setIsClicked] = useState(false);
  const mediaStates = useMediaSet(mediaSetQueries);
  const icon = (
    <Heart
      filled={!!pin}
      size={mediaStates.has('mobile') && iconOnly ? 36 : 24}
    />
  );
  const size = iconOnly ? 'sm' : 'lg';
  const buttonText = pin ? 'Saved' : 'Save';

  const handleSave = async () => {
    if (isSaving) {
      return false;
    }
    const isAdd = !pin;
    await toggle();
    if (onProjectSave) {
      onProjectSave(projectId, isAdd);
    }
    setIsClicked(true);
  };

  return (
    <Button
      className={className}
      data-testid="project-save-button"
      id={`project-save-${projectId}`}
      secondary
      noBorder={iconOnly}
      iconOnly={iconOnly}
      inline={iconOnly}
      size={size}
      onClick={handleSave}
      disabled={isSaving}
    >
      <div className="d-flex align-items-center justify-content-center">
        <HeartIcon
          data-testid="project-save-icon"
          className={classNames({
            animate: isClicked,
            filled: !!pin,
          })}
        >
          {icon}
        </HeartIcon>
        <span className={iconOnly ? 'sr-only' : 'ml-2'}>{buttonText}</span>
      </div>
    </Button>
  );
};

ProjectSave.propTypes = {
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  onProjectSave: PropTypes.func,
  projectId: PropTypes.number.isRequired,
  projectPin: PropTypes.object,
};

ProjectSave.defaultProps = {
  iconOnly: false,
};

export default ProjectSave;
