import ReactiveRecord, { Model } from 'reactiverecord';

class NotificationGroup extends Model {
  static routes = {
    only: 'index',
  };
  static schema = {
    name: String,
    user_group: String,
    humanized_name: String,
  };
}

export default ReactiveRecord.model('NotificationGroup', NotificationGroup);
