import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { currencyFormatter } from '../../../utilities';

function Compensation(props) {
  const { is_full_time, amount, containerClasses } = props;
  const containerClassnames = containerClasses
    ? classNames('compensation-container rate', containerClasses)
    : 'compensation-container rate col-lg-4 text-left pl-0';
  const compensationClassnames = classNames({ highlight: props.highlight });
  return (
    <div className={containerClassnames}>
      <span className="secondary-info-label">
        {is_full_time ? 'Salary' : 'Billable Hourly Rate'}
      </span>
      <span className={compensationClassnames}>
        {amount
          ? currencyFormatter.format(amount) + (is_full_time ? '' : ' / hour')
          : 'N/A'}
      </span>
    </div>
  );
}

Compensation.propTypes = {
  is_full_time: PropTypes.bool,
  amount: PropTypes.number,
  highlight: PropTypes.bool,
  containerClasses: PropTypes.string,
};

Compensation.defaultProps = {
  is_full_time: false,
  highlight: false,
};

export default Compensation;
