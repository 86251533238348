import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StickySectionWrapper } from './styles';
import classNames from 'classnames';

const STICKY_CLASS_ENABLED_OFFSET = 75;
const HEADER_HEIGHT = 59; // height of our sticky navigation

const StickySection = ({
  children,
  topOffset,
  stickyClassEnabledOffset,
  ...props
}) => {
  const stickySectionRef = useRef(null);
  const [stickyClass, setStickyClass] = useState(null);

  useEffect(() => {
    if (stickySectionRef.current) {
      const handleStickySection = () => {
        const { y } = stickySectionRef.current.getBoundingClientRect();

        if (y < parseInt(stickyClassEnabledOffset)) {
          setStickyClass('sticky');
        } else {
          setStickyClass('not-sticky');
        }
      };

      document.addEventListener('scroll', handleStickySection, true);
      return () => {
        document.removeEventListener('scroll', handleStickySection, true);
      };
    }
  }, [stickySectionRef, stickyClassEnabledOffset]);

  return (
    <StickySectionWrapper
      css={{ top: `${topOffset}px` }}
      ref={stickySectionRef}
      className={classNames(props.className, stickyClass)}
    >
      {children}
    </StickySectionWrapper>
  );
};

StickySection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  topOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stickyClassEnabledOffset: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

StickySection.defaultProps = {
  topOffset: HEADER_HEIGHT,
  stickyClassEnabledOffset: STICKY_CLASS_ENABLED_OFFSET,
};

export default StickySection;
