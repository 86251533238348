import React from 'react';
import Button from 'components/ui/buttons/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
function UseLastUploaded({ useLastUploaded, ...props }) {
  const classes = classNames('btn-existing-file', {
    selected: useLastUploaded,
  });

  return (
    <Button
      data-id="use-last-uploaded-resume-btn"
      className={classes}
      {...props}
      type="button"
      css={`
        line-height: 24px;
        font-weight: normal;
      `}
    >
      {useLastUploaded && <div className="round-check-icon"></div>}
      Use {props.safeDisplayName}
    </Button>
  );
}

UseLastUploaded.propTypes = {
  useLastUploaded: PropTypes.bool,
  safeDisplayName: PropTypes.string,
  onClick: PropTypes.func,
};

export default UseLastUploaded;
