import React from 'react';
import PropTypes from 'prop-types';
import Honeybadger from 'honeybadger-js';

import {
  APPOINTMENT_TYPES,
  FULL_HOURS_ARRAY,
  SCHEDULING_PAGE_DURATIONS,
  SCHEDULING_PAGE_FUTURE_DAYS,
} from 'consts';
import { deleteSchedulingPage } from 'requests';

export const optionMap = (
  optionsArray,
  dataTestPrefix,
  idSelector = 1,
  valueSelector = 0,
  displaySelector,
) =>
  optionsArray.map(value => (
    <option
      key={value[idSelector]}
      value={value[idSelector]}
      data-testid={`${dataTestPrefix}-${value[idSelector]
        .toString()
        .toLowerCase()}`}
    >
      {displaySelector ? value[displaySelector] : value[valueSelector]}
    </option>
  ));

export const getAppointmentTypeOptions = () =>
  optionMap(APPOINTMENT_TYPES, 'appointment-type');

export const getSchedulingPageDurationOptions = () =>
  optionMap(SCHEDULING_PAGE_DURATIONS, 'scheduling-page-duration');

export const getSchedulingPageFutureDaysOptions = () =>
  optionMap(SCHEDULING_PAGE_FUTURE_DAYS, 'scheduling-page-future-days');

export const getFullHourOptions = () =>
  optionMap(FULL_HOURS_ARRAY, 'full-day-hours');

export const hasActiveInterviews = schedulingPage => {
  return (
    schedulingPage.active_interview_count > 0 ||
    schedulingPage.active_interview_request_count > 0
  );
};

export const DateAndTime = ({ month, day, time }) => {
  return (
    <div>
      <div className="month">{`${month} ${day}`}</div>
      <div className="time">{time}</div>
    </div>
  );
};

export const FullDateAndTime = ({ monthName, dayOfWeek, day, time, year }) => {
  return (
    <div>
      <div className="month">{`${dayOfWeek}, ${monthName} ${day}, ${year}`}</div>
      <div className="time">{time}</div>
    </div>
  );
};

export const TalentInfo = ({
  application_link,
  project_link,
  project_title,
  showTalentLink,
  talent_name,
}) => {
  return (
    <div className="talent-info ml-3">
      <div className="talent-name">
        {showTalentLink ? (
          <a data-id="view-application-btn" href={application_link}>
            {talent_name}
          </a>
        ) : (
          <div>{talent_name}</div>
        )}
      </div>
      <div className="position">
        for &nbsp;
        <a data-id="view-project-btn" href={project_link}>
          {project_title}
        </a>
      </div>
    </div>
  );
};

DateAndTime.propTypes = {
  month: PropTypes.string.isRequired,
  day: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
};

FullDateAndTime.propTypes = {
  monthName: PropTypes.string.isRequired,
  dayOfWeek: PropTypes.string.isRequired,
  day: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

TalentInfo.propTypes = {
  application_link: PropTypes.string,
  project_link: PropTypes.string,
  project_title: PropTypes.string.isRequired,
  showTalentLink: PropTypes.bool,
  talent_name: PropTypes.string.isRequired,
};

TalentInfo.defaultProps = {
  showTalentLink: true,
};

// Delete a scheduling page.
export const deletePage = async (
  schedulingPageId,
  setSubmitting,
  setDeletionError,
  afterDelete,
) => {
  setSubmitting(true);
  try {
    await deleteSchedulingPage({ id: schedulingPageId });
    setSubmitting(false);
    afterDelete();
  } catch (e) {
    Honeybadger.notify(
      `Nylas error deleting scheduling page with id ${schedulingPageId}: ${e}`,
    );
    setDeletionError(true);
    setSubmitting(false);
  }
};

export const YourEmail = ({ email }) => {
  return (
    <div>
      <div className="font-heavy">Your email</div>
      <div css={{ color: '#707070' }}> {email} </div>
    </div>
  );
};

YourEmail.propTypes = {
  email: PropTypes.string.isRequired,
};
