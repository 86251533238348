import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../icons/Checkmark';
import classNames from 'classnames';
import salesIcon from 'assets/icons/bar-chart-icon-purple.png';
import financeIcon from 'assets/icons/circle-dollar-sign-icon-purple.png';
import administrationIcon from 'assets/icons/thumb-tack-icon-purple.png';
import marketingIcon from 'assets/icons/sign-icon-purple.png';
import technologyIcon from 'assets/icons/computer-icon-purple.png';
import operationsIcon from 'assets/icons/playbook-diagram-icon-purple.png';
import communicationsAndPublicRelationsIcon from 'assets/icons/megaphone-icon-purple.png';
import legalIcon from 'assets/icons/scale-of-justice-icon-purple.png';
import humanResourcesIcon from 'assets/icons/chat-bubble-ellipses-icon-purple.png';

function getJobFunctionImgUrlById(id) {
  const jobFunctions = {
    1: salesIcon,
    2: financeIcon,
    3: administrationIcon,
    4: marketingIcon,
    5: technologyIcon,
    9: operationsIcon,
    11: communicationsAndPublicRelationsIcon,
    13: legalIcon,
    16: humanResourcesIcon,
  };
  return jobFunctions[id] || administrationIcon;
}

const SelectableButton = props => {
  const {
    action,
    classes,
    hidden,
    label,
    selected,
    showImages,
    showUnchecked,
    value,
  } = props;
  const buttonClassnames = classNames(
    'selectable-button btn btn-block btn-filter',
    { 'd-none': hidden, 'is-active': selected },
    classes,
  );
  const showIcon = !!showImages;
  const showSelected = showIcon ? false : selected;

  let checked = false;
  if (showSelected) {
    checked = true;
  } else if (showUnchecked) {
    checked = false;
  }
  const checkIcon = (
    <div className="d-flex align-items-center">
      <Checkmark checked={checked} />
    </div>
  );

  return (
    <button
      data-id="selectable-btn"
      className={buttonClassnames}
      onClick={action}
      type="button"
      data-testid={label}
    >
      {showIcon ? (
        <img
          src={getJobFunctionImgUrlById(value)}
          style={{ display: 'block', height: '25px', margin: '0 auto 12px' }}
          alt={label}
        />
      ) : (
        ''
      )}
      <span className="label-container">
        {showSelected || showUnchecked ? checkIcon : null}
        {label}
      </span>
    </button>
  );
};

SelectableButton.propTypes = {
  action: PropTypes.func.isRequired,
  classes: PropTypes.string,
  hidden: PropTypes.bool,
  label: PropTypes.any.isRequired,
  selected: PropTypes.bool.isRequired,
  showImages: PropTypes.bool,
  showUnchecked: PropTypes.bool,
  value: PropTypes.any,
};

SelectableButton.defaultProps = {
  showUnchecked: false,
};

export default SelectableButton;
