import styled from 'styled-components';
import { colors, fonts, fontWeight, screenSize } from 'styleGuide';

export const AvatarContainer = styled.div`
  padding: 0;
  width: 60px;
  height: 60px;
  border: 2px solid ${colors.blue.primary};
  overflow: hidden;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  color: ${colors.blue.primary};
  background-color: ${colors.white};
  .upload-overlay {
    opacity: 0;
    color: ${colors.blue.primary};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  svg {
    position: absolute;
  }
  &:hover,
  &:focus-within {
    .upload-overlay {
      opacity: 1;
    }
  }
  input[type='file'] {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${screenSize.tablet}) {
    width: 100px;
    height: 100px;
  }
`;
export const Avatar = styled.img`
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 1;
  width: 100%;
  height: 100%;
`;

export const InitialsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .initials {
    color: ${colors.gray.light};
    font-family: ${fonts.title};
    font-size: 60px;
    font-weight: ${fontWeight.heavy};
  }
  @media (max-width: ${screenSize.mobileL}) {
    .initials {
      font-size: ${({ theme }) => theme.fontSizes.xxl};
    }
  }
`;
