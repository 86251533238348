import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Validate } from 'reactiverecord';
import SyntheticInput from './SyntheticInput';
import { PreventableEvent, without } from 'utilities';

const SharedValueInput = forwardRef(
  ({ children, onChange, ...props }, forwardedRef) => (
    <Validate {...without.call(props, 'onBlur')} ref={forwardedRef}>
      {(validateProps, errorText, disabled) => (
        <SyntheticInput disabled={disabled} {...validateProps}>
          {(value, setValue) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const handleChange = useCallback(
              event => {
                if (event.target.value !== value) {
                  setValue(event.target.value);
                  setTimeout(() => {
                    if (onChange) {
                      onChange(new PreventableEvent(event.target.value));
                    }
                  }, 0);
                }
              },
              [setValue, value],
            );
            return children(
              { value, onChange: handleChange, disabled },
              errorText,
            );
          }}
        </SyntheticInput>
      )}
    </Validate>
  ),
);

SharedValueInput.displayName = 'SharedValueInput';
SharedValueInput.propTypes = {
  children: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default SharedValueInput;
