import React from 'react';
import { BID_DESCRIPTIONS } from 'consts';
import ReturnToJobsCTA from './ReturnToJobsCTA';

const ApplicationSaved = () => {
  return (
    <div className="text-md-left text-center px-2 px-md-3">
      <h2 className="title mb-4">Application saved!</h2>
      <p className="mb-4 pb-1">{BID_DESCRIPTIONS.saved}</p>
      <ReturnToJobsCTA />
    </div>
  );
};

export default ApplicationSaved;
