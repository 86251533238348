import React from 'react';
import XCircle from 'components/ui/icons/XCircle';

function XRemoveLink(props) {
  return (
    <a
      css={`
        white-space: nowrap;
        font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
        svg {
          display: inline-block;
          vertical-align: top;
        }
      `}
      {...props}
    >
      <XCircle width="18px" height="18px" /> Remove
    </a>
  );
}
XRemoveLink.defaultProps = {
  href: '#',
};

export default XRemoveLink;
