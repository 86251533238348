import React from 'react';

export default function MagnifyingGlass(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <circle
        cx="8.75"
        cy="8.75"
        r="7.75"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
      />
      <line
        x1="15"
        y1="15"
        x2="20"
        y2="20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
