import React from 'react';

import Icon from '../Icon';

const Archive = props => {
  const title = `Archive`;
  return (
    <Icon title={title} viewBox="0 0 24 23" {...props}>
      <path
        d="M22,0.977396512 C23.105,0.977396512 24,1.87339651 24,2.97739651 L24,2.97739651 L24,5.97739651 C24,7.08239651 23.105,7.97739651 22,7.97739651 L22.09,7.97339651 L22.092,8.008 L22.092,20.062 C22.092,21.171 21.193,22.07 20.083,22.07 L20.083,22.07 L4.009,22.07 C2.9,22.07 2,21.171 2,20.062 L2,20.062 L2,8.008 C2,7.99791818 2.00007438,7.98785372 2.00022262,7.97780713 C0.896,7.97739651 0,7.08239651 0,5.97739651 L0,5.97739651 L0,2.97739651 C0,1.87339651 0.896,0.977396512 2,0.977396512 L2,0.977396512 Z M20.083,8.008 L4.008,8.008 L4.008,20.062 L20.083,20.062 L20.083,8.008 Z M16,11 C16.552,11 17,11.448 17,12 C17,12.552 16.552,13 16,13 L16,13 L8,13 C7.448,13 7,12.552 7,12 C7,11.448 7.448,11 8,11 L8,11 Z M22,2.97739651 L2,2.97739651 L2,5.97739651 L22,5.97739651 L22,2.97739651 Z"
        fill={props.color}
      ></path>
    </Icon>
  );
};

Archive.propTypes = { ...Icon.propTypes };
Archive.defaultProps = {
  ...Icon.defaultProps,
};

export default Archive;
