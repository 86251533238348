import ReactiveRecord, { Model } from 'reactiverecord';

class VideoAccessToken extends Model {
  static routes = {
    create: ':prefix/video-tokens',
  };

  static schema = {
    identity: String,
    room_name: String,
    token: String,
  };
}

export default ReactiveRecord.model('VideoAccessToken', VideoAccessToken);
