import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Profile from 'components/ui/icons/Profile';

const Wrapper = styled.div(
  ({ theme, hasInitials, active }) => `
  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  ${
    hasInitials
      ? `
    position: relative;
    padding-top: 100%;
    background-color: ${theme.lightPink};
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px ${
      active ? `${theme.primaryBlue}` : `${theme.palePrimaryBlue}`
    } ;
    .avatar__initials {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${theme.fontSizes.xs};
      font-weight: ${theme.fontWeights.fontHeavy};
    }
  `
      : ''
  }
`,
);

const Avatar = ({ initials, src, active }) => (
  <Wrapper hasInitials={!src} active={active}>
    {src ? (
      <img src={src} alt={initials || ''} />
    ) : (
      <div className="avatar__initials">
        {initials || <Profile width={16} />}
      </div>
    )}
  </Wrapper>
);

Avatar.propTypes = {
  initials: PropTypes.string,
  src: PropTypes.string,
  active: PropTypes.bool,
};

export default Avatar;
