import React, { useContext, useState, useCallback } from 'react';
import { VideoContext } from './VideoProvider';
import styled from 'styled-components';
import VideoOn from 'components/ui/icons/VideoOn';
import VideoOff from 'components/ui/icons/VideoOff';

const VideoBtn = styled.button`
  width: 3rem;
  border: none;
  background: transparent;
`;

const ToggleVideoButton = () => {
  const { localVideoTrack } = useContext(VideoContext);
  const [enabled, setEnabled] = useState(localVideoTrack.isEnabled);

  const toggleVideo = useCallback(() => {
    if (localVideoTrack.isEnabled) {
      localVideoTrack.disable();
      localVideoTrack.stop();
    } else {
      localVideoTrack.restart();
      localVideoTrack.enable();
    }
    setEnabled(localVideoTrack.isEnabled);
  }, [localVideoTrack]);

  return (
    <VideoBtn id="toggle-video-btn" onClick={toggleVideo} enabled={enabled}>
      {enabled ? <VideoOn size="auto" /> : <VideoOff size="auto" />}
    </VideoBtn>
  );
};

export default ToggleVideoButton;
