import React from 'react';

const Timer = props => (
  <svg
    viewBox="0 0 24 22"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.418 5.327C5.61 1.693 9.834-.575 14.525.127c4.844.725 8.694 4.746 9.234 9.614.738 6.638-4.44 12.26-10.93 12.26-4.501 0-8.363-2.708-10.063-6.581l-.74 1.533a1.066 1.066 0 11-1.92-.926l1.883-3.903a1 1 0 011.335-.466l3.792 1.83a1.065 1.065 0 11-.926 1.92l-1.582-.764A8.989 8.989 0 0012.83 20a9 9 0 008.997-9.264c-.14-4.86-4.324-8.835-9.186-8.734a8.986 8.986 0 00-7.521 4.365.998.998 0 01-1.455.298 1.012 1.012 0 01-.246-1.338zm13.085 8.375l-3.19-2.225v-5.17a1 1 0 00-2 0v6l3.776 2.81a1 1 0 001.414-1.415z"
      fill="currentColor"
    />
  </svg>
);

export default Timer;
