import React, { useRef, useEffect, useState, forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { validated } from 'reactiverecord';
import { MultiSelectWrapper } from './supporting';
import Caret from 'components/ui/icons/Caret';
import PlusCircleO from 'components/ui/icons/Plus';
import X from '../../icons/X';
import ErrorMessage from '../../labels/ErrorMessage';
import Checkbox from 'components/ui/inputs/Checkbox';

// Will be used in 2.1 version when we will implement recommendations
// import { denormalize } from '../MultiSelect/supporting';

const MultiSelectCheckbox = forwardRef(
  (
    {
      options,
      // Will be used in 2.1 version when we will implement recommendations
      //pills,
      defaultValue,
      errorText,
      keysAccessor,
      labelText,
      valueAccessor,
      placeholder,
      max,
      disabled,
      ...props
    },
    // TODO: add RR validation
    // eslint-disable-next-line no-unused-vars
    forwardedRef,
  ) => {
    const [listOpened, setListOpened] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [optionsSelected, setOptionsSelected] = useState(defaultValue || []);

    const inputRef = useRef(null);
    const hiddenInputRef = useRef(null);
    const multiselectRef = useRef(null);

    const listOptions = keysAccessor(options)
      .filter(listFilter)
      .map(key => parseInt(key));

    // Will be used in 2.1 version when we will implement recommendations
    // const renderedPills = denormalize(pills);

    forwardedRef({
      /**
       * Form validation on submit
       */
      get isValid() {
        return optionsSelected.length > 0 ? optionsSelected : false;
      },
      /**
       * Gets value for onChange/onBlur event validation
       */
      get value() {
        return optionsSelected.length > 0 ? optionsSelected : false;
      },
    });

    /**
     * When defaultValue updated we need to update options accordingly
     */
    useEffect(() => {
      setOptionsSelected(defaultValue);
    }, [defaultValue]);

    /**
     * Adds a Listener that checks if the click was outside of the multiselect and closes the multiselect
     */
    useEffect(() => {
      const handleClickOutside = event => {
        if (
          multiselectRef.current &&
          !multiselectRef.current.contains(event.target)
        ) {
          closeList();
        }
      };

      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [multiselectRef]);

    const openList = () => {
      setListOpened(true);
    };

    const closeList = () => {
      setListOpened(false);
    };

    const showList = () => {
      return listOpened;
    };

    const addListItem = async id => {
      if (max && optionsSelected.length >= max) {
        return;
      }
      const list = [...optionsSelected, id];

      await saveOptionAndValidate(list);
    };

    const removeListItem = async id => {
      const list = optionsSelected.filter(value => id !== value);

      await saveOptionAndValidate(list);
    };

    const handleKeywordChange = e => {
      const searchTerm = e.target.value;
      setKeyword(searchTerm);
    };

    const handleHiddenInputChange = () => {
      if (props.onChange) {
        props.onChange(optionsSelected);
      }
    };

    /**
     * Sets the state, forges onChange event and triggers it on hidden input
     */
    async function saveOptionAndValidate(list) {
      const lastValue = hiddenInputRef.current.value;

      // set State is async - we need to wait before firing an event
      await setOptionsSelected(list);

      hiddenInputRef.current.value = list;
      const event = new Event('input', { bubbles: true });
      const tracker = hiddenInputRef.current._valueTracker;
      if (tracker) {
        tracker.setValue(lastValue);
      }
      hiddenInputRef.current.dispatchEvent(event);
    }

    /**
     * Filters value using the search keyword
     */
    function listFilter(key) {
      if (keyword) {
        return (
          valueAccessor(options, key)
            .toLowerCase()
            .indexOf(keyword.toLowerCase()) > -1
        );
      }
      return true;
    }

    /**
     * Calculates max height of the list to insert it as style
     * Minimal maxHeight of the menu is 100px
     */
    function getMenuMaxHeight() {
      const { bottom } = inputRef.current.getBoundingClientRect();
      if (window.innerHeight - bottom < 100) {
        return { maxHeight: `100px` };
      }
      return { maxHeight: `calc(100vh - ${bottom + 10}px)` };
    }

    function isDisabled() {
      return disabled || max <= optionsSelected.length;
    }

    return (
      <MultiSelectWrapper>
        {labelText}
        <div className="multiselect-container" ref={multiselectRef}>
          {optionsSelected.length > 0 && (
            <div className="counter">{optionsSelected.length}</div>
          )}
          <Caret
            className="caret"
            size="16px"
            direction={listOpened ? 'up' : 'down'}
            onClick={listOpened ? closeList : openList}
          />
          <input
            id="multiselect-input"
            data-testid="multiselect-input"
            className={classNames({
              opened: listOpened,
              'multiselect-input': true,
              'input-error': errorText,
            })}
            ref={inputRef}
            placeholder={placeholder}
            value={keyword}
            onChange={e => handleKeywordChange(e)}
            onClick={!listOpened ? openList : null}
          />
          <input
            id="hidden-input"
            className="multiselect-hidden-input"
            ref={hiddenInputRef}
            value={optionsSelected}
            onChange={handleHiddenInputChange}
            data-testid="hidden-input"
          ></input>
          {showList() && (
            <div className="list-wrapper">
              <div className="multiselect-list" style={getMenuMaxHeight()}>
                {listOptions.length > 0 ? (
                  listOptions.map((key, i) => {
                    return (
                      <div
                        role="button"
                        tabIndex={i}
                        className="multiselect-list-item"
                        data-testid={`multiselect-item-${key}`}
                        key={key}
                        onMouseDown={
                          optionsSelected.includes(key)
                            ? () => removeListItem(key)
                            : () => addListItem(key)
                        }
                      >
                        <Checkbox
                          className="list-check-box"
                          labelText={valueAccessor(options, key)}
                          checked={optionsSelected.includes(key)}
                          name="list-check-box"
                          id="list-check-box"
                          labelTestId="list-check-box"
                          value="on"
                          disabled={
                            !optionsSelected.includes(key) && isDisabled()
                          }
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="multiselect-list-item no-results">
                    No Results
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div
          data-testid="multiselect-label-container"
          className={classNames({
            'with-values': !!optionsSelected.length,
            'pill-label-container': true,
          })}
        >
          {optionsSelected.length
            ? optionsSelected
                .map((valueItem, i) => {
                  return (
                    <span
                      role="button"
                      tabIndex={i}
                      key={valueItem}
                      className={classNames({
                        selected: optionsSelected.includes(valueItem),
                        'pill-label': true,
                      })}
                      onClick={
                        optionsSelected.includes(valueItem)
                          ? () => removeListItem(valueItem)
                          : () => addListItem(valueItem)
                      }
                      onKeyDown={
                        optionsSelected.includes(valueItem)
                          ? () => removeListItem(valueItem)
                          : () => addListItem(valueItem)
                      }
                    >
                      {valueAccessor(options, valueItem)}
                      <div className="label-icon">
                        {optionsSelected.includes(valueItem) ? (
                          <X className="x-icon" />
                        ) : (
                          <PlusCircleO className="plus-icon" />
                        )}
                      </div>
                    </span>
                  );
                })
                .reduce((prev, next) => [prev, ' ', next])
            : null}
        </div>
        {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
      </MultiSelectWrapper>
    );
  },
);

MultiSelectCheckbox.propTypes = {
  options: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  // Will be used in 2.1 version when we will implement recommendations
  //pills: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  defaultValue: PropTypes.array,
  errorText: PropTypes.any,
  id: PropTypes.any,
  keysAccessor: PropTypes.func,
  labelText: PropTypes.any,
  /** Optionally set a max length to allow checked. Disables others once the max is reached */
  max: PropTypes.number,
  validating: PropTypes.bool,
  valueAccessor: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
MultiSelectCheckbox.defaultProps = {
  keysAccessor: object => Object.keys(object),
  placeholder: 'Search or select',
  valueAccessor: (object, key) => object[key],
};
MultiSelectCheckbox.idCounter = 0;
MultiSelectCheckbox.displayName = 'MultiSelectCheckbox';

export default validated(MultiSelectCheckbox);
