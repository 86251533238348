import * as Yup from 'yup';
import { countCharactersForQuillEditor } from 'utilities/formatting';
import {
  EMPLOYMENT_STATUS,
  MAX_HOURS_PER_WEEK,
  MAX_PROJECT_MONTHS,
  MIN_HOURS_PER_WEEK,
  MIN_HOURLY_RATE,
  MIN_PROJECT_MONTHS,
  PROJECT_REMOTE_OPTIONS,
} from 'consts';

export const roleValidations = Yup.object({
  project_title: Yup.string().required('Enter a job title'),
});

export const wageValidations = Yup.object({
  talent_rate_max: Yup.number().when('employment_status', {
    is: employment_status =>
      employment_status && employment_status !== EMPLOYMENT_STATUS.full_time,
    then: Yup.number()
      .required('Enter a maximum talent rate')
      .min(
        Yup.ref('talent_rate_min'),
        'The maximum needs to be higher than the minimum.',
      )
      .test(
        'talent_rate_max',
        'The pay range should be no more then 3x the starting salary.',
        (talentRateMaxValue, ctx) => {
          if (!talentRateMaxValue) {
            return false;
          }

          const talentRateMax =
            talentRateMaxValue && parseFloat(talentRateMaxValue);
          const talentRateMin = ctx.parent.talent_rate_min;

          return !(talentRateMin * 3 < talentRateMax);
        },
      ),
  }),

  talent_rate_min: Yup.number().when('employment_status', {
    is: employment_status =>
      employment_status && employment_status !== EMPLOYMENT_STATUS.full_time,
    then: Yup.number()
      .required('Enter a minimum talent rate')
      .test(
        'talent-rate-required',
        `$${MIN_HOURLY_RATE} an hour is the minimum rate to align with the experience of our candidates`,
        value => {
          if (!value) {
            return true;
          }
          return value && value > 0 ? value >= MIN_HOURLY_RATE : false;
        },
      ),
  }),

  salary_max: Yup.number().when('employment_status', {
    is: employment_status =>
      employment_status && employment_status === EMPLOYMENT_STATUS.full_time,
    then: Yup.number()
      .required('Enter a maximum annual salary')
      .min(
        Yup.ref('salary_min'),
        'The maximum needs to be higher than the minimum.',
      )
      .test(
        'salary_max',
        'The pay range should be no more then 3x the starting salary.',
        (salaryMaxValue, ctx) => {
          if (!salaryMaxValue) {
            return false;
          }

          const salaryMax = salaryMaxValue && parseFloat(salaryMaxValue);
          const salaryMin = ctx.parent.salary_min;
          return !(salaryMin * 3 < salaryMax);
        },
      ),
  }),

  salary_min: Yup.number().when('employment_status', {
    is: employment_status =>
      employment_status && employment_status === EMPLOYMENT_STATUS.full_time,
    then: Yup.number()
      .required('Enter a minimum annual salary')
      .test(
        'salary_min',
        `$${MIN_HOURLY_RATE} an hour is the minimum rate to align with the experience of our candidates`,
        (value, ctx) => {
          if (value && ctx.parent.hours_max) {
            const maxHours = ctx.parent.hours_max;
            const calculatedValue = value / 52 / maxHours;
            return calculatedValue >= MIN_HOURLY_RATE;
          }
          return false;
        },
      ),
  }),
  hours_max: Yup.number(),
  employment_status: Yup.string(),
});

export const typeValidations = Yup.object({
  employment_status: Yup.string().required('Choose a role type.'),
  budgeted_months: Yup.number()
    .when('employment_status', {
      is: employment_status =>
        employment_status && employment_status !== EMPLOYMENT_STATUS.full_time,
      then: Yup.number()
        .required('Enter estimated number of months')
        .min(
          MIN_PROJECT_MONTHS,
          `The estimated number of months must be at least ${MIN_PROJECT_MONTHS} month${
            MIN_PROJECT_MONTHS > 1 ? 's' : ''
          }`,
        )
        .max(
          MAX_PROJECT_MONTHS,
          `The estimated number of months can't be greater than ${MAX_PROJECT_MONTHS}`,
        ),
    })
    .nullable(true),
  hours_max: Yup.number()
    .required('Enter hours per week')
    .max(
      MAX_HOURS_PER_WEEK,
      `The maximum is ${MAX_HOURS_PER_WEEK} hours per week`,
    )
    .min(
      MIN_HOURS_PER_WEEK,
      `The minimum is ${MIN_HOURS_PER_WEEK} hours per week`,
    ),
  start_date: Yup.date().required('Enter an ideal start date'),
});

export const locationValidations = Yup.object({
  location: Yup.string().when('project_location', {
    is: project_location =>
      project_location === PROJECT_REMOTE_OPTIONS.partially ||
      project_location === PROJECT_REMOTE_OPTIONS.inOffice,
    then: Yup.string()
      .required('Enter a zip code')
      .matches(/^\d{5}(?:-\d{4})?$/, 'Enter one valid ZIP code'),
  }),
  travel_percentage: Yup.string()
    .required('Choose a travel percentage')
    .nullable(true),
  remote_percentage: Yup.string()
    .when('project_location', {
      is: project_location =>
        project_location === PROJECT_REMOTE_OPTIONS.partially,
      then: Yup.string().required('Select the number of remote days.'),
    })
    .nullable(true),
});

export const descriptionValidations = Yup.object({
  description: Yup.string()
    .required('A description is required.')
    .test(
      'description',
      'Description should be at least 500 characters.',
      value => {
        return countCharactersForQuillEditor(value) >= 500;
      },
    ),
  expertises_attributes: Yup.array(),
  screening_questions_attributes: Yup.array(),
});

export const experienceValidations = Yup.object({
  preferred_experience_level: Yup.string().required('Choose a seniority level'),
  capabilities_category_id: Yup.number().required(
    'Choose a superpower for your ideal candidate',
  ),
  capability_ids: Yup.array().min(1, 'Choose up to 3 capabilities'),
  industry_id: Yup.number().required('Choose an industry'),
});

export const projectCancelReasonsValidations = Yup.object({
  reason: Yup.string().required('A reason is required'),
});

export const miniProjectForm = Yup.object({
  project_title: Yup.string().required('Enter a job title'),
  preferred_experience_level: Yup.string().required('Choose a seniority level'),
});
