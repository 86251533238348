import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { without } from 'utilities';
import PropTypes from 'prop-types';

export const PortalContext = React.createContext({ isPortalDescendant: false });

class Portal extends Component {
  constructor(props, context) {
    super(props, context);
    this.root = document.createElement('DIV');
    Object.assign(this.root, without.call(props, 'children', 'style'));

    if (props.style) {
      Object.assign(this.root.style, props.style);
    }

    document.body.appendChild(this.root);
  }

  componentWillUnmount() {
    if (document.body.contains(this.root)) {
      document.body.removeChild(this.root);
    }
  }

  render() {
    return (
      <PortalContext.Provider value={{ isPortalDescendant: true }}>
        {ReactDOM.createPortal(this.props.children, this.root)}
      </PortalContext.Provider>
    );
  }
}

Portal.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
};

export default Portal;
