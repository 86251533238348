/*
This is here until we transition away from Haml
*/

import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function queryCliented(WrappedComponent) {
  const { name, displayName } = WrappedComponent;
  function QueryClientedComponent(props) {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          useErrorBoundary: true,
        },
      },
    });

    return (
      <QueryClientProvider client={queryClient}>
        <WrappedComponent {...props} />
      </QueryClientProvider>
    );
  }

  QueryClientedComponent.displayName = `QueryCliented(${displayName || name})`;
  return QueryClientedComponent;
}

export default queryCliented;
