/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef, useState, useCallback, forwardRef } from 'react';
import { StyleSheetManager } from 'styled-components';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Frame = forwardRef(({ children, head, ...props }, forwardedRef) => {
  const [stateIframe, setStateIFrame] = useState(null);
  const _setInitialContent = useRef(false);

  const handleRef = useCallback(
    iframe => {
      if (forwardedRef) {
        forwardedRef(iframe);
      }
      if (!_setInitialContent.current) {
        _setInitialContent.current = true;
        iframe.contentDocument.open('text/html', 'replace');
        iframe.contentDocument.write(
          '<!DOCTYPE html><html><head></head><body></body></html>',
        );
        iframe.contentDocument.close();
      }
      setStateIFrame(iframe);
    },
    [forwardedRef],
  );

  let iframeDocument, iframeHead, iframeBody;
  if (stateIframe) {
    iframeDocument = stateIframe.contentDocument;
    iframeHead = iframeDocument.head;
    iframeBody = iframeDocument.body;
  }

  return (
    <>
      <iframe ref={handleRef} {...props} />
      {children && iframeBody && (
        <StyleSheetManager target={iframeHead}>
          <>{createPortal(children, iframeBody)}</>
        </StyleSheetManager>
      )}
      {iframeHead && createPortal(<>{head}</>, iframeHead)}
    </>
  );
});

Frame.displayName = 'Frame';
Frame.propTypes = {
  children: PropTypes.any,
  head: PropTypes.any,
};

export default Frame;
