import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/projects';
const memberRoute = `${collectionRoute}/:slug`;

class MomProject extends Model {
  static routes = {
    index: collectionRoute,
    show: memberRoute,
  };

  static schema = {
    activated_at: String,
    activated_recently: Boolean,
    company_name: String,
    created_at: String,
    employer_brief_description: String,
    employer_industry: String,
    employer_logo_url: String,
    employment_status_human_readable: String,
    full_time: Boolean,
    id: Number,
    industry_name: String,
    is_match: Boolean,
    location_remote_or_city_state_list: Array,
    marketplace_compensation: String,
    pin: Object,
    project_title: String,
    slug: String,
    talent_bid_status: String,
    talent_bid_applied_on_behalf: Boolean,
    updated_recently: Boolean,
  };

  get location() {
    return this.location_remote_or_city_state_list.length > 1
      ? 'Multiple'
      : this.location_remote_or_city_state_list.join();
  }
}

export default ReactiveRecord.model('MomProject', MomProject);
