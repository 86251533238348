import React from 'react';
import PropTypes from 'prop-types';
import { StyledInterviewee, IntervieweeAvatar } from './styles';
import { TalentInfo } from './supporting';

const WithdrawnInterviewee = ({
  application_link,
  project_title,
  talent_name,
}) => {
  return (
    <StyledInterviewee
      className={
        'interviewee withdrawn box-shadow-sm d-flex flex-column justify-content-between'
      }
    >
      <div className="row m-0">
        <IntervieweeAvatar className={'col-lg-4 col-md-4 col-12 p-0'}>
          <TalentInfo
            application_link={application_link}
            project_title={project_title}
            talent_name={talent_name}
            showTalentLink={false}
          />
        </IntervieweeAvatar>
        <div className="col-lg-8 col-md-8 col-12">
          <div>The candidate withdrew their application.</div>
          <div>The interview has been canceled.</div>
        </div>
      </div>
    </StyledInterviewee>
  );
};

WithdrawnInterviewee.propTypes = {
  application_link: PropTypes.string,
  project_title: PropTypes.string.isRequired,
  talent_name: PropTypes.string.isRequired,
};

export default WithdrawnInterviewee;
