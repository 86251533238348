import React from 'react';
import PropTypes from 'prop-types';
import { useAppSettings } from 'utilities/hooks';
import PDFPreviewResume from './PDFPreviewResume.js';

const PreviewResume = props => {
  const { loaded } = useAppSettings();

  return (
    <>
      {loaded && (
        <PDFPreviewResume
          context={props.context}
          uploadedResumeUrl={props.uploadedResumeUrl}
        />
      )}
    </>
  );
};

PreviewResume.propTypes = {
  context: PropTypes.string,
  uploadedResumeUrl: PropTypes.string,
};

export default PreviewResume;
