import React from 'react';

export const TechCrunch = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="169.816"
      height="24.281"
      viewBox="0 0 169.816 24.281"
      {...props}
    >
      <title>TechCrunch</title>
      <rect
        fill="currentColor"
        width="15.874"
        height="8.094"
        transform="translate(31.749 0)"
      />
      <path
        fill="currentColor"
        d="M17.459,15.7v8.094H25.4V39.976h7.937V23.789H41.27V15.7Z"
        transform="translate(-17.459 -15.695)"
      />
      <path
        fill="currentColor"
        d="M57.842,45.609H81.654V37.515H65.78V29.422H57.842Z"
        transform="translate(-34.03 -21.328)"
      />
      <path
        fill="currentColor"
        d="M124.42,26.075H112.759v3.363h3.9V40h3.86V29.438h3.9Z"
        transform="translate(-56.566 -19.955)"
      />
      <path
        fill="currentColor"
        d="M136.67,31.609c-2.746,0-4.776,1.851-4.776,5.452,0,3.2,1.731,5.433,4.875,5.433a8.111,8.111,0,0,0,4.259-1.194l-1.373-2.288a5.454,5.454,0,0,1-2.706.736,1.651,1.651,0,0,1-1.771-1.672h6.129a6.786,6.786,0,0,0,.02-.7C141.327,33.5,139.535,31.609,136.67,31.609Zm-1.492,4.239c.12-1.035.577-1.592,1.393-1.592,1.054,0,1.413.538,1.592,1.592Z"
        transform="translate(-64.418 -22.226)"
      />
      <path
        fill="currentColor"
        d="M155.908,39.589c-.975,0-1.472-.856-1.472-2.507s.458-2.567,1.373-2.567c.776,0,1.114.438,1.612,1.353L159.888,34a4.374,4.374,0,0,0-4.02-2.388c-3.343,0-4.975,2.209-4.975,5.413,0,3.523,1.831,5.472,4.9,5.472,1.851,0,2.985-.7,4.2-2.487l-2.268-1.771C157.063,39.211,156.585,39.589,155.908,39.589Z"
        transform="translate(-72.215 -22.226)"
      />
      <path
        fill="currentColor"
        d="M176,28.937a3.993,3.993,0,0,0-2.627.916V25.1l-3.581,1.432V39.6h3.581v-5.97c0-1.313.458-1.672,1.214-1.672.736,0,1.194.359,1.194,1.712V39.6h3.6V33.215C179.385,30.469,178.29,28.937,176,28.937Z"
        transform="translate(-79.97 -19.553)"
      />
      <path
        fill="currentColor"
        d="M196.261,36.708c-1.672,0-2.348-1.691-2.348-3.82,0-2.209.7-3.821,2.308-3.821,1.274,0,1.751.816,2.248,2.109l3.5-1.373c-.956-2.627-2.308-4.1-5.731-4.1-3.741,0-6.368,2.706-6.368,7.184,0,4.119,2.209,7.184,6.348,7.184a5.992,5.992,0,0,0,5.79-3.6l-3.264-1.732C197.992,36.052,197.475,36.708,196.261,36.708Z"
        transform="translate(-88.21 -19.802)"
      />
      <path
        fill="currentColor"
        d="M217.719,32.585v-.757h-3.582V42.276h3.582V36.584c0-1.274.537-1.692,1.412-1.692a2.256,2.256,0,0,1,1.672.876l.836-3.721a3.2,3.2,0,0,0-3.92.538Z"
        transform="translate(-98.167 -22.226)"
      />
      <path
        fill="currentColor"
        d="M235.752,37.95c0,1.314-.458,1.672-1.194,1.672s-1.193-.358-1.193-1.711V31.98h-3.6v6.389c0,2.746,1.094,4.278,3.383,4.278a3.936,3.936,0,0,0,2.606-.915v.7h3.6V31.98h-3.6Z"
        transform="translate(-104.579 -22.378)"
      />
      <path
        fill="currentColor"
        d="M256.657,31.609a4,4,0,0,0-2.626.916v-.7h-3.582V42.276h3.582v-5.97c0-1.313.458-1.672,1.214-1.672.736,0,1.193.359,1.193,1.712v5.93h3.6V35.887C260.041,33.141,258.946,31.609,256.657,31.609Z"
        transform="translate(-113.068 -22.226)"
      />
      <path
        fill="currentColor"
        d="M275.308,39.589c-.975,0-1.472-.856-1.472-2.507s.457-2.567,1.373-2.567c.776,0,1.114.438,1.612,1.353L279.287,34a4.374,4.374,0,0,0-4.02-2.388c-3.343,0-4.975,2.209-4.975,5.413,0,3.523,1.831,5.472,4.9,5.472,1.85,0,2.985-.7,4.2-2.487l-2.269-1.771C276.462,39.211,275.984,39.589,275.308,39.589Z"
        transform="translate(-121.211 -22.226)"
      />
      <path
        fill="currentColor"
        d="M295.4,28.937a3.992,3.992,0,0,0-2.626.916V25.1l-3.583,1.432V39.6h3.583v-5.97c0-1.313.458-1.672,1.214-1.672.736,0,1.194.359,1.194,1.712V39.6h3.6V33.215C298.783,30.469,297.688,28.937,295.4,28.937Z"
        transform="translate(-128.966 -19.553)"
      />
    </svg>
  );
};
