import React, { useMemo } from 'react';
import TrapFocus from '../TrapFocus';
import Portal from '../Portal';
import styled, { css } from 'styled-components';
import CloseButton from 'components/ui/buttons/CloseButton';
import ModalIn from './ModalIn';
import PropTypes from 'prop-types';
import { without } from 'utilities';

const StyledPortal = styled(Portal)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.black.alpha(0.4)};
  z-index: 206; /* Current Rails dialog is set to 205 */
  overflow-y: scroll;
  ${({ narrow, theme }) =>
    narrow
      ? ` padding: 1em; `
      : `
        @media (min-width: ${theme.screenSizes.tablet}) {
          padding: 1em;
        }
      `}
`;

const StyledTrapFocus = styled(props => (
  <TrapFocus
    {...without.call(props, 'medium', 'large', 'xlarge', 'narrow', 'center')}
  />
))`
  padding: 0 1rem 1rem;
  position: relative;
  width: 100%;
  max-width: 320px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14902);
  ${({ narrow, theme }) =>
    narrow
      ? ` border-radius: 5px; `
      : `
          @media (max-width: ${parseInt(theme.screenSizes.tablet) - 1}px) {
            border-radius: 0;
            min-height: 100%;
          }
          @media (min-width: ${theme.screenSizes.tablet}) {
            padding: 0 45px 52px;
          }
        `}
  ${({ center, theme }) =>
    center &&
    css`
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      @media (max-width: ${parseInt(theme.screenSizes.tablet) - 1}px) {
        width: 90%;
      }
    `}
  margin: 0 auto auto;
  background-color: ${({ theme }) => theme.white};
  ${({ medium, theme }) =>
    medium && `max-width: ${theme.screenSizes.tabletXS};`}
  ${({ large, theme }) => large && `max-width: ${theme.screenSizes.tablet};`}
  ${({ xlarge }) => xlarge && `max-width: 992px;`}
  .dialog-title {
    line-height: 1.25;
    text-align: center;
    font-size: 2.25rem;
    font-family: ${({ theme }) => theme.fonts.title};
  }
`;

const StyledInner = styled.div`
  padding: 65px 0 0;
`;
function Dialog({
  show,
  title,
  children,
  showDialogCloser,
  closeModalOnBlur,
  ...props
}) {
  const id = useMemo(() => `dialog-${Dialog.idCounter++}`, []);
  if (title) {
    if (!Object.prototype.hasOwnProperty.call(props, 'aria-labelledby')) {
      props['aria-labelledby'] = id;
    }
  }
  let blurBehavior = closeModalOnBlur ? 'HIDE' : 'PREVENT';
  if (show) {
    return (
      <StyledPortal narrow={props.narrow}>
        <ModalIn />
        <StyledTrapFocus blurBehavior={blurBehavior} {...props}>
          <StyledInner>
            {title ? (
              <>
                <div className="dialog-title" id={id}>
                  {title}
                </div>
              </>
            ) : null}
            {children}
            {showDialogCloser && (
              <CloseButton
                onClick={props.onHide}
                id={`close-button-${Dialog.idCounter++}`}
              />
            )}
          </StyledInner>
        </StyledTrapFocus>
      </StyledPortal>
    );
  }
  return null;
}
Dialog.idCounter = 0;
Dialog.propTypes = {
  /** Controls whether the dialog is shown */
  show: PropTypes.bool.isRequired,
  /** Optionally increase the size of the dialog */
  medium: PropTypes.bool,
  large: PropTypes.bool,
  xlarge: PropTypes.bool,
  /** Adding a title also shows a dialog header */
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  /** Function should update parent state to change the show prop. */
  onHide: PropTypes.func.isRequired,
  /** Keeps a narrow margin for the dialog at larger viewports */
  narrow: PropTypes.bool,
  /** Center modal on the screen */
  center: PropTypes.bool,
  closeModalOnBlur: PropTypes.bool,
  showDialogCloser: PropTypes.bool,
};
Dialog.defaultProps = {
  showDialogCloser: true,
  closeModalOnBlur: true,
  center: false,
};

export default Dialog;
