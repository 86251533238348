import React from 'react';

import Icon from '../Icon';

const Cloud = props => {
  return (
    <Icon title="Cloud" viewBox="0 0 34 18" {...props}>
      <g transform="translate(-15 -15)">
        <path fill="none" d="M15 15h33v18H15z" />
        <clipPath id="a">
          <path d="M15 15h33v18H15z" />
        </clipPath>
        <g clipPath="url(#a)">
          <g transform="translate(15 15)">
            <circle cx="17" cy="7" r="7" fill="#fff" />
            <circle cx="7" cy="11" r="7" fill="#fff" />
            <g fill="#fff" stroke="#fff" transform="translate(19 3)">
              <ellipse cx="5.207" cy="5.037" rx="4.707" ry="4.537" />
              <ellipse cx="8.793" cy="9.963" rx="4.707" ry="4.537" />
            </g>
            <path d="M7 18h21V9.956L15.435 8l-7.517 4.712L7 18z" fill="#fff" />
          </g>
        </g>
      </g>
    </Icon>
  );
};

Cloud.propTypes = { ...Icon.propTypes };
Cloud.defaultProps = { ...Icon.defaultProps };

export default Cloud;
