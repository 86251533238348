import React from 'react';
import PropTypes from 'prop-types';

const DownloadIconBlue = ({ size = '60' }, props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 60 60"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="30" cy="30.008" r="30" fill="#B0EEE3" />
        <path
          fill="#464A59"
          fillRule="nonzero"
          d="M41.567 12.554c1.605 0 2.909 1.303 2.909 2.909v27.636c0 1.608-1.304 2.91-2.91 2.91H18.365c-1.608 0-2.91-1.302-2.91-2.91V20.557c0-4.42 3.583-8.003 8.003-8.003zm0 2.909h-6.513c-3.389.038-8.197 3.937-9.145 7.933h12.056c.401 0 .727.325.727.727 0 .401-.326.727-.727.727l-12.342.001c-.325.713-1.15 1.127-1.964.93l-.931-.227-.002-.007-4.362-1.076v18.628h23.203V15.463zm-3.602 19.704c.401 0 .727.326.727.728 0 .401-.326.727-.727.727h-16c-.368 0-.673-.274-.721-.629l-.007-.098c0-.402.326-.728.728-.728h16zm0-5.818c.401 0 .727.326.727.728 0 .401-.326.727-.727.727h-16c-.368 0-.673-.274-.721-.629l-.007-.098c0-.402.326-.728.728-.728h16zm-9.976-13.885l-3.804-.001c-3.215 0-5.821 2.607-5.821 5.821v.19l4.498 1.111c.477-2.894 2.495-5.363 5.127-7.121z"
        />
      </g>
    </svg>
  );
};

DownloadIconBlue.propTypes = {
  size: PropTypes.string,
};

export default DownloadIconBlue;
