import React, { useState } from 'react';
import ReactiveRecord from 'reactiverecord';
import PropTypes from 'prop-types';
import { Form } from 'reactiverecord';
import { STATES } from 'consts';
import { without, optionBuilder } from 'utilities';
import { Row, Col } from 'components/ui/grid';
import FormWrapper from 'components/ui/FormWrapper';
import Input from 'components/ui/inputs/Input';
import Select from 'components/ui/inputs/Select';
import Checkbox from 'components/ui/inputs/Checkbox';
import RichTextEditor from 'components/ui/inputs/RichTextEditor';
import withTooltip from 'components/ui/withTooltip';
import InfoCircle from 'components/ui/icons/InfoCircle';
import ApproximateDateInput from 'components/ui/inputs/ApproximateDateInput';

const LinkWithTooltip = withTooltip('a');

const defaultOption = <option value="">Select One</option>;
const stateOptions = [['International', 'International']]
  .concat(STATES)
  .map(optionBuilder);
const toolbarOptions = [['bold', 'italic', { list: 'bullet' }]];
const formatOptions = ['bold', 'italic', 'list'];

const ExperienceForm = ({ onCancel, ...props }) => {
  const [isCurrent, setIsCurrent] = useState(null);

  const getIsCurrent = currentField => {
    if (isCurrent !== null) {
      return isCurrent;
    }
    return currentField.defaultValue;
  };

  const handleCurrentClick = e => {
    setIsCurrent(e.target.checked);
  };

  return (
    <Form {...props}>
      {fields => (
        <FormWrapper fields={fields} onCancel={onCancel}>
          {props.for.career_pause ? null : (
            <>
              <Input data-testid="job-title" {...fields.job_title} />
              <Row>
                <Col className="mt-2" col={12}>
                  <Input data-testid="company-name" {...fields.company_name} />
                </Col>
              </Row>
              <Row>
                <Col className="mt-2" col={6}>
                  <Input data-testid="company-city" {...fields.city} />
                </Col>
                <Col className="mt-2" col={6}>
                  <Select data-testid="company-state" {...fields.state}>
                    {defaultOption}
                    {stateOptions}
                  </Select>
                </Col>
              </Row>
            </>
          )}
          <ApproximateDateInput {...fields.start_date} />
          {getIsCurrent(fields.current) ? null : (
            <ApproximateDateInput {...fields.end_date} />
          )}
          {props.for.career_pause ? null : (
            <Checkbox
              {...without.call(fields.current, 'defaultValue')}
              checked={!!getIsCurrent(fields.current)}
              onClick={handleCurrentClick}
            />
          )}
          <Row>
            <Col className="mt-3" col={12}>
              {props.for.career_pause ? null : (
                <div className="pull-right">
                  <LinkWithTooltip
                    trigger="CLICK"
                    title={
                      <ul
                        css={`
                          min-width: 300px;
                          padding-left: 1.25em;
                          margin: 0;
                        `}
                      >
                        <li>Keep it concise with bullet-points</li>
                        <li>
                          Use action statements like &ldquo;Lead
                          XYZ&hellip;&rdquo; or &ldquo;Streamlined
                          XYZ&hellip;&rdquo;
                        </li>
                        <li>
                          Showcase your impact by including data and numbers
                        </li>
                        <li>
                          List a project you&apos;re proud of or a game changer
                          you spearheaded
                        </li>
                      </ul>
                    }
                    href="#"
                    css={`
                      display: block;
                      margin-bottom: 7px;
                      vertical-align: middle;
                      line-height: 1;
                    `}
                  >
                    Writing tips <InfoCircle width="22" />
                  </LinkWithTooltip>
                </div>
              )}
              <RichTextEditor
                {...fields.description}
                testId="description"
                format={formatOptions}
                labelText={
                  props.for.career_pause
                    ? 'Description'
                    : fields.description.labelText
                }
                toolbar={toolbarOptions}
              />
            </Col>
          </Row>
        </FormWrapper>
      )}
    </Form>
  );
};

ExperienceForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  for: PropTypes.instanceOf(ReactiveRecord.model('Experience')).isRequired,
};

export default ExperienceForm;
