import React, { createContext, useEffect, useReducer, useContext } from 'react';
import { without } from 'utilities';
import PropTypes from 'prop-types';
import config from './filtersConfig';
import ReactiveRecord from 'reactiverecord';
import { UTM_PARAMS } from 'consts';

const FilterContext = createContext({});

const filterReducer = (state, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case 'clearAll':
      newState = {
        ...(state.sort ? { sort: state.sort } : {}),
      };
      return newState;
    case 'removeFilter':
      return without.call(newState, 'page', action.filter);
    case 'setFilter':
      newState[action.filter] = action.value;
      return without.call(newState, 'page');
    case 'setPage':
      newState.page = action.page;
      return newState;
    default:
      throw new Error('action not supported');
  }
};

const allowedFilters = [
  'page',
  'sort',
  'keyword',
  'filter_by',
  'location',
  'remote',
  'office',
  'combination',
  'contract_with_benefits',
  'contract_without_benefits',
  'permanent',
  'maternityship',
  'junior',
  'midlevel',
  'senior',
  'oneToTwenty',
  'twentyToThirty',
  'thirtyToFourty',
  'fourty',
  'minimum_salary_cents',
  'minimum_hourly_rate_cents',
  'competitive',
  'hourly_rate_cents',
  'salary_cents',
];

const sanitizeFilters = filters => {
  const newFilters = {};
  for (var filterName in filters) {
    if (
      filterName.startsWith('capabilities_category_ids') ||
      allowedFilters.includes(filterName) ||
      UTM_PARAMS.includes(filterName)
    ) {
      newFilters[filterName] = filters[filterName];
    }
  }
  return newFilters;
};

const FilterProvider = ({ initialFilters, children }) => {
  const [filters, dispatch] = useReducer(
    filterReducer,
    sanitizeFilters(initialFilters),
  );
  const [filtersConfig, setFiltersConfig] = React.useState(config);

  const setPage = page => {
    dispatch({ type: 'setPage', page });
  };

  const clearAll = () => {
    dispatch({ type: 'clearAll' });
  };

  const setFilter = (filter, value) => {
    if (value) {
      dispatch({ type: 'setFilter', filter: filter, value });
    } else {
      dispatch({ type: 'removeFilter', filter });
    }
  };

  const removeFilter = filter => {
    dispatch({ type: 'removeFilter', filter });
  };

  useEffect(() => {
    ReactiveRecord.model('CapabilitiesCategory')
      .all()
      .then(capabilitiesCategories =>
        setFiltersConfig({
          ...config,
          capabilitiesCategories: capabilitiesCategories.map(
            capabilitiesCategory => ({
              id: `capabilities_category_ids[${capabilitiesCategory.id}]`,
              label: capabilitiesCategory.title,
              value: capabilitiesCategory.id,
            }),
          ),
        }),
      );
  }, []);

  return (
    <FilterContext.Provider
      value={{
        filters,
        filtersConfig,
        setFilter,
        removeFilter,
        setPage,
        clearAll,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

FilterProvider.propTypes = {
  initialFilters: PropTypes.object,
  children: PropTypes.node,
};

function useFilterContext() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilterContext must be used within a CountProvider');
  }
  return context;
}

export { FilterProvider, filterReducer, FilterContext, useFilterContext };
