import React from 'react';

// Steps will appear in this order
export const VALID_STEPS = {
  ROLE: 'role',
  LOCATION: 'location',
  EXPERIENCE: 'experience',
  TYPE: 'type',
  WAGE: 'wage',
  DESCRIPTION: 'description',
  REVIEW: 'review',
};

export const VALID_ACTIONS = {
  BACK: 'back',
  SAVE_CONTINUE: 'saveAndContinue',
  SAVE: 'save',
};

export const VALID_REVIEW_ACTIONS = {
  SAVE_FOR_LATER: 'save_for_later',
  SUBMIT_FOR_APPROVAL: 'submit_for_approval',
};

export const FIRST_STEP = VALID_STEPS.ROLE;

export const LAST_STEP = VALID_STEPS.DESCRIPTION;

export const SENIORITY_OPTIONS = {
  JUNIOR: 'jr',
  MIDLEVEL: 'mid',
  SENIOR: 'sr',
};

export const STEP_FIELDS = {
  role: ['project_title'],
  location: [
    'project_location',
    'remote_percentage',
    'location',
    'travel_percentage',
  ],
  experience: [
    'preferred_experience_level',
    'capabilities_category_id',
    'capability_ids',
    'industry_id',
  ],
  type: [
    'employment_status',
    'hours_max',
    'hours_min',
    'budgeted_months',
    'start_date',
  ],
  //TODO: remove deprecated values for budget and talent_rate
  wage: [
    'budget',
    'talent_rate',
    'talent_rate_max',
    'talent_rate_min',
    'salary_max',
    'salary_min',
    'competitive',
    'employment_status',
    'hours_max',
  ],
  description: [
    'description',
    'expertises',
    'expertises_attributes',
    'screening_questions',
  ],
  review: ['status'],
};

export const FOOTER_MESSAGES = {
  error: 'Unable to save changes',
  latestSaved: 'Latest changes saved',
  unsaved: 'Unsaved changes',
  prompt: 'You still have unsaved changes, are you sure you want to leave?',
};

export const STATUS_LABELS = {
  active: {
    label: 'Active',
    submitMessage: 'Your job post is approved and live on the marketplace.',
  },
  awaiting_verification: {
    label: 'Active',
    submitMessage: (
      <div>
        Complete the next step to get your job online by&nbsp;
        <a
          className="font-heavy color-darkGray"
          data-id="banner-verify-company"
          href="/employer/verification/step-one"
        >
          verifying your information
        </a>
        .
      </div>
    ),
  },
  draft: { label: 'Draft' },
  cancelled: { label: 'Cancelled' },
  interviewing: { label: 'Active' },
  candidate_submitted: { label: 'Active' },
  screening: { label: 'Active' },
  vms_pause: { label: 'Active' },
  pending_approval: {
    label: 'Submitted for review',
    submitMessage:
      'Your job is in review! We’ll reach out if there are any questions.',
  },
  pending_removal: {
    label: 'Archived',
  },
};
