import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import InterviewModalHeader from 'components/employer/interviews/InterviewModalHeader';
import AuthorizeNylasButton from 'components/employer/interviews/RequestInterview/AuthorizeNylasButton';
import stored from 'components/hoc/stored';
import Button from 'components/ui/buttons/Button';
import { Row, Col } from 'components/ui/grid';
import { BenefitsContent } from './supporting';

const ContentWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    .button-group {
      gap: 0.5rem;
      button {
        width: 100%;
        @media (min-width: ${theme.screenSizes.tablet}) {
          width: auto;
        }
      }
      @media (min-width: ${theme.screenSizes.tablet}) {
        gap: unset;
      }
    }
  `,
);

const SelectSchedulingType = ({
  bid,
  handleCancel,
  handleSelectManual,
  project,
  user,
}) => {
  return (
    <ContentWrapper>
      <InterviewModalHeader
        mom={bid.mom}
        heading={`${
          bid.appointment_id ? 'Re' : 'S'
        }chedule an interview the easy way`}
      />
      <>
        <p className="mt-2 mb-4">
          Set up your interview hours and we&apos;ll send an invite.
        </p>
        <BenefitsContent />
      </>

      <Row
        className="button-group mt-4 mx-0 flex-column flex-md-row justify-content-between"
        data-id="select-type-buttons"
      >
        <Col col="12" md="4" className="order-md-3">
          <AuthorizeNylasButton
            bid={bid}
            data-id="select-nylas-scheduler"
            isReschedule={false}
            project={project}
            user={user}
          />
        </Col>

        <Col col="12" md="4" className="order-md-2">
          <Button
            data-id="select-manual-scheduler"
            onClick={handleSelectManual}
            secondary
          >
            Set manual times
          </Button>
        </Col>
        <Col col="12" md="4" className="order-md-1">
          <Button data-id="go-back" onClick={handleCancel} secondary>
            Go back
          </Button>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

SelectSchedulingType.propTypes = {
  bid: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSelectManual: PropTypes.func.isRequired,
  project: PropTypes.object,
  user: PropTypes.object,
};

export default stored(SelectSchedulingType);
