import React from 'react';
import { Box } from 'components/ui/Box';
import WindowWarning from 'components/ui/icons/WindowWarning';
import { URLS } from 'consts';

const VideoNotFound = () => {
  return (
    <Box
      className="mx-3 mx-md-auto text-center mb-5"
      css={`
        margin-top: 8.625rem;
        max-width: 759px;
      `}
      size="lg"
    >
      <WindowWarning className="mb-4 mt-5" />
      <h1 className="mb-4">
        It looks like this interview room has restricted access.
      </h1>
      <p>
        Review the URL to ensure there are no extra spaces or characters. If
        this link is correct, please reach out to Customer Support and
        we&apos;ll get this resolved.
      </p>
      <a className="font-heavy" href={URLS.customerSupport}>
        Contact Customer Support
      </a>
    </Box>
  );
};

export default VideoNotFound;
