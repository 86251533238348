/* eslint-disable react/prop-types */
import React from 'react';
import { EXPERIENCE_LEVEL_ARRAY } from 'consts';
import DollarSign from 'components/ui/icons/DollarSign';
import NumberFormat from 'react-number-format';
import { Label } from './ProjectSummaryStyles';
/**
 * Returns Yes/No/Partially + percentage for a project given the remote conditions
 * @param {Bool} remote_ok Project's `remote_ok`
 * @param {string} remotePercentage Project's remote_percentage
 */
export const getPreferredRemoteDisplayValue = (isRemote, remotePercentage) => {
  let remoteLabel = '';
  if (remotePercentage > 0 && remotePercentage < 100) {
    remoteLabel = 'Partially';
  } else if (isRemote) {
    remoteLabel = 'Yes';
  } else {
    remoteLabel = 'No';
  }
  return (
    <>
      Work remote:&nbsp;
      <strong>
        {remoteLabel}{' '}
        {remotePercentage && remotePercentage > 0
          ? `(${remotePercentage}%)`
          : ''}
      </strong>
    </>
  );
};

/**
 * Gets the experience level as a label i.e. Junior, Mid-Level, Senior
 * @param {number} id Project's `preferred_experience_level`
 */
export const getExperienceLevelDisplayValue = id => {
  let displayValue = EXPERIENCE_LEVEL_ARRAY.find(value => {
    return value[1] === id;
  });
  return displayValue[0];
};

/**
 * Splits every line break into its own paragraph
 * @param {string} description Project's `description`
 */
export const getDescriptionParagraphs = description => {
  let paragraphs = description.split('\n');
  let paragraphElements;
  if (paragraphs && paragraphs.length > 0) {
    paragraphElements = paragraphs.map((value, index) => {
      if (value && value.length > 0) {
        return <p key={index}>{value}</p>;
      }
    });
  }
  return paragraphElements;
};

export const Compensation = ({ project }) => {
  const compensationMin = project.full_time
    ? project.salary_min
    : project.talent_rate_min;
  const compensationMax = project.full_time
    ? project.salary_max
    : project.talent_rate_max;
  const noCompensationPresent =
    project.competitive || project.compensation_amount === 'Competitive';
  return (
    <>
      <div className="mb-2 justify-self-end">
        <DollarSign width={20} height={20} className="mr-2" />
        {noCompensationPresent ? (
          'Competitive'
        ) : (
          <>
            <NumberFormat
              value={compensationMin}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
            &nbsp;-&nbsp;
            <NumberFormat
              value={compensationMax}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </>
        )}
        {!project.full_time && !noCompensationPresent
          ? '/hour'
          : project.full_time && !noCompensationPresent
          ? '/year'
          : null}
      </div>
      <p>
        <i className="label-default font-sm">
          Exact compensation may vary based on skills, experience, and location.
        </i>
      </p>
    </>
  );
};

export const ProjectSummaryFlexFactors = ({ project, talentDisplay }) => {
  const schedule =
    (project.hours_max + project.hours_min) / 2 + ' hours / week';
  return (
    <div>
      <Label>Flex factors</Label>
      <div>
        Work type:&nbsp;
        <strong>
          {project.full_time
            ? 'Permanent'
            : project.maternityship
            ? 'Contract to Hire'
            : 'Contract'}
          &nbsp;
          {project.bill_method && !talentDisplay
            ? `(${project.bill_method})`
            : ''}
        </strong>
      </div>
      <div>
        Schedule: <strong>{schedule}</strong>
      </div>
      {getPreferredRemoteDisplayValue(
        project.remote_ok,
        project.remote_percentage,
      )}
      <div>
        Travel:{' '}
        <strong>
          {project.travel_required
            ? 'Up to ' + project.travel_percentage + '%'
            : 'No'}
        </strong>
      </div>
    </div>
  );
};

export const Industry = ({ industry }) => {
  const projectIndustryName = industry
    ? industry.name
    : 'No industry experience selected';
  return (
    <div>
      <Label>Preferred industry experience</Label>
      <span className="project-skill-item">{projectIndustryName}</span>
    </div>
  );
};

/**
 * Returns string based on how many applicants a current project has
 * @param {number} count Projects applicant count
 */
export const projectApplicants = (count = 0) => {
  if (count < 10) {
    return 'Less than 10 applicants';
  } else if (count >= 10 && count < 50) {
    return `${count} Applicants`;
  } else {
    return '50+ applicants';
  }
};
