import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/buttons/Button';
import Close from 'components/ui/icons/Close';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.darkGray};
  &:not(:disabled):hover {
    background-color: transparent;
  }
`;

const buttonSize = {
  sm: 14,
  md: 20,
};

const CloseButton = ({ size, color, ...props }) => {
  return (
    <StyledButton iconOnly id="close-button" {...props}>
      <Close size={buttonSize[size]} color={color || 'currentColor'} />
    </StyledButton>
  );
};

CloseButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(buttonSize)),
};

CloseButton.defaultProps = {
  size: 'md',
};

export default CloseButton;
