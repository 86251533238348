import React, { useState } from 'react';
import {
  QuestionsSection,
  SectionHeaders,
  Header,
  QuestionsRow,
  InnerRow,
  QuestionsCol,
  IntroRow,
  AboutResumeRev,
  PaleBlueBox,
  OwnYourStorySection,
  StyledResumeRevBlueCurveSVG,
  StyledYellowDots,
  RallySection,
  StyledYellowRallyDots,
  FiveDayChallengeSection,
} from './styles';
import Layout from 'components/containers/shared/Layout';
import { Col, Row } from 'react-bootstrap';
import DownloadIconBlue from 'components/ui/icons/DownloadIconBlue';
import StarCheckBlue from 'components/ui/icons/StarCheckBlue';
import ProfileBlue from 'components/ui/icons/ProfileBlue';
import { YellowDots } from '../../../../assets/svg';

import {
  resume,
  rallyLogo,
  videoCard1,
  videoCard2,
  videoCard3,
  videoCard4,
  videoCard5,
  videoCard6,
  videoCard7,
  videoCard8,
  videoCard9,
  videoCard10,
} from '../../../../assets/images';

const ResumeRev = () => {
  const videos = [
    {
      id: '1',
      image: videoCard1,
      url: 'https://youtube.com/embed/kXERt5Bwxdk',
    },
    {
      id: '2',
      image: videoCard2,
      url: 'https://youtube.com/embed/zlAIKH40VRU',
    },
    {
      id: '3',
      image: videoCard3,
      url: 'https://www.youtube.com/embed/rehARN9kyqo',
    },
    {
      id: '4',
      image: videoCard4,
      url: 'https://youtube.com/embed/1aCaoOFBDWA',
    },
    {
      id: '5',
      image: videoCard5,
      url: 'https://youtube.com/embed/Fwuxgm2Qm0A',
    },
    {
      id: '6',
      image: videoCard6,
      url: 'https://www.youtube.com/embed/i0MCsOFOrJY',
    },
    {
      id: '7',
      image: videoCard7,
      url: 'https://youtube.com/embed/q17gPjEOOPs',
    },
    {
      id: '8',
      image: videoCard8,
      url: 'https://www.youtube.com/embed/3Kw2u4JWovw',
    },
    {
      id: '9',
      image: videoCard9,
      url: 'https://www.youtube.com/embed/HR63o-C3yas',
    },
    {
      id: '10',
      image: videoCard10,
      url: 'https://youtube.com/embed/LXu0PmFKqHQ',
    },
  ];

  const [isActiveVideo, setActiveVideo] = useState(null);

  return (
    <Layout title="Resume Rev">
      <Row>
        <IntroRow>
          <Header>
            <div className="blue-bar">
              <h1>Resume Rev</h1>
            </div>
            <h2>
              Say{' '}
              <span role="img" aria-label="waving hand">
                👋
              </span>{' '}
              to a new solution to help you own your story.
            </h2>
          </Header>
          <StyledYellowDots src={YellowDots} />
          <StyledResumeRevBlueCurveSVG />
        </IntroRow>
        <AboutResumeRev>
          <Col>
            <SectionHeaders>Put Your Best Self Forward</SectionHeaders>
            <p>
              The struggle to concisely communicate your story on paper is real.
              You&#39;re WAY more than your resume — but building a good one is
              often the first step to starting the next chapter of your career
              journey.
            </p>
            <p>
              So we wondered: what would happen if The Mom Project could make
              that step simpler, enjoyable even?
            </p>
            <p>
              Resume Rev was designed to do just that -- help you own your story
              in real life AND on paper. Your resume isn&#39;t you. But it does
              open the door so that companies can get to know you. And we know
              you have so much to share.
            </p>
          </Col>
        </AboutResumeRev>
        <OwnYourStorySection>
          <SectionHeaders>Ready to Own Your Story?</SectionHeaders>
          <InnerRow>
            <Col className="col-lg-6">
              <img src={resume} alt="Example Resume" />
            </Col>
            <Col className="col-lg-6">
              <Row>
                <Col className="col-lg-3 col-md-2 step-icon">
                  <ProfileBlue />
                </Col>
                <Col className="col-lg-9 col-md-10 step-copy">
                  <h5>STEP ONE</h5>
                  <p>Log into (or create) your profile with The Mom Project.</p>
                </Col>
              </Row>
              <Row>
                <Col className="col-lg-3 col-md-2 step-icon">
                  <StarCheckBlue />
                </Col>
                <Col className="col-lg-9 col-md-10 step-copy">
                  <h5>STEP TWO</h5>
                  <p>
                    Thoughtfully complete your profile to 100%. You&#39;ll be
                    guided with tips and questions to ensure your resume
                    reflects you as a professional and a person.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="col-lg-3 col-md-2 step-icon">
                  <DownloadIconBlue />
                </Col>
                <Col className="col-lg-9 col-md-10 step-copy">
                  <h5>STEP THREE</h5>
                  <p>
                    Download your beautifully formatted resume and start
                    applying for jobs on The Mom Project -- or anywhere else!
                  </p>
                </Col>
              </Row>
            </Col>
          </InnerRow>
          <PaleBlueBox />
          <Row className="btn-row">
            <Col>
              <a href="/mom/profile" className="btn tmp-btn primary sm">
                Let&#39;s Do This
              </a>
            </Col>
          </Row>
          <FiveDayChallengeSection>
            <Col>
              <p>
                <span className="font-bold">
                  P.S. Need an extra resume boost?
                </span>
                &nbsp;Sign up for our&nbsp;
                <a href="https://work.themomproject.com/resume-rev-5-day-challenge-evergreen">
                  5-Day Resume Challenge
                </a>
                . We&#39;ll send a daily challenge directly to your inbox for
                five straight days to help you level up your resume.
              </p>
            </Col>
          </FiveDayChallengeSection>
        </OwnYourStorySection>
        <QuestionsSection>
          <SectionHeaders>Have Questions?</SectionHeaders>
          <p>We&#39;ve got you! The Mom Project tackles your top ones here. </p>
          <QuestionsRow>
            {videos.map(video => {
              return (
                <QuestionsCol
                  value={video.id}
                  key={video.id}
                  onClick={() => {
                    setActiveVideo(video.id);
                  }}
                  className={`${
                    isActiveVideo === video.id ? 'active' : 'in-active'
                  }`}
                >
                  {isActiveVideo === video.id ? (
                    <iframe
                      src={video.url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="active"
                      title={video.id}
                    ></iframe>
                  ) : (
                    <img
                      src={video.image}
                      className="in-active"
                      alt="Resume Rev Info Video"
                    />
                  )}
                </QuestionsCol>
              );
            })}
          </QuestionsRow>
        </QuestionsSection>
        <RallySection>
          <StyledYellowRallyDots src={YellowDots} />
          <InnerRow>
            <Col>
              <img src={rallyLogo} alt="Example Resume" />
            </Col>
            <Col>
              <SectionHeaders>Looking For More Support?</SectionHeaders>
              <p>
                You may be a great candidate for RALLY. Apply now for The Mom
                Project&#39;s community-driven program designed to connect you
                with another mom (or mom advocate!) eager to share their
                expertise and help you navigate your job search.
              </p>
              <a
                href="https://work.themomproject.com/letsrally"
                className="btn tmp-btn primary sm"
              >
                Let&#39;s RALLY!
              </a>
            </Col>
          </InnerRow>
        </RallySection>
      </Row>
    </Layout>
  );
};

export default ResumeRev;
