import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { stripHTMLTags } from 'utilities';
import { highlightKeyword } from 'utilities/formatting';
import { List, ListItem } from 'components/ui/inputs/MultiSelect/supporting';

const ListAbsolute = styled(List)`
  position: absolute;
  width: 100%;
  box-shadow: ${({ isMobileContainer }) => (isMobileContainer ? 'none' : null)};
`;

const StyledListItem = styled(ListItem)`
  padding: 0.75rem 1rem;
  &.active {
    background-color: ${({ theme }) => theme.primaryBlue};
    color: ${({ theme }) => theme.white};
  }
`;

const ListHeader = styled.div`
  padding: 0.75rem 1rem 0.675rem;
  color: ${({ theme }) => theme.mediumGray};
`;

const AutosuggestList = React.forwardRef(
  (
    {
      isMobileContainer,
      keyword,
      onSelect,
      resultsHeader,
      selectedIndex,
      suggestions,
    },
    ref,
  ) => {
    const format = suggestion => {
      const suggestionFormatted = stripHTMLTags(suggestion);
      return highlightKeyword(suggestionFormatted, keyword, true);
    };

    return (
      <ListAbsolute isMobileContainer={isMobileContainer} ref={ref}>
        {resultsHeader && (
          <ListHeader className="font-sm" id="list-header">
            {resultsHeader}
          </ListHeader>
        )}
        {Array.from(suggestions).map((suggestion, index) => (
          <StyledListItem
            className={index === selectedIndex ? 'active' : ''}
            tabIndex="0"
            key={index}
            onClick={e => onSelect(e, suggestion)}
            dangerouslySetInnerHTML={{ __html: format(suggestion) }}
          ></StyledListItem>
        ))}
      </ListAbsolute>
    );
  },
);

AutosuggestList.propTypes = {
  isMobileContainer: PropTypes.bool,
  keyword: PropTypes.string,
  onSelect: PropTypes.func,
  resultsHeader: PropTypes.string,
  selectedIndex: PropTypes.number,
  suggestions: PropTypes.oneOfType([
    PropTypes.instanceOf(Set),
    PropTypes.instanceOf(Array),
  ]),
};

AutosuggestList.displayName = 'AutosuggestList';

export default AutosuggestList;
