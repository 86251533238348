import React from 'react';

const TimerFilled = props => {
  return (
    <svg
      viewBox="0 0 15 16"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" fill="none">
        <g transform="translate(-266.000000, -931.000000)">
          <g transform="translate(266.000000, 931.000000)">
            <g>
              <g transform="translate(0.814453, 0.378906)">
                <path d="M6.76957463,3.53892532 C3.77134738,3.53892532 1.33221757,5.97805513 1.33221757,8.97628238 C1.33221757,11.9745096 3.77134738,14.4136394 6.76957463,14.4136394 C9.76762829,14.4136394 12.2069317,11.9745096 12.2069317,8.97628238 C12.2069317,5.97822871 9.76762829,3.53892532 6.76957463,3.53892532 Z M6.76957463,13.3490804 L6.76957463,8.97628238 L6.76957463,4.60348432 C9.18457698,4.60348432 11.1423727,6.56128002 11.1423727,8.97628238 C11.1423727,11.3912847 9.18457698,13.3490804 6.76957463,13.3490804 Z"></path>
                <path
                  d="M7.40018654,2.37646785 L7.40018654,1.72138132 L8.07002727,1.72138132 L8.07002727,0 L5.22159856,0 L5.22159856,1.72138132 L5.89143929,1.72138132 L5.89143929,2.37646785 C4.99941765,2.47783789 4.16120545,2.75608477 3.41134488,3.17597197 L2.84790797,2.33185809 L1.9659539,2.92046393 L2.53112659,3.76718149 C0.99217663,4.98535777 0,6.86539014 0,8.97593522 C-9.25013861e-16,12.6408788 2.9813901,15.6220953 6.64581292,15.6220953 C10.3102357,15.6220953 13.2916258,12.6408788 13.2916258,8.97628238 C13.2916258,5.56719403 10.710335,2.75278677 7.40018654,2.37646785 Z M6.64581292,14.4136394 C3.64758567,14.4136394 1.20845586,11.9745096 1.20845586,8.97628238 C1.20845586,5.97822871 3.64758567,3.53892532 6.64581292,3.53892532 C9.64386658,3.53892532 12.08317,5.97805513 12.08317,8.97628238 C12.08317,11.9745096 9.64386658,14.4136394 6.64581292,14.4136394 Z"
                  fill="currentColor"
                ></path>
              </g>
            </g>
            <circle
              fill="currentColor"
              cx="7.375"
              cy="9.375"
              r="4.375"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TimerFilled;
