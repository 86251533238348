import { useState, useEffect } from 'react';
import ReactiveRecord from 'reactiverecord';

const useAppSettings = () => {
  const AppSettings = ReactiveRecord.model('AppSettings');
  const [stateAppSettings, setStateAppSettings] = useState({ loaded: false });
  useEffect(() => {
    AppSettings.allCached().then(response => {
      setStateAppSettings({ ...response, loaded: true });
    });
  }, [AppSettings]);
  return stateAppSettings;
};

export default useAppSettings;
