import React from 'react';

export default function CareerPause(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215 24" {...props}>
      <path
        fill="currentColor"
        d="M81.6 10.56l-4-5.22h-3v13.39h1.07V6.29h1l4.83 6.16 4.89-6.16h1v12.44h1V5.34h-3zm-17-3.79a5.17 5.17 0 105.16 5.17 5.17 5.17 0 00-5.15-5.17zm0 9.32A4.14 4.14 0 1168.73 12a4.15 4.15 0 01-4.14 4.09zm0-11a6.9 6.9 0 106.89 6.89A6.91 6.91 0 0064.61 5zM70.5 12a5.92 5.92 0 11-5.94-6 5.92 5.92 0 015.94 6zm6-4.66v11.39h1.11v-8.32l4 5.05 4-5.05v8.32h1.17V7.24l-5.18 6.39zM98.12 5.2h-5v13.46h2.62v-5.07h2.81a4.11 4.11 0 004-4.21 2.54 2.54 0 000-.39 4.11 4.11 0 00-4.43-3.79zm1.77 4.5a1.87 1.87 0 01-2.09 1.63h-2.07V7.48h2.34a1.9 1.9 0 011.84 2c0 .03-.01.12-.02.22zM0 7.69h4.25v11h2.64v-11h4.25V5.2H0zm42.52-.39v11.43h1.11v-8.32l4 5.05 4-5.05v8.32h1.17V7.24l-5.13 6.39zm-20.4 3.31h-5.87V5.2h-2.62v13.46h2.62v-5.58h5.87v5.58h2.63V5.2h-2.63zm9 2.47h5.26v-2.34H31.1V7.67h5.81V5.2h-8.43v13.46h8.43v-2.47H31.1zm16.49-2.52l-4-5.22h-3v13.39h1.07V6.29h1l4.83 6.16 4.9-6.16h1v12.44h1V5.34h-3zm67.13-1.23v-.35a4 4 0 00-4.28-3.78h-5.26v13.46h2.64v-5.18h1.38l2.64 5.18h2.92l-2.89-5.5a3.83 3.83 0 002.83-3.83zM110 11.22h-2.14V7.48H110a1.7 1.7 0 01.45 0 1.88 1.88 0 01-.45 3.74zM203 0a12 12 0 1012 12 12 12 0 00-12-12zm-1.25 16.23a1.54 1.54 0 01-3.08 0V7.77a1.54 1.54 0 013.08 0zm5.57 0a1.54 1.54 0 11-3.08 0V7.77a1.54 1.54 0 113.08 0zm-37.1-8.54h4.26v11h2.63v-11h4.26V5.2h-11.15zm-7.66 8.68h-.34a4.14 4.14 0 01-3.83-4.42 4.15 4.15 0 014.14-4.5 3.51 3.51 0 013.68 2.72l2.47-.78a5.92 5.92 0 00-6.17-4.46h-.28a6.8 6.8 0 00-6.57 7v.47a6.7 6.7 0 006.89 6.51 6.11 6.11 0 006.23-4.57l-2.45-.73a3.75 3.75 0 01-3.77 2.76zM123.72 4.92h-.36a6.78 6.78 0 00-6.53 7 .81.81 0 000 .22 6.92 6.92 0 1013.83-.22v-.38a6.8 6.8 0 00-6.94-6.62zm0 11.48a2.54 2.54 0 01-.39 0 4.15 4.15 0 01-3.81-4.47 4.14 4.14 0 014.2-4.48h.4a4.16 4.16 0 013.81 4.47v.4a4.15 4.15 0 01-4.21 4.08zm14.85-1.89a1.7 1.7 0 010 .39 1.82 1.82 0 11-3.62-.45v-.77h-2.52v.86a4.68 4.68 0 000 .53 4.14 4.14 0 004.4 3.86h.17a4.31 4.31 0 004.18-4.42V5.2h-2.62zm6.23 4.15h8.42v-2.47h-5.8v-3.11h5.26v-2.34h-5.26V7.67h5.8V5.2h-8.42z"
      />
    </svg>
  );
}
