import React from 'react';

const TimerHalf = props => {
  return (
    <svg
      viewBox="0 0 14 16"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" fill="none">
        <g transform="translate(-266.000000, -313.000000)">
          <g transform="translate(266.000000, 312.000000)">
            <g transform="translate(-0.613281, 0.757812)">
              <g transform="translate(0.814453, 0.378906)">
                <path d="M6.76957463,3.53892532 C3.77134738,3.53892532 1.33221757,5.97805513 1.33221757,8.97628238 C1.33221757,11.9745096 3.77134738,14.4136394 6.76957463,14.4136394 C9.76762829,14.4136394 12.2069317,11.9745096 12.2069317,8.97628238 C12.2069317,5.97822871 9.76762829,3.53892532 6.76957463,3.53892532 Z M6.76957463,13.3490804 L6.76957463,8.97628238 L6.76957463,4.60348432 C9.18457698,4.60348432 11.1423727,6.56128002 11.1423727,8.97628238 C11.1423727,11.3912847 9.18457698,13.3490804 6.76957463,13.3490804 Z"></path>
                <path
                  d="M7.52394825,2.37646785 L7.52394825,1.72138132 L8.19378898,1.72138132 L8.19378898,0 L5.34536027,0 L5.34536027,1.72138132 L6.015201,1.72138132 L6.015201,2.37646785 C5.12317936,2.47783789 4.28496716,2.75608477 3.53510659,3.17597197 L2.97166968,2.33185809 L2.08971561,2.92046393 L2.65488831,3.76718149 C1.11593834,4.98535777 0.12376171,6.86539014 0.12376171,8.97593522 C0.12376171,12.6408788 3.10515181,15.6220953 6.76957463,15.6220953 C10.4339974,15.6220953 13.4153875,12.6408788 13.4153875,8.97628238 C13.4153875,5.56719403 10.8340967,2.75278677 7.52394825,2.37646785 Z M6.76957463,14.4136394 C3.77134738,14.4136394 1.33221757,11.9745096 1.33221757,8.97628238 C1.33221757,5.97822871 3.77134738,3.53892532 6.76957463,3.53892532 C9.76762829,3.53892532 12.2069317,5.97805513 12.2069317,8.97628238 C12.2069317,11.9745096 9.76762829,14.4136394 6.76957463,14.4136394 Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M6.76957463,4.60348432 L6.76957463,8.97628238 L6.76957463,13.3490804 C9.18457698,13.3490804 11.1423727,11.3912847 11.1423727,8.97628238 C11.1423727,6.56128002 9.18457698,4.60348432 6.76957463,4.60348432 Z"
                  fill="currentColor"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TimerHalf;
