import { objToQueryString } from 'utilities';
import http from 'utilities/http';

export const fetchBids = async params => {
  const response = await http.get('bids' + objToQueryString(params));
  return await response.json();
};

export const getInterviewSchedulingPages = async timezone => {
  const response = await http.get(
    `/api/v1/employers/interview_scheduling_pages?timezone=${timezone}`,
  );
  return await response.json();
};

export const getInterviewSchedulingPage = async id => {
  const response = await http.get(
    `/api/v1/employers/interview_scheduling_pages/${id}`,
  );
  return await response.json();
};

export const awaitNylasSync = async () => {
  const response = await http.get('/nylas/await_nylas_sync');
  return await response.json();
};

export const nylasAccountSynced = async () => {
  const response = await http.get('/nylas/account_synced');
  return await response.json();
};

export const checkNylasToken = async () => {
  const response = await http.get('/nylas/token_valid');
  return await response.json();
};

export const createAppointment = async ({
  appointmentType,
  bidId,
  duration,
  email,
  momId,
  phone,
  nylasBookingUrl,
  timeZone,
}) => {
  const response = await http.post('/api/v1/employers/appointments', {
    body: JSON.stringify({
      appointment_type: appointmentType,
      bid_id: bidId,
      duration: duration,
      email,
      mom_id: momId,
      phone,
      nylas_booking_url: nylasBookingUrl,
      time_zone: timeZone,
    }),
  });
  return await response.json();
};

export const rescheduleAppointment = async appointmentId => {
  const response = await http.patch(
    `/api/v1/employers/appointments/${appointmentId}`,
  );
  return await response;
};

export const fetchBid = async id => {
  const response = await http.get(`bids/${id}`);
  return await response.json();
};

export const updateBidToViewed = async (projectId, bid) => {
  const body = JSON.stringify({ bid_action: 'view' });
  const endpoint = `/api/v1/employers/projects/${projectId}/bids/${bid.id}`;
  const response = await http.patch(endpoint, {
    body,
  });
  return await response.json();
};

export const updateBid = async (bid, body) => {
  const bidId = bid.id;
  const projectId = bid.project.id;
  const endpoint = `/api/v1/employers/projects/${projectId}/bids/${bidId}`;
  const response = await http.patch(endpoint, {
    body,
    mode: 'cors',
    cache: 'no-cache',
    referrer: 'no-referrer',
  });
  return await response.json();
};
