import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import Dialog from 'components/ui/Dialog';
import { Grid, Row, Col } from 'components/ui/grid';
import { without, chunk, handleKeyboardActivation } from 'utilities';
import FadeInOutMount from 'components/ui/FadeInOutMount';
import withTooltip from 'components/ui/withTooltip';
import UserPill from './UserPill';
import { useEmployerCollaborators, useProjectMutation } from 'requests';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import LoadingOverlay from 'components/ui/LoadingOverlay';

const idNormalizer = (normalized, id) => ((normalized[id] = true), normalized);

const Tooltip = withTooltip('span');
Tooltip.defaultProps = { tabIndex: '0' };

const EditUsersModal = ({ userId, project, handleClose }) => {
  const [showTextConfirmation, setShowTextConfirmation] = useState(false);
  const mutation = useProjectMutation(project.id);
  const queryClient = useQueryClient();
  const { data: users } = useEmployerCollaborators(true);
  const userIds = project.user_ids;
  const normalizedIds = userIds.reduce(idNormalizer, {});
  const toggleUserIdOnProject =
    (project_id, normalizedIds, userId, mutation, queryClient) => () => {
      let user_ids;
      if (normalizedIds[userId]) {
        user_ids = Object.keys(without.call(normalizedIds, userId.toString()));
      } else {
        user_ids = Object.keys(normalizedIds).concat(userId.toString());
      }

      mutation.mutate(
        { user_ids },
        {
          onSuccess: () => {
            setShowTextConfirmation(true);
            // TODO: Refactor the API so that it uses the same format as the single project fetch.
            // That way we can use setQueryData and save an extra API call. We can't currently do it
            // given the difference between formats.
            queryClient.invalidateQueries(['projects']);
            queryClient.invalidateQueries(['project', project_id]);
          },
        },
      );
    };
  return (
    <>
      {mutation.isLoading && <LoadingOverlay />}
      <Dialog large onHide={handleClose} show>
        <Grid fluid css="overflow: visible;">
          <Row>
            <Col col={12}>
              <h5 css={({ theme }) => `font-family: ${theme.fonts.primary};`}>
                Edit collaborators
                <FadeInOutMount
                  css={({ theme }) => `display: inline; color: ${theme.green}`}
                  in={showTextConfirmation}
                >
                  &nbsp;Saved!
                </FadeInOutMount>
              </h5>
            </Col>
          </Row>
          {users ? (
            chunk(users, 2).map((chunk, index) => (
              <Row key={index}>
                {chunk.map(user => {
                  const buttonWrapperProps = {};
                  const isCurrentUser = userId === user.id;
                  if (normalizedIds[user.id]) {
                    buttonWrapperProps.selected = true;
                  }
                  if (!user.active) {
                    buttonWrapperProps.deactivated = true;
                  }
                  if (isCurrentUser && project.creator_id) {
                    buttonWrapperProps.disabled = true;
                  } else {
                    buttonWrapperProps.tabIndex = '0';
                    if (user.active && !isCurrentUser) {
                      buttonWrapperProps.onClick = toggleUserIdOnProject(
                        project.id,
                        normalizedIds,
                        user.id,
                        mutation,
                        queryClient,
                      );
                      buttonWrapperProps.onKeyDown = handleKeyboardActivation(
                        buttonWrapperProps.onClick,
                      );
                    }
                  }
                  return (
                    <Col sm={6} key={user.id} className="mt-3">
                      {isCurrentUser ? (
                        <Tooltip
                          trigger="CLICK"
                          hideOnBlur
                          title={
                            <div
                              css={`
                                min-width: 200px;
                              `}
                            >
                              You cannot remove yourself as a collaborator.
                            </div>
                          }
                        >
                          <UserPill
                            user={user}
                            buttonWrapperProps={buttonWrapperProps}
                          />
                        </Tooltip>
                      ) : (
                        <UserPill
                          user={user}
                          buttonWrapperProps={buttonWrapperProps}
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
            ))
          ) : (
            <LoadingSpinner />
          )}
        </Grid>
      </Dialog>
    </>
  );
};

EditUsersModal.propTypes = {
  disabled: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  project: PropTypes.shape({
    creator_id: PropTypes.number,
    creator_type: PropTypes.string,
    id: PropTypes.string,
    user_ids: PropTypes.array,
  }).isRequired,
};

export default EditUsersModal;
