import React from 'react';

export default function Sparkles3Pattern(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97.411"
      height="101.156"
      viewBox="0 0 97.411 101.156"
      {...props}
    >
      <g transform="translate(-905 -2349)">
        <path
          fill="#6685ff"
          d="M445.789,196.267c-4.184-13.313-11.657-21.983-23.156-26.734,11.465-4.805,18.988-13.415,23.128-26.689,4.148,13.13,11.591,21.831,23.116,26.654C457.5,174.345,449.93,182.9,445.789,196.267Z"
          transform="translate(482.367 2206.156)"
        />
        <path
          fill="#fecc7a"
          d="M492.413,192.34c6.751-2.995,11.169-7.971,13.739-15.891,2.638,7.7,6.912,12.933,13.892,15.847-6.82,3-11.253,8.05-13.852,15.961C503.621,200.407,499.224,195.316,492.413,192.34Z"
          transform="translate(482.367 2206.156)"
        />
        <path
          fill="#ffd4d2"
          d="M470.753,213.884c2.23,7.338,5.776,12.209,11.494,15.02-5.66,2.94-9.287,7.7-11.486,15.1-2.211-7.362-5.806-12.166-11.436-15.059C464.934,226.038,468.554,221.267,470.753,213.884Z"
          transform="translate(482.367 2206.156)"
        />
      </g>
    </svg>
  );
}
