import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Portal from './Portal';
import CloseButton from 'components/ui/buttons/CloseButton';
import { useEffectSkipInitialRender } from 'utilities/hooks';
import { debounce, getNextTooltipPositioning } from 'utilities';
import theme from 'theme';
import { Transition } from 'react-transition-group';

const spacing = 5;
const transitionTime = 200;
const Tooltip = styled.div(
  ({ orientTop, orientRight, caretPosition, transitionState }) => `
  position: relative;
  z-index: 206;
  color: ${theme.white};
  background-color: ${theme.primaryBlue};
  border-radius: 5px;
  line-height: 1.5;
  padding: 10px;
  transition: opacity ${transitionTime}ms;
  ${transitionState.match(/entering|exiting/) ? 'opacity: 0;' : ''}

  &:after {
    position: absolute;
    ${
      orientTop
        ? `
    bottom: -8px;
    border-top: 8px solid ${theme.primaryBlue};
    `
        : `
    top: -8px;
    border-bottom: 8px solid ${theme.primaryBlue};
    `
    }
    ${
      orientRight
        ? `right: ${caretPosition}px;`
        : `left: ${caretPosition - spacing / 2}px;`
    }

    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
`,
);

function targetWasOutsideRefs(target, ...refs) {
  for (let i = 0; i < refs.length; i++) {
    const ref = refs[i];
    if (ref && (target === ref || ref.contains(target))) {
      return false;
    }
  }
  return true;
}
function withTooltip(WrappedComponent) {
  const displayName = WrappedComponent.displayName || 'Component';
  function Component({
    trigger = 'MOUSEENTER',
    title,
    tooltipCSS,
    orientRight,
    orientTop,
    isOpen,
    showClose,
    handleOnClose,
    hideOnBlur,
    withPortal,
    ...props
  }) {
    const id = useMemo(() => `tooltip-${withTooltip.idCounter++}`, []);
    const [show, setShow] = useState(false);
    const tooltipPositioning = useRef({ portalStyle: {}, popupStyle: {} });
    const triggerRef = useRef(null);
    const tooltipRef = useRef(null);

    const handleMouseEventShow = useCallback(
      event => {
        if (event.type === 'click') {
          event.preventDefault();
        }
        if (!show) {
          const { show: nextShow, ...nextTooltipPositioning } =
            getNextTooltipPositioning(
              triggerRef.current,
              spacing,
              orientRight,
              orientTop,
            );
          tooltipPositioning.current = nextTooltipPositioning;
          setShow(nextShow);
        }
      },
      [show, orientRight, orientTop],
    );

    useEffect(() => {
      if (isOpen === null) {
        return;
      }
      const { ...nextTooltipPositioning } = getNextTooltipPositioning(
        triggerRef.current,
        spacing,
        orientRight,
        orientTop,
      );
      tooltipPositioning.current = nextTooltipPositioning;
      setShow(isOpen);
    }, [isOpen, orientRight, orientTop]);

    /* Setup listener to remove tooltip */
    const listenerArgs = useRef([]);
    useEffectSkipInitialRender(() => {
      if (show) {
        const listener = trigger === 'MOUSEENTER' ? 'mousemove' : 'mousedown';
        const invokedHandler =
          listener === 'mousemove' ? debounce.call(handler, 75) : handler;
        listenerArgs.current.push([document, [listener, invokedHandler, true]]);
        listenerArgs.current.forEach(([target, args]) =>
          target.addEventListener.apply(target, args),
        );
        return;
      }
      listenerArgs.current.forEach(([target, args]) =>
        target.removeEventListener.apply(target, args),
      );
    }, [show]);
    /* Tear down the listener if it exists on dismount */
    useEffect(
      () => () => {
        listenerArgs.current.forEach(([target, args]) =>
          target.removeEventListener.apply(target, args),
        );
      },
      [],
    );

    const triggerProps = { onFocus: handleMouseEventShow };
    if (trigger === 'MOUSEENTER') {
      triggerProps.onMouseEnter = handleMouseEventShow;
    } else {
      triggerProps.onClick = event => {
        event.preventDefault();
      };
    }
    if (show && hideOnBlur) {
      triggerProps.onBlur = () => setShow(false);
      triggerProps.onKeyDown = event => {
        if (event.key === 'Escape') {
          event.preventDefault();
          event.stopPropagation();
          setShow(false);
        }
      };
    }

    const handler = event => {
      if (
        hideOnBlur &&
        targetWasOutsideRefs(
          event.target,
          triggerRef.current,
          tooltipRef.current,
        )
      ) {
        setShow(false);
        return;
      }
    };

    const closeTooltip = () => {
      handleOnClose && handleOnClose();
      setShow(false);
    };

    const getOrientValue = (position, orient) => {
      if (orient !== undefined) {
        return orient;
      }
      if (position === 'orientTop') {
        return tooltipPositioning.current.orientTop;
      }
      if (position === 'orientRight') {
        return tooltipPositioning.current.orientRight;
      }
    };

    const TooltipComponent = ({ transitionState }) => (
      <Tooltip
        orientRight={getOrientValue('orientRight', orientRight)}
        orientTop={getOrientValue('orientTop', orientTop)}
        caretPosition={tooltipPositioning.current.caretPosition}
        ref={tooltipRef}
        style={tooltipPositioning.current.popupStyle}
        css={tooltipCSS}
        id={id}
        role="tooltip"
        transitionState={transitionState}
      >
        {showClose && (
          <CloseButton
            title="Close"
            data-testid="close-btn"
            color="white"
            id="close-btn"
            onClick={closeTooltip}
            size="sm"
          />
        )}
        {title}
      </Tooltip>
    );

    TooltipComponent.propTypes = {
      transitionState: PropTypes.string,
    };

    return (
      <>
        <WrappedComponent
          {...props}
          {...triggerProps}
          aria-describedby={id}
          ref={triggerRef}
        />
        <Transition timeout={transitionTime} in={show}>
          {transitionState =>
            transitionState === 'exited' ? null : withPortal ? (
              <Portal style={tooltipPositioning.current.portalStyle}>
                <TooltipComponent transitionState={transitionState} />
              </Portal>
            ) : (
              <TooltipComponent transitionState={transitionState} />
            )
          }
        </Transition>
      </>
    );
  }
  Component.displayName = `withTooltip(${displayName})`;
  Component.propTypes = {
    title: PropTypes.any.isRequired,
    isOpen: PropTypes.bool,
    trigger: PropTypes.oneOf(['MOUSEENTER', 'CLICK']),
    tooltipCSS: PropTypes.string,
    orientTop: PropTypes.bool,
    orientRight: PropTypes.bool,
    showClose: PropTypes.bool,
    handleOnClose: PropTypes.func,
    hideOnBlur: PropTypes.bool,
    withPortal: PropTypes.bool,
  };

  Component.defaultProps = {
    hideOnBlur: true,
    withPortal: true,
  };

  return Component;
}
withTooltip.idCounter = 0;

export default withTooltip;
/**
 * onTouchStart -> onTouchEnd
 * onMouseEnter -> onMouseLeave
 * onClick -> onClick (not within) / on space -> esc
 */
