import ReactiveRecord, { Model } from 'reactiverecord';
const collectionRoute = ':prefix/moms/match_feedback';
const memberRoute = `${collectionRoute}/?slug=:slug`;
class MatchRelevancyFeedback extends Model {
  static routes = {
    only: ['index', 'create', 'update'],
    index: memberRoute,
    create: memberRoute,
    update: memberRoute,
  };

  static store = {
    singleton: true,
  };

  static schema = {
    comment: {
      type: String,
      labelText:
        'Please provide any feedback you’d like to share on your recent job match',
    },
    is_relevant: Boolean,
    slug: String,
    source: String,
  };
}

export default ReactiveRecord.model(
  'MatchRelevancyFeedback',
  MatchRelevancyFeedback,
);
