import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaSet } from 'use-media-set';
import { mediaSetQueries } from 'styleGuide';
import theme from 'theme';
import Button from 'components/ui/buttons/Button';
import welcomeImage from 'assets/illustrations/welcome-image.png';
import welcomeImageMobile from 'assets/illustrations/welcome-image-mobile.png';
import { Row, Col, Grid } from 'components/ui/grid';
import { URLS } from 'consts';

const StyledGrid = styled(Grid)`
  max-width: 1440px;
  h1,
  h5 {
    font-family: ${theme.fonts.merriweather};
  }
`;

const StyledHeaderContainer = styled.div`
  @media (max-width: ${theme.screenSizes.laptopS}) {
    position: relative;
    display: flex;
    align-items: center;
    background-image: url(${welcomeImageMobile});
    background-size: cover;
    background-position: right top;
    width: 105%;
    height: 40vw;
    min-height: 228px;
    margin-bottom: 2rem;
  }
  h1 {
    font-weight: 900;
    font-size: 2.5rem;
    font-style: normal;
    @media (max-width: ${theme.screenSizes.laptopS}) {
      max-width: 20rem;
      width: 40%;
    }
    @media (max-width: ${theme.screenSizes.tablet}) {
      font-size: 2rem;
    }
    @media (min-width: ${theme.screenSizes.laptopS}) {
      white-space: nowrap;
    }
  }
`;

const Styledparagraph = styled.p`
  font-weight: 600;
`;

const StyledCol = styled(Col)`
  background-image: url(${welcomeImage});
  background-size: cover;
  min-height: 50vh;
  background-position: center top;
`;

const Welcome = ({ onClickGetStarted }) => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const onMediumScreen = mediaStates.has('mobileOrLaptopS');
  const handleClickProfile = () => {
    window.location.assign(URLS.talentProfile);
  };
  const handleClickJobs = () => {
    onClickGetStarted && onClickGetStarted();
    window.location.assign(URLS.talentMarketplace);
  };

  return (
    <StyledGrid>
      <Row className={onMediumScreen ? 'justify-content-center' : ''}>
        {!onMediumScreen && (
          <StyledCol
            sm={12}
            lg={5}
            className="pl-0 mr-md-5 mb-md-5 mb-lg-0"
          ></StyledCol>
        )}
        <Col
          sm={12}
          lg={5}
          md={12}
          className="flex-wrap text-lg-nowrap text-xs-wrap"
        >
          <div className="mb-5 mt-0 mt-lg-5">
            <StyledHeaderContainer>
              <h1 className="mb-0 mb-lg-3 ml-3 ml-lg-0">
                We&apos;re so glad you&apos;re here!
              </h1>
            </StyledHeaderContainer>
            <p>
              Look for opportunities in your inbox shortly! We&apos;ll email you
              positions that align with your career goals.
            </p>
          </div>
          <div className="mb-5">
            <h5 className="mb-3">Start searching</h5>
            <p>
              Feel free to begin browsing opportunities now! You&apos;ll be able
              to find full-time, part-time, and contract work
            </p>
            <Button
              id="job-search-button"
              data-id="job-search-button"
              onClick={handleClickJobs}
            >
              Explore jobs
            </Button>
          </div>
          <div className="mb-5">
            <h5 className="mb-3">Stand out to employers</h5>
            <p>
              Employers look at your profile when you apply and interview. A
              complete profile is 10X more likely to stand out.
            </p>
            <Button
              id="profile-button"
              data-id="profile-button"
              secondary={true}
              onClick={handleClickProfile}
            >
              Go to my profile
            </Button>
          </div>
          <div className="mb-5">
            <Styledparagraph>
              Looking for resume and interview tips?&nbsp;
              <a target="_blank" href={URLS.careerGuidance} rel="noreferrer">
                Check out these articles
              </a>
            </Styledparagraph>
          </div>
        </Col>
      </Row>
    </StyledGrid>
  );
};

Welcome.propTypes = {
  onClickGetStarted: PropTypes.func,
};

export default Welcome;
