import { css } from 'styled-components';

export const getInputSharedStyles = () => {
  return css`
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 0;
    padding: 13px 15px;
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme }) => theme.fontSizes.base};
    font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
    line-height: 1.5rem;
    color: ${({ theme }) => theme.darkGray};
    background-color: ${({ white, theme }) =>
      white ? theme.white : theme.lightBlue};
    border: none;
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lightGray};
    -webkit-appearance: none;

    ${({ hasIcon }) =>
      hasIcon &&
      `
      padding-right: 48px;
    `}

    ${({ hasSuccess, theme }) =>
      hasSuccess && `box-shadow: inset 0 0 0 1px ${theme.green};`}
    ${({ hasError, theme }) =>
      hasError && `box-shadow: inset 0 0 0 1px ${theme.red};`}

    ${({ hasButton }) =>
      hasButton &&
      `
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}

    &:focus,
    .forceFocusState & {
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primaryBlue};
    }

    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.mediumGray};
      font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
    }
    &::-moz-placeholder {
      color: ${({ theme }) => theme.mediumGray};
      font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
    }
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.mediumGray};
      font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.lightGray};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lightGray};
      &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.mediumGray};
      }
      &::-moz-placeholder {
        color: ${({ theme }) => theme.mediumGray};
      }
      &:-ms-input-placeholder {
        color: ${({ theme }) => theme.mediumGray};
      }
    }
  `;
};
