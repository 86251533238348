import ReactiveRecord, { Model } from 'reactiverecord';
import { getInitialsFirstAndLastName } from 'utilities';

class CurrentMom extends Model {
  static routes = {
    index: ':prefix/moms/account',
    update: ':prefix/moms/account',
  };
  static store = {
    singleton: true,
  };
  // current_password is used on the talent settings form for talent to set a new password, and it isn't passing the current password through the api GET request
  static schema = {
    about_me: String,
    agree_to_talent_clouds_terms: {
      type: Boolean,
      labelText: 'Accept terms & conditions',
    },
    agree_to_terms_and_policy: Boolean,
    availability_date: {
      type: String,
      labelText: ' ',
    },
    availability_id: {
      type: Number,
      labelText: 'What is your current availability?',
    },
    availability: Object,
    avatar_url: String,
    cancellation_reason_id: Number,
    capability_ids: Array,
    cloud_enrollment: Object,
    commute_distance_limit_mi: {
      type: Number,
      labelText: 'How far are you willing to commute? (in miles)',
    },
    contract_with_benefits: {
      type: Boolean,
      labelText: 'Contract with benefits',
    },
    contract_without_benefits: {
      type: Boolean,
      labelText: 'Contract without benefits',
    },
    current_password: { type: String, labelText: 'Current password' },
    has_opted_in_to_dtc: Boolean,
    is_profile_visible: Boolean,
    current_work_status: String,
    email: String,
    emsi_skill_ids: Array,
    exclude_by_min_salary: Boolean,
    expertises_attributes: {
      type: Array,
      labelText: 'Skills (e.g. Microsoft Office)',
    },
    expertises_labels: Array,
    expertises: { type: Array, labelText: 'Skills (e.g. Microsoft Office)' },
    expertises_with_unconfirmed: {
      type: Array,
      labelText: 'Skills (e.g. Microsoft Office)',
    },
    first_name: { type: String, labelText: 'First name' },
    feedback_form_views_map: Object,
    full_name: String,
    full_time: { type: Boolean, labelText: 'Permanent employee' },
    goals_array: Array,
    has_agreed_to_terms: Boolean,
    has_agreed_to_talent_cloud_terms: Boolean,
    is_eligible_for_dtc_onboarding: Boolean,
    id: Number,
    independent_days: {
      type: String,
      labelText: 'Do you need to set your own work days every week?',
    },
    independent_hours: {
      type: String,
      labelText: 'Do you need to set your own hours every week?',
    },
    industries_names: Array,
    industry_ids: Array,
    last_name: { type: String, labelText: 'Last name' },
    level: { type: String, labelText: 'Experience level' },
    maternityship: { type: Boolean, labelText: 'Contract to permanent' },
    minimum_hourly_rate_cents: {
      type: Number,
      labelText: 'per hour*',
    },
    minimum_salary_cents: {
      type: Number,
      labelText: 'per year*',
    },
    notification_group_ids: Array,
    notification_groups: Array,
    one_to_twenty: { type: Boolean, labelText: '1-20 hours per week' },
    other_capability_ids: Array,
    out_of_town_travel_percent: {
      type: Number,
      labelText: 'What percentage of time can you travel out of town?',
    },
    over_forty: { type: Boolean, labelText: '40 hours per week' },
    // for validation to work onChange this is swapped
    password_confirmation: { type: String, labelText: 'Password' },
    password: { type: String, labelText: 'Password confirmation' },
    phone: { type: String, labelText: 'Phone Number' },
    prefered_arraignment: String,
    primary_capabilities_category_id: Number,
    primary_capabilities_category_title: String,
    primary_capability_ids: Array,
    profile_complete: Boolean,
    profile_completion_areas: Object,
    profile_completion_percent: { type: Number },
    remove_avatar: Boolean,
    short_pitch_default: String,
    short_pitch: { type: String, labelText: 'Resume headline' },
    timezone: String,
    thirty_one_to_forty: { type: Boolean, labelText: '31-40 hours per week' },
    top_capabilities_titles: Array,
    twenty_one_to_thirty: { type: Boolean, labelText: '21-30 hours per week' },
    website: { type: String, labelText: 'Website (Optional)' },
    zip: { type: String, labelText: 'Zip Code' },
  };

  get fullName() {
    return [this.first_name, this.last_name].filter(Boolean).join(' ') || null;
  }

  get idealSchedule() {
    return this.getReducedArrayOptions([
      'one_to_twenty',
      'twenty_one_to_thirty',
      'thirty_one_to_forty',
      'over_forty',
    ]);
  }

  get idealWorkStatuses() {
    return this.getReducedArrayOptions([
      'contract_without_benefits',
      'contract_with_benefits',
      'maternityship',
      'full_time',
    ]);
  }

  get initials() {
    if (this.first_name) {
      return getInitialsFirstAndLastName(this.first_name, this.last_name);
    }
    return null;
  }

  set minimum_hourly_rate_cents(newMinimumHourlyRateCents) {
    this._attributes.minimum_hourly_rate_cents =
      newMinimumHourlyRateCents.replace(/[.]/g, '');
  }

  get minHourlyRateCentsDisplayValue() {
    if (this._attributes.minimum_hourly_rate_cents) {
      return this._attributes.minimum_hourly_rate_cents / 100;
    }
    return null;
  }

  set minimum_salary_cents(newMinimumSalaryCents) {
    this._attributes.minimum_salary_cents = newMinimumSalaryCents.replace(
      /[.]/g,
      '',
    );
  }

  get minSalaryCentsDisplayValue() {
    if (this._attributes.minimum_salary_cents) {
      return this._attributes.minimum_salary_cents / 100;
    }
    return null;
  }

  set phone(phone) {
    let phone_digits = phone.replace(/[^0-9]+/g, '');
    if (phone_digits.length > 10 && phone_digits[0] === '1') {
      this._attributes.phone = phone_digits.slice(1);
    }
    this._attributes.phone = phone_digits;
  }

  get shortPitchOrDefault() {
    return this.short_pitch || this.short_pitch_default;
  }

  getReducedArrayOptions(arrayOptions) {
    return arrayOptions.reduce((labels, attribute) => {
      if (this[attribute]) {
        return [
          ...labels,
          {
            attribute: attribute,
            label: this.constructor.schema[attribute].labelText,
          },
        ];
      }
      return labels;
    }, []);
  }
}

export default ReactiveRecord.model('CurrentMom', CurrentMom);
