import React from 'react';
import PropTypes from 'prop-types';

export default function AutoHellip({ children, ...props }) {
  return (
    <div
      css={`
        display: table;
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        .auto-hellip {
          display: table-cell;
          width: 100%;
          max-width: 0;
          padding: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `}
      {...props}
    >
      <div className="auto-hellip">{children}</div>
    </div>
  );
}

AutoHellip.propTypes = {
  children: PropTypes.any,
};
