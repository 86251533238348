import React, { forwardRef, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fonts, fontSizes } from '../../../styleGuide';
import ErrorMessage from '../labels/ErrorMessage';
import { Validate } from 'reactiverecord';

export const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
  label {
    font-family: ${fonts.primary};
    font-size: ${fontSizes.base};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 35px;
    display: inline-block;
    align-items: center;
    &:before {
      content: '';
      height: 25px;
      width: 25px;
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${colors.white};
      transition: all 0.3s;
      border: 1px solid ${colors.gray.dark};
    }
    &:after {
      content: '';
      color: ${colors.white};
      height: 8px;
      width: 14px;
      border-left: 3px solid;
      border-bottom: 3px solid;
      transform: rotate(-45deg);
      position: absolute;
      left: 5.4px;
      top: 7px;
      transition: all 0.3s;
    }
  }

  input[type='checkbox']:disabled {
    :not(:checked) + label {
      color: ${colors.gray.medium};
      &:before {
        border: 1px solid ${colors.gray.medium};
      }
    }
    :checked + label {
      color: ${colors.gray.medium};
      &:before {
        background: ${colors.gray.light};
        border: 1px solid ${colors.gray.light};
      }
      &:after {
        color: ${colors.gray.medium};
      }
    }
  }

  input[type='checkbox'] + label::after {
    content: none;
  }

  input[type='checkbox']:checked + label::after {
    content: '';
  }

  input[type='checkbox']:checked + label::before {
    background: ${colors.blue.primary};
    border: none;
  }

  input[type='checkbox']:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
`;

const InputCheckbox = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
`;
InputCheckbox.defaultProps = { type: 'checkbox' };

/**
 * Checkbox that returns either true, false or given value when checked.
 */
const Checkbox = forwardRef(
  ({ labelText, uncheckedValue, ...props }, forwardedRef) => {
    const generatedId = useMemo(() => `checkbox-${Checkbox.idCounter++}`, []);
    const id = props.id || generatedId;
    const storeValidate = useRef(null);
    const handleRef = useCallback(
      validateRef => ref => {
        const refInterface = {
          get isValid() {
            return storeValidate.current.isValid.bind(storeValidate.current);
          },
          get checked() {
            return ref.checked;
          },
          get value() {
            if (ref.checked) {
              return typeof props.value === 'undefined' ? true : props.value;
            }
            return typeof uncheckedValue === 'undefined'
              ? false
              : uncheckedValue;
          },
        };
        validateRef(refInterface);
        if (forwardedRef) {
          forwardedRef(refInterface);
        }
      },
      [props.value, uncheckedValue, forwardedRef],
    );
    if (
      Object.prototype.hasOwnProperty.call(props, 'defaultValue') &&
      !Object.prototype.hasOwnProperty.call(props, 'defaultChecked')
    ) {
      props.defaultChecked = !!props.defaultValue;
    }

    return (
      <Validate {...props} ref={storeValidate}>
        {({ ref: validateRef, ...validateProps }, errorText, validating) => (
          <CheckboxContainer>
            <InputCheckbox
              id={id}
              disabled={validating}
              {...validateProps}
              ref={handleRef(validateRef)}
            />
            <label htmlFor={id} data-testid={props.labelTestId}>
              {labelText}
            </label>
            {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
          </CheckboxContainer>
        )}
      </Validate>
    );
  },
);
Checkbox.propTypes = {
  labelText: PropTypes.any,
  id: PropTypes.any,
  labelTestId: PropTypes.string,
  /** Set to return a specific value instead of `true` when checked */
  value: PropTypes.any,
  /** Set to return a specific value instead of `false` when unchecked */
  uncheckedValue: PropTypes.any,
  defaultValue: PropTypes.any,
};
Checkbox.idCounter = 0;
Checkbox.displayName = 'Checkbox';

export default Checkbox;
