import React from 'react';
import PropTypes from 'prop-types';

const ProfileBlue = ({ size = '60' }, props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 60 60"
    >
      <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
        <circle cx="30" cy="30.992" r="30" fill="#B0EEE3" />
        <path
          fill="#464A59"
          fillRule="nonzero"
          d="M30 14.992c4.765 0 8.628 4.506 8.628 10.065 0 2.393-.716 4.591-1.911 6.319C42.08 32.97 46 37.974 46 43.915v.484c-.007 1.457-1.297 2.587-2.754 2.587h-.688l.007-.018H17.437l.005.018h-.662c-1.464 0-2.757-1.131-2.78-2.594v-.477c0-5.941 3.919-10.945 9.284-12.54-1.195-1.727-1.912-3.925-1.912-6.318 0-5.559 3.864-10.065 8.628-10.065zM25.708 33.79l-.236.033c-4.812.75-8.515 5.127-8.589 10.269h26.234c-.075-5.226-3.897-9.66-8.825-10.303-1.264.85-2.73 1.334-4.292 1.334-1.562 0-3.028-.485-4.292-1.333zM30 17.867c-3.176 0-5.752 3.22-5.752 7.19s2.576 7.19 5.752 7.19c3.177 0 5.752-3.22 5.752-7.19s-2.575-7.19-5.752-7.19z"
        />
      </g>
    </svg>
  );
};

ProfileBlue.propTypes = {
  size: PropTypes.string,
};

export default ProfileBlue;
