import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/pins';
const memberRoute = `${collectionRoute}/:id`;

class Pin extends Model {
  static routes = {
    index: collectionRoute,
    create: collectionRoute,
    show: collectionRoute,
    destroy: memberRoute,
  };

  static schema = {
    pinnable_id: Number,
    pinnable_type: String,
    pinner_type: String,
  };
}

export default ReactiveRecord.model('Pin', Pin);
