import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { validated } from 'reactiverecord';
import {
  CharacterTracker,
  LabelText,
  RichTextEditorWrapper,
} from './RichTextEditorStyles';
import Input from 'components/ui/inputs/Input';
import QuillEditor, { toolbarOptions, formatOptions } from './QuillEditor';

const EMPTY_STRING = '';
const PLURAL = 's';
const REMAINING = 'remaining';
const USED = 'used';

// default 65535 for maxLength matches mysql's default limit for `text` fields
// this fixes a security flaw while maintaining when character count should be displayed
const adjustContentToMaxLength = (editor, maxLength = 65535) => {
  if (maxLength && editor.getLength() > maxLength) {
    editor.deleteText(maxLength);
  }
};

const setInitialContents = (editor, value, maxLength) => {
  if (value) {
    editor.setContents(value, 'silent');
    adjustContentToMaxLength(editor, maxLength);
  }
};

const RichTextEditor = forwardRef((props, forwardedRef) => {
  const {
    defaultValue,
    errorText,
    format,
    height,
    labelText,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    showCharacterCount,
    toolbar,
  } = props;
  const [charCount, setCharCount] = useState(0);
  const [charsRemaining, setCharsRemaining] = useState(maxLength);
  const [editor, setEditor] = useState(null);
  const [editorValue, setEditorValue] = useState(defaultValue || EMPTY_STRING);

  useEffect(() => {
    if (!editor) {
      const quill = new QuillEditor('#editor', toolbar, format);
      setInitialContents(quill, editorValue, maxLength);
      setCharsRemaining(quill.getCharsRemaining(maxLength));
      setCharCount(quill.getCharCount());

      quill.on('text-change', () => {
        const content = quill.getText() ? quill.getHTML() : EMPTY_STRING;
        adjustContentToMaxLength(quill, maxLength);
        setEditorValue(content);
        onChange(content);
        setCharsRemaining(quill.getCharsRemaining(maxLength));
        setCharCount(quill.getCharCount());
      });

      quill.on('selection-change', range => {
        if (!range && onBlur) {
          onBlur(quill.getText());
        } else if (range && onFocus) {
          onFocus();
        }
      });
      setEditor(quill);
    }
  }, [
    maxLength,
    onBlur,
    onFocus,
    props,
    editor,
    editorValue,
    toolbar,
    format,
    onChange,
  ]);

  const formatCountText = (count, descriptor) => {
    let pluralizer = count === 1 ? EMPTY_STRING : PLURAL;
    return `${count} character${pluralizer} ${descriptor}.`;
  };

  const displayCharacterCount = () => !maxLength && showCharacterCount;

  return (
    <RichTextEditorWrapper height={height} hasError={!!errorText}>
      {labelText && <LabelText>{labelText}</LabelText>}
      <div id="editor" data-testid="editor"></div>
      {maxLength && (
        <CharacterTracker>
          {formatCountText(charsRemaining, REMAINING)}
        </CharacterTracker>
      )}
      {displayCharacterCount() && (
        <CharacterTracker>{formatCountText(charCount, USED)}</CharacterTracker>
      )}
      <Input
        hidden={true}
        errorText={errorText}
        onChange={onChange}
        ref={forwardedRef}
        value={editorValue}
        data-testid="hidden-input"
      />
    </RichTextEditorWrapper>
  );
});

RichTextEditor.displayName = 'RichTextEditor';
RichTextEditor.propTypes = {
  defaultValue: PropTypes.string,
  errorText: PropTypes.string,
  format: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  showCharacterCount: PropTypes.bool,
  toolbar: PropTypes.array,
};

RichTextEditor.defaultProps = {
  defaultValue: EMPTY_STRING,
  format: formatOptions,
  height: 'auto',
  showCharacterCount: false,
  toolbar: toolbarOptions,
};

export default validated(RichTextEditor);
