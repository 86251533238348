import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/** NOT FOR PRODUCTION USE: Used to test component states in Storybook */
export default function MockState({ focus, hover, className, ...props }) {
  return (
    <div
      className={classNames(
        { forceFocusState: focus, forceHoverState: hover },
        className,
      )}
      {...props}
    />
  );
}
MockState.propTypes = {
  focus: PropTypes.bool,
  hover: PropTypes.bool,
  className: PropTypes.string,
};
