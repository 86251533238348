import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import deprecated from 'components/hoc/deprecated';

import { flexOptions, flexContentAlignOptions } from 'styleGuide';
import { camelToKebabCase } from 'utilities';

const getStyles = props => {
  const suffix = props.reverse ? '-reverse' : '';
  let styles = `display: ${props.inline ? 'inline-' : ''}flex;`;
  if (props.center) {
    styles += `
      align-items: center;
      justify-content: center;
    `;
  }
  if (props.direction) {
    styles += `
      flex-direction: ${props.direction}${suffix};
    `;
  }
  if (props.full) {
    styles += props => {
      if (typeof props.full === 'string') {
        return `
          ${props.full}: 100%;
        `;
      } else {
        return `
          width: 100%;
          height: 100%;
          flex-basis: 100%;
        `;
      }
    };
  }
  if (props.gap) {
    styles += `
      gap: ${props.gap}px;
    `;
  }
  if (props.wrap) {
    styles += `
      flex-wrap: wrap${
        props.reverse || props.wrap === 'reverse' ? '-reverse' : ''
      };
    `;
  }
  ['alignContent', 'alignItems', 'justifyContent', 'order']
    .filter(
      type => props[type] && props[type] != null && +props[type].length > 0,
    )
    .forEach(type => {
      styles += `
        ${camelToKebabCase(type)}: ${props[type]};
      `;
    });
  return styles;
};

const StyledFlex = styled.div`
  ${props => getStyles(props)}
`;

const Flex = props => {
  return <StyledFlex {...props} />;
};

Flex.propTypes = {
  alignContent: PropTypes.oneOf(flexContentAlignOptions),
  alignItems: PropTypes.oneOf(
    [...flexOptions].filter(o => o.slice(0, 6) !== 'space'),
  ),
  center: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row']),
  gap: PropTypes.number,
  full: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  inline: PropTypes.bool,
  justifyContent: PropTypes.oneOf(flexContentAlignOptions),
  order: PropTypes.number,
  reverse: PropTypes.bool,
  wrap: PropTypes.string,
};

Flex.defaultProps = {
  alignContent: 'stretch',
  alignItems: 'center',
  direction: 'row',
  justifyContent: 'flex-start',
};

export default deprecated(Flex, 'Please use Row, Col, or utility classes');
