import React from 'react';
import PropTypes from 'prop-types';
import ReactiveRecord, { Collection } from 'reactiverecord';

const VideoParticipantModel = ReactiveRecord.model('VideoParticipant');

const VideoParticipants = ({ children, ...props }) => {
  return (
    <Collection for={VideoParticipantModel} {...props}>
      {videoParticipants => {
        return children(videoParticipants);
      }}
    </Collection>
  );
};

VideoParticipants.propTypes = {
  children: PropTypes.func.isRequired,
};

export default VideoParticipants;
