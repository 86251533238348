import styled from 'styled-components';
import { screenSize } from 'styleGuide';
import { Row, Col } from 'components/ui/grid';
import PlusCircleButton from 'components/ui/buttons/PlusCircleButton';

export const LabelText = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
  line-height: 20px;
  color: ${({ theme }) => theme.mediumGray};
`;

export const ExpertiseFormContainer = styled(Row)`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.lightGray : '#f3f7ff'};
  border-radius: 5px;
  border: solid 1px #d3d4d8;
  margin-left: 0;
  width: 100%;
`;

export const RemoveLabelHandle = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.white};
  svg {
    color: white;
  }
`;

export const Label = styled.div`
  position: relative;
  box-sizing: content-box;
  height: inherit;
  padding: 7px 36px 7px 12px;
  line-height: 16px;
  color: white;
  background-color: ${({ theme }) => theme.darkGray};
  border-radius: 15px;
  cursor: pointer;
  margin-left: 15px;
  width: ${({ type }) => (type === 'talent' ? '20%' : '32%')};
  white-space: normal;
  display: flex;
  align-items: center;

  @media (max-width: ${screenSize.tablet}) {
    width: ${({ type }) => (type === 'talent' ? '25%' : '75%')};
  }
`;

export const StyledFormSection = styled(Row)`
  display: block;
  width: 100%;
  margin: ${({ type }) => (type === 'talent' ? '0.5em 0 0' : '0px')};
  padding: ${({ type }) => (type === 'talent' ? '10px;' : '0px')};
  .row {
    margin: 0;
    justify-content: space-between;
    .input-container {
      padding: 0;
    }
  }
  input {
    box-shadow: none;
  }

  label {
    margin-bottom: ${({ type }) => (type === 'talent' ? 'inherit' : '0')};
  }
  button {
    background-color: transparent;
    margin-left: 2px;
    &:disabled {
      pointer-events: none;
    }
    &:hover {
      background: transparent;
    }
    svg {
      margin: 0;
    }
  }
`;

export const StyledPlusCircleButton = styled(PlusCircleButton)`
  pointer-events: none;
  padding: 0;
  &:disabled {
    background-color: ${({ theme }) => theme.lightGray};
  }
`;

export const ExpertiseSubText = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.mediumGray};
`;

export const ListContainer = styled(Col)`
  position: relative;
  padding: 0;
`;

export const List = styled.div`
  position: absolute;
  overflow-y: scroll;
  max-height: 250px;
  width: 100%;
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.lightGray},
    0 15px 15px ${({ theme }) => theme.darkGray.alpha(0.3)};
  -webkit-overflow-scrolling: touch;
  z-index: 206; /* Current Rails dialog is set to 205 */
`;

export const ListItem = styled.div`
  display: block;
  padding: 5px 15px;
  color: ${({ theme }) => theme.darkGray};
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.primaryBlue};
    color: ${({ theme }) => theme.white};
    outline: 0;
  }
  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.primaryBlue};
    color: ${theme.white};
  `}
`;
