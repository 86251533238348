import ReactiveRecord, { Model } from 'reactiverecord';
const route = ':prefix/employers/cloud_statuses';

class CloudStatus extends Model {
  static routes = {
    index: route,
  };
  static schema = {
    name: String,
  };
}

export default ReactiveRecord.model('CloudStatus', CloudStatus);
