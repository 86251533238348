import moment from 'moment';

const CalendarTime = props => {
  const timeFormat = props.timeFormat || 'h:mm A';
  const dateFormat = props.dateFormat || 'MMM Do YYYY';
  return moment(props.timestamp).calendar(null, {
    sameElse: `${dateFormat} [at] ${timeFormat}`,
    lastWeek: `dddd [at] ${timeFormat}`,
  });
};

export default CalendarTime;
