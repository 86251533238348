import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/containers/shared/Layout';

const InviteContainer = props => {
  return (
    <Layout title="Welcome to The Mom Project" className="mb-5">
      <div
        className="grow-in-column"
        css={({ theme }) => `background-color: ${theme.lightBlue};`}
      >
        {props.children}
        <div className="mt-5 pb-5"></div>
      </div>
    </Layout>
  );
};
InviteContainer.propTypes = {
  children: PropTypes.any,
};
export default InviteContainer;
