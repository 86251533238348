import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/ui/buttons/Button';
import Flex from 'components/ui/Flex';
import XCircle from 'components/ui/icons/XCircle';
import { toKebabCase } from 'utilities';

const PillWrapper = styled(Flex)`
  border-radius: 25px;
  padding: 4px 5px 4px 10px;
  background-color: ${({ theme }) => theme.darkGray};
  span,
  button {
    line-height: 1;
  }
  .pill-remove {
    opacity: 0.65;
    transition: all 200ms ease-in-out;
    &:focus,
    &:hover {
      opacity: 1;
    }
  }
`;

const Pill = ({ text, isRemovable, onRemove }) => {
  const id = toKebabCase(text);
  return (
    <PillWrapper
      alignItems="center"
      className="text-white"
      data-id={`${id}-pill`}
      direction="row"
      gap={10}
      justifyContent="flex-start"
    >
      <span className="font-sm">{text}</span>
      {isRemovable ? (
        <Button
          className="p-0 pill-remove"
          data-id={`${id}-pill-remove-btn`}
          iconOnly
          inline
          onClick={onRemove}
        >
          <XCircle color="white" size={18} />
        </Button>
      ) : null}
    </PillWrapper>
  );
};

Pill.propTypes = {
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
  text: PropTypes.string,
};

export default Pill;
