import React from 'react';
import Flex from 'components/ui/Flex';
import Pill from 'components/ui/Pill';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import { useFilterContext } from './FilterContext';
import { formatMoneyFromCents } from './supporting';
import { UTM_PARAMS } from 'consts';

const FilterPills = () => {
  const { removeFilter, filters, clearAll, filtersConfig } = useFilterContext();

  if (!filtersConfig) {
    return null;
  }

  function handleRemoveFilter(item) {
    removeFilter(item.id);
  }

  function handleClear() {
    clearAll();
  }

  const excludedFilters = ['keyword', 'sort', 'page', ...UTM_PARAMS];

  const labels = [];
  Object.values(filtersConfig)
    .flat()
    .forEach(item => {
      labels[item.id] = item.label;
    });

  const filterBy = {
    new: 'New Jobs',
    matched: 'Recommended',
    saved: 'Saved jobs',
  };

  const items = Object.keys(filters)
    .filter(id => {
      if (excludedFilters.includes(id)) {
        return false;
      }
      // Make sure the capabilities are loaded before rendering those pills
      if (
        id.match(/^capabilities_category_ids/) &&
        !filtersConfig.capabilitiesCategories.length
      ) {
        return false;
      }
      return true;
    })
    .map(id => {
      const value = filters[id];
      let label = labels[id] || value;
      if (id === 'minimum_hourly_rate_cents' || id === 'hourly_rate_cents') {
        label = `${formatMoneyFromCents(value)} per hour`;
      } else if (id === 'minimum_salary_cents' || id === 'salary_cents') {
        label = `${formatMoneyFromCents(value)} per year`;
      } else if (id === 'filter_by') {
        label = filterBy[value];
      }
      return {
        id,
        label,
      };
    });

  return (
    <div>
      {items.length > 0 && (
        <Flex
          alignItems="center"
          direction="row"
          gap={8}
          justifyContent="flex-start"
          wrap="true"
        >
          {items.map(item => {
            return (
              <Pill
                isRemovable
                key={item.id}
                onRemove={() => handleRemoveFilter(item)}
                text={item.label}
              />
            );
          })}
          <ButtonLink
            id="clear-all"
            data-testid="clear-all"
            onClick={handleClear}
          >
            <strong>Clear All</strong>
          </ButtonLink>
        </Flex>
      )}
    </div>
  );
};

export default FilterPills;
