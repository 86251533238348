import React from 'react';

export const CBSNews = props => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 182 28"
      width="182px"
      height="28px"
      {...props}
    >
      <title>CBS News</title>
      <path
        fill="currentColor"
        d="M15.22 2.001c-3.375 0-6.609 1.35-9.007 3.724s-3.724 5.632-3.724 9.007 1.35 6.609 3.724 9.007c2.397 2.397 5.632 3.724 9.007 3.724s6.609-1.35 9.007-3.724c2.397-2.397 3.724-5.632 3.724-9.007s-1.35-6.609-3.724-9.007c-2.397-2.374-5.632-3.724-9.007-3.724zM15.22 7.354c3.491 0 6.842 1.396 9.309 3.863 1.047 1.047 1.885 2.234 2.513 3.537-0.628 1.303-1.489 2.49-2.513 3.537-2.467 2.467-5.818 3.863-9.309 3.863s-6.842-1.396-9.309-3.863c-1.047-1.047-1.885-2.234-2.513-3.537 0.628-1.303 1.489-2.49 2.513-3.537 2.467-2.467 5.818-3.863 9.309-3.863zM15.22 7.983c-1.792 0-3.514 0.721-4.771 1.978s-1.978 2.979-1.978 4.771 0.721 3.514 1.978 4.771 2.979 1.978 4.771 1.978 3.514-0.721 4.771-1.978 1.978-2.979 1.978-4.771-0.721-3.514-1.978-4.771c-1.257-1.257-2.979-1.978-4.771-1.978v0z"
      ></path>
      <path
        fill="currentColor"
        d="M34.819 14.722c0-9.386 2.616-12.618 5.847-12.618 4.462 0 6.924 6.77 7.694 9.386v-9.079c-0.769 0.615-1.077 0.923-2 0.923-0.769 0-2.154-0.462-2.616-0.769-0.615-0.308-1.846-0.615-2.924-0.615-4.001 0-10.771 5.232-10.771 12.925 0 9.079 6.001 12.925 10.771 12.925 1.231 0 2.462-0.462 3.077-0.923 0.615-0.308 1.693-0.923 2.616-0.923 0.769 0 1.231 0.308 2 0.923v-9.079c-0.615 3.539-3.231 9.54-7.694 9.54-1.231 0-3.077-0.616-3.847-1.385-0.462-0.308-2.154-1.539-2.154-11.233z"
      ></path>
      <path
        fill="currentColor"
        d="M63.299 13.898c4.924-1.077 5.847-3.231 5.847-5.539 0-3.077-3.539-6.001-8.463-6.001h-11.848v0.308h3.847v24.466h-3.847v0.308h12.31c3.693 0 9.079-2.308 9.079-7.078s-5.078-6.309-6.924-6.463zM64.838 8.359c0 5.232-1.693 5.386-3.385 5.386h-3.693v-11.079h2.77c2.308-0.154 4.308 0.462 4.308 5.693zM60.991 27.132h-3.231v-13.079h3.385c2.462 0 4.616 1.231 4.616 6.617s-1.846 6.463-4.77 6.463z"
      ></path>
      <path
        fill="currentColor"
        d="M85.034 11.493h0.308v-9.386h-0.308c0 0-0.769 0.923-2 0.923-1.077 0-2.924-1.077-4.77-1.077s-6.77 2-6.77 7.54c0 5.539 3.693 6.617 5.539 7.386s7.386 1.846 7.386 5.539c0 3.693-2.616 5.078-4.77 5.078s-4.924-1.539-6.463-4.924c-1.539-3.385-1.539-4.924-1.539-4.924h-0.308v9.54h0.308c0 0 0.615-0.923 1.539-0.923s4.155 1.693 6.617 1.693c2.462 0 7.694-2.616 7.694-8.309s-4.308-6.309-5.847-6.77c-1.539-0.462-7.386-1.539-7.386-5.693s4.308-5.693 6.924-3.847c2.462 1.693 3.847 6.77 3.847 8.155z"
      ></path>
      <path
        fill="currentColor"
        d="M172.699 12.745c-1.539-0.462-7.386-1.539-7.386-5.693s4.308-5.693 6.924-3.847 3.847 6.924 3.847 8.309h0.308v-9.386h-0.308c0 0-0.769 0.923-2 0.923-1.077 0-2.924-1.077-4.77-1.077s-6.77 2-6.77 7.54 3.693 6.617 5.539 7.386 7.386 1.846 7.386 5.539-2.616 5.078-4.77 5.078-4.924-1.539-6.463-4.924-1.539-4.924-1.539-4.924h-0.308v9.54h0.308c0 0 0.615-0.923 1.539-0.923s4.155 1.693 6.617 1.693c2.462 0 7.694-2.616 7.694-8.309-0.154-5.693-4.308-6.309-5.847-6.924zM164.083 2.127h-5.693v0.769h2.462l-5.539 18.003-5.386-18.003h2.77v-0.769h-9.232v0.769h1.539l2.308 7.54-3.231 10.463-5.232-18.157h2.154v-0.769h-32.621v0.769h2.77v15.849l-10.156-16.618h-7.232v0.769h2.77v23.85h-2.77v0.616h6.155v-0.616h-2.616v-21.388l13.387 22.004h1.077v-24.312h5.078v23.85h-2.77v0.616h19.542v-7.848h-0.462c0 0-0.923 4.462-2.924 5.847-1.077 0.769-2.154 1.231-2.924 1.385h-6.001v-12.464h1.693c1.077 0 1.847 0.308 2.77 1.077 0.923 0.923 1.539 4.001 1.539 4.001h0.615v-10.925h-0.615c0 0-0.308 1.385-0.923 2.924-0.462 1.539-2.462 2.154-3.385 2.154-0.615 0-1.539 0-1.847 0v-10.771h5.386c1.077 0.308 2.616 0.923 3.385 1.846 1.385 1.693 1.693 3.539 1.693 3.539h0.615v-5.539h2.154l7.54 25.389h0.923l5.078-16.465 4.924 16.465h0.923l7.848-25.389h2.308l0.154-0.462z"
      ></path>
    </svg>
  );
};
