import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ANALYTICS, SHORT_FORMAT_DATE_OPTIONS } from 'consts';
import AnalyticsContext from '../../shared/analytics/AnalyticsContext';
import Location from './Location';
import EditUsers from 'containers/employer_application/job_posts/EditUsers';
import { useAppSettingsRQ } from 'requests';
import { getFormattedDate } from 'utilities';
import ProjectActions from './ProjectActions';

function OpenProject({ job }) {
  const { appSettings, isLoading } = useAppSettingsRQ();
  const { trackEvents } = useContext(AnalyticsContext);
  if (isLoading) {
    return null;
  }
  const {
    project_title,
    bid_count,
    created_at,
    city_state_list,
    id,
    posted_by,
    remote_percentage,
  } = job.attributes;
  const {
    view_project_link,
    review_project_link,
    review_bids_link,
    duplicate_project_link,
  } = job.links;

  const createdAt = getFormattedDate(
    new Date(created_at),
    SHORT_FORMAT_DATE_OPTIONS,
  );
  return (
    <div className="project">
      <div className="box box-md box-shadow-sm">
        <div className="row">
          <div className="basic-info col-lg-6">
            <div className="title font-md">{project_title}</div>
          </div>
          <ProjectActions
            copyPath={duplicate_project_link}
            editPath={review_project_link}
            viewPath={view_project_link}
            showProjectRemoval={appSettings.project_removal}
            projectSlug={id}
            projectTitle={project_title}
          />
        </div>
        <div className="secondary-info row justify-content-between">
          <div className="col-md-3 text-left pl-0 pb-3 pb-lg-0">
            <span className="font-heavy d-block mb-1">Created</span>
            {createdAt}
          </div>
          <div className="col-md-3 text-left pl-0 pb-3 pb-lg-0">
            <span className="font-heavy d-block mb-1">Posted by</span>
            {posted_by}
          </div>
          <Location
            cityStateList={city_state_list}
            remotePercentage={remote_percentage}
            containerClassnames="col-md-3"
          />
          <div className="edit-container col-md-3 pr-0">
            <a
              onClick={() =>
                trackEvents(ANALYTICS.EVENT_TYPES.Click)({
                  target: 'Review Bids',
                })
              }
              href={review_bids_link}
              className="tmp-btn lg btn-edit-job"
              data-testid="review-button"
            >
              Applicants ({bid_count})
            </a>
          </div>
        </div>
        <div className="text-left mt-3">
          <EditUsers project={job.attributes} />
        </div>
      </div>
    </div>
  );
}

OpenProject.propTypes = {
  job: PropTypes.object.isRequired,
};

export default OpenProject;
