import React from 'react';
import {
  SectionSubtitle,
  ListItem,
  DegreeTitle,
  SideSectionContainer,
} from './styles';
import Certifications from 'resources/certifications/Certifications';

const PreviewCertifications = () => {
  return (
    <Certifications>
      {certifications =>
        certifications.length > 0 ? (
          <SideSectionContainer>
            <SectionSubtitle className="mb-3">Certifications</SectionSubtitle>
            <ul>
              {certifications.map(certification => (
                <ListItem key={certification.id}>
                  <DegreeTitle>{certification.title}</DegreeTitle>
                  {certification.issued_by && (
                    <div>{certification.issued_by}</div>
                  )}
                  <div>{certification.year_issued}</div>
                </ListItem>
              ))}
            </ul>
          </SideSectionContainer>
        ) : null
      }
    </Certifications>
  );
};

export default PreviewCertifications;
