import Button from 'components/ui/buttons/Button';
import LinkButton from 'components/ui/buttons/LinkButton';
import { screenSizeInt } from 'styleGuide';
import styled, { css } from 'styled-components';

export const headerStyles = css(
  ({ theme }) => `
  background-color: ${theme.headerBackground};
  .navbar-collapse {
    top: 80px;
    background-color: ${theme.lightBlue};
    flex-grow: 1;
    flex-basis: 100%;
    justify-content: center;
  }
`,
);
export const HeaderList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #ffffff;
  box-shadow: inset 0 1px 6px 0 rgb(0 0 0 / 15%);
  @media (min-width: ${screenSizeInt.laptopM + 1}px) {
    flex-direction: row;
    justify-content: center;
    height: auto;
    box-shadow: none;
  }
`;

export const HamburgerListItem = styled.li`
  list-style: none;
  width: 100%;
  @media (min-width: ${screenSizeInt.laptopM + 1}px) {
    display: none;
  }
`;

export const HeaderListItem = styled.li(
  ({ theme }) =>
    css`
      text-align: center;
      padding: 24px;
      list-style: none;
      width: 100%;
      cursor: pointer;
      @media (min-width: ${theme.screenSizes.laptopS}) {
        font-size: ${theme.fontSizes.base};
        padding: 16px;
      }
      @media (max-width: ${theme.screenSizes.laptopS}) {
        text-align: left;
        padding: 24px;
        list-style: none;
        width: 100%;
      }
      &::after {
        display: block;
        content: attr(data-text);
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: bold;
      }
    `,
);

export const HeaderLink = styled.a(
  ({ theme }) =>
    css`
      color: ${theme.darkerGray};
      font-size: ${theme.fontSizes.lg};
      padding: 24px;
      font-weight: ${theme.fontWeights.fontBold};
      text-transform: none;
      transition: none;
      &:hover {
        color: ${theme.primaryBlue};
        font-weight: ${theme.fontWeights.fontHeavy};
      }
      @media (min-width: ${theme.screenSizes.laptopS}) {
        font-size: ${theme.fontSizes.base};
        padding: 0;
      }
    `,
);

export const HeaderButtonItem = styled.li(
  ({ theme }) =>
    css`
      ${'' /* styles for hamburger nav */}
      text-align: center;
      padding: 10px;
      list-style: none;
      width: 100%;
      font-size: ${theme.fontSizes.base};
      ${'' /* styles for non-hamburger navbar menu */}
      @media (min-width: ${theme.screenSizes.laptopM}) {
        padding: 9px;
      }
    `,
);

export const HeaderButton = styled(Button)(
  ({ theme }) =>
    css`
      &.header {
        /* styles for hamburger nav: at 1305px or lower */
        font-size: ${theme.fontSizes.md};
        width: 93%;
        padding: 8px;
        border-radius: 20px;
        line-height: 1.5;
        height: auto;
        /* styles for non-hamburger navbar menu: at 1306px or higher */
        @media (min-width: ${screenSizeInt.laptopM + 1}px) {
          font-size: ${theme.fontSizes.base};
          padding: 8px;
          max-width: 150px;
        }
      }
      &.homepage {
        padding: 1.25rem 3rem;
        line-height: 1;
      }
    `,
);

export const HeaderJoinButton = styled(LinkButton)(
  ({ theme }) =>
    css`
      border-radius: 20px;
      padding: 8px;
      width: 93%;
      font-size: ${theme.fontSizes.md};
      @media (min-width: ${theme.screenSizes.laptopM}) {
        font-size: ${theme.fontSizes.base};
      }
    `,
);

export const HeaderHeading = styled.div(
  ({ theme }) =>
    css`
      font-weight: bold;
      color: ${theme.primaryBlue};
    `,
);

export const ButtonsContainer = styled.div(
  ({ theme }) =>
    css`
      max-width: 550px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      flex-direction: column;
      @media (min-width: ${theme.screenSizes.laptopS}) {
        flex-direction: row;
      }
    `,
);

export const ModalSectionContainer = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      margin-bottom: 5px;
      padding: 30px 15px;
      a {
        max-width: 220px;
      }
      @media (min-width: ${theme.screenSizes.laptopS}) {
        width: 305px;
      }
    `,
);
