import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rescheduleAppointment } from 'components/containers/employer/projects/bids/requests';
import ButtonGroup from 'components/ui/buttons/ButtonGroup';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  gap: 4.5rem;
`;

const ConfirmReschedule = ({
  handleOnClose,
  handleOnSuccess,
  interview,
  secondaryButtonText,
}) => {
  const handleRescheduleRequest = async (appointmentId, handleOnSuccess) => {
    const response = await rescheduleAppointment(appointmentId);
    if (response.status === 202) {
      handleOnSuccess();
    } else {
      const message = `Error rescheduling the appointment ${appointmentId}`;
      throw new Error(message);
    }
  };

  return (
    <ContentWrapper>
      <div className="text-center mt-4">
        No problem! We’ll ask the candidate to choose another time slot based on
        your availability. Your calendar will update with the time they select.
      </div>
      <ButtonGroup
        id="confirm-request-sent-btns"
        data-id="confirm-request-sent-btns"
        primary={{
          id: 'view-applicants-btn',
          onClick: () => handleRescheduleRequest(interview.id, handleOnSuccess),
          text: 'Ask to reschedule',
        }}
        secondary={{
          id: 'got-it-btn',
          onClick: handleOnClose,
          text: secondaryButtonText || 'Nevermind',
        }}
      />
    </ContentWrapper>
  );
};

ConfirmReschedule.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleOnSuccess: PropTypes.func.isRequired,
  interview: PropTypes.object,
  secondaryButtonText: PropTypes.string,
};

export default ConfirmReschedule;
