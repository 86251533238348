import React from 'react';
import PropTypes from 'prop-types';
import FunctionsCapabilitiesContext from './FunctionsCapabilitiesContext';

class FunctionsCapabilitiesProvider extends React.Component {
  render() {
    return (
      <FunctionsCapabilitiesContext.Provider value={this.getStateAndHelpers()}>
        {this.props.children}
      </FunctionsCapabilitiesContext.Provider>
    );
  }

  // Returns a collection of capabilities for the selected function
  getVisibleCapabilities = () => {
    const { props } = this;
    if (props.selectedSuperheroFunction) {
      return props.capabilities[props.selectedSuperheroFunction];
    }
    return [];
  };

  // Returns a collection of capabilities excluding the capabilities that are selected
  getOtherCapabilities = () => {
    const { props } = this;
    const allCapabilities = Object.keys(props.capabilities).reduce(
      (capabilities, key) => {
        return [...capabilities, ...props.capabilities[key]];
      },
      [],
    );

    const visibleCapabilities = this.getVisibleCapabilities().map(
      capability => capability[0],
    );

    return allCapabilities.filter(capability => {
      return (
        !props.selectedCapabilities.includes(capability[0]) &&
        !visibleCapabilities.includes(capability[0])
      );
    });
  };

  onChange = (key, value) => {
    const { props } = this;
    switch (key) {
      case props.superheroFunctionStateKey: {
        props.onChange({
          [props.superheroFunctionStateKey]: value,
          // Resets the selected capabilities if they exist
          [props.capabilitiesStateKey]: [],
          [props.otherCapabilitiesStateKey]: [],
        });
        break;
      }
      case this.props.capabilitiesStateKey: {
        props.onChange({
          [props.capabilitiesStateKey]: value,
          // Removes from other capabilities if it exists
          [props.otherCapabilitiesStateKey]:
            props.selectedOtherCapabilities.filter(c => !value.includes(c)),
        });
        break;
      }
      default: {
        props.onChange({ [key]: value });
      }
    }
  };

  getStateAndHelpers = () => {
    const {
      onChange,
      getSuperheroFunctionsProps,
      getCapabilitiesProps,
      getOtherCapabilitiesProps,
    } = this;
    return {
      onChange,
      getSuperheroFunctionsProps,
      getCapabilitiesProps,
      getOtherCapabilitiesProps,
    };
  };

  getSuperheroFunctionsProps = props => {
    return {
      stateKey: this.props.superheroFunctionStateKey,
      selected: this.props.selectedSuperheroFunction,
      options: this.props.superheroFunctions,
      onChange: this.onChange,
      ...props,
    };
  };

  getCapabilitiesProps = props => {
    return {
      stateKey: this.props.capabilitiesStateKey,
      selected: this.props.selectedCapabilities,
      options: this.getVisibleCapabilities(),
      onChange: this.onChange,
      ...props,
    };
  };

  getOtherCapabilitiesProps = props => {
    return {
      stateKey: this.props.otherCapabilitiesStateKey,
      selected: this.props.selectedOtherCapabilities,
      defaultValue: this.props.selectedOtherCapabilities,
      value: this.props.selectedOtherCapabilities,
      options: this.getOtherCapabilities(),
      onChange: e => this.onChange('capabilities_other', e.target.value),
      ...props,
    };
  };
}

FunctionsCapabilitiesProvider.propTypes = {
  superheroFunctions: PropTypes.array.isRequired,
  capabilities: PropTypes.object.isRequired,
  selectedSuperheroFunction: PropTypes.number,
  selectedCapabilities: PropTypes.array,
  selectedOtherCapabilities: PropTypes.array,
  superheroFunctionStateKey: PropTypes.string,
  capabilitiesStateKey: PropTypes.string,
  otherCapabilitiesStateKey: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

FunctionsCapabilitiesProvider.defaultProps = {
  selectedCapabilities: [],
  selectedOtherCapabilities: [],
  superheroFunctionStateKey: 'selectedSuperheroFunction',
  capabilitiesStateKey: 'selectedCapabilities',
  otherCapabilitiesStateKey: 'selectedOtherCapabilities',
};

export default FunctionsCapabilitiesProvider;
