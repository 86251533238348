import styled, { css } from 'styled-components';
export const RichTextEditorWrapper = styled.div(({ height, theme }) => {
  const formattedHeight = height === 'auto' ? height : `${height}px`;

  return css`
    p,
    ol {
      margin-bottom: ${theme.fontSizes.base};
    }
    .ql-stroke {
      stroke: ${theme.darkGray};
    }
    .ql-toolbar {
      border: 1px solid ${theme.lightGray};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-family: ${theme.fonts.primary};
    }
    .ql-toolbar button:hover,
    .ql-toolbar button:focus,
    .ql-toolbar .ql-picker-item:hover,
    .ql-toolbar .ql-picker-label:hover,
    .ql-toolbar .ql-picker-label .ql-active,
    .ql-toolbar .ql-picker-label.ql-active {
      color: ${theme.primaryBlue};
    }
    .ql-toolbar button.ql-active .ql-stroke,
    .ql-toolbar button:hover .ql-stroke,
    .ql-toolbar button:focus .ql-stroke {
      stroke: ${theme.primaryBlue};
    }
    .ql-container {
      border: 1px solid ${theme.lightGray};
      height: ${formattedHeight};
    }
    .ql-editor {
      background-color: ${theme.lightBlue};
      font-size: ${theme.fontSizes.base};
      font-family: ${theme.fonts.primary};
      ${({ hasError, theme }) =>
        hasError && `box-shadow: inset 0 0 0 1px ${theme.red};`}
    }
    .ql-container,
    .ql-editor {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .ql-editor ol li:not(.ql-direction-rtl) {
      padding-left: 0;
    }
    .ql-picker.ql-size .ql-picker-item[data-value='large']:before,
    .ql-picker.ql-size .ql-picker-label[data-value='large']:before {
      content: 'Header';
      font-family: ${theme.fonts.title};
      font-weight: ${theme.fontWeights.fontHeavy};
      font-size: ${theme.fontSizes.md};
    }
    .ql-picker-label {
      svg {
        width: 0 !important;
      }
    }
    .ql-size.ql-picker {
      .ql-picker-label:after {
        content: '\f078';
        font-family: 'FontAwesome';
        font-size: ${theme.fontSizes.xs};
        position: absolute;
        top: 1px !important;
        right: 0;
      }
    }
    .ql-size.ql-picker.ql-expanded {
      .ql-picker-label:after {
        content: '\f077';
        font-family: 'FontAwesome';
        font-size: ${theme.fontSizes.xs};
        position: absolute;
        top: 1px !important;
        right: 0;
      }
    }
  `;
});

export const CharacterTracker = styled.div(
  ({ theme }) => css`
    color: ${theme.mediumGray};
    font-size: ${theme.fontSizes.xs};
    font-style: italic;
    margin-top: 0.5rem;
  `,
);

export const LabelText = styled.div`
  padding-bottom: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
  line-height: 1.4;
  color: ${({ theme }) => theme.mediumGray};
`;
