import React from 'react';

import {
  LabelCaretContainer,
  LabelHelptextWrapper,
  LabelWrapper,
  StyledHR,
} from 'components/shared/Header/stylesV2';
import {
  HamburgerListItem,
  HeaderLink,
  HeaderListItem,
} from 'components/shared/Header/PublicHeaderStyles';
import { mediaSetQueries } from 'styleGuide';
import { navItems } from 'components/shared/Header/NavItems';

import { useMediaSet } from 'use-media-set';

export const PublicHeaderComponent2024 = () => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const hamburgerScreenSize = mediaStates.has('mobileOrLaptopM');

  // for !hamburgerScreenSize
  const items = [
    navItems.publicRoutes2024.talent,
    navItems.publicRoutes2024.companies,
    navItems.publicRoutes2024.about,
  ];

  return (
    <>
      {!hamburgerScreenSize && (
        <>
          {items.map(item => (
            <HeaderListItem key={item.label} data-text={item.label}>
              <HeaderLink href={item.href} target={item.target}>
                {item.label}
              </HeaderLink>
            </HeaderListItem>
          ))}
        </>
      )}
      {hamburgerScreenSize &&
        items.map(item => {
          return (
            <HamburgerListItem key={item.label}>
              <LabelCaretContainer
                onClick={() => (window.location.href = item.href)}
              >
                <LabelHelptextWrapper>
                  <LabelWrapper>{item.label}</LabelWrapper>
                </LabelHelptextWrapper>
              </LabelCaretContainer>
              <StyledHR />
            </HamburgerListItem>
          );
        })}
    </>
  );
};
