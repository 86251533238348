import React from 'react';

const Briefcase = props => (
  <svg {...props} viewBox="0 0 19 17" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(0 1)"
      strokeWidth="1.3"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
    >
      <rect x=".65" y="2.4" width="17.384" height="12.971" rx="3" />
      <path d="M6.044 2.09h0A2.09 2.09 0 018.134 0h2.432c1.145 0 2.074.929 2.074 2.074" />
      <rect x="7.353" y="7.58" width="3.978" height="2.658" rx="1.329" />
      <path d="M11.217 8.988h6.606M.53 8.988h6.606" />
    </g>
  </svg>
);

export default Briefcase;
