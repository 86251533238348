import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getTimeZones } from '@vvo/tzdb';

import Select from 'components/ui/inputs/Select';
import { getTimeZoneFromBrowser } from 'utilities';

export const TimezoneOptions = () => {
  const options = getTimeZones();

  return (
    <>
      <option disabled value="">
        Select timezone
      </option>
      {options.map(name => {
        const n = name.name;
        return (
          <option data-testid={n} key={n} value={n}>
            {name.currentTimeFormat}
          </option>
        );
      })}
    </>
  );
};

const TimezoneSelector = ({ labelText, onChange, selectedTimezone }) => {
  const [selected, setSelected] = useState(
    selectedTimezone || getTimeZoneFromBrowser(),
  );

  const valueSelected = ev => {
    const value = ev.target.value;
    setSelected(value);
    onChange(value);
  };

  return (
    <Select
      classes="p-0"
      id="time_zone"
      labelText={labelText}
      onChange={valueSelected}
      title={selectedTimezone}
      truncate
      value={selected}
    >
      <TimezoneOptions />
    </Select>
  );
};

TimezoneSelector.propTypes = {
  labelText: PropTypes.string,
  onChange: PropTypes.func,
  selectedTimezone: PropTypes.string,
};

export default TimezoneSelector;
