import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonsContainer,
  ModalSectionContainer,
} from 'components/shared/Header/PublicHeaderStyles';
import AnalyticsContext from 'components/shared/analytics/AnalyticsContext';
import LinkButton from 'components/ui/buttons/LinkButton';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import Dialog from 'components/ui/Dialog';
import { ANALYTICS, URLS } from 'consts';

function JoinLoginModal({ initialType, show, toggle }) {
  const { trackEvents } = useContext(AnalyticsContext);
  const [type, setType] = useState(initialType);
  const isJoin = type === 'join';
  return (
    <Dialog
      onHide={toggle}
      show={show}
      title={isJoin ? 'Join us at The Mom Project' : 'Log in to your account'}
      large
    >
      <ButtonsContainer data-id="header-btn-container">
        <ModalSectionContainer>
          <LinkButton
            className="mb-3 mt-3"
            data-id="header-individual-join-btn"
            data-testid="join-as-individual"
            href={URLS[isJoin ? 'talentSignup' : 'talentLogin']}
            size="xl"
            secondary
          >
            {isJoin ? 'Join as talent' : 'Talent log in'}
          </LinkButton>
        </ModalSectionContainer>
        <ModalSectionContainer>
          <LinkButton
            className="mb-3 mt-3"
            data-id="join-as-company-btn"
            href={URLS[isJoin ? 'employerSignup' : 'employerLogin']}
            onClick={() =>
              trackEvents(ANALYTICS.EVENT_TYPES.Click)({
                event_category:
                  ANALYTICS.EVENT_CATEGORIES[
                    isJoin ? 'Company_Create_Account' : 'Company_Login'
                  ],
                target: isJoin ? 'Company Signup' : 'Login Button',
                userType: ANALYTICS.USER_TYPES.Employer,
              })
            }
            size="xl"
            secondary
          >
            {isJoin ? 'Join as a company' : 'Company log in'}
          </LinkButton>
        </ModalSectionContainer>
      </ButtonsContainer>
      <div className="text-center">
        {isJoin ? 'Already have an account? ' : 'New to The Mom Project? '}
        <ButtonLink
          className="link-home-cta"
          data-id="link-home-cta"
          onClick={() => setType(isJoin ? 'login' : 'join')}
          link
          inline
        >
          {isJoin ? 'Log in here' : 'Join here'}
        </ButtonLink>
      </div>
    </Dialog>
  );
}

JoinLoginModal.propTypes = {
  initialType: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func,
};

export default JoinLoginModal;
