import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { HeaderButton } from './Header/PublicHeaderStyles';
import themed from 'components/hoc/themed';
import AnalyticsContext from 'components/shared/analytics/AnalyticsContext';
import JoinLoginModal from 'components/shared/Header/JoinLoginModal';
import ShowToggle from 'components/ui/ShowToggle';
import { ANALYTICS, URLS } from 'consts';
import { useAppSettings } from 'utilities/hooks';

const JoinButton = ({ buttonLabel, location }) => {
  const { trackEvents } = useContext(AnalyticsContext);
  const { join_ex_tx } = useAppSettings();

  return (
    <>
      <ShowToggle preventDefault>
        {(show, toggle) => (
          <>
            <HeaderButton
              className={location}
              data-id={`${location}-join-btn`}
              onClick={() => {
                join_ex_tx ? (window.location.href = URLS.join) : toggle();
                trackEvents(ANALYTICS.EVENT_TYPES.Click)({
                  event_category: ANALYTICS.EVENT_CATEGORIES.General_Join,
                  target: 'Join',
                });
              }}
            >
              {buttonLabel}
            </HeaderButton>
            <JoinLoginModal initialType="join" show={show} toggle={toggle} />
          </>
        )}
      </ShowToggle>
    </>
  );
};

JoinButton.propTypes = {
  buttonLabel: PropTypes.string,
  location: PropTypes.oneOf(['header', 'homepage']),
};

JoinButton.defaultProps = {
  buttonLabel: 'Join',
  location: 'header',
};

export default themed(JoinButton);
