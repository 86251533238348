import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styleGuide';

const Phone = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-513 -100)">
        <g>
          <path
            stroke={props.color}
            strokeWidth="2"
            d="M21 10.5C21 16.3 16.299 21 10.5 21S0 16.3 0 10.5C0 4.701 4.701 0 10.5 0S21 4.7 21 10.5z"
            transform="translate(514 101)"
          />
          <path
            fill={props.color}
            d="M9.072 7.818s.45.617.052 1.017c-.261.263-.619.89-.434 1.158.185.268 2.462 2.462 2.462 2.462s.26.228.434.145c.174-.083 1.014-.58 1.014-.58s.347-.116.724.146c.381.264 1.554 1.042 1.554 1.042s.334.42.1.792c-.234.37-.966 1.105-.966 1.105s-.29.197-.95.04c-.658-.156-2.198-.523-4.308-2.632-1.951-1.95-2.81-4.575-2.81-4.575s-.061-.458.217-.865 1.1-1.448 1.72-.76c.646.72 1.191 1.505 1.191 1.505z"
            transform="translate(514 101) rotate(45 10.5 10.645)"
          />
        </g>
      </g>
    </g>
  </svg>
);

Phone.propTypes = {
  color: PropTypes.string,
};

Phone.defaultProps = {
  color: colors.blue.primary,
};

export default Phone;
