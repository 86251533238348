import React from 'react';
import PropTypes from 'prop-types';

const DownCaret = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      viewBox="0 0 22 22"
    >
      <path
        fill={props.fillColor}
        d="M1.312 5.312c.416-.416 1.091-.416 1.507 0l8.103 8.103 8.104-8.103c.416-.416 1.091-.416 1.507 0 .416.416.416 1.091 0 1.507 0 0-6.645 6.644-8.886 8.882-.403.404-1.054.4-1.458-.004L1.312 6.819c-.416-.416-.416-1.091 0-1.507"
      />
    </svg>
  );
};

DownCaret.propTypes = {
  fillColor: PropTypes.any,
  width: PropTypes.number,
};

DownCaret.defaultProps = {
  fillColor: 'currentColor',
  width: 14,
};

export default DownCaret;
