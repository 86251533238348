import React, { forwardRef, useMemo } from 'react';
import { Label } from './Input/styles';
import { Validate } from 'reactiverecord';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ErrorMessage from '../labels/ErrorMessage';
import SuccessMessage from 'components/ui/labels/SuccessMessage';
import ChevronDown from 'components/ui/icons/ChevronDown';

const StyledSelect = styled.select`
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 13px 48px 13px 15px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
  line-height: 24px;
  color: ${({ theme }) => theme.darkGray};
  cursor: pointer;
  background-color: ${({ white, theme }) =>
    white ? theme.white : theme.lightBlue};
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lightGray};
  -webkit-appearance: none;

  ${({ hasSuccess, theme }) =>
    hasSuccess && `box-shadow: inset 0 0 0 1px ${theme.green};`}
  ${({ hasError, theme }) =>
    hasError && `box-shadow: inset 0 0 0 1px ${theme.red};`}

  &:focus,
  .forceFocusState & {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primaryBlue};
    font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.lightGray};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lightGray};
  }
  option {
    font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
  }
`;

const StyledCaret = styled.i`
  bottom: 19.275px !important;
  ${props => (props.small ? 'right: 10px !important;' : '')}
`;
const Select = forwardRef(
  (
    { secondaryLabelText, successText, labelText, white, children, ...props },
    forwardedRef,
  ) => {
    const generatedId = useMemo(() => `select-${Select.idCounter++}`, []);
    const id = props.id || generatedId;
    return (
      <Validate {...props} ref={forwardedRef}>
        {(selectProps, errorText, validating) => (
          <Label htmlFor={id} isDisabled={!!selectProps.disabled || validating}>
            {labelText ? <span className="label-text">{labelText}</span> : null}
            {secondaryLabelText && (
              <div className="secondary-label-text">{secondaryLabelText}</div>
            )}
            <div className="input-wrapper">
              <StyledCaret className="inline-icon" small={props.small}>
                <ChevronDown width={props.small ? 16 : 20} />
              </StyledCaret>
              <StyledSelect
                className={props.truncate ? 'text-overflow' : null}
                id={id}
                disabled={validating}
                white={white}
                hasError={!!errorText}
                hasSuccess={!!successText}
                {...selectProps}
              >
                {children}
              </StyledSelect>
            </div>
            {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
            {successText ? (
              <SuccessMessage>{successText}</SuccessMessage>
            ) : null}
          </Label>
        )}
      </Validate>
    );
  },
);

Select.propTypes = {
  labelText: PropTypes.string,
  secondaryLabelText: PropTypes.string,
  successText: PropTypes.string,
  children: PropTypes.node,
  /** White input for rendering on non-white background */
  white: PropTypes.bool,
  id: PropTypes.any,
  truncate: PropTypes.bool,
  small: PropTypes.bool,
};

Select.defaultProps = {
  truncate: false,
};

Select.idCounter = 0;
Select.displayName = 'Select';

export default Select;
