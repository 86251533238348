import React, { useRef, useState, useCallback, useEffect } from 'react';
import UploadIcon from 'components/ui/icons/UploadIcon';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import ConfirmationBanner from 'components/ui/ConfirmationBanner';
import Close from 'components/ui/icons/Close';
import ReactiveRecord from 'reactiverecord';
import PropTypes from 'prop-types';
import { URLS } from 'consts';
import {
  animationTime,
  uploadFile,
  UploaderLabel,
  DownloadLabel,
  ResumeWrapper,
} from './supporting';
import ManageResume from './ManageResume';
import ReplaceResume from './ManageResume/ReplaceResume';

const Resume = ReactiveRecord.model('Resume');

const ResumeUploader = ({
  downloadAttribute,
  nameAttribute,
  uploaderAttribute,
  uploaderPath,
  displayType,
  resume,
  afterSave,
  loaded,
  parseSkills,
  ...props
}) => {
  const uploaderRequest = useRef(null);
  const [errorText, setErrorText] = useState(null);
  const [inputKey, setInputKey] = useState(Math.random());
  const [progress, setProgress] = useState('0');
  const [stateDownloadUrl, setStateDownloadUrl] = useState(null);
  const [stateFileName, setStateFileName] = useState('Upload resume');
  const [stateResumeId, setStateResumeId] = useState(null);

  const [show, setShow] = useState(null);
  const [showActions, setShowActions] = useState(null);
  const downloadUrl =
    (resume && resume[downloadAttribute]) || stateDownloadUrl || null;
  const fileName = (resume && resume[nameAttribute]) || stateFileName || null;
  const resumeId = (resume && resume.id) || stateResumeId || null;

  const handleError = useCallback(errorText => {
    setErrorText(errorText);
    setProgress('0');
  }, []);
  const handleFileProcessed = useCallback(
    processedFile => {
      setProgress('100');
      setTimeout(() => {
        setStateResumeId(processedFile.id);
        setStateDownloadUrl(processedFile[downloadAttribute]);
        setStateFileName(processedFile[nameAttribute]);
      }, animationTime);
      if (afterSave) {
        afterSave(processedFile);
      }
      setShowActions(true);
      if (displayType === 'management') {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    },
    [afterSave, displayType, downloadAttribute, nameAttribute],
  );
  const handleClick = useCallback(() => {
    if (uploaderRequest.current) {
      uploaderRequest.current.abort();
    }
  }, []);
  const handleChange = useCallback(
    event => {
      if (event.target.files.length) {
        const file = event.target.files[0];
        setErrorText(null);
        setStateFileName(file.name);
        setInputKey(Math.random());
        setProgress('0');
        const [request, uploader] = uploadFile(
          file,
          uploaderAttribute,
          uploaderPath,
          setProgress,
          parseSkills,
        );
        uploaderRequest.current = request;
        uploader.then(handleFileProcessed).catch(handleError);
      }
    },
    [
      uploaderAttribute,
      uploaderPath,
      handleFileProcessed,
      handleError,
      parseSkills,
    ],
  );
  const handleRemove = useCallback(
    event => {
      event.preventDefault();
      Resume.destroy(resumeId)
        .then(() => {
          setStateDownloadUrl(null);
          setStateResumeId(null);
          setErrorText(null);
          setStateFileName('Upload resume');
          setInputKey(Math.random());
          setProgress('0');
          setShowActions(false);
          if (afterSave) {
            afterSave(null);
          }
        })
        .catch(() => setErrorText('Could not delete this resume.'));
    },
    [afterSave, resumeId],
  );

  useEffect(() => {
    if (loaded && displayType === 'management') {
      setShowActions(true);
    } else if (displayType !== 'management' && resumeId) {
      setShowActions(true);
    }
  }, [loaded, displayType, resumeId]);

  return (
    <ResumeWrapper {...props}>
      <ConfirmationBanner in={show}>
        Success! Your resume was uploaded.
      </ConfirmationBanner>
      {displayType !== 'upload' && (
        <p>
          Your resume is used to match you to the best opportunities that fit
          your career goals and preferences. Supported file types are pdf, docx,
          and doc. We recommend you include months in employment periods.{' '}
          <a href={URLS.resumeGuidance} target="_blank" rel="noreferrer">
            Need more help?
          </a>
        </p>
      )}
      {showActions ? (
        <>
          {fileName && displayType === 'upload' && (
            <DownloadLabel>
              <a
                download
                target="_blank"
                rel="noreferrer noopener nofollow"
                href={downloadUrl}
                className="py-2 download-label__file"
              >
                {fileName}
              </a>
              <button
                id="delete-resume"
                className="download-label__remove"
                onClick={handleRemove}
              >
                <Close size={20} />
              </button>
            </DownloadLabel>
          )}
          {resume && displayType === 'manage' && (
            <ManageResume
              resume={resume}
              handleChange={handleChange}
              handleRemove={handleRemove}
            />
          )}
          {resume && displayType === 'update' && (
            <>
              <DownloadLabel className="mb-3">
                <a
                  href={downloadUrl}
                  title="View your resume"
                  target="_blank"
                  className="py-2 download-label__file d-flex justify-content-between"
                  rel="noreferrer"
                >
                  <span>{fileName}</span>
                  <span className="view mr-3 font-heavy text-primary-blue">
                    View
                  </span>
                </a>
              </DownloadLabel>
              <ReplaceResume
                className="text-sm-left text-center"
                resume={resume}
                handleChange={handleChange}
              />
            </>
          )}
          {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
        </>
      ) : (
        <>
          {displayType === 'management' && (
            <>
              <p className="m-0">You do not currently have a resume on file.</p>
            </>
          )}
          <UploaderLabel progressWidth={progress}>
            <UploadIcon width="20" className="mr-3" /> {stateFileName}
            <input
              key={inputKey}
              type="file"
              name="resume-uploader"
              onClick={handleClick}
              onChange={handleChange}
            />
          </UploaderLabel>
          {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
        </>
      )}
    </ResumeWrapper>
  );
};

ResumeUploader.defaultProps = {
  downloadAttribute: Resume.downloadAttribute,
  nameAttribute: Resume.nameAttribute,
  uploaderAttribute: Resume.uploaderAttribute,
  uploaderPath: new Resume().routeFor('create'),
  displayType: 'upload',
  loaded: false,
};

ResumeUploader.propTypes = {
  downloadAttribute: PropTypes.string.isRequired,
  nameAttribute: PropTypes.string.isRequired,
  resume: PropTypes.instanceOf(Resume),
  uploaderAttribute: PropTypes.string.isRequired,
  uploaderPath: PropTypes.string.isRequired,
  displayType: PropTypes.string,
  afterSave: PropTypes.func,
  loaded: PropTypes.bool,
  parseSkills: PropTypes.bool,
};

export default ResumeUploader;
