import styled from 'styled-components';
import theme from 'theme';

export const navbarItemHeight = 60;
const navbarItemHeightRem = `${navbarItemHeight / theme.em}rem`;

export const StyledHeader = styled.header(
  ({ theme }) => `
  z-index: 5;
  color: ${theme.headerFontColorV2};
  background-color: ${theme.headerBackgroundV2};
  box-shadow: ${theme.boxShadow100};
  @media (min-width: ${theme.screenSizes.laptopS}) {
    display: flex;
  }
  .navbar {
    display: flex;
    flex-wrap: wrap;
    padding: 0; /* Override of Bootstrap 4.4.1 _navbar.scss  */
    justify-content: ${theme.headerItemsAligned}; /* publicTheme */
    /* Adjust for switch from hamburger */
    @media (min-width: ${theme.screenSizes.laptopS}) {
      justify-content: ${theme.headerItemsAligned}; /* publicTheme */
    }
    /* Adjust for switch to nav items at edges of fixed width container */
    @media (min-width: ${
      parseInt(theme.screenSizes.laptopL) + theme.em * 2.6 /* 2.6 = 1.3x2 */
    }px) {
      width: ${theme.screenSizes.laptopL};
      padding-right: 0;
      padding-left: 0;
      margin: 0 auto;
    }
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${navbarItemHeightRem};
    padding-top: 0; /* Override Bootstrap 4.4.1 */
    padding-bottom: 0; /* Override Bootstrap 4.4.1 */
    margin-left: 1.3rem;
    color: ${theme.companyLogoColorBlue};
    @media (max-width: ${theme.screenSizes.laptopM}) {
      &.hidden {
        display: none;
      }
    }
    svg {
      display: block;
    }
  }
  .navbar-collapse {
    position: relative;
    top: 0 !important;
    background-color: ${theme.white};
    box-shadow: inset 0 4px 3px -3px rgba(0, 0, 0, 0.15);
    a[class*='HeaderLink'] {
      display: block;
      padding: 0;
    }
  }
  .logo {
    width: 230px;
    height: 19px;
  }

  .navbar-toggler {
    height: ${navbarItemHeightRem};
    padding: 0 1.3rem; /* Override Bootstrap 4.4.1 _navbar.scss */
    color: ${theme.navTogglerBlue};
    border: 0;
    -webkit-appearance: none;
    svg {
      display: block;
      margin: 0 auto;
    }
  }

  button[aria-expanded='true'] {
    .hamburger-menu {
      display: none;
    }
    .close-button {
      display: block;
    }
  }
  button[aria-expanded='false'] {
    .close-button {
      display: none;
    }
  }
  `,
);

export const listItemSharedStyles = ({ theme }) => `
  @media (max-width: ${parseInt(theme.screenSizes.laptopS) - 1}px) {
    &.hidden-mobile {
      display: none;
    }
  }
  @media (min-width: ${theme.screenSizes.laptopS}) {
    &#resources-dropdown-btn {
      font-size: ${theme.fontSizes.sm};
      padding: 0 2rem;
      white-space: nowrap;
    }
    &.dropdown-btn {
      font-size: ${theme.fontSizes.sm};
      margin-right: 1.3rem;
      white-space: nowrap;
    }
    &.mobile-only-item {
      display: none;
    }
  }
`;
export const StyledHR = styled.hr`
  margin-right: 2%;
  margin-left: 2%;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border-width: 1px;
  color: #dadbdd;
`;

export const List = styled.ul(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; /* Override of Bootstrap 4.4.1 reboot */
  list-style: none;
  @media (min-width: ${theme.screenSizes.laptopS}) {
    flex-direction: row;
    li + li {
      margin-left: 1rem;
    }
  }
  @media (max-width: ${theme.screenSizes.laptopS}) {
    .nav-block {
      border-bottom: none !important;
    }
  }
`,
);

export const ListItem = styled.li(listItemSharedStyles);

export const LabelCaretContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    a[class*='LabelWrapper'] {
      font-weight: ${theme.fontWeights.fontHeavy};
    }
  }
`;

export const Link = styled.a(
  ({ theme }) => `
  display: block;
  height: ${navbarItemHeightRem};
  padding: 0 2rem;
  font-size: ${theme.fontSizes.sm};
  line-height: ${navbarItemHeightRem};
  color: ${theme.darkGray};
  text-align: center;
  transition: background-color ${theme.bootstrapTransitionTime}ms;
  &:hover,
  &:focus {
    background-color: ${theme.lightBlue};
  }
  svg {
    height: 16px;
    margin: 10px auto 5px;
  }
  .is-active & {
    color: ${theme.darkGray};
    box-shadow: inset 0 -5px 0 ${theme.primaryBlue};
    @media (max-width: ${parseInt(theme.screenSizes.laptopS) - 1}px) {
      background-color: ${theme.lightBlue};
    }
  }
`,
);

export const LabelHelptextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 5rem;
  padding: 0 2rem;
  .helptext {
    font-size: 15px;
    line-height: 1.5rem;
    cursor: pointer;
  }
`;

export const LabelWrapper = styled.a(
  ({ theme }) => `
  display: block;
  align-items: center;
  font-size: 20px;
  font-weight: ${theme.fontWeights.fontNormal};
  line-height: 2rem;
  cursor: pointer;
  text-align: left;
  text-transform: none;
`,
);

export const MenuItem = styled.a(
  ({ theme }) => `
  display: block;
  line-height: 1.5rem;
  transition: background-color ${theme.bootstrapTransitionTime}ms;
  &:hover {
    background-color: ${theme.lightBlue};
  }
  &.is-active {
    @media (max-width: ${parseInt(theme.screenSizes.laptopS) - 1}px) {
      background-color: ${theme.lightBlue};
    }
  }
`,
);

export const Description = styled.span(
  ({ theme }) => `
  position: relative;
  font-size: ${theme.fontSizes.sm};
  }
  `,
);

export const expand = (menuRef, setState) => {
  setState({ className: 'collapsing', style: {} });
  setTimeout(() => {
    const { height } = menuRef.current.getBoundingClientRect();
    setState({ className: 'collapsing', style: { height: `${height}px` } });
    setTimeout(() => {
      setState({ className: '', style: {} });
    }, theme.bootstrapTransitionTime);
  }, 0);
};

export const collapse = (menuRef, setState) => {
  const { height } = menuRef.current.getBoundingClientRect();
  setState({ className: 'collapsing', style: { height: `${height}px` } });
  setTimeout(() => {
    setState({ className: 'collapsing', style: {} });
    setTimeout(() => {
      setState({ className: 'collapse', style: {} });
    }, theme.bootstrapTransitionTime);
  }, 0);
};
