import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { getInputSharedStyles } from 'components/ui/inputs/InputSharedStyles';
import { Row, Col } from 'components/ui/grid';
import NumberFormat from 'react-number-format';
import { Label } from 'components/ui/inputs/Input/styles';

export const StyledNumberFormat = styled(NumberFormat)`
  text-align: left;
  ${getInputSharedStyles()}
  padding: 13px 15px 13px 35px;
`;

export const InputWrapper = styled.div`
  position: relative;
  &:after {
    content: '$';
    font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
    position: absolute;
    left: 15px;
    top: 13px;
  }
`;

export const getCurrencyContainerCSS = () =>
  css`
    label {
      display: flex;
      align-items: center;
      .label-text {
        margin-left: 15px;
        font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
        order: 2;
        color: ${({ theme }) => theme.black};
      }
    }
  `;

const NumberInput = ({ defaultValue, fieldName, labelText, onChange }) => {
  return (
    <Row className="mt-2">
      <Col className="pb-4" sm={12} md={6} css={getCurrencyContainerCSS()}>
        <Label>
          <span className="label-text">{labelText}</span>
          <InputWrapper>
            <StyledNumberFormat
              decimalScale={2}
              defaultValue={defaultValue / 100}
              thousandSeparator
              onValueChange={({ floatValue }) => {
                onChange(fieldName, floatValue * 100);
              }}
              fixedDecimalScale
            />
          </InputWrapper>
        </Label>
      </Col>
    </Row>
  );
};

NumberInput.propTypes = {
  defaultValue: PropTypes.number,
  fieldName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberInput.defaultProps = {
  defaultValue: 0,
};

export default NumberInput;
