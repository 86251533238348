import React, { useRef, useState, useCallback } from 'react';
import ReactiveRecord from 'reactiverecord';
import PropTypes from 'prop-types';
import { uploadFile } from 'resources/resumes/supporting';

const Resume = ReactiveRecord.model('Resume');

const UploadResume = ({
  uploaderAttribute = Resume.uploaderAttribute,
  uploaderPath = new Resume().routeFor('create'),
  afterSave,
  parseSkills = false,
  onError,
  bidRelated,
}) => {
  const uploaderRequest = useRef(null);
  const [inputKey, setInputKey] = useState(Math.random());

  const handleError = useCallback(
    errorText => {
      onError(errorText);
    },
    [onError],
  );
  const handleFileProcessed = useCallback(
    processedFile => {
      if (afterSave) {
        afterSave(processedFile);
      }
    },
    [afterSave],
  );
  const handleClick = useCallback(() => {
    if (uploaderRequest.current) {
      uploaderRequest.current.abort();
    }
  }, []);
  const handleChange = useCallback(
    event => {
      if (event.target.files.length) {
        const file = event.target.files[0];
        setInputKey(Math.random());
        const [request, uploader] = uploadFile(
          file,
          uploaderAttribute,
          uploaderPath,
          () => undefined,
          parseSkills,
          bidRelated,
        );
        uploaderRequest.current = request;
        uploader.then(handleFileProcessed).catch(handleError);
      }
    },
    [
      uploaderAttribute,
      uploaderPath,
      handleFileProcessed,
      handleError,
      parseSkills,
      bidRelated,
    ],
  );

  return (
    <>
      <label
        htmlFor="resume-uploader"
        className={'btn-profile-file'}
        data-testid="resume-uploader"
      >
        Upload resume
        <input
          key={inputKey}
          type="file"
          id={'resume-uploader'}
          onClick={handleClick}
          onChange={handleChange}
          hidden
        />
      </label>
    </>
  );
};

UploadResume.propTypes = {
  afterSave: PropTypes.func.isRequired,
  uploaderAttribute: PropTypes.string,
  uploaderPath: PropTypes.string,
  parseSkills: PropTypes.bool,
  onError: PropTypes.func,
  bidRelated: PropTypes.bool,
};

export default UploadResume;
