import React, { useState } from 'react';

import http from 'utilities/http';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ResumeRevLogo from 'assets/illustrations/resume-rev-logo.svg';
import TheStudyLogo from 'assets/illustrations/the-study-white.svg';
import DTCLogo from 'assets/illustrations/dtc-logo.svg';
import DTCTalentCloud from 'assets/illustrations/dtc-talent-cloud.svg';
import ResumeModal from 'components/talent/dashboard/Resume/ResumeModal';
import { Box } from 'components/ui/Box';
import Button from 'components/ui/buttons/Button';
import Bell from 'components/ui/icons/Bell';
import ProgressBar from 'components/ui/ProgressBar';
import Accordion from 'components/ui/Accordion';
import { colors } from 'styleGuide';

export const Collapsible = styled.div`
  transition: all 0.5s ease-in, padding, margin 0.05s ease-in;
  overflow: hidden;
  padding: 0 40px;
  height: auto;
`;
const StyledBox = styled(Box)`
  max-width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  .clickable {
    cursor: pointer;
  }
  .flex_row > .flex_column {
    flex: 1 1 auto;
    place-content: stretch space-between;
  }
  .flex_row > .flex_column > * {
    max-width: 100%;
  }
`;
const StyledImg = styled.img`
  align-self: flex-start;
  max-height: 100%;
  max-width: 255px;
  width: auto;
`;

const ActionCard = ({ action, onChange, user }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const actionDetail = action.action;

  const images = {
    resume_rev: ResumeRevLogo,
    the_study: TheStudyLogo,
    dtclogo: DTCLogo,
    dtctalentcloud: DTCTalentCloud,
  };

  const getComponent = (id, props) => {
    if (id === 'resume_rev') {
      return <ResumeModal {...props} />;
    }
  };

  const getSafeHtml = (c, key, user) => {
    const val = c.value;
    const content = val.content || val;
    const variable = val.variable ? user[val.variable] : null;
    const iHtml = variable ? content.replace('{variable}', variable) : content;
    const innerHtml = {
      __html: iHtml,
    };
    return <div dangerouslySetInnerHTML={innerHtml} key={key} />;
  };

  const goToRoute = route => {
    const isExternal = /(http|www|\.com)/.test(route);
    if (isExternal) {
      window.open(route, '_blank');
    } else {
      window.location.assign(route);
    }
  };

  const handleButtonClick = route => {
    if (actionDetail.action_type === 'button') {
      updateAction('is_completed').then(() => {
        goToRoute(route);
      });
    } else {
      goToRoute(route);
    }
  };

  const actionCardVariableRegexp = /\$\{[a-z].+?\}/g;
  const getUserActionDataVariables = text => {
    let matches = text.match(actionCardVariableRegexp);
    if (!matches || !matches.length) {
      return text;
    }
    let templateVariables = matches.reduce((result, match) => {
      result.push(match.replace(/\$|{|}/g, ''));
      return result;
    }, []);

    let variableMap = templateVariables.reduce((mapping, variable) => {
      mapping[variable] = action.data[variable];
      return mapping;
    }, {});

    return interpolateTemplateVariables(text, variableMap);
  };

  const interpolateTemplateVariables = (string, params) => {
    let names = Object.keys(params);
    let vals = Object.values(params).map(value =>
      value === undefined ? '' : value,
    );
    return new Function(...names, `return \`${string}\`;`)(...vals);
  };

  const updateAction = prop => {
    const updatedAction = Object.assign(action, {
      [prop]: true,
    });
    return http
      .patch(`/api/v1/moms/actions/${action.id}`, {
        credentials: 'same-origin',
        referrer: 'no-referrer',
        body: JSON.stringify({ user_action: updatedAction }),
      })
      .then(res => onChange(res))
      .catch(err => console.error(err));
  };

  const getActionBlock = c => {
    const key = c.key ? c.key : c.type;
    switch (c.type) {
      case 'button':
        if (typeof c.value.route !== 'undefined') {
          c.value.route = getUserActionDataVariables(c.value.route);
        }
        return (
          <div className="d-flex flex-row justify-content-end gap-3" key={key}>
            <Button
              id="btn-dismiss-action"
              inline
              link
              onClick={() => updateAction('is_dismissed')}
            >
              Dismiss
            </Button>
            {c.value.route && (
              <Button id={key} onClick={() => handleButtonClick(c.value.route)}>
                {c.value.label}
              </Button>
            )}
            {c.value.modal && (
              <>
                <Button
                  id={key}
                  onClick={ev => {
                    ev.preventDefault();
                    setModalOpen(true);
                  }}
                >
                  {c.value.label}
                </Button>
                {modalOpen &&
                  getComponent(c.value.modal, {
                    handleClose: ev => {
                      ev.preventDefault();
                      setModalOpen(false);
                      updateAction('is_completed');
                    },
                  })}
              </>
            )}
          </div>
        );
      case 'column':
      case 'row':
        return (
          <div
            className={`d-flex gap-1 gap-sm-3
            flex-column flex-lg-${c.type}
            flex_${c.type}
            ${c.type === 'column' ? 'justify-space-between' : ''}`}
            key={key}
          >
            {c.components.map(comp => getActionBlock(comp))}
          </div>
        );
      case 'html':
        if (typeof c.value === 'string') {
          c.value = getUserActionDataVariables(c.value);
        }
        return getSafeHtml(c, key, user);
      case 'image':
        return (
          <StyledImg
            alt={c.value.label}
            key={key}
            src={images[c.value.variable]}
          />
        );
      case 'progress':
        return (
          <ProgressBar
            alwaysStar
            barHeightOnly
            key={key}
            percentage={user[c.value.variable]}
            withCircle
          />
        );
      case 'text':
        if (typeof c.value === 'string') {
          c.value = getUserActionDataVariables(c.value);
        }
        return (
          <div className={key === 'subtitle' ? 'font-bolder' : ''} key={key}>
            {c.value}
          </div>
        );
      default:
        break;
    }
  };

  // Handle Action Card Title
  if (
    action.data &&
    [
      'has_presented_jobs?',
      'has_thankable_bids?',
      'has_been_invited?',
    ].includes(actionDetail.audience_attribute)
  ) {
    actionDetail.title = getUserActionDataVariables(actionDetail.title);
  }

  return (
    <StyledBox backgroundColor={colors.green.pale} padding="0 20px" size="md">
      <Accordion
        headerIcon={<Bell color={colors.blue.primary} size={25} />}
        title={actionDetail.title}
      >
        <Collapsible
          className="d-flex align-items-stretch flex-column justify-content-start pb-3"
          id={`action-content-${action.action_id}`}
        >
          <div className="d-flex align-items-stretch flex-column gap-3">
            {actionDetail.properties.components.map(c => getActionBlock(c))}
          </div>
        </Collapsible>
      </Accordion>
    </StyledBox>
  );
};

ActionCard.propTypes = {
  action: PropTypes.any,
  onChange: PropTypes.func,
  user: PropTypes.any,
};

export default ActionCard;
