import ReactiveRecord, { Model } from 'reactiverecord';

class CapabilitiesCategory extends Model {
  static schema = {
    title: String,
    slug: String,
    priority: Number,
  };
}

export default ReactiveRecord.model(
  'CapabilitiesCategory',
  CapabilitiesCategory,
);
