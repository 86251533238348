import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export function Grid({ fluid, className, ...props }) {
  return (
    <div
      className={classNames({
        'container-fluid': fluid,
        container: !fluid,
        [className]: className,
      })}
      {...props}
    />
  );
}

Grid.propTypes = {
  className: PropTypes.string,
  fluid: PropTypes.bool,
};

export function Row({ className, ...props }) {
  return (
    <div
      className={classNames({ row: true, [className]: className })}
      {...props}
    />
  );
}

Row.propTypes = {
  className: PropTypes.string,
};

export function Col({ className, ...props }) {
  const colClassName = [];
  if (className) {
    colClassName.push(className);
  }

  for (let colClass in props) {
    if (
      colClass.match(/((pull|push|offset)-)?(xs|sm|md|lg)/) &&
      props[colClass] >= 0 &&
      props[colClass] <= 12
    ) {
      if (colClass.match(/(push|pull|offset)/)) {
        colClassName.push(`${colClass}-${props[colClass]}`);
      } else {
        colClassName.push(`col-${colClass}-${props[colClass]}`);
      }
      delete props[colClass];
    } else if (
      colClass === 'col' &&
      props[colClass] >= 1 &&
      props[colClass] <= 12
    ) {
      colClassName.push(`col-${props[colClass]}`);
      delete props[colClass];
    }
  }
  return <div className={colClassName.filter(Boolean).join(' ')} {...props} />;
}

Col.propTypes = {
  className: PropTypes.string,
};
