import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsProvider from 'components/shared/analytics/AnalyticsProvider';
import Modal from 'components/ui/Modal';
import Button from 'components/ui/buttons/Button';
import themed from 'components/hoc/themed';
import UploadIcon from 'components/ui/icons/UploadIcon';
import ShowToggle from 'components/ui/ShowToggle';
import styled from 'styled-components';
import { getSharedButtonStyles } from 'components/ui/buttons/Button';

export const ReplaceResumeButton = styled.div`
  ${getSharedButtonStyles({ size: 'md' })}
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0;
  }
`;

const ReplaceResume = props => {
  const handleChangeAndToggle = toggle => event => {
    props.handleChange(event);
    toggle();
  };

  return (
    <AnalyticsProvider disableTracking>
      <ShowToggle>
        {(show, toggle) => (
          <>
            <Button
              id="replace-resume"
              secondary
              css="border-radius: 2rem"
              className="py-2"
              onClick={toggle}
            >
              <UploadIcon width={20} className="mr-2" /> {props.linkText}
            </Button>
            <Modal
              titleAlignment="text-left"
              title="Would you like to replace your resume on file?"
              size="md"
              onHide={toggle}
              show={show}
            >
              <div className="request-withdraw">
                <p className="mb-4 px-4">
                  Uploading a new resume will replace the resume you currently
                  have on file. Would you like to continue?
                </p>
                <div className="input-container mt-2 px-4 text-right">
                  <Button
                    id="cancel-replace-resume-btn"
                    data-testid="cancel-replace-resume-btn"
                    secondary
                    noBorder
                    onClick={toggle}
                    className="mb-3"
                  >
                    No, keep my current resume
                  </Button>
                  <ReplaceResumeButton
                    className="position-relative d-inline-block ml-3"
                    id="replace-resume-btn"
                    data-testid="replace-resume-btn"
                  >
                    <input
                      className="position-absolute"
                      type="file"
                      name="resume-uploader"
                      onChange={handleChangeAndToggle(toggle)}
                    />
                    Yes, replace my resume
                  </ReplaceResumeButton>
                </div>
              </div>
            </Modal>
          </>
        )}
      </ShowToggle>
    </AnalyticsProvider>
  );
};

ReplaceResume.propTypes = {
  handleChange: PropTypes.func,
  linkText: PropTypes.string,
};
ReplaceResume.defaultProps = {
  linkText: 'Replace Resume',
};

export default themed(ReplaceResume);
