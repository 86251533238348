import styled from 'styled-components';

import { Box } from 'components/ui/Box';
import { screenSize } from 'styleGuide';

export const BidCardBox = styled(Box)`
  max-width: 100%;
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  ul {
    list-style-position: inside;
    margin-bottom: 0;
  }
  @media (max-width: ${screenSize.mobileL}) {
    .talent-profile {
      margin-right: 20px;
      flex-direction: column;
      order: 1;
    }
    display: inherit;
  }
`;

export const BidCardContent = styled.div`
  padding: 36px 36px 36px 24px;
`;

export const BidCardContentBody = styled.div`
  padding-left: 72px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  grid-column: span 2;
  justify-content: space-between;
  max-width: 420px;
  margin: 0;
  button {
    width: 200px;
    height: 50px;
    &.selectable-button {
      padding: 12px;
    }
  }
  @media (max-width: ${screenSize.mobileL}) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

export const RedactedDetails = styled.div`
  height: 2px;
  width: 20%;
  background-color: ${({ theme }) => theme.lightGray};
`;

export const RedactedDetailsWrapper = styled.div`
  &:before {
    display: block;
    width: 40%;
    height: 2px;
    content: '';
    background-color: ${({ theme }) => theme.lightGray};
  }
`;
