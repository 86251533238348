/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState, useCallback } from 'react';
import ReactiveRecord from 'reactiverecord';
import PropTypes from 'prop-types';
import { URLS, PREFERRED_EXPERIENCE_LEVEL_VALUES } from 'consts';
import MultiSelect from 'components/ui/inputs/MultiSelect';
import Flex from 'components/ui/Flex';
import TalentPrimarySuperpower from 'components/shared/TalentPrimarySuperpower';
import TalentCapabilities from 'components/shared/TalentCapabilities';
import FunctionsCapabilitiesContext from 'components/shared/FunctionsCapabilitiesContext';
import FunctionsCapabilitiesProvider from 'components/shared/FunctionsCapabilitiesProvider';
import OtherCapabilities from 'components/shared/OtherCapabilities';
import FoundSkills from 'components/shared/FoundSkills';
import { Row, Col } from 'components/ui/grid';
import CurrentMom from 'resources/current_mom/CurrentMom';
import ResumeUploader from 'resources/resumes/ResumeUploader';
import AsteriskHelperText from '../../shared/AsteriskHelperText';
import SuggestedExperiences from 'components/shared/SuggestedExperiences';

const ID_PREPEND = 'id-';

const CareerStory = props => {
  const [resume, setResume] = useState(null);
  const [resumeLoaded, setResumeLoaded] = useState(false);
  const ResumeLatestModel = ReactiveRecord.model('ResumeLatest');

  const loadResume = useCallback(() => {
    ResumeLatestModel.load().then(resume => {
      setResumeLoaded(true);
      setResume(resume);
    });
  }, [ResumeLatestModel]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadResume();
  }, [loadResume]);

  const getValues = arr =>
    arr.reduce((obj, row) => {
      obj[`${ID_PREPEND}${row[1]}`] = row[0];
      return obj;
    }, {});

  const valueAccessor = (obj, key) => {
    const prependedKey = key.toString().startsWith(ID_PREPEND)
      ? key
      : `${ID_PREPEND}${key}`;
    return obj[prependedKey];
  };

  const keysAccessor = obj =>
    Object.keys(obj).map(key => key.replace(ID_PREPEND, ''));

  const showCapabilities = props.state.primaryFunction > 0;

  const loadLatestResume = processedFile => {
    if (processedFile) {
      loadResume();
    } else {
      setResume(null);
    }
  };

  return (
    <div className="container sop-container">
      <FunctionsCapabilitiesProvider
        superheroFunctions={props.functions}
        capabilities={props.capabilities}
        selectedSuperheroFunction={props.state.primaryFunction}
        selectedCapabilities={props.state.capabilities}
        selectedOtherCapabilities={props.state.capabilities_other}
        superheroFunctionStateKey="primaryFunction"
        capabilitiesStateKey="capabilities"
        otherCapabilitiesStateKey="capabilities_other"
        onChange={values => {
          // Temporary fix until we can change all onChange events to accept multiple values
          Object.keys(values).forEach(key => {
            props.onChange(key, values[key]);
          });
        }}
      >
        <FunctionsCapabilitiesContext.Consumer>
          {({
            getSuperheroFunctionsProps,
            getCapabilitiesProps,
            getOtherCapabilitiesProps,
          }) => (
            <>
              <Row className="mb-5">
                <Col col="12">
                  <div className="font-md font-medium">
                    Upload your resume &mdash; <i>Optional</i>
                  </div>
                  <div className="text-alt font-sm mt-1 mb-4">
                    Your resume is used to match you to the best opportunities
                    that fit your career goals and preferences. You will be able
                    to upload a new resume when you apply to a job. Supported
                    file types are pdf, docx, and doc. We recommend you include
                    months in employment periods.{' '}
                    <a
                      href={URLS.resumeGuidance}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Need more help?
                    </a>
                  </div>
                  <ResumeUploader
                    resume={resume}
                    loaded={resumeLoaded}
                    displayType="upload"
                    afterSave={loadLatestResume}
                    parseSkills={true}
                  />
                </Col>
              </Row>
              {resume && resume.id && (
                <CurrentMom>
                  {(currentMom, loaded) => {
                    if (loaded) {
                      return (
                        <Flex direction="column" alignItems="stretch" gap={20}>
                          <FoundSkills currentMom={currentMom} {...props} />
                          <SuggestedExperiences className="mb-5" />
                        </Flex>
                      );
                    }
                    return null;
                  }}
                </CurrentMom>
              )}
              <AsteriskHelperText />
              <TalentPrimarySuperpower
                {...getSuperheroFunctionsProps({
                  label: 'What are you an expert at?',
                  helpText: 'Choose an expertise',
                  cols: 3,
                  validate: true,
                })}
              />
              {showCapabilities && (
                <>
                  <TalentCapabilities
                    {...getCapabilitiesProps({
                      cols: 2,
                      label: 'What are your top talents?',
                      maxLength: 3,
                      helpText: 'Choose up to 3 talents',
                      validate: true,
                    })}
                  />
                  <OtherCapabilities {...getOtherCapabilitiesProps()} />
                </>
              )}
            </>
          )}
        </FunctionsCapabilitiesContext.Consumer>
      </FunctionsCapabilitiesProvider>
      <div className="row mt-5 mb-5 justify-content-center">
        <div className="form-group col-lg-6 col-md-6 col-12">
          <MultiSelect
            id="industries-filter"
            labelText="Which industries do you have experience in? (Choose up to 6 industries)*"
            defaultValue={props.state.industries}
            onChange={e => props.onChange('industries', e.target.value)}
            max={6}
            keysAccessor={keysAccessor}
            valueAccessor={valueAccessor}
          >
            {getValues(props.state.industry_options)}
          </MultiSelect>
        </div>
      </div>
      <div className="row mb-5 justify-content-center">
        <div className="form-group col-6 col-md-6 col-12">
          <label>{'What is your experience level?*'}</label>
          <select
            data-testid="experience-level"
            className="form-control"
            defaultValue={props.state.experience_level}
            onChange={e => props.onChange('experience_level', e.target.value)}
          >
            <option value="">Choose one</option>
            {Object.keys(PREFERRED_EXPERIENCE_LEVEL_VALUES).map(
              experienceLevelLabel => (
                <option
                  key={experienceLevelLabel}
                  value={
                    PREFERRED_EXPERIENCE_LEVEL_VALUES[experienceLevelLabel]
                  }
                >
                  {experienceLevelLabel}
                </option>
              ),
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

CareerStory.propTypes = {
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  functions: PropTypes.array.isRequired,
  capabilities: PropTypes.object.isRequired,
  extractedSkills: PropTypes.array,
};

CareerStory.defaultProps = {
  extractedSkills: [],
};

export default CareerStory;
