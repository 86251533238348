import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaSet } from 'use-media-set';

import Button from 'components/ui/buttons/Button';
import Flex from 'components/ui/Flex';
import { mediaSetQueries } from 'styleGuide';

const ContentWrapper = styled(Flex)`
  max-width: 500px;
  margin: 2.5rem auto 0 auto;
`;

const InterviewSchedulingPageSuccess = ({
  buttonText,
  isEditing,
  handleContinue,
}) => {
  const mediaStates = useMediaSet(mediaSetQueries);

  return (
    <Flex alignItems="stretch" direction="column" gap={72}>
      <ContentWrapper alignItems="center" direction="column">
        <h1 className="mb-4 mt-5 text-center">
          {`${isEditing ? 'Changes saved!' : 'Saved!'}`}
        </h1>
        <div className="text-center">
          {isEditing
            ? 'Settings saved for all interviews and invites that use this template.'
            : 'This template can now be used to set up invites.'}
        </div>
      </ContentWrapper>
      <Flex
        alignItems="center"
        gap={32}
        direction="row"
        justifyContent="flex-end"
      >
        <Button
          block={mediaStates.has('mobile')}
          data-id="continue-scheduling-btn"
          onClick={handleContinue}
        >
          {buttonText || 'See all'}
        </Button>
      </Flex>
    </Flex>
  );
};

InterviewSchedulingPageSuccess.propTypes = {
  buttonText: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

export default InterviewSchedulingPageSuccess;
