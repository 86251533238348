import React from 'react';
import { createRoot } from 'react-dom/client';
import Dialog from './index';
import { Provider } from 'react-redux';
import ReactiveRecord from 'reactiverecord';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import Button from 'components/ui/buttons/Button';

export default function confirmDialog({
  children,
  blurBehavior = 'HIDE',
  large = false,
  confirmCTA = 'OK',
  cancelCTA = 'Cancel',
  ...props
}) {
  return new Promise((yes, no) => {
    const { store } = ReactiveRecord;
    const renderElement = document.createElement('DIV');
    const root = createRoot(renderElement);

    function handleCleanup(decision) {
      root.unmount();
      decision();
    }
    const onConfirm = handleCleanup.bind(this, yes);
    const onCancel = handleCleanup.bind(this, no);

    root.render(
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Dialog
            large={large}
            show
            onHide={onCancel}
            role="alertdialog"
            autoFocusBehavior="LAST"
            blurBehavior={blurBehavior}
            narrow
            {...props}
          >
            <div
              css={`
                margin-top: 42px;
              `}
            >
              {children}
            </div>
            <div className="mt-5 text-right">
              <div className="d-inline-block mr-2">
                <Button
                  data-id="cancel-cta-btn"
                  css={`
                    min-width: 50px;
                  `}
                  size="sm"
                  secondary
                  onClick={onCancel}
                >
                  {cancelCTA}
                </Button>
              </div>
              <Button
                data-id="confirm-cta-btn"
                css={`
                  min-width: 50px;
                `}
                size="sm"
                onClick={onConfirm}
              >
                {confirmCTA}
              </Button>
            </div>
          </Dialog>
        </Provider>
      </ThemeProvider>,
    );
  });
}
