import React from 'react';

const Home = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      id="Group_2332"
      width="18"
      height="18"
      data-name="Group 2332"
      viewBox="0 0 19.284 17.792"
    >
      <path
        fill="currentColor"
        id="Path_2271"
        d="M506.147 38.526a.862.862 0 0 0 1.219 0l8.171-8.17 8.17 8.17a.863.863 0 0 0 1.22 0 .862.862 0 0 0 0-1.219l-8.8-8.8a.833.833 0 0 0-1.179 0l-8.8 8.8a.862.862 0 0 0-.001 1.219z"
        data-name="Path 2271"
        transform="translate(-505.895 -28.263)"
      />
      <rect
        fill="currentColor"
        id="Rectangle_804"
        width="1.617"
        height="5.661"
        data-name="Rectangle 804"
        rx=".809"
        transform="translate(13.27)"
      />
      <path
        fill="currentColor"
        id="Path_2272"
        d="M521.757 37.263v8.9h-3.235v-6.47a.809.809 0 0 0-.809-.809h-3.235a.809.809 0 0 0-.809.809v6.47h-3.235v-8.9h-1.617v8.9a1.618 1.618 0 0 0 1.617 1.617h3.235a1.608 1.608 0 0 0 1.609-1.608V40.5h1.636v5.671a1.608 1.608 0 0 0 1.608 1.608h3.235a1.617 1.617 0 0 0 1.617-1.617v-8.9z"
        data-name="Path 2272"
        transform="translate(-506.454 -29.985)"
      />
    </svg>
  );
};

export default Home;
