import React from 'react';
import PropTypes from 'prop-types';

import Calendar from 'assets/illustrations/calendar.svg';
import stored from 'components/hoc/stored';
import Flex from 'components/ui/Flex';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import PlusCircleO from 'components/ui/icons/PlusCircleO';

const EmptyInterviewSchedulingPages = ({ addNewSchedulingPage }) => {
  return (
    <Flex className="my-4" direction="column" alignItems="center" gap={16}>
      <img src={Calendar} alt="No scheduling pages added yet" width="70px" />
      <div className="text-center font-heavy">
        Create a template to let us know which hours you prefer to meet.
      </div>
      <ButtonLink
        data-id="new-scheduling-page-button"
        onClick={addNewSchedulingPage}
      >
        <PlusCircleO size={18} />
        <span className="ml-2 font-heavy">Add new template</span>
      </ButtonLink>
    </Flex>
  );
};

EmptyInterviewSchedulingPages.propTypes = {
  addNewSchedulingPage: PropTypes.func.isRequired,
  textContent: PropTypes.string,
};

export default stored(EmptyInterviewSchedulingPages);
