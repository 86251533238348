import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';

const CloudPinOutline = ({ fill, height, stroke, width, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      {...props}
    >
      <g fill={fill} stroke={stroke} fillRule="evenodd">
        <path d="M40 .5a9.474 9.474 0 016.718 2.782A9.474 9.474 0 0149.5 10h0v38.793L1.207.5z" />
        <path d="M30.21 12.22l.153-.153a2.007 2.007 0 012.728-.103l.11.103 4.803-4.803a1.472 1.472 0 012.506-.907l2.632 2.633a1.472 1.472 0 01-.907 2.506h0l-4.802 4.803.102.11c.68.788.647 1.98-.101 2.729h0l-.153.152-7.071-7.07zM33.24 15.25l-5.361 5.36-.195 1.206 1.205-.195 5.361-5.361z" />
      </g>
    </svg>
  );
};

CloudPinOutline.propTypes = {
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.number,
  stroke: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.number,
};

CloudPinOutline.defaultProps = {
  fill: theme.white,
  height: 50,
  stroke: theme.lightGray,
  width: 50,
};

export default CloudPinOutline;
