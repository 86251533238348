import React, { useEffect, useState } from 'react';
import ReactiveRecord from 'reactiverecord';
import PropTypes from 'prop-types';
import { FeedbackFormWrapper } from './styles';
import Button from 'components/ui/buttons/Button';
import Dialog from 'components/ui/Dialog';
import LinkButton from 'components/ui/buttons/LinkButton';
import Questions from './Questions';
import { URLS } from 'consts';

const InAppFeedbackForm = ({ formData, show, toggle, dialog, delay }) => {
  const showModal = delay ? true : false;
  const [visible, setVisible] = useState(!showModal);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, delay);
  }, [delay]);

  const FeedbackFormSubmission = ReactiveRecord.model('FeedbackFormSubmission');

  const getResponsesArray = formData => {
    const responses = [];
    formData.feedback_form_questions.map(question => {
      responses.push({
        feedback_form_question_id: question.id,
        response: getResponse(question.id),
      });
    });
    return responses;
  };

  const getResponse = questionId => {
    const question = document.getElementById(`question${questionId}`);
    const elements = question.querySelectorAll('.response-element');
    let response = '';
    elements.forEach(element => {
      if (element.type === 'radio' || element.type === 'checkbox') {
        if (element.checked) {
          response = element.value;
        }
      } else {
        response = element.value;
      }
    });
    return response;
  };

  const handleSubmitForm = (event, formData) => {
    event.preventDefault();
    const submission = new FeedbackFormSubmission({
      id: formData.id,
      feedback_form_responses: getResponsesArray(formData),
    });
    submission.save();
    setSubmitted(true);
  };

  const FeedbackForm = ({ formData }) => {
    formData.feedback_form_questions.sort((a, b) => {
      return a['display_order'] - b['display_order'];
    });
    return (
      <FeedbackFormWrapper>
        {!submitted ? (
          <form className="form mt-2">
            <>
              <h2 className="fancy font-xl mb-4 text-center">
                {formData.heading}
              </h2>
              {formData.description && <p>{formData.description}</p>}
              <Questions questions={formData.feedback_form_questions} />
              <Button
                className="feedback-button float-sm-right mb-3"
                id="feedback-button"
                type="submit"
                onClick={event => handleSubmitForm(event, formData)}
              >
                {formData.submit_text}
              </Button>
            </>
          </form>
        ) : (
          <div className="message text-center mb-3">
            <h2 className="fancy font-xl pb-3">
              Thanks for sharing your feedback!
            </h2>
            <LinkButton
              href={URLS.talentMarketplace}
              className="mt-3 d-block d-sm-inline-block"
              id="return-to-jobs-button"
            >
              Return to job search
            </LinkButton>
          </div>
        )}
      </FeedbackFormWrapper>
    );
  };

  return (
    <>
      {visible && dialog && (
        <Dialog show={show} onHide={toggle} large>
          <FeedbackForm formData={formData} />
        </Dialog>
      )}
      {visible && !dialog && <FeedbackForm formData={formData} />}
    </>
  );
};

InAppFeedbackForm.propTypes = {
  formData: PropTypes.object.isRequired,
  show: PropTypes.bool,
  toggle: PropTypes.func,
  dialog: PropTypes.bool,
  delay: PropTypes.number,
};

InAppFeedbackForm.defaultProps = {
  dialog: true,
};

export default InAppFeedbackForm;
