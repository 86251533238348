import moment from 'moment';

const groupDatesByMonth = (acc, date) => {
  const dateAsMoment = moment(date);
  const yearAndMonth = dateAsMoment.format('YYYY-MM');
  if (!Object.prototype.hasOwnProperty.call(acc, yearAndMonth)) {
    acc[yearAndMonth] = [];
  }
  acc[yearAndMonth].push(dateAsMoment.date());
  return acc;
};

const groupContiguousDates = (acc, date) => {
  const lastElement = acc[acc.length - 1];

  if (!lastElement || lastElement[lastElement.length - 1] !== date - 1) {
    acc.push([]);
  }

  acc[acc.length - 1].push(date);
  return acc;
};

const formatRelatedDatesToString = dates =>
  dates.length === 1 ? dates[0] : `${dates[0]}-${dates[dates.length - 1]}`;

const formatIntoDatesString = dates =>
  dates
    .sort((a, b) => a - b)
    .reduce(groupContiguousDates, [])
    .map(formatRelatedDatesToString)
    .join(', ');

const formatIntoMonthDatesYearString = ([key, value]) => {
  const dateMoment = moment(key);
  const dates = formatIntoDatesString(value);
  return `${dateMoment.format('MMM')} ${dates}, ${dateMoment.year()}`;
};

export const formatPreferredDatesToString = preferredDates => {
  let dates = preferredDates.split(',').reduce(groupDatesByMonth, {});

  return Object.entries(dates)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(formatIntoMonthDatesYearString)
    .join(', ');
};

/**
 * Highlight Keywords
 * @param subject The complete text that we will search
 * @param search What we will search to highlight (aka Keyword)
 * @param isReverse When false, highlights the "search" parameter. When true, highlights everything but the "search"
 * @param highlightFunction
 * @returns {string|*}
 */
export const highlightKeyword = (
  subject,
  search,
  isReverse = false,
  highlightFunction = replace => {
    return '<strong>' + replace + '</strong>';
  },
) => {
  subject = subject.trim();
  if (!isReverse) {
    return subject.replace(new RegExp(search, 'i'), highlightFunction(search));
  }
  const searchIndex = subject.toLowerCase().indexOf(search.toLowerCase());
  if (searchIndex >= 0) {
    let highlightWord = subject.substring(0, searchIndex);
    if (searchIndex > 0) {
      highlightWord = highlightFunction(highlightWord);
    }
    return (
      highlightWord +
      subject.substring(searchIndex, searchIndex + search.length) +
      highlightFunction(subject.substring(searchIndex + search.length))
    );
  }
  return subject;
};

/**
 * This function is implemented so we can validate character count through Formik/Yup
 * Standard QuillEditor char count is inconsistent as it also counts new lines as new characters
 * that creates validation issues
 *
 * @param textWithHtml The complete text that we will search
 * @returns {number}
 */
export const countCharactersForQuillEditor = textWithHtml => {
  if (!textWithHtml) {
    return 0;
  }
  /**
   * We don't need to care here about constructions such as " Something < something "
   * will be counted incorrectly by current regex because QuillEditor replaces
   * all non-tag "<" by html code
   *
   * First replace strips all html tags
   *
   * Second replace regex replaces any Html code format "& + 2-5letters +;" (ex. &amp; )
   * by one letter (in our case it is "a") so they can be counted as 1 symbol
   */
  return textWithHtml
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/(&[a-z]{2,5};)/g, 'a').length;
};
