import React from 'react';
import PropTypes from 'prop-types';

import theme from 'theme';

const RightArrow = props => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 593 1024"
    >
      <title>Right Arrow</title>
      <path
        fill={props.fillColor}
        stroke={props.fillColor}
        strokeWidth={40}
        d="M79.789 1021.14l-62.47-62.47 505.711-505.711 62.47 62.47-505.711 505.711z"
      />
      <path
        fill={props.fillColor}
        stroke={props.fillColor}
        strokeWidth={40}
        d="M3.998 59.61l62.47-62.47 505.711 505.711-62.47 62.47-505.711-505.711z"
      />
    </svg>
  );
};

RightArrow.propTypes = {
  fillColor: PropTypes.any.isRequired,
};
RightArrow.defaultProps = {
  fillColor: theme.white,
};

export default RightArrow;
