import React from 'react';
import PropTypes from 'prop-types';
import { ModalContent, BottomSection } from './styles';
import PreviewResume from './PreviewResume';
import { URLS } from 'consts';
import Button from 'components/ui/buttons/Button';
import Dialog from 'components/ui/Dialog';

const ResumeModal = props => {
  // This is a temporary measure to save the bid as a draft when
  // navigating to a profile section. Remove this when the bids
  // page is refactored to react.
  const saveDraftAndRedirect = destinationUrl => {
    window.location = destinationUrl;
  };
  const handleDownloadResume = () => {
    window.open(URLS.talentGeneratedResume);
  };

  const resumeAction = () => {
    const action = (
      <Button id="download-resume" onClick={handleDownloadResume}>
        Download Resume
      </Button>
    );

    if (props.context === 'uploaded') {
      action = null;
    }

    return action;
  };

  return (
    <Dialog large onHide={props.handleClose} show={true}>
      <ModalContent>
        <PreviewResume
          {...props}
          context={props.context}
          saveDraftAndRedirect={saveDraftAndRedirect}
        />
        <BottomSection {...props}>{resumeAction()}</BottomSection>
      </ModalContent>
    </Dialog>
  );
};

ResumeModal.propTypes = {
  context: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleUseResume: PropTypes.func,
};

export default ResumeModal;
