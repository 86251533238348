import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/notification-prefs';
const memberRoute = `${collectionRoute}/:notification_type_id`;

class NotificationPref extends Model {
  static routes = {
    index: collectionRoute,
    create: collectionRoute,
    update: memberRoute,
  };
  static schema = {
    id: Number,
    frequency: String,
    notifiable_id: Number,
    notifiable_type: String,
    notification_type_id: Number,
    notification_type: String,
  };
}

export default ReactiveRecord.model('NotificationPref', NotificationPref);
