import React, { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { validated } from 'reactiverecord';
import MultiSelect from './MultiSelect';
import { debounce } from 'utilities';

const DropdownAutocompleteFetch = forwardRef(
  (
    { className, data, getListItems, onChange, toOptions, ...props },
    forwardedRef,
  ) => {
    const [listValue, setListValue] = useState(data);
    const [selectedValues, setSelectedValues] = useState({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onInputChange = useCallback(
      debounce.call(e => {
        getListItems(e.target.value).then(items => {
          const valueOptions = items.reduce(toOptions, {});
          // The text shown in pills are pulled from the list of options provided in the "data" prop below.
          // Because we are dynamically updating the list, and it's possible selected values will
          // be removed from the list when it updates, we keep track of them and merge them back into the
          // new options before updating state
          setListValue({
            ...valueOptions,
            ...selectedValues,
          });
        });
      }, 400),
      [toOptions, selectedValues],
    );

    const onSelectionChange = useCallback(
      e => {
        const keys = e.target.value;
        onChange(keys);
        const selectedValues = keys.reduce((object, key) => {
          object[key] = listValue[key];
          return object;
        }, {});
        setSelectedValues(selectedValues);
      },
      [listValue, onChange],
    );

    return (
      <div className={className}>
        <MultiSelect
          data={listValue}
          onChange={onSelectionChange}
          onInputChange={onInputChange}
          ref={forwardedRef}
          {...props}
        >
          {listValue}
        </MultiSelect>
      </div>
    );
  },
);

DropdownAutocompleteFetch.displayName = 'DropdownAutocompleteFetch';

DropdownAutocompleteFetch.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.array,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  errorText: PropTypes.any,
  getListItems: PropTypes.func.isRequired,
  id: PropTypes.any,
  keysAccessor: PropTypes.func,
  labelText: PropTypes.any,
  /** Optionally set a max length to allow checked. Disables others once the max is reached */
  max: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  toOptions: PropTypes.func.isRequired,
  validating: PropTypes.bool,
  value: PropTypes.array,
  valueAccessor: PropTypes.func,
};

DropdownAutocompleteFetch.defaultProps = {
  className: '',
  data: {},
};

export default validated(DropdownAutocompleteFetch);
