import React from 'react';

const EmployerHowItWorksBanner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 224 192"
    >
      <g fillRule="nonzero" fill="none">
        <path
          fill="#D3D4D8"
          d="M220.528 150.956l-103.05 34.776a5.116 5.116 0 01-6.475-3.199L63.27 41.51a5.104 5.104 0 013.203-6.465L169.522.268a5.116 5.116 0 016.475 3.199L223.73 144.49a5.105 5.105 0 01-3.203 6.465z"
        />
        <path
          fill="#FFF"
          d="M218.661 147.007l-99.594 33.811c-1.8.609-3.75-.36-4.36-2.167L68.18 40.373a3.464 3.464 0 012.158-4.38l99.594-33.811c1.8-.609 3.75.36 4.36 2.167l46.526 138.278a3.464 3.464 0 01-2.158 4.38z"
        />
        <path
          fill="#D3D4D8"
          d="M192.935 192H85.065c-2.796-.003-5.062-2.298-5.065-5.13V37.13c.003-2.832 2.269-5.127 5.065-5.13h107.87c2.796.003 5.062 2.298 5.065 5.13v149.74c-.003 2.832-2.269 5.127-5.065 5.13z"
        />
        <path
          fill="#FFF"
          d="M192.53 189H86.47a3.469 3.469 0 01-3.47-3.458V39.458A3.468 3.468 0 0186.47 36h106.06a3.469 3.469 0 013.47 3.458v146.084a3.468 3.468 0 01-3.47 3.458z"
        />
        <path
          fill="#D3D4D8"
          d="M168.76 77H98.24C97.003 77 96 75.88 96 74.5s1.003-2.5 2.24-2.5h70.52c1.237 0 2.24 1.12 2.24 2.5s-1.003 2.5-2.24 2.5zm8.033 8H98.207c-.788 0-1.517-.476-1.911-1.25a2.785 2.785 0 010-2.5c.394-.774 1.123-1.25 1.911-1.25h78.586c1.219 0 2.207 1.12 2.207 2.5s-.988 2.5-2.207 2.5z"
        />
        <path
          fill="#6685FF"
          d="M168.76 111H98.238C97 110.998 96 109.88 96 108.5s1.001-2.498 2.238-2.5h70.522c1.237 0 2.24 1.12 2.24 2.5s-1.003 2.5-2.24 2.5z"
        />
        <path
          fill="#D3D4D8"
          d="M176.793 119H98.205c-1.218-.002-2.205-1.12-2.205-2.5s.987-2.498 2.205-2.5h78.588c1.219 0 2.207 1.12 2.207 2.5s-.988 2.5-2.207 2.5zm-8.033 25H98.238C97 143.998 96 142.88 96 141.5s1.001-2.498 2.238-2.5h70.522c1.237 0 2.24 1.12 2.24 2.5s-1.003 2.5-2.24 2.5zm8.033 8H98.207c-1.219 0-2.207-1.12-2.207-2.5s.988-2.5 2.207-2.5h78.586c1.219 0 2.207 1.12 2.207 2.5s-.988 2.5-2.207 2.5z"
        />
        <path
          fill="#2F2E41"
          d="M55.791 101H40.209A1.213 1.213 0 0139 99.786V93.04c0-4.993 4.03-9.04 9-9.04s9 4.047 9 9.04v6.746c0 .67-.542 1.213-1.209 1.214z"
        />
        <path
          fill="#FFB8B8"
          d="M81.015 137.995a2.8 2.8 0 01-2.095-1.125 2.848 2.848 0 01-.49-2.345L70 129.157 74.718 127l7.281 5.429a2.848 2.848 0 011.957 3.224 2.826 2.826 0 01-2.941 2.342z"
        />
        <path
          fill="#464A59"
          d="M74.926 134.994a1.47 1.47 0 01-.774-.312l-22.832-17.96-2.944-5.314a2.986 2.986 0 01.255-3.286 2.888 2.888 0 014.264-.336l25.449 20.947c.332.229.56.583.632.984.072.4-.019.814-.25 1.146l-2.44 3.497a1.48 1.48 0 01-1.36.633z"
        />
        <path
          fill="#FFB8B8"
          d="M22 183.766L24.964 185 31 173.822 26.626 172z"
        />
        <path
          fill="#464A59"
          d="M21.57 184l5.897 2.451a4.115 4.115 0 012.212 2.236 4.27 4.27 0 01.025 3.188l-.05.125L20 187.986 21.57 184z"
        />
        <path fill="#FFB8B8" d="M58.001 188h3.387L63 175h-5z" />
        <path
          fill="#464A59"
          d="M57 186h6.718c1.136 0 2.225.51 3.028 1.418.803.908 1.254 2.14 1.254 3.424V191H57v-5zm2.794-6.002c-1.31 0-2.596-.549-3.305-.914a1.367 1.367 0 01-.719-.964l-6.954-36.003-18.043 34.661a1.354 1.354 0 01-.761.66c-3.886 1.333-6.005-.765-6.736-1.728a1.389 1.389 0 01-.15-1.408l8.356-18.639 5.052-17.705L41.641 125l.112.012 17.077 1.846 2.076 11.726 2.092 39.045c.018.311-.068.62-.245.873a3.45 3.45 0 01-2.96 1.496z"
        />
        <circle cx="50.5" cy="94.5" r="6.5" fill="#FFB8B8" />
        <path
          fill="#6685FF"
          d="M53.51 133c-2.829 0-6.33-1.777-10.44-5.308l-.07-.06 2.906-24.612.136-.005c4.157-.161 7.54.972 10.342 3.472l.024.027 2.27 3.134.002.058.32 19.25-.008.028c-.61 1.786-1.957 3.166-3.648 3.737a5.928 5.928 0 01-1.834.279z"
        />
        <path
          fill="#464A59"
          d="M56.96 144a1.519 1.519 0 01-1.469-1.104l-4.208-15.564-2.665 14.18c-.146.782-.897 1.312-1.703 1.2l-11.607-1.582a1.516 1.516 0 01-1.082-.69 1.453 1.453 0 01-.145-1.255l5.87-16.908-1.313-9.907a7.296 7.296 0 011.723-5.733 7.657 7.657 0 015.49-2.632l.117-.005 5.583 13.156.888-3.47 1.39-7.47.25-.489c4 2.253 6.336 7.103 6.128 11.609l-.352 7.911 3.119 16.153c.154.804-.386 1.578-1.208 1.732l-4.524.842a1.565 1.565 0 01-.282.026z"
        />
        <path
          fill="#2F2E41"
          d="M60 94h-9.784l-.1-1.32-.502 1.32h-1.506l-.199-2.616L46.915 94H44v-.13c.005-3.792 3.275-6.866 7.31-6.87h1.38c4.035.004 7.305 3.078 7.31 6.87V94z"
        />
        <path
          fill="#D3D4D8"
          d="M220.723 192H.277c-.153 0-.277-.224-.277-.5s.124-.5.277-.5h220.446c.153 0 .277.224.277.5s-.124.5-.277.5z"
        />
        <path
          fill="#6685FF"
          d="M54 136.955a3.04 3.04 0 001.025 2.283 2.949 2.949 0 002.371.71A5.01 5.01 0 0061.99 143a4.974 4.974 0 003.542-1.489A5.119 5.119 0 0067 137.917v-4.404c0-1.134-.906-1.513-2.023-1.513h-5.975c-1.117 0-2.023.38-2.023 1.513v.42c-1.644.002-2.977 1.354-2.979 3.022zm.812 0c.002-1.213.971-2.196 2.167-2.198v3.16c0 .414.05.827.148 1.229-.049.003-.098.007-.148.007-1.196 0-2.165-.984-2.167-2.198z"
        />
        <path
          fill="#FFB8B8"
          d="M54.665 136.049a2.815 2.815 0 00-1.905-1.37 2.96 2.96 0 00-2.334.5L42 130l.032 4.935 7.997 4.162c.957 1.018 2.568 1.2 3.751.421 1.183-.777 1.563-2.268.885-3.47z"
        />
        <path
          fill="#464A59"
          d="M47.048 139c-.325 0-.636-.13-.866-.36L35.81 128.213a2.766 2.766 0 01-.74-2.578c.889-3.902 3.052-13.224 3.966-15.52a8.094 8.094 0 013.497-4.094l.04-.021 3.285.61.289 10.181-3.967 8.544 8.435 8.003a1.23 1.23 0 01.127 1.648l-2.727 3.537c-.215.28-.54.452-.89.475l-.077.002z"
        />
        <ellipse cx="62" cy="132.5" fill="#2F2E41" rx="4" ry="1" />
      </g>
    </svg>
  );
};
export default EmployerHowItWorksBanner;
