import React, { useState } from 'react';

import HeaderDropdownListItem from 'components/shared/Header/HeaderDropdownListItem';
import {
  LabelCaretContainer,
  LabelHelptextWrapper,
  LabelWrapper,
  MenuItem,
  StyledHR,
} from 'components/shared/Header/stylesV2';
import {
  HamburgerListItem,
  HeaderLink,
  HeaderListItem,
} from 'components/shared/Header/PublicHeaderStyles';
import Caret from 'components/ui/icons/Caret';
import { mediaSetQueries } from 'styleGuide';
import { navItems } from 'components/shared/Header/NavItems';

import styled from 'styled-components';
import { useMediaSet } from 'use-media-set';

const DropdownItemDescription = styled.div(
  ({ theme }) => `
  font-size: ${theme.fontSizes.sm};
  font-weight: 400;
  padding: 0.5rem 1rem;
  width: 120px;
  hover: none;
  text-align: left;
  color: #787b85;
`,
);

const MenuItemsContainer = styled.div`
  border-left: 1px solid #dadbdd;
`;

export const PublicHeaderComponent = () => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const mediaStates = useMediaSet(mediaSetQueries);
  const hamburgerScreenSize = mediaStates.has('mobileOrLaptopM');

  // for !hamburgerScreenSize
  const dropdowns = [
    navItems.publicRoutes.careersAndCommunity,
    navItems.publicRoutes.smallCompanies,
    navItems.publicRoutes.enterprise,
  ];
  const directLink = navItems.publicRoutes.about;

  // for hamburgerScreenSize: when selectedMenu is true, children menu items are shown
  const hamburgerArray = [...dropdowns, directLink];
  const hamburgerItems = selectedMenu ? selectedMenu.children : hamburgerArray;

  const handleMenu = selectedItem => {
    if (selectedItem.href) {
      window.location.href = selectedItem.href;
    } else {
      setSelectedMenu(selectedItem);
    }
  };

  return (
    <>
      {!hamburgerScreenSize && (
        <>
          {dropdowns.map(parent => (
            <HeaderDropdownListItem
              className="text-nowrap"
              data-text={parent.label}
              label={parent.label}
              key={parent.label}
            >
              <DropdownItemDescription className="text-wrap">
                {parent.helptext}
              </DropdownItemDescription>
              <MenuItemsContainer>
                {parent.children.map(child => (
                  <MenuItem
                    href={child.href}
                    target={child.target}
                    key={child.label}
                  >
                    {child.label}
                  </MenuItem>
                ))}
              </MenuItemsContainer>
            </HeaderDropdownListItem>
          ))}
          <HeaderListItem data-text={directLink.label}>
            <HeaderLink href={directLink.href} target={directLink.target}>
              {directLink.label}
            </HeaderLink>
          </HeaderListItem>
        </>
      )}
      {hamburgerScreenSize &&
        hamburgerItems.map(item => {
          return (
            <HamburgerListItem key={item.label}>
              <LabelCaretContainer onClick={() => handleMenu(item)}>
                <LabelHelptextWrapper>
                  <LabelWrapper>{item.label}</LabelWrapper>
                  <div className="helptext">{item.helptext}</div>
                </LabelHelptextWrapper>
                {item.children && (
                  <Caret
                    className="ml-2"
                    style={{ marginRight: '1.7rem' }}
                    width={18}
                  />
                )}
              </LabelCaretContainer>
              <StyledHR />
            </HamburgerListItem>
          );
        })}
    </>
  );
};
