import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'components/ui/Flex';

const InterviewSchedulingPageUsage = ({
  children,
  schedulingPage,
  showLabel,
}) => {
  const { active_interview_count, active_interview_request_count } =
    schedulingPage;

  const requestInfo = {
    multiple: active_interview_request_count > 1,
    atleastOne: active_interview_request_count > 0,
  };
  const interviewInfo = {
    multiple: active_interview_count > 1,
    atleastOne: active_interview_count > 0,
  };

  const renderCopy = () => {
    return (
      <div data-testid="scheduling-page-usage-copy">
        <span>You have </span>
        {requestInfo.atleastOne ? (
          <span className="font-heavy">
            {active_interview_request_count} interview invite
            {requestInfo.multiple ? 's ' : ' '}
          </span>
        ) : null}
        {requestInfo.atleastOne && interviewInfo.atleastOne ? (
          <span>and </span>
        ) : null}
        {interviewInfo.atleastOne ? (
          <span className="font-heavy">
            {active_interview_count} interview
            {interviewInfo.multiple ? 's ' : ' '}
          </span>
        ) : null}
        <span>using this setting. </span>
        {children}
      </div>
    );
  };

  if (active_interview_count + active_interview_request_count > 0) {
    return (
      <>
        {showLabel ? (
          <Flex alignItems="flex-start" direction="column" gap={8}>
            {showLabel && <div className="label-default">Usage</div>}
            {renderCopy()}
          </Flex>
        ) : (
          renderCopy()
        )}
      </>
    );
  } else {
    return null;
  }
};

InterviewSchedulingPageUsage.propTypes = {
  children: PropTypes.node,
  schedulingPage: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
};

InterviewSchedulingPageUsage.defaultProps = {
  showLabel: true,
};

export default InterviewSchedulingPageUsage;
