import { getInputSharedStyles } from '../InputSharedStyles';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const StyledNumberFormat = styled(NumberFormat)`
  text-align: left;
  ${getInputSharedStyles()}
  padding: 13px 15px 13px 35px;
`;

export const InputWrapper = styled.div`
  position: relative;
  &:before {
    content: ${({ rateType }) => rateType && `"${rateType}"`};
    font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
    position: absolute;
    right: 0.9375rem;
    top: 0.8125rem;
  }
  &:after {
    content: '$';
    font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
    position: absolute;
    left: 0.9375rem;
    top: 0.8125rem;
  }
  input {
    ${({ hasError, theme }) =>
      hasError && `box-shadow: inset 0 0 0 1px ${theme.red};`}
  }
`;
