import styled, { css } from 'styled-components';
import Button from 'components/ui/buttons/Button';

export const ErrorMessage = styled.div(
  () => css`
    margin: 1rem 0;
    padding: 1rem;
    background-color: #fff6f6;
  `,
);

export const ErrorHeader = styled.div(
  () => css`
    font-weight: ${({ theme }) => theme.fontWeights.fontBolder};
    padding-bottom: 0.75rem;
  `,
);

export const ErrorButton = styled(Button)(
  ({ theme }) =>
    css`
      width: 100%;
      @media (min-width: ${theme.screenSizes.tablet}) {
        width: 274px;
      }
    `,
);
