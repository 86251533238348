import styled from 'styled-components';
import { getInputSharedStyles } from '../InputSharedStyles';
import PropTypes from 'prop-types';
import Button from '../../buttons/Button';

export const Label = styled.label`
  display: block;
  .label-text {
    display: block;
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
    line-height: 20px;
    color: ${({ theme }) => theme.mediumGray};
    margin-bottom: 0.5 rem;
  }

  .secondary-label-text {
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.mediumGray};
    margin-top: 10px;
  }

  .inline-icon {
    position: absolute;
    right: 15px;
    bottom: 15px;
    pointer-events: none;
    svg {
      color: ${({ isDisabled, theme }) =>
        isDisabled ? theme.mediumGray : theme.primaryBlue};
      display: block;
    }
  }

  .input-wrapper {
    position: relative;
  }

  .label-text + .input-wrapper {
    margin: 5px 0 0 0;
  }
  ${({ hasButton }) =>
    hasButton &&
    `
    .input-wrapper {
      display: flex;
      align-items: flex-end;
    }
  `}
  ${Button} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform: translateX(-1px);
  }
`;
Label.propTypes = {
  isDisabled: PropTypes.bool,
  hasButton: PropTypes.bool,
};

export const StyledInput = styled.input`
  ${getInputSharedStyles()}
`;
