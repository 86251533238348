import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styleGuide';

const OurClientBuildingLogo = ({ width = '97', height = '97', ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-24 -24 97 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Client</title>
      <path
        d="m4 42v-36h20v8h20v28h-40zm4-4h4v-4h-4v4zm0-8h4v-4h-4v4zm0-8h4v-4h-4v4zm0-8h4v-4h-4v4zm8 24h4v-4h-4v4zm0-8h4v-4h-4v4zm0-8h4v-4h-4v4zm0-8h4v-4h-4v4zm8 24h16v-20h-16v4h4v4h-4v4h4v4h-4v4zm8-12v-4h4v4h-4zm0 8v-4h4v4h-4z"
        fill={colors.blue.navy}
      />
    </svg>
  );
};

OurClientBuildingLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default OurClientBuildingLogo;
