import ReactiveRecord, { Model } from 'reactiverecord';

class Capability extends Model {
  static schema = {
    title: String,
    capabilities_category_id: Number,
  };
}

export default ReactiveRecord.model('Capability', Capability);
