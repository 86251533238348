import React from 'react';
import PropTypes from 'prop-types';
import LocationPin from 'components/ui/icons/LocationPin';
import { IconWrapper } from './styles';

const Location = ({ locations, iconWidth }) => {
  return (
    <div className="d-flex">
      <IconWrapper>
        <LocationPin className="text-grey" width={iconWidth} height="auto" />
      </IconWrapper>
      <div className="font-sm">
        {locations.map(location => (
          <span key={location} className="mr-2 text-nowrap d-block">
            {location}
          </span>
        ))}
      </div>
    </div>
  );
};

Location.propTypes = {
  locations: PropTypes.array.isRequired,
  iconWidth: PropTypes.number,
};

export default Location;
