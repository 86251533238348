import React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Facebook</title>
      <path
        d="M3.76328 13.1806V24H8.46738V13.1806H12.2307V8.47647H8.46738V6.12429C8.46738 4.71306 9.4082 4.24265 10.349 4.24282H12.7011V0.00909629C11.2898 0.00917917 8.34087 -0.105757 6.58574 0.479506C4.32786 1.23242 3.76328 3.61557 3.76328 4.7132V8.47647H0V13.1806H3.76328Z"
        fill="currentColor"
      />
    </svg>
  );
};

Facebook.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Facebook.defaultProps = {
  width: 13,
  height: 24,
};

export default Facebook;
