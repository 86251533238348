import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/expertises';
const memberRoute = `${collectionRoute}/:id`;

class MomExpertise extends Model {
  static attributesName = 'expertises_attributes';
  static routes = {
    index: collectionRoute,
    create: collectionRoute,
    show: memberRoute,
    update: memberRoute,
    destroy: memberRoute,
  };

  static schema = {
    label: { type: String, labelText: 'example: Microsoft Office' },
    emsi_skill_id: { type: Number, labelText: 'example: Microsoft Office' },
    _destroy: Boolean,
  };
}

export default ReactiveRecord.model('MomExpertise', MomExpertise);
