import React from 'react';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'styleGuide';

const LoadingMessage = styled.span(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.base};
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${theme.primaryBlue};
  `,
);

const InlineLoader = ({ children, className }) => (
  <div className={`d-flex align-items-center ${className}`}>
    <LoadingSpinner color={colors.blue.primary} size={30} />
    <LoadingMessage className="ml-3">{children}</LoadingMessage>
  </div>
);

InlineLoader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default InlineLoader;
