import React from 'react';

const DollarSign = props => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.616 12.228C13.616 12.6947 13.4993 13.1333 13.266 13.544C13.042 13.9547 12.706 14.3 12.258 14.58C11.8193 14.86 11.2967 15.028 10.69 15.084V16.218H9.78V15.084C8.90267 15 8.19333 14.7247 7.652 14.258C7.11067 13.7913 6.84 13.1753 6.84 12.41H8.548C8.576 12.774 8.69267 13.0773 8.898 13.32C9.10333 13.5533 9.39733 13.7027 9.78 13.768V10.688C9.15467 10.5293 8.646 10.3707 8.254 10.212C7.862 10.044 7.526 9.78267 7.246 9.428C6.97533 9.07333 6.84 8.588 6.84 7.972C6.84 7.19733 7.11067 6.558 7.652 6.054C8.19333 5.54067 8.90267 5.242 9.78 5.158V4.024H10.69V5.158C11.5113 5.23267 12.1693 5.494 12.664 5.942C13.168 6.38067 13.4527 6.98733 13.518 7.762H11.81C11.782 7.47267 11.67 7.216 11.474 6.992C11.278 6.75867 11.0167 6.6 10.69 6.516V9.54C11.3153 9.68933 11.824 9.848 12.216 10.016C12.608 10.1747 12.9393 10.4313 13.21 10.786C13.4807 11.1313 13.616 11.612 13.616 12.228ZM8.464 7.888C8.464 8.27067 8.576 8.56933 8.8 8.784C9.03333 8.99867 9.36 9.17133 9.78 9.302V6.474C9.37867 6.52067 9.05667 6.66533 8.814 6.908C8.58067 7.15067 8.464 7.47733 8.464 7.888ZM10.69 13.768C11.1007 13.7027 11.4227 13.5393 11.656 13.278C11.8893 13.0073 12.006 12.6853 12.006 12.312C12.006 11.9387 11.8893 11.6493 11.656 11.444C11.432 11.2293 11.11 11.0567 10.69 10.926V13.768Z"
        fill="currentColor"
      />
      <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
    </svg>
  );
};

export default DollarSign;
