import { useState } from 'react';
import PropTypes from 'prop-types';

const ShowToggle = props => {
  const [show, setShow] = useState(props.defaultShow);
  const toggle = e => {
    if (
      e !== null &&
      typeof e === 'object' &&
      'preventDefault' in e &&
      props.preventDefault
    ) {
      e.preventDefault();
    }
    setShow(!show);
  };
  return props.children(show, toggle);
};

ShowToggle.propTypes = {
  /** Sets the inital state of showing or hiding */
  defaultShow: PropTypes.bool,
  children: PropTypes.func.isRequired,
  /** Chooses whether to call `preventDefault()` on events which trigger the toggle */
  preventDefault: PropTypes.bool,
};

ShowToggle.defaultProps = {
  defaultShow: false,
};
export default ShowToggle;
