import ReactiveRecord, { Model } from 'reactiverecord';

class User extends Model {
  static schema = {
    agree_to_terms_and_policy: Boolean,
    after_sign_up_path: String,
    email: { labelText: 'Work Email', type: String },
    first_name: String,
    invite_token: String,
    is_subscriber: Boolean,
    last_name: String,
    password: { labelText: 'Create a Password', type: String },
    phone: { labelText: 'Phone Number', type: String },
  };
}

export default ReactiveRecord.model('User', User);
