import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import SuccessMessage from 'components/ui/labels/SuccessMessage';
import { Label } from 'components/ui/inputs/Input/styles';
import {
  StyledNumberFormat,
  InputWrapper,
} from 'components/ui/inputs/InputCurrency/InputCurrencyStyles';
import { without } from 'utilities';

const InputCurrency = ({
  labelText,
  successText,
  touched,
  errors,
  name,
  value,
  rateType,
  ...props
}) => {
  const generatedId = useMemo(
    () => `input-currency-${InputCurrency.idCounter++}`,
    [],
  );
  const id = props.id || generatedId;
  const hasError = touched && touched[name] && errors && errors[name];

  return (
    <Label htmlFor={name}>
      {labelText ? <span className="label-text">{labelText}</span> : null}

      <InputWrapper hasError={hasError} rateType={rateType}>
        <StyledNumberFormat
          id={id}
          name={name}
          value={value}
          thousandSeparator={true}
          disabled={props.disabled}
          {...without.call(props, 'onChange', 'children')} // Override formik's onChange with onValueChange
        />
      </InputWrapper>

      {hasError && <ErrorMessage>{errors[name]}</ErrorMessage>}
      {successText ? <SuccessMessage>{successText}</SuccessMessage> : null}
    </Label>
  );
};

InputCurrency.propTypes = {
  decimalScale: PropTypes.number,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  fixedDecimalScale: PropTypes.bool,
  id: PropTypes.any,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired, // provided by Formik
  onValueChange: PropTypes.func.isRequired,
  successText: PropTypes.string,
  touched: PropTypes.object,
  value: PropTypes.any,
  rateType: PropTypes.string,
};

InputCurrency.defaultProps = {
  decimalScale: 2,
  fixedDecimalScale: false,
};

InputCurrency.idCounter = 0;

export default InputCurrency;
