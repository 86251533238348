import React from 'react';

const LinkedIn = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.024"
      height="35.023"
      viewBox="0 0 35.024 35.023"
      {...props}
    >
      <title>LinkedIn</title>
      <path
        fill="currentColor"
        d="M150.918,372.974a1.718,1.718,0,1,0,1.7,1.718A1.709,1.709,0,0,0,150.918,372.974Z"
        transform="translate(-139.37 -364.027)"
      />
      <path
        fill="currentColor"
        d="M155.833,378.062V376.2h-3.407v10.224h3.407v-4.875c0-2.934,3.412-3.2,3.412,0v4.875h3.4V380.7C162.648,375.1,157.318,375.309,155.833,378.062Z"
        transform="translate(-137.469 -362.128)"
      />
      <rect
        fill="currentColor"
        width="3.408"
        height="10.223"
        transform="translate(9.844 14.072)"
      />
      <path
        fill="currentColor"
        d="M160.543,367.354a17.512,17.512,0,1,0,17.512,17.512A17.511,17.511,0,0,0,160.543,367.354Zm0,31.84a14.328,14.328,0,1,1,14.328-14.328A14.328,14.328,0,0,1,160.543,399.194Z"
        transform="translate(-143.031 -367.354)"
      />
    </svg>
  );
};

export default LinkedIn;
