import React, { useContext } from 'react';
import { VideoContext } from './VideoProvider';
import styled, { css } from 'styled-components';
import RemoteParticipant from './RemoteParticipant';
import LocalParticipant from './LocalParticipant';
import VideoControls from './VideoControls';
import HideIntercomLauncher from 'components/shared/HideIntercomLauncher';

const headerOffset = 60;
const controlsOffset = 64;

const RoomContainer = styled.div`
  background: black;
  height: calc(100vh - ${headerOffset}px);
`;

const ParticipantContainer = styled.div(
  ({ theme }) => css`
    height: 100%;
    max-width: ${theme.screenSizes.laptop};
    position: relative;
    margin: 0 auto;
  `,
);

const LocalParticipantContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 36vw;
    height: calc(36vw * 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 1rem;
    z-index: 1;

    @media (min-width: ${theme.screenSizes.tablet}) {
      width: 20vw;
      height: calc(20vw * 0.75);
    }

    @media (min-width: ${theme.screenSizes.laptop}) {
      width: 12vw;
      height: calc(12vw * 0.75);
    }
  `,
);

const RemoteParticipantContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${theme.lightestGray};
    font-size: ${theme.fontSizes.md};
    height: calc(100vh - ${headerOffset + controlsOffset}px);
    video {
      position: absolute;
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      max-width: ${theme.screenSizes.laptop};
    }
  `,
);

const VideoRoom = () => {
  const { remoteParticipants } = useContext(VideoContext);

  return (
    <RoomContainer>
      <HideIntercomLauncher />
      <ParticipantContainer>
        <LocalParticipantContainer>
          <LocalParticipant />
        </LocalParticipantContainer>
        <RemoteParticipantContainer>
          {remoteParticipants.length ? (
            <RemoteParticipant participant={remoteParticipants[0]} />
          ) : (
            <p>Waiting for others to join...</p>
          )}
        </RemoteParticipantContainer>
      </ParticipantContainer>
      <VideoControls />
    </RoomContainer>
  );
};

export default VideoRoom;
