import React from 'react';

const EmailCircle = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M131.936,367.477C122.329,367.477 114.424,375.382 114.424,384.989C114.424,394.596 122.329,402.501 131.936,402.501C141.543,402.501 149.448,394.596 149.448,384.989C149.448,375.382 141.543,367.477 131.936,367.477ZM131.936,399.317C124.076,399.317 117.608,392.849 117.608,384.989C117.608,377.129 124.076,370.661 131.936,370.661C139.796,370.661 146.264,377.129 146.264,384.989C146.263,392.849 139.796,399.315 131.936,399.316L131.936,399.317Z"
        fill="currentColor"
        transform="matrix(1,0,0,1,-114.424,-367.477)"
      />
      <path
        d="M6.5,10.5L6.5,18.5L20.5,18.5L20.5,10.5L13.5,15.5L6.5,10.5Z"
        fill="currentColor"
        transform="matrix(1.28571,0,0,1.42857,0.154357,-2.20279)"
      />
      <path
        d="M6.5,8.5L13.5,13.5L20.5,8.5L6.5,8.5"
        fill="currentColor"
        transform="matrix(1.28571,0,0,1.42857,0.154357,-1.34564)"
      />
    </svg>
  );
};

export default EmailCircle;
