import React from 'react';

const Instagram = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.023"
      height="35.023"
      viewBox="0 0 35.023 35.023"
      {...props}
    >
      <title>Instagram</title>
      <path
        fill="currentColor"
        d="M213.941,376.664a3.79,3.79,0,0,0-2.167-2.168,5.46,5.46,0,0,0-1.8-.344c-.791-.037-1.043-.045-3.057-.045s-2.265.008-3.055.045a5.452,5.452,0,0,0-1.8.344,3.787,3.787,0,0,0-2.168,2.168,5.411,5.411,0,0,0-.344,1.8c-.037.791-.045,1.043-.045,3.055s.008,2.265.045,3.055a5.414,5.414,0,0,0,.344,1.8,3.779,3.779,0,0,0,2.168,2.167,5.451,5.451,0,0,0,1.8.345c.79.037,1.043.045,3.055.045s2.265-.008,3.057-.045a5.459,5.459,0,0,0,1.8-.345,3.782,3.782,0,0,0,2.167-2.167,5.409,5.409,0,0,0,.345-1.8c.035-.79.045-1.041.045-3.055s-.01-2.264-.045-3.055A5.407,5.407,0,0,0,213.941,376.664Zm-.989,7.847a4.153,4.153,0,0,1-.255,1.377,2.462,2.462,0,0,1-1.407,1.406,4.108,4.108,0,0,1-1.375.255c-.782.035-1.017.043-3,.043s-2.213-.008-2.994-.043a4.12,4.12,0,0,1-1.377-.255,2.452,2.452,0,0,1-1.406-1.406,4.07,4.07,0,0,1-.255-1.377c-.037-.782-.045-1.016-.045-2.995s.008-2.213.045-2.993a4.07,4.07,0,0,1,.255-1.377,2.449,2.449,0,0,1,1.407-1.406,4.06,4.06,0,0,1,1.375-.255c.782-.037,1.016-.043,2.994-.043s2.214.006,3,.043a4.069,4.069,0,0,1,1.375.255,2.461,2.461,0,0,1,1.407,1.406,4.153,4.153,0,0,1,.255,1.377c.035.78.043,1.016.043,2.993S212.987,383.729,212.952,384.511Z"
        transform="translate(-189.407 -364.004)"
      />
      <path
        fill="currentColor"
        d="M206.978,375.718a.889.889,0,1,0,.89.89A.889.889,0,0,0,206.978,375.718Z"
        transform="translate(-185.511 -363.051)"
      />
      <path
        fill="currentColor"
        d="M205.578,376.371a3.8,3.8,0,1,0,3.806,3.8A3.8,3.8,0,0,0,205.578,376.371Zm0,6.276a2.47,2.47,0,1,1,2.471-2.471A2.469,2.469,0,0,1,205.578,382.647Z"
        transform="translate(-188.066 -362.664)"
      />
      <path
        fill="currentColor"
        d="M210.675,367.761a17.512,17.512,0,1,0,17.512,17.512A17.512,17.512,0,0,0,210.675,367.761Zm0,31.839A14.328,14.328,0,1,1,225,385.273,14.328,14.328,0,0,1,210.675,399.6Z"
        transform="translate(-193.163 -367.761)"
      />
    </svg>
  );
};

export default Instagram;
