import React from 'react';
import PropTypes from 'prop-types';

import {
  FACEBOOK_SHARE_URL,
  TWITTER_SHARE_URL,
  LINKEDIN_SHARE_URL,
  POPUP_SHARE_PROPERTIES,
  SHARE_EMAIL_TEMPLATE,
} from 'consts';
import ButtonLink from '../buttons/ButtonLink';
import FacebookCircle from 'components/ui/icons/Logos/FacebookCircle';
import TwitterCircle from 'components/ui/icons/Logos/TwitterCircle';
import LinkedInCircle from 'components/ui/icons/Logos/LinkedInCircle';
import EmailCircle from 'components/ui/icons/EmailCircle';
import themed from 'components/hoc/themed';
import theme from 'theme';

import styled from 'styled-components';

const StyledButtonLink = styled(ButtonLink)`
  color: ${({ theme }) => theme.darkGray};
`;

const SocialMediaIcons = props => {
  const encodedProjectTitle = encodeURIComponent(props.projectTitle);
  const openFacebook = () => {
    const sharerUrl = `${FACEBOOK_SHARE_URL}?u=${props.referralUrl}/${props.slug}&title=${encodedProjectTitle}
    &caption=&quote=`;
    openWindow(sharerUrl);
  };

  const openTwitter = () => {
    const sharerUrl = `${TWITTER_SHARE_URL}?text=${encodedProjectTitle} ${props.referralUrl}/${props.slug}`;
    openWindow(sharerUrl);
  };

  const openLinkedin = () => {
    const sharerUrl = `${LINKEDIN_SHARE_URL}&url=${props.referralUrl}/${props.slug}`;
    openWindow(sharerUrl);
  };

  const openWindow = sharerUrl => {
    window.open(sharerUrl, 'targetWinow', POPUP_SHARE_PROPERTIES);
    return false;
  };

  return (
    <div className="d-flex justify-content-between">
      <StyledButtonLink
        id="facebook-share"
        className="p-0"
        color={theme.darkGray}
        onClick={openFacebook}
      >
        <FacebookCircle width={30} />
      </StyledButtonLink>
      <StyledButtonLink
        id="linkedin-share"
        className="p-0"
        color={theme.darkGray}
        onClick={openLinkedin}
      >
        <LinkedInCircle width={30} />
      </StyledButtonLink>
      <a
        className="d-flex text-grey"
        target="_blank"
        rel="noopener noreferrer"
        href={SHARE_EMAIL_TEMPLATE}
      >
        <EmailCircle width={30} />
      </a>
      <StyledButtonLink
        id="twitter-share"
        className="p-0"
        color={theme.darkGray}
        onClick={openTwitter}
      >
        <TwitterCircle width={30} />
      </StyledButtonLink>
    </div>
  );
};

SocialMediaIcons.propTypes = {
  referralUrl: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  projectTitle: PropTypes.string.isRequired,
};

export default themed(SocialMediaIcons);
