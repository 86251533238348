import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/ui/buttons/LinkButton';
import Button from 'components/ui/buttons/Button';
import { Row, Col } from 'components/ui/grid';
import { URLS } from 'consts';

const SuccessNotification = ({
  appointmentType,
  handleOnClose,
  isReschedule,
}) => {
  const appointmentTypeCopy = <strong>{appointmentType} interview</strong>;
  // TODO: update this hook when the interviews page is migrated.
  // const { project_id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const project_id = params.get('project_id');
  return (
    <div className="p-4 text-center">
      <h1 className="text-center fancy mb-4">
        {isReschedule ? "We've asked them to reschedule!" : 'Invite sent!'}
      </h1>
      {isReschedule ? (
        <p>We sent your invitation to reschedule the {appointmentTypeCopy}</p>
      ) : (
        <p>
          We&apos;ve sent your invitation for a {appointmentTypeCopy}
          &nbsp; to this candidate.
        </p>
      )}
      <p>
        Look out for an email confirmation once they choose a time. You can also
        find updates on your Interviews page.
      </p>
      <Row className="justify-content-end">
        <Col col="12" md="5" className="order-1 order-md-2">
          {project_id ? (
            <LinkButton
              data-id="interview-request-ok-btn"
              type="button"
              className="mt-4 w-100"
              href={URLS.employerApplicantReview(project_id)}
            >
              See more applicants
            </LinkButton>
          ) : (
            <Button
              data-id="success-modal-close"
              type="button"
              className="mt-4 w-100"
              onClick={handleOnClose}
            >
              See my interviews
            </Button>
          )}
        </Col>
        {project_id && (
          <Col col="12" md="4" className="order-2 order-md-1">
            <LinkButton
              data-id="interview-request-ok-btn"
              type="button"
              className="mt-4 w-100"
              href={URLS.employerHome}
              secondary
            >
              Go to dashboard
            </LinkButton>
          </Col>
        )}
      </Row>
    </div>
  );
};

SuccessNotification.propTypes = {
  isReschedule: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  appointmentType: PropTypes.oneOf(['phone', 'video']),
};

export default SuccessNotification;
