import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaSet } from 'use-media-set';

import {
  ClickableWrapper,
  DetailsWrapper,
  MobileWrapper,
  StyledCollapsibleContainer,
} from './styles';
import DeleteSchedulingPageButton from '../DeleteSchedulingPageButton';
import { openingHoursText } from 'components/employer/interviews/RequestInterview/supporting';
import stored from 'components/hoc/stored';
import { Box } from 'components/ui/Box';
import Button from 'components/ui/buttons/Button';
import Flex from 'components/ui/Flex';
import Checkmark from 'components/ui/icons/Checkmark';
import Eye from 'components/ui/icons/Eye';
import Pencil from 'components/ui/icons/Pencil';
import VerticalDots from 'components/ui/icons/VerticalDots';
import {
  FUTURE_DAYS_TO_TEXT,
  MINUTES_TO_TEXT,
  SCHEDULING_PAGE_ACTION_TYPE,
} from 'consts';
import { colors, mediaSetQueries } from 'styleGuide';

const InterviewSchedulingPageCard = ({
  onAction,
  onSelect,
  schedulingPage,
  selected,
  selectable,
}) => {
  const [expanded, setExpanded] = useState(false);
  const mediaStates = useMediaSet(mediaSetQueries);
  const isMobile = mediaStates.has('mobile');
  const { EDIT, DELETE, VIEW } = SCHEDULING_PAGE_ACTION_TYPE;

  const pageAction = (ev, action) => {
    ev.preventDefault();
    onAction(action, schedulingPage);
    setExpanded(false);
  };

  const buttonProps = {
    block: isMobile,
    className: `text-nowrap ${isMobile && 'text-left'}`,
    inline: true,
    link: true,
  };
  const buttons = (
    <>
      <Button
        {...buttonProps}
        data-id="view-scheduling-page-button"
        onClick={ev => pageAction(ev, VIEW)}
      >
        <Eye width="18px" height="18px" />
        <span className="ml-1">View</span>
      </Button>
      <Button
        {...buttonProps}
        data-id="edit-scheduling-page-button"
        onClick={ev => pageAction(ev, EDIT)}
      >
        <Pencil width="18px" height="18px" />
        <span className="ml-1">Edit</span>
      </Button>
      <DeleteSchedulingPageButton
        id="delete-scheduling-page-button-comp"
        handleClick={ev => pageAction(ev, DELETE)}
        schedulingPage={schedulingPage}
      />
    </>
  );

  const renderName = () => {
    const nameText = (
      <div
        className={`text-overflow text-left font-${
          selected ? 'heavy' : 'bold'
        }`}
      >
        {schedulingPage.name}
      </div>
    );
    if (selectable) {
      return nameText;
    } else {
      return (
        <Button
          data-id="scheduling-page-name"
          link
          inline
          onClick={ev => pageAction(ev, VIEW)}
        >
          {nameText}
        </Button>
      );
    }
  };

  const renderDetails = () => {
    return (
      <Flex alignItems="stretch" direction="column" css="min-width: 0">
        {renderName()}
        {!isMobile && (
          <>
            <div>{MINUTES_TO_TEXT[schedulingPage.duration]}</div>
            <div>
              Schedule&nbsp;
              {FUTURE_DAYS_TO_TEXT[schedulingPage.available_days_in_future]} in
              the future
            </div>
          </>
        )}
        <div>{openingHoursText(schedulingPage.opening_hours)}</div>
      </Flex>
    );
  };

  const renderContent = () => (
    <DetailsWrapper isMobile={isMobile} selectable={selectable}>
      {selectable ? (
        <>
          <Checkmark
            checked={selected}
            className="check"
            color={selected ? colors.blue.primary : 'transparent'}
          />
          <ClickableWrapper onClick={onSelect}>
            {renderDetails()}
          </ClickableWrapper>
        </>
      ) : (
        <>{renderDetails()}</>
      )}
      {renderButtons()}
    </DetailsWrapper>
  );

  const renderButtons = () => {
    if (isMobile) {
      return (
        <Button
          inline
          link
          data-id="mobile-menu"
          onClick={() => setExpanded(!expanded)}
          onBlur={() => setExpanded(false)}
        >
          <VerticalDots size={20} />
        </Button>
      );
    } else {
      return (
        <Flex direction="row" gap={16} justifyContent="flex-end">
          {buttons}
        </Flex>
      );
    }
  };

  return isMobile ? (
    <MobileWrapper className={selected ? 'selected' : ''}>
      {renderContent()}
      <StyledCollapsibleContainer isOpen={expanded}>
        <Box className="py-3 px-4">
          <Flex
            alignItems="stretch"
            className="w-100"
            direction="column"
            gap={16}
          >
            {buttons}
          </Flex>
        </Box>
      </StyledCollapsibleContainer>
    </MobileWrapper>
  ) : (
    <Box>{renderContent()}</Box>
  );
};

InterviewSchedulingPageCard.propTypes = {
  onAction: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  schedulingPage: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
};

InterviewSchedulingPageCard.defaultProps = {
  selected: false,
};

export default stored(InterviewSchedulingPageCard);
