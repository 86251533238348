import React, { useEffect } from 'react';

function deprecated(WrappedComponent, message) {
  const { name, displayName } = WrappedComponent;
  function DeprecatedComponent(props) {
    useEffect(() => {
      if (process.env.NODE_ENV === 'development') {
        console.warn(`${name || displayName} is deprecated.`, message || '');
      }
    }, []);
    return <WrappedComponent {...props} />;
  }

  return DeprecatedComponent;
}

export default deprecated;
