import React from 'react';
import styled from 'styled-components';
import { getUtmValues } from 'utilities';

// We are using these hidden fields to test the HubspotFormAPI

export const HiddenFields = styled.div`
  height: 0;
`;

const HubspotUTMFields = () => {
  return (
    <HiddenFields id="hubspotUtmFields" className="overflow-hidden invisible">
      <input
        id="form_source"
        name="form_source"
        defaultValue={getUtmValues.form_source}
        data-testid="form_source"
      />
      <input
        id="session_campaign"
        name="session_campaign"
        defaultValue={getUtmValues.session_campaign}
        data-testid="session_campaign"
      />
      <input
        id="session_content"
        name="session_content"
        defaultValue={getUtmValues.session_content}
        data-testid="session_content"
      />
      <input
        id="session_medium"
        name="session_medium"
        defaultValue={getUtmValues.session_medium}
        data-testid="session_medium"
      />
      <input
        id="session_referrer"
        name="session_referrer"
        defaultValue={getUtmValues.session_referrer}
        data-testid="session_referrer"
      />
      <input
        id="session_source"
        name="session_source"
        defaultValue={getUtmValues.session_source}
        data-testid="session_source"
      />
      <input
        id="session_term"
        name="session_term"
        defaultValue={getUtmValues.session_term}
        data-testid="session_term"
      />
    </HiddenFields>
  );
};

export default HubspotUTMFields;
