import React, { useContext } from 'react';
import ReactiveRecord, { Form } from 'reactiverecord';
import Button from 'components/ui/buttons/Button';
import Input from 'components/ui/inputs/Input';
import PropTypes from 'prop-types';
import { VideoContext } from './VideoProvider';
import styled, { css } from 'styled-components';

const VideoAccessToken = ReactiveRecord.model('VideoAccessToken');

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    @media (max-width: ${theme.screenSizes.tablet}) {
      width: 100%;
    }
  `,
);

const VideoAccessTokenForm = ({
  identity,
  roomName,
  secondary,
  setAlreadyJoined,
  submitBtnEnabled,
}) => {
  const { connect, hasExitedRoom, localAudioTrack, localVideoTrack } =
    useContext(VideoContext);
  let buttonText = 'Join interview';
  if (hasExitedRoom) {
    buttonText = 'Rejoin interview';
  }

  const handleOnClick = () => {
    if (hasExitedRoom && localVideoTrack.isEnabled) {
      localVideoTrack.restart();
    }
    if (hasExitedRoom && localAudioTrack.isEnabled) {
      localAudioTrack.restart();
    }
  };

  return (
    <Form
      for={new VideoAccessToken()}
      afterSave={connect}
      afterRollback={() => setAlreadyJoined(true)}
    >
      {fields => (
        <>
          <Input
            type="hidden"
            {...fields.room_name}
            labelText=""
            defaultValue={roomName}
          />
          <Input
            type="hidden"
            {...fields.identity}
            labelText=""
            defaultValue={identity}
          />
          <StyledButton
            id="submit-video-token"
            {...fields.submit}
            disabled={!submitBtnEnabled || fields.submitting}
            secondary={secondary}
            onClick={handleOnClick}
          >
            {buttonText}
          </StyledButton>
        </>
      )}
    </Form>
  );
};

VideoAccessTokenForm.propTypes = {
  identity: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  setAlreadyJoined: PropTypes.func.isRequired,
  submitBtnEnabled: PropTypes.bool.isRequired,
};

VideoAccessTokenForm.defaultProps = {
  secondary: false,
};

export default VideoAccessTokenForm;
