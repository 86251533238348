import React, { createContext, useState } from 'react';
import useVideoRoom from './useVideoRoom';
import PropTypes from 'prop-types';
import useLocalTracks from './useLocalTracks';

export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [roomState, setRoomState] = useState('disconnected');
  const [hasExitedRoom, setHasExitedRoom] = useState(false);
  const {
    localAudioTrack,
    localVideoTrack,
    permissionError,
    notFoundError,
    genericError,
  } = useLocalTracks();
  const localTracks = [localAudioTrack, localVideoTrack];
  const { connect, remoteParticipants, localParticipant, room } = useVideoRoom(
    setRoomState,
    localTracks,
  );

  return (
    <VideoContext.Provider
      value={{
        connect,
        genericError,
        hasExitedRoom,
        localAudioTrack,
        localParticipant,
        localVideoTrack,
        notFoundError,
        permissionError,
        remoteParticipants,
        room,
        roomState,
        setHasExitedRoom,
        setRoomState,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

VideoProvider.propTypes = {
  children: PropTypes.any,
};
