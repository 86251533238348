import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FadeInOutMount from 'components/ui/FadeInOutMount';
import { listItemSharedStyles, Link, navbarItemHeight } from './stylesV2';
import { getNextTooltipPositioning } from 'utilities';

const spacing = 0;
const LabelWrapper = styled.li(
  ({ theme, hasFocus }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${hasFocus ? `box-shadow: inset 0 -5px 0 ${theme.primaryBlue};` : ''}
  ${listItemSharedStyles({ theme })}
`,
);
const PopupWrapper = styled.div(
  ({ theme }) => `
  overflow: hidden;
  background-color: ${theme.white};
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
  ${Link} {
    min-width: 150px;
    padding: 0 1rem;
    font-size: ${theme.fontSizes.base};
    color: ${theme.primaryBlue};
    text-align: left;
    text-transform: none;
    &.profile-link {
      display: flex;
      align-items: center;
    }
  }
`,
);

const DropdownListItem = ({ label, children, ...props }) => {
  const [show, setShow] = useState(false);
  const triggerRef = useRef(null);
  const tooltipPositioning = useRef({ portalStyle: {}, popupStyle: {} });

  const handleShow = useCallback(() => {
    if (!show) {
      tooltipPositioning.current = getNextTooltipPositioning(
        triggerRef.current,
        spacing,
      );
      setShow(true);
    }
  }, [show]);
  const handleHide = useCallback(() => {
    setShow(false);
  }, []);

  const handleClickAway = event => {
    if (!triggerRef.current.contains(event.target)) {
      handleHide();
    }
  };
  const handleTab = event => {
    // hide menu if we tab away from it
    if (
      event.key === 'Tab' &&
      !triggerRef.current.contains(event.target) &&
      show
    ) {
      handleHide();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickAway, true);
    document.addEventListener('keyup', handleTab, true);
    return () => {
      document.removeEventListener('click', handleClickAway, true);
      document.removeEventListener('keyup', handleTab, true);
    };
  });

  return (
    <LabelWrapper
      ref={triggerRef}
      tabIndex="0"
      {...props}
      hasFocus={show}
      onFocus={handleShow}
      onMouseEnter={handleShow}
      onMouseLeave={handleHide}
    >
      <div>{label}</div>
      <FadeInOutMount in={show} timeout={180}>
        <div
          style={{
            ...tooltipPositioning.current.portalStyle,
            position: 'fixed',
            top: `${navbarItemHeight}px`,
            zIndex: '5',
          }}
        >
          <PopupWrapper style={tooltipPositioning.current.popupStyle}>
            {children}
          </PopupWrapper>
        </div>
      </FadeInOutMount>
    </LabelWrapper>
  );
};

DropdownListItem.propTypes = {
  label: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

export default DropdownListItem;
