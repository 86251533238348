import styled from 'styled-components';
import theme from 'theme';

export const TalentFlexWrapper = styled.div`
  .selectable-button {
    width: calc(100% - 30px);
  }
`;

export const TooltipWrapper = styled.div`
  margin-bottom: 0.825rem;

  a {
    margin-bottom: 0;
  }

  g {
    color: ${({ theme }) => theme.primaryBlue};
    fill: currentColor;
  }
`;

export const CenterAlignedDiv = styled.div`
  align-self: center;
`;

export const Label = styled.div`
  font-family: sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  color: rgba(142, 145, 153, 1);
  text-transform: uppercase;
`;

export const AddSkillTitle = styled.button`
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.fontHeavy};
  color: ${theme.primaryBlue};
`;

export const AddSkillSearch = styled.div`
  font-family: ${theme.fonts.primary};
  margin-left: 16px;
`;
