import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import themed from 'components/hoc/themed';

class InputMoney extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  componentDidMount() {
    this.moneyValidator(this.input.current, 7);
  }

  moneyValidator = (element, size, maxValue) => {
    const { required } = this.props;
    element.addEventListener('keypress', applyRegex);
    element.addEventListener('keyup', applyRegex);
    element.addEventListener('blur', applyRegex);

    function applyRegex() {
      this.value.replace(/^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, '');
      // we don't think this is a valid check any longer, but leaving it in as a reminder in case it has unintended side effects
      // if (this.value.length >= size) {
      //   e.preventDefault();
      // }
      if (
        (maxValue && this.value > maxValue) ||
        (required && (!this.value || this.value === '0'))
      ) {
        this.classList.add('error');
      } else {
        this.classList.remove('error');
      }
    }
  };

  render() {
    const { name, value, onChange, placeholder, readOnly, required } =
      this.props;
    const inputClassnames = classNames('form-control', { validate: required });
    let inputProps = {
      className: inputClassnames,
      type: 'number',
      name: name,
      ref: this.input,
      readOnly: readOnly,
      required: required,
      placeholder: placeholder,
    };

    if (onChange) {
      inputProps.onChange = onChange;
      inputProps.value = value;
    } else {
      inputProps.defaultValue = value;
    }

    return (
      <div className="input-money-container">
        <input {...inputProps} />
      </div>
    );
  }
}

InputMoney.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

InputMoney.defaultProps = {
  readOnly: false,
  required: false,
};

export default themed(InputMoney);
