import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Star from 'components/ui/icons/Star';
import { colors, fontWeight } from 'styleGuide';

const markerWidth = 40;

export const Container = styled.div(
  props => `
    align-items: center;
    display: grid;
    height: ${props.withCircle && !props.barHeightOnly ? 42 : 20}px;
    position: relative;
`,
);

export const Bar = styled.div(
  props => `
    height: 20px;
    border-radius: 10px;
    border: 1px solid ${colors.gray.light};
    background-color: ${colors.white};
    ${props.barHeightOnly && 'margin: -11px 0;'}
`,
);

export const PercentageFill = styled.div(
  props => `
    background-color: ${colors.blue.primary};
    border-radius: 10px;
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    transition: width 0.6s ease;
    white-space: nowrap;
    width: ${props.width}%;
`,
);

export const PercentagePoint = styled.div(
  props => `
    align-items: center;
    background: ${colors.white};
    border-radius: 50%;
    border: solid 1px ${colors.gray.light};
    color: ${colors.blue.primary};
    display: flex;
    font-weight: ${fontWeight.fontHeavy};
    height: ${markerWidth}px;
    justify-content: center;
    left: calc(${props.percentage}% - ${markerWidth / 2}px);
    letter-spacing: -1px;
    position: absolute;
    transition: all 0.6s ease;
    width: ${markerWidth}px;
`,
);

const ProgressBar = props => {
  return (
    <Container {...props}>
      <Bar>
        <PercentageFill
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={props.percentage}
          role="progressbar"
          width={props.percentage}
        >
          {props.withCircle && (
            <PercentagePoint percentage={props.percentage}>
              {props.alwaysStar || props.percentage === 100 ? (
                <Star />
              ) : (
                `${props.percentage}%`
              )}
            </PercentagePoint>
          )}
        </PercentageFill>
      </Bar>
    </Container>
  );
};

ProgressBar.propTypes = {
  alwaysStar: PropTypes.bool,
  barHeightOnly: PropTypes.bool,
  percentage: PropTypes.number.isRequired,
  withCircle: PropTypes.bool,
};

ProgressBar.defaultProps = {
  alwaysStar: false,
  barHeightOnly: false,
  percentage: 0,
  withCircle: false,
};

export default ProgressBar;
