import React from 'react';

import Icon from '../Icon';

const Lock = props => {
  return (
    <Icon viewBox="0 0 20 24" fill="none" {...props}>
      <rect
        x="1"
        y="9"
        width="18"
        height="14"
        rx="1"
        stroke={props.color}
        strokeWidth="2"
      />
      <path
        d="M5 9C4.99995 6 5.6 1 10 1C14.4 1 15.0001 5.5 15.0001 9"
        stroke={props.color}
        strokeWidth="2"
      />
    </Icon>
  );
};

Lock.propTypes = { ...Icon.propTypes };
Lock.defaultProps = { ...Icon.defaultProps };

export default Lock;
