import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Caret from 'components/ui/icons/Caret';
import Button from 'components/ui/buttons/Button';
import { colors } from 'styleGuide';
import { toKebabCase } from 'utilities';

const FilterToggleButton = styled(Button)(
  ({ bordered, dense, disabled, open, theme }) => {
    const paddingBottom = dense ? 0.5 : open || disabled ? 1 : 1.5;
    return css`
      border-radius: 0;
      width: 100%;
      padding-top: ${dense ? 1 : 1.5}rem;
      padding-bottom: ${paddingBottom}rem;
      line-height: 1.25rem;
      .header-text {
        color: ${theme.darkGray};
      }
      &:hover,
      &:focus {
        .header-text {
          color: ${theme[disabled ? 'darkGray' : 'darkBlue']};
        }
      }
      ${disabled && `background-color: transparent !important;`}
      ${bordered && `border-top: 1px solid ${theme.darkGray}`}
    `;
  },
);
function FilterHeader({
  bordered,
  caretColor,
  collapsible,
  dense,
  headerIcon,
  headerText,
  open,
  setOpen,
  ...props
}) {
  return (
    <FilterToggleButton
      bordered={bordered}
      dense={dense}
      disabled={!collapsible}
      link
      inline
      id={props.id || `toggle-${toKebabCase(headerText)}`}
      onClick={() => collapsible && setOpen(!open)}
      type="button"
      {...props}
    >
      <div className="d-flex flex-row align-items-center justify-content-between gap-1 w-100">
        {headerIcon}
        <div className="header-text text-left text-wrap w-100">
          {headerText}
        </div>
        {collapsible && (
          <Caret
            color={caretColor}
            size={22}
            direction={open ? 'up' : 'down'}
          />
        )}
      </div>
    </FilterToggleButton>
  );
}

FilterHeader.propTypes = {
  bordered: PropTypes.bool,
  caretColor: PropTypes.string,
  collapsible: PropTypes.bool,
  dense: PropTypes.bool,
  headerIcon: PropTypes.node,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

FilterHeader.defaultProps = {
  bordered: false,
  caretColor: colors.gray.dark,
  collapsible: false,
  dense: false,
  open: false,
  setOpen: null,
};

export default FilterHeader;
