import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/experiences';
const memberRoute = `${collectionRoute}/:id`;

function reverseChronological(a, b) {
  const aCurrent = a.current ? 1 : 0;
  const bCurrent = b.current ? 1 : 0;
  if (bCurrent - aCurrent) {
    return bCurrent - aCurrent;
  }
  if (a.end_date > b.end_date) {
    return -1;
  }
  if (a.end_date < b.end_date) {
    return 1;
  }
  if (a.start_date > b.start_date) {
    return -1;
  }
  if (a.start_date < b.start_date) {
    return 1;
  }
  return 0;
}

class Experience extends Model {
  static routes = {
    index: collectionRoute,
    create: collectionRoute,
    show: memberRoute,
    update: memberRoute,
    destroy: memberRoute,
  };

  static scopes = {
    reverseChronological(collection) {
      return collection.slice().sort(reverseChronological);
    },
  };

  static schema = {
    career_pause: Boolean,
    city_state: String,
    city: String,
    company_name: String,
    current: { labelText: 'I currently work here', type: Boolean },
    date_summary: String,
    description: { labelText: 'Main Responsibilities', type: String },
    end_date: { labelText: 'End', type: String },
    extracted_from_parsed_resume: Boolean,
    job_title: String,
    sanitized_description: String,
    start_date: { labelText: 'Start', type: String },
    user_confirmed: Boolean,
    state: String,
    _timestamps: true,
  };

  static defaultCareerPauseDescription = `Power in the Pause; Life evolves. This section denotes an extended step away from the paid workforce to significantly contribute to one's family.`;
}

export default ReactiveRecord.model('Experience', Experience);
