import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/ui/buttons/Button';
import Flex from 'components/ui/Flex';
import Caret from 'components/ui/icons/Caret';
import { colors } from 'styleGuide';

const CaretToggle = ({
  caretSize,
  fontSize,
  showText,
  hideText,
  parentSelector,
  collapseSelector,
}) => {
  const [open, setOpen] = useState(true);
  const toggleFunction = () => {
    document.querySelector(parentSelector).classList.toggle(collapseSelector);
    if (open) {
      setTimeout(() => setOpen(!open), 500);
    } else {
      setOpen(!open);
    }
  };
  const StyledButton = styled(Button)`
    min-width: unset;
    &:hover {
      background-color: #60606016 !important;
    }
    span {
      font-weight: 400;
      color: ${colors.gray.medium};
    }
  `;
  return (
    <StyledButton
      data-id="show-hide-btn"
      onClick={toggleFunction}
      data-testid="show-hide-toggle"
      size={fontSize}
      link
    >
      <Flex direction="row" alignItems="center" gap={8}>
        <span className="font-sm">{open ? hideText : showText}</span>
        <Caret
          color={colors.gray.medium}
          size={caretSize}
          direction={open ? 'up' : 'down'}
        />
      </Flex>
    </StyledButton>
  );
};

CaretToggle.propTypes = {
  caretSize: PropTypes.number,
  collapseSelector: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  hideText: PropTypes.string,
  parentSelector: PropTypes.string.isRequired,
  showText: PropTypes.string,
};

CaretToggle.defaultProps = {
  caretSize: 14,
  fontSize: 'xs',
  hideText: 'Hide',
  showText: 'Show',
};

export default CaretToggle;
