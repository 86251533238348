import {
  roleValidations,
  locationValidations,
  experienceValidations,
  typeValidations,
  wageValidations,
  descriptionValidations,
} from 'validationSchemas/Project';

const PROJECT_VALIDATIONS = [
  { name: 'role', validations: roleValidations },
  { name: 'location', validations: locationValidations },
  { name: 'experience', validations: experienceValidations },
  { name: 'type', validations: typeValidations },
  { name: 'wage', validations: wageValidations },
  { name: 'description', validations: descriptionValidations },
];

export const getCurrentStep = async project => {
  if (project.status !== 'draft') {
    return 'review';
  }

  let stepName = '';
  for (let i = 0; i < PROJECT_VALIDATIONS.length; i++) {
    await PROJECT_VALIDATIONS[i].validations.validate(project).catch(() => {
      stepName = PROJECT_VALIDATIONS[i].name;
    });
    if (!!stepName) {
      break;
    }
  }
  return stepName || 'review';
};
