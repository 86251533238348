import React from 'react';
import Pencil from 'components/ui/icons/Pencil';
import PropTypes from 'prop-types';

function PencilEditLink({ linkText, ...props }) {
  return (
    <a
      css={`
        white-space: nowrap;
        font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
        svg {
          display: inline-block;
          vertical-align: top;
        }
      `}
      {...props}
    >
      <Pencil width="18px" height="18px" />
      {linkText}
    </a>
  );
}
PencilEditLink.propTypes = {
  href: PropTypes.string,
  linkText: PropTypes.string,
};
PencilEditLink.defaultProps = {
  href: '#',
  linkText: 'Edit',
};

export default PencilEditLink;
