import React from 'react';
import LinkButton from 'components/ui/buttons/LinkButton';
import { URLS } from 'consts';

const ReturnToJobsCTA = () => {
  return (
    <LinkButton
      href={URLS.talentMarketplace}
      id="return-to-jobs-button"
      className="mb-3"
    >
      Return to jobs
    </LinkButton>
  );
};

export default ReturnToJobsCTA;
