import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import themed from 'components/hoc/themed';
import queryCliented from 'components/hoc/queryCliented';
import { URLS } from 'consts';
import { useShowDemographicSurvey } from 'requests';
import ApplicationSaved from './ApplicationSaved';
import BidModalWrapper from './BidModalWrapper';
import FeedbackFormOrJobs from './FeedbackFormOrJobs';

export const CloseButton = styled.a`
  position: absolute;
  right: 0;
`;

const BidModalPrompts = ({ jobTitle }) => {
  const [visible, setVisible] = useState(false);
  const [bidStatus, setBidStatus] = useState('');

  const setVisibleAndBidStatus = modal => {
    // the modal change is triggered here after the MutationObserver is called
    // TODO: find a better way to do this
    const status = document.querySelector('.modal .bid-status').innerText;
    setVisible(modal.style.display === 'block');
    setBidStatus(status);
  };

  const observeModalAttributeChange = func => {
    const modal = document.querySelector('.modal');
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes') {
          func(modal);
        }
      });
    });

    observer.observe(modal, {
      attributes: true,
    });
  };

  observeModalAttributeChange(setVisibleAndBidStatus);

  const bidSubmitted = bidStatus.match(/submitted/i);

  const redirectToDemographicSurvey = () => {
    sessionStorage.setItem('submittedJob', jobTitle);
    window.location = URLS.talentDemographic;
  };

  const checkEeoApi = visible && !!bidSubmitted;

  const {
    isLoading: eeoLoading,
    isFetching: eeoFetching,
    data: eeoSurvey,
  } = useShowDemographicSurvey(checkEeoApi);

  const eeoSurveyLoaded = !eeoLoading && !eeoFetching;

  return (
    <>
      {visible && (
        <>
          {bidSubmitted ? (
            <>
              {eeoSurveyLoaded && eeoSurvey.show_demographic_survey ? (
                redirectToDemographicSurvey()
              ) : (
                <FeedbackFormOrJobs jobTitle={jobTitle} />
              )}
            </>
          ) : (
            <BidModalWrapper>
              <ApplicationSaved />
            </BidModalWrapper>
          )}
        </>
      )}
    </>
  );
};

BidModalPrompts.propTypes = {
  jobTitle: PropTypes.string.isRequired,
};

export default queryCliented(themed(BidModalPrompts));
