import ReactiveRecord, { Model } from 'reactiverecord';

const routePrefix = ':prefix/employers/appointments';
class EmployerAppointment extends Model {
  static routes = {
    index: routePrefix,
    create: routePrefix,
    update: `${routePrefix}/:id`,
    show: `${routePrefix}/:id`,
  };

  static schema = {
    appointment_type: { type: String, labelText: 'Type of interview' },
    available_dates: Array,
    bid_id: Number,
    bid_schedule_date: Array,
    duration: Number,
    email: { type: String, labelText: `Interviewer's email` },
    employer_reschedule: Boolean,
    mom_id: Number,
    nylas_booking_url: String,
    phone: { type: String, labelText: `Interviewer's phone number` },
    preferred_dates: String,
    preferred_times: String,
    reschedule: Boolean,
    talent_preferred_timezone: String,
    time_zone: { type: String, labelText: `Interviewer's time zone` },
  };
}

export default ReactiveRecord.model('EmployerAppointment', EmployerAppointment);
