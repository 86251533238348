import React, { useContext, useState } from 'react';
import stored from 'components/hoc/stored';
import PropTypes from 'prop-types';
import RescheduleRequestButton from './RescheduleRequestButton';
import RescheduleRequestHandler from 'components/employer/interviews/InterviewModal/RescheduleRequestHandler';
import InterviewModal from 'components/employer/interviews/InterviewModal';
import { Box } from 'components/ui/Box';
import InterviewContext from './InterviewContext';
import Dialog from 'components/ui/Dialog';
import { getTimeFromNow } from 'utilities';

const RescheduleRequest = props => {
  const [openModal, toggleOpenModal] = useState(false);
  const toggleModal = () => {
    toggleOpenModal(!openModal);
  };
  const { project_title, talent_name, requested_at } = props.request.attributes;
  const daysAgo = requested_at ? getTimeFromNow(requested_at) : '';

  const dispatch = useContext(InterviewContext);
  const handleSuccess = () => {
    dispatch({ type: 'reschedule_by_talent', interview: props.request });
    toggleOpenModal(false);
  };

  return (
    <>
      <div className="reschedule-request mt-3 box-shadow-sm">
        <Box size="md" className="mb-2">
          <div className="request-header">
            <div className="title">
              <strong>{project_title}</strong> Interview with{' '}
              <strong>{talent_name}</strong>.
            </div>
            <div className="requested">
              <strong>Requested </strong>
              {daysAgo}
            </div>
          </div>
          <div className="request-message">
            <p>
              <strong>{talent_name}.</strong> has requested that their interview
              be held at a different time. Either the time slot they previously
              confirmed no longer works with their schedule, or they aren&apos;t
              available during the time slots you proposed.
            </p>
          </div>
          <div className="action-buttons">
            <RescheduleRequestButton
              data-id="reschedule-request-btn"
              onClick={toggleModal}
            />
          </div>
        </Box>
      </div>
      {openModal && (
        <RescheduleRequestHandler appointmentId={+props.request.id}>
          {appointment => (
            <Dialog onHide={toggleModal} show={true} large>
              <InterviewModal
                bidId={+props.request.relationships.bid.data.id}
                handleOnClose={toggleModal}
                handleOnSuccess={handleSuccess}
                interview={appointment}
                isReschedule
                mom={{
                  id: +props.request.relationships.mom.data.id,
                  first_name_last_initial: props.request.attributes.talent_name,
                }}
                projectTitle={props.request.attributes.project_title}
              />
            </Dialog>
          )}
        </RescheduleRequestHandler>
      )}
    </>
  );
};

RescheduleRequest.displayName = 'RescheduleRequest';

RescheduleRequest.propTypes = {
  request: PropTypes.object.isRequired,
};

export default stored(RescheduleRequest);
