import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Options from './SelectableOptions';
import MultiSelectManager from '../ui/MultiSelectManager';

function Questionnaire(props) {
  const questionnaireClasses = classNames('form-group col-12 questionnaire', {
    validate: props.validate,
  });

  const formattedOptions = Object.keys(props.options).map(key => {
    const option = props.options[key];
    if (typeof option === 'object') {
      return { id: key, title: option.label, tooltip: option.description };
    } else {
      return { id: key, title: option };
    }
  });

  return (
    <MultiSelectManager
      onChange={props.onChange}
      initialSelected={props.selected}
      maxLength={props.maxLength}
    >
      {({ onChange, selected }) => (
        <div className="row">
          <div className={questionnaireClasses}>
            <div className="font-md font-medium">
              {props.label}
              {props.validate && <span className="required-mark">*</span>}
            </div>
            {props.helpText ? (
              <div className="text-alt font-sm mt-1 mb-3">{props.helpText}</div>
            ) : (
              <div className="text-alt font-sm mb-3" />
            )}
            <Options
              cols={2}
              options={formattedOptions}
              selected={selected}
              handleClick={item => onChange(props.fieldName, item.id)}
              targetName={props.targetName}
            />
          </div>
        </div>
      )}
    </MultiSelectManager>
  );
}

Questionnaire.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.object,
  cols: PropTypes.number,
  helpText: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  maxLength: PropTypes.number,
  validate: PropTypes.bool,
  targetName: PropTypes.string,
};

Questionnaire.defaultProps = {
  validate: false,
  cols: 2,
  selected: [],
};

export default Questionnaire;
