import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import Checkmark from 'components/ui/icons/Checkmark';

const BorderedStep = styled.div(
  ({ theme }) => css`
  position: relative;
  width: 100%;
  padding-top: 20px;

  ::after {
    content: '';
    height: 5px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: webkit-linear-gradient(
      to right,
      ${({ active, completed }) =>
        completed
          ? active
            ? `${theme.primaryBlue} 0%,
            ${theme.primaryBlue} 75%,
            ${theme.lightGray} 75%,
            ${theme.lightGray} 100%`
            : `${theme.primaryBlue} 0%,
            ${theme.primaryBlue} 100%`
          : `${theme.lightGray} 0%,
      ${theme.lightGray} 100%`}
    );
    background: linear-gradient(
      to right,
      ${({ active, completed }) =>
        completed
          ? active
            ? `${theme.primaryBlue} 0%,
            ${theme.primaryBlue} 75%,
            ${theme.lightGray} 75%,
            ${theme.lightGray} 100%`
            : `${theme.primaryBlue} 0%,
            ${theme.primaryBlue} 100%`
          : `${theme.lightGray} 0%,
      ${theme.lightGray} 100%`}
    );
  `,
);

const StepNumber = styled.span(
  ({ theme }) => css`
    font-weight: ${theme.fontWeights.fontMedium};
    height: 25px;
    width: 25px;
    background-color: ${({ completed }) =>
      completed ? `${theme.primaryBlue}` : `${theme.lightestGray}`};
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: ${({ completed }) =>
      completed ? `${theme.white}` : `${theme.darkGray}`};
    margin-right: 6px;
  `,
);

const StepLabel = styled.span(
  ({ theme }) => css`
    display: none;
    font-weight: ${({ active }) =>
      active
        ? `${theme.fontWeights.fontHeavy}`
        : `${theme.fontWeights.fontNormal}`};
    color: ${theme.darkGray};
    @media (min-width: ${theme.screenSizes.tablet}) {
      display: inline-block;
    }
  `,
);

// Format for `steps` props
// [
//   {label: 'Step one', active: false},
//   {label: 'Step two', active: true},
//   {label: 'Step three', active: false},
// ]
const MultiStepProgressBar = ({ steps }) => {
  const indexOfActiveStep = steps.findIndex(step => step.active);

  // If more than one step is active - we throw an error to let the developer know
  // they are not implementing step functionality correctly.
  useEffect(() => {
    const activeStepCount = steps.filter(s => s.active).length;
    if (activeStepCount > 1) {
      throw new Error(
        'MultiStepProgressBar can only have one active step at a time.',
      );
    }
  }, [steps]);

  const stepsForBar = steps.map((step, index) => {
    const isCompleted = index <= indexOfActiveStep;
    return (
      <BorderedStep
        completed={isCompleted}
        active={step.active}
        key={`${step.label}-${index}`}
      >
        <div>
          <StepNumber completed={isCompleted}>
            {isCompleted && !step.active ? (
              <Checkmark checked={true} />
            ) : (
              index + 1
            )}
          </StepNumber>
          <StepLabel active={step.active}>{step.label}</StepLabel>
        </div>
      </BorderedStep>
    );
  });

  return (
    <div className="w-100 mt-4">
      <div className="d-flex flex-row justify-content-between w-100">
        {stepsForBar}
      </div>
    </div>
  );
};

MultiStepProgressBar.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default MultiStepProgressBar;
