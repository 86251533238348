import React from 'react';
import store from 'utilities/store';
import { Provider } from 'react-redux';
import themed from './themed';

function stored(WrappedComponent) {
  const { name, displayName } = WrappedComponent;
  const ThemedWrappedComponent = themed(WrappedComponent);
  function StoredComponent(props) {
    return (
      <Provider store={store}>
        <ThemedWrappedComponent {...props} />
      </Provider>
    );
  }
  StoredComponent.displayName = `Stored(${displayName || name})`;
  return StoredComponent;
}

export default stored;
