import React from 'react';
import PropTypes from 'prop-types';
import ReactiveRecord, { Member } from 'reactiverecord';

const FeedbackFormModel = ReactiveRecord.model('FeedbackForm');

let requestedAtLeastOnce = false;
let loadedAtLeastOnce = false;

const FeedbackForm = ({ children, ...props }) => {
  return (
    <Member for={FeedbackFormModel} fetch={!requestedAtLeastOnce} {...props}>
      {feedbackForm => {
        requestedAtLeastOnce = true;
        if (/20[0-2]/.test(feedbackForm._request.status)) {
          loadedAtLeastOnce = true;
        }
        return children(feedbackForm, loadedAtLeastOnce);
      }}
    </Member>
  );
};

FeedbackForm.propTypes = {
  children: PropTypes.func.isRequired,
};

export default FeedbackForm;
