import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Portal from './Portal';
import CloseButton from 'components/ui/buttons/CloseButton';

const Modal = props => {
  const modalContainerClasses = classNames(
    'modal-container animated fadeIn faster delay-1s',
    { small: props.size === 'sm' },
    { medium: props.size === 'md' },
    { large: props.size === 'lg' },
    { default: !props.size },
  );
  if (props.show) {
    return (
      <Portal>
        <div id={props.id} className="modal d-block">
          <div className={modalContainerClasses}>
            <div className="modal-border" />
            <div className="modal-content">
              {props.title && (
                <h1
                  className={classNames(
                    'fancy my-4 mx-4',
                    props.titleAlignment,
                  )}
                >
                  {props.title}
                </h1>
              )}
              {props.children}
              <CloseButton
                title="Close"
                onClick={props.onHide}
                data-testid="close-btn"
                id="close-btn"
              />
            </div>
          </div>
        </div>
      </Portal>
    );
  }
  return null;
};

Modal.defaultProps = {
  titleAlignment: 'text-center',
};

Modal.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  show: PropTypes.bool.isRequired,
  id: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  onHide: PropTypes.func,
  titleAlignment: PropTypes.string,
};

export default Modal;
