import ReactiveRecord, { Model } from 'reactiverecord';

class FeedbackFormSubmission extends Model {
  static routes = {
    only: ['create'],
    create: ':prefix/feedback_form_submissions',
  };
  static store = {
    singleton: true,
  };
  static schema = {
    id: Number,
    feedback_form_responses: Array,
  };
}

export default ReactiveRecord.model(
  'FeedbackFormSubmission',
  FeedbackFormSubmission,
);
