import ReactiveRecord, { Model } from 'reactiverecord';

const memberRoute = ':prefix/user/account';
class CurrentUser extends Model {
  static store = {
    singleton: true,
  };

  static routes = {
    index: memberRoute,
    update: memberRoute,
  };

  static schema = {
    admin: Boolean,
    agree_to_terms_and_policy: Boolean,
    avatar_url: String,
    current_password: String,
    email: String,
    first_name: String,
    full_name: String,
    initials: String,
    is_nylas_user: Boolean,
    is_subscriber: Boolean,
    last_name: String,
    password_confirmation: String,
    password: String,
    phone: { type: String, labelText: 'Phone Number' },
    reached_max_interviews_per_day: Boolean,
    receive_notification_emails: Boolean,
    remove_avatar: Boolean,
  };
}

export default ReactiveRecord.model('CurrentUser', CurrentUser);
