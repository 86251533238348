import React from 'react';

function List(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 16" {...props}>
      <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
      <line
        x1="7"
        y1="1.5"
        x2="24.5"
        y2="1.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="1.5" cy="8" r="1.5" fill="currentColor" />
      <line
        x1="7"
        y1="8"
        x2="24.5"
        y2="8"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="1.5" cy="14.5" r="1.5" fill="currentColor" />
      <line
        x1="7"
        y1="14.5"
        x2="24.5"
        y2="14.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default List;
