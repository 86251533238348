import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CheckboxContainer, InputRadio } from './styles';

const Radio = forwardRef(
  (
    {
      infoText,
      labelText,
      containerProps,
      'data-testid': dataTestId,
      ...props
    },
    forwardedRef,
  ) => {
    const generatedId = useMemo(() => `radio-${Radio.idCounter++}`, []);
    const id = props.id || generatedId;

    return (
      <>
        <CheckboxContainer {...containerProps}>
          <InputRadio id={id} {...props} ref={forwardedRef} />
          <label className="w-100" htmlFor={id} data-testid={dataTestId}>
            {labelText}
          </label>
          {infoText && <div className="infoText">{infoText}</div>}
        </CheckboxContainer>
      </>
    );
  },
);

Radio.propTypes = {
  labelText: PropTypes.any,
  id: PropTypes.any,
  containerProps: PropTypes.object,
  'data-testid': PropTypes.string,
  infoText: PropTypes.string,
};
Radio.idCounter = 0;
Radio.displayName = 'Radio';

export default Radio;
