import React from 'react';
import PropTypes from 'prop-types';
import Timer from 'components/ui/icons/Timer';
import styled from 'styled-components';

const ExpirationContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.mediumGray};
    font-size: ${theme.fontSizes.sm};
  `,
);

const ProjectExpiration = ({ expirationLabel }) => {
  return (
    <ExpirationContainer>
      <Timer width={24} height={24} />
      &nbsp;
      {expirationLabel}
    </ExpirationContainer>
  );
};

ProjectExpiration.propTypes = {
  expirationLabel: PropTypes.string.isRequired,
};

export default ProjectExpiration;
