import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import SuccessMessage from 'components/ui/labels/SuccessMessage';
import { Label } from 'components/ui/inputs/Input/styles';
import { StyledDatePicker } from 'components/ui/inputs/DateInput/styles';
import Calendar from 'components/ui/icons/Calendar';
import { noop, without } from 'utilities';

const InputDate = ({
  name,
  touched,
  errors,
  successText,
  labelText,
  onBlur,
  onChange,
  setFieldTouched,
  ...props
}) => {
  const generatedId = useMemo(() => `date-input-${InputDate.idCounter++}`, []);
  const id = props.id || generatedId;
  const [date, setDate] = useState(
    props.defaultValue ? new Date(props.defaultValue) : null,
  );

  const handleChange = date => {
    setDate(date);
    onChange(date);
  };

  const handleCalendarClose = () => {
    onBlur(name);
    // If there is no value, we set the field to true in the touched object.
    // by doing so, we are avoiding a race condition where the error is set before the value is changed
    if (!date) {
      setFieldTouched(name);
    }
  };

  const hasError = touched && touched[name] && errors && errors[name];
  return (
    <Label htmlFor={id}>
      {labelText ? <span className="label-text">{labelText}</span> : null}
      <div className="input-wrapper">
        <StyledDatePicker
          id={id}
          name={name}
          hasError={hasError}
          hasIcon={true}
          value={date}
          clearIcon={null}
          calendarIcon={<Calendar width="20" height="20" />}
          onCalendarClose={handleCalendarClose}
          onChange={handleChange}
          {...without.call(props, 'value', 'onChange')} // Override value and onChange from Formik
        />
        {hasError && <ErrorMessage>{errors[name]}</ErrorMessage>}
        {successText && <SuccessMessage>{successText}</SuccessMessage>}
      </div>
    </Label>
  );
};

InputDate.propTypes = {
  defaultValue: PropTypes.any,
  errors: PropTypes.object,
  id: PropTypes.any,
  labelText: PropTypes.string,
  minDate: PropTypes.object,
  name: PropTypes.string.isRequired,
  successText: PropTypes.string,
  setFieldTouched: PropTypes.func,
  touched: PropTypes.object,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
InputDate.defaultProps = {
  autoComplete: 'off',
  oChange: noop,
  setFieldTouched: noop,
};
InputDate.idCounter = 0;
InputDate.displayName = 'InputDate';

export default InputDate;
