import React, { useRef, useState } from 'react';
import ReactiveRecord, { Form } from 'reactiverecord';
import PropTypes from 'prop-types';
import { getAppointmentTypeOptions } from '../supporting';
import SuccessNotification from './SuccessNotification';
import { ModalContainer } from './InterviewModalStyles';
import ReschedulePreferences from './ReschedulePreferences';
import TimeAndDateSection from './TimeAndDateSection';
import InterviewModalHeader from 'components/employer/interviews/InterviewModalHeader';
import stored from 'components/hoc/stored';
import Button from 'components/ui/buttons/Button';
import { Row, Col } from 'components/ui/grid';
import Input from 'components/ui/inputs/Input';
import InputPhone from 'components/ui/inputs/InputPhone';
import Select from 'components/ui/inputs/Select';
import { TimezoneOptions } from 'components/ui/inputs/TimezoneSelector';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import withTooltip from 'components/ui/withTooltip';
import { APPOINTMENT_TYPES_LABEL } from 'consts';
import { getTimeZoneFromBrowser } from 'utilities';
import { useInputValue } from 'utilities/hooks';
import { useCurrentUser } from 'requests';

const Tooltip = withTooltip('div');

const EmployerAppointment = ReactiveRecord.model('EmployerAppointment');

const InterviewModal = ({ mom, ...props }) => {
  const { isLoading, isFetching, data: currentUser } = useCurrentUser();
  const appointment = useRef(props.interview || new EmployerAppointment());
  const [showNotification, setShowNotification] = useState(false);
  const [handleInputChange, getInputValue] = useInputValue();

  const tooltipCSS = props.isReschedule ? '' : 'display: none;';

  if (showNotification) {
    return (
      <SuccessNotification
        handleAcceptNotification={props.handleOnSuccess}
        isReschedule={props.isReschedule}
        appointmentType={appointment.current.appointment_type}
        handleOnClose={props.handleOnClose}
      />
    );
  }

  if (isLoading || isFetching) {
    return <LoadingOverlay />;
  }

  return (
    <Form for={appointment.current} afterSave={() => setShowNotification(true)}>
      {fields => (
        <ModalContainer>
          <InterviewModalHeader
            heading={
              props.isReschedule
                ? 'Reschedule an interview'
                : 'Send an invite to interview'
            }
            mom={mom}
          />
          {props.isReschedule &&
            appointment.current.preferred_times &&
            appointment.current.preferred_dates && (
              <ReschedulePreferences
                appointment={appointment.current}
                className="mt-4"
                talentName={mom.first_name_last_initial}
              />
            )}
          <Row className="mb-3 mt-4">
            <Col col={6}>
              <Tooltip
                title={
                  <div
                    css={`
                      min-width: 250px;
                    `}
                  >
                    Unable to change interview type for an active interview
                    request.
                  </div>
                }
                tooltipCSS={tooltipCSS}
              >
                <Select
                  disabled={props.isReschedule}
                  data-testid="appointment-type-select"
                  onChange={e => handleInputChange(e.target.value)}
                  title={<div>This is a tooltip</div>}
                  trigger="CLICK"
                  {...fields.appointment_type}
                >
                  <option value="">Select</option>
                  {getAppointmentTypeOptions()}
                </Select>
              </Tooltip>
            </Col>
            <Col col={6}>
              {getInputValue(fields.appointment_type) ===
                APPOINTMENT_TYPES_LABEL.phone && (
                <InputPhone
                  {...fields.phone}
                  defaultValue={currentUser.phone}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col col={6}>
              <Input
                {...fields.email}
                defaultValue={currentUser.email}
                type="email"
              />
            </Col>
            <Col col={6}>
              <Select
                {...fields.time_zone}
                data-testid="timezone-select"
                defaultValue={
                  appointment.current.time_zone || getTimeZoneFromBrowser()
                }
                truncate
              >
                <TimezoneOptions />
              </Select>
            </Col>
          </Row>
          <h2>When are you free to meet?</h2>
          <p className="mt-2">
            Add a date and block of time you are free and then select an
            option.&nbsp;
            <b>Repeat</b> until you have chosen at least three times to send.
          </p>
          <p>
            Tip: If you enter a longer block of time, we&apos;ll divide it into
            30 minute meetings.
          </p>
          <TimeAndDateSection fields={fields} />
          <div className="d-none">
            <Input
              type="hidden"
              {...fields.bid_id}
              defaultValue={props.bidId}
            />
            <Input type="hidden" {...fields.mom_id} defaultValue={mom.id} />
            <Input type="hidden" {...fields.duration} defaultValue={30} />
          </div>
          <Row className="mt-3 justify-content-end" css="gap: 1rem;">
            <Col className="order-2 order-md-1" col="12" md="3">
              <Button
                className="px-4 w-100"
                data-id="cancel-submit"
                onClick={props.handleOnCancel || props.handleOnClose}
                secondary
              >
                {props.secondaryButtonText || 'Cancel'}
              </Button>
            </Col>
            <Col className="order-1 order-md-2 p-0" col="12" md="5">
              {props.isReschedule ? (
                <Button
                  className="px-5 w-100"
                  data-id="reschedule-interview"
                  {...fields.submit}
                >
                  Send reschedule request
                </Button>
              ) : (
                <Button
                  className="px-5 w-100"
                  data-id="submit-interview"
                  type="submit"
                  {...fields.submit}
                >
                  Send invite
                </Button>
              )}
            </Col>
          </Row>
          {fields.submitting && <LoadingOverlay />}
        </ModalContainer>
      )}
    </Form>
  );
};

InterviewModal.propTypes = {
  bidId: PropTypes.number.isRequired,
  handleOnCancel: PropTypes.func,
  handleOnClose: PropTypes.func.isRequired,
  handleOnSuccess: PropTypes.func.isRequired,
  interview: PropTypes.object,
  isReschedule: PropTypes.bool.isRequired,
  mom: PropTypes.shape({
    city: PropTypes.string,
    first_name_last_initial: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    state: PropTypes.string,
  }).isRequired,
  projectTitle: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string,
};

InterviewModal.defaultProps = {
  isReschedule: false,
};

export default stored(InterviewModal);
