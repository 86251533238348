import React from 'react';
import PropTypes from 'prop-types';
import { Label, StyledInput } from 'components/ui/inputs/Input/styles';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import LabelWithTooltip from './LabelWithTooltip';
import { useFormikContext } from 'formik';

const Input = ({ button, id, labelText, name, tooltipText, ...props }) => {
  const { touched, errors } = useFormikContext();
  const hasErrors = touched && touched[name] && errors && errors[name];
  return (
    <Label htmlFor={id} hasButton={!!button}>
      <span className="label-text">
        <LabelWithTooltip labelText={labelText} tooltipText={tooltipText} />
      </span>
      <div className="input-wrapper">
        <StyledInput name={name} {...props} hasButton={!!button} />
        {button ? button : null}
      </div>
      {hasErrors && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </Label>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  /** Renders adjacent to input */
  button: PropTypes.any,
  errors: PropTypes.object,
  touched: PropTypes.object,
  labelText: PropTypes.string,
  tooltipText: PropTypes.string,
};

Input.displayName = 'Input';

export default Input;
