import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'components/ui/LoadingOverlay';

const LoaderWrapper = props => {
  return props.contentReady ? props.children : <LoadingOverlay />;
};

LoaderWrapper.propTypes = {
  contentReady: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

export default LoaderWrapper;
