import React from 'react';
import PropTypes from 'prop-types';
import { URLS } from 'consts';
import Draft from './Draft';
import OpenProject from './OpenProject';
import PreviousPosition from './PreviousPosition';

const projectTypes = {
  draft: 'draft',
  pending_approval: 'pending_approval',
  open: 'open',
  previous: 'previous',
  active: 'active',
};

const ProjectWrapper = props => {
  const subtitle = projectType => {
    switch (projectType) {
      case projectTypes.draft:
        return 'Drafts';
      case projectTypes.pending_approval:
        return 'Submitted for review';
      case projectTypes.open:
        return 'Open jobs';
      case projectTypes.active:
        return 'Active';
      case projectTypes.previous:
        return 'Archived';
      default:
        return '';
    }
  };

  const renderProject = (projectType, project) => {
    switch (projectType) {
      case projectTypes.draft:
      case projectTypes.pending_approval:
        return <Draft key={`draft-${project.id}`} draft={project} />;
      case projectTypes.open:
      case projectTypes.active:
        return <OpenProject key={`open-project-${project.id}`} job={project} />;
      case projectTypes.previous:
        return (
          <PreviousPosition
            key={`previous-position-${project.id}`}
            project={project}
          />
        );
      default:
        return null;
    }
  };

  const renderFooterLink = projectType => {
    switch (projectType) {
      case projectTypes.draft:
        return <a href={URLS.employerNewProjectURL}>Post a Job</a>;
      case projectTypes.open:
        return <a href={URLS.employerProjects}>See Past Job Posts</a>;
      default:
        return null;
    }
  };

  const { type: projectType, showFooterLink } = props;
  const projects = props.projects.map(project =>
    renderProject(projectType, project),
  );

  return (
    <div
      id={projectType + '-container'}
      className="projects-wrapper page-section-row"
    >
      <div className="sop-container">
        <h2 className="section-subtitle">{subtitle(projectType)}</h2>
        {projects}
        {showFooterLink && renderFooterLink(projectType)}
      </div>
    </div>
  );
};

ProjectWrapper.propTypes = {
  projects: PropTypes.array.isRequired,
  type: PropTypes.oneOf(Object.keys(projectTypes)),
  showFooterLink: PropTypes.bool.isRequired,
};

export default ProjectWrapper;
