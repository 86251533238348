import React from 'react';

export const NewYorkTimes = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="196.048"
      height="27.531"
      viewBox="0 0 196.048 27.531"
      {...props}
    >
      <title>The New York Times</title>
      <path
        fill="currentColor"
        d="M5.707,16.248V8.854l-2.224.992a7.323,7.323,0,0,0-.468,3.113,7.665,7.665,0,0,0,1.5,3.883l1.19-.593M12.219,1.025a3.869,3.869,0,0,1,2.059.887,2.66,2.66,0,0,1,.6,2.608,2.649,2.649,0,0,1-1.295,1.818,3.445,3.445,0,0,1-1.874.524v3.564l1.734,1.4-1.734,1.4v4.854a7.874,7.874,0,0,0,2.814-3.225s.044-.122.146-.351a6.45,6.45,0,0,1-.635,3.358,6.263,6.263,0,0,1-2.6,2.935,7.01,7.01,0,0,1-5.256.907,7.454,7.454,0,0,1-4.9-3.387A8.094,8.094,0,0,1,.1,13.756,8.19,8.19,0,0,1,5.435,6.58,2.814,2.814,0,0,1,6.4,6.322c-.225.153-.484.351-.8.56a5.933,5.933,0,0,0-2.019,2.7L9.057,7.141V14.8L4.641,17.01a5.758,5.758,0,0,0,3.34,1.9,5.243,5.243,0,0,0,3.53-.725V13.228l-1.724-1.4,1.725-1.4V6.863a23.56,23.56,0,0,1-2.751-.585C7.779,6.036,4.5,5.117,3.989,5.04a1.941,1.941,0,0,0-1.533.291A1.522,1.522,0,0,0,2,6.834a1.688,1.688,0,0,0,.468.669,1.425,1.425,0,0,1-.532-.222A2.486,2.486,0,0,1,.788,5.29,3.3,3.3,0,0,1,2.166,2.351a4.151,4.151,0,0,1,2.9-.73c1.613.23,3.781,1.141,5.713,1.6a2.394,2.394,0,0,0,1.853-.064.911.911,0,0,0,.324-1.238c-.411-.709-1.2-.693-1.867-.818A2.858,2.858,0,0,1,12.219,1.025Z"
        transform="translate(-0.1 -1.001)"
      />
      <path
        fill="currentColor"
        d="M86.734,29.141l-2.488,1.823V24.9l2.488,4.241m-.072-5.825s-1.678,1.016-2.806,1.689-2.642,1.407-2.642,1.407v7.055l-1.009.811.134.157.984-.794,3.086,2.79,5.363-4.249-.127-.153-2.964,2.326-2.436-2.281v-.851l4.894-3.6Z"
        transform="translate(-53.302 -15.822)"
      />
      <path
        fill="currentColor"
        d="M48.934,26.263a4.979,4.979,0,0,0,3.445-.48,4.76,4.76,0,0,0,2.481-4.4l.07-2.56V13.233l1.06-.81-.124-.157-1.043.786-2.408-2.677-3.089,2.653V4.013L45.467,7a1,1,0,0,1,.735.98V20.6l-1.635,1.213.112.169.79-.589,2.2,2.044,3.46-2.753L51,20.53l-.814.645-.861-.855.008-7.035,1.008-.859,1.5,1.867s0,4.556,0,6.068c-.011,1.612-.017,3.624-.864,4.6a3.266,3.266,0,0,1-2.049,1.307"
        transform="translate(-29.633 -3.001)"
      />
      <path
        fill="currentColor"
        d="M121.523,25.212c-1.382-.158-1.963-1-1.947-1.657a1.508,1.508,0,0,1,1.235-1.367,2.732,2.732,0,0,1,2.424,1.129l3.193-3.528-.154-.137-.858.959a5.584,5.584,0,0,0-3.339-1.8V8.066l8.293,15.106s.08.173.42.173c.294,0,.223-.238.223-.238V7.9a3.782,3.782,0,0,0,2.2-.911,3.088,3.088,0,0,0,.769-3.6,1.73,1.73,0,0,1-1.7,1.572,2.939,2.939,0,0,1-2.12-.968L126.97,7.63l.152.137.914-1.044A3.911,3.911,0,0,0,130.811,7.9v8.821L124.658,5.55A3.182,3.182,0,0,0,121.924,3.8a2.886,2.886,0,0,0-2.753,2.5,3.465,3.465,0,0,0,.016,1.613s.225-1.46,1.128-1.447c.811.012,1.233.758,1.559,1.319v3.237c-.837.056-3.014.153-3.147,2.419a2.658,2.658,0,0,0,.972,2.024,2.042,2.042,0,0,0,1.049.379.992.992,0,0,1-.495-1.117c.189-.665,1.572-.774,1.622-.5V18.8a4.074,4.074,0,0,0-3.757,1.891,3.073,3.073,0,0,0,.074,3.209,3.209,3.209,0,0,0,3.332,1.31"
        transform="translate(-78.208 -2.587)"
      />
      <path
        fill="currentColor"
        d="M168.531,29.141l-2.489,1.823V24.9l2.489,4.241m-.072-5.825s-1.678,1.016-2.807,1.689-2.641,1.407-2.641,1.407v7.055l-1.01.811.134.157.984-.794,3.086,2.79,5.364-4.249-.127-.153-2.964,2.326-2.436-2.281v-.851l4.894-3.6Z"
        transform="translate(-107.629 -15.822)"
      />
      <path
        fill="currentColor"
        d="M193.846,31.468l-2.63-2.185-1.155.839-.117-.165,1.175-.859V22.99c.057-2.516-2.3-1.891-2.176-4.56a3.217,3.217,0,0,1,1.765-2.354,3.176,3.176,0,0,1,1.278-.311s-1.076.673-.815,1.641c.4,1.491,2.953,1.625,3,3.612v6.93l1.883,1.508.495-.387v-7.5l-1.015-1.008,2.614-2.314,2.388,2.116-.911.988V28l2.237,1.846.4-.254v-8l-1.017-.951,2.62-2.395,2.449,2.024.978-.867.132.153-2.2,1.935v6.523l-5.634,3.475L196.7,29.2l-2.858,2.266"
        transform="translate(-125.519 -10.807)"
      />
      <path
        fill="currentColor"
        d="M534.009,29.141l-2.489,1.823V24.9l2.489,4.241m-.072-5.825s-1.679,1.016-2.806,1.689-2.642,1.407-2.642,1.407v7.055l-1.01.811.135.157.984-.794,3.086,2.79,5.363-4.249-.127-.153-2.964,2.326-2.437-2.281v-.851l4.894-3.6Z"
        transform="translate(-350.365 -15.822)"
      />
      <path
        fill="currentColor"
        d="M471.733,26.307l.131.153,1.01-.871,1.5,1.7v5.83l-.728.665,2.336,2.641,2.4-2.363-.985-1.02V25.988l.6-.476,1.658,1.935v5.814l-.623.621,2.2,2.475L483.578,34l-.865-.907-.022-7.079.636-.516,1.728,1.9v5.782l-.569.568,2.429,2.649,3.261-3.011-.138-.149-.792.729-1.215-1.347v-6.6l1.173-.891-.122-.161-1.174.891-2.219-2.487-3.173,2.508-2.222-2.419-3.065,2.4-2.243-2.387-3.254,2.83"
        transform="translate(-313.34 -15.862)"
      />
      <path
        fill="currentColor"
        d="M451.962,26.237l.132.153.853-.738,1.335,1.58V33.9l-.941.823.131.153.988-.843,2.153,2.483,3.111-2.777-.131-.154-.916.806-1.286-1.484V26.39l1.085-.939-.131-.153-1.03.887-2.266-2.64-3.086,2.693"
        transform="translate(-300.209 -15.973)"
      />
      <path
        fill="currentColor"
        d="M454.632,10.888l2.342-2.145,1.9,2.112-2.353,2.137-1.894-2.1"
        transform="translate(-301.982 -6.143)"
      />
      <path
        fill="currentColor"
        d="M414.776,16.248V8.854l-2.224.992a7.333,7.333,0,0,0-.468,3.113,7.659,7.659,0,0,0,1.5,3.883l1.19-.593m6.511-15.223a3.869,3.869,0,0,1,2.059.887,2.661,2.661,0,0,1,.6,2.608,2.648,2.648,0,0,1-1.3,1.818,3.441,3.441,0,0,1-1.873.524v3.564l1.734,1.4-1.734,1.4v4.854a7.879,7.879,0,0,0,2.815-3.225s.045-.122.145-.351a6.44,6.44,0,0,1-.634,3.358,6.263,6.263,0,0,1-2.6,2.935,7.007,7.007,0,0,1-5.255.907,7.453,7.453,0,0,1-4.9-3.387,8.084,8.084,0,0,1-1.187-4.564A8.189,8.189,0,0,1,414.5,6.58a2.8,2.8,0,0,1,.967-.258c-.225.153-.485.351-.8.56a5.936,5.936,0,0,0-2.02,2.7l5.476-2.443V14.8l-4.416,2.213a5.759,5.759,0,0,0,3.341,1.9,5.24,5.24,0,0,0,3.529-.725V13.228l-1.724-1.4,1.724-1.4V6.863a23.55,23.55,0,0,1-2.75-.585c-.983-.242-4.258-1.161-4.772-1.238a1.941,1.941,0,0,0-1.533.291,1.522,1.522,0,0,0-.459,1.5,1.688,1.688,0,0,0,.468.669A1.421,1.421,0,0,1,411,7.282a2.484,2.484,0,0,1-1.145-1.992,3.3,3.3,0,0,1,1.378-2.939,4.152,4.152,0,0,1,2.9-.73c1.612.23,3.781,1.141,5.713,1.6a2.393,2.393,0,0,0,1.853-.064.911.911,0,0,0,.324-1.238c-.411-.709-1.2-.693-1.867-.818A2.857,2.857,0,0,1,421.287,1.025Z"
        transform="translate(-271.787 -1.001)"
      />
      <path
        fill="currentColor"
        d="M560.864,23.276v3.5l1.166,1.016a15.07,15.07,0,0,0,3.453-3.906,2.679,2.679,0,0,1-2.671,1.072,3.68,3.68,0,0,1-1.948-1.685m-2.828,9.289a3.054,3.054,0,0,1,3.015-1.439,3.644,3.644,0,0,1,2.462,2.31V28.989l-1.275-1.109C560.837,29.287,558.345,31.323,558.036,32.564Zm1.816,3.375a2.318,2.318,0,0,1-2.271-1.871c-.361-1.561.722-2.617,2.359-4.278l-1.932-1.766v-3.37s1.42-.71,2.532-1.4,2.25-1.451,2.25-1.451.83,1.048,1.768.964a1.31,1.31,0,0,0,1.358-1.56c.25.447.922,1.778-1.659,4.753l2.1,1.7v4.378a42.208,42.208,0,0,0-4.75,2.955s-1.289-1.612-2.408-.842C558.445,34.665,558.791,35.544,559.852,35.939Z"
        transform="translate(-370.311 -14.419)"
      />
      <path
        fill="currentColor"
        d="M305.173,32.251l2.714,2.455V27.192l-2.714-2.512v7.571m5.6-6.136.946-.871.136.153-.922.855v6.963s-1.75.928-2.973,1.617-2.526,1.5-2.526,1.5l-3.212-2.7-.849.738-.137-.153.893-.778V25.946h.012s1.515-.685,2.793-1.407c1.143-.645,2.422-1.391,2.422-1.391Z"
        transform="translate(-200.101 -15.71)"
      />
      <path
        fill="currentColor"
        d="M338.352,25.545l2.04-2.084a3.032,3.032,0,0,0,.543.375,1.473,1.473,0,0,0,1.334.081,2.525,2.525,0,0,0,.687-.532,3.288,3.288,0,0,1-1.68,3.233c-.4.193-1.558.565-2.772-.915v7.491l1.291,1.142,1.078-.892L341,33.6l-3.376,2.818-2.569-2.338-.924.87-.151-.162,1.408-1.27.012-6.467-1.08-1.431-.928.818-.134-.149,3.27-2.939,1.827,2.193"
        transform="translate(-221.368 -15.846)"
      />
      <path
        fill="currentColor"
        d="M374.046,28.3l3.772-5.487a2.9,2.9,0,0,0,1.364.818,2.535,2.535,0,0,0,2.206-.831c-.2,1.467-.941,3.132-2.477,3.386a3.709,3.709,0,0,1-2.612-.826l-.245.379,5.237,7.91,1.047-.919.135.153-3.594,3.141L374.046,28.3"
        transform="translate(-248.46 -15.479)"
      />
      <path
        fill="currentColor"
        d="M361.664,7.962c0-1.185-.486-1.915-1.12-1.863l4.115-2.891v16.36h0l1.086,1.145.823-.714.129.153-3.316,2.895-2.246-2.109-.9.79-.142-.153,1.569-1.366V7.962"
        transform="translate(-239.194 -2.467)"
      />
      <path
        fill="currentColor"
        d="M264.489,14.221a1.681,1.681,0,0,1-1.17.516,1.727,1.727,0,0,1-1.183-.52v2.241a1.621,1.621,0,0,1,2.353-.028V14.221m0-4.8-1.587-1.4-.766.6v5.253a1.67,1.67,0,0,0,1.187.6,1.615,1.615,0,0,0,1.166-.572ZM262.136,24.3a2.035,2.035,0,0,0,1.658-.319,1.91,1.91,0,0,0,.7-1.539V16.772a1.61,1.61,0,0,0-1.166-.58,1.667,1.667,0,0,0-1.187.6ZM256.51,9.351c0-1.1-.449-1.722-1.176-1.738-1.046-.024-1.294,1.451-1.294,1.451A2.754,2.754,0,0,1,254.8,6.9,3.057,3.057,0,0,1,257.9,5.883a2.6,2.6,0,0,1,2.069,2.745V23.848s.609.073,1.027.166c.485.1.944.229.944.229V5.891h.2V8.367l3.3-2.661,2.421,2.153,1.129-.976.129.153-1.18,1.016V22.2a2.741,2.741,0,0,1-1.079,2.4c-1.944,1.153-4.29-.177-6.38-.479-1.551-.23-3.986-.524-4.6.9a1.321,1.321,0,0,0,.518,1.661c1.412.944,7.7-1.584,9.855-.581a2.589,2.589,0,0,1,1.655,3.358c-.511,1.867-2.868,2.194-2.868,2.194a1.666,1.666,0,0,0,.824-1.778c-.167-.56-.547-.718-1.781-.581-2.695.306-5.916,1.612-8.08.706a3.367,3.367,0,0,1-1.828-3.229c.055-1.972,2.332-2.75,2.332-2.75V16.732c-.065-.29-1.28-.214-1.545.323-.4.811.5,1.181.5,1.181a1.626,1.626,0,0,1-1.38-.613,2.3,2.3,0,0,1-.114-2.745,3.1,3.1,0,0,1,2.539-1.225Z"
        transform="translate(-168.439 -4.126)"
      />
    </svg>
  );
};
