/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import InputFile from 'components/ui/inputs/InputFile';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function UseUpload(props) {
  const { useUpload, onClick, uploadFile, fileName, inputKey } = props;
  const classes = classNames('btn-upload-file', { selected: useUpload });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <label className={classes} htmlFor="bid_resume" onClick={onClick}>
      {useUpload && <div className="round-check-icon"></div>}
      Upload resume
      <InputFile
        id="bid_resume"
        name="bid[resume]"
        key={inputKey}
        className="required d-none"
        onChange={uploadFile}
        data-source={fileName}
      />
    </label>
  );
}

UseUpload.propTypes = {
  uploadFile: PropTypes.func,
  onClick: PropTypes.func,
  useUpload: PropTypes.bool,
  fileName: PropTypes.string,
  inputKey: PropTypes.any,
};

export default UseUpload;
