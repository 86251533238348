import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaSet } from 'use-media-set';

import { getFullHourOptions } from 'components/employer/interviews/supporting';
import Button from 'components/ui/buttons/Button';
import Flex from 'components/ui/Flex';
import PlusCircleO from 'components/ui/icons/PlusCircleO';
import Trashcan from 'components/ui/icons/Trashcan';
import Checkbox from 'components/ui/inputs/Checkbox';
import Select from 'components/ui/inputs/Select';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import { mediaSetQueries } from 'styleGuide';
import { capitalize } from 'utilities';

const DaysWrapper = styled(Flex)`
  [class^='Checkbox'],
  label {
    margin: 0 !important;
  }
`;

const TimeSelect = styled(Select)`
  padding-right: 24px;
  width: ${({ isSmallScreen }) => (isSmallScreen ? 120 : 130)}px;
`;

const TimeRangeSelects = ({
  checkboxText,
  onChange,
  rangeName,
  timeRanges,
}) => {
  // consts
  const defaultInput = { startTime: '09:00', endTime: '17:00', error: null };
  const fullHourOptions = getFullHourOptions();
  const mediaStates = useMediaSet(mediaSetQueries);
  const isSmallScreen = mediaStates.has('mobileOrSmTablet');

  // State
  // timeRangeList is array of objects representing each row of time ranges.
  const [checked, setChecked] = useState(true);
  const [timeRangeList, setTimeRangeList] = useState([]);

  // If all time ranges are removed, uncheck the checkbox.
  // If time range changes, validate and show errors if needed.
  useEffect(() => {
    if (timeRangeList.length === 0) {
      setChecked(false);
    }
    validateTimeRanges();
  }, [timeRangeList, validateTimeRanges]);

  // If timeRanges are passed in, set the timeRangeList to those ranges and check the box.
  useEffect(() => {
    if (timeRanges && timeRanges.length > 0) {
      setTimeRangeList(timeRanges);
      setChecked(true);
    }
  }, [timeRanges]);

  const onTimeRangeChange = (index, { target }) => {
    const targetName = target.name;
    const currentInputs = [...timeRangeList];
    const newInput = {
      ...currentInputs[index],
      [targetName]: target.value,
      error: null,
    };
    currentInputs[index] = newInput;
    setTimeRangeList(currentInputs);
    onChange(currentInputs);
  };

  // If they check the checkbox - we show one input with 9-5 defaults.
  // If they uncheck the checkbox - we clear all state so we show no inputs.
  const onCheckboxToggle = () => {
    const newChecked = !checked;
    const newTimeRangeList = newChecked ? [defaultInput] : [];
    setChecked(newChecked);
    setTimeRangeList(newTimeRangeList);
    onChange(newTimeRangeList);
  };

  // Validations:
  // 1. Endtime should come after start time.
  // 2. Time ranges should not overlap with the other ranges.
  const validateTimeRanges = useCallback(() => {
    let newError = false;
    const allTimeRanges = [...timeRangeList];
    const overlappingError = 'Time ranges cannot overlap.';
    const newTimeRanges = allTimeRanges.map(
      (currentTimeRange, currentRangeIndex) => {
        const { endTime, startTime } = currentTimeRange;
        const timesSelected = endTime && startTime;
        const endTimeBeforeStart = endTime <= startTime;

        if (timesSelected && endTimeBeforeStart && !currentTimeRange.error) {
          newError = true;
          currentTimeRange.error =
            'End time cannot be on or before start time.';
        }

        const overlappingEndTimes = hasOverlappingTimes(
          allTimeRanges,
          currentTimeRange,
          currentRangeIndex,
        );

        if (overlappingEndTimes && !currentTimeRange.error) {
          currentTimeRange.error = overlappingError;
          newError = true;
        } else if (
          !overlappingEndTimes &&
          currentTimeRange.error === overlappingError
        ) {
          currentTimeRange.error = null;
          newError = true;
        }

        return currentTimeRange;
      },
    );

    if (newError) {
      setTimeRangeList(newTimeRanges);
      onChange(newTimeRanges);
    }
  }, [timeRangeList, onChange]);

  const hasOverlappingTimes = (
    allTimeRanges,
    { startTime, endTime },
    currentRangeIndex,
  ) => {
    return allTimeRanges.reduce((accum, otherRange, otherRangeIndex) => {
      if (
        currentRangeIndex <= otherRangeIndex ||
        !startTime ||
        !otherRange.startTime ||
        !otherRange.endTime
      ) {
        return accum;
      }

      const startTimeLessThanEnd =
        startTime < otherRange.endTime && startTime >= otherRange.startTime;

      const rangeIncludesOtherRange =
        startTime < otherRange.startTime && endTime >= otherRange.endTime;

      const rangeInBetweenOtherRange =
        startTime < otherRange.startTime &&
        endTime > otherRange.startTime &&
        endTime < otherRange.endTime;

      if (
        startTimeLessThanEnd ||
        (endTime && (rangeIncludesOtherRange || rangeInBetweenOtherRange))
      ) {
        accum = true;
      }

      return accum;
    }, false);
  };

  const renderInput = ({ startTime, endTime, error }, index) => {
    return (
      <React.Fragment key={`${rangeName}-${index}`}>
        <Flex
          alignItems="center"
          data-testid={`${rangeName}-time-range`}
          direction="row"
          gap={isSmallScreen ? 4 : 8}
        >
          <TimeSelect
            data-testid={`${rangeName}-startTime-${index}`}
            isSmallScreen={isSmallScreen}
            key={`${rangeName}-startTime-${index}`}
            name="startTime"
            onChange={event => onTimeRangeChange(index, event)}
            small={isSmallScreen}
            value={startTime}
          >
            {fullHourOptions}
          </TimeSelect>
          <div>&mdash;</div>
          <TimeSelect
            data-testid={`${rangeName}-endTime-${index}`}
            isSmallScreen={isSmallScreen}
            key={`${rangeName}-endTime-${index}`}
            name="endTime"
            onChange={event => onTimeRangeChange(index, event)}
            small={isSmallScreen}
            value={endTime}
          >
            {fullHourOptions}
          </TimeSelect>
          <Button
            className={`p-${isSmallScreen ? 1 : 2}`}
            data-testid={`${rangeName}-add-time`}
            iconOnly
            id="add-time"
            onClick={addInput}
          >
            <PlusCircleO size={24} />
          </Button>
          <Button
            className={`p-${isSmallScreen ? 1 : 2}`}
            data-testid={`${rangeName}-delete-time`}
            iconOnly
            id="delete-time"
            onClick={e => deleteInput(e, index)}
          >
            <Trashcan size={24} />
          </Button>
        </Flex>
        {error && <ErrorMessage className="mb-4">{error}</ErrorMessage>}
      </React.Fragment>
    );
  };

  const addInput = e => {
    e.preventDefault();
    const newInputs = [
      ...timeRangeList,
      { startTime: '', endTime: '', error: null },
    ];
    setTimeRangeList(newInputs);
    onChange(newInputs);
  };

  const deleteInput = (e, index) => {
    e.preventDefault();
    const newInputs = [...timeRangeList];
    newInputs.splice(index, 1);
    setTimeRangeList(newInputs);
    onChange(newInputs);
  };

  return (
    <DaysWrapper direction="column" alignItems="stretch" gap={8}>
      {checkboxText && (
        <Checkbox
          checked={checked}
          data-testid={checkboxText}
          key={checkboxText}
          labelText={capitalize(checkboxText)}
          onChange={onCheckboxToggle}
        />
      )}
      {timeRangeList.length > 0 &&
        timeRangeList.map((input, index) => renderInput(input, index))}
    </DaysWrapper>
  );
};

TimeRangeSelects.propTypes = {
  checkboxText: PropTypes.string,
  endTimeList: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  rangeName: PropTypes.string.isRequired,
  startTimeList: PropTypes.array,
  timeRanges: PropTypes.array,
};

export default TimeRangeSelects;
