import React from 'react';
import theme from 'theme';

export const CareerPauseResume = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 60" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x2=".623"
          y1="1"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor={theme.white} stopOpacity="0" />
          <stop offset="1" stopOpacity=".902" />
        </linearGradient>
      </defs>
      <g opacity="0.4" transform="translate(-66)">
        <path
          d="M0 0h473.485C488.129 0 500 13.431 500 30s-11.871 30-26.515 30H0z"
          fill="url(#linear-gradient)"
          transform="translate(66)"
        />
        <g transform="translate(278.364 21.423)">
          <path
            d="M216.268 14.254l-6.118-7.643v13.783h1.338V10.359l4.779 6.1 4.779-6.1v10.035h1.409V6.54z"
            fill={theme.white}
            transform="translate(-117.856 -3.739)"
          />
          <path
            d="M124.023 14.254l-6.113-7.643v13.783h1.338V10.359l4.775 6.1 4.779-6.1v10.035h1.409V6.54z"
            fill={theme.white}
            transform="translate(-66.626 -3.739)"
          />
          <path
            d="M128.358 17.529h1.218V1.38h-3.583l-4.779 6.3-4.775-6.3h-3.589v16.149h1.294v-15h1.24l5.829 7.43 5.9-7.43h1.245z"
            fill={theme.white}
            transform="translate(-63.815 -.873)"
          />
          <path
            d="M220.608 17.529h1.218V1.38h-3.583l-4.779 6.3-4.779-6.3H205.1v16.149h1.289v-15h1.245l5.829 7.43 5.9-7.43h1.245z"
            fill={theme.white}
            transform="translate(-115.051 -.873)"
          />
          <path
            d="M170.023 16.511a4.993 4.993 0 1 1 .009 0m.022-11.231a6.225 6.225 0 1 0 6.224 6.225 6.225 6.225 0 0 0-6.224-6.225"
            fill={theme.white}
            transform="translate(-92.13 -3.039)"
          />
          <path
            d="M167.424 16.034a7.141 7.141 0 1 1 7.114-7.167v.053a7.14 7.14 0 0 1-7.114 7.114m0-15.464a8.314 8.314 0 1 0 8.314 8.314A8.336 8.336 0 0 0 167.424.57"
            fill={theme.white}
            transform="translate(-89.508 -.423)"
          />
          <path
            d="M10.87 4.017v13.232H7.686V4.017H2.56v-3H16v3z"
            fill={theme.white}
            transform="translate(-2.56 -.673)"
          />
          <path
            d="M49.775 17.249v-6.732H42.7v6.732h-3.16V1.02h3.16v6.523h7.074V1.02h3.179v16.229z"
            fill={theme.white}
            transform="translate(-23.099 -.673)"
          />
          <path
            d="M79.82 17.249V1.02h10.164v2.974h-7.007V7.7h6.344v2.814h-6.344v3.757h7.007v2.974z"
            fill={theme.white}
            transform="translate(-45.47 -.673)"
          />
          <path
            d="M260.748 8.4a2.267 2.267 0 0 0 2.543-2.312 2.283 2.283 0 0 0-2.543-2.334h-2.494V8.4zm-2.516 2.726v6.109h-3.162V1h6.069a4.97 4.97 0 0 1 5.335 5.06 4.967 4.967 0 0 1-5.335 5.06z"
            fill={theme.white}
            transform="translate(-142.804 -.662)"
          />
          <path
            d="M293.612 8.265a2.27 2.27 0 1 0 0-4.508h-2.583v4.508zm-.916 2.721h-1.667v6.251h-3.179V1h6.34a4.872 4.872 0 0 1 5.175 4.989 4.606 4.606 0 0 1-3.446 4.611l3.481 6.638h-3.526z"
            fill={theme.white}
            transform="translate(-161.011 -.662)"
          />
          <path
            d="M327.77 14.094a5.012 5.012 0 0 0 5.082-5.407 5.008 5.008 0 0 0-5.082-5.4c-2.521 0-5.055 1.738-5.055 5.4a5.006 5.006 0 0 0 5.055 5.407m0-13.854a8.2 8.2 0 0 1 8.381 8.474 8.346 8.346 0 1 1-16.69 0A8.18 8.18 0 0 1 327.77.24"
            fill={theme.white}
            transform="translate(-178.567 -.24)"
          />
          <path
            d="M361.722 11.226h3.045v.938a2.2 2.2 0 1 0 4.375.071V1h3.158v11.227a5.188 5.188 0 0 1-5.039 5.334h-.248a4.992 4.992 0 0 1-5.313-5.287z"
            fill={theme.white}
            transform="translate(-202.027 -.662)"
          />
          <path
            d="M395.34 17.249V1.02h10.16v2.974h-7V7.7h6.34v2.814h-6.34v3.757h7v2.974z"
            fill={theme.white}
            transform="translate(-220.711 -.673)"
          />
          <path
            d="M424.8 8.714A8.2 8.2 0 0 1 433.065.24c4.486 0 6.8 2.748 7.438 5.38l-2.974.943a4.238 4.238 0 0 0-4.446-3.277c-2.428 0-4.988 1.743-4.988 5.429a4.985 4.985 0 0 0 5.033 5.335 4.508 4.508 0 0 0 4.557-3.317l2.952.889a7.38 7.38 0 0 1-7.509 5.513 8.09 8.09 0 0 1-8.31-8.448"
            fill={theme.white}
            transform="translate(-237.073 -.24)"
          />
          <path
            d="M472.62 4.017v13.232h-3.183V4.017h-5.127v-3h13.436v3z"
            fill={theme.white}
            transform="translate(-259.017 -.673)"
          />
        </g>
        <ellipse
          cx="21.444"
          cy="21.422"
          fill={theme.white}
          rx="21.444"
          ry="21.422"
          transform="translate(514.249 8.578)"
        />
        <g transform="translate(527.973 19.707)">
          <rect
            width="5.494"
            height="20.586"
            rx="2.747"
            transform="translate(9.946)"
          />
          <rect width="5.494" height="20.586" rx="2.747" />
        </g>
      </g>
    </svg>
  );
};
