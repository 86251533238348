import ReactiveRecord, { Model } from 'reactiverecord';

const routePrefix = ':prefix/employers/invites';
class Invite extends Model {
  static routes = {
    index: routePrefix,
    create: routePrefix,
  };

  static schema = {
    email: String,
    employer_company_name: String,
    first_name: String,
    full_name: String,
    initials: String,
    last_name: String,
    _timestamps: true,
    _primaryKey: 'slug',
  };
}

export default ReactiveRecord.model('Invite', Invite);
