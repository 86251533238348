import React from 'react';
import PropTypes from 'prop-types';

const RescheduleRequestButton = props => {
  return (
    <button
      data-id="reschedule-request-btn"
      className="btn btn-link"
      onClick={props.onClick}
    >
      <i className="fa fa-calendar-o" aria-hidden="true" /> Reschedule
    </button>
  );
};

RescheduleRequestButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RescheduleRequestButton;
