import styled, { css } from 'styled-components';
import { colorsRebrand } from 'styleGuide';

export const StyledInputContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    .radio-input {
      opacity: 0;
      height: 0;
      width: 0;
      position: absolute;
    }
    .radio-input + label {
      box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      color: ${colorsRebrand.navy.dark};
      cursor: pointer;
      font-size: 1.125rem;
      white-space: nowrap;
      min-height: 8.125rem;
      @media (min-width: ${theme.screenSizes.tablet}) {
        font-size: ${theme.fontSizes.lg};
        border-radius: 3px;
      }

      .visual-radio {
        width: 25px;
        height: 25px;
        display: inline-block;
        border: 4px solid ${theme.white};
        box-shadow: 0 0 0 1px ${colorsRebrand.navy.base};
        border-radius: 50%;
      }
    }
    .radio-input:checked + label {
      box-shadow: inset 0 0 0 2px ${colorsRebrand.ocean.bold};
      font-weight: ${theme.fontWeights.fontHeavy};
      letter-spacing: -0.3px;
      .visual-radio {
        background-color: ${colorsRebrand.ocean.base};
        box-shadow: 0 0 0 1px ${colorsRebrand.ocean.base};
      }
    }
    .radio-input:focus + label {
      box-shadow: inset 0 0 0 2px ${colorsRebrand.ocean.bold};
      .visual-radio {
        background-color: ${colorsRebrand.ocean.base};
        box-shadow: 0 0 0 1px ${colorsRebrand.ocean.base};
      }
    }
  `,
);
