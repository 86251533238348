import React from 'react';

export default function X(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <line
        x1="7.5"
        y1="7.5"
        x2="12.5"
        y2="12.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="7.5"
        y1="12.5"
        x2="12.5"
        y2="7.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
