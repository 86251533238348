import styled from 'styled-components';

const toggleHeight = '40';
const toggleTime = '0.25s';

export const Wrapper = styled.label`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0; /* << This is only here to override existing global application CSS */
`;

export const Label = styled.div(
  ({ theme }) => `
  position: relative;
  background-color: ${theme.lightGray};
  border-radius: ${toggleHeight / 2}px;
  transition: background-color ${toggleTime}, box-shadow ${toggleTime};
  box-shadow: 0 0 0 3px transparent;
  &:after {
    content: "";
    position: absolute;
    top: 5px;
    width: ${toggleHeight - 10}px;
    height: ${toggleHeight - 10}px;
    border-radius: ${(toggleHeight - 10) / 2}px;
    background-color: ${theme.white};
    transition: left ${toggleTime};
  }
  .checked-label,
  .unchecked-label {
    display: inline-block;
    line-height: ${toggleHeight}px;
  }
  .checked-label {
    padding: 0 3em 0 1em;
  }
  .unchecked-label {
    padding: 0 1em 0 3em;
  }
`,
);
export const Input = styled.input(
  ({ theme }) => `
  position: absolute;
  clip: rect(0, 0, 0, 0);
  &:not(:checked) ~ ${Label} {
    &:after {
      left: 5px;
    }
    .checked-label {
      display: none;
    }
  }
  &:focus ~ ${Label} {
    box-shadow: 0 0 0 3px ${theme.lightGray.alpha(0.4)};
  }
  &:focus:checked ~ ${Label} {
    box-shadow: 0 0 0 3px ${theme.primaryBlue.alpha(0.4)};
  }
  &:checked ~ ${Label} {
    background-color: ${theme.primaryBlue};
    color: ${theme.white};
    &:after {
      left: calc(100% - ${toggleHeight - 5}px);
    }
    .unchecked-label {
      display: none;
    }
  }
`,
);

Input.defaultProps = { type: 'checkbox' };
