import React from 'react';

import Icon from '../Icon';

const Circle = props => {
  return (
    <Icon viewBox="0 0 16 16" aria-hidden="true" {...props}>
      <circle cx="8" cy="8" r="8" fill={props.color}></circle>
    </Icon>
  );
};

Circle.propTypes = { ...Icon.propTypes };
Circle.defaultProps = { ...Icon.defaultProps };

export default Circle;
