import React from 'react';
import ReactiveRecord, { Member } from 'reactiverecord';
import PropTypes from 'prop-types';

const EmployerAppointmentModel = ReactiveRecord.model('EmployerAppointment');
const idsLoaded = {};
const EmployerAppointment = ({ children, ...props }) => {
  return (
    <Member for={EmployerAppointmentModel} {...props}>
      {appointment => {
        if (/20[0-2]/.test(appointment._request.status)) {
          idsLoaded[props.find] = true;
        }
        return children(appointment, idsLoaded[props.find]);
      }}
    </Member>
  );
};

EmployerAppointment.propTypes = {
  find: PropTypes.any,
  children: PropTypes.func,
};

export default EmployerAppointment;
