import styled from 'styled-components';

import { colors, screenSize, fontSizes } from 'styleGuide';

export const ResumeDownloadContainer = styled.button`
  display: flex;
  position: relative;
  border: 0;
  background: transparent;

  @media (min-width: ${screenSize.mobileS}) {
    padding: 8px 0;
    font-size: ${fontSizes.xs};
  }
  @media (min-width: ${screenSize.tablet}) {
    padding: 0;
    background: none;
    font-size: ${fontSizes.sm};
  }
`;

export const ModalContent = styled.div`
  min-height: 750px;
  margin-bottom: 60px;
  @media (max-width: ${screenSize.mobileL}) {
    margin-bottom: 120px;
  }
`;

export const BottomSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: 'flex-end';
    padding: 20px 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${colors.white};
    border-top: 1px solid ${colors.gray.light};
    .link-section {
      width: 280px;
    }
    @media (max-width: ${screenSize.mobileL}) {
      position: fixed;
      flex-direction: column;
      text-align: center;
      .link-section {
        width: 280px;
        order: 2;
        margin: 0 auto 14px;
      }
      button {
        order 2;
      }
    }
`;
