import React from 'react';
import PropTypes from 'prop-types';
import Video from 'twilio-video';
import { Container, Row, Col } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import unsupportedBrowser from 'assets/illustrations/unsupported-browser.png';

const UnsupportedBrowserWarningIcon = styled.div`
  background: url(${unsupportedBrowser}) no-repeat center center;
  background-size: contain;
  margin: 0 auto;
  width: 16rem;
  height: 16rem;
`;

const Cont = styled(Container)`
  padding-top: 3rem;
  min-height: 75vh;
`;

const SubHeading = styled.div(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.md};
  `,
);

const Heading = styled.div(
  ({ theme }) => css`
    font-weight: bold;
    font-size: ${theme.fontSizes.md};
  `,
);

const UnsupportedBrowserWarning = ({ children }) => {
  if (!Video.isSupported) {
    return (
      <Cont>
        <Row>
          <Col className="text-center">
            <UnsupportedBrowserWarningIcon />
            <Heading className="pb-4 pt-1">
              Uh oh! It seems the browser you are using isn’t supported for this
              video interview
            </Heading>
            <SubHeading>
              Please update or try another browser if possible.
            </SubHeading>
          </Col>
        </Row>
      </Cont>
    );
  }

  return children;
};

UnsupportedBrowserWarning.propTypes = {
  children: PropTypes.any,
};

export default UnsupportedBrowserWarning;
