import React from 'react';

const DownArrow = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      id="Icon_-_Caret_down"
      width="32.516"
      height="18.027"
      data-name="Icon - Caret down"
      viewBox="0 0 32.516 18.027"
    >
      <path
        fill="currentColor"
        id="Path_2454"
        d="M125.09 156.348a1.746 1.746 0 0 1 2.469 0l13.277 13.278 13.277-13.278a1.746 1.746 0 0 1 2.469 0 1.743 1.743 0 0 1 0 2.469l-14.558 14.555a1.687 1.687 0 0 1-2.389-.008l-14.545-14.547a1.744 1.744 0 0 1 0-2.469z"
        data-name="Path 2454"
        transform="translate(-124.577 -155.837)"
      />
    </svg>
  );
};

export default DownArrow;
