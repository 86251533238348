import styled from 'styled-components';
import { containerWidth, fontSizes, screenSize } from 'styleGuide';

export const SummaryContainer = styled.div`
  max-width: ${containerWidth.default};
  margin: 0 auto 40px;
  display: grid;
  grid-template-columns: 60px auto;
  padding: 0 20px;
  align-items: center;
  grid-gap: 5px 20px;
  .profile-progress {
    grid-row: 1;
    align-self: self-end;
    padding-top: 59px;
  }
  a {
    align-self: end;
  }
  .profile-progress,
  a {
    grid-column: 1 / span 2;
  }
  .profile-progress {
    padding-top: 70px;
  }

  @media (min-width: ${screenSize.tablet}) {
    grid-template-columns: 120px auto 210px;
    padding: 0;
    .profile-progress {
      grid-column: 2 / span 1;
    }
    a {
      grid-column-start: 3;
    }
  }
`;

export const Header = styled.h2`
  margin-top: 12px;
  font-size: ${fontSizes.lg};
  line-height: 30px;
  align-self: self-start;
  grid-column-start: 2;
  grid-row: 1;
  @media (min-width: ${screenSize.tablet}) {
    margin-top: 0;
    font-size: 32px;
  }
`;

export const AvatarContainer = styled.div`
  max-width: 120px;
  margin: 0;
  align-self: self-start;
  justify-self: center;
  grid-column-start: 1;
  grid-row-start: 1;
  svg {
    opacity: 0.7;
  }
`;

export const Note = styled.div`
  grid-column-start: 2;
  @media (max-width: ${screenSize.tablet}) {
    grid-column: 1 / span 2;
  }
`;
