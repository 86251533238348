import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/ui/grid';
import { YourEmail } from 'components/employer/interviews/supporting';
import AuthorizeNylasButton from 'components/employer/interviews/RequestInterview/AuthorizeNylasButton';
import Button from 'components/ui/buttons/Button';
import { useCurrentUser } from 'requests';
import LoadingOverlay from 'components/ui/LoadingOverlay';

const NylasReauthentication = ({ handleOnClose }) => {
  const { isLoading, isFetching, data: currentUser } = useCurrentUser();

  return isLoading || isFetching ? (
    <LoadingOverlay />
  ) : (
    <>
      <div className="my-4">
        Your calendar got disconnected, sorry about that! A quick reconnect will
        do the trick so you can set up your interviews.
      </div>
      <YourEmail email={currentUser.email} />
      <hr className="my-4" />
      <Row
        className="w-100 d-flex mx-0 mt-4 flex-column flex-md-row"
        css="gap: 2rem;"
        data-id="select-type-buttons"
      >
        <Col className="order-md-3">
          <AuthorizeNylasButton
            className="w-100"
            buttonText="Connect my calendar"
            data-id="select-nylas-scheduler"
            isReschedule={true}
            user={currentUser}
            redirectTo={window.location.href}
          />
        </Col>
        {handleOnClose && (
          <Col className="order-3 order-md-1">
            <Button
              className="w-100"
              data-id="go-back"
              secondary
              onClick={handleOnClose}
            >
              Go back
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

NylasReauthentication.propTypes = {
  handleOnClose: PropTypes.func,
};

export default NylasReauthentication;
