import styled, { css } from 'styled-components';
import Button from '../../buttons/Button';
import { fontWeight, colors } from 'styleGuide';
import DatePicker from 'react-date-picker';
import 'focus-within-polyfill';
import { getInputSharedStyles } from '../InputSharedStyles';

export const Label = styled.label(
  ({ theme }) => css`
    display: block;
    .label-text {
      display: block;
      font-size: ${theme.fontSizes.sm};
      font-family: ${theme.fonts.primary};
      font-weight: ${theme.fontWeights.fontNormal};
      line-height: 1.25rem;
      color: ${theme.mediumGray};
      text-transform: uppercase;
    }

    ${({ hasButton }) =>
      hasButton &&
      `
    .input-wrapper {
      display: flex;
      align-items: flex-end;
    }
  `}
    ${Button} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      transform: translateX(-1px);
    }
  `,
);

export const StyledDatePicker = styled(DatePicker)(
  ({ theme }) => css`
    ${getInputSharedStyles()}
    padding-right: 15px;
    transition: all 0.3s;
    max-height: 50px;
    &:focus-within {
      border: 1px solid ${theme.primaryBlue};
      outline: 0;
    }

    ${({ hasError, theme }) =>
      hasError && `box-shadow: inset 0 0 0 1px ${theme.red};`}

    /* Date picker overrides  */
    .react-date-picker--disabled {
      background-color: ${theme.lightGray};
      box-shadow: inset 0 0 0 1px ${theme.lightGray};
      &::-webkit-input-placeholder {
        color: ${theme.mediumGray};
      }
      &::-moz-placeholder {
        color: ${theme.mediumGray};
      }
      &:-ms-input-placeholder {
        color: ${theme.mediumGray};
      }
    }
    .react-date-picker__wrapper {
      width: 100%;
      border: 0;
    }
    .react-date-picker__button {
      color: ${colors.blue.primary};
      &:disabled {
        color: ${theme.mediumGray};
      }
    }

    .react-date-picker__calendar {
      top: 100% !important;
      bottom: unset !important;
    }

    input {
      font-weight: ${fontWeight.fontBold};
    }
    /* End of date picker overrides */
    /* Date picker original css  */

    .react-date-picker {
      display: inline-flex;
      position: relative;
      font: ${theme.fonts.primary};
    }
    .react-date-picker,
    .react-date-picker *,
    .react-date-picker *:before,
    .react-date-picker *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .react-date-picker--disabled {
      background-color: ${theme.lightestGray};
      color: #6d6d6d;
    }
    .react-date-picker__wrapper {
      display: flex;
    }
    .react-date-picker__inputGroup {
      min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
      flex-grow: 1;
      display: flex;
      padding: 0 2px;
      align-items: baseline;
      box-sizing: content-box;
    }
    .react-date-picker__inputGroup__divider {
      padding: 1px 0;
      white-space: pre;
    }
    .react-date-picker__inputGroup__input {
      min-width: 0.54em;
      height: 100%;
      position: relative;
      padding: 0 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;
    }
    .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
    .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .react-date-picker__inputGroup__input--hasLeadingZero {
      margin-left: -0.54em;
      padding-left: calc(1px + 0.54em);
    }
    .react-date-picker__button {
      border: 0;
      background: transparent;
      padding: 4px 6px;
    }
    .react-date-picker__button:enabled {
      cursor: pointer;
    }
    .react-date-picker__button svg {
      display: inherit;
    }
    .react-date-picker__calendar {
      width: 350px;
      max-width: 100vw;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
    }
    .react-date-picker__calendar--closed {
      display: none;
    }
    .react-date-picker__calendar .react-calendar {
      border-width: thin;
    }

    .react-calendar {
      width: 350px;
      max-width: 100%;
      background: white;
      border: 1px solid ${theme.darkGray};
      font-family: ${theme.fonts.primary};
      line-height: 1.125em;
    }
    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .react-calendar button {
      margin: 0;
      border: 0;
      outline: none;
    }
    .react-calendar button:enabled:hover {
      cursor: pointer;
    }
    .react-calendar__navigation {
      height: 44px;
      margin-bottom: 1em;
    }
    .react-calendar__navigation button {
      min-width: 44px;
      background: none;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: ${colors.lightGray};
    }
    .react-calendar__navigation button[disabled] {
      background-color: ${theme.lightestGray};
    }
    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
    }
    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5em;
    }
    .react-calendar__month-view__weekNumbers {
      font-weight: bold;
    }
    .react-calendar__month-view__weekNumbers .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
    }
    .react-calendar__month-view__days__day--weekend {
      color: ${theme.red};
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${theme.mediumGray};
    }
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 2em 0.5em;
    }
    .react-calendar__tile {
      max-width: 100%;
      text-align: center;
      padding: 0.75em 0.5em;
      background: none;
    }
    .react-calendar__tile:disabled {
      background-color: ${theme.lightestGray};
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: ${theme.lightGray};
    }
    .react-calendar__tile--hasActive {
      background: ${theme.darkBlue};
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: ${theme.darkBlue};
    }
    .react-calendar__tile--active {
      background: ${theme.primaryBlue};
      color: white;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: ${theme.darkBlue};
    }
    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: ${theme.lightGray};
    }
    /* End of Date picker original css  */
  `,
);
