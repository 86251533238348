import React, { useRef, useEffect } from 'react';
import { debounce } from 'utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputCurrency from 'components/ui/inputs/InputCurrency';

export const CompensationContainer = styled.div(
  ({ theme }) => `
  label {
    width: 17rem;
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 0.5rem;
    position: relative;
    .label-text {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto !important;
      width: 5.2rem;
      height: 1.3rem;
      font-size: 1rem;
      font-weight: ${theme.fontWeights.fontMedium};
      text-transform: none;
      color: ${theme.darkGray};
      z-index: 1;
    }
    input {
      margin-bottom: 0 !important;
      padding-right: 5.5rem;
    }
    [class^='ErrorMessage'] {
      grid-column: 1 / span 2;
      margin-top: -0.5rem;
    }
  }
`,
);

export const SalaryField = ({ inputProps, ...props }) => {
  const calculatedHourlyContainer = useRef(null);
  const calculatedHourly = useRef(null);
  useEffect(() => {
    calculateHourly(Number(inputProps.value));
  }, [inputProps.value]);

  const calculateHourly = salary => {
    if (salary > 0) {
      const hourly = (salary / 2080).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      calculatedHourly.current.innerText = hourly;
      calculatedHourlyContainer.current.classList.remove('d-none');
    } else {
      calculatedHourly.current.innerText = '';
      calculatedHourlyContainer.current.classList.add('d-none');
    }
  };

  const calculateHourlyDebounce = debounce.call(e => {
    const salary = Number(e.target.value.replace(/,/g, ''));
    calculateHourly(salary);
  });

  return (
    <CompensationContainer>
      <div className="salary-alignments d-block d-md-flex align-items-center mb-4">
        <InputCurrency
          {...inputProps}
          {...props}
          labelText={inputProps.labelText}
          onChange={calculateHourlyDebounce}
          id="salary_cents"
          data-testid="minimum_salary_cents"
          name="salary_cents"
          fixedDecimalScale
          rateType={inputProps.rateType}
        />
        <div
          className="my-1 ml-2 mt-3 calculated-hourly d-none"
          ref={calculatedHourlyContainer}
        >
          Equivalent to&nbsp;
          <strong>
            <span ref={calculatedHourly}></span>
          </strong>
          &nbsp;per hour
        </div>
      </div>
    </CompensationContainer>
  );
};

SalaryField.propTypes = {
  inputProps: PropTypes.any,
  white: PropTypes.string,
};

export const HourlyField = ({ inputProps, ...props }) => {
  const calculatedSalaryContainer = useRef(null);
  const calculatedSalary = useRef(null);

  useEffect(() => {
    calculateSalary(Number(inputProps.value));
  }, [inputProps.value]);

  const calculateSalary = hourly => {
    if (hourly > 0) {
      const salary = (hourly * 2080).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: '0',
        maximumFractionDigits: '2',
      });
      calculatedSalary.current.innerText = salary;
      calculatedSalaryContainer.current.classList.remove('d-none');
    } else {
      calculatedSalary.current.innerText = '';
      calculatedSalaryContainer.current.classList.add('d-none');
    }
  };

  const calculateSalaryDebounce = debounce.call(e => {
    const hourly = Number(e.target.value.replace(/,/g, ''));
    calculateSalary(hourly);
  }, 300);

  return (
    <CompensationContainer>
      <div className="salary-alignments d-block d-md-flex align-items-center mb-4">
        <InputCurrency
          {...inputProps}
          {...props}
          labelText={inputProps.labelText}
          onChange={calculateSalaryDebounce}
          id="hourly_rate_cents"
          data-testid="minimum_hourly_rate_cents"
          name="hourly_rate_cents"
          fixedDecimalScale
          rateType={inputProps.rateType}
        />
        <div
          className="my-1 ml-2 mt-3 calculated-salary d-none"
          ref={calculatedSalaryContainer}
        >
          Equivalent to&nbsp;
          <strong>
            <span ref={calculatedSalary}></span>
          </strong>
          &nbsp;per year
        </div>
      </div>
    </CompensationContainer>
  );
};

HourlyField.propTypes = {
  inputProps: PropTypes.any,
  white: PropTypes.string,
};
