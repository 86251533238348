import { isEmpty, without } from 'utilities';

export const setFieldErrors = (errors, formikHelpers) =>
  Object.keys(errors).forEach(key =>
    formikHelpers.setFieldError(key, errors[key][0]),
  );

export const excludeUnchangedOrEmptyFields = (values, originalValues) => {
  const excludedFields = Object.keys(values).filter(
    key => isEmpty(values[key]) || values[key] === originalValues[key],
  );
  return { ...without.call(values, ...excludedFields) };
};
