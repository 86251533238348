import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  margin-bottom: 0.825rem;

  a {
    margin-bottom: 0;
  }

  g {
    color: ${({ theme }) => theme.mediumGray};
    fill: currentColor;
  }
`;
