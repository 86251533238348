import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/actions';
const memberRoute = `${collectionRoute}/:id`;
class Action extends Model {
  static routes = {
    index: collectionRoute,
    show: memberRoute,
    update: memberRoute,
  };
  static schema = {
    id: Number,
    action_id: Number,
    actionable_type: String,
    actionable_id: Number,
    is_completed: Boolean,
    data: String,
    created_at: String,
    updated_at: String,
    action: Object,
  };
}

export default ReactiveRecord.model('Action', Action);
