import ReactiveRecord, { Model } from 'reactiverecord';

class TitleSearch extends Model {
  static routes = {
    only: 'show',
    show: '/api/v1/moms/titles/search',
  };

  static schema = {
    category: String,
    has_active_projects: Boolean,
    has_representative_titles: Boolean,
    limit: String,
    name: String,
    query: String,
    titles: Array,
  };
}

export default ReactiveRecord.model('TitleSearch', TitleSearch);
