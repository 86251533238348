import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ElementByCondition from './ElementByCondition';

const Elements = ({ elements }) => {
  const radioGroup = elements.filter(
    element => element['element_type'] === 'input_radio',
  );
  const labelGroup = elements.filter(
    element =>
      element['element_type'] === 'label_before_radio' ||
      element['element_type'] === 'label_after_radio',
  );

  return (
    <>
      {elements.map((element, i) => {
        return (
          <Fragment key={`element${i}`}>
            <ElementByCondition
              element={element}
              radioGroup={radioGroup}
              labelGroup={labelGroup}
            />
          </Fragment>
        );
      })}
    </>
  );
};

Elements.propTypes = {
  elements: PropTypes.array,
};

export default Elements;
