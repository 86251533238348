import React, { useContext } from 'react';
import {
  SectionSubtitle,
  ListItemCircle,
  SideSectionContainer,
} from './styles';
import MomContext from 'components/talent/MomContext';
import { URLS } from 'consts';
import ProfileContext from './ProfileContext';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import Caret from 'components/ui/icons/Caret';

const Capabilities = () => {
  const mom = useContext(MomContext);
  const profile = useContext(ProfileContext);

  const capabilities =
    mom.capabilities &&
    mom.capabilities.map(value => (
      <ListItemCircle key={value.id}>{value.title}</ListItemCircle>
    ));

  const expertises =
    mom.expertises &&
    mom.expertises.map(({ label }) => (
      <ListItemCircle key={label}>{label}</ListItemCircle>
    ));

  const careerStoryLinkProps = {};
  if (mom.isBidSource) {
    careerStoryLinkProps.onClick = profile.editProfileAction;
  }
  return (
    <>
      <SideSectionContainer>
        <SectionSubtitle className="mb-3">Skills</SectionSubtitle>
        {capabilities && capabilities.length ? (
          <ul>
            {capabilities}
            {expertises}
          </ul>
        ) : (
          <div>
            You don&apos;t have any capabilities added.
            {mom.isBidSource
              ? ' Save this bid as a draft and '
              : ' Click here to '}
            <ButtonLink
              data-id="edit-career-story-btn"
              onClick={() => profile.editProfileAction(URLS.talentCareerStory)}
            >
              edit your career story <Caret />
            </ButtonLink>
          </div>
        )}
      </SideSectionContainer>
    </>
  );
};

export default Capabilities;
