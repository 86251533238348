import { useEffect } from 'react';

// Hide the intercom launcher chat widget while this component is present
const HideIntercomLauncher = () => {
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', { hide_default_launcher: true });
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('update', { hide_default_launcher: false });
      }
    };
  }, []);

  return null;
};

export default HideIntercomLauncher;
