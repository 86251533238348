import React from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from 'components/ui/buttons/ButtonGroup';

const InterviewSchedulingFormButtons = ({
  cancelButtonText,
  isEditing,
  handleCancel,
  handleSubmit,
}) => {
  const buttonText = isEditing ? 'Save as new' : 'Create';

  const buttons = {
    cancel: {
      id: 'cancel-scheduling-page-form',
      onClick: handleCancel,
      text: cancelButtonText || 'Cancel',
    },
    create: {
      id: 'submit-scheduling-page-form',
      onClick: () => handleSubmit(true),
      text: buttonText,
    },
    update: {
      id: 'save-existing-scheduling-page',
      onClick: () => handleSubmit(false),
      text: 'Save',
    },
  };

  return (
    <ButtonGroup
      id="interview-scheduling-form-btns"
      primary={isEditing ? buttons.update : buttons.create}
      secondary={isEditing ? buttons.create : buttons.cancel}
      tertiary={isEditing ? buttons.cancel : null}
    />
  );
};

InterviewSchedulingFormButtons.propTypes = {
  cancelButtonText: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default InterviewSchedulingFormButtons;
