import React from 'react';
import { CORPORATION_TYPES } from 'consts';
import { Field, useFormikContext } from 'formik';
import Select from 'components/ui/formik/Select';
import { optionBuilder } from 'utilities';

const FieldCorporationType = () => {
  const { errors, touched, setFieldValue } = useFormikContext();
  return (
    <Field
      id="corporation_type"
      data-testid="corporation_type"
      name="corporation_type"
      labelText="Corporation Type"
      touched={touched}
      errors={errors}
      as={Select}
      onChange={({ target: { value } }) =>
        setFieldValue('corporation_type', value)
      }
      tooltipText="This information is usually on your W9 form."
    >
      <option value="">Select</option>
      {CORPORATION_TYPES.map(type => optionBuilder([type, type]))}
    </Field>
  );
};

export default FieldCorporationType;
