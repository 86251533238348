import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ConfettiAnimation = styled.span(
  ({ getBackgroundColor, theme, ...props }) => `
  position: fixed;
  top: -10%;
  left: ${props.length}%;
  display: inline-block;
  width: ${props.w}px;
  height: ${props.h}px;
  background-color: ${getBackgroundColor(
    theme.primaryBlue,
    theme.palePink,
    theme.mediumYellow,
  )};
  opacity: ${props.o};
  transform: rotate(${props.rotation}deg);
  animation: drop-${props.index} ${props.speed1}s ${props.speed2}s ease-out;
  z-index: 1;

  @keyframes drop-${props.index} {
    100% {
      top: 98%;
      left: ${props.keyLeft}%;
    }
  }
  `,
);

const Confetti = ({ totalNumConfetti, delay }) => {
  const [visible, setVisible] = useState(true);
  const getRandomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const getRandomColor = (color1, color2, color3) => {
    const index = Math.floor(getRandomNumber(1, 4));
    switch (index) {
      case 1:
        return color1;
      case 2:
        return color2;
      case 3:
        return color3;
    }
  };

  const generateConfetti = totalNumConfetti => {
    return Array.apply(null, Array(totalNumConfetti)).map((confetti, index) => {
      const length = getRandomNumber(1, 100);
      return (
        <ConfettiAnimation
          getBackgroundColor={getRandomColor}
          h={getRandomNumber(6, 12)}
          index={index}
          key={index}
          keyLeft={length + getRandomNumber(1, 15)}
          length={length}
          o={Math.random() + 0.5}
          rotation={Math.random() * 360}
          speed1={4 + Math.random()}
          speed2={Math.random()}
          w={getRandomNumber(2, 5)}
        />
      );
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, delay);
  }, [delay]);

  return visible ? <>{generateConfetti(totalNumConfetti)}</> : null;
};

Confetti.defaultProps = {
  delay: 5000,
  totalNumConfetti: 150,
};

Confetti.propTypes = {
  delay: PropTypes.number,
  totalNumConfetti: PropTypes.number,
};

export default Confetti;
