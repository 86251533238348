import React from 'react';

const InterviewContext = React.createContext({
  upcoming: {
    data: [],
  },
  rescheduleRequests: {
    data: [],
  },
  pending: {
    data: [],
  },
  handleRemoveInterview: () => undefined,
});

export default InterviewContext;
