import React from 'react';

const CircleCheck = props => {
  return (
    <svg
      fill="none"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="34.8368"
        cy="34.7562"
        r="31.8612"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        d="m17.3475 33.3371c.6823-.6824 1.7852-.6824 2.466 0l10.9456 10.9456 19.8884-19.8884c.6807-.6807 1.7852-.6807 2.466 0 .6807.6824.6807 1.7853 0 2.466l-21.1975 21.1975c-.6382.6398-1.674.6398-2.3138 0l-12.2547-12.2547c-.6808-.6808-.6808-1.7853 0-2.466z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CircleCheck;
