import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AudioLevelIndicator from './AudioLevelIndicator';

const AudioTrack = ({ track }) => {
  const audioEl = useRef(HTMLAudioElement);

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current);
    return () => track.detach().forEach(el => el.remove());
  }, [track]);

  return <AudioLevelIndicator track={track} />;
};

AudioTrack.propTypes = {
  track: PropTypes.object.isRequired,
};

export default AudioTrack;
