import React from 'react';
import PropTypes from 'prop-types';
import DollarSign from 'components/ui/icons/DollarSign';
import { IconWrapper } from './styles';

const Compensation = ({ compensation, iconClassName, iconWidth = 15 }) => (
  <div className="font-sm my-2 d-flex">
    <IconWrapper className={iconClassName}>
      <DollarSign className="text-grey" width={iconWidth} />
    </IconWrapper>
    {compensation}
  </div>
);

Compensation.propTypes = {
  compensation: PropTypes.string,
  iconClassName: PropTypes.string,
  iconWidth: PropTypes.number,
};

export default Compensation;
