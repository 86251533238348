import React from 'react';

const Gear = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill="currentColor"
        d="M620.724,156.865h-.4a7.6,7.6,0,0,0-.42-1.008l.282-.283a1.617,1.617,0,0,0,0-2.287l-1.144-1.144a1.616,1.616,0,0,0-2.287,0l-.283.282a7.681,7.681,0,0,0-1.008-.421v-.4a1.618,1.618,0,0,0-1.617-1.617h-1.617a1.618,1.618,0,0,0-1.617,1.617v.4a7.71,7.71,0,0,0-1.008.421l-.283-.282a1.616,1.616,0,0,0-2.287,0l-1.144,1.144a1.617,1.617,0,0,0,0,2.287l.282.283a7.606,7.606,0,0,0-.42,1.008h-.4a1.618,1.618,0,0,0-1.617,1.617V160.1a1.618,1.618,0,0,0,1.617,1.617h.4a7.572,7.572,0,0,0,.42,1.008l-.282.283a1.617,1.617,0,0,0,0,2.287l1.144,1.144a1.617,1.617,0,0,0,2.287,0l.283-.283a7.6,7.6,0,0,0,1.008.421v.4a1.618,1.618,0,0,0,1.617,1.617h1.617a1.617,1.617,0,0,0,1.617-1.617v-.4a7.572,7.572,0,0,0,1.008-.421l.283.283a1.617,1.617,0,0,0,2.287,0l1.144-1.144a1.617,1.617,0,0,0,0-2.287l-.282-.283a7.563,7.563,0,0,0,.42-1.008h.4a1.617,1.617,0,0,0,1.617-1.617v-1.617A1.618,1.618,0,0,0,620.724,156.865Zm0,3.235h-1.677a6.023,6.023,0,0,1-1.187,2.866l1.186,1.186L617.9,165.3l-1.186-1.186a6.032,6.032,0,0,1-2.866,1.187v1.677h-1.617V165.3a6.033,6.033,0,0,1-2.865-1.187l-1.186,1.186-1.144-1.144,1.186-1.186a6.025,6.025,0,0,1-1.187-2.866h-1.678v-1.617h1.678a6.022,6.022,0,0,1,1.187-2.865l-1.186-1.186,1.144-1.144,1.186,1.186a6.025,6.025,0,0,1,2.865-1.187v-1.678h1.617v1.678a6.024,6.024,0,0,1,2.866,1.187l1.186-1.186,1.143,1.144-1.186,1.186a6.02,6.02,0,0,1,1.187,2.865h1.677Z"
        transform="translate(-603.741 -149.991)"
      />
      <path
        fill="currentColor"
        d="M614.285,156.491a4.044,4.044,0,1,0,4.044,4.044A4.044,4.044,0,0,0,614.285,156.491Zm0,6.47a2.426,2.426,0,1,1,2.426-2.426A2.427,2.427,0,0,1,614.285,162.961Z"
        transform="translate(-604.984 -151.234)"
      />
    </svg>
  );
};

export default Gear;
