//@ts-nocheck
import React from 'react';
import { MARKETING_LINKS, URLS, TALENT_NAV_SECTIONS } from 'consts';
import { Link, List, ListItem } from './stylesV2';
import DropdownListItem from './DropdownListItem';
import LogoutLink from './LogoutLink';
import Header from '.';
import { TalentAvatar } from 'components/shared/TalentAvatar';
import classNames from 'classnames';
import CurrentMom from 'resources/current_mom/CurrentMom';
import { useAppSettings } from 'utilities/hooks';

const TalentHeaderV2 = ({ ...props }) => {
  const route = window.location.pathname;
  const activeSection = getActiveSection(route);
  const { talent_freelancer_pro: isTalentProEnabled } = useAppSettings();
  return (
    <Header {...props}>
      <CurrentMom>
        {(currentMom, loaded) => (
          <List className="ml-auto">
            <ListItem
              className={classNames({
                'is-active': activeSection === TALENT_NAV_SECTIONS.jobs,
              })}
            >
              <Link href={URLS.jobs}>Jobs</Link>
            </ListItem>

            <DropdownListItem
              id="resources-dropdown-btn"
              className="hidden-mobile"
              label="Resources"
            >
              <Link href={MARKETING_LINKS.events} target="_blank">
                Events
              </Link>
              <Link href={URLS.careerGuidance} target="_blank">
                The Study
              </Link>
              <Link href={MARKETING_LINKS.blog} target="_blank">
                Blog
              </Link>
            </DropdownListItem>
            {isTalentProEnabled && (
              <ListItem
                className={classNames({
                  'is-active':
                    activeSection === TALENT_NAV_SECTIONS.freelancer_pro,
                })}
              >
                <Link href={URLS.freelancerProPromotionalPage}>
                  Freelancer Pro
                </Link>
              </ListItem>
            )}
            <ListItem
              className={classNames({
                'is-active': activeSection === TALENT_NAV_SECTIONS.dashboard,
              })}
            >
              <Link href={URLS.talentDashboard}>Dashboard</Link>
            </ListItem>
            {!loaded || (
              <DropdownListItem
                className="hidden-mobile dropdown-btn"
                label={
                  <TalentAvatar
                    firstName={currentMom.first_name}
                    lastName={currentMom.last_name}
                    photoUrl={currentMom.avatar_url}
                    size="xxs"
                  />
                }
              >
                <Link href={URLS.talentProfile} className="profile-link">
                  <TalentAvatar
                    firstName={currentMom.first_name}
                    lastName={currentMom.last_name}
                    photoUrl={currentMom.avatar_url}
                    size="xxxs"
                  />
                  &nbsp;Profile
                </Link>
                <Link href={URLS.talentSettings}>Settings</Link>
                <LogoutLink logoutURL={URLS.talentLogout} />
              </DropdownListItem>
            )}
            <ListItem
              className={classNames('mobile-only-item', {
                'is-active': activeSection === TALENT_NAV_SECTIONS.resources,
              })}
            >
              <Link href={MARKETING_LINKS.events} target="_blank">
                Events
              </Link>
            </ListItem>
            <ListItem
              className={classNames('mobile-only-item', {
                'is-active': activeSection === TALENT_NAV_SECTIONS.resources,
              })}
            >
              <Link href={URLS.careerGuidance} target="_blank">
                The Study
              </Link>
            </ListItem>
            <ListItem
              className={classNames('mobile-only-item', {
                'is-active': activeSection === TALENT_NAV_SECTIONS.resources,
              })}
            >
              <Link href={MARKETING_LINKS.blog} target="_blank">
                Blog
              </Link>
            </ListItem>
            <ListItem
              className={classNames('mobile-only-item', {
                'is-active': activeSection === TALENT_NAV_SECTIONS.profile,
              })}
            >
              <Link href={URLS.talentProfile}>Profile</Link>
            </ListItem>
            <ListItem
              className={classNames('mobile-only-item', {
                'is-active': activeSection === TALENT_NAV_SECTIONS.settings,
              })}
            >
              <Link href={URLS.talentSettings}>Settings</Link>
            </ListItem>
            <ListItem className="mobile-only-item">
              <LogoutLink logoutURL={URLS.talentLogout} />
            </ListItem>
          </List>
        )}
      </CurrentMom>
    </Header>
  );
};

export default TalentHeaderV2;

const getActiveSection = route => {
  let activeSection = '';
  let modifiedRoute = route;
  if (route.includes(URLS.talentProfile)) {
    modifiedRoute = URLS.talentProfile;
  }

  switch (modifiedRoute) {
    case URLS.talentDashboard:
      activeSection = TALENT_NAV_SECTIONS.dashboard;
      break;
    case URLS.jobs:
      activeSection = TALENT_NAV_SECTIONS.jobs;
      break;
    case URLS.talentProfile:
      activeSection = TALENT_NAV_SECTIONS.profile;
      break;
    case URLS.talentSettings:
      activeSection = TALENT_NAV_SECTIONS.settings;
      break;
    case URLS.freelancerProPromotionalPage:
      activeSection = TALENT_NAV_SECTIONS.freelancer_pro;
      break;
    default:
      activeSection = '';
      break;
  }
  return activeSection;
};
