import React from 'react';
import { Field, useFormikContext } from 'formik';
import { optionBuilder } from 'utilities';
import Select from 'components/ui/formik/Select';
import { STATES } from 'consts';

const FieldStateOfInc = () => {
  const { errors, touched, setFieldValue } = useFormikContext();
  return (
    <Field
      id="state_of_inc"
      data-testid="state_of_inc"
      name="state_of_inc"
      labelText="State of Incorporation"
      touched={touched}
      errors={errors}
      as={Select}
      onChange={({ target: { value } }) => setFieldValue('state_of_inc', value)}
      tooltipText="Indicates the state where your headquarters or main office is located."
    >
      <option value="">Select</option>
      {STATES.map(([state, abbreviation]) =>
        optionBuilder([state, abbreviation]),
      )}
    </Field>
  );
};

export default FieldStateOfInc;
