import EmployerHeaderV2 from './EmployerHeaderV2';
import PublicHeader from './PublicHeader';
import PropTypes from 'prop-types';
import React from 'react';
import TalentHeaderV2 from './TalentHeaderV2';

const DynamicHeader = ({ isCurrentEmployer, isCurrentMom, ...props }) => {
  if (isCurrentEmployer) {
    return <EmployerHeaderV2 className="sticky-in-column" {...props} />;
  } else if (isCurrentMom) {
    return <TalentHeaderV2 className="sticky-in-column" {...props} />;
  } else {
    return <PublicHeader className="sticky-in-column" />;
  }
};

DynamicHeader.propTypes = {
  isCurrentEmployer: PropTypes.bool,
  isCurrentMom: PropTypes.bool,
};

DynamicHeader.defaultProps = {
  isCurrentEmployer: false,
  isCurrentMom: false,
};

export default DynamicHeader;
