import React from 'react';
import Check from 'components/ui/icons/Check';
import styled from 'styled-components';
import FadeInOutMount from './FadeInOutMount';
import PropTypes from 'prop-types';

const Wrapper = styled.div(
  ({ theme }) => `
  svg {
    width: 20px;
    height: 20px;
    display: block;
    color: ${theme.green};
    border: 2px solid;
    border-radius: 50%;
  }
`,
);

const CheckConfirmation = ({ timeout, in: inProp, children, ...props }) => {
  return (
    <FadeInOutMount timeout={timeout} in={inProp}>
      <Wrapper {...props}>
        <Check />
        {children}
      </Wrapper>
    </FadeInOutMount>
  );
};

CheckConfirmation.defaultProps = {
  timeout: 400,
};

CheckConfirmation.propTypes = {
  timeout: FadeInOutMount.propTypes.timeout,
  in: FadeInOutMount.propTypes.in,
  children: PropTypes.any,
};

export default CheckConfirmation;
