import React from 'react';
import { Field, useFormikContext } from 'formik';
import Input from 'components/ui/formik/Input';

const FieldLegalCity = () => {
  const { errors, touched } = useFormikContext();
  return (
    <Field
      id="legal_city"
      data-testid="legal_city"
      name="legal_city"
      labelText="Legal Company City"
      touched={touched}
      errors={errors}
      as={Input}
    />
  );
};

export default FieldLegalCity;
