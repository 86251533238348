import React, { forwardRef, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ErrorMessage from '../labels/ErrorMessage';
import { Validate } from 'reactiverecord';
import Input from 'components/ui/inputs/Input';

export const Wrapper = styled.div(
  ({ theme }) => css`
    .selector-input {
      opacity: 0;
      height: 0;
      width: 0;
      position: absolute;
    }
    .selector-input + label {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      padding: 0.3rem 1rem;
      font-size: ${theme.fontSizes.base};
      line-height: 1.5;
      color: ${theme.darkestGray};
      border-radius: 0.3rem;
      min-height: 3.125rem;
      cursor: pointer;
      user-select: none;
      > label {
        margin-bottom: 0;
      }
    }
    .selector-input:checked + label {
      font-weight: ${theme.fontWeights.fontHeavy};
      letter-spacing: -0.2px;
      background-color: ${theme.lightBlue};
      box-shadow: inset 0 0 0 2px ${theme.primaryBlue};
      &:hover {
        box-shadow: ${theme.boxShadow100}, inset 0 0 0 2px ${theme.primaryBlue};
      }
    }
    .selector-input:not(:checked) + label {
      font-weight: ${theme.fontWeights.fontBold};
      background-color: ${theme.lightestGray.lighten(3)};
      letter-spacing: 0;
      box-shadow: inset 0 0 0 1px ${theme.lighterGray};
      &:hover {
        box-shadow: ${theme.boxShadow100}, inset 0 0 0 1px ${theme.lighterGray};
      }
      > label,
      .colon {
        display: none;
      }
    }
    .selector-input:focus + label {
      box-shadow: inset 0 0 0 2px ${theme.primaryBlue};
      &:hover {
        box-shadow: ${theme.boxShadow100}, inset 0 0 0 2px ${theme.primaryBlue};
      }
    }
    .selector-input:disabled + label {
      color: ${theme.darkGray};
    }
  `,
);

/**
 * Multiple choice selector that returns the value selected when checked
 * Can be checkbox or radio input
 */
const MultipleChoiceSelector = forwardRef(
  (
    {
      hasInput,
      inputValue,
      onInputChanged,
      inputType,
      labelTestId,
      labelText,
      placeholder,
      uncheckedValue,
      ...props
    },
    forwardedRef,
  ) => {
    const generatedId = useMemo(
      () => `multiple-choice-selector-${MultipleChoiceSelector.idCounter++}`,
      [],
    );
    const id = props.id || generatedId;
    const storeValidate = useRef(null);
    const handleRef = useCallback(
      validateRef => ref => {
        const refInterface = {
          get isValid() {
            return storeValidate.current.isValid.bind(storeValidate.current);
          },
          get checked() {
            return ref.checked;
          },
          get value() {
            if (ref.checked) {
              return typeof props.value === 'undefined' ? true : props.value;
            }
            return typeof uncheckedValue === 'undefined'
              ? false
              : uncheckedValue;
          },
        };
        validateRef(refInterface);
        if (forwardedRef) {
          forwardedRef(refInterface);
        }
      },
      [props.value, uncheckedValue, forwardedRef],
    );
    if (
      Object.prototype.hasOwnProperty.call(props, 'defaultValue') &&
      !Object.prototype.hasOwnProperty.call(props, 'defaultChecked')
    ) {
      props.defaultChecked = !!props.defaultValue;
    }

    return (
      <Validate {...props} ref={storeValidate}>
        {({ ref: validateRef, ...validateProps }, errorText, validating) => (
          <Wrapper>
            <input
              className="selector-input"
              disabled={validating}
              id={id}
              type={inputType}
              {...validateProps}
              ref={handleRef(validateRef)}
            />
            <label htmlFor={id} data-testid={labelTestId}>
              <span>{labelText}</span>
              {hasInput && (
                <>
                  <span className="mr-3 colon">:</span>
                  <Input
                    placeholder={placeholder}
                    onChange={onInputChanged}
                    value={inputValue}
                    className="px-2 py-1 bg-white rounded"
                  />
                </>
              )}
            </label>
            {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
          </Wrapper>
        )}
      </Validate>
    );
  },
);
MultipleChoiceSelector.propTypes = {
  defaultValue: PropTypes.any,
  hasInput: PropTypes.bool,
  id: PropTypes.any,
  inputType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  labelText: PropTypes.any,
  labelTestId: PropTypes.string,
  placeholder: PropTypes.string,
  onInputChanged: PropTypes.func,
  inputValue: PropTypes.string,
  /** Set to return a specific value instead of `false` when unchecked */
  uncheckedValue: PropTypes.any,
  /** Set to return a specific value instead of `true` when checked */
  value: PropTypes.any,
};
MultipleChoiceSelector.defaultProps = {
  inputType: 'checkbox',
};
MultipleChoiceSelector.idCounter = 0;
MultipleChoiceSelector.displayName = 'MultipleChoiceSelector';

export default MultipleChoiceSelector;
