import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, ErrorHeader, ErrorButton } from './styles';
import Warning from 'components/ui/icons/Warning';

const RequestInterviewError = ({
  buttonText,
  handleTryAgain,
  handleOptOut,
}) => (
  <div className="w-100">
    <ErrorMessage className="w-100">
      <ErrorHeader>
        <Warning width="26" height="22" style={{ marginRight: '.5em' }} />
        Oops, something&apos;s not right
      </ErrorHeader>
      <div>
        We&apos;re sorry, an error has occurred, please try again. If the issue
        persists
        {buttonText
          ? ' please contact support.'
          : ' proceed by selecting slots manually.'}
      </div>
    </ErrorMessage>

    <div
      className="d-flex flex-column flex-md-row justify-content-end"
      css="gap: 1rem;"
    >
      <div className="order-2 order-md-1">
        <ErrorButton
          id="select-manually-btn"
          data-testid="select-manually-btn"
          onClick={handleOptOut}
          size="md"
          secondary
        >
          {buttonText || 'Select slots manually'}
        </ErrorButton>
      </div>
      <div className="order-1 order-md-2">
        <ErrorButton
          id="try-again-btn"
          data-testid="try-again-btn"
          onClick={handleTryAgain}
          size="md"
        >
          Try again
        </ErrorButton>
      </div>
    </div>
  </div>
);

RequestInterviewError.propTypes = {
  buttonText: PropTypes.string,
  handleOptOut: PropTypes.func.isRequired,
  handleTryAgain: PropTypes.func.isRequired,
};

export default RequestInterviewError;
