import React from 'react';
import { Field, useFormikContext } from 'formik';
import Input from 'components/ui/formik/Input';

const FieldCompanyWebsite = () => {
  const { touched, errors } = useFormikContext();
  return (
    <Field
      id="business_website"
      data-testid="business_website"
      name="business_website"
      labelText="Company Website"
      touched={touched}
      errors={errors}
      as={Input}
    />
  );
};

export default FieldCompanyWebsite;
