import React from 'react';
import MultiSelect from 'components/ui/inputs/MultiSelect';
import { getObjectFromArray } from 'utilities';
import PropTypes from 'prop-types';

function OtherCapabilities({ options, ...props }) {
  return (
    <div className="row mb-5 justify-content-center">
      <div
        className="form-group col-lg-6 col-md-6 col-12"
        id="other-capabilities-container"
      >
        <MultiSelect
          id="capabilities-other"
          labelText="Other talents (Select a max of 6)"
          max={6}
          {...props}
        >
          {getObjectFromArray(options, false)}
        </MultiSelect>
      </div>
    </div>
  );
}

OtherCapabilities.propTypes = {
  selected: PropTypes.array.isRequired,
  value: PropTypes.array,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OtherCapabilities;
