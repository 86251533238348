import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/schools';
const memberRoute = `${collectionRoute}/:id`;

class School extends Model {
  static routes = {
    index: collectionRoute,
    create: collectionRoute,
    show: memberRoute,
    update: memberRoute,
    destroy: memberRoute,
  };

  static schema = {
    school_name: String,
    degree: { labelText: 'Degree/Diploma earned', type: String },
    concentration: String,
    current: { labelText: 'Currently attending', type: Boolean },
    graduation_year: { labelText: 'Graduation Year (Optional)', type: String },
  };
}

export default ReactiveRecord.model('School', School);
