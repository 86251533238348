import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/ui/buttons/Button';
import { useAppSettings } from 'utilities/hooks';

const AuthorizeNylasButton = ({
  bid,
  buttonText,
  project,
  user,
  isReauth,
  isReschedule,
  from,
  redirectTo,
  ...props
}) => {
  const { nylas_login_details } = useAppSettings();
  const fromDashboard = from === 'Dashboard';
  const fromInterviewsPage = from === 'Interviews';

  function oauthAuthorizeRequest() {
    const { client_id, redirect_uri } = JSON.parse(nylas_login_details);

    const state = JSON.stringify({
      bid_id: bid ? bid.id : null,
      project_slug: project ? project.slug : null,
      user_id: user.id,
      is_reauth: isReauth,
      is_reschedule: isReschedule,
      from_dashboard: fromDashboard,
      from_interviews_page: fromInterviewsPage,
      redirect_to: redirectTo,
    });

    const nylasParams = {
      response_type: 'code',
      scopes: 'calendar',
      login_hint: user.email,
      state: encodeURI(state),
      client_id: client_id,
      redirect_uri: redirect_uri,
    };

    const queryParams = new URLSearchParams(nylasParams);

    window.location = `https://api.nylas.com/oauth/authorize?${queryParams}`;
  }

  return (
    <Button
      {...props}
      data-id="btn-auth-nylas"
      data-testid="btn-auth-nylas"
      onClick={oauthAuthorizeRequest}
      type="button"
    >
      <span>{buttonText || 'Connect my calendar'}</span>
    </Button>
  );
};

AuthorizeNylasButton.propTypes = {
  bid: PropTypes.any,
  buttonText: PropTypes.string,
  from: PropTypes.oneOf(['Dashboard', 'Interviews']),
  isReauth: PropTypes.bool,
  isReschedule: PropTypes.bool,
  project: PropTypes.any,
  user: PropTypes.object.isRequired,
  redirectTo: PropTypes.string,
};

export default AuthorizeNylasButton;
