import ReactiveRecord, { Model } from 'reactiverecord';

class Cancellation extends Model {
  static routes = {
    create: ':prefix/cancellations',
  };
  static schema = {
    cancellation_reason_id: Number,
    id: Number,
  };
}

export default ReactiveRecord.model('Cancellation', Cancellation);
