import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Warning from '../icons/Warning';

export const StyledErrorMessage = styled.div`
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  color: ${({ theme }) => theme.red};
  font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
  line-height: 1.5;
  font-size: ${({ theme }) => theme.fontSizes.base};
  display: flex;
  svg {
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

const ErrorMessage = ({ children, ...props }) => {
  return (
    <StyledErrorMessage {...props}>
      <Warning width="26" height="22" />
      {children}
    </StyledErrorMessage>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.any,
};

export default ErrorMessage;
