import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FadeInOutMount from 'components/ui/FadeInOutMount';

const StyledInputHelper = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    .title {
      font-weight: ${theme.fontWeights.fontHeavy};
    }
    @media (min-width: ${theme.screenSizes.laptop}) {
      ${props => props.fixed && 'position: absolute'};
      ${props => (props.width ? `width: ${props.width}px;` : `width: 350px;`)}
      ${props => props.top !== undefined && `top: ${props.top}px;`}
      ${props =>
        props.bottom !== undefined ? `bottom: ${props.bottom}px;` : ''}
      ${props => (props.left !== undefined ? `left: ${props.left}px;` : '')}
      ${props => props.right !== undefined && `right: ${props.right}px;`}
    }
  `,
);

const TooltipText = styled.div`
  border-radius: 5px;
  ul li {
    margin-left: 1rem;
  }
`;

const InputHelper = props => {
  return (
    <FadeInOutMount in={props.show} timeout={300}>
      <StyledInputHelper
        width={props.width}
        right={props.right}
        left={props.left}
        top={props.top}
        bottom={props.bottom}
        {...props}
      >
        <TooltipText>
          {props.title && <div className="title p-4">{props.title}</div>}
          {props.children}
        </TooltipText>
      </StyledInputHelper>
    </FadeInOutMount>
  );
};

InputHelper.propTypes = {
  width: PropTypes.number,
  right: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  top: PropTypes.number,
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  fixed: PropTypes.bool,
  children: PropTypes.any,
};

InputHelper.defaultProps = {
  show: false,
};

export default InputHelper;
