import React from 'react';

export default function ChevronRight(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.03 15" {...props}>
      <path
        d="M1 14l6.6-6.5L1 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
}
