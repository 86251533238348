import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/buttons/Button';

const MaxInterviewsReached = ({ handleOnClose }) => (
  <div className="d-flex flex-column">
    <h1 className="px-md-1 text-center">
      Yikes, you&apos;ve sent quite a few interviews today!
    </h1>
    <p className="mt-3 mb-5">
      You have reached the maximum number of interview requests per day. We do
      this to keep you organized. If you have any questions, you can reach out
      to your Account Executive.
    </p>
    <Button data-id="max-interviews-cta" onClick={handleOnClose}>
      I understand
    </Button>
  </div>
);

MaxInterviewsReached.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
};

export default MaxInterviewsReached;
