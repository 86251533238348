import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Wrapper,
} from 'components/ui/inputs/CheckboxToggle/CheckboxToggleStyles';
import ErrorMessage from 'components/ui/labels/ErrorMessage';

const CheckboxToggle = ({
  checkedLabel,
  errors,
  touched,
  id,
  uncheckedLabel,
  labelText,
  name,
  ...props
}) => (
  <>
    <Wrapper htmlFor={id} title={labelText}>
      <Input id={id} name={name} {...props} checked={props.value} />
      <Label>
        <b className="checked-label">{checkedLabel}</b>
        <b className="unchecked-label">{uncheckedLabel}</b>
      </Label>
    </Wrapper>
    {touched && touched[name] && errors && errors[name] && (
      <ErrorMessage>{errors[name]}</ErrorMessage>
    )}
  </>
);

CheckboxToggle.propTypes = {
  name: PropTypes.string.isRequired,
  checkedLabel: PropTypes.string,
  defaultValue: PropTypes.any,
  errors: PropTypes.object,
  id: PropTypes.any.isRequired,
  labelText: PropTypes.string,
  touched: PropTypes.object,
  uncheckedLabel: PropTypes.string,
  uncheckedValue: PropTypes.any,
  value: PropTypes.any,
};

CheckboxToggle.defaultProps = {
  checkedLabel: 'Show',
  uncheckedLabel: 'Hide',
};

export default CheckboxToggle;
