/* eslint-disable no-empty-function */
import React from 'react';

const FunctionsCapabilitiesContext = React.createContext({
  onChange: () => {},
  getSuperheroFunctionsProps: props => props,
  getCapabilitiesProps: props => props,
  getOtherCapabilitiesProps: props => props,
});

export default FunctionsCapabilitiesContext;
