import ReactiveRecord, { Model } from 'reactiverecord';

class BidSearch extends Model {
  static routes = {
    only: 'show',
    show: '/api/v1/employers/projects/:project_id/bids/search',
  };
  static DEFAULT_ORDER = 'submitted_at_desc';
  static DEFAULT_RADIUS = 30;
  static orderOptions = {
    submitted_at_desc: 'Newest » Oldest',
    submitted_at: 'Oldest » Newest',
  };

  static schema = {
    aggregations: Object,
    bids: Array,
    expertises: Array,
    has_project_invitation: Boolean,
    industries: Array,
    location: String,
    order: String,
    page: Number,
    pagination: Object,
    per: Number,
    project_id: Number,
    project_title: String,
    q: String,
    radius: String,
    save_for_later: Boolean,
    status: String,
    total: Number,
  };
}

export default ReactiveRecord.model('BidSearch', BidSearch);
