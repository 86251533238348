import React, { useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { VideoContext } from './VideoProvider';
import MicOn from 'components/ui/icons/MicOn';
import MicOff from 'components/ui/icons/MicOff';

const MuteBtn = styled.button`
  width: 3rem;
  border: none;
  background: transparent;
`;

const MuteButton = () => {
  const { localAudioTrack } = useContext(VideoContext);
  const [muted, setMuted] = useState(!localAudioTrack.isEnabled);

  const muteLocalAudioTrack = useCallback(() => {
    localAudioTrack.isEnabled
      ? localAudioTrack.disable()
      : localAudioTrack.enable();
    setMuted(!muted);
  }, [localAudioTrack, muted]);

  return (
    <MuteBtn onClick={muteLocalAudioTrack} muted={muted}>
      {muted ? <MicOff size="auto" /> : <MicOn size="auto" />}
    </MuteBtn>
  );
};

export default MuteButton;
