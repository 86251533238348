import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Calendar from 'components/ui/icons/Calendar';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/FlexItem';

const config = {
  fullDate: {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
    year: 'numeric',
  },
  time: {
    timeStyle: 'short',
  },
};

const LocalDateTime = ({ dateTime, format, fromUnix, showIcon }) => {
  const [localDate, setLocalDate] = useState();

  useEffect(() => {
    // if passed a unix timestamp, convert it to milliseconds
    const value = fromUnix ? dateTime * 1000 : dateTime;
    const dateObj = new Date(value);
    const locale = 'en-us';
    if (format) {
      setLocalDate(dateObj.toLocaleString(locale, config[format]));
    } else {
      const date = dateObj.toLocaleDateString(locale, config.fullDate);
      const time = dateObj.toLocaleTimeString(locale, config.time);
      setLocalDate(`${date} at ${time}`);
    }
  }, [dateTime, format, fromUnix, setLocalDate]);

  return (
    <>
      {showIcon ? (
        <Flex alignItems="center" direction="row" gap={6} inline>
          <Calendar size={'1em'} />
          <FlexItem grow={0} shrink={0}>
            {localDate}
          </FlexItem>
        </Flex>
      ) : (
        localDate
      )}
    </>
  );
};

LocalDateTime.propTypes = {
  dateTime: PropTypes.any,
  format: PropTypes.string,
  fromUnix: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export default LocalDateTime;
