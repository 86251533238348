import React from 'react';

const User = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill="currentColor"
        d="M174.523,230.2c-2.68,0-4.852,2.535-4.852,5.661s2.172,5.661,4.852,5.661,4.852-2.535,4.852-5.661S177.2,230.2,174.523,230.2Zm0,9.7c-1.786,0-3.235-1.811-3.235-4.044s1.448-4.044,3.235-4.044,3.235,1.81,3.235,4.044S176.31,239.9,174.523,239.9Z"
        transform="translate(-165.525 -230.195)"
      />
      <path
        fill="currentColor"
        d="M176.291,241.309l-.969,1.539a5.831,5.831,0,0,1,5.6,5.838H166.165a5.831,5.831,0,0,1,5.6-5.838l-.968-1.539a7.329,7.329,0,0,0-6.251,7.278v.268a1.537,1.537,0,0,0,1.562,1.459h.374l0-.011h14.132l0,.011h.387a1.519,1.519,0,0,0,1.549-1.455v-.273A7.329,7.329,0,0,0,176.291,241.309Z"
        transform="translate(-164.544 -232.321)"
      />
    </svg>
  );
};

export default User;
