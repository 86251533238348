import ReactiveRecord, { Model } from 'reactiverecord';

class CloudSearch extends Model {
  static routes = {
    show: `/employer/clouds_search`,
  };
  static schema = {
    clouds: Array,
    total: Number,
  };
}

export default ReactiveRecord.model('CloudSearch', CloudSearch);
