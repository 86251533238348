import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'components/ui/buttons/Button';

export const SubmitCancelWrapper = styled.div(
  ({ theme }) => `
    @media (min-width: ${theme.screenSizes.tablet}) {
      display: flex;
      flex-direction: row-reverse;
    }
    ${Button} {
      @media (max-width: ${parseInt(theme.screenSizes.tablet) - 1}px) {
        display: block;
        width: 100%;
        &[type='reset'] {
          margin-top: 1em;
        }
      }
      @media (min-width: ${theme.screenSizes.tablet}) {
        &[type='reset'] {
          margin-right: 1em;
        }
      }
    }
  `,
);

const SubmitCancel = ({ fields, submitLabel, onCancel, ...props }) => {
  return (
    <SubmitCancelWrapper {...props}>
      <Button data-id="submit-save-btn" {...fields.submit}>
        {submitLabel || 'Save'}
      </Button>
      {onCancel && (
        <Button
          data-id="submit-cancel-btn"
          type="reset"
          onClick={onCancel}
          secondary
          noBorder
        >
          Cancel
        </Button>
      )}
    </SubmitCancelWrapper>
  );
};

SubmitCancel.propTypes = {
  fields: PropTypes.any.isRequired,
  submitLabel: PropTypes.any,
  onCancel: PropTypes.func,
};

export default SubmitCancel;
