import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  createRef,
  useCallback,
} from 'react';
import styled, { css } from 'styled-components';
import Button from 'components/ui/buttons/Button';
import Dialog from 'components/ui/Dialog';
import Input from 'components/ui/inputs/Input';
import ShowToggle from 'components/ui/ShowToggle';
import MagnifyingGlass from 'components/ui/icons/MagnifyingGlass';
import Close from 'components/ui/icons/Close';
import AutosuggestList from './AutosuggestList';
import { debounce, noop, stripHTMLTags } from 'utilities';
import { useMediaSet } from 'use-media-set';
import { mediaSetQueries } from 'styleGuide';
import PropTypes from 'prop-types';
import KeywordSearchMobile from './KeywordSearchMobile';

const getClearRightPosition = props => {
  if (props.showIconOnButton) {
    return 60;
  }
  return props.isMobile || !props.showButton ? 16 : 158;
};

const getTopDisplacement = props => {
  if (props.withLabelText) {
    return 20;
  }
  return 0;
};

const InputContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    .keyword-search__magnifying-icon {
      position: absolute;
      top: ${props => getTopDisplacement(props) + 14}px;
      left: 16px;
      z-index: 1;
    }
    .keyword-search__clear {
      position: absolute;
      top: ${props => getTopDisplacement(props) + 12}px;
      right: ${props => getClearRightPosition(props)}px;
      z-index: 1;
      color: ${theme.primaryBlue};
    }
    .keyword-search__input {
      padding-right: 40px;
      align-self: stretch;
    }
    .keyword-search__search-button {
      min-width: ${props => (props.showIconOnButton ? 0 : 140)}px;
    }
    input {
      padding-left: ${props => (props.showIcon ? 3 : 1)}rem;
      background: ${theme.white};
    }
    label {
      margin-bottom: 2px; /* accounts for border on focused input */
    }
  `,
);

const KeywordSearchAutosuggest = ({
  fetchAutocomplete,
  handleClear,
  handleOnBlur,
  handleSubmit,
  hasAutocomplete,
  labelText,
  placeholder,
  resultsHeader,
  showButton,
  showIcon,
  showIconOnButton,
  showSVPModal,
  showX,
  title,
  value,
}) => {
  const inputRef = useRef();
  const [listIndex, setListIndex] = useState(-1);
  const listRef = createRef();
  const [suggestions, setSuggestions] = useState(new Set());
  const [keyword, setKeyword] = useState(() => value || '');
  const mediaStates = useMediaSet(mediaSetQueries);
  const onSmallScreen = showSVPModal && mediaStates.has('mobileOrSmTablet');
  const [listFocus, setListFocus] = useState(false);
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (listFocus && suggestions.size > 0) {
      setShowList(true);
    } else {
      setShowList(false);
    }
  }, [listFocus, suggestions]);

  // Handles the scenario where the filters are cleared outside this component
  useEffect(() => {
    if (!value) {
      setKeyword('');
    }
  }, [value]);

  const handleSubmitSearch = useCallback(
    (e, term) => {
      e.preventDefault();
      handleSubmit(term);
      resetSuggestions();
    },
    [handleSubmit, resetSuggestions],
  );

  const clearKeywordSearchBar = () => {
    setKeyword('');
    resetSuggestions();
    handleClear();
  };

  const resetSuggestions = useCallback(() => {
    setSuggestions(new Set());
    setListIndex(-1);
  }, [setSuggestions, setListIndex]);

  const fetchAutocompleteSuggestions = useMemo(
    () =>
      debounce.call(term => {
        fetchAutocomplete(term).then(suggestions => {
          setListIndex(-1);
          setListFocus(true);
          setSuggestions(suggestions);
        });
      }, 100),
    [setSuggestions, fetchAutocomplete],
  );

  const handleKeywordChange = e => {
    const searchTerm = e.target.value;
    setKeyword(searchTerm);
    if (hasAutocomplete && searchTerm.length > 2) {
      fetchAutocompleteSuggestions(searchTerm);
    } else {
      resetSuggestions();
    }
  };

  const handleKeyDown = e => {
    // TODO: extract this function to support file
    if (!keyword || suggestions.size === 0) {
      return;
    }
    let newCursorPosition = listIndex;
    const { current } = listRef;
    if (e.key === 'Escape') {
      setListFocus(false);
      setListIndex(-1);
      return;
    }
    if (e.key === 'ArrowDown' && listIndex < suggestions.size) {
      e.preventDefault();
      if (listIndex === suggestions.size - 1) {
        newCursorPosition = 0;
      } else {
        newCursorPosition = listIndex + 1;
      }
    } else if (e.key === 'ArrowUp' && listIndex >= -1) {
      e.preventDefault();
      if (listIndex === 0 || listIndex === -1) {
        newCursorPosition = suggestions.size - 1;
      } else {
        newCursorPosition = listIndex - 1;
      }
    } else if (e.key === 'Enter') {
      let targetIndex = listIndex;
      if (current && current.childNodes[0].id === 'list-header') {
        targetIndex += 1; // we want to skip the index of the header
      }
      const selectedItem = current && current.childNodes[targetIndex];
      if (selectedItem) {
        selectedItem.click();
        setListIndex(-1);
        return;
      }
    }
    setListIndex(newCursorPosition);
  };

  const handleBlur = () => {
    handleSubmit(keyword);
    setTimeout(() => {
      if (
        !inputRef.current ||
        !inputRef.current.contains(document.activeElement)
      ) {
        setListFocus(false);
        setListIndex(-1);
      }
    }, 0);
    handleOnBlur();
  };

  const onSelect = useCallback(
    (e, suggestion) => {
      const displayTerm = stripHTMLTags(suggestion);
      setKeyword(displayTerm);
      handleSubmitSearch(e, displayTerm);
      setListFocus(false);
    },
    [setKeyword, handleSubmitSearch, setListFocus],
  );

  const SubmitButton = useMemo(
    () => (
      <Button
        id="keyword-search-submit"
        className="keyword-search__search-button"
        type="submit"
        onClick={e => handleSubmitSearch(e, keyword)}
      >
        {showIconOnButton ? (
          <MagnifyingGlass width={20} height={20} />
        ) : (
          'Search'
        )}
      </Button>
    ),
    [handleSubmitSearch, showIconOnButton, keyword],
  );

  // TODO simplify how the KeywordSearchMobile works, I'm sure there's a better way to organize it but I haven't found anything yet - Alexa
  return (
    <div>
      {!onSmallScreen && (
        <h2 className="font-primary-regular font-md m-0">{title}</h2>
      )}
      <ShowToggle preventDefault defaultShow={false}>
        {(show, toggle) => {
          return (
            <>
              <Dialog
                onHide={toggle}
                show={show && onSmallScreen && hasAutocomplete}
                showDialogCloser={false}
                title="Search"
                viewBox="20 20"
              >
                <KeywordSearchMobile
                  clearKeywordSearchBar={clearKeywordSearchBar}
                  handleBlur={handleBlur}
                  handleKeywordChange={handleKeywordChange}
                  handleSubmitSearch={handleSubmitSearch}
                  hasAutocomplete={hasAutocomplete}
                  InputContainer={InputContainer}
                  inputRef={inputRef}
                  labelText={labelText}
                  keyword={keyword}
                  onSelect={onSelect}
                  resultsHeader={resultsHeader}
                  setKeyword={setKeyword}
                  setListFocus={setListFocus}
                  showList={showList}
                  suggestions={suggestions}
                  toggle={toggle}
                />
              </Dialog>
              <InputContainer
                isMobile={onSmallScreen}
                onBlur={handleBlur}
                onFocus={() => setListFocus(true)}
                onKeyDown={handleKeyDown}
                ref={inputRef}
                showButton={showButton}
                showIcon={showIcon}
                showIconOnButton={showIconOnButton}
                withLabelText={!!labelText}
              >
                {showIcon && (
                  <MagnifyingGlass
                    className="keyword-search__magnifying-icon"
                    height={20}
                    width={20}
                  />
                )}
                <Input
                  button={!onSmallScreen && showButton && SubmitButton}
                  className="keyword-search__input not-heavy"
                  id="keyword-search-box"
                  labelText={labelText}
                  onChange={e => handleKeywordChange(e)}
                  onClick={toggle}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      handleSubmitSearch(e, keyword);
                    }
                  }}
                  placeholder={placeholder}
                  value={keyword}
                />
                {keyword && showX && (
                  <Button
                    className="keyword-search__clear unstyled-button"
                    id="clear-search-button"
                    inline
                    link
                    onClick={clearKeywordSearchBar}
                  >
                    <Close
                      height={16}
                      title="Clear keyword search"
                      width={16}
                    />
                  </Button>
                )}
                {keyword.length > 0 &&
                  showList &&
                  hasAutocomplete &&
                  !onSmallScreen && (
                    <AutosuggestList
                      keyword={keyword}
                      onSelect={onSelect}
                      ref={listRef}
                      resultsHeader={resultsHeader}
                      selectedIndex={listIndex}
                      suggestions={suggestions}
                    />
                  )}
              </InputContainer>
            </>
          );
        }}
      </ShowToggle>
    </div>
  );
};

KeywordSearchAutosuggest.propTypes = {
  fetchAutocomplete: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  handleOnBlur: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  hasAutocomplete: PropTypes.bool,
  labelText: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  resultsHeader: PropTypes.string,
  showButton: PropTypes.bool,
  showIcon: PropTypes.bool,
  showIconOnButton: PropTypes.bool,
  showSVPModal: PropTypes.bool,
  showX: PropTypes.bool,
  value: PropTypes.string,
};

KeywordSearchAutosuggest.defaultProps = {
  hasAutocomplete: false,
  handleOnBlur: noop,
  showButton: true,
  showIcon: true,
  showSVPModal: false,
  showX: true,
};

export default KeywordSearchAutosuggest;
