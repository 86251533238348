import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import themed from 'components/hoc/themed';
import { Avatar, AvatarContainer, InitialsContainer } from './styles';
import { activateLoader, deactivateLoader, showMessage } from 'utilities';
import PlusCircle from '../icons/PlusCircle';
import { getInitialsFirstAndLastName } from 'utilities';
import { getCsrfToken } from 'utilities/http';

const AvatarUpload = props => {
  const [avatar, setAvatar] = useState(props.avatar);
  const formRef = useRef(null);
  const onUploadFile = event => {
    if (event.target.files && event.target.files[0]) {
      activateLoader();
      let request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE) {
          if (request.status === 200) {
            let responseObj = JSON.parse(this.response);
            setAvatar(responseObj[props.mode].avatar);
          } else {
            showMessage('An error has occurred', true);
          }
          deactivateLoader();
        }
      };
      request.open('PATCH', props.updateUrl);
      request.setRequestHeader('X-CSRF-Token', getCsrfToken());
      const formData = new FormData(formRef.current);
      request.send(formData);
    }
  };
  const initials = getInitialsFirstAndLastName(props.firstName, props.lastName);
  return (
    <AvatarContainer>
      <div className="upload-overlay">
        <PlusCircle width="25" />
        <form
          id="upload-form"
          data-remote="true"
          method="patch"
          encType="multipart/form-data"
          acceptCharset="UTF-8"
          ref={formRef}
        >
          <input type="hidden" name={props.mode + '[id]'} />
          <input type="hidden" name="_method" value="patch" />
          <input
            type="file"
            name={props.mode + '[avatar]'}
            className="input-upload"
            onChange={onUploadFile}
          />
        </form>
      </div>
      {avatar.thumb.url && <Avatar src={avatar.thumb.url} alt="" />}
      {!avatar.thumb.url && (
        <InitialsContainer>
          <div className="initials">{initials}</div>
          <PlusCircle width="25" />
        </InitialsContainer>
      )}
    </AvatarContainer>
  );
};

export default themed(AvatarUpload);

AvatarUpload.propTypes = {
  updateUrl: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatar: PropTypes.object,
  mode: PropTypes.oneOf(['employer', 'mom']),
};
