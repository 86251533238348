import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TimeSlotsContainer } from './InterviewModalStyles';
import XCircle from 'components/ui/icons/XCircle';
import CollapsibleContainer from 'components/ui/CollapsibleContainer';
import {
  SelectButton,
  StyledFilterHeader,
  StyledTimeSlot,
} from './InterviewModalStyles';
import ShowToggle from 'components/ui/ShowToggle';
import { getFormattedDate } from 'utilities';
import ButtonLink from 'components/ui/buttons/ButtonLink';

const formatTime = date =>
  Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);

const InterviewTimeSlots = ({ date, dateTimes, onChange, value }) => {
  const formattedDate = useMemo(
    () => getFormattedDate(new Date(`${date}T00:00:00`)),
    [date],
  );
  const clearDate = useCallback(() => {
    const filteredList = value.filter(
      valueDate => valueDate.substring(0, 10) !== date,
    );
    onChange(filteredList);
  }, [date, onChange, value]);
  const handleRemoveTimeSlot = timeSlot => {
    onChange(value.filter(valueDate => valueDate !== timeSlot));
  };
  return (
    <ShowToggle defaultShow>
      {(show, toggle) => (
        <>
          <StyledFilterHeader
            headerText={formattedDate}
            setOpen={toggle}
            open={show}
            collapsible={true}
          />
          <CollapsibleContainer isOpen={show}>
            <TimeSlotsContainer>
              <div className="timeslots-container__times">
                <div
                  className="text-right"
                  css="grid-column-start:2;grid-column-end:3;"
                >
                  <ButtonLink
                    data-id={`clear-${date}`}
                    className="mb-2 text-underline"
                    type="button"
                    onClick={clearDate}
                  >
                    Clear All
                  </ButtonLink>
                </div>
                {dateTimes.map((availableTime, index) => (
                  <TimeSlot
                    key={`timeslot-${index}`}
                    availableTime={availableTime}
                    handleRemoveTimeSlot={handleRemoveTimeSlot}
                    date={date}
                  />
                ))}
              </div>
            </TimeSlotsContainer>
          </CollapsibleContainer>
        </>
      )}
    </ShowToggle>
  );
};

const TimeSlot = ({ availableTime, handleRemoveTimeSlot }) => {
  const dateObject = new Date(availableTime);
  return (
    <StyledTimeSlot className="timeslots-container__time-block">
      <span>
        {formatTime(dateObject)} -{' '}
        {formatTime(dateObject.setMinutes(dateObject.getMinutes() + 30))}
      </span>
      {/* // eslint-disable-next-line @the-mom-project/tmp/no-unidentified-buttons */}
      <SelectButton
        type="button"
        data-id="btn-time-slot"
        className="unstyled-button"
        onClick={() => handleRemoveTimeSlot(availableTime)}
      >
        <XCircle width={20} className="ml-2" />
      </SelectButton>
    </StyledTimeSlot>
  );
};

InterviewTimeSlots.propTypes = {
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dateTimes: PropTypes.array.isRequired,
  value: PropTypes.any,
};

TimeSlot.propTypes = {
  availableTime: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  handleRemoveTimeSlot: PropTypes.func.isRequired,
};

export default InterviewTimeSlots;
