import styled from 'styled-components';
import { colors, containerWidth, fonts, screenSize } from 'styleGuide';
import { mediaKitDesktop, mediaKitMobile } from '../../../../assets/images';
import { starsPattern1, starsPattern2 } from '../../../../assets/svg';
import { Row } from 'react-bootstrap';

export const Header = styled.div`
  background-color: ${colors.blue.light};
  position: relative;
  h1 {
    ${fonts.header.xl}
    padding: 60px 0;
    text-align: center;
    color: ${colors.blue.primary};
  }
  @media (min-width: ${screenSize.tablet}) {
    .hero-box {
      position: absolute;
      top: 310px;
      right: 10%;
    }
  }
  @media (max-width: ${screenSize.mobileL}) {
    h1 {
      padding: 30px 0;
    }
    .hero-box {
      background-color: ${colors.white};
    }
  }
`;

export const DownloadSection = styled.div`
  background-color: ${colors.white};
  position: relative;
  height: 60px;
  a {
    right: 10%;
    position: absolute;
  }
  @media (max-width: ${screenSize.mobileL}) {
    background-color: transparent;
    text-align: center;
    a {
      position: relative;
      right: unset;
    }
  }
`;

export const SubTitle = styled.h2`
  color: ${colors.blue.primary};
  ${fonts.header.lg};
  margin-bottom: 30px;
`;

export const Hero = styled.div`
  background-image: url(${mediaKitDesktop});
  background-size: cover;
  background-repeat: no-repeat;
  height: 410px;
  @media (max-width: ${screenSize.mobileL}) {
    background-image: url(${mediaKitMobile});
    height: 150px;
  }
`;

export const SparkleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  h3 {
    color: ${colors.blue.primary};
    ${fonts.header.sm};
  }
  p,
  div {
    max-width: 445px;
    margin: 16px 0;
  }
  @media (max-width: ${screenSize.mobileL}) {
    padding: 50px 20px;
    background-image: url(${starsPattern1});
    background-position: top 20px left 180px;
    background-repeat: no-repeat;
    background-size: 60px;
    h3 {
      align-self: flex-start;
    }
  }

  @media (min-width: ${screenSize.tablet}) {
    width: 635px;
    height: 260px;
    background-color: ${colors.white};
    box-shadow: -6px -6px 0px 0px ${colors.pink.pale};
    background-image: url(${starsPattern1}), url(${starsPattern2});
    background-position: bottom 20px right 20px, top 20px left 20px;
    background-repeat: no-repeat;
  }
`;

export const Description = styled(Row)`
  max-width: ${containerWidth.large};
  margin: 2rem;
  justify-content: space-between;
  .headshot-container {
    img {
      width: 100%;
      margin-left: -5px;
      position: relative;
    }
    .img-footer {
      background-color: rgba(255, 255, 255, 0.8);
      width: 100%;
      height: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  ul {
    margin: 20px;
  }
  @media (min-width: ${screenSize.tablet}) {
    margin: 70px auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media (max-width: ${screenSize.mobileL}) {
    padding: 0 20px;
  }
`;

export const Label = styled.div`
  ${fonts.label.boldBlack}
`;

export const Press = styled.section`
  background-color: ${colors.blue.light};
  min-height: 270px;
  h2 {
    width: 100%;
    margin-bottom: 80px;
    text-align: center;
    background: linear-gradient(
      to bottom,
      ${colors.white} 50%,
      ${colors.blue.light} 50%
    );
  }
  .logos-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: ${screenSize.mobileL}) {
    h2 {
      width: 100%;
      margin-bottom: 30px;
    }
    .logos-container {
      padding: 0 20px 70px;
      justify-content: space-around;
      svg {
        margin-bottom: 35px;
      }
    }
  }
`;

export const Contact = styled.section`
  background-color: ${colors.pink.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    width: 100%;
    text-align: center;
    background: linear-gradient(
      to bottom,
      ${colors.blue.light} 50%,
      ${colors.pink.light} 50%
    );
  }
  @media (max-width: ${screenSize.mobileL}) {
    .media-box {
      background: none;
      h3 {
        align-self: center;
      }
    }
  }
  @media (min-width: ${screenSize.tablet}) {
    min-height: 450px;
  }
`;
