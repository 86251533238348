import React from 'react';
import PropTypes from 'prop-types';
import { AlignRow, AlignRowItem } from 'components/ui/AlignRow';
import Alert from 'components/ui/icons/Alert';
import styled from 'styled-components';

export const AlertContainer = styled.span`
  margin-left: 0.5rem;
  color: #e7204c;
  vertical-align: text-bottom;
  svg {
    height: 1.25rem;
  }
`;

const SectionHeader = ({
  HeaderTag,
  headerClasses,
  showAlert,
  title,
  children,
}) => {
  const alertSection = (
    <AlertContainer>
      <Alert />
    </AlertContainer>
  );

  return (
    <AlignRow grow>
      <AlignRowItem grow>
        <HeaderTag className={headerClasses}>
          {title}
          {showAlert && alertSection}
        </HeaderTag>
      </AlignRowItem>
      <AlignRowItem>{children}</AlignRowItem>
    </AlignRow>
  );
};

SectionHeader.propTypes = {
  HeaderTag: PropTypes.string,
  headerClasses: PropTypes.string,
  children: PropTypes.any,
  showAlert: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

SectionHeader.defaultProps = {
  HeaderTag: 'h2',
  headerClasses: 'h2',
  showAlert: false,
};

export default SectionHeader;
