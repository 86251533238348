import React from 'react';

const VideoAlreadyJoinedError = () => {
  return (
    <>
      <div>
        <strong>
          A participant from your account has already joined this call
        </strong>
      </div>
      <br />
      <p>
        To continue, disconnect from the video call from any additional devices
        you may be using and refresh this screen
      </p>
    </>
  );
};

export default VideoAlreadyJoinedError;
