import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/buttons/Button';
import MomContext from 'components/talent/MomContext';
import { URLS } from 'consts';

const BookInterviewButton = props => {
  const { email: currentMomEmail } = useContext(MomContext);
  const launchTalentScheduler = ({
    appointmentId,
    nylasBookingUrl,
    rescheduleUrl,
  }) => {
    let schedulingUrl;
    if (!nylasBookingUrl) {
      schedulingUrl = URLS.talentSelectInterview(appointmentId);
    } else {
      schedulingUrl = rescheduleUrl
        ? URLS.nylasRescheduler(rescheduleUrl, appointmentId)
        : URLS.nylasScheduler(nylasBookingUrl, appointmentId, currentMomEmail);
    }
    window.location.href = schedulingUrl;
  };

  return (
    <Button
      data-id="dashboard-book-interview"
      data-testid={`dashboard-book-interview-${props.appointmentId}`}
      className="align-self-center"
      onClick={() => launchTalentScheduler(props)}
      secondary
    >
      Book Interview
    </Button>
  );
};

BookInterviewButton.propTypes = {
  appointmentId: PropTypes.number.isRequired,
  nylasBookingUrl: PropTypes.string,
  rescheduleUrl: PropTypes.string,
};

export default BookInterviewButton;
