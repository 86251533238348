import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ListItem as StyledListItem } from './styles';

const ExpertiseListItem = ({
  addStateExpertise,
  expertise,
  focus,
  skill,
  shouldFocus,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (focus && shouldFocus) {
      // Move element into view when it is focused
      ref.current.focus();
    }
  }, [focus, shouldFocus]);

  const handleSelect = useCallback(
    e => {
      if (e.key && e.key !== 'Enter') {
        return;
      }
      addStateExpertise(skill, expertise);
    },
    [addStateExpertise, skill, expertise],
  );

  // We want to use onMouseDown here because it fires before the onBlur event attached to the input
  return (
    <StyledListItem
      tabIndex={focus ? 0 : -1}
      ref={ref}
      onMouseDown={handleSelect}
      onKeyPress={handleSelect}
    >
      {skill.formatted}
    </StyledListItem>
  );
};

ExpertiseListItem.propTypes = {
  addStateExpertise: PropTypes.func,
  expertise: PropTypes.object,
  focus: PropTypes.bool,
  skill: PropTypes.object,
  shouldFocus: PropTypes.bool,
};

export default ExpertiseListItem;
