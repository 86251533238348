import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InAppFeedbackForm from 'components/ui/InAppFeedbackForm';
import Close from 'components/ui/icons/Close';
import { URLS } from 'consts';
import { useFeedbackForm } from 'requests';
import BidModalWrapper from './BidModalWrapper';

export const CloseButton = styled.a`
  position: absolute;
  top: -2.5rem;
  right: 0;
`;

const FeedbackFormOrJob = ({ jobTitle }) => {
  const {
    isLoading,
    isFetching,
    data: feedback,
  } = useFeedbackForm('application_assessment');
  const feedbackRequestLoaded = !isLoading && !isFetching;

  const redirectToJobs = () => {
    sessionStorage.setItem('submittedJob', jobTitle);
    window.location = URLS.talentMarketplace;
  };

  return feedbackRequestLoaded && feedback.heading ? (
    <BidModalWrapper>
      <CloseButton href={URLS.talentMarketplace} id="job-search-link">
        <Close title="Return to job search" />
      </CloseButton>
      <InAppFeedbackForm formData={feedback} dialog={false} />
    </BidModalWrapper>
  ) : (
    feedbackRequestLoaded && redirectToJobs()
  );
};

FeedbackFormOrJob.propTypes = {
  jobTitle: PropTypes.string,
};

export default FeedbackFormOrJob;
