import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styleGuide';

const StyledFilterCount = styled.small`
  color: ${props => (props.disabled ? colors.gray.medium : colors.gray.dark)};
`;

const FilterCount = ({ count }) => {
  return <StyledFilterCount disabled={!count}> ({count})</StyledFilterCount>;
};

FilterCount.propTypes = {
  count: PropTypes.number,
};

export default FilterCount;
