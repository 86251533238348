import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'components/ui/grid';
import Checkbox from 'components/ui/inputs/Checkbox';
import Button from 'components/ui/buttons/Button';
import { COMMUNITY_LINKS, getRedirectLocation } from 'consts';
import { Form } from 'reactiverecord';
import ErrorMessage from 'components/ui/labels/ErrorMessage';

const TermsOfService = ({ resource }) => {
  const [errorText, setErrorText] = useState(null);
  const handleAfterRollback = params => {
    setErrorText(params._request.body.errors);
  };
  const handleAfterSave = () => {
    const redirectLocation = getRedirectLocation();
    if (redirectLocation) {
      window.location.assign(redirectLocation);
    } else {
      window.location.assign('/');
    }
  };
  return (
    <Form
      for={resource}
      afterSave={handleAfterSave}
      afterRollback={handleAfterRollback}
    >
      {fields => (
        <>
          <Grid
            className="mb-5 mt-5"
            css={`
              max-width: 900px;
              margin: 0 auto;
            `}
          >
            <Row>
              <Col col={12}>
                <h1 className="text-center">
                  We’ve updated our Terms of Service and Privacy Policy
                </h1>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col col={12}>
                <p>
                  In an effort to continually improve your experience,
                  we&apos;ve made some updates to our Terms of Service and
                  Privacy Policy. In order to continue using our platform, we
                  ask you to carefully read and accept our Terms of Service and
                  Privacy Policy.
                </p>
              </Col>
            </Row>
            <Row>
              <Col col={12} className="mb-3">
                <a
                  href={COMMUNITY_LINKS.termsOfService}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  Terms of Service
                </a>
              </Col>
            </Row>
            <Row>
              <Col col={12} className="mb-3">
                <a
                  href={COMMUNITY_LINKS.privacyPolicy}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  Privacy Policy
                </a>
              </Col>
            </Row>
            <Row>
              <Col col={12}>
                <Checkbox
                  labelTestId="agree-to-terms-and-policy"
                  {...fields.agree_to_terms_and_policy}
                  labelText="I have read and agree to the Terms of Service and Privacy Policy."
                  validators={{
                    acceptance: [
                      {
                        message:
                          'You need to read and accept the Terms of Service and our Privacy Policy.',
                        accept: true,
                      },
                    ],
                  }}
                />
                <hr />
              </Col>
            </Row>
            <Row>
              <Col col={12} className="d-flex justify-content-end">
                <Button id="submit-button" {...fields.submit}>
                  Continue
                </Button>
              </Col>
            </Row>
            {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
          </Grid>
        </>
      )}
    </Form>
  );
};
TermsOfService.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default TermsOfService;
