import styled from 'styled-components';

export const ButtonWrapper = styled.div(
  ({ theme, disabled, selected, deactivated }) => `
  display: flex;
  padding: 1rem;
  border: 2px solid ${theme.lightGray};
  border-radius: 3rem;
  background-color: ${theme.white};
  &:hover {
    box-shadow: 0 1px 4px 0 ${theme.lightGray};
    cursor: pointer;
  }
  svg {
    color: ${theme.primaryBlue};
    border-radius: 50%;
  }
  .user_selected {
    font-weight: ${theme.fontWeights.fontBold};
  }
  ${
    selected &&
    `
  border: 2px solid ${theme.primaryBlue};
  `
  }
  ${
    deactivated &&
    `
    background-color: ${theme.lightestGray};
    color: ${theme.lightGray};
    border: 2px solid ${theme.lightGray};
  `
  }
  ${
    disabled &&
    `
    background-color: ${theme.lightestGray}
  `
  }
`,
);
