import React, { useState } from 'react';

import Header from '.';
import {
  HeaderButtonItem,
  HeaderList,
  headerStyles,
} from 'components/shared/Header/PublicHeaderStyles';
import AnalyticsProvider from 'components/shared/analytics/AnalyticsProvider';
import JoinButton from 'components/shared/JoinButton';
import LoginButton from 'components/shared/LoginButton';
import { mediaSetQueries } from 'styleGuide';

import publicTheme from 'publicTheme';
import { useMediaSet } from 'use-media-set';
import { PublicHeaderComponent2024 } from './PublicHeaderComponent2024';

const PublicHeader = props => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const mediaStates = useMediaSet(mediaSetQueries);
  const hamburgerScreenSize = mediaStates.has('mobileOrLaptopM');

  return (
    <AnalyticsProvider>
      <Header
        data-testid="public-header"
        theme={publicTheme}
        css={headerStyles}
        onBack={() => setSelectedMenu(null)}
        onClose={() => setSelectedMenu(null)}
        headerText={selectedMenu ? selectedMenu.label : ''}
        {...props}
      >
        <HeaderList className="m-0">
          <PublicHeaderComponent2024 />

          {(!selectedMenu || !hamburgerScreenSize) && (
            <>
              <HeaderButtonItem
                className={!hamburgerScreenSize ? '' : 'mt-5'}
                data-analyticsid="homepage-navbar-login-btn"
              >
                <LoginButton data-id="header-login-component-btn" />
              </HeaderButtonItem>
              <HeaderButtonItem data-analyticsid="homepage-navbar-join-btn">
                <JoinButton data-id="header-join-component-btn" />
              </HeaderButtonItem>
            </>
          )}
        </HeaderList>
      </Header>
    </AnalyticsProvider>
  );
};

export default PublicHeader;
