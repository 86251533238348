import styled from 'styled-components';
import { colors, fonts, fontSizes, fontWeight, screenSize } from 'styleGuide';
import { Row, Col } from 'react-bootstrap';
import ResumeRevBlueCurve from 'components/ui/icons/ResumeRevBlueCurve';

export const IntroRow = styled(Row)`
  margin: 0 auto;
  width: 100vw;
  height: 350px;
  &.row {
    justify-content: center;
  }

  @media (max-width: ${screenSize.laptopL}) {
    height: 325px;
  }

  @media (max-width: ${screenSize.laptop}) {
    height: 300px;
  }

  @media (max-width: ${screenSize.tablet}) {
    height: 250px;
  }

  @media (max-width: ${screenSize.mobileL}) {
    height: 170px;
  }
`;

export const Header = styled(Col)`
  text-align: center;
  width: 100%;

  div.blue-bar {
    max-width: 410px;
    background-image: linear-gradient(transparent 50%, ${colors.blue.pale} 50%);
    background-repeat: no-repeat;
    margin: 0 auto;
  }
  h1 {
    ${fonts.header.xl}
    font-size: 72px;
    margin: 64px 0 19px 0;
    color: ${colors.blue.primary};
  }
  h2 {
    max-width: 512px;
    font-family: ${fonts.primary};
    font-size: 36px;
    font-weight: ${fontWeight.fontMedium};
    line-height: 47px;
    margin: 0 auto;
  }

  @media (max-width: ${screenSize.tablet}) {
    h1 {
      margin-top: 10px;
    }
  }

  @media (max-width: ${screenSize.mobileL}) {
    h1 {
      padding: 0;
      font-size: 48px;
    }
    h2 {
      font-size: ${fontSizes.md};
      line-height: 25px;
      max-width: 275px;
    }
    div.blue-bar {
      width: 75%;
    }
  }
`;

export const StyledYellowDots = styled.img`
  height: 350px;
  position: absolute;
  right: 0;
  top: 85px;

  @media (max-width: ${screenSize.laptopL}) {
    top: 175px;
    height: 225px;
  }

  @media (max-width: ${screenSize.laptop}) {
    height: 225px;
  }

  @media (max-width: ${screenSize.tablet}) {
    height: 150px;
    right: -30px;
  }

  @media (max-width: ${screenSize.mobileL}) {
    height: 75px;
    right: -50px;
  }
`;

export const StyledResumeRevBlueCurveSVG = styled(ResumeRevBlueCurve)`
  position: absolute;
  right: 0;
  width: 100%;
  height: inherit;
  top: 100px;
  z-index: -1;

  @media (max-width: ${screenSize.laptopL}) {
    top: 155px;
  }

  @media (max-width: ${screenSize.tablet}) {
    top: 130px;
  }
`;

export const AboutResumeRev = styled(Row)`
  background-color: ${colors.blue.light};
  width: 100%;
  text-align: center;

  .col {
    max-width: 768px;
    margin: 0 auto;
  }
  p {
    margin-bottom: 30px;
  }

  @media (max-width: ${screenSize.tablet}) {
    margin: 0;

    .col {
      padding: 0 54px;
    }
  }
`;

export const SectionHeaders = styled.h3`
  ${fonts.header.lg}
  margin-bottom: 36px;
  text-align: center;
  width: 100%;

  @media (min-width: ${screenSize.mobileL}) {
    width: 60%;
    margin: 0 auto;
    font-size: 36px;
    margin-bottom: 36px;
  }
`;

export const PaleBlueBox = styled.div`
  background-color: ${colors.blue.pale};
  height: 60px;
  width: 100%;
  margin-top: -40px;
`;

export const OwnYourStorySection = styled(Row)`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 54px;
  background-color: ${colors.pink.light};
  img {
    max-width: 320px;
    padding: 20px 0;
  }
  h5 {
    color: ${colors.blue.primary};
    font-family: ${fonts.primary};
    font-size: ${fontSizes.base};
    text-align: left;
  }
  p {
    max-width: 780px;
    margin: 0 auto 36px auto;
    text-align: left;
  }
  button {
    width: 200px;
    margin: 54px auto;
  }
  .btn-row {
    padding: 50px 0;
  }
  .row {
    margin: 0 auto;
    width: 100%;
  }

  @media (max-width: ${screenSize.tablet}) {
    margin: 0 auto;
    padding-top: 30px;

    .step-copy {
      padding-left: 25px;
    }
  }

  @media (max-width: ${screenSize.mobileL}) {
    .step-icon {
      max-width: 100px;

      svg {
        width: 100%;
      }
    }
  }
`;

export const InnerRow = styled(Row)`
  max-width: 991px;

  @media (max-width: ${screenSize.mobileL}) {
    flex-direction: column-reverse;
  }
`;

export const QuestionsSection = styled.div`
  width: 100%;
  padding: 54px 0;
  margin-bottom: 54px;
  text-align: center;

  @media (max-width: ${screenSize.mobileL}) {
    p {
      padding: 0 90px;
    }
  }
`;

export const QuestionsRow = styled(Row)`
  max-width: 991px;
  margin: 0 auto;
`;

export const QuestionsCol = styled.button`
  margin: 5px 0;
  flex-wrap: wrap;
  display: flex;
  background: none;
  border: none;

  &.active {
    min-width: 991px;

    iframe {
      width: 100%;
      max-width: 991px;
      height: 583px;
      transition: max-width 0.3s ease-in-out;
    }
  }
  &.in-active {
    flex-grow: 1;
    margin: 5px;
    img {
      width: 485px;
    }
  }

  @media (max-width: ${screenSize.laptopS}) {
    margin: 0 auto;

    &.active {
      min-width: 520px;
      height: auto;
      iframe {
        margin: 0 auto;
        max-height: 400px;
        width: 650px;
      }
    }
    &.in-active {
      img {
        max-width: 520px;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: ${screenSize.mobileL}) {
    margin: 0 auto;

    &.active {
      min-width: 320px;
      height: auto;
      iframe {
        margin: 0 auto;
        max-height: 200px;
        width: 350px;
      }
    }
    &.in-active {
      img {
        max-width: 320px;
        margin: 0 auto;
      }
    }
  }
`;

export const FiveDayChallengeSection = styled(Row)`
  p {
    text-align: center;
    margin: 0 auto 50px;
    font-style: italic;
  }
`;

export const RallySection = styled(Row)`
  position: relative;
  background-color: ${colors.pink.light};
  width: 100%;
  padding: 54px 0;
  margin: 0 auto 50px auto;
  justify-content: center;

  h3 {
    text-align: left;
    margin: 0;
    width: 100%;
  }

  p {
    padding: 40px 0;
  }
  .row {
    align-items: center;
  }

  img {
    max-width: 275px;
  }

  @media (max-width: ${screenSize.tablet}) {
    padding: 54px;
  }

  @media (max-width: ${screenSize.mobileL}) {
    .row {
      margin: 0 54px;
      flex-direction: column;

      .col {
        margin: 15px 0;
      }
    }
  }
`;

export const StyledYellowRallyDots = styled.img`
  position: absolute;
  left: 0;
  top: -30px;
  height: 275px;

  @media (max-width: ${screenSize.mobileL}) {
    display: none;
  }
`;
