import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Validate } from 'reactiverecord';
import SyntheticInput from 'components/ui/inputs/SyntheticInput';

const SyntheticMultiSelectInput = forwardRef(
  ({ children, ...props }, forwardedRef) => (
    <Validate {...props} ref={forwardedRef}>
      {(validateProps, errorText, disabled) => (
        <SyntheticInput disabled={disabled} {...validateProps}>
          {(value = [], setValue) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const handleChange = useCallback(
              newValue => {
                if (newValue && newValue !== value) {
                  setValue(newValue);
                }
              },
              [setValue, value],
            );

            return children(
              { value, onChange: handleChange, disabled },
              errorText,
            );
          }}
        </SyntheticInput>
      )}
    </Validate>
  ),
);

SyntheticMultiSelectInput.displayName = 'SyntheticMultiSelectInput';

SyntheticMultiSelectInput.propTypes = {
  children: PropTypes.any,
};

export default SyntheticMultiSelectInput;
