import React from 'react';
import PropTypes from 'prop-types';

class AnimateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const props = this.props;
    return (
      <div key={props.step} className="animated fadeIn delay-1s">
        {this.props.children}
      </div>
    );
  }
}

AnimateWrapper.propTypes = {
  children: PropTypes.node,
  step: PropTypes.string,
};

export default AnimateWrapper;
