import React from 'react';
import PropTypes from 'prop-types';
import {
  validateForm,
  activateLoader,
  deactivateLoader,
} from '../../utilities';
import Questionnaire from '../shared/Questionnaire';
import AnalyticsProvider from '../shared/analytics/AnalyticsProvider';
import Modal from '../ui/Modal';
import TimezoneSelector from '../ui/inputs/TimezoneSelector';
import Button from '../ui/buttons/Button';
import themed from 'components/hoc/themed';
import http from 'utilities/http';

class RequestReschedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      selectedDates: [],
      selectedTimes: [],
      selectedTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    this.updateSelectedDates = this.updateSelectedDates.bind(this);
    this.updateSelectedTimes = this.updateSelectedTimes.bind(this);
    this.updateSelectedTimezone = this.updateSelectedTimezone.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleModal = e => {
    e.preventDefault();
    this.setState(state => ({
      openModal: !state.openModal,
    }));
  };

  updateSelectedDates(fieldName, date) {
    this.setState({
      selectedDates: this.selectedDatesArray(date),
    });
  }

  updateSelectedTimes(fieldName, time) {
    this.setState({
      selectedTimes: this.selectedTimesArray(time),
    });
  }

  updateSelectedTimezone(id, value) {
    this.setState({ selectedTimezone: value });
  }

  selectedDatesArray(date) {
    let prevDatesArray = this.state.selectedDates;
    if (prevDatesArray.includes(date)) {
      prevDatesArray.splice(prevDatesArray.indexOf(date), 1);
    } else {
      prevDatesArray.push(date);
    }
    return prevDatesArray;
  }

  selectedTimesArray(time) {
    let prevTimesArray = this.state.selectedTimes;
    if (prevTimesArray.includes(time)) {
      prevTimesArray.splice(prevTimesArray.indexOf(time), 1);
    } else {
      prevTimesArray.push(time);
    }
    return prevTimesArray;
  }

  onSubmit(e) {
    e.preventDefault();
    activateLoader();

    if (!validateForm()) {
      deactivateLoader();
      return false;
    }
    const body = JSON.stringify({
      appointment: {
        preferred_dates: this.state.selectedDates.toString(),
        preferred_times: this.state.selectedTimes.toString(),
        time_zone: this.state.selectedTimezone,
      },
    });

    http
      .patch(`/mom/appointment/${this.props.appointment_id}/dates/reschedule`, {
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        referrer: 'no-referrer',
        body: body,
      })
      .then(response => {
        return response.json();
      })
      .then(function (responseJson) {
        deactivateLoader();
        if (responseJson.status === 'success') {
          window.location.assign('/mom');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  content() {
    return (
      <div className="request-reschedule">
        <form className="request-reschedule-form" onSubmit={this.onSubmit}>
          <h2 className="mb-3">{`Select one or more days you're available in the coming week.`}</h2>
          <div className="selectable-button-container validate select-one">
            {this.renderDateButtons()}
          </div>
          <h2 className="mb-3">{`Select one or more times of day you're available.`}</h2>
          <div className="selectable-button-container">
            {this.renderTimeButtons()}
          </div>
          <div className="timezone-container">
            <h2 className="mb-3">Confirm your timezone.</h2>
            <TimezoneSelector
              selectedTimezone={this.state.selectedTimezone}
              onChange={this.updateSelectedTimezone}
              required={true}
            />
          </div>
          <p>We will notify the employer with your request to reschedule.</p>
          <p>
            Your preferences will be taken into consideration but we cannot
            guarantee employer availability at these times.
          </p>
          <div className="submit-button text-center">
            <Button
              data-id="send-reschedule-request-btn"
              type="submit"
              name="commit"
            >
              Send reschedule request
            </Button>
          </div>
        </form>
      </div>
    );
  }

  renderDateButtons() {
    let dates = {};

    this.props.potential_dates.forEach(element => {
      dates[element[0]] = element[1];
    });
    return (
      <Questionnaire
        label="Select one or more days"
        options={dates}
        cols={3}
        selected={this.state.selectedDates}
        onChange={this.updateSelectedDates}
        helpText="Select 1 for now"
        validate={true}
      />
    );
  }

  renderTimeButtons() {
    let times = {};
    this.props.potential_times.forEach(element => {
      times[element[0]] = element[1];
    });
    return (
      <Questionnaire
        label="Select one or more times"
        options={times}
        cols={3}
        selected={this.state.selectedTimes}
        onChange={this.updateSelectedTimes}
        helpText="Select 1 for now"
        validate={true}
      />
    );
  }

  render() {
    const { props, state } = this;
    return (
      <AnalyticsProvider disableTracking>
        <Button
          id={`reschuedule=${props.appointment_id}`}
          secondary
          onClick={this.toggleModal}
        >
          {props.linkText}
        </Button>
        <Modal
          title="Request Different Time"
          size="lg"
          onHide={this.toggleModal}
          show={state.openModal}
        >
          {this.content()}
        </Modal>
      </AnalyticsProvider>
    );
  }
}

RequestReschedule.propTypes = {
  appointment_id: PropTypes.number,
  potential_dates: PropTypes.array,
  potential_times: PropTypes.array,
  linkText: PropTypes.string,
};

RequestReschedule.defaultProps = {
  linkText: 'Request Different Time',
};

export default themed(RequestReschedule);
