import React from 'react';

const HeartOutlined = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 19"
    fill="none"
  >
    <path
      className="outline"
      fillRule="nonzero"
      clipRule="nonzero"
      d="M11.926 3.175c2.978-2.501 5.76-2.693 7.877-2.01 3.085.996 4.553 3.82 3.893 6.499C22.15 13.926 12.116 17.927 11.93 18v.004l-.004-.002a.03.03 0 0 0-.005.002V18c-.186-.073-10.22-4.074-11.765-10.336C-.506 4.986.963 2.16 4.05 1.165c2.116-.683 4.898-.491 7.877 2.01Zm.003 12.837c.146-.048 7.655-3.157 9.437-7.487.695-1.69 1.096-4.164-1.683-5.475-3.094-1.46-5.676.776-7.548 2.398-.07.062-.14.122-.209.181l-.212-.183C9.84 3.825 7.26 1.59 4.167 3.05 1.39 4.36 1.79 6.836 2.485 8.525c1.782 4.33 9.29 7.439 9.436 7.487v.002h.008v-.002Z"
      fill="currentColor"
    />
  </svg>
);

export default HeartOutlined;
