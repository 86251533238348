import React from 'react';

import Icon from '../Icon';

const LocationPin = props => {
  return (
    <Icon viewBox="0 0 16 20.6" {...props}>
      <path
        d="m8 0c4.4 0 8 3.6 8 8.1v.3c0 3-4.8 9.1-6.9 11.7-.6.7-1.6.7-2.1 0-2.2-2.6-7-8.7-7-11.7v-.3c0-4.5 3.6-8.1 8-8.1zm0 1.8c-3.4 0-6.2 2.9-6.2 6.5 0 3.5 6 10.2 6.2 10.2s6.2-6.8 6.2-10.3-2.8-6.4-6.2-6.4zm0 2.5c2 0 3.5 1.6 3.5 3.5s-1.5 3.5-3.5 3.5-3.5-1.5-3.5-3.5 1.5-3.5 3.5-3.5zm0 .9c-1.5 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6c1.6 0 2.7-1.2 2.7-2.6s-1.1-2.6-2.7-2.6z"
        fill={props.color}
      />
    </Icon>
  );
};

LocationPin.propTypes = { ...Icon.propTypes };
LocationPin.defaultProps = { ...Icon.defaultProps };

export default LocationPin;
