import React from 'react';
import { Grid, Row, Col } from 'components/ui/grid';
import PropTypes from 'prop-types';
import SubmitCancel from './SubmitCancel';

function FormWrapper({ fields, onCancel, children, submitLabel, ...props }) {
  return (
    <Grid fluid {...props}>
      {children}
      <Row>
        <Col className="mt-4 pb-1" sm={12}>
          <SubmitCancel
            fields={fields}
            submitLabel={submitLabel}
            onCancel={onCancel}
          />
        </Col>
      </Row>
    </Grid>
  );
}

FormWrapper.propTypes = {
  fields: PropTypes.object.isRequired,
  submitLabel: PropTypes.string,
  onCancel: PropTypes.func,
  children: PropTypes.any,
};

export default FormWrapper;
