import React from 'react';
import Icon from 'components/ui/Icon';
import publicTheme from 'publicTheme';

export default function MicOn(props) {
  return (
    <Icon viewBox="0 0 46 46" {...props}>
      <defs>
        <filter id="ywu3f2hqaa">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-601 -559) translate(601 559)">
            <circle
              cx="23"
              cy="23"
              r="23"
              fill={publicTheme.primaryBlue.toString()}
            />
            <g filter="url(#ywu3f2hqaa)">
              <g fill={publicTheme.primaryBlue.toString()} fillRule="nonzero">
                <g>
                  <path
                    d="M.035 6.247c0 2.937 2.345 5.679 5.235 6.097l.05-.347-.336-.102-.011.048c-.056.365.128.426.686.487.303.033.76.058 1.397.072.828-.017 1.642-.213 2.424-.529.285-.115.546-.239.778-.363.141-.075.243-.135.3-.171l-.379-.592c-.042.027-.128.078-.253.144-.21.113-.45.226-.71.332-.708.286-1.441.463-2.159.478-.604-.014-1.042-.038-1.322-.069-.143-.015-.227-.03-.257-.04.111.037.065-.002.19.358-.006.03.108-.342.108-.342l-.405-.059C2.828 11.281.738 8.837.738 6.247V0H.035v6.247z"
                    transform="translate(15.682 18.818)"
                  />
                  <path
                    d="M3.035 6.247c0 2.937 2.345 5.679 5.235 6.097l.05-.347-.336-.102-.011.048c-.056.365.128.426.686.487.303.033.76.058 1.397.072.828-.017 1.642-.213 2.424-.529.285-.115.546-.239.778-.363.141-.075.243-.135.3-.171l-.379-.592c-.042.027-.128.078-.253.144-.21.113-.45.226-.71.332-.708.286-1.441.463-2.159.478-.604-.014-1.042-.038-1.322-.069-.143-.015-.227-.03-.257-.04.111.037.065-.002.19.358-.006.03.108-.342.108-.342l-.405-.059c-2.543-.368-4.633-2.812-4.633-5.402V0h-.703v6.247z"
                    transform="translate(15.682 18.818) matrix(-1 0 0 1 16.593 0)"
                  />
                </g>
                <path
                  d="M2.671 16.295h-.175l.175.176v-.351l-.175.175H6.713v-3.661l-.176.175h.352l-.176-.175v3.661h4.569l-.176-.175v.35l.176-.175H6.713V16.494h.176v-.175h-.352v.175h.176V16.295H2.67zm3.866.176h-.176V16.67H7.064V16.47H6.89v.175h4.568v-.702H6.89l.175.176V12.458H6.361v3.661l.176-.175H2.32v.702H6.537v-.175z"
                  transform="translate(15.682 18.818)"
                />
              </g>
            </g>
            <rect width="9" height="17" x="18" y="11" fill="#FFF" rx="4" />
          </g>
        </g>
      </g>
    </Icon>
  );
}

MicOn.propTypes = { ...Icon.propTypes };
