import styled from 'styled-components';
import { screenSize, colors, fontSizes, fontWeight } from 'styleGuide';
import { Row } from 'components/ui/grid';

export const StyledFooter = styled.footer`
  position: relative;
  color: ${colors.gray.dark};
  padding: 40px 20px 40px 20px;
  @media (max-width: ${screenSize.mobileL}) {
    font-size: ${fontSizes.sm};
    padding: 0;
  }
`;

export const LinkLogo = styled.a`
  color: ${colors.blue.primary};
  @media (max-width: 576px) {
    width: 70%;
  }
`;

export const FooterLink = styled.a`
  ${'' /* !important is used here to override the home2022 font-size styles */}
  color: ${colors.gray.dark};
  font-size: ${fontSizes.sm} !important;
  line-height: 30px;
  &[role='button']:hover {
    color: ${colors.blue.secondary};
    cursor: pointer;
  }
  @media (min-width: ${screenSize.mobileL}) {
    font-size: ${fontSizes.base} !important;
  }
`;

export const FooterTitle = styled.span`
  font-weight: ${fontWeight.fontHeavy};
  text-transform: initial;
`;

export const SubFooter = styled.div`
  background-color: ${colors.white};
  padding-bottom: 40px;
  color: ${colors.blue.primary};
  font-size: ${fontSizes.sm};
  a {
    padding: 0 6px;
  }
`;

export const StyledHR = styled.hr`
  margin-right: 2rem;
  margin-left: 2rem;
  border-width: 2px;
  color: #dadbdd;
  @media (min-width: ${screenSize.tablet}) {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  @media (min-width: ${screenSize.laptopL}) {
    margin-right: 8%;
    margin-left: 8%;
  }
`;

export const RowFooterWrapper = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  padding-right: 75px;
  margin-left: 0px;
  @media (min-width: ${screenSize.laptopL}) {
    padding-left: 75px;
    margin-left: -15px;
    flex-wrap: nowrap;
  }
`;
