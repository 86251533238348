import React from 'react';
import styled, { css } from 'styled-components';
import MuteButton from './MuteButton';
import DisconnectButton from './DisconnectButton';
import ToggleVideoButton from './ToggleVideoButton';
import PropTypes from 'prop-types';

const ControlPanel = styled.div(
  ({ theme }) => css`
    display: flex;
    position: absolute;
    bottom: ${props => (props.preview ? '0.5' : '0')}rem;
    width: 100%;
    justify-content: center;
    height: 4rem;
    right: 0;
    left: 0;
    padding: inherit;
    background: ${props => (!props.preview ? theme.darkGray : '')};
    button:not(:first-child) {
      margin-left: 1.5rem;
    }
  `,
);

const VideoControls = ({ preview }) => {
  return (
    <ControlPanel preview={preview}>
      <MuteButton id="mute-btn" />
      {!preview ? <DisconnectButton id="disconnect-btn" /> : null}
      <ToggleVideoButton id="toggle-video-btn" />
    </ControlPanel>
  );
};

VideoControls.propTypes = {
  preview: PropTypes.bool,
};

export default VideoControls;
