import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import SuccessMessage from 'components/ui/labels/SuccessMessage';
import { Validate } from 'reactiverecord';
import { StyledInput, Label } from './styles';

const Input = forwardRef(
  (
    { successText, labelText, helpText, button, icon, white, ...props },
    forwardedRef,
  ) => {
    const generatedId = useMemo(() => `input-${Input.idCounter++}`, []);
    const id = props.id || generatedId;
    const inputTypeNotHidden = props.type ? props.type !== 'hidden' : true;
    return (
      <Validate {...props} ref={forwardedRef}>
        {(inputProps, errorText, validating) => {
          return (
            <Label
              htmlFor={id}
              hasButton={!!button}
              isDisabled={!!inputProps.disabled || validating}
              className={inputTypeNotHidden ? '' : 'mb-0'}
            >
              {inputTypeNotHidden && (
                <div className="d-flex flex-row" css="gap: 0.5rem;">
                  {labelText ? (
                    <span className="label-text">{labelText}</span>
                  ) : null}
                  {helpText ? (
                    <small className="text-alt">{helpText}</small>
                  ) : null}
                </div>
              )}
              <div className="input-wrapper">
                {icon ? <i className="inline-icon">{icon}</i> : null}
                <StyledInput
                  id={id}
                  disabled={validating}
                  {...inputProps}
                  hasError={!!errorText}
                  hasSuccess={!!successText}
                  hasIcon={!!icon}
                  hasButton={!!button}
                  white={white}
                />
                {button ? button : null}
              </div>
              {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
              {successText ? (
                <SuccessMessage>{successText}</SuccessMessage>
              ) : null}
            </Label>
          );
        }}
      </Validate>
    );
  },
);
Input.propTypes = {
  labelText: PropTypes.string,
  helpText: PropTypes.string,
  successText: PropTypes.any,
  id: PropTypes.any,
  /** For best results: 20x20 SVG icon */
  icon: PropTypes.any,
  /** White input for rendering on non-white background */
  white: PropTypes.bool,
  /** Renders adjacent to input */
  button: PropTypes.any,
  type: PropTypes.string,
};
Input.defaultProps = {
  autoComplete: 'off',
};
Input.idCounter = 0;
Input.displayName = 'Input';

export default Input;
