import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterHeader from 'components/ui/FilterHeader';
import styled from 'styled-components';

const StyledIcon = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 14px;
  cursor: pointer;
`;

const KeywordSearch = ({
  onChange,
  value,
  headerText,
  name,
  icon,
  placeholder,
}) => {
  const [keyword, setKeyword] = React.useState('');

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onChange(e);
    }
  };

  const handleBlur = e => {
    if ((value || '') !== keyword) {
      onChange(e);
    }
  };

  useEffect(() => {
    setKeyword(value || '');
  }, [value]);

  return (
    <>
      {headerText && <FilterHeader headerText={headerText} dense />}
      <div className="position-relative pt-2">
        <input
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          value={keyword}
          onChange={e => {
            setKeyword(e.target.value);
          }}
          className="form-control form-control-alt"
          aria-label={`${name} search`}
          name={name}
          placeholder={placeholder}
        />
        {icon && <StyledIcon>{icon}</StyledIcon>}
      </div>
    </>
  );
};

KeywordSearch.propTypes = {
  name: PropTypes.string.isRequired,
  headerText: PropTypes.string,
  icon: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

KeywordSearch.displayName = 'KeywordSearch';

export default KeywordSearch;
