import React from 'react';
import ReactiveRecord, { Form } from 'reactiverecord';
import PropTypes from 'prop-types';
import AnalyticsProvider from 'components/shared/analytics/AnalyticsProvider';
import Modal from 'components/ui/Modal';
import FormWrapper from 'components/ui/FormWrapper';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import themed from 'components/hoc/themed';
import Pencil from 'components/ui/icons/Pencil';
import ShowToggle from 'components/ui/ShowToggle';
import Input from 'components/ui/inputs/Input';

const ResumeLatest = ReactiveRecord.model('ResumeLatest');

const RenameResume = props => {
  return (
    <AnalyticsProvider disableTracking>
      <ShowToggle>
        {(show, toggle) => (
          <>
            <ButtonLink
              id="rename-resume"
              className="font-heavy"
              onClick={toggle}
            >
              <Pencil width={20} className="mr-2" /> {props.linkText}
            </ButtonLink>
            <Modal
              titleAlignment="text-left"
              title="What would you like to name this resume?"
              size="md"
              onHide={toggle}
              show={show}
            >
              <Form for={props.resume} afterSave={toggle}>
                {fields => (
                  <FormWrapper fields={fields} onCancel={toggle}>
                    <div className="request-withdraw">
                      <div className="mb-4 px-2">
                        <Input
                          {...fields.display_name}
                          defaultValue={props.resume.safe_display_name}
                        />
                      </div>
                    </div>
                  </FormWrapper>
                )}
              </Form>
            </Modal>
          </>
        )}
      </ShowToggle>
    </AnalyticsProvider>
  );
};

RenameResume.propTypes = {
  resume: PropTypes.instanceOf(ResumeLatest).isRequired,
  linkText: PropTypes.string,
};
RenameResume.defaultProps = {
  linkText: 'Rename Resume',
};

export default themed(RenameResume);
