import React from 'react';
import PropTypes from 'prop-types';
import Interviewee from './Interviewee';
const PastInterviews = props => {
  const interviewees = props.pastInverviews.map(item => {
    return <Interviewee key={item.id} data={item} isPast={true} />;
  });

  return (
    <>
      <div id="interviews" className="page-section-row not-striped-rows">
        <div className="sop-container">
          <h2>Past Interviews</h2>
          <div>{interviewees}</div>
        </div>
      </div>
    </>
  );
};

PastInterviews.propTypes = {
  pastInverviews: PropTypes.array.isRequired,
};

export default PastInterviews;
