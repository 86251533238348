import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import themed from '../hoc/themed';
import { colors } from 'styleGuide';

const getColor = color => {
  if (color === 'primary') {
    return colors.blue.primary;
  } else if (color === 'pink') {
    return colors.gray.dark;
  }
  return colors.white;
};

const getBackgroundColor = variant => {
  if (variant === 'outlined') {
    return 'transparent';
  }
  if (variant === 'pink') {
    return colors.pink.medium;
  }
  return colors.green.medium;
};

const getBorder = (variant, color) => {
  if (variant === 'outlined') {
    return `1px solid ${getColor(color)}`;
  }
  return `1px solid ${getBackgroundColor(variant)}`;
};

const Wrapper = styled.span(
  ({ theme, color, variant }) => `
  display: inline-block;
  border-radius: 25px;
  padding: 1px 10px;
  font-weight: ${theme.fontWeights.fontMedium};
  font-size: ${theme.fontSizes.xs};
  color: ${getColor(color)};
  border: ${getBorder(variant, color)};
  background-color: ${getBackgroundColor(variant)};
  line-height: 20px;
`,
);

const Badge = ({ children, className, variant, color }) => {
  return (
    <Wrapper color={color} variant={variant} className={className}>
      <span>{children}</span>
    </Wrapper>
  );
};

Badge.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
};

export default themed(Badge);
