import React from 'react';
import PropTypes from 'prop-types';
import LocalDateTime from 'components/ui/LocalDateTime';

const VideoAppointmentConfirmation = props => {
  const { appointment_date, video_call, project } = props;

  return (
    <div className="text-center mb-4">
      <p>
        Mark your calendar! Your video interview is confirmed for the{' '}
        {project.project_title} opportunity.
      </p>
      <p>
        Your interview will start at{' '}
        <LocalDateTime dateTime={appointment_date.appointment_date_and_time} />
      </p>
      <p>You can access your video interview room with the following link:</p>
      <p>
        <a href={`/mom/interview/${video_call.name}`}>LINK TO VIDEO ROOM</a>
      </p>
      <strong>
        Important! You must have a current version of Chrome, Edge, or Safari to
        access the video room.
      </strong>
    </div>
  );
};

VideoAppointmentConfirmation.propTypes = {
  appointment_date: PropTypes.object,
  video_call: PropTypes.object,
  project: PropTypes.object,
};

export default VideoAppointmentConfirmation;
