import React from 'react';
import { Col, Row } from 'react-bootstrap';
import unsupportedBrowser from 'assets/illustrations/unsupported-browser.png';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PermissionWarningIcon = styled.div`
  background: url(${unsupportedBrowser}) no-repeat center center;
  background-size: contain;
  margin: 0 auto;
  width: 16rem;
  height: 16rem;
`;

const Cont = styled.div`
  min-height: 75vh;
`;

const VideoError = ({ children }) => {
  return (
    <Cont>
      <Row>
        <Col className="text-center">
          <PermissionWarningIcon />
          {children}
        </Col>
      </Row>
    </Cont>
  );
};

VideoError.propTypes = {
  children: PropTypes.any,
};

export default VideoError;
