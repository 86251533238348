import React from 'react';
import LinkButton from 'components/ui/buttons/LinkButton';
import { URLS } from 'consts';
import PropTypes from 'prop-types';

const VisitRecommendedTalentPageCTA = ({
  className,
  id,
  dataTestid,
  projectSlug,
  title,
  ...props
}) => (
  <LinkButton
    className={className}
    href={URLS.employerRecommendedTalent(projectSlug)}
    id={id}
    data-testid={dataTestid}
    {...props}
  >
    {title}
  </LinkButton>
);

VisitRecommendedTalentPageCTA.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  dataTestid: PropTypes.string,
  projectSlug: PropTypes.string,
  title: PropTypes.string,
};

export default VisitRecommendedTalentPageCTA;
