import React from 'react';
import PropTypes from 'prop-types';
import { useMediaSet } from 'use-media-set';

import Button from 'components/ui/buttons/Button';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/FlexItem';
import { mediaSetQueries } from 'styleGuide';

/*
This component makes it easy to add 1-3 CTA buttons 
to any UI that render and update consistently
—————————————————————————————————————————————————————————————————————————————————————————
⏐           ⏐ DESKTOP                                   ⏐ TABLET          ⏐ MOBILE      ⏐
⏐———————————⏐—————————————————————————————————————————————————————————————⏐—————————————⏐
⏐ DIRECTION ⏐ Row                                                         ⏐ Column      ⏐
⏐———————————⏐—————————————————————————————————————————————————————————————⏐—————————————⏐
⏐ ORDER     ⏐ [tertiary] <— space —> [secondary] [primary]                ⏐ [primary]   ⏐
⏐           ⏐                                                             ⏐ [secondary] ⏐
⏐           ⏐                                                             ⏐ [tertiary]  ⏐
⏐———————————⏐—————————————————————————————————————————————————————————————⏐—————————————⏐
⏐ SIZING    ⏐ Default Button + extra horizontal padding ⏐ Default Button  ⏐ Full width  ⏐
⏐———————————⏐———————————————————————————————————————————————————————————————————————————⏐
⏐ SPACING   ⏐ 16px                                                                      ⏐
—————————————————————————————————————————————————————————————————————————————————————————
*/

const ButtonGroup = ({ primary, secondary, tertiary }) => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const isMobile = mediaStates.has('mobile');

  const renderButton = (config, isPrimary) => {
    const id = config.id;
    return (
      <Button
        block={isMobile}
        className={mediaStates.has('mobileOrSmTablet') ? null : `px-5`}
        data-id={id}
        data-testid={id}
        disabled={config.disabled}
        id={id}
        onClick={config.onClick}
        secondary={!isPrimary}
        type="button"
      >
        {config.text}
      </Button>
    );
  };

  return (
    <Flex
      alignItems={isMobile ? 'stretch' : 'center'}
      className="w-100"
      direction={isMobile ? 'column' : 'row'}
      gap={16}
      justifyContent={isMobile ? 'flex-start' : 'flex-end'}
    >
      {tertiary && (
        <FlexItem grow={1} order={isMobile ? 3 : 1}>
          {renderButton(tertiary)}
        </FlexItem>
      )}
      {secondary && (
        <FlexItem align="stretch" order={2}>
          {renderButton(secondary)}
        </FlexItem>
      )}
      {primary && (
        <FlexItem align="stretch" order={isMobile ? 1 : 3}>
          {renderButton(primary, true)}
        </FlexItem>
      )}
    </Flex>
  );
};

const ButtonConfig = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

ButtonGroup.propTypes = {
  primary: PropTypes.shape(ButtonConfig).isRequired,
  secondary: PropTypes.shape(ButtonConfig),
  tertiary: PropTypes.shape(ButtonConfig),
};

export default ButtonGroup;
