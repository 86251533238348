import React from 'react';
import PropTypes from 'prop-types';
import { getTimeFromNow } from 'utilities';
import Clock from 'components/ui/icons/Clock';
import { IconWrapper } from './styles';

const ProjectActivatedAt = ({ activatedAt }) => (
  <div className="d-flex align-items-center">
    <IconWrapper>
      <Clock width={22} height="auto" fillColor="currentColor" />
    </IconWrapper>
    <div className="font-sm">
      Posted {getTimeFromNow(Date.parse(activatedAt))}
    </div>
  </div>
);

ProjectActivatedAt.propTypes = {
  activatedAt: PropTypes.string,
};

export default ProjectActivatedAt;
