import React from 'react';

export const Inc = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73.247"
      height="25.313"
      viewBox="0 0 73.247 25.313"
      {...props}
    >
      <title>Inc.</title>
      <path
        fill="currentColor"
        d="M56.288,226.981v-5.412H59.53v-13.6H56.288v-5.824H73.573v5.824H70.331v13.6h3.241v5.416Z"
        transform="translate(-56.288 -202.143)"
      />
      <path
        fill="currentColor"
        d="M192.868,260.267v-5.414h2.506v-5.668c0-1.708-1.217-2.473-2.784-2.473a3.378,3.378,0,0,0-3.484,3.448v4.694h2.579v5.414H178.162v-5.414H180.6v-8.168h-2.442v-5.42h10.664V245a3.374,3.374,0,0,0,.488-.524,8.633,8.633,0,0,1,7.109-3.031c4.11,0,7.457,1.672,7.457,6.238v7.163h2.194v5.416Z"
        transform="translate(-159.982 -235.429)"
      />
      <path
        fill="currentColor"
        d="M382.272,249.92l-7.3.8c-.09-2.025-.505-4.166-2.975-4.166-3.136,0-3.379,2.613-3.379,5.123v.052c0,2.509.243,5.122,3.379,5.122a2.838,2.838,0,0,0,3.1-2.939l.239,0,7.028,0-.059.627c-1.2,5.908-6.532,6.833-10.377,6.833-6.027,0-11.953-2.961-11.953-9.687v.019c0-6.726,5.926-9.687,11.953-9.687,0,0,9.452-.613,10.341,7.894"
        transform="translate(-314.677 -236.069)"
      />
      <path
        fill="currentColor"
        d="M512.216,331.062a3.078,3.078,0,1,1-3.078-3.08,3.077,3.077,0,0,1,3.078,3.08"
        transform="translate(-438.969 -309.211)"
      />
    </svg>
  );
};
