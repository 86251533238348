// Press logos
export { default as forbes } from './forbes.svg';
export { default as cnbc } from './cnbc.svg';
export { default as cbs } from './cbs-news.svg';
export { default as refinery29 } from './refinery-29.svg';
// Icons
export { default as downloadIcon } from './download-icon.svg';
// Stars patterns
export { default as starsPattern1 } from './patterns/stars-1.svg';
export { default as starsPattern2 } from './patterns/stars-2.svg';
export { default as LightBlueCurve } from './patterns/LightBlueCurve.svg';
//Other assets
export { default as YellowDots } from './patterns/yellow-dots.svg';
export { default as downArrow } from './dropdown-arrow.svg';
export { default as horizontalDots } from './horizontal-dots.svg';
export { default as whiteRightArrow } from './white-right-arrow.svg';
