import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'components/ui/buttons/ButtonLink';

function FileGallery({ fileIcon, fileName, onRemove, ...props }) {
  return (
    <div id="gallery" {...props}>
      <div className="files" id="files-container">
        <div className={`icon ${fileIcon}`}></div>
        <span className="filename">{fileName}</span>
      </div>
      <ButtonLink
        data-id="file-gallery-remove-btn"
        className="btn btn-link remove remove-btn"
        type="button"
        onClick={onRemove}
      >
        &times;
      </ButtonLink>
      <div className="files" id="files-container"></div>
    </div>
  );
}

FileGallery.propTypes = {
  fileIcon: PropTypes.string,
  fileName: PropTypes.string,
  onRemove: PropTypes.func,
};

export default FileGallery;
