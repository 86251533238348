import styled, { css } from 'styled-components';

export const ProjectSummaryContainer = styled.div(
  ({ talentDisplay, theme }) => css`
    display: flex;
    flex-direction: column;
    .align-left {
      padding: 20px;
      background-color: ${theme.lightBlue};
    }
    .project-summary__location-industry {
      margin: 30px 0 40px;
      svg {
        margin-right: 8px;
      }
    }
    .location-wrapper {
      display: flex;
      span {
        display: inline-block;
        padding-right: 4px;
      }
    }

    .applicant-count {
      ${talentDisplay &&
      css`
        margin-top: 8px;
      `}
    }

    @media (min-width: ${theme.screenSizes.tablet}) {
      flex-direction: row;
      justify-content: space-between;
      .project-summary__grid {
        display: grid;
        grid-template-columns: ${talentDisplay ? '50% 50%' : '90% 90%'};
      }
      .project-summary__main-content {
        padding-top: 10px;
      }
      .align-left {
        padding: 20px 40px;
        max-width: 330px;
      }
    }

    @media (max-width: ${theme.screenSizes.laptopS}) {
      .project-summary__grid {
        grid-template-columns: auto;
      }
      .project-summary__location-industry {
        white-space: unset;
      }
    }
  `,
);

export const Label = styled.div(
  ({ theme }) => css`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${theme.primaryBlue};
    margin-bottom: 10px;
  `,
);

export const Description = styled.div`
  ol,
  ul {
    padding: 0 0 0 32px;
    margin: 0;
    margin-bottom: 1rem;
    list-style-type: disc;

    br {
      display: none;
    }
  }
`;

export const ApplicantCount = styled.span(
  () => css`
    white-space: nowrap;
  `,
);

export const LowAppCount = styled.span(
  ({ theme }) => css`
    font-size: 12px;
    white-space: nowrap;
    color: ${theme.mediumGreen};
  `,
);
