import React, { forwardRef, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PreventableEvent } from 'utilities';
import Check from '../icons/Check';

const Wrapper = styled.div`
  position: relative;
  padding: 13px 46px;
  font-size: ${({ theme }) => theme.fontSizes.base};
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border-radius: 37px;

  ${({ checked, theme, isDisabled }) => `
    color: ${isDisabled && !checked ? theme.mediumGray : theme.primaryBlue};
    background-color: ${
      isDisabled && !checked ? theme.white.darken(4.5) : theme.white
    };
    box-shadow: inset 0 0 0 ${checked ? '2px' : '1px ' + theme.lightGray};
    font-weight: ${
      checked ? theme.fontWeights.fontHeavy : theme.fontWeights.fontBold
    };
    letter-spacing: ${checked ? '-0.2px' : 0};
    &:hover {
      box-shadow:
        ${theme.boxShadow100},
        inset 0 0 0 ${checked ? '2px' : '1px ' + theme.lightGray};
    }
  `}

  .checked-indicator {
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px;
  }
`;

/**
 * Checkbox button that returns either true, false or given value when checked.
 */
const CheckboxButton = forwardRef(
  (
    {
      labelText,
      checked,
      defaultChecked,
      value,
      defaultValue,
      uncheckedValue,
      onClick,
      onKeyDown,
      isStateManaged,
      disabled,
      onChange,
      children,
      ...props
    },
    forwardedRef,
  ) => {
    const generatedId = useMemo(
      () => `checkbox-button-${CheckboxButton.idCounter++}`,
      [],
    );
    const id = props.id || generatedId;
    let initialValue = defaultChecked;
    if (defaultChecked === undefined) {
      initialValue = !!defaultValue;
      if (defaultValue === undefined) {
        initialValue = !!value;
      }
    }
    const [stateChecked, setStateChecked] = useState(initialValue);
    let isChecked = checked;
    if (checked === undefined) {
      isChecked = stateChecked;
    }
    const handleRef = useCallback(() => {
      if (forwardedRef) {
        forwardedRef({
          get checked() {
            return isChecked;
          },
          get value() {
            if (isChecked) {
              return value === undefined ? true : value;
            }
            return uncheckedValue === undefined ? false : uncheckedValue;
          },
        });
      }
    }, [value, isChecked, uncheckedValue, forwardedRef]);

    const toggle = useCallback(
      handleChange => {
        const nextIsChecked = !isChecked;
        let nextValue;
        if (nextIsChecked) {
          nextValue = value === undefined ? true : value;
        } else {
          nextValue = uncheckedValue === undefined ? false : uncheckedValue;
        }
        if (checked === undefined || isStateManaged) {
          setStateChecked(nextValue);
        }
        setTimeout(() => {
          if (handleChange) {
            handleChange(
              new PreventableEvent(nextValue, { checked: nextIsChecked }),
            );
          }
        }, 0);
      },
      [checked, uncheckedValue, value, isStateManaged, isChecked],
    );

    const handleClick = useCallback(
      handleChange => event => {
        if (onClick) {
          onClick(event);
          if (event.defaultPrevented) {
            return;
          }
        }
        toggle(handleChange);
      },
      [onClick, toggle],
    );
    const handleKeyDown = useCallback(
      handleChange => event => {
        if (onKeyDown) {
          onKeyDown(event);
          if (event.defaultPrevented) {
            return;
          }
        }
        if (event.key === ' ') {
          event.preventDefault();
          toggle(handleChange);
        }
      },
      [onKeyDown, toggle],
    );

    const wrapperProps = {
      ...props,
      id,
      checked: isChecked,
      ref: handleRef,
      isDisabled: disabled,
    };
    if (!disabled) {
      wrapperProps.tabIndex = '0';
      wrapperProps.onClick = handleClick(onChange);
      wrapperProps.onKeyDown = handleKeyDown(onChange);
    }
    return (
      <Wrapper {...wrapperProps}>
        {isChecked ? (
          <Check width="20" height="20" className="checked-indicator" />
        ) : null}
        {labelText || children}
      </Wrapper>
    );
  },
);
CheckboxButton.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.any,
  /** Assumes onChange will update given checked value, such as when used in a StateRadioGroup */
  isStateManaged: PropTypes.bool,
  labelText: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  /** Set to return a specific value instead of `false` when unchecked */
  uncheckedValue: PropTypes.any,
  /** Set to return a specific value instead of `true` when checked */
  value: PropTypes.any,
};
CheckboxButton.idCounter = 0;
CheckboxButton.displayName = 'CheckboxButton';

export default CheckboxButton;
