import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import stored from 'components/hoc/stored';
import Honeybadger from 'honeybadger-js';

import { colors } from 'styleGuide';
import {
  getInterviewSchedulingPage,
  getInterviewSchedulingPages,
  nylasAccountSynced,
} from 'components/containers/employer/projects/bids/requests';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import RequestInterviewError from 'components/employer/interviews/RequestInterview/RequestInterviewError';
import { checkNylasToken } from 'components/containers/employer/projects/bids/requests';
import { getTimeZoneFromBrowser, noop } from 'utilities';
import NylasReauthentication from 'components/employer/interviews/NylasReauthentication';

const InterviewSchedulingPages = ({
  buttonText,
  children,
  handleAccountNotYetSynced,
  handleOptOut,
  schedulingPageId,
  onReauthClose,
  onTokenInvalid = noop,
}) => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [tokenValid, setTokenValid] = useState(true);

  useEffect(() => {
    loadPages();
  }, [loadPages]);

  /**
   * Initial call that checks if the token is valid first
   * Then depending on schedulingPageId we're fetching this page or all pages
   * @type {(function(): Promise<void>)|*}
   */
  const loadPages = useCallback(async () => {
    setLoading(true);
    setShowError(false);

    try {
      const token_response = await checkNylasToken();
      if (!token_response.token_valid) {
        setTokenValid(false);
        setLoading(false);
        onTokenInvalid();
        return;
      }
    } catch (e) {
      Honeybadger.notify(`Nylas token check failed: ${e}`);
      setShowError(true);
      setLoading(false);
    }

    if (schedulingPageId) {
      fetchPage(schedulingPageId);
    } else {
      fetchPages();
    }
  }, [fetchPage, fetchPages, schedulingPageId, onTokenInvalid]);

  const fetchPage = useCallback(async schedulingPageId => {
    try {
      const page = await getInterviewSchedulingPage(schedulingPageId);
      if (page.error) {
        // Handle 404s
        handleError(schedulingPageId, page.error);
      } else {
        setPages([page]);
        setLoading(false);
      }
    } catch (e) {
      handleError(schedulingPageId, e);
    }
  }, []);

  const handleError = (schedulingPageId, e) => {
    Honeybadger.notify(
      `Nylas error getting scheduling page with id ${schedulingPageId}: ${e}`,
    );
    setShowError(true);
    setLoading(false);
  };

  const fetchPages = useCallback(async () => {
    const timezone = getTimeZoneFromBrowser();
    try {
      const account_synced_response = await nylasAccountSynced();
      if (account_synced_response.success) {
        const pages = await getInterviewSchedulingPages(timezone);
        setPages(pages);
        setLoading(false);
      } else {
        return handleAccountNotYetSynced();
      }
    } catch (e) {
      Honeybadger.notify(`Nylas error getting pages: ${e}`);
      setShowError(true);
      setLoading(false);
    }
  }, [handleAccountNotYetSynced]);

  return (
    <>
      {showError && (
        <RequestInterviewError
          handleTryAgain={loadPages}
          handleOptOut={handleOptOut}
          buttonText={buttonText}
        />
      )}
      {loading && (
        <>
          <div className="my-4">
            Thanks for waiting while we fetch your preferences.
          </div>
          <div className="mb-4">
            <LoadingSpinner color={colors.blue.primary} size={50} />
          </div>
        </>
      )}
      {!loading && !showError && tokenValid && (
        <div className="w-100 d-flex flex-column">{children(pages)}</div>
      )}
      {!loading && !showError && !tokenValid && (
        <NylasReauthentication handleOnClose={onReauthClose} />
      )}
    </>
  );
};

InterviewSchedulingPages.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.func.isRequired,
  handleAccountNotYetSynced: PropTypes.func,
  handleOptOut: PropTypes.func.isRequired,
  schedulingPageId: PropTypes.string,
  onReauthClose: PropTypes.func,
  onTokenInvalid: PropTypes.func,
};

export default stored(InterviewSchedulingPages);
