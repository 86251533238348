import React, {
  forwardRef,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import SuccessMessage from 'components/ui/labels/SuccessMessage';
import { Validate } from 'reactiverecord';
import { Label } from '../Input/styles';
import { StyledDatePicker } from './styles';
import Calendar from '../../icons/Calendar';

const DateInput = forwardRef(
  ({ successText, labelText, ...props }, forwardedRef) => {
    const generatedId = useMemo(
      () => `date-input-${DateInput.idCounter++}`,
      [],
    );
    const id = props.id || generatedId;
    const [date, setDate] = useState(
      props.defaultValue ? new Date(props.defaultValue) : null,
    );
    const storeValidate = useRef(null);
    const handleRef = useCallback(
      validateRef => ref => {
        const refInterface = {
          get isValid() {
            return storeValidate.current.isValid.bind(storeValidate.current);
          },
          get value() {
            return ref.props.value;
          },
        };
        validateRef(refInterface);
        if (forwardedRef) {
          forwardedRef(refInterface);
        }
      },
      [forwardedRef],
    );
    const handleChange = date => {
      setDate(date);
      if (props.onChange) {
        props.onChange(date);
      }
    };
    return (
      <Label htmlFor={id}>
        {labelText ? <span className="label-text">{labelText}</span> : null}
        <Validate {...props} ref={storeValidate}>
          {({ ref: validateRef, ...inputProps }, errorText, validating) => (
            <div className="input-wrapper">
              <StyledDatePicker
                id={id}
                {...inputProps}
                hasError={!!errorText}
                hasIcon={true}
                value={date}
                ref={handleRef(validateRef)}
                disabled={validating}
                clearIcon={null}
                calendarIcon={<Calendar width="20" height="20" />}
                onChange={handleChange}
              />
              {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
            </div>
          )}
        </Validate>
        {successText ? <SuccessMessage>{successText}</SuccessMessage> : null}
      </Label>
    );
  },
);

DateInput.propTypes = {
  labelText: PropTypes.string,
  successText: PropTypes.string,
  defaultValue: PropTypes.any,
  id: PropTypes.any,
  minDate: PropTypes.object,
  onChange: PropTypes.func,
};
DateInput.defaultProps = {
  autoComplete: 'off',
};
DateInput.idCounter = 0;
DateInput.displayName = 'DateInput';

export default DateInput;
