import React from 'react';
import AutosuggestList from './AutosuggestList';
import MagnifyingGlass from 'components/ui/icons/MagnifyingGlass';
import Input from 'components/ui/inputs/Input';
import Button from 'components/ui/buttons/Button';
import Close from 'components/ui/icons/Close';
import styled from 'styled-components';
import theme from 'theme';

import PropTypes from 'prop-types';

const DialogCloser = styled.span`
  position: absolute;
  top: 1.1rem;
  left: 1rem;
  color: ${theme.primaryBlue};
  cursor: pointer;
  font-weight: ${theme.fontWeights.fontHeavy};
`;

const Search = styled.span`
  position: absolute;
  top: 1.1rem;
  right: 1rem;
  color: ${theme.primaryBlue};
  cursor: pointer;
  font-weight: ${theme.fontWeights.fontHeavy};
`;

const KeywordSearchMobile = ({
  clearKeywordSearchBar,
  handleBlur,
  handleKeywordChange,
  handleSubmitSearch,
  hasAutocomplete,
  InputContainer,
  inputRef,
  keyword,
  labelText,
  onSelect,
  resultsHeader,
  setListFocus,
  showIcon,
  showList,
  showX,
  suggestions,
  toggle,
}) => (
  <>
    <DialogCloser onClick={toggle}>Close</DialogCloser>
    <Search
      onClick={e => {
        handleSubmitSearch(e, keyword);
        setListFocus(false);
        toggle();
      }}
    >
      Search
    </Search>
    <InputContainer
      isMobile={true}
      onBlur={handleBlur}
      onFocus={() => setListFocus(true)}
      ref={inputRef}
      showButton={false}
      showIcon={true}
      withLabel={labelText}
    >
      {showIcon && (
        <MagnifyingGlass
          className="keyword-search__magnifying-icon"
          height={20}
          width={20}
        />
      )}
      <Input
        className="keyword-search__input not-heavy"
        id="keyword-search-box"
        labelText={labelText}
        onChange={e => handleKeywordChange(e)}
        value={keyword}
      />
      {keyword && showX && (
        <Button
          className="keyword-search__clear unstyled-button"
          id="clear-search-button"
          inline
          link
          onClick={clearKeywordSearchBar}
        >
          <Close width={16} height={16} title="Clear keyword search" />
        </Button>
      )}
      <hr />
      {keyword.length > 0 && showList && hasAutocomplete && (
        <AutosuggestList
          isMobileContainer
          keyword={keyword}
          onSelect={onSelect}
          resultsHeader={resultsHeader}
          suggestions={suggestions}
        />
      )}
    </InputContainer>
  </>
);

KeywordSearchMobile.propTypes = {
  clearKeywordSearchBar: PropTypes.func,
  handleBlur: PropTypes.func,
  handleKeywordChange: PropTypes.func,
  handleSubmitSearch: PropTypes.func,
  hasAutocomplete: PropTypes.bool,
  InputContainer: PropTypes.object,
  inputRef: PropTypes.object,
  keyword: PropTypes.string,
  labelText: PropTypes.string,
  onSelect: PropTypes.func,
  resultsHeader: PropTypes.string,
  setKeyword: PropTypes.func,
  setListFocus: PropTypes.func,
  showIcon: PropTypes.bool,
  showList: PropTypes.bool,
  showX: PropTypes.bool,
  suggestions: PropTypes.object,
  toggle: PropTypes.func,
};

KeywordSearchMobile.defaultProps = {
  hasAutocomplete: false,
  showX: true,
};

export default KeywordSearchMobile;
