import { useState } from 'react';
import Video from 'twilio-video';
import Honeybadger from 'honeybadger-js';

const useVideoRoom = (setRoomState, localTracks) => {
  const [room, setRoom] = useState(null);
  const [localParticipant, setLocalParticipant] = useState({});
  const [remoteParticipants, setRemoteParticipants] = useState([]);

  const tearDownParticipant = participant => {
    const filteredParticipants = remoteParticipants.filter(
      p => p !== participant,
    );
    setRemoteParticipants(filteredParticipants);
  };

  const setupParticipant = participant => {
    setRemoteParticipants(prev => [...prev, participant]);
  };

  const connect = token => {
    return Video.connect(token.token, { tracks: localTracks })
      .then(room => {
        setRoom(room);
        setLocalParticipant(room.localParticipant);

        const disconnect = () => room.disconnect();

        room.once('disconnected', () => {
          window.removeEventListener('beforeunload', disconnect);
          setLocalParticipant({});
          setRemoteParticipants([]);
          setRoom(null);
        });

        room.participants.forEach(setupParticipant);

        window.addEventListener('beforeunload', disconnect);

        room.on('participantConnected', setupParticipant);
        room.on('participantDisconnected', tearDownParticipant);

        setRoomState(room.state);
      })
      .catch(err => {
        Honeybadger.notify(
          `[video-interview] - error connecting to room: ${err}`,
        );
      });
  };

  return { connect, remoteParticipants, localParticipant, room };
};

export default useVideoRoom;
