import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme }) => css`
    background-color: ${theme.lightPink};
    padding: 40px;
    display: flex;
    justify-content: center;
  `,
);

export const BorderBox = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${theme.white};
    box-shadow: -6px -6px 0px 0px ${theme.palePink};
    .header {
      color: ${theme.primaryBlue};
      ${theme.fonts.header.sm};
    }
    p,
    div {
      max-width: 445px;
      margin: 16px 0;
    }
    @media (max-width: ${theme.screenSizes.mobileL}) {
      padding: 150px 20px;
    }
    @media (min-width: ${theme.screenSizes.tablet}) {
      width: 635px;
      height: 260px;
      z-index: 1;
    }
  `,
);

export const Spinner = styled.span``;
