import React from 'react';

export default function Alert(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 21" {...props}>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M22.863 0a3 3 0 013 3v14.835a3 3 0 01-3 3H8.027a3 3 0 01-3-3V14.05L.524 11.342a1.08 1.08 0 01-.11-1.774l.11-.076 4.503-2.708V3A3 3 0 017.851.005L8.027 0zm0 2H8.027a1 1 0 00-1 1v4.912l-.024.011-4.083 2.494 4.083 2.461.024.012v4.945a1 1 0 001 1h14.836a1 1 0 001-1V3a1 1 0 00-1-1zm-7.418 11.672a1 1 0 110 2 1 1 0 010-2zm0-8.508a1 1 0 011 1v5.5a1 1 0 01-2 0v-5.5a1 1 0 01.884-.994l.116-.006z"
      />
    </svg>
  );
}
