import React from 'react';
import KeywordSearchAutosuggest from 'components/ui/inputs/Autosuggest/KeywordSearchAutosuggest';
import ReactiveRecord from 'reactiverecord';
import { useFilterContext } from './FilterContext';
import PropTypes from 'prop-types';

const KeywordSearchAutocomplete = ({ autocomplete = true }) => {
  const TitleSearch = ReactiveRecord.model('TitleSearch');
  const { filters, setFilter } = useFilterContext();

  const handleKeywordChanged = term => {
    if (term !== filters.keyword) {
      setFilter('keyword', term);
    }
  };

  const fetchKeywordSearch = term =>
    TitleSearch.find(undefined, {
      category: 'specific',
      limit: 10,
      query: term,
    }).then(({ titles }) => {
      const suggestions = new Set();
      titles.forEach(result => {
        suggestions.add(result.name);
      });
      return suggestions;
    });

  return (
    <KeywordSearchAutosuggest
      title=""
      placeholder="Search job title or keyword"
      handleSubmit={handleKeywordChanged}
      handleClear={() => handleKeywordChanged('')}
      value={filters.keyword}
      fetchAutocomplete={fetchKeywordSearch}
      showSVPModal={false}
      showIcon={false}
      showIconOnButton={true}
      hasAutocomplete={autocomplete}
    />
  );
};

KeywordSearchAutocomplete.propTypes = {
  autocomplete: PropTypes.bool,
};

export default KeywordSearchAutocomplete;
