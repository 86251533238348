import React from 'react';
import Icon from 'components/ui/Icon';

export default function Eye(props) {
  return (
    <Icon viewBox="0 0 24 14" {...props}>
      <title>Eye</title>
      <g id="GUI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Icons"
          transform="translate(-475.000000, -156.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g
            id="2-Icon/General/Eye"
            transform="translate(476.000000, 152.000000)"
          >
            <path
              d="M10.5735,4 C16.3045,4.029 20.0455,7.747 21.6465,9.752 C22.2335,10.486 22.2335,11.514 21.6465,12.248 C20.0455,14.253 16.3045,17.97 10.5735,17.997 L10.5735,17.997 L10.5735,18 L10.5435,17.998 L10.5435,17.998 L10.5135,18 C4.7815,17.97 1.0415,14.253 -0.5605,12.248 C-1.1465,11.514 -1.1465,10.486 -0.5605,9.752 C1.0415,7.747 4.7815,4.029 10.5135,4.003 L10.5135,4.003 C10.5235,4 10.5335,4.002 10.5435,4.002 L10.5585,4.001 L10.5585,4.001 L10.5735,4 Z M10.5675,6 L10.5195,6 C4.3055,6.028 1.1365,10.863 1.0515,10.994 L1.0515,10.994 C1.0435,10.994 1.0475,11 1.0475,11 L1.0475,11 L1.0435,11.006 C1.1365,11.137 4.3055,15.972 10.5195,15.998 L10.5195,15.998 L10.5195,16 L10.5435,15.999 L10.5435,15.999 L10.5675,16 C16.7815,15.972 19.9505,11.137 20.0355,11.006 L20.0355,11.006 L20.0435,11.006 C20.0395,11 20.0435,10.994 20.0435,10.994 L20.0435,10.994 C19.9505,10.863 16.7815,6.028 10.5675,6.002 L10.5675,6.002 L10.5675,6 Z M10.5435,7.0001 C12.7525,7.0001 14.5435,8.7911 14.5435,11.0001 C14.5435,13.2091 12.7525,15.0001 10.5435,15.0001 C8.3345,15.0001 6.5435,13.2091 6.5435,11.0001 C6.5435,8.7911 8.3345,7.0001 10.5435,7.0001 Z M10.5435,9.0001 C9.4395,9.0001 8.5435,9.8951 8.5435,11.0001 C8.5435,12.1041 9.4395,13.0001 10.5435,13.0001 C11.6475,13.0001 12.5435,12.1041 12.5435,11.0001 C12.5435,9.8951 11.6475,9.0001 10.5435,9.0001 Z"
              id="Eye"
            ></path>
          </g>
        </g>
      </g>
    </Icon>
  );
}

Eye.propTypes = { ...Icon.propTypes };
