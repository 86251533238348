import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'components/ui/Flex';
import stored from 'components/hoc/stored';
import TimeRangeSelects from 'components/shared/TimeRangeSelects';

const InterviewSchedulingPageAvailableHours = ({ onChange, timeRanges }) => {
  const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

  return (
    <>
      <Flex alignItems="stretch" direction="column" gap={8}>
        <div className="label-default">I can meet during these hours</div>
        <p>
          We save your settings as a template for interview invites. Your
          calendar finds available times to send candidates.
        </p>
        <Flex alignItems="stretch" direction="column" gap={24}>
          {DAYS_OF_WEEK.map(day => {
            return (
              <TimeRangeSelects
                checkboxText={day}
                id={day}
                key={day}
                onChange={newValue => onChange(newValue, day)}
                rangeName={day}
                timeRanges={timeRanges[day].value}
              />
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};

InterviewSchedulingPageAvailableHours.propTypes = {
  onChange: PropTypes.func.isRequired,
  timeRanges: PropTypes.object.isRequired,
};

export default stored(InterviewSchedulingPageAvailableHours);
