import React from 'react';
import Icon from 'components/ui/Icon';
import publicTheme from 'publicTheme';

export default function Hangup(props) {
  return (
    <Icon viewBox="0 0 46 46" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={publicTheme.red.toString()}
              d="M23.159.317C35.773.317 46 10.544 46 23.16 46 35.774 35.773 46 23.159 46 10.544 46 .317 35.774.317 23.159S10.544.317 23.16.317z"
              transform="translate(-677 -559) translate(677 559)"
            />
            <path
              fill="#FFF"
              d="M17.262 13.83c1.416 1.574 2.61 3.295 2.61 3.295s.984 1.352.115 2.227c-.574.577-1.357 1.952-.952 2.538.405.586 5.393 5.393 5.393 5.393s.57.5.952.317c.381-.181 2.22-1.269 2.22-1.269s.76-.257 1.587.318c.834.58 3.403 2.283 3.403 2.283s.732.921.219 1.734-2.116 2.42-2.116 2.42-.637.432-2.08.09c-1.442-.342-4.815-1.147-9.437-5.766-4.274-4.273-6.154-10.023-6.154-10.023s-.136-1.001.473-1.893c.61-.892 2.411-3.174 3.767-1.665z"
              transform="translate(-677 -559) translate(677 559) rotate(135 23 23.317)"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
}
