import styled, { css } from 'styled-components';

export const DateTime = styled.div(
  ({ theme }) => css`
    font-weight: ${theme.fontWeights.fontHeavy};
    font-size: ${theme.fontSizes.md};
  `,
);

export const GreetingRow = styled.div(
  () => css`
    display: flex;
    margin-bottom: 1.5rem;
    line-height: 1.75rem;
    flex-flow: row nowrap;
    align-items: center;
  `,
);

export const StyledAvatarContainer = styled.div(
  () => css`
    .initials,
    img {
      width: 1.75rem;
      height: 1.75rem;
      margin-right: 0.6rem;
      font-size: 0.75rem;
      line-height: 1.75rem;
    }
  `,
);

export const GreetingHeader = styled.div(
  () => css`
    margin-bottom: 1.5rem;
  `,
);

export const BottomSpacingContainer = styled.div(
  () => css`
    margin-bottom: 1.5rem;
  `,
);

export const Disclaimer = styled.p(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.sm};
  `,
);
