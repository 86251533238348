import ReactiveRecord, { Model } from 'reactiverecord';

class EmployerAccount extends Model {
  static store = {
    singleton: true,
  };

  static routes = {
    create: ':prefix/employers/account',
  };

  set phone(phone) {
    let phone_digits = phone.replace(/[^0-9]+/g, '');
    if (phone_digits.length > 10 && phone_digits[0] === '1') {
      this._attributes.phone = phone_digits.slice(1);
    }
    this._attributes.phone = phone_digits;
  }

  static schema = {
    agree_to_terms_and_policy: Boolean,
    company_size: String,
    company_name: String,
    email: { type: String, labelText: 'Email' },
    first_name: String,
    gclid: String,
    last_name: String,
    number_of_employees: { type: String, labelText: 'Company Size' },
    password: { type: String, labelText: 'Password' },
    creation_source: { type: String },
  };

  get humanizedNumberOfEmployees() {
    return this.constructor.numberOfEmployeesOptions[this.number_of_employees];
  }
}

export default ReactiveRecord.model('EmployerAccount', EmployerAccount);
