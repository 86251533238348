import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaSet } from 'use-media-set';

import DeleteSchedulingPageButton from '../DeleteSchedulingPageButton';
import EmptyInterviewSchedulingPages from '../EmptyInterviewSchedulingPages';
import InterviewSchedulingPages from '../InterviewSchedulingPages';
import { createAppointment } from 'components/containers/employer/projects/bids/requests';
import InterviewModalHeader from 'components/employer/interviews/InterviewModalHeader';
import InterviewSchedulingPageCard from 'components/employer/interviews/SchedulingPage/InterviewSchedulingPageCard';
import stored from 'components/hoc/stored';
import Button from 'components/ui/buttons/Button';
import ButtonGroup from 'components/ui/buttons/ButtonGroup';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import Flex from 'components/ui/Flex';
import Eye from 'components/ui/icons/Eye';
import Pencil from 'components/ui/icons/Pencil';
import PlusCircleO from 'components/ui/icons/PlusCircleO';
import CheckboxButton from 'components/ui/inputs/CheckboxButton';
import { SCHEDULING_PAGE_ACTION_TYPE } from 'consts';
import { mediaSetQueries } from 'styleGuide';
import { openingHoursText } from './supporting';

const ContentWrapper = styled(Flex)`
  max-width: 500px;
  min-height: 320px;
`;

const StyledCheckboxButton = styled(CheckboxButton)`
  width: ${props => (props.isMobile ? 'calc(100vw - 115px)' : '325px')};
`;

const SelectInterviewSchedulingPage = ({
  bid,
  editedPageId,
  handleAccountNotYetSynced,
  handleCancel,
  handleDeleteSchedulingPage,
  handleEditSchedulingPage,
  handleNewSchedulingPage,
  handleSelectManually,
  handleSendRequest,
  handleViewSchedulingPage,
  momId,
  secondaryButtonText,
  user,
}) => {
  const [selectedPageId, setSelectedPageId] = useState(editedPageId || null);
  const [submitting, setSubmitting] = useState(false);
  const [isReauthenticate, setIsReauthenticate] = useState(false);
  const mediaStates = useMediaSet(mediaSetQueries);
  const isMobile = mediaStates.has('mobile');

  const flexDirection = {
    direction: isMobile ? 'column' : 'row',
  };
  const bidId = bid.id;

  const isSelected = page =>
    selectedPageId && selectedPageId === page.id ? true : false;

  const handleAction = (type, page) => {
    switch (type) {
      case SCHEDULING_PAGE_ACTION_TYPE.EDIT:
        handleEditSchedulingPage(page);
        break;
      case SCHEDULING_PAGE_ACTION_TYPE.VIEW:
        handleViewSchedulingPage(page);
        break;
      case SCHEDULING_PAGE_ACTION_TYPE.DELETE:
        handleDeleteSchedulingPage(page);
        break;
    }
  };

  const sendInterviewRequest = async (bidId, page) => {
    setSubmitting(true);
    const appointmentType = page.slug.includes('video') ? 'video' : 'phone';
    const appointmentDetails = {
      appointmentType,
      bidId,
      duration: page.duration,
      email: user.email,
      momId,
      nylasBookingUrl: page.slug,
      phone: user.phone,
      timeZone: page.timezone,
    };
    const appointment = await createAppointment(appointmentDetails);
    handleSendRequest(appointment);
    setSubmitting(false);
  };

  const toggleSelection = page => {
    setSelectedPageId(isSelected(page) ? null : page.id);
  };

  return (
    <>
      <InterviewModalHeader
        mom={bid.mom}
        heading={
          isReauthenticate
            ? 'My interview hours'
            : 'When are you available to meet?'
        }
        subheader={
          isReauthenticate
            ? null
            : 'Choose or set up a block of time for your interview hours. Your calendar updates when they choose a time within the block.'
        }
      />
      <InterviewSchedulingPages
        handleAccountNotYetSynced={handleAccountNotYetSynced}
        handleOptOut={handleSelectManually}
        onReauthClose={handleCancel}
        onTokenInvalid={() => setIsReauthenticate(true)}
      >
        {schedulingPages => {
          const selectedPage = schedulingPages.find(
            p => p.id === selectedPageId,
          );
          return (
            <>
              {schedulingPages.length === 0 && (
                <EmptyInterviewSchedulingPages
                  className="mt-4"
                  addNewSchedulingPage={handleNewSchedulingPage}
                />
              )}
              <Flex alignItems="stretch" direction="column" gap={24}>
                {schedulingPages.length > 0 && (
                  <ContentWrapper
                    alignItems={isMobile ? 'stretch' : 'flex-start'}
                    direction="column"
                    gap={26}
                  >
                    <>
                      <Flex
                        alignItems={isMobile ? 'stretch' : 'flex-start'}
                        direction="column"
                        gap={isMobile ? 0 : 16}
                        className="mt-4"
                      >
                        {schedulingPages.map(page => {
                          const openingHours = page.opening_hours;
                          if (isMobile) {
                            return (
                              <InterviewSchedulingPageCard
                                className="w-100"
                                key={page.slug}
                                onAction={handleAction}
                                onSelect={() => toggleSelection(page)}
                                schedulingPage={page}
                                selectable
                                selected={isSelected(page)}
                              />
                            );
                          } else {
                            return (
                              <Flex
                                key={page.slug}
                                direction="row"
                                justifyContent="space-between"
                                gap={16}
                              >
                                <StyledCheckboxButton
                                  checked={isSelected(page)}
                                  className={
                                    isSelected(page) ? '' : 'not-heavy'
                                  }
                                  data-id={page.id}
                                  data-testid={page.id}
                                  isMobile={isMobile}
                                  key={page.slug}
                                  onChange={() => toggleSelection(page)}
                                >
                                  <div className="text-overflow">
                                    {page.name}
                                  </div>
                                  <div className="text-dark">
                                    {openingHoursText(openingHours)}
                                  </div>
                                </StyledCheckboxButton>
                                <Button
                                  data-id="edit-scheduling-page-button"
                                  data-testid="edit-scheduling-page-button"
                                  onClick={() => handleEditSchedulingPage(page)}
                                  link
                                  inline
                                >
                                  <Pencil width="18px" height="18px" />
                                  <span className="ml-1 font-heavy">Edit</span>
                                </Button>
                                <Button
                                  data-id="view-scheduling-page-button"
                                  data-testid="view-scheduling-page-button"
                                  onClick={() => handleViewSchedulingPage(page)}
                                  link
                                  inline
                                >
                                  <Eye width="19px" height="19px" />
                                  <span className="ml-1 font-heavy">View</span>
                                </Button>
                                <DeleteSchedulingPageButton
                                  id="delete-scheduling-page-button-comp"
                                  schedulingPage={page}
                                  handleClick={() =>
                                    handleDeleteSchedulingPage(page)
                                  }
                                />
                              </Flex>
                            );
                          }
                        })}
                      </Flex>
                    </>
                  </ContentWrapper>
                )}
                {schedulingPages.length > 0 && (
                  <Flex
                    {...flexDirection}
                    alignItems={isMobile ? 'center' : null}
                    justifyContent={isMobile ? null : 'flex-start'}
                  >
                    <ButtonLink
                      data-id="new-scheduling-page-button"
                      onClick={handleNewSchedulingPage}
                    >
                      <PlusCircleO size={18} />
                      <span className="ml-2 font-heavy">Add new template</span>
                    </ButtonLink>
                  </Flex>
                )}
                <ButtonGroup
                  id="select-interview-scheduling-page-btns"
                  primary={{
                    disabled: !selectedPage || submitting,
                    id: 'btn-auth-nylas',
                    onClick: () => {
                      sendInterviewRequest(bidId, selectedPage);
                    },
                    text: 'Send invite',
                  }}
                  secondary={{
                    id: 'btn-cancel-auth-nylas',
                    onClick: handleCancel,
                    text: secondaryButtonText || 'Cancel',
                  }}
                />
              </Flex>
            </>
          );
        }}
      </InterviewSchedulingPages>
    </>
  );
};

SelectInterviewSchedulingPage.displayName = 'SelectInterviewSchedulingPage';

SelectInterviewSchedulingPage.propTypes = {
  bid: PropTypes.object,
  editedPageId: PropTypes.number,
  handleAccountNotYetSynced: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDeleteSchedulingPage: PropTypes.func.isRequired,
  handleEditSchedulingPage: PropTypes.func.isRequired,
  handleNewSchedulingPage: PropTypes.func.isRequired,
  handleSelectManually: PropTypes.func.isRequired,
  handleSendRequest: PropTypes.func.isRequired,
  handleViewSchedulingPage: PropTypes.func.isRequired,
  interview: PropTypes.object,
  momId: PropTypes.any,
  secondaryButtonText: PropTypes.string,
  user: PropTypes.object,
};

export default stored(SelectInterviewSchedulingPage);
