import { createGlobalStyle } from 'styled-components';

const defineColorLiterals = ({ ThemeColor, ...themeConstants }) =>
  Object.keys(themeConstants).reduce((style, key) => {
    if (themeConstants[key] instanceof ThemeColor) {
      return `
      ${style}
      .color-${key} {
        color: ${themeConstants[key]};
      }
      .bg-${key} {
        background-color: ${themeConstants[key]};
      }
    `;
    }
    return style;
  }, '');

const defineFontLiterals = ({ fonts }) =>
  Object.keys(fonts).reduce((style, key) => {
    if (typeof fonts[key] === 'string') {
      return `
        ${style}
        .font-${key} { font-family: ${fonts[key]}; }
      `;
    }
    return style;
  }, '');

export default createGlobalStyle(
  ({ theme }) => `
  .responsive {
    width: 100%;
    height: auto;
  }
  ${defineColorLiterals(theme)}
  ${defineFontLiterals(theme)}
`,
);
