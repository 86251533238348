import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';

const Clock = ({ fillColor, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill={fillColor} fillRule="nonzero">
          <g>
            <g>
              <path
                d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm0 2c-4.971 0-9 4.029-9 9s4.029 9 9 9c4.97 0 9-4.029 9-9s-4.03-9-9-9zm-.517 3.307c.552 0 1 .448 1 1v5.17l3.19 2.225c.39.391.39 1.024 0 1.415-.39.39-1.023.39-1.414 0l-3.776-2.81v-6c0-.552.448-1 1-1z"
                transform="translate(-95 -100) translate(31 99) translate(64 1)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Clock.propTypes = {
  fillColor: PropTypes.string,
};

Clock.defaultProps = {
  fillColor: theme.primaryBlue.toString(),
};

export default Clock;
