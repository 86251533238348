import React from 'react';
import PropTypes from 'prop-types';
import { Link } from './stylesV2';

const LogoutLink = props => {
  return (
    <Link
      onClick={() => window.dataLayer.push({ event: 'logged-out' })}
      href={props.logoutURL}
    >
      Logout
    </Link>
  );
};

LogoutLink.propTypes = {
  logoutURL: PropTypes.string.isRequired,
};

export default LogoutLink;
