import ReactiveRecord, { Model } from 'reactiverecord';
import { getFirstNameAndInitialForLastName } from 'utilities';

class VideoParticipant extends Model {
  static routes = {
    index: ':prefix/video-participants',
  };

  static schema = {
    name: String,
    avatar: String,
  };

  get firstNameAndLastInitial() {
    return getFirstNameAndInitialForLastName(this.name);
  }
}

export default ReactiveRecord.model('VideoParticipant', VideoParticipant);
