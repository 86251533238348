import React from 'react';
import PropTypes from 'prop-types';

const RightNavArrowCloud = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <filter id="a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.400000 0 0 0 0 0.521569 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M50 49.72C50 77.179 27.823 99.439.467 99.439V0C27.823 0 50 22.26 50 49.72z"
        />
        <g filter="url(#a)" transform="rotate(90 25 25)">
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M48.938 41.028l.001-21.4-6.395 6.518-1.357-1.383 7.991-8.146a.915.915 0 011.313-.004c1.943 1.978 7.56 7.704 7.975 8.126l.024.024-1.357 1.383-6.394-6.517v21.399h-1.8z"
          />
        </g>
      </g>
    </svg>
  );
};

RightNavArrowCloud.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
RightNavArrowCloud.defaultProps = {
  height: 100,
  width: 50,
};

export default RightNavArrowCloud;
