import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Scrollspy from 'react-scrollspy';

const StyledNavButton = styled.button(
  ({ theme }) => css`
    width: 100%;
    text-align: left;
    padding-left: 20px;
    color: ${theme.darkerGray};
    font-weight: ${theme.fontWeights.fontNormal};
    background-color: transparent;
    border: none;
    transition: all 0.4s ease-in-out;

    &:hover,
    &:focus {
      background-color: ${theme.lightBlue};
      font-weight: ${theme.fontWeights.fontHeavy};
    }
  `,
);

const StyledNavBox = styled.div(
  ({ theme }) => css`
    width: 170px;
    border: 1px solid ${theme.lightGray};
    border-radius: 3px;
    .active-section {
      button {
        padding-left: 1rem;
        font-weight: ${theme.fontWeights.fontHeavy};
        border-left: 4px solid ${theme.primaryBlue};
      }
    }
    ul {
      margin-bottom: 0;
      list-style-type: none;
      li {
        margin: 0;
        padding: 0;
      }
    }
    @media (min-width: ${theme.screenSizes.laptop}) {
      width: 205px;
    }
  `,
);

const SectionNavigation = ({
  scrollOffsetTop = 0,
  sections,
  viewPortOffset,
}) => {
  // Scroll to the ref of that activeSection.
  const handleClick = activeSection => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        activeSection.ref.current.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        scrollOffsetTop,
    });
  };

  return (
    <StyledNavBox>
      <Scrollspy
        currentClassName="active-section"
        offset={viewPortOffset}
        items={sections.map(section => section.id)}
      >
        {sections.map((section, index) => (
          <li key={`${section.name}-${index}`}>
            <StyledNavButton
              id={`${section.name}-${index}`}
              className="py-3"
              onClick={e => {
                handleClick(section);
                e.target.blur();
              }}
              type="button"
            >
              {section.name}
            </StyledNavButton>
          </li>
        ))}
      </Scrollspy>
    </StyledNavBox>
  );
};

SectionNavigation.propTypes = {
  scrollOffsetTop: PropTypes.number,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  viewPortOffset: PropTypes.number,
};

SectionNavigation.defaultProps = {
  viewPortOffset: -400,
};

export default SectionNavigation;
