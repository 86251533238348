import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '@honeybadger-io/react';
import Honeybadger from 'honeybadger-js';
import { getHoneyBadgerConfig } from 'utilities';
import ErrorPage from './ErrorPage';

const ErrorWrapper = ({ context, children, customErrorPage }) => {
  const honeyBadgerConfig = getHoneyBadgerConfig();
  const honeybadger = Honeybadger.configure(honeyBadgerConfig);
  context && honeybadger.setContext(context);
  return (
    <ErrorBoundary
      honeybadger={honeybadger}
      ErrorComponent={customErrorPage || ErrorPage}
    >
      {children}
    </ErrorBoundary>
  );
};

ErrorWrapper.propTypes = {
  context: PropTypes.object,
  children: PropTypes.any,
  customErrorPage: PropTypes.elementType,
};

export default ErrorWrapper;
