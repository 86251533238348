import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import themed from 'components/hoc/themed';

class InputText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue || '',
      valid: true,
    };
    this.inputTextField = React.createRef();
  }

  handleOnChange = event => {
    let value = event.target.value;
    let valid = this.isValid(value);
    this.setState({
      value: value,
      valid: this.isValid(value),
    });
    if (this.props.handleOnChange) {
      this.props.handleOnChange(this.props.id, value, valid);
    }
  };

  isValid = value => {
    if (this.props.required) {
      return value && value.trim().length > 0;
    } else {
      return true;
    }
  };

  handleOnBlur = e => {
    this.setState({ isValid: this.isValid(e.target.value) });
  };

  render() {
    const {
      classes,
      containerClasses,
      disabled,
      id,
      name,
      label,
      required,
      size,
      hasError,
      type,
    } = this.props;
    const inputTextClassNames = classnames(
      'form-control',
      { validate: required },
      classes,
      {
        error: !this.state.valid,
      },
    );
    const containerClassNames = classnames(
      'input-text form-group col-xs-12',
      containerClasses,
      { 'col-md-4 col-lg-4': size === 'small' },
      { 'col-md-6 col-lg-6': size === 'medium' },
      { 'col-md-12 col-lg-12': size === 'large' },
      { error: hasError },
    );
    let inputProps = {
      id: id,
      type: type,
      name: name,
      className: inputTextClassNames,
      value: this.state.value,
      onChange: this.handleOnChange,
      onBlur: this.handleOnBlur,
      ref: this.inputTextField,
    };
    if (required) {
      inputProps.required = required;
    }
    if (disabled) {
      inputProps.disabled = disabled;
    }
    return (
      <div className={containerClassNames}>
        <label htmlFor={name}>
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
        <input {...inputProps} />
      </div>
    );
  }
}

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  containerClasses: PropTypes.string,
  classes: PropTypes.string,
  required: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func,
  defaultValue: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full-width']),
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  type: PropTypes.string,
};

InputText.defaultProps = {
  size: 'medium',
  disabled: false,
  type: 'text',
};

export default themed(InputText);
