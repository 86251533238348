import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  getAppointmentTypeOptions,
  getSchedulingPageDurationOptions,
  getSchedulingPageFutureDaysOptions,
} from './../supporting';
import { useErrorScroll } from './hooks';
import {
  formValuesToSchedulingPage,
  schedulingPageToFormValues,
  validateForm,
} from './supporting';
import InterviewSchedulingFormButtons from './InterviewSchedulingFormButtons';
import InterviewSchedulingPageAvailableHours from './InterviewSchedulingPageAvailableHours';
import RequestInterviewError from './RequestInterviewError';
import stored from 'components/hoc/stored';
import ScreenSizeContext from 'components/shared/ScreenSizeContext';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/FlexItem';
import Input from 'components/ui/inputs/Input';
import Select from 'components/ui/inputs/Select';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import withTooltip from 'components/ui/withTooltip';
import { INTERVIEW_SCHEDULING_PAGE_FORM_TYPES } from 'consts';
import { createSchedulingPage, updateSchedulingPage } from 'requests';

const Tooltip = withTooltip('div');
const TooltipContent = styled.div`
  min-width: 250px;
`;
const InputWrapper = styled.div`
  ${props =>
    props.smallWidth && !props.isSmallScreen ? 'max-width: 650px' : ''};
`;

const InterviewSchedulingPageForm = ({
  errorButtonText,
  formType,
  handleCancel,
  handleOptOut,
  handleSuccess,
  interviewSchedulingPage,
  secondaryButtonText,
  smallWidth,
}) => {
  // Consts
  const defaultInput = { startTime: '09:00', endTime: '17:00', error: null };
  const isEditing = formType === INTERVIEW_SCHEDULING_PAGE_FORM_TYPES.edit;
  const options = {
    appointmentType: getAppointmentTypeOptions(),
    duration: getSchedulingPageDurationOptions(),
    futureDays: getSchedulingPageFutureDaysOptions(),
  };
  const { isSmallScreen } = useContext(ScreenSizeContext);
  const defaultValue = isEditing ? [] : [defaultInput];
  // State
  const [submitting, setSubmitting] = useState(false);
  const [pageCreationError, setPageCreationError] = useState(false);
  const [formValues, setFormValues] = useState({
    name: { value: '', error: null },
    duration: { value: options.duration[1].key, error: null },
    interview_type: { value: options.appointmentType[0].key, error: null },
    available_days_in_future: { value: options.futureDays[1].key, error: null },
    opening_hours: {
      monday: { value: defaultValue },
      tuesday: { value: defaultValue },
      wednesday: { value: defaultValue },
      thursday: { value: defaultValue },
      friday: { value: defaultValue },
      error: null,
    },
  });
  const setValidSubmit = useErrorScroll();

  // If an interviewSchedulingPage is passed in we set the form values to it's values.
  // This is used when we are editing a scheduling page.
  useEffect(() => {
    if (interviewSchedulingPage) {
      setFormValues(schedulingPageToFormValues(interviewSchedulingPage));
    }
  }, [interviewSchedulingPage]);

  const onSubmit = saveAsNew => {
    if (validateForm(formValues, setFormValues, setValidSubmit)) {
      postPage(saveAsNew);
    }
  };

  // Create or update the scheduling page.
  const postPage = saveAsNew => {
    setSubmitting(true);
    setPageCreationError(false);
    const postMethod = saveAsNew ? createSchedulingPage : updateSchedulingPage;
    postMethod(formValuesToSchedulingPage(formValues))
      .then(response => {
        setSubmitting(false);
        handleSuccess(response.page);
      })
      .catch(() => {
        setPageCreationError(true);
        setSubmitting(false);
      });
  };

  // When a value in a form field changes - set the new value and clear out it's errors.
  const onValueChange = (newValue, fieldName, secondaryFieldName) => {
    setFormValues(prevState => {
      const valuePair = {
        value: newValue,
      };
      const newField = {
        [fieldName]: {
          ...(secondaryFieldName ? prevState[fieldName] : {}),
          ...(secondaryFieldName
            ? { [secondaryFieldName]: valuePair }
            : valuePair),
          error: null,
        },
      };
      return {
        ...prevState,
        ...newField,
      };
    });
  };

  const renderFields = children => (
    <Flex
      alignItems={isSmallScreen ? 'stretch' : null}
      direction={isSmallScreen ? 'column' : 'row'}
      gap={16}
    >
      {children}
    </Flex>
  );
  const renderField = children => (
    <FlexItem basis="45%" grow={1}>
      {children}
    </FlexItem>
  );

  const interviewTypeSelect = () => {
    return (
      <Select
        data-testid="appointment-type-disabled-select"
        disabled={isEditing}
        labelText="Type of interview"
        onChange={({ target: { value } }) =>
          onValueChange(value, 'interview_type')
        }
        value={formValues.interview_type.value}
      >
        {options.appointmentType}
      </Select>
    );
  };

  return (
    <form className="request-reschedule-form">
      <h1 className="mb-4">Set up my interview hours</h1>
      {pageCreationError && (
        <RequestInterviewError
          buttonText={errorButtonText}
          handleOptOut={handleOptOut}
          handleTryAgain={postPage}
        />
      )}
      {submitting && !pageCreationError && <LoadingOverlay />}
      {!pageCreationError && formValues.name && (
        <>
          <Flex direction="column" alignItems="stretch" gap={32}>
            <InputWrapper smallWidth={smallWidth} isSmallScreen={isSmallScreen}>
              <Flex direction="column" alignItems="stretch" gap={16}>
                {renderFields(
                  <>
                    {/* Name of meeting template */}
                    {renderField(
                      <>
                        <Input
                          data-testid="scheduling-page-name-input"
                          labelText="Name of meeting template"
                          onChange={({ target: { value } }) =>
                            onValueChange(value, 'name')
                          }
                          value={formValues.name.value}
                        />
                        {formValues.name.error && (
                          <ErrorMessage>{formValues.name.error}</ErrorMessage>
                        )}
                      </>,
                    )}
                    {/* Meeting length */}
                    {renderField(
                      <Select
                        data-testid="scheduling-page-duration-select"
                        labelText="Meeting length"
                        onChange={({ target: { value } }) =>
                          onValueChange(value, 'duration')
                        }
                        value={formValues.duration.value}
                      >
                        {options.duration}
                      </Select>,
                    )}
                  </>,
                )}
                {renderFields(
                  <>
                    {/* Interview type */}
                    {renderField(
                      <>
                        {isEditing && (
                          <Tooltip
                            title={
                              <TooltipContent>
                                The type of interview cannot change if a
                                schedule is in use.
                              </TooltipContent>
                            }
                          >
                            {interviewTypeSelect()}
                          </Tooltip>
                        )}
                        {!isEditing && interviewTypeSelect()}
                      </>,
                    )}
                    {/* How far out to schedule */}
                    {renderField(
                      <Select
                        data-testid="available-days-in-future-select"
                        labelText="How far out to schedule"
                        onChange={({ target: { value } }) =>
                          onValueChange(value, 'available_days_in_future')
                        }
                        value={formValues.available_days_in_future.value}
                      >
                        {options.futureDays}
                      </Select>,
                    )}
                  </>,
                )}

                {/* Available hours */}
                <InterviewSchedulingPageAvailableHours
                  onChange={(newValue, day) =>
                    onValueChange(newValue, 'opening_hours', day)
                  }
                  timeRanges={formValues.opening_hours}
                />
                {formValues.opening_hours.error && (
                  <ErrorMessage>{formValues.opening_hours.error}</ErrorMessage>
                )}
              </Flex>
            </InputWrapper>
            <InterviewSchedulingFormButtons
              cancelButtonText={secondaryButtonText}
              handleCancel={handleCancel}
              handleSubmit={onSubmit}
              id="scheduling-page-form-buttons"
              isEditing={isEditing}
            />
          </Flex>
        </>
      )}
    </form>
  );
};

InterviewSchedulingPageForm.propTypes = {
  appointmentType: PropTypes.oneOf(['phone', 'video']),
  errorButtonText: PropTypes.string,
  formType: PropTypes.oneOf([
    INTERVIEW_SCHEDULING_PAGE_FORM_TYPES.create,
    INTERVIEW_SCHEDULING_PAGE_FORM_TYPES.edit,
  ]).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOptOut: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  interviewSchedulingPage: PropTypes.object,
  secondaryButtonText: PropTypes.string,
  smallWidth: PropTypes.bool,
};

export default stored(InterviewSchedulingPageForm);
