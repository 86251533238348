import styled from 'styled-components';
import { colors, fonts, fontSizes, fontWeight, screenSize } from 'styleGuide';

export const StyledPreview = styled.div`
  padding-top: 40px;
  font-size: ${fontSizes.xs};
  line-height: 1.5;
  color: ${colors.gray.dark};
  p {
    margin: 1rem 0;
  }
  @media (max-width: ${screenSize.mobileL}) {
    font-size: 8px;
    h2 {
      font-size: ${fontSizes.md};
    }
  }
`;

export const Separator = styled.hr`
  padding: 0;
  border: 0;
  border-top: 2px solid ${colors.gray.light};
`;

export const SectionSubtitle = styled.h4`
  font-family: ${fonts.primary};
  font-size: ${fontSizes.base};
  @media (max-width: ${screenSize.mobileL}) {
    font-size: ${fontSizes.xs};
  }
`;

export const SmallSubtitle = styled.h5`
  font-family: ${fonts.primary};
  font-size: ${fontSizes.xs};
  line-height: 2.64;
  font-weight: ${fontWeight.fontMedium};
  @media (max-width: ${screenSize.mobileL}) {
    font-size: 5px;
  }
`;

export const ListItemCircle = styled.li`
  list-style: inside;
`;

export const SideSectionContainer = styled.div`
  margin-bottom: 40px;
  word-wrap: break-word;
  @media (max-width: ${screenSize.mobileL}) {
    margin-bottom: 20px;
  }
`;

export const ListItem = styled.li`
  position: relative;
  list-style: none;
  margin-bottom: 30px;
  ol,
  ul {
    padding-left: 10pt;
    margin: 0;
    white-space: normal;
  }
`;

export const DegreeTitle = styled.h5`
  font-family: ${fonts.primary};
  font-size: ${fontSizes.xs};
  line-height: 1.5;
  @media (max-width: ${screenSize.mobileL}) {
    font-size: 8px;
  }
`;

export const Description = styled.div`
  white-space: pre-wrap;
  u {
    text-decoration: underline;
  }
  b,
  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
  ul {
    list-style: disc;
  }
  ol,
  ul {
    padding-left: 1.15rem;
    margin: 0;
    margin-bottom: 1rem;
    white-space: normal;

    br {
      display: none;
    }
  }
`;
