import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import { StyledList, StyledNav } from './styles';
import { DOTS, calculatePaginationRange } from './calculatePagination';

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = useMemo(() => {
    return calculatePaginationRange(
      totalCount,
      pageSize,
      siblingCount,
      currentPage,
    );
  }, [totalCount, pageSize, siblingCount, currentPage]);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <StyledNav>
      <StyledList className="pagination">
        <li>
          {currentPage > 1 && (
            <ButtonLink
              id="pagination-prev"
              onClick={onPrevious}
              aria-label="Previous"
            >
              &lsaquo; Prev
            </ButtonLink>
          )}
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <li key={`page-dots-${index}`}>&#8230;</li>;
          } else if (pageNumber === currentPage) {
            return <li key={`page-${pageNumber}`}>{pageNumber}</li>;
          } else {
            return (
              <li key={`page-${pageNumber}`}>
                <ButtonLink
                  id={`pagination-${pageNumber}`}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </ButtonLink>
              </li>
            );
          }
        })}
        {currentPage !== lastPage && (
          <li>
            <ButtonLink id="pagination-next" aria-label="Next" onClick={onNext}>
              Next &rsaquo;
            </ButtonLink>
          </li>
        )}
      </StyledList>
    </StyledNav>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default Pagination;
