import React from 'react';

import Icon from '../Icon';

const XCircle = props => {
  return (
    <Icon title="Close" viewBox="0 0 20 20" {...props}>
      <line
        x1="7"
        y1="7"
        x2="13"
        y2="13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="13"
        y1="7"
        x2="7"
        y2="13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="none"
        stroke={props.color}
        strokeWidth="2"
      />
    </Icon>
  );
};

XCircle.propTypes = { ...Icon.propTypes };
XCircle.defaultProps = { ...Icon.defaultProps };

export default XCircle;
