import { css, keyframes } from 'styled-components';

export const colors = {
  warning: '#ffa800',
  error: '#e7204c',
  white: '#ffffff',
  black: '#000000',
  gray: {
    lightest: '#f3f3f4',
    lighter: '#e5e5e5',
    light: '#d6d3d8',
    medium: '#abadb3',
    mediumDark: '#8e9199',
    dark: '#464a59',
    darker: '#3d414f',
    darkest: '#2c3141',
  },
  gradient: {
    start: '#87adff',
    end: '#5573f2',
  },
  borderFocus: '#e6ebff',
  pink: {
    pale: '#ffd4d2',
    lightest: '#ffe0de',
    light: '#fff6f6',
    medium: '#e3b8b6',
  },
  blue: {
    primary: '#6685ff',
    secondary: '#1a48ff',
    light: '#f3f7ff',
    pale: '#b0eee3',
    dark: '#4f6de3',
    palePrimary: '#CFD4F5',
    navy: '#0B1C57',
  },
  cyan: {
    light: '#f3fcfc',
  },
  yellow: {
    light: '#FFF8EB',
    medium: '#fecc7a',
    dark: '#eab651',
  },
  green: {
    medium: '#189b84',
    dark: '#127a68',
    light: '#b0eee3',
    pale: '#f3fcfc',
  },
};

export const colorsRebrand = {
  white: '#fff',
  offwhite: '#fcfbf9',
  cream: '#f3f0ea',
  taupe: '#e5ddd2',
  oatmeal: '#e0d2bf',
  black: '#000',
  disabled: '#c2c3c7',
  lemon: '#fff84b',
  tangerine: {
    base: 'ef6e4a',
    pale: '#fff5f2',
    light: '#fec7b4',
    bold: '#b9471f',
    dark: '#99330e',
  },
  ocean: {
    base: '#3272e2',
    pale: '#deebff',
    light: '#accbfd',
    bold: '#0948b4',
    dark: '#003b9e',
  },
  navy: {
    base: '#0b1c57',
    pale: '#becae6',
    light: '#4b619f',
    bold: '#010e2e',
    dark: '#00091a',
  },
  emerald: {
    base: '#44a874',
    pale: '#f2fff3',
    light: '#bfeac7',
    bold: '#1e8941',
    dark: '#0f792a',
  },
  error: {
    base: '#f11630',
    pale: '#ffeeef',
    light: '#ffcfdc',
    bold: '#b0000e',
    dark: '#900008',
  },
  warning: {
    base: '#ffb818',
    pale: '#fff9ee',
    light: '#fff3d2',
    bold: '#c28300',
    dark: '#a36d00',
  },
};

export const screenSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletXS: '520px',
  tablet: '768px',
  laptopS: '991.98px', // temporary breakpoint to play along with bootstrap... for now 🔪
  laptop: '1024px',
  laptopM: '1305px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const screenSizeInt = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletXS: 520,
  tabletS: 600,
  tablet: 768,
  laptopS: 991.98,
  laptop: 1024,
  laptopM: 1305,
  laptopL: 1440,
  desktop: 2560,
};

export const mediaSetQueries = {
  mobile: { maxWidth: screenSizeInt.tabletS },
  mobileOrSmTablet: { maxWidth: screenSizeInt.tablet },
  mobileOrLaptopS: { maxWidth: screenSizeInt.laptopS },
  mobileOrLaptopM: { maxWidth: screenSizeInt.laptopM },
  mobileOrTablet: { maxWidth: screenSizeInt.laptop },
  tablet: { width: `${screenSizeInt.tabletS}..${screenSizeInt.laptop}` },
  desktop: { minWidth: screenSizeInt.laptop },
};

export const containerWidth = {
  default: '900px',
  medium: '600px',
  large: '1100px',
};

export const fontSizes = {
  base: '1rem', // 16px
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1.25rem', // 20px
  lg: '1.563rem', // 25px
  xl: '1.875rem', // 30px
  xxl: '2.5rem', // 40px
  xxxl: '3.75rem', // 60px
};

export const fontWeight = {
  fontLight: 300,
  fontNormal: 400,
  fontMedium: 500,
  fontBold: 500,
  fontBolder: 600,
  fontHeavy: 700,
};

const sansSerifFont = `'Poppins', sans-serif`;
const serifFont = `'austin-extrabold', serif`;
const gerstner = `gerstner-programm-fsl, sans-serif`;
const merriweather = `'Merriweather', serif`;

export const fonts = {
  primary: sansSerifFont,
  gerstner: gerstner,
  merriweather: merriweather,
  secondary: 'europa-reg',
  title: serifFont,
  header: {
    sm: css`
      font: 700 ${fontSizes.lg} / 1 ${serifFont};
      @media (max-width: ${screenSize.mobileL}) {
        font: 700 25px/1 ${serifFont};
      }
    `,
    md: css`
      font: 700 30px/1 ${serifFont};
      @media (max-width: ${screenSize.mobileL}) {
        font: 700 30px/1 ${serifFont};
      }
    `,
    lg: css`
      font: 700 40px/50px ${serifFont};
      @media (max-width: ${screenSize.mobileL}) {
        font: 700 30px/1 ${serifFont};
      }
    `,
    xl: css`
      font: 700 60px/1 ${serifFont};
      @media (max-width: ${screenSize.mobileL}) {
        font: 700 40px/1 ${serifFont};
      }
    `,
  },
  label: {
    boldBlack: css`
      font: ${fontWeight.fontHeavy} 16px/24px ${sansSerifFont};
      color: ${colors.black};
    `,
  },
};

export const boxPadding = {
  xs: '15px',
  sm: '20px 30px',
  md: '38px 40px',
  lg: '40px 30px',
};

export const directionDegree = {
  down: '90deg',
  left: '180deg',
  right: '0',
  up: '270deg',
};

const fullSpin = keyframes`{
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
`;

const spinningKeyframes = keyframes`
  50% {
    transform: rotate(45deg)
  }

  100% {
    transform: rotate(90deg)
  }
`;

const spinningAsyncKeyframes = keyframes`
  50% {
    transform: rotate(90deg)
  }

  100% {
    transform: rotate(180deg)
  }
`;

export const spinningAsyncAnimation = ({ time = '1.2s', type = 'ease' } = {}) =>
  css`
    animation: ${time} ${spinningAsyncKeyframes} infinite ${type};
  `;

export const spinningAnimation = ({ time = '1.2s', type = 'ease' } = {}) =>
  css`
    animation: ${time} ${spinningKeyframes} infinite ${type};
  `;

export const fullSpinAnimation = ({ time = '1s', type = 'linear' } = {}) =>
  css`
    animation: ${time} ${fullSpin} infinite ${type};
  `;

export const flexOptions = [
  'flex-start',
  'flex-end',
  'center',
  'baseline',
  'space-between',
  'space-around',
  'stretch',
];

export const flexContentAlignOptions = [...flexOptions].filter(
  o => o !== 'baseline',
);

export const withUnitsRecursive = (obj, unitType) => {
  const units = unitType || 'px';
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      obj[key] = withUnitsRecursive(obj[key]);
    } else {
      obj[key] = `${obj[key]}${units}`;
    }
  });
  return obj;
};
