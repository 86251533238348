import styled from 'styled-components';
import PropTypes from 'prop-types';

import { boxPadding } from 'styleGuide';

export const Box = styled.div(
  ({ theme, size = 'lg', highlight = false, ...props }) => `
      background-color: ${props.backgroundColor || theme.white};
      border-radius: 5px;
      ${props.withBorder ? `border-top: 8px solid ${theme.primaryBlue};` : ''}
      box-shadow: ${
        highlight
          ? `0px 1px 4px 2px ${theme.primaryBlue.alpha(0.25)}`
          : theme.boxShadow100
      };
      margin-bottom: 10px;
      padding: ${props.padding || boxPadding[size]};
      position: relative;
    `,
);

Box.propTypes = {
  backgroundColor: PropTypes.any,
  children: PropTypes.any,
  classes: PropTypes.string,
  padding: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  withBorder: PropTypes.bool,
  highlight: PropTypes.bool,
};
