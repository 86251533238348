import React from 'react';
import PropTypes from 'prop-types';
import ReactiveRecord, { Member } from 'reactiverecord';

const CurrentUserModel = ReactiveRecord.model('CurrentUser');

let requestedAtLeastOnce = false;
let loadedAtLeastOnce = false;

const CurrentUser = ({ children, ...props }) => {
  return (
    <Member for={CurrentUserModel} fetch={!requestedAtLeastOnce} {...props}>
      {currentUser => {
        requestedAtLeastOnce = true;
        if (/20[0-2]/.test(currentUser._request.status)) {
          loadedAtLeastOnce = true;
        }
        return children(currentUser, loadedAtLeastOnce);
      }}
    </Member>
  );
};

CurrentUser.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CurrentUser;
