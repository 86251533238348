import React from 'react';

export default function Warning(props) {
  return (
    <svg viewBox="0 0 26 22" {...props}>
      <path
        d="M12.15,1.59,1.26,19.36a1,1,0,0,0,.86,1.53H23.88a1,1,0,0,0,.86-1.53L13.85,1.59A1,1,0,0,0,12.15,1.59Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
      <line
        x1="13"
        y1="8"
        x2="13"
        y2="14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="13" cy="17" r="1" fill="currentColor" />
    </svg>
  );
}
