import Quill from 'quill/dist/quill';

export const toolbarOptions = [
  [{ size: ['large', false] }],
  ['bold', 'italic', { list: 'bullet' }],
];
export const formatOptions = ['size', 'bold', 'italic', 'list'];

export default class QuillEditor {
  constructor(
    targetId = '#editor',
    toolbar = toolbarOptions,
    format = formatOptions,
  ) {
    this.editor = new Quill(targetId, this.getOptions(toolbar, format));
  }

  getOptions(toolbar, formats) {
    const bindings = {
      enter: {
        key: 13,
        format: ['size'],
        handler: () => {
          const range = this.editor.getSelection(true);
          this.editor.insertText(range.index, '\n');
        },
      },
    };

    return {
      modules: {
        keyboard: { bindings },
        toolbar,
      },
      formats,
      theme: 'snow',
    };
  }

  on(event, callback) {
    this.editor.on(event, callback);
  }

  getLength() {
    return this.editor.getLength();
  }

  getText() {
    return this.editor.getText().trim();
  }

  getCharsRemaining(maxLength) {
    return maxLength - this.getLength() + 1;
  }

  getCharCount() {
    return this.getLength() - 1;
  }

  deleteText(maxLength) {
    this.editor.deleteText(maxLength, this.editor.getLength());
  }

  getHTML() {
    return this.editor.root.innerHTML;
  }

  setContents(value, type = 'silent') {
    const delta = this.editor.clipboard.convert({ html: value });
    this.editor.setContents(delta, type);
  }
}
