import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/ui/inputs/Checkbox';
import Radio from 'components/ui/inputs/Radio';
import TextArea from 'components/ui/inputs/TextArea';

const ElementByCondition = ({ element, labelGroup, radioGroup }) => {
  const elementName = element['element_name'];
  const elementPlaceholder = element['element_placeholder'];
  const elementText = element['element_text'];
  const elementType = element['element_type'];
  const elementValue = element['element_value'];
  const inlineRadioStyles = labelGroup ? 'inline-elements' : '';
  const labelBefore =
    labelGroup.length > 0 ? labelGroup[0]['element_text'] : false;
  const labelAfter =
    labelGroup.length > 0 ? labelGroup[1]['element_text'] : false;

  return (
    <>
      {elementType === 'label' && <label>{elementText}</label>}
      {elementType.includes(
        'label_before_radio',
        'label_after_radio',
        'input_radio',
      ) && (
        <div className="radio-fieldset">
          {labelBefore && (
            <label className="label_before small">{labelBefore}</label>
          )}
          <div className={`text-center ${inlineRadioStyles}`}>
            {radioGroup.map((radio, i) => {
              return (
                <Radio
                  className="response-element"
                  name={radio['element_name']}
                  labelText={radio['element_text']}
                  value={radio['element_value']}
                  key={`radio${i}`}
                />
              );
            })}
          </div>
          {labelAfter && (
            <label className="label_after text-right small">{labelAfter}</label>
          )}
        </div>
      )}
      {elementType === 'input_radio' && !labelGroup && (
        <Radio
          className="response-element"
          name={elementName}
          labelText={elementText}
          value={elementValue}
        />
      )}
      {elementType === 'input_checkbox' && (
        <Checkbox
          className="response-element"
          name={elementName}
          labelText={elementText}
          value={elementValue}
        />
      )}
      {elementType === 'textarea' && (
        <TextArea
          className="response-element mb-4"
          name={elementName}
          placeholder={elementPlaceholder}
          minRows={2}
        />
      )}
    </>
  );
};

ElementByCondition.propTypes = {
  element: PropTypes.object,
  labelGroup: PropTypes.array,
  radioGroup: PropTypes.array,
};

export default ElementByCondition;
