import React from 'react';
import PropTypes from 'prop-types';

const DisplayLoop = props => {
  return (
    <div
      css={`
        display: inline-block;
        margin-right: 14px;
        margin-bottom: 14px;
        border: 1px solid ${({ theme }) => theme.lightGray};
        padding: 12px 20px;
        border-radius: 30px;
      `}
    >
      {props.children}
    </div>
  );
};

DisplayLoop.propTypes = {
  children: PropTypes.any.isRequired,
};

export default DisplayLoop;
