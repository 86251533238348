import React from 'react';
import PropTypes from 'prop-types';
import ProjectExpiration from 'components/employer/projects/ProjectSummary/ProjectExpiration';
import Badge from 'components/ui/Badge';
import RecommendedBadge from './RecommendedBadge';

const ProjectBadge = ({
  activatedRecently,
  expirationLabel,
  isInvited,
  isMatch,
  talentDisplay,
  updatedRecently,
}) => {
  let badgeText;
  if (activatedRecently || updatedRecently) {
    badgeText = activatedRecently ? 'New' : 'Updated';
  }
  return (
    <div className="d-flex mt-2 align-items-center">
      {badgeText && <Badge className="mr-2">{badgeText}</Badge>}
      {isMatch && <RecommendedBadge />}
      {isInvited && (
        <Badge className="ml-2" color="pink" variant="pink">
          Invited to apply
        </Badge>
      )}
      {talentDisplay && !!expirationLabel && (
        <ProjectExpiration expirationLabel={expirationLabel} />
      )}
    </div>
  );
};

ProjectBadge.propTypes = {
  activatedRecently: PropTypes.bool,
  expirationLabel: PropTypes.string,
  isInvited: PropTypes.bool,
  isMatch: PropTypes.bool,
  talentDisplay: PropTypes.bool,
  updatedRecently: PropTypes.bool,
};

export default ProjectBadge;
