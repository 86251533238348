import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import stored from 'components/hoc/stored';
import { useMediaSet } from 'use-media-set';

import { ANALYTICS, URLS } from 'consts';
import { queryStringToObj } from 'utilities';
import { Layout } from 'components/containers/shared/Layout';
import AnalyticsProvider from 'components/shared/analytics/AnalyticsProvider';
import UpcomingInterviews from 'components/employer/interviews/UpcomingInterviews';
import RescheduleRequests from 'components/employer/interviews/RescheduleRequests';
import PastInterviews from 'components/employer/interviews/PastInterviews';
import InterviewContext from 'components/employer/interviews/InterviewContext';
import InterviewSchedulingPageAccountLoading from 'components/employer/interviews/RequestInterview/InterviewSchedulingPageAccountLoading.js';
import withErrorBoundary from 'components/hoc/withErrorBoundary';
import Pencil from 'components/ui/icons/Pencil';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import { mediaSetQueries } from 'styleGuide';
import CalendarSyncModal from 'containers/employer_application/CalendarSyncModal.js';
import Dialog from 'components/ui/Dialog';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
});

const Interviews = props => {
  const [interviews, dispatch] = useInterviewReducer(
    props.upcoming_appointments.data
      .concat(props.pending_appointments.data)
      .concat(props.pending_withdrawn_appointments.data),
    props.reschedule_requests.data,
  );
  const mediaStates = useMediaSet(mediaSetQueries);
  const [showCalendarSyncModal, setShowCalendarSyncModal] = useState(false);
  const [showCoffeeModal, setShowCoffeeModal] = useState(
    queryStringToObj(location.search).showCoffeeModal || false,
  );

  const handleManageSchedulingPages = () => {
    if (props.user.data.attributes.is_nylas_user) {
      window.location = URLS.schedulingPages;
    } else {
      setShowCalendarSyncModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowCalendarSyncModal(false);
    setShowCoffeeModal(false);
  };

  return (
    <Layout title="Interviews">
      <QueryClientProvider client={queryClient}>
        <AnalyticsProvider
          user={props.employer.data.attributes}
          category="Manage Applicants"
          userType={ANALYTICS.USER_TYPES.Employer}
        >
          <InterviewContext.Provider value={dispatch}>
            <Dialog
              show={showCalendarSyncModal || showCoffeeModal}
              onHide={handleCloseModal}
              autoFocusBehavior={false}
              large
            >
              {showCalendarSyncModal && (
                <CalendarSyncModal
                  user={{
                    id: props.user.data.id,
                    email: props.user.data.attributes.email,
                  }}
                  from="Interviews"
                  handleClose={handleCloseModal}
                />
              )}
              {showCoffeeModal && (
                <InterviewSchedulingPageAccountLoading
                  handleAccountSynced={() => {
                    window.location = URLS.schedulingPages;
                  }}
                  handleOptOut={handleCloseModal}
                />
              )}
            </Dialog>
            <div className="sop-container mt-4 d-flex justify-content-end">
              <ButtonLink
                className={!mediaStates.has('desktop') && 'mr-2'}
                data-id="edit-scheduling-pages"
                onClick={handleManageSchedulingPages}
                inline={mediaStates.has('desktop')}
              >
                <Pencil width="18px" height="18px" />
                <span
                  className="ml-1"
                  data-id="manage-scheduling-pages-link"
                  data-testid="manage-scheduling-pages-link"
                >
                  Set up my interview hours
                </span>
              </ButtonLink>
            </div>
            {interviews.reschedules.length > 0 && (
              <RescheduleRequests
                reschedule_requests={interviews.reschedules}
              />
            )}
            <UpcomingInterviews upcomingInterviews={interviews.upcoming} />
            {props.past_appointments.data.length > 0 && (
              <PastInterviews pastInverviews={props.past_appointments.data} />
            )}
          </InterviewContext.Provider>
        </AnalyticsProvider>
      </QueryClientProvider>
    </Layout>
  );
};

const useInterviewReducer = (upcoming, reschedules) => {
  const [interviews, dispatch] = useReducer(interviewStateReducer, {
    upcoming,
    reschedules,
  });
  function interviewStateReducer(state, action) {
    const reduceReschedule = value => {
      if (value.id === action.interview.id) {
        value.attributes.is_confirmed = false;
      }
      return value;
    };
    switch (action.type) {
      case 'reschedule':
        return { ...state, upcoming: state.upcoming.map(reduceReschedule) };
      case 'reschedule_by_talent':
        action.interview.attributes.is_confirmed = false;
        return {
          ...state,
          reschedules: state.reschedules.filter(
            value => value.id !== action.interview.id,
          ),
          upcoming: state.upcoming.concat([action.interview]),
        };
      case 'cancel':
        return {
          ...state,
          upcoming: state.upcoming.filter(
            value => value.id !== action.interview.id,
          ),
        };
      default:
        throw new Error('Action not supported');
    }
  }
  return [interviews, dispatch];
};

Interviews.propTypes = {
  mode: PropTypes.oneOf(['employer', 'admin']),
  employer: PropTypes.object.isRequired,
  upcoming_appointments: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  pending_appointments: PropTypes.object,
  pending_withdrawn_appointments: PropTypes.object,
  reschedule_requests: PropTypes.object,
  past_appointments: PropTypes.object,
};

function InterviewsWrapper(props) {
  const component = () => <Interviews {...props} />;
  const context = {
    employerId: props.employer.id,
  };
  return withErrorBoundary(component, context);
}

export default stored(InterviewsWrapper);
