import React from 'react';
import VideoError from './VideoError';

const VideoPermissionError = () => {
  return (
    <VideoError>
      <>
        <h4>
          It looks like you have blocked this page from accessing your video
          and/or microphone.
        </h4>
        <p>
          In order to continue with your video interview, you must enable your
          video in the browser and then refresh this page.
        </p>
      </>
    </VideoError>
  );
};

export default VideoPermissionError;
