import React from 'react';
import ReactiveRecord from 'reactiverecord';
import PropTypes from 'prop-types';
import DeleteResume from './DeleteResume';
import RenameResume from './RenameResume';
import DownloadResume from './DownloadResume';
import ReplaceResume from './ReplaceResume';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import resumeThumbnail from 'assets/illustrations/resume-thumbnail.svg';

const ResumeFilePreview = styled.div`
  width: 12.5rem;
  height: 15.6rem;
`;

const StyledRow = styled(Row)(
  ({ theme }) => `
    max-width: 40rem;
    background-color: ${theme.lightBlue};
    button {
      display: block;
      line-height: 2;
    }
  `,
);

const Resume = ReactiveRecord.model('ResumeLatest');

const ManageResume = props => {
  const resumePreviewPath = props.resume.thumb_url;
  const placeholderImageText =
    'Preview only supported in PDF format. If this is your first time here, you may need to upload a new resume to see the thumbnail preview.';

  return (
    <>
      {props.resume.bids_present && (
        <p>
          This is the resume you applied with on your last bid. You can replace
          or delete it below.
        </p>
      )}
      <StyledRow className="py-4 px-2 m-0">
        <Col col={12} md={4} className="mb-4 mb-md-0 mr-md-2">
          <ResumeFilePreview className="text-center m-auto">
            {resumePreviewPath && (
              <img
                src={resumePreviewPath}
                alt="Resume Thumbnail"
                width="100%"
                data-testid="resume-thumbnail"
              />
            )}
            {!resumePreviewPath && (
              <img
                src={resumeThumbnail}
                alt={placeholderImageText}
                title={placeholderImageText}
                data-testid="resume-placeholder-thumbnail"
              />
            )}
          </ResumeFilePreview>
        </Col>
        <Col col={12} md={7} className="text-md-left text-center pl-md-4">
          <p className="h5 mt-2 text-break">{props.resume.safe_display_name}</p>
          <div className="d-inline-block">
            <DeleteResume
              resume={props.resume}
              handleRemove={props.handleRemove}
            />
            <RenameResume resume={props.resume} />
            <DownloadResume resume={props.resume} />
          </div>
          <hr className="my-4" />
          <div className="d-inline-block">
            <ReplaceResume
              resume={props.resume}
              handleChange={props.handleChange}
            />
          </div>
        </Col>
      </StyledRow>
    </>
  );
};

ManageResume.propTypes = {
  handleChange: PropTypes.func,
  handleRemove: PropTypes.func,
  resume: PropTypes.instanceOf(Resume).isRequired,
};

export default ManageResume;
