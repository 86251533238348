import React from 'react';

import Icon from '../Icon';

const Trashcan = props => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#a)">
        <path
          d="m7.825 4.168v-2.08c0-1.153.935-2.088 2.087-2.088h4.176c1.152 0 2.087.935 2.087 2.088v2.08h4.176l-.001.007h2.088c.576 0 1.043.468 1.043 1.044s-.467 1.044-1.043 1.044h-2.309l-1.678 15.857c-.107 1.068-1.005 1.88-2.078 1.88h-8.746c-1.073 0-1.972-.812-2.078-1.88l-1.678-15.857h-2.309c-.576 0-1.043-.468-1.043-1.044s.467-1.044 1.043-1.044h2.088l-.001-.007zm2.087 0h4.176v-2.08h-4.176zm6.125 2.095-10.058.033 1.655 15.656 8.737-.04 1.664-15.649h-1.86zm-5.603 13.545c-.288 0-.522-.234-.522-.522v-10.437c0-.288.234-.522.522-.522s.522.234.522.522v10.437c0 .288-.234.522-.522.522zm2.609-.522c0 .288.234.522.522.522s.522-.234.522-.522v-10.437c0-.288-.234-.522-.522-.522s-.522.234-.522.522z"
          fill={props.color}
          fillRule="evenodd"
        />
      </g>
    </Icon>
  );
};

Trashcan.propTypes = { ...Icon.propTypes };
Trashcan.defaultProps = { ...Icon.defaultProps };

export default Trashcan;
