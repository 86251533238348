import styled from 'styled-components';
import { getInputSharedStyles } from 'components/ui/inputs/InputSharedStyles';
import { CheckboxContainer } from 'components/ui/inputs/Checkbox';

export const MultiSelectWrapper = styled.div`
  .multiselect-container {
    position: relative;
    background-color: ${({ theme }) => theme.lightBlue}
    border-radius: 5px;
    
    .caret {
      cursor: pointer;
      position: absolute;
      right: 12px;
      margin-top: 1rem;
  
      color: ${({ theme }) => theme.primaryBlue};
    }
  
    .counter {
      color: white;
      cursor: pointer;
      position: absolute;
      right: 37px;
      margin-top: 8px;
      font-weight: 700;
      font-size: 0.875rem;
      background-color: ${({ theme }) => theme.companyLogoColorBlue};
      border-radius: 20px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .multiselect-input{
      ${getInputSharedStyles()}
      border-radius: 6px;
      padding: 11px 15px;
      font-weight: 400;
      
      &.opened {
        border: 1px solid ${({ theme }) => theme.primaryBlue};
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        -goog-ms-box-shadow: none;
        outline: 0;
      }
      
      &.input-error {
        border: 1px solid rgba(231,32,76,1);
      }
    }
    
    .multiselect-hidden-input{
      visibility: hidden;
      display: none;
    }
    
    .list-wrapper{
      position: absolute;
      width: 100%;
      z-index: 2;
    }
    
    .multiselect-list {
      padding-top: 1rem;
      box-sizing: border-box;
      overflow: scroll;
      overflow-x: hidden;
      background-color: white;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
    
      -webkit-overflow-scrolling: touch;
      z-index: 206; /* Current Rails dialog is set to 205 */
      border-radius: 6px;
    }
  
    .multiselect-list-item {
      display: block;
      padding: 0px 15px;
      color: ${({ theme }) => theme.darkGray};
      cursor: pointer;
      
      &.no-results {
        padding-bottom: 1rem;
        font-style: italic;
      }
    
      ${CheckboxContainer} {
        margin-bottom: 0;
      }
    
      label {
        cursor: pointer;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    
        &:before {
          height: 20px;
          width: 20px;
          border: 2px solid ${({ theme }) => theme.darkGray};       
        }
    
        &:after {
          height: 8px;
          width: 13px;
          border-left: 2px solid;
          border-bottom: 2px solid;
          left: 3.4px;
          top: 4px;
        }
      }
    }
  }
  
  .pill-label-container {
    line-height: 38px;
  
    &.with-values {
      padding: 0.75em 0 0;
    }

    .pill-label {
      position: relative;
      box-sizing: content-box;
      display: inline-block;
      padding: 9px 50px 9px 24px;
      margin-right: 1rem;
      line-height: 16px;
      color: ${({ theme }) => theme.darkGray};
      vertical-align: top;
      background-color: white;
      border-radius: 25px;
      border: 1px solid ${({ theme }) => theme.darkGray};
      cursor: pointer;
      margin-bottom: 0.5rem;
    
      font-size: 0.875rem;
    
      &.selected {
        font-weight: 700;
        color: white;
        background-color: ${({ theme }) => theme.darkGray};
        border: 1px solid ${({ theme }) => theme.darkGray};
      }
    
      .label-icon {
        .x-icon {
          position: absolute;
          top: 2px;
          right: 17px;
          width: 30px;
          height: 29px;
          color: white;
        }
        .plus-icon {
          position: absolute;
          top: 2px;
          right: 17px;
          width: 30px;
          height: 29px;
          color: ${({ theme }) => theme.darkGray};
        }
      }
    }   
  }  
`;

export function denormalize(obj) {
  return Object.keys(obj);
}
