import styled from 'styled-components';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import rightCaret from 'assets/icons/right-caret.svg';
import leftCaret from 'assets/icons/left-caret.svg';

import { colors, fonts, fontSizes, fontWeight } from 'styleGuide';

const buttonHeight = {
  xs: 'auto',
  sm: '44px',
  md: '50px',
  lg: '64px',
  xl: '64px',
};

const buttonPadding = {
  xs: '0.25rem 0.3rem',
  sm: '0.5rem 1rem',
  md: '0.85rem 1rem',
  lg: '1rem 1.25rem',
  xl: '0.85rem 2rem',
};

const Button = styled.button(props => ` ${getSharedButtonStyles(props)} `);

export const getSharedButtonStyles = props => {
  const clearBg = props.iconOnly || props.link;
  return `
    ${getBlockStyles(props)}
    ${getCaretStyles(props.caret, clearBg)}
    ${getShadowStyles(props)};
    background-color: ${getBackgroundColor(props, clearBg)};
    border-radius: ${props.iconOnly ? '50%' : '5px'};
    border: none;
    box-sizing: border-box;
    color: ${getColorStyles(props, clearBg)};
    font-family: ${fonts.primary};
    font-size: ${
      props.inline
        ? 'inherit'
        : props.size === 'xs'
        ? fontSizes.sm
        : fontSizes.base
    };
    font-weight: ${
      props.inline && !props.link ? 'inherit' : fontWeight.fontHeavy
    };
    margin: 0;
    padding: ${getPaddingStyles(props)};
    transition: all 0.3s ease-in;
    text-decoration: none;
    white-space: nowrap;
    ${props.css ? props.css : ''};
    &[disabled] {
      background-color: ${clearBg ? colors.white : colors.gray.medium};
      color: ${clearBg ? colors.gray.medium : colors.white};
      cursor: default;
    }
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      ${getHoverStyles(props, clearBg)}
      transition-timing-function: ease-out;
    }
    &:not([disabled]) {
      cursor: pointer;
    }
  `;
};

const getBackgroundColor = (props, clearBg) => {
  if (props.secondary) {
    return colors.white;
  } else if (clearBg) {
    return 'transparent';
  } else if (props.dark) {
    return colors.gray.dark;
  } else if (props.color) {
    return props.color;
  } else {
    return colors.blue.primary;
  }
};

const getBlockStyles = props => {
  if (props.size !== 'xs' && props.size !== 'sm') {
    if (props.size === 'xl') {
      return `min-width: 225px;`;
    } else if (props.block) {
      return `
        display: block;
        width: 100%;
      `;
    } else if (props.iconOnly || props.inline) {
      return `width: auto;`;
    } else {
      return `min-width: 150px;`;
    }
  }
  return '';
};

const getCaretStyles = (caret, clearBg) => {
  if (caret) {
    const getCaret = caret === 'right' ? rightCaret : leftCaret;
    const sharedStyles = `
      background-image: url(${getCaret});
      content: '';
      display: inline-block;
      height: 1em;
      margin-bottom: -0.175em;
      width: 1em;
      ${!clearBg && 'filter: brightness(100);'}
    `;
    switch (caret) {
      case 'left':
        return `
          padding-left: 1em !important;
          &::before {
            ${sharedStyles}
            margin-right: 5px;
          }
          `;
      case 'right':
        return `
          padding-right: 1em !important;
          &::after {
            ${sharedStyles}
            margin-left: 5px;
          }
        `;
    }
  } else {
    return '';
  }
};

const getColorStyles = (props, clearBg) => {
  if (props.color && props.link) {
    return props.color;
  } else if (props.secondary || clearBg) {
    return colors.blue.primary;
  } else {
    return 'white';
  }
};

const getHoverStyles = (props, clearBg) => {
  if (clearBg) {
    return `
      color: ${props.color ? darken(0.25, props.color) : colors.blue.secondary};
      background-color: ${
        props.inline ? 'transparent' : colors.blue.primary + '2b'
      };
      `;
  } else {
    return `
      background-color: ${
        props.secondary
          ? colors.blue.light
          : props.color
          ? darken(0.15, props.color)
          : colors.blue.dark
      };
    `;
  }
};

const getPaddingStyles = props => {
  if (props.inline) {
    return 0;
  } else {
    return buttonPadding[props.size];
  }
};

// TODO: Change this to use border instead of box-shadow
const getShadowStyles = props => {
  if (props.secondary && !props.onDark && !props.noBorder) {
    return `box-shadow: inset 0 0 0 2px !important;`;
  }
  return '';
};

Button.propTypes = {
  block: PropTypes.bool,
  caret: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
  color: PropTypes.string,
  iconOnly: PropTypes.bool,
  inline: PropTypes.bool,
  link: PropTypes.bool,
  noBorder: PropTypes.bool, // Allows for buttons with no border (used in forms)
  dark: PropTypes.bool, // Use when rendering on a dark background, usually purple.
  secondary: PropTypes.bool, // White bordered look
  size: PropTypes.oneOf(Object.keys(buttonHeight)),
};

Button.defaultProps = {
  iconOnly: false,
  link: false,
  size: 'md',
};

export default Button;
