import React from 'react';
import styled from 'styled-components';
import Portal from './Portal';
import PropTypes from 'prop-types';
import { loaderSizes } from './Loader';
import LoadingSpinner from './LoadingSpinner';

const loaderSize = 'lg';
const LoadingOverlay = ({ children }) => (
  <Portal>
    <LoadingWrapper>
      <div
        className="d-flex flex-column"
        css={`
          left: calc(50% - ${loaderSizes[loaderSize]} / 2);
          top: calc(50% - ${loaderSizes[loaderSize]} / 2);
          position: absolute;
        `}
      >
        <LoadingSpinner
          id="loader"
          aria-label="loading"
          data-testid="loading-spinner"
          color="primary"
          width="50"
          height="50"
        />
        <LoadingText>{children}</LoadingText>
      </div>
    </LoadingWrapper>
  </Portal>
);

const LoadingWrapper = styled.div`
  background: ${({ theme }) => theme.darkGray};
  opacity: 0.8;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* The class .fixed-bottom has z-index 1030.
     Increasing the z-index of LoadingWrapper to prevent the .fixed-bottom elements to be shown. */
  z-index: 1031;
`;

const LoadingText = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.white};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6.25rem;
  text-align: center;
`;

LoadingOverlay.propTypes = {
  children: PropTypes.any,
};

export default LoadingOverlay;
