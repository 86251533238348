import React from 'react';
import PropTypes from 'prop-types';

const StarOutline = ({ color, height, width, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 36"
      height={height}
      width={width}
      {...props}
    >
      <g fill="none" fillRule="evenodd" strokeLinecap="square">
        <g stroke={color}>
          <path
            d="M38.595 13.177l-12.2-1.705c-.258-.036-.48-.191-.595-.415L20.344.422c-.29-.564-1.127-.564-1.417 0l-5.455 10.633c-.115.225-.34.38-.596.416l-12.2 1.705c-.647.09-.906.856-.436 1.296l8.827 8.277c.186.175.271.425.227.672L7.209 35.11c-.11.622.568 1.095 1.147.802l10.912-5.518c.23-.116.505-.116.736 0l10.911 5.518c.58.293 1.257-.18 1.146-.802l-2.083-11.687c-.045-.247.04-.497.227-.672l8.828-8.277c.468-.44.21-1.206-.438-1.296"
            transform="translate(-565 -42) translate(250) translate(315.364 42)"
          />
        </g>
      </g>
    </svg>
  );
};

StarOutline.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

StarOutline.defaultProps = {
  color: 'currentColor',
  height: 36,
  width: 40,
};

export default StarOutline;
