import React from 'react';
import Icon from 'components/ui/Icon';
import publicTheme from 'publicTheme';

export default function VideoOff(props) {
  return (
    <Icon viewBox="0 0 46 46" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-932 -181) translate(932 181)">
            <circle cx="23" cy="23" r="23" fill={publicTheme.red.toString()} />
            <path
              stroke="#FFF"
              d="M29.572 20.08l5.881-3.839c.463-.302 1.083-.171 1.384.291.107.163.163.353.163.547V30.33c0 .552-.448 1-1 1-.164 0-.326-.04-.47-.118l-5.958-3.18h0V20.08z"
            />
            <rect
              width="20"
              height="18"
              x="9.5"
              y="14.5"
              stroke="#FFF"
              rx="2"
            />
            <path stroke="#FFF" strokeLinecap="square" d="M11.5 11.5L35 35" />
          </g>
        </g>
      </g>
    </Icon>
  );
}

VideoOff.propTypes = { ...Icon.propTypes };
