import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import RadioGroup from 'components/ui/formik/RadioGroup';
import Button from 'components/ui/buttons/Button';
import { projectCancelReasonsValidations } from 'validationSchemas/Project';
import { Field, Form, Formik } from 'formik';
import { useCloseProjectMutation } from 'requests';
import { useQueryClient } from '@tanstack/react-query';
import { showMessage } from 'utilities';
import { setFieldErrors } from 'utilities/formik';

const CloseProjectForm = ({ cancelReasons, onCancel, onCloseJob, slug }) => {
  const mutation = useCloseProjectMutation(slug);
  const queryClient = useQueryClient();

  const initialValues = {
    reason: '',
  };

  // handleRadioChange is called when the user selects a close reason.
  // These values will be set in the project's `removal_request` NVP.value field for data querying.
  const handleRadioChange = (e, setValues) => {
    const reasonValue = e.target.value;

    setValues({
      reason: reasonValue,
    });
  };

  const submit = (values, formikHelpers) => {
    mutation.mutate(values, {
      onError: ({ errors }) => setFieldErrors(errors, formikHelpers),
      onSuccess: () => {
        onCancel();
        queryClient.invalidateQueries(['projects']);
        onCloseJob(values);
      },
    });
  };

  if (mutation.isError) {
    showMessage('An error has occurred', true);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={projectCancelReasonsValidations}
      className="remove-project-modal-content px-4"
    >
      {({ values, setValues, setTouched, handleSubmit }) => {
        return (
          <>
            {mutation.isLoading && <LoadingOverlay />}
            <Form onSubmit={handleSubmit}>
              <h1 className="fancy mb-4">
                Please tell us why you&apos;re closing this job
              </h1>
              <Field
                as={RadioGroup}
                id="project_cancel_reasons"
                name="project_cancel_reasons"
                onChange={e => handleRadioChange(e, setValues, setTouched)}
                values={cancelReasons}
              />
              <div className="text-right">
                <Button
                  className="mr-4"
                  data-id="remove-job-btn"
                  data-testid="remove-job-button"
                  onClick={onCancel}
                  size="md"
                  type="button"
                  secondary
                >
                  Cancel
                </Button>
                <Button
                  data-id="remove-job-btn"
                  data-testid="remove-job-button"
                  disabled={!values.reason}
                  size="md"
                  type="submit"
                >
                  Close job
                </Button>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

CloseProjectForm.propTypes = {
  cancelReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onCloseJob: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
};

export default CloseProjectForm;
