import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { flexOptions } from 'styleGuide';

const getStyles = props => {
  let styles = '';
  ['align', 'justify'].forEach(type => {
    if (props[type]) {
      styles += `
        ${type}-self: ${props[type]};
      `;
    }
  });
  ['basis', 'grow', 'shrink'].forEach(type => {
    if (props[type]) {
      styles += `
        flex-${type}: ${props[type]};
      `;
    }
  });
  ['display', 'order'].forEach(type => {
    if (props[type]) {
      styles += `
        ${type}: ${props[type]};
      `;
    }
  });
  return styles;
};

const StyledFlexItem = styled.div(
  props => css`
    ${getStyles(props)}
  `,
);

const FlexItem = props => {
  return <StyledFlexItem className={props.className} {...props} />;
};

FlexItem.propTypes = {
  align: PropTypes.oneOf(flexOptions),
  basis: PropTypes.string,
  className: PropTypes.string,
  display: PropTypes.oneOf(['block', 'flex', 'inline-block', 'inline-flex']),
  grow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  justify: PropTypes.oneOf(flexOptions),
  order: PropTypes.number,
  shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FlexItem.defaultValues = {
  basis: 'auto',
  className: '',
  display: 'block',
  grow: 0,
  shrink: 1,
};

export default FlexItem;
