import styled from 'styled-components';

const ButtonLink = styled.button(
  ({ theme }) => `
  color: ${theme.primaryBlue};
  background-color: transparent;
  border: 1px solid transparent;
  transition: 0.2s;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    color: ${theme.secondaryBlue};
  }
`,
);

export default ButtonLink;
