import React, { useContext } from 'react';
import { SmallSubtitle, SideSectionContainer, SectionSubtitle } from './styles';
import MomContext from '../../../MomContext';
import { toPhone, cleanUrl } from 'utilities';
import PropTypes from 'prop-types';

const ContactInfo = props => {
  const mom = useContext(MomContext);

  if (props.redactInfo) {
    return (
      <SideSectionContainer>
        <SectionSubtitle className="mb-3">Contact info</SectionSubtitle>
        <div className="text-alt font-italic">
          We keep your information private until you accept a position
        </div>
      </SideSectionContainer>
    );
  }

  return (
    <SideSectionContainer>
      <div>
        <SmallSubtitle>EMAIL</SmallSubtitle>
        {mom.email}
      </div>
      {mom.phone && (
        <div>
          <SmallSubtitle>PHONE</SmallSubtitle>
          {toPhone(mom.phone)}
        </div>
      )}
      {mom.website && (
        <div>
          <SmallSubtitle>WEBSITE</SmallSubtitle>
          {cleanUrl(mom.website)}
        </div>
      )}
    </SideSectionContainer>
  );
};

ContactInfo.propTypes = {
  redactInfo: PropTypes.bool,
};

export default ContactInfo;
