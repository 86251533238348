import React from 'react';

const Twitter = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.023"
      height="35.023"
      viewBox="0 0 35.023 35.023"
      {...props}
    >
      <title>Twitter</title>
      <path
        fill="currentColor"
        d="M240.459,375.879a5.76,5.76,0,0,1-1.823.7,2.873,2.873,0,0,0-4.892,2.619,8.15,8.15,0,0,1-5.919-3,2.876,2.876,0,0,0,.888,3.834,2.852,2.852,0,0,1-1.3-.358l0,.035a2.874,2.874,0,0,0,2.3,2.816,2.893,2.893,0,0,1-1.3.049,2.871,2.871,0,0,0,2.681,1.993,5.753,5.753,0,0,1-3.564,1.229,6.092,6.092,0,0,1-.686-.04,8.166,8.166,0,0,0,12.57-6.879l-.008-.371a5.865,5.865,0,0,0,1.433-1.487,5.8,5.8,0,0,1-1.649.452A2.885,2.885,0,0,0,240.459,375.879Z"
        transform="translate(-215.747 -363.648)"
      />
      <path
        fill="currentColor"
        d="M237.388,368.118A17.512,17.512,0,1,0,254.9,385.63,17.511,17.511,0,0,0,237.388,368.118Zm0,31.839a14.328,14.328,0,1,1,14.328-14.328A14.328,14.328,0,0,1,237.388,399.958Z"
        transform="translate(-219.876 -368.118)"
      />
    </svg>
  );
};

export default Twitter;
