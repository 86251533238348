/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { without, focusFirstDescendant, focusLastDescendant } from 'utilities';

function isTooltip(node) {
  if (node.getAttribute('role') === 'tooltip') {
    return true;
  }
  if (node.parentNode && node.parentNode !== node.ownerDocument) {
    return isTooltip(node.parentNode);
  }
  return false;
}

let IgnoreUtilFocusChanges = false;
/** Traps focus for a11y components */
class TrapFocus extends Component {
  static propTypes = {
    /** Default: FIRST | Optionally focus the first or last focusable element in the focus trap */
    autoFocusBehavior: PropTypes.oneOf(['FIRST', 'LAST', false, true]),
    /** Default: PREVENT | Chooses whether to prevent blurring (PREVENT), or call `props.onHide` (HIDE) when focus trap is blurred.  */
    blurBehavior: PropTypes.oneOf(['PREVENT', 'HIDE']),
    /** Function to close the focus trap, called on Esc keypress and blur. */
    onHide: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autoFocusBehavior: 'FIRST',
    blurBehavior: 'PREVENT',
  };

  state = {
    dialogStyle: {
      transition: 'transform 100ms',
    },
  };

  componentDidMount() {
    this.prevActiveElement = document.activeElement;
    document.addEventListener('focus', this.trapFocus, true);
    document.addEventListener('mousedown', this.emphasizeModal, true);
    document.addEventListener('click', this.handleClickAway, true);

    if (this.props.autoFocusBehavior === 'FIRST') {
      IgnoreUtilFocusChanges = true;
      focusFirstDescendant(this.dialogElem);
      IgnoreUtilFocusChanges = false;
    }
    if (this.props.autoFocusBehavior === 'LAST') {
      IgnoreUtilFocusChanges = true;
      focusLastDescendant(this.dialogElem);
      IgnoreUtilFocusChanges = false;
    }

    this.lastFocus = document.activeElement;
  }

  componentWillUnmount() {
    document.removeEventListener('focus', this.trapFocus, true);
    document.removeEventListener('mousedown', this.emphasizeModal, true);
    document.removeEventListener('click', this.handleClickAway, true);
    if (this.prevActiveElement) {
      this.prevActiveElement.focus();
    }
  }

  render() {
    const { state, props } = this;
    return (
      <>
        <div tabIndex="0" />
        <div
          ref={this.storeDialogElem}
          aria-modal="true"
          role="dialog"
          style={state.dialogStyle}
          onKeyDown={this.handleKeyDown}
          {...without.call(
            props,
            'onHide',
            'autoFocusBehavior',
            'blurBehavior',
          )}
        />
        <div tabIndex="0" />
      </>
    );
  }

  handleKeyDown = event => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.props.onHide();
    }
  };

  storeDialogElem = ref => {
    this.dialogElem = ref;
  };

  emphasizeModal = event => {
    if (
      !this.dialogElem.contains(event.target) &&
      this.props.blurBehavior === 'PREVENT'
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.lastFocus.focus();
      this.setState({
        dialogStyle: {
          ...this.state.dialogStyle,
          transform: 'scale(1.02)',
        },
      });
      setTimeout(() => {
        this.setState({
          dialogStyle: without.call(this.state.dialogStyle, 'transform'),
        });
      }, 100);
    }
  };

  handleClickAway = event => {
    if (!this.dialogElem.contains(event.target) && !isTooltip(event.target)) {
      if (this.props.blurBehavior === 'PREVENT') {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      if (this.props.blurBehavior === 'HIDE') {
        this.props.onHide();
      }
    }
  };

  trapFocus = event => {
    if (IgnoreUtilFocusChanges) {
      return;
    }
    if (this.dialogElem.contains(event.target)) {
      this.lastFocus = event.target;
    } else {
      IgnoreUtilFocusChanges = true;
      focusFirstDescendant(this.dialogElem);
      IgnoreUtilFocusChanges = false;
      if (this.lastFocus === document.activeElement) {
        IgnoreUtilFocusChanges = true;
        focusLastDescendant(this.dialogElem);
        IgnoreUtilFocusChanges = false;
      }
      this.lastFocus = document.activeElement;
    }
  };
}

export default TrapFocus;
