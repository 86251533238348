import React from 'react';

const VideoChat = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 22 22">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-514 -200)">
        <g transform="translate(514 200)">
          <circle cx="11" cy="11" r="10" stroke="#6685ff" strokeWidth="2" />
          <g stroke="#FFF" strokeWidth="2" transform="translate(4.23 6.77)">
            <path
              stroke="#6685ff"
              d="M9 2.024l2.548-1.29c.493-.249 1.095-.052 1.344.441.071.14.108.295.108.452v3.877c0 .552-.448 1-1 1-.131 0-.261-.026-.382-.076L9 5.345h0v-3.32z"
            />
            <rect stroke="#6685ff" width="7" height="6" x="1" y="1" rx="2" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default VideoChat;
