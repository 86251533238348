import ReactiveRecord, { Model } from 'reactiverecord';

const routePrefix = ':prefix/employers/projects/:project_id/bids';
class EmployerBid extends Model {
  static routes = {
    update: `${routePrefix}/:id`,
    show: `${routePrefix}/:id`,
  };

  static schema = {
    access_employer: Boolean,
    appointments: Array,
    billable_rate: Number,
    employer_active_contract_type: String,
    counter: Boolean,
    employer_resume_url: String,
    has_project_invitation: Boolean,
    hours: Number,
    id: Number,
    interview_scheduled_at: Object,
    mom: Object,
    project: Object,
    proposal_details: String,
    rate: Number,
    relevancy_score_rank: Number,
    sanitized_proposal_details: String,
    save_for_later: Boolean,
    start_date: String,
    status: String,
    total: Number,
  };
}

export default ReactiveRecord.model('EmployerBid', EmployerBid);
