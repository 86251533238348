/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import stored from 'components/hoc/stored';
import Interviewee from './Interviewee';
import Modal from '../../ui/Modal';
import ConfirmCall from './ConfirmCall';

import PropTypes from 'prop-types';
import AnalyticsContext from 'components/shared/analytics/AnalyticsContext';
import InterviewContext from './InterviewContext';
import Dialog from 'components/ui/Dialog';
import InterviewModal from 'components/employer/interviews/InterviewModal';
import RescheduleRequestHandler from 'components/employer/interviews/InterviewModal/RescheduleRequestHandler';
import RescheduleNylasInterview from 'components/employer/interviews/RescheduleNylasInterview/RescheduleNylasInterview';
import {
  activateLoader,
  clearQueryStringWithoutPageReload,
  deactivateLoader,
  showMessage,
} from 'utilities';
import { useQueryString } from 'utilities/hooks';
import { callCandidateRequest } from 'requests';
import { ANALYTICS } from 'consts';

const UpcomingInterviews = props => {
  const analyticsContext = React.useContext(AnalyticsContext);
  const [currentInterview, setCurrentInterview] = useState(null);
  const [currentCall, setCurrentCall] = useState(null);
  const [upcomingInterviews, setUpcomingInterviews] = useState([]);
  const [rescheduleId, setRescheduleId] = useQueryString('reschedule-id');

  useEffect(() => {
    openActiveRescheduleModal();
  }, [rescheduleId]);

  useEffect(() => {
    setUpcomingInterviews(getUpcomingInterviewees(props.upcomingInterviews));
  }, []);

  const isFutureInterview = appointment => {
    const yesterday = new Date().setHours(0, 0, 0);
    // did talent confirm the appointment?
    if (appointment.attributes.is_confirmed) {
      return (
        new Date(appointment.attributes.selected_appointment_date_time) >
        yesterday
      );
    }
    // are any proposed times upcoming?
    const proposed_times =
      appointment.attributes.proposed_appointment_date_times;

    // if there's no proposed times, it is a Nylas interview, there is no selected time yet.
    // otherwise the interview was scheduled through the manual flow.
    if (proposed_times && proposed_times.length > 0) {
      return (
        proposed_times &&
        proposed_times.some(time => new Date(time) > yesterday)
      );
    } else if (!appointment.attributes.selected_appointment_date_time) {
      return true;
    }
  };

  const getUpcomingInterviewees = interviews => {
    return interviews
      .map(item => {
        if (isFutureInterview(item)) {
          return (
            <Interviewee
              key={item.id}
              data={item}
              handleOnReschedule={handleOnReschedule}
            />
          );
        }
      })
      .filter(Boolean);
  };

  const openActiveRescheduleModal = () => {
    if (rescheduleId) {
      const activeReschedule = props.upcomingInterviews.find(
        interview => interview.id === rescheduleId,
      );
      if (activeReschedule) {
        setCurrentInterview(activeReschedule);
      }
    }
  };

  const handleOnClose = () => {
    setRescheduleId(null);
    clearQueryStringWithoutPageReload();
    setCurrentInterview(null);
    setCurrentCall(null);
  };

  const handleOnReschedule = interview => {
    setRescheduleId(interview.id);
  };

  const trackCallBtn = () => {
    analyticsContext.trackEvents(ANALYTICS.EVENT_TYPES.Click)({
      target: 'Interview Call Button',
    });
  };

  const callCandidate = () => {
    activateLoader();
    trackCallBtn();
    callCandidateRequest(currentCall)
      .then(response => {
        if (response.code !== 200 && response.code !== 204) {
          showMessage(`An error has occurred: ${response.message}`, true);
        } else {
          showMessage('Calling candidate now...');
        }
        handleOnClose();
        deactivateLoader();
      })
      .catch(error => {
        showMessage(`An error has occurred: ${error}`, true);
        deactivateLoader();
      });
  };

  const dispatch = useContext(InterviewContext);
  const handleSuccess = () => {
    dispatch({ type: 'reschedule', interview: currentInterview });
    handleOnClose();
  };

  return (
    <>
      <div id="interviews" className="page-section-row not-striped-rows">
        <div className="sop-container">
          <h2>Upcoming interviews</h2>
          {upcomingInterviews.length > 0 ? (
            <div>{upcomingInterviews}</div>
          ) : (
            <div className="empty-message">
              You have no upcoming interviews.
            </div>
          )}
        </div>
      </div>
      {currentInterview && (
        <RescheduleRequestHandler appointmentId={+currentInterview.id}>
          {appointment => (
            <Dialog
              autoFocusBehavior={!appointment.nylas_booking_url}
              onHide={handleOnClose}
              show={true}
              large
            >
              {appointment.nylas_booking_url ? (
                <RescheduleNylasInterview
                  mom={{
                    id: currentInterview.relationships.mom.data.id,
                    first_name_last_initial:
                      currentInterview.attributes.talent_name,
                    avatar_url: currentInterview.attributes.talent_avatar.url,
                    city: currentInterview.attributes.talent_location.city,
                    state: currentInterview.attributes.talent_location.state,
                  }}
                  interview={appointment}
                  handleOnSuccess={handleSuccess}
                  handleOnClose={handleOnClose}
                  projectId={currentInterview.relationships.project.data.id}
                />
              ) : (
                <InterviewModal
                  bidId={+currentInterview.relationships.bid.data.id}
                  handleOnClose={handleOnClose}
                  handleOnSuccess={handleSuccess}
                  interview={appointment}
                  isReschedule
                  mom={{
                    id: +currentInterview.relationships.mom.data.id,
                    first_name_last_initial:
                      currentInterview.attributes.talent_name,
                  }}
                  projectTitle={currentInterview.attributes.project_title}
                />
              )}
            </Dialog>
          )}
        </RescheduleRequestHandler>
      )}
      <Modal
        size="sm"
        show={currentCall !== null}
        onHide={handleOnClose}
        title="Call this candidate"
      >
        <ConfirmCall
          onConfirmCall={callCandidate}
          handleOnClose={handleOnClose}
        />
      </Modal>
    </>
  );
};

UpcomingInterviews.propTypes = {
  upcomingInterviews: PropTypes.array.isRequired,
};

export default stored(UpcomingInterviews);
