import React from 'react';

import Icon from '../Icon';

const VerticalDots = props => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="m0 0h24v24h-24z" fill="none" pointerEvents="none" />
      <g
        fill={props.color}
        transform="matrix(.96000008 0 0 .96000008 9.600002 0)"
      >
        <path d="m2.09009.391846c1.104 0 2 .896004 2 2.000004s-.896 2-2 2c-1.104002 0-2.000002-.896-2.000002-2 .0000001-1.104.896-2.000004 2.000002-2.000004" />
        <path d="m2.09009 10.3918c1.104 0 2 .896 2 2s-.896 2-2 2c-1.104002 0-2.000002-.896-2.000002-2 .0000001-1.104.896-2 2.000002-2" />
        <path d="m2.09009 20.3918c1.104 0 2 .896 2 2s-.896 2-2 2c-1.104002 0-2.000002-.896-2.000002-2 .0000001-1.104.896-2 2.000002-2" />
      </g>
    </Icon>
  );
};

VerticalDots.propTypes = { ...Icon.propTypes };
VerticalDots.defaultProps = { ...Icon.defaultProps };

export default VerticalDots;
