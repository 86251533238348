import ReactiveRecord, { Model } from 'reactiverecord';

class Announcement extends Model {
  static schema = {
    id: Number,
    position: String,
  };
}

export default ReactiveRecord.model('Announcement', Announcement);
