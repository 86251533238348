import React from 'react';
import PropTypes from 'prop-types';

import { TalentAvatar } from 'components/shared/TalentAvatar';
import Flex from 'components/ui/Flex';
import { colors } from 'styleGuide';
import { RedactedDetails } from '../BidCard/styles';

const TalentProfile = ({
  avatarBorderColor,
  avatarSize = 'xs',
  children,
  isNotWithdrawn,
  resume,
  mom: { avatar_url, first_name_last_initial, city_state },
  showNewBidReview,
}) => {
  return (
    <Flex direction="row" gap={8} justifyContent="space-between" wrap="true">
      <Flex direction="row" gap={12} justifyContent="flex-start">
        <TalentAvatar
          borderColor={avatarBorderColor || colors.blue.primary}
          bordered
          photoUrl={isNotWithdrawn ? avatar_url : null}
          size={avatarSize}
          talentName={first_name_last_initial}
        />
        <Flex alignItems="flex-start" direction="column">
          <Flex
            alignItems="center"
            direction="row"
            gap={4}
            justifyContent="flex-start"
          >
            <h4 className="talent-name font-heavy text-nowrap font-primary-regular">
              {first_name_last_initial}
            </h4>
          </Flex>
          {city_state && city_state.length && isNotWithdrawn && (
            <div className="talent-location">{city_state}</div>
          )}
          {city_state && city_state.length && !isNotWithdrawn && (
            <RedactedDetails className="w-100 mt-3" />
          )}
          {resume}
        </Flex>
      </Flex>
      {showNewBidReview && children}
    </Flex>
  );
};

TalentProfile.defaultProps = {
  isNotWithdrawn: true,
};

TalentProfile.propTypes = {
  avatarBorderColor: PropTypes.string,
  avatarSize: PropTypes.string,
  children: PropTypes.any,
  isNotWithdrawn: PropTypes.bool,
  resume: PropTypes.any,
  mom: PropTypes.shape({
    avatar_url: PropTypes.string,
    city_state: PropTypes.string,
    first_name_last_initial: PropTypes.string.isRequired,
  }).isRequired,
  showNewBidReview: PropTypes.bool,
};

export default TalentProfile;
