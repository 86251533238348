import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

const MomRouter = ({ location, currentMom, childrenRoutes }) => {
  return (
    <Switch>
      {!currentMom.has_agreed_to_terms &&
        !location.pathname.includes('/mom/terms_of_service') && (
          <Route
            path={location.pathname}
            render={() => (
              <Redirect
                to={{
                  pathname: `/mom/terms_of_service`,
                  search: `?redirect_after=${location.pathname}${location.search}`,
                }}
              />
            )}
          />
        )}
      {childrenRoutes}
    </Switch>
  );
};

MomRouter.propTypes = {
  childrenRoutes: PropTypes.node,
  currentMom: PropTypes.object,
  location: PropTypes.object,
};

export default MomRouter;
