import ReactiveRecord, { Model } from 'reactiverecord';

const routePrefix = ':prefix/employers/users';
class EmployerUser extends Model {
  static routes = {
    index: routePrefix,
    update: `${routePrefix}/:id`,
  };

  static schema = {
    active: Boolean,
    admin: Boolean,
    avatar_url: String,
    email: String,
    full_name: String,
    initials: String,
  };
}

export default ReactiveRecord.model('EmployerUser', EmployerUser);
