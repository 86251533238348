import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useMediaSet } from 'use-media-set';
import { mediaSetQueries } from 'styleGuide';
import Flex from 'components/ui/Flex';
import { URLS } from 'consts';
import LinkButton from 'components/ui/buttons/LinkButton';
import { getThankYouNoteEndDate } from './utilities';

const Heading = styled.div(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.fontHeavy};
  `,
);

const VideoCallFinishedMom = ({ videoCall, wrapButtons }) => {
  const lastDateForThankYouNote = getThankYouNoteEndDate(videoCall.start_time);
  const mediaStates = useMediaSet(mediaSetQueries);
  const isMobile = mediaStates.has('mobile');

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      className="flex-grow-1"
    >
      <div className="mt-4 mb-4">
        <Heading className="mb-3">Your interview is complete!</Heading>
        <p>
          We&apos;ll follow up with you on next steps once we hear more from the
          employer. In the meantime, feel free to send them a thank you note if
          you&apos;d like!
        </p>
        <p className="font-sm">
          To show your interest, we recommend reaching out within 1 business day
          if you can. You have the option to send a thank you note through 8pm
          EST on {lastDateForThankYouNote}.
        </p>
      </div>
      <Flex
        direction={wrapButtons || isMobile ? 'column' : 'row'}
        reverse={wrapButtons || isMobile}
        wrap="reverse"
        justifyContent="center"
        gap={16}
      >
        <LinkButton
          id="return-to-dashboard"
          href={URLS.talentDashboard}
          secondary
          noBorder
        >
          Go to my dashboard
        </LinkButton>
        <LinkButton
          id="write-thank-you"
          href={URLS.talentInterviewFeedback(videoCall.bid_slug)}
        >
          Write a thank you
        </LinkButton>
      </Flex>
    </Flex>
  );
};

VideoCallFinishedMom.propTypes = {
  videoCall: PropTypes.object.isRequired,
  wrapButtons: PropTypes.bool,
};

export default VideoCallFinishedMom;
