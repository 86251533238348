import React from 'react';
import PropTypes from 'prop-types';
import TimerFilled from 'components/ui/icons/TimerFilled';
import TimerHalf from 'components/ui/icons/TimerHalf';
import { IconWrapper } from './styles';

const ProjectType = ({ isFullTime, projectType }) => {
  const ProjectTypeIcon = () => {
    return isFullTime ? <TimerFilled width="15" /> : <TimerHalf width="15" />;
  };

  return (
    <div className="job_type">
      <IconWrapper>
        <ProjectTypeIcon />
      </IconWrapper>
      {projectType}
    </div>
  );
};

ProjectType.propTypes = {
  isFullTime: PropTypes.bool.isRequired,
  projectType: PropTypes.string.isRequired,
};

export default ProjectType;
