import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import { Label } from 'components/ui/inputs/Input/styles';
import styled from 'styled-components';

const paddingTB = 13;
const characterCounterPadding = 16;
const TextAreaWrapper = styled.div`
  position: relative;
  .characters-remaining {
    position: absolute;
    bottom: 8px;
    left: 12px;
    text-transform: none;
    line-height: 1;
    pointer-events: none;
  }
`;
const StyledTextArea = styled.textarea(
  ({ white, theme, characterCounter, css }) => `
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: ${theme.fontWeights.fontHeavy};
  line-height: 24px;
  color: ${theme.darkGray};
  background-color: ${white ? theme.white : theme.lightBlue};
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px ${theme.lightGray};
  -webkit-appearance: none;
  padding: ${paddingTB}px 12px${
    characterCounter ? ` ${paddingTB + characterCounterPadding}px` : ''
  };
  resize: none;

  &:focus,
  .forceFocusState & {
    box-shadow: inset 0 0 0 1px ${theme.primaryBlue};
  }
  &::-webkit-input-placeholder {
    color: ${theme.mediumGray};
    font-weight: ${theme.fontWeights.fontNormal};
  }
  &::-moz-placeholder {
    color: ${theme.mediumGray};
    font-weight: ${theme.fontWeights.fontNormal};
  }
  &:-ms-input-placeholder {
    color: ${theme.mediumGray};
    font-weight: ${theme.fontWeights.fontNormal};
  }

  &:disabled {
    background-color: ${theme.lightGray};
    box-shadow: inset 0 0 0 1px ${theme.lightGray};
    &::-webkit-input-placeholder {
      color: ${theme.mediumGray};
    }
    &::-moz-placeholder {
      color: ${theme.mediumGray};
    }
    &:-ms-input-placeholder {
      color: ${theme.mediumGray};
    }
  }
  ${css || ''}
`,
);

const TextArea = ({ labelText, id, name, touched, errors, ...props }) => {
  const hasErrors = touched && touched[name] && errors && errors[name];

  return (
    <Label htmlFor={id}>
      {labelText ? <span className="label-text">{labelText}</span> : null}
      <div className="input-wrapper">
        <TextAreaWrapper>
          <StyledTextArea id={id} {...props} />
        </TextAreaWrapper>
      </div>
      {hasErrors && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </Label>
  );
};

TextArea.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

TextArea.displayName = 'TextArea';

export default TextArea;
