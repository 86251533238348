import React from 'react';

import Icon from '../Icon';

const Close = props => {
  return (
    <Icon title="Close" viewBox="0 0 30 30" {...props}>
      <path
        fill={props.color}
        d="M29.365 26.751L17.613 15 29.365 3.246a1.844 1.844 0 000-2.611 1.844 1.844 0 00-2.611 0L15.001 12.387 3.25.635a1.844 1.844 0 00-2.612 0 1.844 1.844 0 000 2.611l11.75 11.755L.635 26.754a1.844 1.844 0 000 2.611 1.844 1.844 0 002.611 0l11.753-11.752L26.75 29.365a1.844 1.844 0 002.612 0 1.844 1.844 0 00.002-2.614z"
      />
    </Icon>
  );
};

Close.propTypes = { ...Icon.propTypes };
Close.defaultProps = { ...Icon.defaultProps };

export default Close;
