import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/cloud_enrollments';
const memberRoute = `${collectionRoute}/:id`;

class CloudEnrollment extends Model {
  static routes = {
    create: collectionRoute,
    update: memberRoute,
    show: memberRoute,
  };

  static schema = {
    status_id: Number,
    opt_in_to_dtc: Boolean,
  };
}

export default ReactiveRecord.model('CloudEnrollment', CloudEnrollment);
