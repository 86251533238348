import React from 'react';
import PropTypes from 'prop-types';
import { getTimeZones } from '@vvo/tzdb';

import BulletItems from 'components/shared/BulletItems';

const ReschedulePreferences = ({ appointment, talentName }) => {
  const talentTimeZone = getTimeZones().find(
    tz => tz.name === appointment.talent_preferred_timezone,
  );
  const talentTzName = talentTimeZone.alternativeName;
  const talentTzOffset = talentTimeZone.currentTimeFormat.split(' ')[0];
  return (
    <div className="talent-reschedule-request my-4 ">
      {appointment.preferred_times && (
        <>
          {`${talentName} is available in the`}&nbsp;
          <BulletItems
            items={appointment.preferred_times.split(',')}
            theme="blue"
          />
        </>
      )}
      <>
        in{' '}
        <BulletItems
          items={[`${talentTzName} (${talentTzOffset})`]}
          theme="blue"
        />
      </>
      {appointment.preferred_dates && (
        <>
          on{' '}
          <BulletItems
            items={appointment.preferred_dates.split(',')}
            theme="blue"
          />
        </>
      )}
    </div>
  );
};

ReschedulePreferences.propTypes = {
  appointment: PropTypes.shape({
    preferred_dates: PropTypes.string,
    preferred_times: PropTypes.string,
    talent_preferred_timezone: PropTypes.string.isRequired,
  }),
  talentName: PropTypes.string.isRequired,
};

export default ReschedulePreferences;
