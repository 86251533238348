import ReactiveRecord, { Model } from 'reactiverecord';

class MomAccount extends Model {
  static store = {
    singleton: true,
  };

  static routes = {
    create: '/api/v1/users',
  };

  set phone(phone) {
    let phone_digits = phone.replace(/[^0-9]+/g, '');
    if (phone_digits.length > 10 && phone_digits[0] === '1') {
      this._attributes.phone = phone_digits.slice(1);
    } else {
      this._attributes.phone = phone_digits;
    }
  }

  static schema = {
    agree_to_terms_and_policy: Boolean,
    email: String,
    first_name: String,
    full_name: String,
    last_name: String,
    phone: { type: String, labelText: 'Phone Number' },
    password: { type: String, labelText: 'Password' },
    referred_by: String,
    redirect_after: String,
    zip: { type: String, labelText: 'Zip Code' },
  };
}

export default ReactiveRecord.model('MomAccount', MomAccount);
