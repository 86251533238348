import ReactiveRecord, { Model } from 'reactiverecord';

const route = ':prefix/moms/interview_feedbacks?slug=:slug';
class InterviewFeedback extends Model {
  static routes = {
    create: route,
    show: route,
  };

  static schema = {
    _primaryKey: 'slug',
    feedback: { type: String },
    slug: { type: String },
    project: { type: Object },
  };
}

export default ReactiveRecord.model('InterviewFeedback', InterviewFeedback);
