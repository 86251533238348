import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import ShowToggle from './ShowToggle';
import PencilEditLink from './PencilEditLink';
import { queryStringToObj } from 'utilities';

const PencilEditDialog = ({
  title,
  children,
  showDialogCloser,
  testId,
  closeModalOnBlur,
  editLinkText,
  ...props
}) => {
  const param = title.replace(/' '/g, '');
  const queryString = queryStringToObj(location.search);
  const showModal = queryString.showModal === param;

  return (
    <ShowToggle preventDefault defaultShow={showModal}>
      {(show, toggle) => (
        <>
          <PencilEditLink
            onClick={toggle}
            title={title}
            data-testid={testId}
            linkText={editLinkText}
          />
          <Dialog
            large
            show={show}
            onHide={toggle}
            title={title}
            showDialogCloser={showDialogCloser}
            closeModalOnBlur={closeModalOnBlur}
            {...props}
          >
            {children(toggle)}
          </Dialog>
        </>
      )}
    </ShowToggle>
  );
};

PencilEditDialog.propTypes = {
  testId: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  showDialogCloser: PropTypes.bool,
  closeModalOnBlur: PropTypes.bool,
  editLinkText: PropTypes.string.isRequired,
};
PencilEditDialog.defaultProps = {
  editLinkText: 'Edit',
};

export default PencilEditDialog;
