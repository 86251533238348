import React from 'react';
import { Field, useFormikContext } from 'formik';
import Input from 'components/ui/formik/Input';

const FieldLegalZip = () => {
  const { errors, touched } = useFormikContext();
  return (
    <Field
      id="legal_zip"
      data-testid="legal_zip"
      name="legal_zip"
      labelText="Legal Company Zip"
      touched={touched}
      errors={errors}
      as={Input}
    />
  );
};

export default FieldLegalZip;
