import styled from 'styled-components';

export const StyledList = styled.ul`
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 0;
`;

export const StyledNav = styled.nav`
  max-width: 400px;
  width: 100%;
  margin: auto;
`;
