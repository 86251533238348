import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontSizes } from 'styleGuide';

const OnboardingProgressBar = props => {
  return (
    <div className="hero-progress">
      <div className="row no-gutters">
        {props.sections.map((value, key) => {
          const active = key <= props.active;
          const selected = active
            ? 'hero-progress-complete'
            : 'hero-progress-incomplete';
          return (
            <ProgressButton
              data-id="onboarding-progress-btn"
              className={
                'col ' + (key === props.active ? '' : 'd-none d-sm-block')
              }
              key={key}
              onClick={() => props.action(key)}
            >
              <div className={selected}>
                <div className="text-center mx-auto">
                  {key + 1 + '.'} {value}
                </div>
              </div>
            </ProgressButton>
          );
        })}
      </div>
    </div>
  );
};

const ProgressButton = styled.button`
  background: none;
  border: 0;
  font-size: ${fontSizes.xs};
  text-transform: uppercase;
`;

OnboardingProgressBar.propTypes = {
  sections: PropTypes.array.isRequired,
  active: PropTypes.number,
  action: PropTypes.func.isRequired,
};

export default OnboardingProgressBar;
