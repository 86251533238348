import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/certifications';
const memberRoute = `${collectionRoute}/:id`;

class Certification extends Model {
  static routes = {
    index: collectionRoute,
    create: collectionRoute,
    show: memberRoute,
    update: memberRoute,
    destroy: memberRoute,
  };

  static schema = {
    title: String,
    issued_by: { labelText: 'Issued By (Optional)', type: String },
    year_issued: String,
    is_current: Boolean,
  };
}

export default ReactiveRecord.model('Certification', Certification);
