import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BottomSpacingContainer,
  GreetingHeader,
  DateTime,
  Disclaimer,
} from './styles';
import VideoAlreadyJoinedError from '../VideoAlreadyJoinedError';
import VideoAccessTokenForm from '../VideoAccessTokenForm';
import PreviewParticipants from './PreviewParticipants';
import useParticipantStatus from '../useParticipantStatus';
import useInterviewStatus from '../useInterviewStatus';

const VideoPreviewGreeting = ({
  videoCall,
  roomParticipants = [],
  identity,
  roomName,
}) => {
  const interviewReady = useInterviewStatus(videoCall);
  const joined = useParticipantStatus(identity, roomParticipants);
  const [alreadyJoined, setAlreadyJoined] = useState(joined);
  const interviewGreeting = interviewReady
    ? 'Your interview is ready to join!'
    : 'Your interview room will be ready';
  const { formattedStartTime } = videoCall;

  useEffect(() => {
    setAlreadyJoined(joined);
  }, [joined]);

  if (alreadyJoined) {
    return <VideoAlreadyJoinedError />;
  }

  return (
    <>
      <BottomSpacingContainer>
        <GreetingHeader>{interviewGreeting}</GreetingHeader>
        <PreviewParticipants roomParticipants={roomParticipants} />
        <DateTime
          dangerouslySetInnerHTML={{ __html: formattedStartTime }}
        ></DateTime>
      </BottomSpacingContainer>
      <BottomSpacingContainer>
        <VideoAccessTokenForm
          roomName={roomName}
          identity={identity}
          submitBtnEnabled={interviewReady}
          setAlreadyJoined={setAlreadyJoined}
        />
      </BottomSpacingContainer>
      <Disclaimer>Interview room is available for 2 hours.</Disclaimer>
    </>
  );
};

VideoPreviewGreeting.propTypes = {
  videoCall: PropTypes.object.isRequired,
  roomParticipants: PropTypes.array.isRequired,
  identity: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
};

export default VideoPreviewGreeting;
