import React from 'react';
import PropTypes from 'prop-types';

function MultiSelect(props) {
  const SelectComponent =
    props.maxLength === 1 && !props.checkboxType ? RadioSelect : CheckboxSelect;
  return <SelectComponent {...props} />;
}

MultiSelect.propTypes = {
  initialSelected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  controlled: PropTypes.bool,
  afterUpdate: PropTypes.func,
  checkboxType: PropTypes.bool,
};

// Receives and returns a single selection
class RadioSelect extends React.Component {
  static propTypes = MultiSelect.propTypes;

  constructor(props) {
    super(props);
    this.state = {
      selected: props.initialSelected,
    };
  }

  handleChange = (key, value) => {
    const { props, state } = this;
    if (state.selected === value) {
      this.setState({ selected: '' }, () =>
        props.onChange(key, this.state.selected),
      );
    } else {
      this.setState({ selected: value }, () =>
        props.onChange(key, this.state.selected),
      );
    }
  };

  render() {
    return this.props.children({
      onChange: this.handleChange,
      selected: this.state.selected,
    });
  }
}

// Receives and returns multiple selections
class CheckboxSelect extends React.Component {
  static propTypes = MultiSelect.propTypes;

  constructor(props) {
    super(props);
    this.state = {
      selected: props.initialSelected || [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Some components do need the selected values to be controlled from the outside
    // E.g. the capabilities, which can be reset when the superpower changes
    if (
      nextProps.controlled &&
      nextProps.initialSelected !== this.state.selected
    ) {
      this.setState({
        selected: nextProps.initialSelected,
      });
    }
  }

  handleChange = (key, value) => {
    const { props, state } = this;
    if (value === 'unemployed' || state.selected.includes('unemployed')) {
      if (state.selected.includes(value)) {
        this.setState({ selected: [] }, () =>
          props.onChange(key, this.state.selected),
        );
      } else {
        this.setState({ selected: [value] }, () =>
          props.onChange(key, this.state.selected),
        );
      }
    } else if (state.selected.includes(value)) {
      this.setState(
        lastState => ({
          selected: lastState.selected.filter(v => v !== value),
        }),
        () => {
          props.onChange(key, this.state.selected);
          props.afterUpdate && props.afterUpdate(this.state);
        },
      );
    } else if (
      isNaN(props.maxLength) ||
      state.selected.length < props.maxLength
    ) {
      this.setState(
        lastState => ({
          selected: [...lastState.selected, value],
        }),
        () => {
          props.onChange(key, this.state.selected);
          props.afterUpdate && props.afterUpdate(this.state);
        },
      );
    }
  };

  render() {
    return this.props.children({
      onChange: this.handleChange,
      selected: this.state.selected,
    });
  }
}

export default MultiSelect;
