import React from 'react';

import Icon from '../Icon';

const PlusCircleO = props => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <line
        x1="10"
        y1="7"
        x2="10"
        y2="13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="7"
        y1="10"
        x2="13"
        y2="10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
    </Icon>
  );
};

PlusCircleO.propTypes = { ...Icon.propTypes };
PlusCircleO.defaultProps = { ...Icon.defaultProps };

export default PlusCircleO;
