/* eslint-disable react/prop-types */
import React from 'react';
import { URLS, getRedirectLocation } from 'consts';
import themed from 'components/hoc/themed';
import { BorderBox, Container } from './SignupThanksStyles';
import Sparkles3Pattern from 'components/ui/icons/Sparkles3Pattern';
import Sparkles4Pattern from 'components/ui/icons/Sparkles4Pattern';
import { Loader } from 'components/ui/Loader';

const SignupThanks = () => {
  React.useEffect(() => {
    const redirectLocation = getRedirectLocation();
    if (redirectLocation) {
      setTimeout(() => {
        window.location.assign(redirectLocation);
      }, 4000);
    } else {
      setTimeout(() => {
        window.location.assign(URLS.talentOnboarding);
      }, 4000);
    }
  }, []);
  return (
    <Container>
      <BorderBox>
        <h1>Thank you for registering!</h1>
        <div>Please wait while we setup your dashboard...</div>
        <Loader size="md" />
        <Sparkles3Pattern
          css={`
            position: absolute;
            left: 20px;
            bottom: 20px;
          `}
        />
        <Sparkles4Pattern
          width="50"
          height="100"
          css={`
            position: absolute;
            right: 20px;
            top: 20px;
          `}
        />
      </BorderBox>
    </Container>
  );
};

export default themed(SignupThanks);
