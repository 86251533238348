import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';

const LeftArrow = props => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 593 1024"
    >
      <title>Left Arrow</title>
      <path
        fill={props.fillColor}
        d="M515.082 0.431l62.768 62.768-508.124 508.124-62.768-62.768 508.124-508.124z"
      />
      <path
        fill={props.fillColor}
        d="M591.235 966.549l-62.768 62.768-508.124-508.124 62.768-62.768 508.124 508.124z"
      />
    </svg>
  );
};

LeftArrow.propTypes = {
  fillColor: PropTypes.any.isRequired,
};
LeftArrow.defaultProps = {
  fillColor: theme.white,
};

export default LeftArrow;
