import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { StyledHeader, expand, collapse } from './stylesV2';
import CompanyLogoFull from 'components/ui/icons/CompanyLogoFull';
import HamburgerMenu from '../../ui/icons/HamburgerMenu';
import Close from 'components/ui/icons/Close';
import CountNotificationBubble from './CountNotificationBubble';
import classNames from 'classnames';
import Media from 'react-media';
import stored from 'components/hoc/stored';
import Caret from 'components/ui/icons/Caret';
import { screenSizeInt } from 'styleGuide';
import styled, { css } from 'styled-components';

const HeaderText = styled.div(
  css`
    font-size: 16px;
    font-weight: 500;
  `,
);

const Header = ({
  children,
  headerText,
  onBack,
  onClose,
  notificationCount,
  ...props
}) => {
  const [state, setState] = useState({ className: 'collapse', style: {} });
  const menuRef = useRef(null);
  const toggle = useCallback(() => {
    if (menuRef.current) {
      collapse(menuRef, setState);
      if (onClose) {
        setTimeout(() => onClose(), 300);
      }
    } else {
      expand(menuRef, setState);
    }
  }, [onClose]);

  return (
    <StyledHeader {...props}>
      <nav className="navbar navbar-v2">
        <a className="navbar-brand hidden" href="/">
          <CompanyLogoFull />
        </a>
        {children ? (
          <Media query={`(min-width: ${screenSizeInt.laptopM + 1}px)`}>
            {matches =>
              matches ? (
                children
              ) : (
                <>
                  {headerText ? (
                    <Caret
                      direction="left"
                      href="#"
                      onClick={onBack}
                      style={{
                        minWidth: '56px',
                        minHeight: '22px',
                        paddingRight: '2rem',
                        cursor: 'pointer',
                      }}
                    ></Caret>
                  ) : (
                    <a className="navbar-brand" href="/">
                      <CompanyLogoFull />
                    </a>
                  )}
                  <HeaderText>{headerText}</HeaderText>
                  <button
                    aria-controls="navbarNav"
                    aria-expanded={state.className ? 'false' : 'true'}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    type="button"
                    style={{
                      minWidth: '56px',
                      display: 'block',
                    }}
                    onClick={toggle}
                    id="navbar-toggler"
                  >
                    <CountNotificationBubble
                      count={notificationCount}
                      superscript={true}
                    >
                      <HamburgerMenu className="hamburger-menu" height="19px" />
                      <Close
                        className="close-button"
                        style={{ color: '#212630' }}
                        size={16}
                      />
                    </CountNotificationBubble>
                  </button>
                  {state.className === 'collapse' ? null : (
                    <div
                      className={classNames('navbar-collapse', state.className)}
                      style={state.style}
                      id="navbarNav"
                    >
                      <div ref={menuRef}>{children}</div>
                    </div>
                  )}
                </>
              )
            }
          </Media>
        ) : null}
      </nav>
    </StyledHeader>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headerText: PropTypes.string,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  notificationCount: PropTypes.number,
};

export default stored(Header);
