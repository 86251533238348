import React from 'react';
import PropTypes from 'prop-types';

const Hero = props => (
  <div className="position-relative">
    {props.children}
    <div className="onboarding-hero text-center" id={props.id}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col mb-2">
            <div className="onboarding-hero-header mb-2">{props.heading}</div>
            <div className="row justify-content-center">
              <div className="col-11 col-md-6">
                <div className="onboarding-hero-description">
                  {props.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default Hero;
