import React from 'react';
import ErrorWrapper from 'components/containers/shared/ErrorWrapper';

const withErrorBoundary = (Component, context = null) => {
  return (
    <ErrorWrapper context={context}>
      <Component />
    </ErrorWrapper>
  );
};

export default withErrorBoundary;
