import React from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

const Wrapper = styled.div(
  ({ transitionState, transitionTime }) => `
  transition: opacity ${transitionTime}ms;
  ${transitionState.match(/entering|exiting/) ? 'opacity: 0;' : ''}  
`,
);

const FadeInOutMount = ({ timeout, in: inProp, ...props }) => (
  <Transition timeout={timeout} in={inProp}>
    {state =>
      state === 'exited' ? null : (
        <Wrapper transitionTime={timeout} transitionState={state} {...props} />
      )
    }
  </Transition>
);

FadeInOutMount.defaultProps = {
  timeout: 400,
};

FadeInOutMount.propTypes = {
  timeout: Transition.propTypes.timeout,
  in: Transition.propTypes.in,
};

export default FadeInOutMount;
