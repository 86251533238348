import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Label } from 'components/ui/inputs/Input/styles';
import Checkbox from './Checkbox';
import ErrorMessage from 'components/ui/labels/ErrorMessage';

const CheckboxGroup = ({
  errors,
  touched,
  id,
  labelText,
  name,
  onChange,
  values,
  checked,
}) => {
  return (
    <>
      <Label>
        <span id={`formik-checkbox-group-${id}`} className="label-text mb-2">
          {labelText}
        </span>
      </Label>
      <div
        role="group"
        className="d-flex flex-column"
        aria-labelledby={`formik-checkbox-${id}`}
      >
        {Object.entries(values).map(([fieldName, value]) => (
          <Field
            as={Checkbox}
            key={value}
            touched={touched}
            onChange={onChange}
            name={name}
            value={value}
            labelText={fieldName}
            checked={checked ? checked.includes(value) : false}
          />
        ))}
        {touched && touched[name] && errors && errors[name] && (
          <ErrorMessage>{errors[name]}</ErrorMessage>
        )}
      </div>
    </>
  );
};

CheckboxGroup.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  values: PropTypes.object.isRequired,
  checked: PropTypes.array,
};

export default CheckboxGroup;
