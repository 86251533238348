import React, { useEffect, useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';
import styled from 'styled-components';

const AudioContext = window.AudioContext || window.webkitAudioContext;

let clipId = 0;
const getUniqueClipId = () => clipId++;
const drawEllipse = (ctx, canvasHeight, minRadiusY, maxRadiusY) => {
  const radiusX = 2.5;
  const ellipseSpacing = 8;
  const yCord = canvasHeight / 2;
  const xCord1 = yCord;
  const xCord2 = xCord1 + ellipseSpacing;
  const xCord3 = xCord2 + ellipseSpacing;
  ctx.beginPath();

  // syntax: ctx.ellipse(x, y, radiusX, radiusY, rotation, startAngle, endAngle [, anticlockwise]);
  ctx.ellipse(xCord1, yCord, radiusX, minRadiusY, 0, 0, Math.PI * 2);
  ctx.ellipse(xCord2, yCord, radiusX, maxRadiusY, 0, 0, Math.PI * 2);
  ctx.ellipse(xCord3, yCord, radiusX, minRadiusY, 0, 0, Math.PI * 2);
  ctx.fillStyle = theme.primaryBlue;
  ctx.fill();
};

const Canvas = styled.canvas`
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
  z-index: 1;
`;

const AudioLevelIndicator = ({ track }) => {
  const [analyser, setAnalyser] = useState();
  const canvasRef = useRef();
  const canvasHeight = 15;
  const canvasWidth = 50;
  const radiusConstraint = 3;

  useEffect(() => {
    const audioCtx = new AudioContext();
    const analyser = audioCtx.createAnalyser();

    // This makes the transition between values over time smoother
    analyser.smoothingTimeConstant = 0.9;
    analyser.minDecibels = -45;
    analyser.maxDecibels = -10;

    //Size of the FFT (Fast Fourier Transform) to be used to determine the frequency domain
    analyser.fftSize = 512;

    const stream = new MediaStream([track.mediaStreamTrack]);
    const source = audioCtx.createMediaStreamSource(stream);

    source.connect(analyser);

    setAnalyser(analyser);
  }, [track.mediaStreamTrack]);

  useEffect(() => {
    let frameID;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // This is an arbitrary number used to adjust the volume indicator
    const SOUND_MULTIPLYER = 8;

    if (analyser) {
      const soundData = new Uint8Array(analyser.frequencyBinCount);

      const draw = () => {
        frameID = requestAnimationFrame(draw);
        analyser.getByteFrequencyData(soundData);

        context.clearRect(0, 0, canvasWidth, canvasHeight);

        // draw this when there's no sound
        const restingRadius = 2.5;
        drawEllipse(context, canvasHeight, restingRadius, restingRadius);

        // draw this when there is sound
        for (let i = 0; i < soundData.length; i++) {
          let minRadiusY = Math.min(
            radiusConstraint,
            Math.floor(Math.log10(soundData[i]) * SOUND_MULTIPLYER),
          );
          let maxRadiusY = minRadiusY + 5;
          drawEllipse(context, canvasHeight, minRadiusY, maxRadiusY);
        }
      };

      draw();
    }

    return () => cancelAnimationFrame(frameID);
  }, [analyser, radiusConstraint]);

  return (
    <Canvas
      width={canvasWidth}
      height={canvasHeight}
      ref={canvasRef}
      id={`audio-indicator-${getUniqueClipId()}`}
    ></Canvas>
  );
};

AudioLevelIndicator.propTypes = {
  track: PropTypes.object.isRequired,
};

export default memo(AudioLevelIndicator);
