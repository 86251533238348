import React, { forwardRef, useEffect, useState, useCallback } from 'react';
import ErrorMessage from './labels/ErrorMessage';
import PropTypes from 'prop-types';

/**
 * Prevents form submission if the given `validator` function returns error text.
 * Renders the error text when necessary or gives it to a child function along with
 * a way to manually trigger the validation, such as when other fields change.
 */
const FieldsValidator = forwardRef(
  ({ children, fields, validator }, forwardedRef) => {
    const [errorText, setErrorText] = useState(null);
    const trigger = useCallback(() => {
      validator(fields, nextErrorText => setErrorText(nextErrorText || null));
    }, [fields, validator]);
    useEffect(() => {
      forwardedRef({
        isValid(callback) {
          validator(fields, nextErrorText => {
            setErrorText(nextErrorText || null);
            /**
             * If there was error text, the component is not valid. Therefore,
             * we can safely use the NOT operator on nextErrorText to determine
             * whether the component is valid or invalid.
             */
            callback(!nextErrorText);
          });
        },
        /* Returns no value */
        value: () => ({}),
      });
    }, [forwardedRef, fields, validator]);
    if (children) {
      return children(trigger, errorText);
    }
    return errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null;
  },
);

FieldsValidator.build = key => (resource, form) => ({
  [key]: {
    key,
    ref: ref => (form.fields[key] = ref),
    fields: form.fields,
  },
});

FieldsValidator.displayName = 'FieldsValidator';

FieldsValidator.propTypes = {
  children: PropTypes.func,
  fields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  validator: PropTypes.func.isRequired,
};

export default FieldsValidator;
/**
 * USAGE
 * Example validator:
 * const datesValidator = (fields, done) => {
 *   if (fields.start_date.value && fields.end_date.value) {
 *     if (fields.start_date.value <= fields.end_date.value) {
 *       // Calling done with a message marks the field as invalid and sets the error text
 *       // Form submission will be prevented
 *       done('The start date must be after the end date.');
 *     }
 *   }
 *   // Calling done with no arguments marks the field as valid and removes the error text
 *   // Form submission will NOT be prevented
 *   done()
 * }
 * // Example usage in a ReactiveRecord Form:
 * <Form builder={FieldsValidator.build('dates')}>
 *   {fields => (
 *     <FieldsValidator {...fields.dates} validator={datesValidator}>
 *       {(trigger, errorText) => (
 *         <Fragment>
 *           {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
 *           <Input {...fields.start_date} onChange={trigger} />
 *           <Input {...fields.end_date} onChange={trigger} />
 *         </Fragment>
 *       )}
 *     </FieldsValidator>
 *   )}
 * </Form>
 * // Optionally, you can render the validator with no trigger, which will cause it to only
 * // validate the fields before form submission. The error message will be rendered in place.
 * <Form builder={FieldsValidator.build('dates')}>
 *   {fields => (
 *     <Fragment>
 *       <FieldsValidator {...fields.dates} validator={datesValidator} />
 *       <Input {...fields.start_date} onChange={trigger} />
 *       <Input {...fields.end_date} onChange={trigger} />
 *     </Fragment>
 *   )}
 * </Form>
 */
