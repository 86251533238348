import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SkillItem(props) {
  const classes = classNames('bullet-item', props.theme);
  return <div className={classes}>{props.name}</div>;
}

SkillItem.propTypes = {
  name: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['default', 'blue']),
};

SkillItem.defaultProps = {
  theme: 'default',
};

export default SkillItem;
