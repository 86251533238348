import { useState, useEffect, useCallback } from 'react';

const useInterviewStatus = videoCall => {
  const MILLISECONDS = 1000;
  const startTime = videoCall.start_time;
  const endTime = videoCall.end_time;
  const [interviewReady, setInterviewReady] = useState(false);

  const checkInterviewReady = useCallback(() => {
    const start = () => new Date(startTime).getTime();
    const end = () => new Date(endTime).getTime();
    const now = () => new Date(Date.now()).getTime();

    if (now() >= start() && now() < end()) {
      setInterviewReady(true);
    }
  }, [endTime, startTime]);

  useEffect(() => {
    checkInterviewReady();

    const intervalId = setInterval(() => {
      checkInterviewReady();
    }, MILLISECONDS);

    return () => clearInterval(intervalId);
  }, [checkInterviewReady]);

  return interviewReady;
};

export default useInterviewStatus;
