import React from 'react';

import Icon from '../Icon';

const ArrowLong = props => {
  const title = `${[props.direction].map(
    d => d.charAt(0).toUpperCase() + d.substring(1),
  )} Arrow`;
  return (
    <Icon title={title} viewBox="0 0 24 24" {...props}>
      <path
        d="m23.7 11.2-9.5-9.5c-.4-.4-1.2-.4-1.6 0-.4.4-.4 1.2 0 1.6l7.6 7.6h-19.1c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1h19.1l-7.6 7.6c-.4.4-.4 1.2 0 1.6s1.2.4 1.6 0 7.1-7.1 9.5-9.5c.4-.5.4-1.2 0-1.6z"
        fill={props.color}
      />
    </Icon>
  );
};

ArrowLong.propTypes = {
  ...Icon.propTypes,
};
ArrowLong.defaultProps = {
  ...Icon.defaultProps,
  direction: 'right',
};

export default ArrowLong;
