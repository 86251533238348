import { useState } from 'react';
import ReactiveRecord from 'reactiverecord';

const useProjectSave = (projectPin, projectId) => {
  const PinModel = ReactiveRecord.model('Pin');
  const [isSaving, setSaving] = useState(false);
  const [pin, setPin] = useState(() => {
    if (projectPin && projectPin.id) {
      return new PinModel(projectPin);
    }
    return null;
  });

  const toggle = async () => {
    setSaving(true);
    if (!pin) {
      const newPin = await PinModel.create({
        pinnable_id: projectId,
        pinnable_type: 'Project',
        pinner_type: 'Mom',
      });
      setPin(newPin);
    } else {
      await pin.destroy();
      setPin(null);
    }
    setSaving(false);
  };

  return {
    pin,
    toggle,
    isSaving,
  };
};

export default useProjectSave;
