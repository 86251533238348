import React from 'react';
import PropTypes from 'prop-types';
import EmployerAppointment from 'resources/employer_appointments/EmployerAppointment';
import LoadingOverlay from 'components/ui/LoadingOverlay';

const RescheduleRequestHandler = ({ appointmentId, children }) => {
  return (
    <EmployerAppointment find={appointmentId}>
      {(appointment, loaded) => {
        return loaded ? children(appointment) : <LoadingOverlay />;
      }}
    </EmployerAppointment>
  );
};

RescheduleRequestHandler.propTypes = {
  appointmentId: PropTypes.number.isRequired,
  children: PropTypes.any,
};

export default RescheduleRequestHandler;
