import React, { useContext } from 'react';
import styled from 'styled-components';
import { VideoContext } from './VideoProvider';
import Hangup from 'components/ui/icons/Hangup';

const DisconnectBtn = styled.button`
  width: 3rem;
  border: none;
  background: transparent;
`;

const DisconnectButton = () => {
  const { localParticipant, room, setHasExitedRoom, setRoomState } =
    useContext(VideoContext);

  const disconnect = () => {
    localParticipant.tracks.forEach(({ track }) => {
      const trackEl = track.detach();
      trackEl.forEach(el => el.remove());
    });

    room.disconnect();
    setRoomState(room.state);
    setHasExitedRoom(true);
  };

  return (
    <DisconnectBtn
      id="disconnect-btn"
      data-testid="disconnect-btn"
      onClick={disconnect}
    >
      <Hangup size="auto" />
    </DisconnectBtn>
  );
};

export default DisconnectButton;
