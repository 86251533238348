import React from 'react';
import PropTypes from 'prop-types';
import themed from 'components/hoc/themed';
import {
  getExperienceLevelDisplayValue,
  Industry,
} from 'components/employer/projects/ProjectSummary/supporting';
import { Label } from 'components/employer/projects/ProjectSummary/ProjectSummaryStyles.js';
import Location from 'components/shared/projects/Location';
import ProjectActivatedAt from 'components/shared/projects/ProjectActivatedAt';
import ProjectApplicants from 'components/shared/projects/ProjectApplicants';

const ProjectDetails = ({ project, talentDisplay }) => {
  return (
    <>
      <div className="mb-4">
        <div>
          <Location
            iconWidth={18}
            locations={project.location_remote_or_city_state_list}
          />
        </div>
        <div className="pt-2">
          <ProjectActivatedAt activatedAt={project.activated_at} />
        </div>
        <div className="pt-2">
          {talentDisplay && (
            <ProjectApplicants
              talentDisplay={talentDisplay}
              applicantCount={project.applicant_count}
            />
          )}
        </div>
      </div>
      <div className="mb-4">
        <Label>Preferred skills</Label>
        <ul>
          {project.capabilities.map(capability => (
            <li className="project-skill-item" key={capability.slug}>
              {capability.title}
            </li>
          ))}
          {project.expertises &&
            project.expertises.map(skill => (
              <li className="project-skill-item" key={skill.id}>
                {skill.label}
              </li>
            ))}
        </ul>
      </div>
      <div className="mb-4">
        <Industry industry={project.industry} />
      </div>
      <div className="mb-4">
        <Label>Experience level</Label>
        {getExperienceLevelDisplayValue(project.preferred_experience_level)}
      </div>
    </>
  );
};

ProjectDetails.propTypes = {
  project: PropTypes.shape({
    location_remote_or_city_state_list: PropTypes.array,
    activated_at: PropTypes.string,
    applicant_count: PropTypes.number,
    capabilities: PropTypes.array,
    expertises: PropTypes.array,
    industry: PropTypes.object,
    preferred_experience_level: PropTypes.string,
  }).isRequired,
  talentDisplay: PropTypes.bool,
};

export default themed(ProjectDetails);
