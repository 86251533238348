import React from 'react';
import PropTypes from 'prop-types';
import ProjectLogo from './ProjectLogo';
import themed from 'components/hoc/themed';

const ProjectCompanyInformation = ({ employer }) => {
  return (
    <div className="d-flex">
      <div className="mr-3 mb-2 d-block">
        <ProjectLogo
          companyName={employer.company_name}
          logoUrl={employer.logo_url}
        />
      </div>
      <div>
        <div className="font-sm text-alt d-flex flex-wrap">
          {employer.company_name ? (
            <div className="mr-1">{employer.company_name}</div>
          ) : (
            <div className="mr-1">Our client</div>
          )}
          {employer.industry_name && (
            <>
              <div className="mr-1 mb-1">-</div>
              <div>{employer.industry_name}&nbsp;company</div>
            </>
          )}
        </div>
        {employer.brief_description && (
          <div className="d-flex font-sm flex-wrap">
            <div>{employer.brief_description}</div>
          </div>
        )}
      </div>
    </div>
  );
};

ProjectCompanyInformation.propTypes = {
  employer: PropTypes.shape({
    brief_description: PropTypes.string,
    company_name: PropTypes.string,
    industry_name: PropTypes.string.isRequired,
    logo_url: PropTypes.string,
  }).isRequired,
};

export default themed(ProjectCompanyInformation);
