import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InterviewSchedulingPageUsage from './InterviewSchedulingPageUsage';
import ButtonGroup from 'components/ui/buttons/ButtonGroup';
import Flex from 'components/ui/Flex';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import RequestInterviewError from 'components/employer/interviews/RequestInterview/RequestInterviewError';

const ContentWrapper = styled(Flex)`
  max-width: 525px;
  margin: 2.5rem auto 0 auto;
`;

const JustMakingSureSchedulingPage = ({
  buttonText,
  cancelButtonText,
  content,
  handleCancel,
  handleContinue,
  schedulingPage,
  showError,
  submitting,
}) => {
  return (
    <Flex alignItems="stretch" direction="column" gap={72}>
      <ContentWrapper alignItems="center" direction="column">
        {submitting && <LoadingOverlay />}
        {showError && (
          <RequestInterviewError
            buttonText="Go to scheduling pages"
            handleOptOut={handleCancel}
            handleTryAgain={handleContinue}
          />
        )}
        {!showError && (
          <>
            <h1 className="mb-4 mt-5">Are you sure?</h1>
            <div className="text-center">
              {!content && (
                <InterviewSchedulingPageUsage
                  schedulingPage={schedulingPage}
                  showLabel={false}
                >
                  <span>Your changes will apply to those interviews.</span>
                </InterviewSchedulingPageUsage>
              )}
              {content}
            </div>
          </>
        )}
      </ContentWrapper>
      {!showError && (
        <ButtonGroup
          id="just-making-sure-btns"
          primary={{
            id: 'just-making-sure-continue',
            onClick: handleContinue,
            text: buttonText || 'Continue to edit',
          }}
          secondary={{
            id: 'go-back',
            onClick: handleCancel,
            text: cancelButtonText || "Don't Change",
          }}
        />
      )}
    </Flex>
  );
};

JustMakingSureSchedulingPage.propTypes = {
  buttonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  content: PropTypes.string,
  handleContinue: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  schedulingPage: PropTypes.object.isRequired,
  showError: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default JustMakingSureSchedulingPage;
