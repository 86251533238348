import React from 'react';
import PropTypes from 'prop-types';

const LinkedIn = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>LinkedIn</title>
      <rect
        x="0.70105"
        y="7.30469"
        width="5.21733"
        height="16.6955"
        fill="currentColor"
      />
      <rect
        x="9.04881"
        y="7.30469"
        width="5.21733"
        height="16.6955"
        fill="currentColor"
      />
      <rect
        x="19.4834"
        y="15.6523"
        width="5.21733"
        height="8.34773"
        fill="currentColor"
      />
      <path
        d="M16.875 12.522C14.3706 12.522 14.2663 14.9568 14.2663 16.1742V10.9568C15.1358 10.0873 17.6585 7.7399 21.0488 8.86999C24.1792 9.91345 24.701 13.0438 24.701 16.1742H19.4836C19.4836 15.1308 18.9619 12.522 16.875 12.522Z"
        fill="currentColor"
      />
      <circle cx="3.30972" cy="2.60867" r="2.60867" fill="currentColor" />
    </svg>
  );
};

LinkedIn.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

LinkedIn.defaultProps = {
  width: 25,
  height: 24,
};

export default LinkedIn;
