import ReactiveRecord, { Model } from 'reactiverecord';

class FeedbackForm extends Model {
  static routes = {
    only: ['index'],
    index: ':prefix/feedback-forms/:name',
  };
  static store = {
    singleton: true,
  };
  static schema = {
    name: String,
    heading: String,
    description: String,
    dismiss_option: String,
    submit_text: String,
    feedback_form_questions: Array,
  };
}

export default ReactiveRecord.model('FeedbackForm', FeedbackForm);
