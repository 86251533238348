import React, { useEffect, useState } from 'react';
import { countCharactersForQuillEditor } from 'utilities/formatting';
import PropTypes from 'prop-types';
import {
  CharacterTracker,
  LabelText,
  RichTextEditorWrapper,
} from 'components/ui/inputs/RichTextEditor/RichTextEditorStyles';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import QuillEditor, {
  toolbarOptions,
  formatOptions,
} from 'components/ui/inputs/RichTextEditor/QuillEditor';

const EMPTY_STRING = '';
const PLURAL = 's';
const REMAINING = 'remaining';
const USED = 'used';

// default 65535 for maxLength matches mysql's default limit for `text` fields
// this fixes a security flaw while maintaining when character count should be displayed
const adjustContentToMaxLength = (editor, maxLength = 65535) => {
  if (maxLength && editor.getLength() > maxLength) {
    editor.deleteText(maxLength);
  }
};

const setInitialContents = (editor, value, maxLength) => {
  if (value) {
    editor.setContents(value, 'silent');
    adjustContentToMaxLength(editor, maxLength);
  }
};

const RichTextEditor = props => {
  const {
    value,
    name,
    format,
    height,
    labelText,
    maxLength,
    onBlur,
    touched,
    errors,
    onChange,
    onFocus,
    showCharacterCount,
    toolbar,
  } = props;
  const [charCount, setCharCount] = useState(0);
  const [charsRemaining, setCharsRemaining] = useState(maxLength);
  const [editor, setEditor] = useState(null);
  const [editorValue, setEditorValue] = useState(value || EMPTY_STRING);

  useEffect(() => {
    if (!editor) {
      const quill = new QuillEditor('#editor', toolbar, format);
      setInitialContents(quill, editorValue, maxLength);
      setCharsRemaining(quill.getCharsRemaining(maxLength));
      setCharCount(countCharactersForQuillEditor(quill.getHTML()));

      quill.on('text-change', () => {
        const content = quill.getText() ? quill.getHTML() : EMPTY_STRING;
        adjustContentToMaxLength(quill, maxLength);
        setEditorValue(content);
        onChange(content);
        setCharsRemaining(quill.getCharsRemaining(maxLength));
        setCharCount(countCharactersForQuillEditor(quill.getHTML()));
      });

      quill.on('selection-change', range => {
        if (!range && onBlur) {
          onBlur(quill.getText());
        } else if (range && onFocus) {
          onFocus();
        }
      });
      setEditor(quill);
    }
  }, [
    maxLength,
    onBlur,
    onFocus,
    props,
    editor,
    editorValue,
    toolbar,
    format,
    onChange,
  ]);

  const formatCountText = (count, descriptor) => {
    let pluralizer = count === 1 ? EMPTY_STRING : PLURAL;
    return `${count} character${pluralizer} ${descriptor}.`;
  };
  const displayCharacterCount = () => !maxLength && showCharacterCount;
  const hasError = touched && touched[name] && errors && errors[name];
  return (
    <RichTextEditorWrapper height={height} hasError={hasError}>
      {labelText && <LabelText>{labelText}</LabelText>}
      <div id="editor" data-testid="editor"></div>
      {maxLength && (
        <CharacterTracker>
          {formatCountText(charsRemaining, REMAINING)}
        </CharacterTracker>
      )}
      {displayCharacterCount() && (
        <CharacterTracker>{formatCountText(charCount, USED)}</CharacterTracker>
      )}
      {hasError && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </RichTextEditorWrapper>
  );
};

RichTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  format: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  showCharacterCount: PropTypes.bool,
  toolbar: PropTypes.array,
  touched: PropTypes.object,
  errors: PropTypes.object,
};

RichTextEditor.defaultProps = {
  value: EMPTY_STRING,
  format: formatOptions,
  height: 'auto',
  showCharacterCount: false,
  toolbar: toolbarOptions,
};

export default RichTextEditor;
