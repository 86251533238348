import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Options from './SelectableOptions';
import MultiSelectManager from '../ui/MultiSelectManager';

class TalentCapabilities extends React.Component {
  handleClick = item => {
    const { props } = this;
    props.onChange(props.stateKey, item.id);
  };

  getFormattedOptions() {
    const { props } = this;
    return props.options.map(option => ({ id: option[0], title: option[1] }));
  }

  render() {
    const props = this.props;
    const capabilitiesClasses = classNames('form-group col-12 questionnaire', {
      validate: props.validate,
    });
    return (
      <MultiSelectManager
        controlled={true}
        onChange={props.onChange}
        maxLength={props.maxLength}
        initialSelected={props.selected}
      >
        {({ selected, onChange }) => (
          <div className="row">
            <div className={capabilitiesClasses}>
              <div
                className="font-md font-medium"
                id="required-capabilities-label"
              >
                {props.label}
                {props.validate && (
                  <span className="required-mark">*</span>
                )}{' '}
              </div>
              {props.helpText ? (
                <div className="text-alt font-sm mt-1 mb-3">
                  {props.helpText}
                </div>
              ) : (
                <div className="text-alt font-sm mb-3" />
              )}
              <Options
                cols={2}
                options={this.getFormattedOptions()}
                selected={selected}
                handleClick={item => {
                  onChange(props.stateKey, item.id);
                }}
                targetName="Capability"
              />
            </div>
          </div>
        )}
      </MultiSelectManager>
    );
  }
}

TalentCapabilities.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  cols: PropTypes.number.isRequired,
  helpText: PropTypes.string,
  maxLength: PropTypes.number,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  stateKey: PropTypes.string,
  validate: PropTypes.bool,
};

export default TalentCapabilities;
