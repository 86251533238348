import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Box } from 'components/ui/Box';
import DollarSign from 'components/ui/icons/DollarSign';
import Clock from 'components/ui/icons/Clock';
import XCircle from 'components/ui/icons/XCircle';
import LocationPin from 'components/ui/icons/LocationPin';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import LinkButton from 'components/ui/buttons/LinkButton';
import styled, { css } from 'styled-components';
import theme from 'theme';
import Flex from 'components/ui/Flex';
import { IconWrapper, StatusLabel } from './styles';
import { useMediaSet } from 'use-media-set';
import { colors, mediaSetQueries } from 'styleGuide';
import { URLS } from 'consts';
import { getFormattedDate, getTimeFromNow } from 'utilities';
import useProjectSave from './useProjectSave';

const SectionWithBorder = styled.div(
  ({ theme }) => css`
    @media (min-width: ${theme.screenSizes.tablet}) {
      border-left: 1px solid ${theme.lightGray};
      margin-left: 2rem;
      padding-left: 2rem;
    }
  `,
);

const IconWithText = ({ icon, children }) => {
  return (
    <div className="mb-2">
      <IconWrapper iconColor={colors.gray.dark}>{icon}</IconWrapper>
      {children}
    </div>
  );
};

IconWithText.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

const ProjectSavedCard = ({ onProjectSave, project }) => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const isSmallScreen = mediaStates.has('mobileOrSmTablet');
  const daysAgo = getTimeFromNow(project.activated_at);
  const savedOn = getFormattedDate(new Date(project.pin.created_at), {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const { toggle, isSaving } = useProjectSave(project.pin, project.id);

  const handleRemove = async () => {
    await toggle();
    if (onProjectSave) {
      onProjectSave(project.id, false);
    }
  };

  return (
    <Box
      backgroundColor={theme.lightBlue}
      data-testid="project-card"
      className="mt-3 p-4"
    >
      <StatusLabel backgroundColor="#49c8a5">Saved</StatusLabel>
      <Flex
        direction="column"
        alignItems="stretch"
        className={isSmallScreen ? 'mt-5' : ''}
      >
        <div>
          <a className="font-md" href={URLS.project(project.slug)}>
            <strong>{project.project_title}</strong>
          </a>
          <div>
            Saved on: <strong>{savedOn}</strong>
          </div>
        </div>
        <hr className="mt-4 mb-4" />
        <Row>
          <Col className="col-md-7 col-12">
            <Flex
              alignItems="stretch"
              className="font-sm font-light"
              direction={isSmallScreen ? 'column' : 'row'}
            >
              <div>
                <IconWithText
                  icon={<Clock width="20" fillColor="currentColor" />}
                >
                  Posted {daysAgo}
                </IconWithText>
                <IconWithText icon={<LocationPin width="20" />}>
                  {project.location_remote_or_city_state_list.join(', ')}
                </IconWithText>
              </div>
              <SectionWithBorder>
                <IconWithText icon={<DollarSign width="20" />}>
                  {project.marketplace_compensation}
                </IconWithText>
              </SectionWithBorder>
            </Flex>
          </Col>
          <Col className="col-md-5 col-12">
            {isSmallScreen && <hr className="mt-3 mb-3" />}
            <Flex
              direction={isSmallScreen ? 'column' : 'row'}
              gap={20}
              reverse={isSmallScreen}
              alignItems={isSmallScreen ? 'flex-start' : 'center'}
              justifyContent={isSmallScreen ? 'flex-start' : 'flex-end'}
            >
              <ButtonLink
                id={`remove-saved-button-${project.slug}`}
                className="p-0"
                disabled={isSaving}
                onClick={handleRemove}
                data-testid={`remove-saved-button-${project.slug}`}
              >
                <XCircle width="20" height="20" /> Remove
              </ButtonLink>
              <LinkButton
                id={`project-button-${project.slug}`}
                data-testid={`project-button-${project.slug}`}
                className="d-block d-sm-inline-block"
                href={URLS.project(project.slug)}
                secondary
                size={isSmallScreen ? 'md' : 'sm'}
              >
                Apply Now
              </LinkButton>
            </Flex>
          </Col>
        </Row>
      </Flex>
    </Box>
  );
};

ProjectSavedCard.propTypes = {
  onProjectSave: PropTypes.func,
  project: PropTypes.object.isRequired,
};

export default ProjectSavedCard;
