/* eslint react/prop-types: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { PREFERRED_HOURS, TALENT_AVAILABILITIES, URLS } from 'consts';
import { numberValidator } from 'utilities';
import PropTypes from 'prop-types';
import Questionnaire from './Questionnaire';
import Radio from 'components/ui/inputs/Radio';
import MultiSelect from 'components/ui/MultiSelectManager';
import { SalaryField, HourlyField } from './CompensationFields';
import { TalentFlexWrapper } from './styles';
import AsteriskHelperText from './AsteriskHelperText';

const TalentFlexFactors = ({
  availability,
  employment_status,
  preferred_hours,
  exclude_by_min_salary,
  minimum_hourly_rate_cents,
  minimum_salary_cents,
  onChange,
  preferred_remote,
  validate,
}) => {
  const [excludeByMinSalary, setExcludeByMinSalary] = useState(
    exclude_by_min_salary,
  );

  const availability_details = {
    label: 'What is your current availability?*',
    fieldType: 'checkbox',
    fieldName: 'availability',
    options: TALENT_AVAILABILITIES,
  };

  const employmentStatusDetails = {
    contract_without_benefits: 'Contract without benefits',
    contract_with_benefits: {
      label: 'Contract with benefits',
      description:
        'Many contract roles offer typical benefits such as health insurance, retirement benefits, and more. Access to benefits may be subject to a waiting period and have associated costs.',
    },
    parttime: 'Contract to permanent',
    fulltime: 'Permanent employee',
  };

  const employment_status_details = {
    label: 'Which type of roles are you open to?*',
    fieldType: 'checkbox',
    fieldName: 'employment_status',
    options: employmentStatusDetails,
  };

  const preferred_remote_details = {
    label: 'Where can you work?*',
    fieldType: 'checkbox',
    fieldName: 'preferred_remote',
    options: {
      office: 'Fully on-site',
      remote: 'Fully remote',
      combination: 'Partially remote',
      unsure: 'No preference',
    },
  };

  const exclude_by_min_salary_details = {
    fieldType: 'radio',
    fieldName: 'exclude_by_min_salary',
  };

  const display = {
    hourly: {
      labelText: 'per hour',
      value: minimum_hourly_rate_cents ? minimum_hourly_rate_cents / 100 : null,
    },
    salary: {
      labelText: 'per year',
      value: minimum_salary_cents ? minimum_salary_cents / 100 : null,
    },
  };

  const remotePercentage = useRef();
  useEffect(() => {
    if (remotePercentage.current) {
      numberValidator(remotePercentage.current, 3, 100);
    }
  }, [remotePercentage]);

  const helpTextOne = 'Select the one that applies.';
  const helpTextAll = 'Select all that apply.';

  const handleSalaryValueChange = (event, compensationType) => {
    const compensationValue = event.floatValue || 0;
    if (compensationType === 'salary' && compensationValue > 0) {
      onChange('minimum_salary_cents', compensationValue * 100);
    } else if (compensationType === 'salary' && compensationValue < 1) {
      onChange('minimum_salary_cents', null);
    }
    if (compensationType === 'hourly' && compensationValue > 0) {
      onChange('minimum_hourly_rate_cents', compensationValue * 100);
    } else if (compensationType === 'hourly' && compensationValue < 1) {
      onChange('minimum_hourly_rate_cents', null);
    }
  };

  return (
    <TalentFlexWrapper className="sop-container container">
      <div className="mb-3">
        <AsteriskHelperText />
        <Questionnaire
          {...availability_details}
          selected={availability}
          onChange={onChange}
          helpText={helpTextOne}
          validate={validate}
          maxLength={1}
          targetName="Employment Status"
        />
      </div>
      <div className="mb-3">
        <Questionnaire
          {...employment_status_details}
          selected={employment_status}
          onChange={onChange}
          helpText={helpTextAll}
          validate={validate}
          targetName="Employment Status"
        />
      </div>
      <div className="mb-3">
        <Questionnaire
          {...PREFERRED_HOURS}
          selected={preferred_hours}
          onChange={onChange}
          helpText={helpTextAll}
          validate={validate}
          targetName="Preferred Hours"
        />
      </div>
      <div className="mb-3">
        <Questionnaire
          {...preferred_remote_details}
          selected={preferred_remote}
          onChange={onChange}
          helpText={helpTextOne}
          validate={validate}
          maxLength={1}
          targetName="Preferred Remote"
        />
      </div>
      <div className="mb-3">
        <div className="font-md font-medium mb-3">
          Do you want to <strong>only</strong> be matched with jobs that are
          above a certain compensation amount?
        </div>
        <MultiSelect
          {...exclude_by_min_salary_details}
          onChange={onChange}
          initialSelected={exclude_by_min_salary}
          maxLength={1}
        >
          {({ onChange: onRadioChange, selected }) => {
            return (
              <>
                <Radio
                  className="mt-2"
                  checked={selected === false}
                  data-testid="exclude-false"
                  labelText="No"
                  onChange={() => {
                    setExcludeByMinSalary(false);
                    onRadioChange(
                      exclude_by_min_salary_details.fieldName,
                      false,
                    );
                  }}
                />
                <Radio
                  className="mt-2"
                  checked={selected === true}
                  data-testid="exclude-true"
                  labelText="Yes"
                  onChange={() => {
                    setExcludeByMinSalary(true);
                    onRadioChange(
                      exclude_by_min_salary_details.fieldName,
                      true,
                    );
                  }}
                />
              </>
            );
          }}
        </MultiSelect>
      </div>
      {excludeByMinSalary && (
        <>
          <p className="mb-4">
            To receive the best matches, please share the minimum threshold at
            which you&apos;d start considering roles. You will only be presented
            with opportunities that meet or exceed your requirements.&ensp;
            <a
              href={URLS.salaryGuidance}
              target="_blank"
              rel="noopener noreferrer"
            >
              Need more help?
            </a>
          </p>
          <p className="primary-label mb-4">
            You may enter either Annual Salary, Hourly Rate, or both. Annual and
            hourly rates do not need to be equivalents.*
          </p>

          <SalaryField
            inputProps={display.salary}
            defaultValue={display.salary.value}
            onValueChange={event => handleSalaryValueChange(event, 'salary')}
          />
          <HourlyField
            inputProps={display.hourly}
            defaultValue={display.hourly.value}
            onValueChange={event => handleSalaryValueChange(event, 'hourly')}
          />

          <small>
            *Salary to hourly conversion assumes 2080 hours per year (40 hours
            per week x 52 weeks per year). If you only enter one field, we will
            calculate an equivalent value for the other field.
          </small>
        </>
      )}
    </TalentFlexWrapper>
  );
};

const components = {
  talent: TalentFlexFactors,
};

class FlexFactors extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { mode } = this.props;
    const Component = components[mode];
    return <Component {...this.props} />;
  }
}

FlexFactors.propTypes = {
  columns: PropTypes.number,
  location: PropTypes.string,
  mode: PropTypes.oneOf(['talent', 'employer']),
};

FlexFactors.defaultProps = {
  columns: 2,
};

export default FlexFactors;
