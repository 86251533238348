import styled from 'styled-components';

import { colors, screenSize } from 'styleGuide';
import Flex from 'components/ui/Flex';

export const ContentWrapper = styled(Flex)`
  min-height: 500px;
  width: ${props => (props.isSmallScreen ? '90vw' : '80vw')};
  margin: 0 auto 2rem;
`;

export const StyledInterviewee = styled.div`
  position: relative;
  padding: 16px;
  margin: 16px auto;
  background: white;
  .interview-date-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    line-height: 30px;
    justify-content: center;
  }
  &.max-width {
    max-width: 800px;
  }
  &.cancelled {
    .interview-date-time {
      color: $md-grey-1;
    }
  }
  &.has-direct-dial-details {
    margin-bottom: 0;
  }
  &.interview-call-details {
    background-color: #f3f7ff;
    color: ${colors.gray.dark};
    margin-top: 0;
    text-align: center;

    .number {
      font-weight: bold;
    }

    .helper {
      color: ${colors.gray.medium};
    }
  }
  .align-baseline {
    align-items: baseline;
  }
  .list-inside {
    list-style-position: inside;
  }
  @media (max-width: ${screenSize.mobileL}) {
    padding: 16px 8px 30px;
    .interview-date-time {
      flex-direction: column;
    }
    .action-buttons {
      border-top: 1px solid #ddd;
      display: flex;
      flex-direction: row;
    }
    .action-buttons button,
    .action-buttons a {
      margin: 0 auto;
      text-align: center;
    }
  }
`;

export const IntervieweeAvatar = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  .talent-avatar {
    margin-right: 16px;
  }
  .avatar-img {
    width: 98px;
    height: 98px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
  }
`;

export const InterviewDateAndTime = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const PendingTag = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${colors.warning};
  color: ${colors.white};
  padding: 4px 10px;
`;
