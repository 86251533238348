import ReactiveRecord, { Model } from 'reactiverecord';

class DemographicSurveySubmission extends Model {
  static routes = {
    create: ':prefix/moms/demographic_surveys',
  };

  static schema = {
    demographic_survey_responses: Array,
  };
}

export default ReactiveRecord.model(
  'DemographicSurveySubmission',
  DemographicSurveySubmission,
);
