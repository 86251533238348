import React from 'react';
import classNames from 'classnames';

import Header from '.';
import { Link, List, ListItem } from './stylesV2';
import DropdownListItem from './DropdownListItem';
import LogoutLink from './LogoutLink';
import { TalentAvatar } from 'components/shared/TalentAvatar';
import { URLS } from 'consts';
import CurrentUser from 'resources/current_user/CurrentUser';
import { useAppSettings } from 'utilities/hooks';

const EmployerHeaderV2 = props => {
  const { show_talent_clouds_ui } = useAppSettings();

  let activeSection = '';
  const route = window.location.pathname;
  const navItems = [
    ...(show_talent_clouds_ui
      ? [
          {
            display: 'Talent clouds',
            name: 'clouds',
            section: 'clouds',
            url: URLS.employerClouds,
          },
        ]
      : []),
    {
      display: 'Job posts',
      name: 'projects',
      section: 'post-job',
      url: URLS.employerProjects,
    },
    {
      display: 'Interviews',
      name: 'appointments',
      section: 'appointments',
      url: URLS.employerAppointments,
    },
    {
      display: 'Dashboard',
      name: 'home',
      section: 'home',
      url: URLS.employerHome,
    },
    {
      display: 'Settings',
      inMenu: true,
      name: 'settings',
      section: 'settings',
      url: URLS.employerSettings,
    },
  ].map(item => {
    const output = {
      display: item.display,
      inMenu: item.inMenu || false,
      name: item.name,
      section: item.section,
      url: item.url,
    };
    if (output.url === URLS.employerHome) {
      if (route === URLS.employerHome) {
        activeSection = output.section;
      }
    } else if (route.indexOf(output.url) > -1) {
      activeSection = output.section;
    }
    return output;
  });
  const tabs = navItems.filter(s => !s.inMenu);
  const menuItems = navItems.filter(s => s.inMenu);

  return (
    <Header {...props}>
      <List className="ml-auto">
        {tabs.map(tab => {
          return (
            <ListItem
              className={classNames({
                'is-active': activeSection === tab.section,
              })}
              key={tab.section}
            >
              <Link data-section={tab.section} href={tab.url}>
                {tab.display}
              </Link>
            </ListItem>
          );
        })}
        <CurrentUser>
          {(currentUser, loaded) =>
            loaded && (
              <DropdownListItem
                className="hidden-mobile dropdown-btn"
                label={
                  <TalentAvatar
                    className="header-avatar"
                    firstName={currentUser.first_name}
                    lastName={currentUser.last_name}
                    photoUrl={currentUser.avatar_url}
                    size="xxs"
                  />
                }
              >
                {menuItems.map(item => {
                  return (
                    <Link
                      data-section={item.section}
                      href={item.url}
                      key={item.section}
                    >
                      {item.display}
                    </Link>
                  );
                })}
                <LogoutLink logoutURL={URLS.employerLogout} />
              </DropdownListItem>
            )
          }
        </CurrentUser>
        {menuItems.map(item => {
          return (
            <ListItem
              className={classNames('mobile-only-item', {
                'is-active': activeSection === item.section,
              })}
              key={item.section}
            >
              <Link data-section={item.section} href={item.url}>
                {item.display}
              </Link>
            </ListItem>
          );
        })}
        <ListItem className="mobile-only-item">
          <LogoutLink logoutURL={URLS.employerLogout} />
        </ListItem>
      </List>
    </Header>
  );
};

export default EmployerHeaderV2;
