import React from 'react';

export default function DownloadIcon(props) {
  return (
    <svg viewBox="0 0 22 21.7" {...props}>
      <title>Download Icon</title>
      <g transform="translate(-124 -190)" fill="currentColor">
        <path d="M130.39,200.8h0a1.064,1.064,0,0,1,1.507,0l3.1,3.1,3.1-3.1a1.064,1.064,0,0,1,1.507,0h0a1.064,1.064,0,0,1,0,1.507l-3.885,3.882a1.028,1.028,0,0,1-1.458-.005l-3.877-3.877A1.064,1.064,0,0,1,130.39,200.8Z" />
        <path d="M136,205h-2V191a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1Z" />
        <path d="M144,205v3.2a1.5,1.5,0,0,1-1.5,1.5h-15a1.5,1.5,0,0,1-1.5-1.5V205a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v3.7a3,3,0,0,0,3,3h16a3,3,0,0,0,3-3V205a1,1,0,0,0-1-1h0A1,1,0,0,0,144,205Z" />
      </g>
    </svg>
  );
}
