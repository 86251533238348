import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CollapsibleContainer from 'components/ui/CollapsibleContainer';
import FilterHeader from 'components/ui/FilterHeader';
import { colors } from 'styleGuide';
import { toKebabCase } from 'utilities';

const Accordion = ({
  bordered,
  children,
  defaultShow,
  dense,
  headerIcon,
  onExpand,
  expandedTitle,
  showBottomAccordion,
  title,
}) => {
  const [expanded, setExpanded] = useState(defaultShow);
  const [headerTitle, setHeaderTitle] = useState(title);
  const elementId = `${toKebabCase(title)}-container`;

  const toggle = () => {
    const isExpanding = expanded === false;
    setExpanded(!expanded);
    if (isExpanding && onExpand) {
      onExpand();
    }
    if (!expanded && expandedTitle) {
      setHeaderTitle(expandedTitle);
    } else {
      setHeaderTitle(title);
    }
  };

  return (
    <div className="d-flex flex-column align-items-stretch">
      <FilterHeader
        aria-controls={elementId}
        aria-expanded={expanded}
        bordered={bordered}
        caretColor={colors.blue.primary}
        collapsible={true}
        dense={dense}
        headerIcon={headerIcon}
        headerText={headerTitle}
        open={expanded}
        setOpen={toggle}
      />
      <CollapsibleContainer className="mb-3" isOpen={expanded} id={elementId}>
        {children}
        {showBottomAccordion && (
          <FilterHeader
            aria-controls={elementId}
            aria-expanded={expanded}
            bordered={bordered}
            caretColor={colors.blue.primary}
            collapsible={true}
            dense={dense}
            headerText={headerTitle}
            open={expanded}
            setOpen={toggle}
          />
        )}
      </CollapsibleContainer>
    </div>
  );
};

Accordion.propTypes = {
  bordered: PropTypes.bool,
  children: PropTypes.any,
  defaultShow: PropTypes.bool.isRequired,
  expandedTitle: PropTypes.string,
  dense: PropTypes.bool,
  headerIcon: PropTypes.node,
  onExpand: PropTypes.func,
  showBottomAccordion: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
  bordered: false,
  defaultShow: false,
  dense: false,
  showBottomAccordion: false,
  onExpand: () => null,
};

export default Accordion;
