import styled from 'styled-components';
import { without, PreventableEvent } from 'utilities';
import { getInputSharedStyles } from 'components/ui/inputs/InputSharedStyles';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.lightGray : theme.lightBlue};
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lightGray};
  .magnifying-glass {
    position: absolute;
    right: 12px;
    margin-top: 1rem;
    pointer-events: none;
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.mediumGray : theme.primaryBlue};
  }
`;

export const LabelContainer = styled.div`
  line-height: 38px;

  &.with-values {
    padding: 0.75em 0 0;
  }
`;

export const RemoveLabelHandle = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.white};
  svg {
    color: white;
  }
`;

export const Label = styled.span`
  position: relative;
  box-sizing: content-box;
  display: inline-block;
  padding: 7px 36px 7px 10px;
  margin-left: 15px;
  line-height: 16px;
  color: white;
  vertical-align: top;
  background-color: ${({ theme }) => theme.darkGray};
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  ${getInputSharedStyles()}
  background-color: transparent;
  box-shadow: none;
`;

export function normalize(array, asBoolean = true) {
  return array.reduce((obj, item) => {
    if (asBoolean) {
      obj[item] = true;
      return obj;
    } else {
      return { ...obj, ...item };
    }
  }, {});
}

export function denormalize(obj) {
  return Object.keys(obj);
}

export const List = styled.div`
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.lightGray},
    0 15px 15px ${({ theme }) => theme.darkGray.alpha(0.3)};
  -webkit-overflow-scrolling: touch;
  z-index: 206; /* Current Rails dialog is set to 205 */
`;

export const ListItem = styled.div`
  display: block;
  padding: 5px 15px;
  color: ${({ theme }) => theme.darkGray};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primaryBlue};
    color: ${({ theme }) => theme.white};
  }
  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.primaryBlue};
    color: ${theme.white};
  `}
`;

function getListAndPortalStyle(inputElem, isPortalDescendant) {
  const { width, bottom, left } = inputElem.getBoundingClientRect();
  const { pageXOffset, pageYOffset } = window;
  const portalStyle = isPortalDescendant
    ? {
        position: 'relative',
        top: '3px',
      }
    : {
        position: 'absolute',
        left: `${pageXOffset + left}px`,
        top: `${pageYOffset + bottom + 3}px`,
      };
  return [
    {
      position: 'absolute',
      left: '0',
      top: '0',
      width: `${width}px`,
      maxHeight: `calc(100vh - ${bottom + 10}px)`,
    },
    portalStyle,
  ];
}

export function generateAdd(
  usingStateValue,
  value,
  mergeState,
  onChange,
  inputRef,
) {
  return id => {
    value[id] = true;
    inputRef.current.value = '';
    if (usingStateValue) {
      mergeState({ stateValue: value, ariaActiveDescendant: null });
      /**
       * TODO: At this point, the position of the input could have changed. It
       * should be time to update the position of the listbox.
       */
    }
    setTimeout(() => {
      if (onChange) {
        onChange(new PreventableEvent(denormalize(value)));
      }
    }, 0);
  };
}

export function generateRemove(usingStateValue, value, mergeState, onChange) {
  return id => {
    const nextValue = without.call(value, id);
    if (usingStateValue) {
      mergeState({ stateValue: nextValue });
    }
    setTimeout(() => {
      if (onChange) {
        onChange(new PreventableEvent(denormalize(nextValue)));
      }
    }, 0);
  };
}

export function generateStoreInput(value, forwardedRef, inputRef) {
  return inputElem => {
    inputRef.current = inputElem;
    if (inputElem) {
      forwardedRef({
        get value() {
          return denormalize(value);
        },
      });
      return;
    }
    forwardedRef(null);
  };
}

export function generateHandleFocus(
  onFocus,
  state,
  inputRef,
  setState,
  isPortalDescendant,
) {
  return event => {
    if (onFocus) {
      onFocus(event);
      if (event.defaultPrevented) {
        return;
      }
    }
    if (!state.inputHasFocus) {
      const [listStyle, portalStyle] = getListAndPortalStyle(
        inputRef.current,
        isPortalDescendant,
      );
      setState({
        ...state,
        inputHasFocus: true,
        multiSelectHasFocus: true,
        listStyle,
        portalStyle,
      });
    }
  };
}

export function generateHandleBlur(
  onBlur,
  inputHasFocus,
  mergeState,
  wrapperRef,
) {
  return event => {
    if (onBlur) {
      onBlur(event);
      if (event.defaultPrevented) {
        return;
      }
    }
    setTimeout(() => {
      const nextState = {
        inputHasFocus: false,
        multiSelectHasFocus: false,
        ariaActiveDescendant: null,
        listStyle: {},
        portalStyle: {},
      };
      if (!wrapperRef.current.contains(document.activeElement)) {
        mergeState(nextState);
        return;
      }
      if (inputHasFocus) {
        nextState.multiSelectHasFocus = true;
        mergeState(nextState);
      }
    }, 0);
  };
}

export function generateHandleLabelKeyDown(onKeyDown, remove) {
  return id => event => {
    if (onKeyDown) {
      onKeyDown(event);
      if (event.defaultPrevented) {
        return;
      }
    }
    if (event.key.match(/(?: |Enter)/)) {
      event.preventDefault();
      remove(id);
      /* TODO: At this point, the label that previously had focus is gone. Move focus to the next label. */
    }
  };
}

export function generateHandleLabelClick(onClick, remove) {
  return id => event => {
    if (onClick) {
      onClick(event);
      if (event.defaultPrevented) {
        return;
      }
    }
    remove(id);
  };
}

export function generateHandleChange(
  onChange,
  children,
  state,
  setState,
  filterResults = false,
) {
  return event => {
    if (onChange) {
      onChange(event);
      if (event.defaultPrevented) {
        return;
      }
    }
    const searchTerm = event.target.value;
    const results = filterResults
      ? Object.keys(children).filter(key => {
          if (state.searchTerm) {
            return (
              children[key]
                .toLowerCase()
                .indexOf(state.searchTerm.toLowerCase()) > -1
            );
          }
          return true;
        })
      : children;
    const nextResults = filterResults
      ? Object.keys(children).filter(key => {
          if (searchTerm) {
            return (
              children[key].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
            );
          }
          return true;
        })
      : children;
    if (JSON.stringify(results) !== JSON.stringify(nextResults)) {
      setState({ ...state, searchTerm, ariaActiveDescendant: null });
      return;
    }
  };
}

export function generateHandleKeyDown(
  onKeyDown,
  state,
  add,
  listRef,
  optionValueMapping,
  renderedOptions,
  setState,
  inputRef,
) {
  return event => {
    if (onKeyDown) {
      onKeyDown(event);
      if (event.defaultPrevented) {
        return;
      }
    }
    if (event.key === 'Enter' && state.ariaActiveDescendant) {
      event.preventDefault();
      event.stopPropagation();
      add(optionValueMapping[state.ariaActiveDescendant]);
      inputRef.current.blur();
    }
    if (event.key.match(/Arrow(?:Up|Down)/) && listRef.current) {
      event.preventDefault();
      event.stopPropagation();
      let nextActiveIndex =
        event.key === 'ArrowDown' ? 0 : renderedOptions.length - 1;
      if (state.ariaActiveDescendant) {
        const adjustment = event.key === 'ArrowDown' ? 1 : -1;
        const currentIndex = renderedOptions.indexOf(
          state.ariaActiveDescendant,
        );
        nextActiveIndex = (currentIndex + adjustment) % renderedOptions.length;
      }
      const [nextAriaActiveDescendant] = renderedOptions.slice(nextActiveIndex);
      setState({ ...state, ariaActiveDescendant: nextAriaActiveDescendant });
      setTimeout(() => {
        try {
          document
            .getElementById(nextAriaActiveDescendant)
            .scrollIntoView({ block: 'nearest' });
        } catch (_e) {
          // continue regardless of error
        }
      }, 0);
    }
  };
}
