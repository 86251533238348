import ReactiveRecord, { Model } from 'reactiverecord';

class CloudMember extends Model {
  static routes = {
    show: ':prefix/employers/clouds_member',
  };
  static store = {
    singleton: true,
  };
  static schema = {
    about_me: String,
    activity_feed: Array,
    availability: Object,
    all_capabilities_titles: Array,
    city_state: String,
    clouds_with_membership_through_admin: Array,
    exclude_by_min_salary: Boolean,
    experiences_list: Array,
    expertises_labels: Array,
    full_name: String,
    id: Number,
    industries_names: Array,
    is_opted_in_to_dtc: Boolean,
    minimum_hourly_rate_cents: Number,
    minimum_salary_cents: Number,
    portal_path_full: String,
    profile_resume_url: Object,
    talent_clouds: Array,
    website: String,
  };

  get minSalaryCentsDisplayValue() {
    if (this._attributes.minimum_salary_cents) {
      return this._attributes.minimum_salary_cents / 100;
    }
    return null;
  }

  get minHourlyRateCentsDisplayValue() {
    if (this._attributes.minimum_hourly_rate_cents) {
      return this._attributes.minimum_hourly_rate_cents / 100;
    }
    return null;
  }
}

export default ReactiveRecord.model('CloudMember', CloudMember);
