import React from 'react';
import PropTypes from 'prop-types';

import TalentProfile from 'containers/employer_application/bids/talent-profile/TalentProfile';
import stored from 'components/hoc/stored';
import Flex from 'components/ui/Flex';

const InterviewModalHeader = ({
  mom,
  centeredAndSpaced,
  heading,
  subheader,
}) => {
  return (
    <Flex
      alignItems={centeredAndSpaced ? 'center' : 'stretch'}
      direction="column"
      gap={centeredAndSpaced ? 72 : 16}
    >
      <h1 className={`fancy ${centeredAndSpaced ? 'text-center' : null}`}>
        {heading}
      </h1>
      {subheader && <p>{subheader}</p>}
      <Flex alignItems="center" direction="row" gap={16}>
        <TalentProfile mom={mom} />
      </Flex>
    </Flex>
  );
};

InterviewModalHeader.propTypes = {
  centeredAndSpaced: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  mom: PropTypes.object.isRequired,
  subheader: PropTypes.string,
};

export default stored(InterviewModalHeader);
