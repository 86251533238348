import styled from 'styled-components';
import { colors } from 'styleGuide';

export const StickySectionWrapper = styled.div`
  position: sticky;
  padding-top: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  padding-bottom: 16px;
  z-index: 1;

  &.sticky {
    border-bottom: 1px solid ${colors.gray.light};
  }
`;
