import React from 'react';
import moment from 'moment-timezone';
import RescheduleRequestButton from './RescheduleRequestButton';
import PropTypes from 'prop-types';
import { colors } from 'styleGuide';

import {
  StyledInterviewee,
  IntervieweeAvatar,
  InterviewDateAndTime,
} from './styles';
import CancelInterviewButton from './CancelInterviewButton';
import { FullDateAndTime, TalentInfo } from './supporting';
import WithdrawnInterviewee from './WithdrawnInterviewee';
import { APPOINTMENT_TYPES_LABEL } from 'consts';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import LinkButton from 'components/ui/buttons/LinkButton';
import Phone from 'components/ui/icons/Phone';

const Interviewee = React.memo(props => {
  let time;
  const {
    selected_appointment_date_time,
    talent_name,
    project_title,
    is_confirmed,
    is_withdrawn,
    bid_withdrawn,
    masked_number,
    appointment_type,
    scheduled_via_nylas,
  } = props.data.attributes;
  const { application_link, project_link, cancel_link, video_call_link } =
    props.data.links;
  const { handleOnReschedule } = props;
  const appointmentDate = new Date(selected_appointment_date_time);
  const monthName = appointmentDate.toLocaleString('default', {
    month: 'long',
  });
  const day = appointmentDate.getDate();
  const dayOfWeek = appointmentDate.toLocaleDateString('default', {
    weekday: 'long',
  });
  const year = appointmentDate.toLocaleDateString('default', {
    year: 'numeric',
  });
  const isConfirmedAndUpcoming = is_confirmed && !props.isPast;
  const hasDirectDialDetails = masked_number ? 'has-direct-dial-details ' : '';
  const showJoinVideo =
    appointment_type === APPOINTMENT_TYPES_LABEL.video &&
    isConfirmedAndUpcoming;
  const showPhoneNumber =
    masked_number &&
    appointment_type === APPOINTMENT_TYPES_LABEL.phone &&
    isConfirmedAndUpcoming;

  if (is_confirmed) {
    time = moment(appointmentDate).format('LT z');
  }

  if (is_withdrawn) {
    return (
      <WithdrawnInterviewee
        application_link={application_link}
        project_title={project_title}
        talent_name={talent_name}
      />
    );
  } else {
    return (
      <>
        <StyledInterviewee
          className={
            'interviewee box-shadow-sm d-flex flex-column justify-content-between ' +
            hasDirectDialDetails
          }
        >
          <div className="row m-0">
            <IntervieweeAvatar className="col-lg-4 col-md-4 col-12 p-0 align-baseline">
              <TalentInfo
                application_link={application_link}
                project_link={project_link}
                project_title={project_title}
                talent_name={talent_name}
                showTalentLink={!bid_withdrawn}
              />
            </IntervieweeAvatar>

            {is_confirmed ? (
              <InterviewDateAndTime className="col-lg-4 col-md-4 col-12 mt-3 mt-sm-0 align-baseline">
                <FullDateAndTime
                  monthName={monthName}
                  dayOfWeek={dayOfWeek}
                  day={day}
                  year={year}
                  time={time}
                />
              </InterviewDateAndTime>
            ) : (
              <div className="col-lg-4 col-md-4 col-12 mt-3 mt-sm-0 align-baseline">
                <p>
                  Invite sent. Candidate will choose a time for a{' '}
                  <b>{appointment_type}</b> interview.
                </p>
                {scheduled_via_nylas ? null : (
                  <p>
                    Need to make a change to the proposed times? We get it; life
                    is hectic.&nbsp;
                    <ButtonLink
                      data-id={`reschedule-appointment-${props.data.id}`}
                      onClick={() => {
                        handleOnReschedule(props.data);
                      }}
                    >
                      Edit your availability
                    </ButtonLink>
                    .
                  </p>
                )}
              </div>
            )}

            <div className="action-buttons flex-wrap col-lg-4 col-md-4 col-12 mt-3 mt-sm-0 pt-3 pt-sm-0 justify-content-start align-baseline">
              {showJoinVideo && (
                <LinkButton
                  id="join-interview-button"
                  data-testid="join-interview-button"
                  className="mb-2 text-white"
                  href={video_call_link}
                >
                  Join video interview
                </LinkButton>
              )}
              {showPhoneNumber && (
                <div className="font-bolder">
                  <Phone color={colors.black} height={16} width={16} />
                  {}
                  <span>
                    &nbsp;Call <span className="number">{masked_number}</span>*
                  </span>
                </div>
              )}
              {isConfirmedAndUpcoming && !bid_withdrawn && (
                <>
                  <RescheduleRequestButton
                    data-id="reschedule-request-btn"
                    onClick={() => {
                      handleOnReschedule(props.data);
                    }}
                  />
                </>
              )}
              {!props.isPast && !bid_withdrawn && (
                <CancelInterviewButton
                  data-id="cancel-interview-btn"
                  className="mb-3"
                  cancelLink={cancel_link}
                  interview={props.data}
                />
              )}
            </div>
          </div>
        </StyledInterviewee>
        {masked_number &&
          isConfirmedAndUpcoming &&
          appointment_type !== APPOINTMENT_TYPES_LABEL.video && (
            <StyledInterviewee className="interviewee box-shadow-sm interview-call-details">
              <p className="helper">
                *This is a temporary phone number and will only be available
                during the interview window.
              </p>
            </StyledInterviewee>
          )}
      </>
    );
  }
});

Interviewee.displayName = 'Interviewee';

Interviewee.propTypes = {
  data: PropTypes.object.isRequired,
  handleOnReschedule: PropTypes.func,
  isPast: PropTypes.bool,
};

export default Interviewee;
