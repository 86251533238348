import React from 'react';
import { createRoot } from 'react-dom/client';
import Dialog from './index';
import { Provider } from 'react-redux';
import ReactiveRecord from 'reactiverecord';
import Button from '../buttons/Button';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

export default function alertDialog({ children, cta = 'OK', ...props }) {
  const { store } = ReactiveRecord;
  const renderElement = document.createElement('DIV');
  document.body.appendChild(renderElement);
  const root = createRoot(renderElement);
  function handleCleanup(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    root.unmount(root);
    document.body.removeChild(renderElement);
  }

  root.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Dialog
          show
          narrow
          onHide={handleCleanup}
          role="alertdialog"
          {...props}
        >
          <div className="mr-3 ml-3">{children}</div>
          <div className="mt-5 text-right">
            <Button
              data-id="alert-dialog-btn"
              size="sm"
              onClick={handleCleanup}
            >
              {cta}
            </Button>
          </div>
        </Dialog>
      </Provider>
    </ThemeProvider>,
  );
}
