import React from 'react';
import PropTypes from 'prop-types';
import backBlue from 'assets/icons/back-blue@3x.png';
import backWhite from 'assets/icons/back-white@3x.png';

const BackButton = props => {
  const classNames = ['btn', 'btn-link', 'font-bold'];
  if (props.inverse) {
    classNames.push('back-button--inverse');
  } else {
    classNames.push('back-button');
  }
  if (props.fixed) {
    classNames.push('back-button--fixed');
  }
  return (
    <button
      data-id="back-btn"
      className={classNames.join(' ')}
      onClick={props.action}
    >
      <img
        src={props.inverse ? backBlue : backWhite}
        style={{
          verticalAlign: 'middle',
          width: '7px',
          height: '12px',
          marginRight: '8px',
          marginBottom: '3px',
        }}
        alt=""
      />
      {props.label}
    </button>
  );
};

BackButton.propTypes = {
  action: PropTypes.func.isRequired,
  inverse: PropTypes.bool,
  fixed: PropTypes.bool,
  label: PropTypes.string,
};

BackButton.defaultProps = {
  label: 'Back',
};

export default BackButton;
