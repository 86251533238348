import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Location = props => {
  const containerClassnames = classNames(
    'location-container text-left pl-0 ',
    props.containerClassnames,
  );
  return (
    <div className={containerClassnames}>
      <span className="font-heavy d-block mb-1">Location</span>
      <span>
        {props.remotePercentage === 100
          ? 'Remote'
          : props.cityStateList.join(' ')}
      </span>
    </div>
  );
};

Location.propTypes = {
  cityStateList: PropTypes.array.isRequired,
  containerClassnames: PropTypes.string,
  remotePercentage: PropTypes.number,
};

export default Location;
