import React from 'react';
import PropTypes from 'prop-types';

import { directionDegree } from 'styleGuide';

const Icon = ({
  children,
  color,
  direction,
  height,
  size,
  title,
  width,
  ...props
}) => {
  let widthForIcon;
  let heightForIcon;
  let css = `
    color: ${color};    
  `;

  if (direction) {
    css += `
      transform: rotate(${directionDegree[direction]});
      transition: transform 500ms ease-in-out;
    `;
  }

  if ((width || /0/.test(width)) && (height || /0/.test(width))) {
    widthForIcon = isNaN(width) ? width : `${width}px`;
    heightForIcon = isNaN(height) ? height : `${height}px`;
  } else {
    const formattedSize = isNaN(size) ? size : `${size}px`;
    widthForIcon = formattedSize;
    heightForIcon = formattedSize;
  }

  css += `
    height: ${heightForIcon};
    width: ${widthForIcon};
    min-height: ${heightForIcon};
    min-width: ${widthForIcon};
  `;

  const hasTitle = title && title.length > 0;
  return (
    <svg
      aria-hidden={hasTitle ? false : true}
      aria-labelledby={hasTitle ? title : 'none'}
      css={css}
      focusable="false"
      height="100%"
      role="img"
      version="1.1"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {hasTitle && <title>{title}</title>}
      {children}
    </svg>
  );
};

Icon.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.oneOf(Object.keys(directionDegree)),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Icon.defaultProps = {
  color: 'currentColor',
  size: '1em',
};

export default Icon;
