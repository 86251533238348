import React from 'react';
import PropTypes from 'prop-types';
import SkillItem from './SkillItem';

const SkillItems = props => {
  return props.items.map(value => <SkillItem name={value} key={value} />);
};

SkillItems.propTypes = {
  items: PropTypes.array.isRequired,
};

export default SkillItems;
