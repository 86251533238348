import React from 'react';
import { colors } from 'styleGuide';

export default function PlusCircle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" {...props}>
      <path
        d="M12.5 0A12.5 12.5 0 1025 12.5 12.5 12.5 0 0012.5 0z"
        fill="currentColor"
      />
      <path
        d="M16.5 11.5h-3v-3a1 1 0 00-1-1 1 1 0 00-1 1v3h-3a1 1 0 00-1 1 1 1 0 001 1h3v3a1 1 0 001 1 1 1 0 001-1v-3h3a1 1 0 001-1 1 1 0 00-1-1z"
        fill={colors.white}
      />
    </svg>
  );
}
