import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/employers/projects';
const memberRoute = `${collectionRoute}/:id`;

class Project extends Model {
  static routes = {
    only: 'show',
    show: memberRoute,
  };

  static schema = {
    id: Number,
    employer: Object,
    project_title: String,
  };
}

export default ReactiveRecord.model('Project', Project);
