import styled from 'styled-components';

import Button, { getSharedButtonStyles } from 'components/ui/buttons/Button';
import { colors } from 'styleGuide';

const getSecondaryStyles = () => {
  return `&:hover {
    background-color: ${colors.blue.primary}30 !important;
    color: ${colors.blue.dark} !important;
  }`;
};

const LinkButton = styled.a(
  props => `
    ${
      // INFO: Preventing styles from application.sass overriding SharedButtonStyle logic
      props.secondary ? getSecondaryStyles() : ''
    };
    ${getSharedButtonStyles(props)}
    text-align: center;
  `,
);

LinkButton.propTypes = Button.propTypes;

LinkButton.defaultProps = Button.defaultProps;

export default LinkButton;
