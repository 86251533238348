import React from 'react';
import PropTypes from 'prop-types';

const ProjectCompanyBriefDescription = ({ briefDescription }) => {
  return (
    <>
      {briefDescription && (
        <div className="d-flex font-sm flex-wrap">
          <div>{briefDescription}</div>
        </div>
      )}
    </>
  );
};

ProjectCompanyBriefDescription.propTypes = {
  briefDescription: PropTypes.string,
};

export default ProjectCompanyBriefDescription;
