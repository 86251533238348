// client headshots
export { default as briar } from './clients/briar-2x.jpg';
export { default as alida } from './clients/alida-2x.jpg';
export { default as christian } from './clients/christian-2x.jpg';
export { default as christine } from './clients/christine-2x.jpg';
export { default as dalana } from './clients/dalana-2x.jpg';
// talent headshots
export { default as libby } from './talent/Moms-Libby.png';
export { default as sneha } from './talent/Moms-Sneha.png';
export { default as kim } from './talent/Moms-Kim.png';
// icons
export { default as matchLogo } from './icons/match@3x.png';
export { default as marketplaceLogo } from './icons/marketplace@3x.png';
export { default as menuMobileDarkGray } from './icons/mobile-menu-icon-dark-gray.png';
export { default as marketplaceBlack } from './icons/nav-marketplace@3x-black.png';
export { default as marketplaceActive } from './icons/nav-marketplace@3x-blue.png';
export { default as profileActive } from './icons/profile@3x.png';
export { default as profile } from './icons/nav-profile@3x-black.png';
// hero images
export { default as welcomeHero } from './heroes/welcome-bg.jpg';
export { default as mediaKitMobile } from './heroes/media-kit-hero-mobile.jpg';
export { default as mediaKitDesktop } from './heroes/media-kit-hero-desktop.jpg';
// leadership
export { default as allison } from './leadership/Allison.jpg';
// Resume Rev assets
export { default as resume } from './resume_rev/resumeRev-resume.jpg';
export { default as rallyLogo } from './resume_rev/resumerev-rally-lockup.png';
export { default as videoCard1 } from './resume_rev/resume-rev-video-card-images-01.jpg';
export { default as videoCard2 } from './resume_rev/resume-rev-video-card-images-02.jpg';
export { default as videoCard3 } from './resume_rev/resume-rev-video-card-images-03.jpg';
export { default as videoCard4 } from './resume_rev/resume-rev-video-card-images-04.jpg';
export { default as videoCard5 } from './resume_rev/resume-rev-video-card-images-05.jpg';
export { default as videoCard6 } from './resume_rev/resume-rev-video-card-images-06.jpg';
export { default as videoCard7 } from './resume_rev/resume-rev-video-card-images-07.jpg';
export { default as videoCard8 } from './resume_rev/resume-rev-video-card-images-08.jpg';
export { default as videoCard9 } from './resume_rev/resume-rev-video-card-images-09.jpg';
export { default as videoCard10 } from './resume_rev/resume-rev-video-card-images-10.jpg';
