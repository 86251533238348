import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse } from 'react-bootstrap';
function CollapsibleContainer({ isOpen, children, ...props }) {
  return (
    <Collapse in={isOpen}>
      <CollapsibleInnerContainer {...props}>
        {children}
      </CollapsibleInnerContainer>
    </Collapse>
  );
}

CollapsibleContainer.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

const CollapsibleInnerContainer = styled.div`
  overflow: hidden;
`;

export default CollapsibleContainer;
