import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ErrorMessage from '../labels/ErrorMessage';
import { colors } from '../../../styleGuide';

export const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
  label {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.fontSizes.base};
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 35px;
    display: inline-block;
    align-items: center;
    &:before {
      content: '';
      height: 25px;
      width: 25px;
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${({ theme }) => theme.white};
      transition: all 0.3s;
      border: 1px solid ${({ theme }) => theme.darkGray};
    }
    &:after {
      content: '';
      color: ${({ theme }) => theme.white};
      height: 8px;
      width: 14px;
      border-left: 3px solid;
      border-bottom: 3px solid;
      transform: rotate(-45deg);
      position: absolute;
      left: 5.4px;
      top: 7px;
      transition: all 0.3s;
    }
  }

  input[type='checkbox']:disabled {
    :not(:checked) + label {
      color: ${({ theme }) => theme.headerFontColor};
      &:before {
        border: 1px solid ${({ theme }) => theme.headerFontColor};
      }
    }
    :checked + label {
      color: ${({ theme }) => theme.headerFontColor};
      &:before {
        background: ${colors.gray.light};
        border: 1px solid ${colors.gray.light};
      }
      &:after {
        color: ${({ theme }) => theme.headerFontColor};
      }
    }
  }

  input[type='checkbox'] + label::after {
    content: none;
  }

  input[type='checkbox']:checked + label::after {
    content: '';
  }

  input[type='checkbox']:checked + label::before {
    background: ${({ theme }) => theme.primaryBlue};
    border: none;
  }

  input[type='checkbox']:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
`;

const InputCheckbox = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
`;

/**
 * Checkbox that returns either true, false or given value when checked.
 */
const Checkbox = ({
  disabled,
  errors,
  touched,
  name,
  id,
  labelText,
  labelTestId,
  onChange,
  value,
  checked,
}) => {
  const generatedId = useMemo(
    () => `formik-checkbox-${Checkbox.idCounter++}`,
    [],
  );
  const fieldId = id || generatedId;

  return (
    <CheckboxContainer>
      <InputCheckbox
        id={fieldId}
        disabled={disabled}
        name={name}
        type="checkbox"
        onClick={e => onChange && onChange(e)}
        value={value}
        defaultChecked={checked}
      />
      <label htmlFor={fieldId} data-testid={labelTestId}>
        {labelText}
      </label>
      {touched && touched[name] && errors && errors[name] && (
        <ErrorMessage>{errors[name]}</ErrorMessage>
      )}
    </CheckboxContainer>
  );
};
Checkbox.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.any,
  onChange: PropTypes.func,
  id: PropTypes.any,
  labelTestId: PropTypes.string,
  value: PropTypes.any,
  touched: PropTypes.object,
  checked: PropTypes.bool,
};
Checkbox.idCounter = 0;
Checkbox.displayName = 'Checkbox';

export default Checkbox;
