import ReactiveRecord, { Model } from 'reactiverecord';

class Title extends Model {
  static routes = {
    only: 'show',
    show: ':prefix/employers/titles/search',
  };

  static schema = {
    category: String,
    has_active_projects: Boolean,
    id: Number,
    limit: String,
    name: String,
    page: String,
    pagination: String,
    per: String,
    query: String,
    titles: Array,
    total: String,
  };
}

export default ReactiveRecord.model('Title', Title);
