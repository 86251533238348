import React from 'react';

import Icon from '../Icon';

const HamburgerMenu = props => {
  return (
    <Icon viewBox="0 0 30 22" {...props}>
      <g fill={props.color} transform="translate(-324 -20)">
        <rect width="2" height="30" rx="1" transform="rotate(90 167 187)" />
        <rect width="2" height="30" rx="1" transform="rotate(90 162 192)" />
        <rect width="2" height="30" rx="1" transform="rotate(90 157 197)" />
      </g>
    </Icon>
  );
};

HamburgerMenu.propTypes = {
  ...Icon.propTypes,
};
HamburgerMenu.defaultProps = {
  ...Icon.defaultProps,
};

export default HamburgerMenu;
