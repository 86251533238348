import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const Layout = props => {
  return (
    <>
      <Helmet>
        <title>
          {[props.title, 'The Mom Project'].filter(Boolean).join(' | ')}
        </title>
        {props.subtitle && <meta name="description" content={props.subtitle} />}
      </Helmet>
      {props.children}
    </>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
};

export default Layout;
