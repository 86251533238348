import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import VideoAccessTokenForm from './VideoAccessTokenForm';
import VideoCallFinishedMom from './VideoCallFinishedMom';
import VideoQualityFeedback from './VideoQualityFeedback';

import { VideoContext } from './VideoProvider';

const VideoContainer = styled.div(
  ({ theme }) => css`
    align-items: center;
    aspect-ratio: 4/3;
    border: 1px solid ${theme.lightGray};
    display: flex;
    justify-content: center;
    width: 100%;
  `,
);

const VideoCallExited = ({ identity, roomName, videoCall, resourceType }) => {
  const isEmployer = resourceType === 'Employer';
  const { localAudioTrack, localVideoTrack } = useContext(VideoContext);

  useEffect(() => {
    localVideoTrack.stop();
    localAudioTrack.stop();
  }, [localAudioTrack, localVideoTrack]);

  return (
    <div id="video-call-exited" className="pt-5 pb-5">
      <Container>
        <Row>
          <Col sm={12} md={isEmployer ? 12 : 7} lg={7}>
            <VideoContainer>
              <VideoAccessTokenForm
                identity={identity}
                roomName={roomName}
                secondary
                setAlreadyJoined={() => null}
                submitBtnEnabled
              />
            </VideoContainer>
          </Col>
          <Col sm={12} md={isEmployer ? 12 : 5} lg={5}>
            <div className="d-flex flex-column justify-content-center h-100">
              {resourceType === 'Mom' && (
                <VideoCallFinishedMom videoCall={videoCall} />
              )}
              {resourceType === 'Employer' && <VideoQualityFeedback />}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

VideoCallExited.propTypes = {
  identity: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  videoCall: PropTypes.object.isRequired,
};

export default VideoCallExited;
