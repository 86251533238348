import ReactiveRecord, { Model } from 'reactiverecord';
const collectionRoute = ':prefix/moms/appointments';
const memberRoute = `${collectionRoute}/:id`;

class Appointment extends Model {
  static routes = {
    index: collectionRoute,
    show: memberRoute,
    update: memberRoute,
  };

  static schema = {
    bid_id: String,
    appointment_action: String,
    appointment_type: String,
    appointment_dates: Array,
    available_dates: Array,
    cancelled: String,
    employer_company_name: String,
    interview_feedback_created_at: String,
    nylas_booking_url: String,
    nylas_reschedule_url: String,
    preferred_dates: String,
    preferred_dates_array: Array,
    preferred_times_array: Array,
    preferred_times: Array,
    position_name: String,
    potential_dates: Array,
    potential_times: Array,
    redirect_url: String,
    talent_phone_number: String,
    talent_preferred_timezone: String,
    thank_you_deadline: Date,
    thankable: Boolean,
    time_zone: String,
    video_call: Object,
  };
}

export default ReactiveRecord.model('Appointment', Appointment);
