import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import FadeInOutMount from 'components/ui/FadeInOutMount';
import { listItemSharedStyles, MenuItem } from './stylesV2';
import { getNextTooltipPositioning } from 'utilities';
import { HeaderLink } from './PublicHeaderStyles';

const spacing = 0;
const navbarHeight = 73.5;

// LabelWrapper for "Small Companies", "Careers and Community", "Enterprise"
export const LabelWrapper = styled.li(
  ({ theme, hasFocus }) => css`
    &::marker {
      font-size: 0;
    }
    text-align: center;
    font-weight: ${theme.fontWeights.fontMedium};
    cursor: pointer;
    &:hover ${HeaderLink} {
      color: ${theme.primaryBlue};
      font-weight: ${theme.fontWeights.fontHeavy};
      &::marker {
        font-size: 0;
      }
    }
    @media (min-width: ${theme.screenSizes.laptopS}) {
      font-size: ${theme.fontSizes.base};
      padding: 16px;
    }
    &::after {
      display: block;
      content: attr(data-text);
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: bold;
    }
    ${hasFocus
      ? `
      font-weight: ${theme.fontWeights.fontHeavy};
      color: ${theme.primaryBlue};
      `
      : ''}
    ${listItemSharedStyles({ theme })}
  `,
);

// Links that appear in dropdown menu: Small Companies & Enterprise
const PopupWrapper = styled.div(
  ({ theme }) => `
  overflow: hidden;
  background-color: ${theme.white};
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 1rem 0;
  ${MenuItem} {
    min-width: 158px;
    padding: 0.5rem 1rem;
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeights.fontMedium};
    color: ${theme.darkestGray};
    text-align: left;
    text-transform: none;
    &:hover {
      color: ${theme.white};
      background-color: ${theme.primaryBlue};
    }
  }
`,
);

const HeaderDropdownListItem = ({ label, children, ...props }) => {
  const [show, setShow] = useState(false);
  const triggerRef = useRef(null);
  const tooltipPositioning = useRef({ portalStyle: {}, popupStyle: {} });

  const handleShow = useCallback(() => {
    if (!show) {
      tooltipPositioning.current = getNextTooltipPositioning(
        triggerRef.current,
        spacing,
      );
      setShow(true);
    }
  }, [show]);
  const handleHide = useCallback(() => {
    setShow(false);
  }, []);

  const handleClickAway = event => {
    if (!triggerRef.current.contains(event.target)) {
      handleHide();
    }
  };
  const handleTab = event => {
    // hide menu if we tab away from it
    if (
      event.key === 'Tab' &&
      !triggerRef.current.contains(event.target) &&
      show
    ) {
      handleHide();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickAway, true);
    document.addEventListener('keyup', handleTab, true);
    return () => {
      document.removeEventListener('click', handleClickAway, true);
      document.removeEventListener('keyup', handleTab, true);
    };
  });

  return (
    <LabelWrapper
      ref={triggerRef}
      tabIndex="0"
      {...props}
      hasFocus={show}
      onFocus={handleShow}
      onMouseEnter={handleShow}
      onMouseLeave={handleHide}
    >
      <HeaderLink as="div">{label}</HeaderLink>
      <FadeInOutMount in={show} timeout={180}>
        <div
          style={{
            position: 'absolute',
            top: `${navbarHeight - 16}px`,
            zIndex: '5',
          }}
        >
          <PopupWrapper onMouseEnter={handleShow}>{children}</PopupWrapper>
        </div>
      </FadeInOutMount>
    </LabelWrapper>
  );
};

HeaderDropdownListItem.propTypes = {
  label: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

export default HeaderDropdownListItem;
