import React, { useState } from 'react';
import ReactiveRecord from 'reactiverecord';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaSet } from 'use-media-set';
import { mediaSetQueries } from 'styleGuide';
import Button from 'components/ui/buttons/Button';
import LinkButton from 'components/ui/buttons/LinkButton';
import NumericRating from 'components/ui/NumericRating';
import Checkbox, { CheckboxContainer } from 'components/ui/inputs/Checkbox';
import Flex from 'components/ui/Flex';
import FeedbackFormResource from 'resources/feedback_form/FeedbackForm';
import { URLS } from 'consts';

const StyledCheckboxContainer = styled.div`
  ${CheckboxContainer} label {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const FeedbackForm = ({ formData }) => {
  formData.feedback_form_questions.sort((a, b) => {
    return a['display_order'] - b['display_order'];
  });
  const [rating, setRating] = useState(null);
  const [responses, setResponses] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const FeedbackFormSubmission = ReactiveRecord.model('FeedbackFormSubmission');
  const ratingQuestion = formData.feedback_form_questions[0];
  const additionalQuestions = formData.feedback_form_questions.slice(1);
  const mediaStates = useMediaSet(mediaSetQueries);
  const isMobile = mediaStates.has('mobile');

  const handleSubmitForm = (event, formData) => {
    event.preventDefault();
    const submission = new FeedbackFormSubmission({
      id: formData.id,
      feedback_form_responses: [
        {
          feedback_form_question_id: ratingQuestion.id,
          response: rating,
        },
        ...responses,
      ],
    });
    submission.save();
    setSubmitted(true);
  };

  const handleResponse = (questionId, checked) => {
    const newResponses = responses.filter(
      response => response.feedback_form_question_id !== questionId,
    );
    if (checked) {
      newResponses.push({
        feedback_form_question_id: questionId,
        response: 1,
      });
    }
    setResponses(newResponses);
  };

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className="flex-grow-1 mt-3"
    >
      {!submitted ? (
        <>
          <div>
            <h2 className="font-xl mb-4">{formData.heading}</h2>
            <div className="form-group">
              <div className="mb-3">{ratingQuestion.label}</div>
              <div className="d-inline-block">
                <NumericRating
                  onChange={rate => setRating(rate)}
                  maxValue={5}
                />
                <Flex className="mt-3" justifyContent="space-between">
                  <span className="small">Not Satisfied</span>
                  <span className="small">Very Satisfied</span>
                </Flex>
              </div>
            </div>

            {rating !== null && rating < 4 && (
              <StyledCheckboxContainer className="mt-4">
                {additionalQuestions.map(question => {
                  const elementId =
                    question.feedback_form_elements[0].element_id;
                  const elementName =
                    question.feedback_form_elements[0].element_name;
                  return (
                    <Checkbox
                      key={elementId}
                      id={elementId}
                      onChange={e =>
                        handleResponse(question.id, e.target.checked)
                      }
                      name={elementName}
                      labelText={question.label}
                      value={elementName}
                    />
                  );
                })}
              </StyledCheckboxContainer>
            )}
          </div>
          <Flex
            className="mt-3 w-100"
            direction={isMobile ? 'column' : 'row'}
            reverse={isMobile}
            justifyContent="flex-start"
            alignItems="stretch"
            gap={16}
          >
            <LinkButton
              id="go-to-dashboard"
              href={URLS.employerHome}
              secondary
              noBorder
            >
              Go to dashboard
            </LinkButton>
            <Button
              id="feedback-button"
              type="submit"
              disabled={rating === null}
              onClick={event => handleSubmitForm(event, formData)}
            >
              {formData.submit_text}
            </Button>
          </Flex>
        </>
      ) : (
        <div>
          <h2 className="font-xl mb-3">Thanks for sharing your feedback!</h2>
          <p>
            We are committed to giving you the best experience we can. We use
            your feedback to make our platform easier to use.
          </p>
          <LinkButton
            href={URLS.employerHome}
            className="mt-4 d-block d-sm-inline-block"
            id="go-to-dashboard"
          >
            Go to dashboard
          </LinkButton>
        </div>
      )}
    </Flex>
  );
};

FeedbackForm.propTypes = {
  formData: PropTypes.object.isRequired,
};

const VideoQualityFeedback = () => {
  return (
    <FeedbackFormResource where={{ name: 'video_quality' }}>
      {(feedbackForm, loaded) =>
        loaded && feedbackForm.heading ? (
          <FeedbackForm formData={feedbackForm} />
        ) : null
      }
    </FeedbackFormResource>
  );
};

export default VideoQualityFeedback;
