import React from 'react';

function Pencil(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M12.29.309a3.45 3.45 0 00-2.48 1.008c-.255.252-.255.63 0 .85l1.558 1.545-7.6 7.563-1.146-1.134 8.041-7.945c.255-.253.255-.63 0-.851-.254-.252-.636-.252-.859 0L1.318 9.699a.856.856 0 00-.19.379l-.382 4.569c0 .189.063.378.19.504a.673.673 0 00.414.158h.096l4.391-.578c.35-.032.573-.347.54-.693-.03-.316-.349-.568-.667-.536l-3.628.483.222-2.49 3.085 3.057a.63.63 0 00.445.19.63.63 0 00.446-.19l8.427-8.35c1.336-1.324 1.4-3.498.096-4.822-.7-.662-1.559-1.04-2.513-1.071zM5.77 13.26l-1.144-1.134 7.569-7.5L13.34 5.76l-7.57 7.5zm8.397-8.445L11.145 1.82c.35-.189.732-.283 1.114-.283.604 0 1.176.252 1.59.693.668.693.763 1.765.318 2.584z"
        fillRule="nonzero"
        fill="currentColor"
      />
    </svg>
  );
}

export default Pencil;
