import React from 'react';
import publicTheme from 'publicTheme';
import Icon from 'components/ui/Icon';

export default function VideoOn(props) {
  return (
    <Icon viewBox="0 0 46 46" {...props}>
      <g fill="#FFF" fillRule="evenodd">
        <g>
          <g>
            <g
              fill={publicTheme.primaryBlue.toString()}
              transform="translate(-753 -559) translate(753 559)"
            >
              <circle cx="23" cy="23" r="23" />
            </g>
            <g
              stroke={publicTheme.primaryBlue.toString()}
              transform="translate(-753 -559) translate(753 559) translate(9 14)"
            >
              <path d="M20.572 6.08l5.881-3.839c.463-.302 1.083-.171 1.384.291.107.163.163.353.163.547V16.33c0 .552-.448 1-1 1-.164 0-.326-.04-.47-.118l-5.958-3.18h0V6.08z" />
              <rect width="20" height="18" x=".5" y=".5" rx="2" />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
}

VideoOn.propTypes = { ...Icon.propTypes };
