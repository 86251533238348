import React from 'react';
import PropTypes from 'prop-types';
import { colors, fontSizes, screenSize } from 'styleGuide';
import styled from 'styled-components';

const StyledUl = styled.ul`
  margin: 0 15px;
  max-height: 320px;
  display: flex;
  flex-flow: column wrap;

  @media (max-width: ${screenSize.tablet}) {
    max-height: 500px;
  }

  @media (max-width: ${screenSize.mobileL}) {
    max-height: none;
  }
`;

const StyledLi = styled.li`
  font-size: ${fontSizes.md};
  font-weight: 500;
  line-height: 1.67;
  color: ${colors.gray.dark};
`;

const ProfileList = props => {
  return (
    <StyledUl>
      {props.children.map(child => {
        return <StyledLi key={child.id}>{child.label}</StyledLi>;
      })}
    </StyledUl>
  );
};

ProfileList.propTypes = {
  children: PropTypes.array.isRequired,
};

export default ProfileList;
