import React from 'react';
import PropTypes from 'prop-types';
import { Step } from './styles';
import Scrollspy from 'react-scrollspy';
import themed from 'components/hoc/themed';

const Stepper = ({ steps, stepItems, offset }) => {
  return (
    <Scrollspy
      className="d-flex justify-content-between flex-row position-relative p-0 m-auto text-center text-uppercase"
      currentClassName="is-active"
      offset={offset}
      items={stepItems}
    >
      {steps.map(step => {
        return (
          <Step
            key={step.name}
            active={step.active}
            complete={step.complete}
            className="position-relative w-100"
          >
            {step.text}
          </Step>
        );
      })}
    </Scrollspy>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  stepItems: PropTypes.array.isRequired,
  offset: PropTypes.number,
};

export default themed(Stepper);
