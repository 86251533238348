import React from 'react';

const Post = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill="currentColor"
        d="M81.717,210.112v14.557a1.617,1.617,0,0,0,1.617,1.617h12.94a1.617,1.617,0,0,0,1.617-1.617V210.112a1.618,1.618,0,0,0-1.617-1.617H83.334A1.618,1.618,0,0,0,81.717,210.112Zm14.557,14.557H83.334V210.112h12.94Z"
        transform="translate(-81.717 -208.495)"
      />
      <g transform="translate(3.235 5.219)">
        <rect
          fill="currentColor"
          width="0.809"
          height="9.705"
          rx="0.404"
          transform="translate(9.705) rotate(90)"
        />
        <rect
          fill="currentColor"
          width="0.809"
          height="9.705"
          rx="0.404"
          transform="translate(9.705 3.31) rotate(90)"
        />
        <rect
          fill="currentColor"
          width="0.809"
          height="9.705"
          rx="0.404"
          transform="translate(9.705 6.545) rotate(90)"
        />
      </g>
    </svg>
  );
};

export default Post;
