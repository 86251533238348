import React from 'react';
import PropTypes from 'prop-types';

const QuestionMark = ({ fillColor = '#464A59', ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Circle Question</title>
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Timing/Compensation---7"
          transform="translate(-883.000000, -863.000000)"
          fill={fillColor}
          fillRule="nonzero"
        >
          <g id="Group-4" transform="translate(731.000000, 843.000000)">
            <g
              id="2-Icon/Alert/Circle-Question"
              transform="translate(152.000000, 20.000000)"
            >
              <path
                d="M2.92909091,2.92909091 C6.83454545,-0.976363636 13.1663636,-0.976363636 17.0709091,2.92909091 C20.9763636,6.83454545 20.9763636,13.1663636 17.0709091,17.0718182 C13.1663636,20.9763636 6.83454545,20.9763636 2.92909091,17.0718182 C-0.976363636,13.1663636 -0.976363636,6.83454545 2.92909091,2.92909091 Z M15.7854545,4.21454545 C12.59,1.01909091 7.41,1.01909091 4.21454545,4.21454545 C1.01909091,7.41 1.01909091,12.59 4.21454545,15.7854545 C7.41,18.9809091 12.59,18.9809091 15.7854545,15.7854545 C18.9809091,12.59 18.9809091,7.41 15.7854545,4.21454545 Z M10.0991818,13.3689091 C10.601,13.3689091 11.0082727,13.7761818 11.0082727,14.278 C11.0082727,14.7798182 10.601,15.1870909 10.0991818,15.1870909 C9.59736364,15.1870909 9.19009091,14.7798182 9.19009091,14.278 C9.19009091,13.7761818 9.59736364,13.3689091 10.0991818,13.3689091 Z M9.84127273,4.81736364 C11.5449091,4.72827273 12.9549091,6.08372727 12.9549091,7.76827273 C12.9549091,8.49372727 12.6112727,9.06827273 12.2067273,9.61281818 C11.6221818,10.3982727 10.9067273,10.5737273 10.9067273,11.5928182 L10.9067273,11.5928182 L10.9067273,11.9937273 C10.9067273,12.4955455 10.5003636,12.9028182 9.99763636,12.9028182 C9.49581818,12.9028182 9.08854545,12.4955455 9.08854545,11.9937273 C9.08854545,11.4328182 9.11672727,10.8237273 9.35581818,10.3055455 C9.67309091,9.61918182 10.2303636,9.13736364 10.7049091,8.56827273 C10.8976364,8.33736364 11.1367273,8.08554545 11.1367273,7.76827273 C11.1367273,7.14009091 10.6276364,6.63190909 10.0003636,6.63190909 C9.37218182,6.63190909 8.864,7.14009091 8.864,7.76827273 C8.864,8.27009091 8.45672727,8.67736364 7.95490909,8.67736364 C7.47127273,8.67736364 7.04945455,8.30372727 7.05672727,7.82009091 C7.11218182,6.26827273 8.28218182,4.89918182 9.84127273,4.81736364 Z"
                id="Circle-Question"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

QuestionMark.propTypes = {
  fillColor: PropTypes.string,
};

export default QuestionMark;
