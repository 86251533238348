import React from 'react';
import PropTypes from 'prop-types';
import ShowToggle from 'components/ui/ShowToggle';
import PlusCircleButton from 'components/ui/buttons/PlusCircleButton';
import EditUsersModal from './EditUsersModal';
import { useCurrentUser } from 'requests';

const EditUsers = ({ project, disabled = false }) => {
  const { data: currentUser } = useCurrentUser();

  const isProjectCreator =
    project.creator_id === (currentUser && currentUser.id) &&
    project.creator_type === 'User';

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <strong>Collaborators:</strong> {project.collaborators_full_names}
      {currentUser && (currentUser.admin || isProjectCreator) ? (
        <ShowToggle preventDefault>
          {(show, toggle) => (
            <>
              <div className="text-center text-md-left d-block d-md-inline-block pt-2 pt-md-0">
                <PlusCircleButton
                  data-id="edit-collaborators-btn"
                  className="w-100"
                  disabled={disabled}
                  onClick={toggle}
                >
                  Edit collaborators
                </PlusCircleButton>
              </div>
              {show && (
                <EditUsersModal
                  userId={currentUser.id}
                  handleClose={toggle}
                  project={project}
                />
              )}
            </>
          )}
        </ShowToggle>
      ) : null}
    </>
  );
};

EditUsers.propTypes = {
  disabled: PropTypes.bool,
  project: PropTypes.object.isRequired,
};

export default EditUsers;
