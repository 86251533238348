import React from 'react';
import PropTypes from 'prop-types';

function InputFile(props) {
  return <input type="file" {...props} />;
}

InputFile.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  datasource: PropTypes.string,
};

export default InputFile;
