import React, { forwardRef, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../labels/ErrorMessage';
import { Validate } from 'reactiverecord';
import { Input, Label, Wrapper } from './CheckboxToggleStyles';

const CheckboxToggle = forwardRef(
  (
    { labelText, uncheckedValue, checkedLabel, uncheckedLabel, ...props },
    forwardedRef,
  ) => {
    const generatedId = useMemo(
      () => `checkbox-toggle-${CheckboxToggle.idCounter++}`,
      [],
    );
    const id = props.id || generatedId;
    const storeValidate = useRef(null);

    const handleRef = useCallback(
      validateRef => ref => {
        const refInterface = {
          get isValid() {
            return storeValidate.current.isValid.bind(storeValidate.current);
          },
          get checked() {
            return ref.checked;
          },
          get value() {
            if (ref.checked) {
              return typeof props.value === 'undefined' ? true : props.value;
            }
            return typeof uncheckedValue === 'undefined'
              ? false
              : uncheckedValue;
          },
        };

        validateRef(refInterface);

        if (forwardedRef) {
          forwardedRef(refInterface);
        }
      },
      [forwardedRef, props.value, uncheckedValue],
    );

    if (
      Object.prototype.hasOwnProperty.call(props, 'defaultValue') &&
      !Object.prototype.hasOwnProperty.call(props, 'defaultChecked')
    ) {
      props.defaultChecked = !!props.defaultValue;
    }

    return (
      <Validate {...props} ref={storeValidate}>
        {({ ref: validateRef, ...validateProps }, errorText, validating) => (
          <>
            <Wrapper htmlFor={id} title={labelText}>
              <Input
                id={id}
                disabled={validating}
                {...validateProps}
                ref={handleRef(validateRef)}
              />
              <Label>
                <b className="checked-label">{checkedLabel}</b>
                <b className="unchecked-label">{uncheckedLabel}</b>
              </Label>
            </Wrapper>
            {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
          </>
        )}
      </Validate>
    );
  },
);

CheckboxToggle.idCounter = 0;
CheckboxToggle.displayName = 'CheckboxToggle';
CheckboxToggle.defaultProps = {
  checkedLabel: 'Show',
  uncheckedLabel: 'Hide',
};
CheckboxToggle.propTypes = {
  id: PropTypes.any,
  labelText: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  uncheckedValue: PropTypes.any,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
};

export default CheckboxToggle;
