import React from 'react';
import PropTypes from 'prop-types';
import ToSLabel from 'components/ui/ToSLabel';
import Checkbox from 'components/ui/inputs/Checkbox';

const ToSCheckbox = ({ field }) => {
  return (
    <Checkbox
      labelTestId="agree-to-terms-and-policy"
      {...field}
      labelText={<ToSLabel />}
      validators={{
        acceptance: [
          {
            message:
              'You need to read and accept the Terms of Service and our Privacy Policy.',
            accept: true,
          },
        ],
      }}
    />
  );
};

ToSCheckbox.propTypes = {
  field: PropTypes.object.isRequired, // ReactiveRecord's ...fields.fieldName
};

export default ToSCheckbox;
