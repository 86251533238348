import React from 'react';
import PropTypes from 'prop-types';
import ReactiveRecord, { Member } from 'reactiverecord';

const VideoCallModel = ReactiveRecord.model('VideoCall');

let requestedAtLeastOnce = false;
let loadedAtLeastOnce = false;
let loadFailed = false;

const VideoCall = ({ children, ...props }) => {
  return (
    <Member for={VideoCallModel} fetch={!requestedAtLeastOnce} {...props}>
      {videoCall => {
        requestedAtLeastOnce = true;
        if (videoCall._request.status === 200) {
          loadedAtLeastOnce = true;
        }
        if (videoCall._request.status === 404) {
          loadFailed = true;
        }
        return children(videoCall, loadedAtLeastOnce, loadFailed);
      }}
    </Member>
  );
};

VideoCall.propTypes = {
  children: PropTypes.func.isRequired,
};

export default VideoCall;
