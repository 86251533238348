import React, { useEffect, useState } from 'react';
import { AVAILABLE_APPOINTMENT_TIMES } from 'consts';

export const useHoursOptions = () => {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [toHoursOptions, setToHoursOptions] = useState();
  const LAST_AVAILABLE_SLOT =
    AVAILABLE_APPOINTMENT_TIMES[AVAILABLE_APPOINTMENT_TIMES.length - 1];
  useEffect(() => {
    const remainingTimes = AVAILABLE_APPOINTMENT_TIMES.filter(
      value => value.id > startTime,
    );
    // last available time
    if (startTime === LAST_AVAILABLE_SLOT.id.toString()) {
      setToHoursOptions([
        <option
          key={`last-available-${startTime}`}
          value={LAST_AVAILABLE_SLOT.id}
          data-testid={`to-time-${LAST_AVAILABLE_SLOT.id}`}
        >
          {LAST_AVAILABLE_SLOT.displayEndTime}
        </option>,
      ]);
    } else {
      setToHoursOptions(
        remainingTimes.map(value => (
          <option
            key={value.id}
            value={value.id}
            data-testid={`to-time-${value.id}`}
          >
            {value.displayEndTime}
          </option>
        )),
      );
    }
  }, [LAST_AVAILABLE_SLOT, startTime]);
  return [startTime, endTime, toHoursOptions, setStartTime, setEndTime];
};
