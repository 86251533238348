import React from 'react';
import PropTypes from 'prop-types';

const Twitter = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Twitter</title>
      <path
        d="M15.551 22.9501C23.651 21.3301 25.676 11.2501 25.676 6.75009L14.201 8.10009C12.176 8.10009 9.47604 7.42509 7.45104 6.75009C5.91418 6.2378 2.27605 2.9251 0.70105 1.3501C0.70105 2.7001 0.70105 4.72509 1.37605 6.07509C2.05105 7.42509 2.72605 8.77509 4.07605 10.1251L1.37605 9.45009C2.05105 10.8001 2.55503 12.6541 4.75105 14.8501C6.46862 16.5677 8.35104 17.7751 9.47604 18.2251C9.47604 18.4501 9.47604 19.5751 7.45104 20.2501C6.30553 20.6319 2.95105 20.9251 1.37605 20.9251C2.72605 22.0501 7.45104 24.5701 15.551 22.9501Z"
        fill="currentColor"
      />
      <path
        d="M27.701 2.02441L25.001 4.04941H27.026L25.676 5.39941L22.976 2.69941L27.701 2.02441Z"
        fill="currentColor"
      />
      <circle cx="19.6012" cy="6.07499" r="6.07499" fill="currentColor" />
    </svg>
  );
};

Twitter.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Twitter.defaultProps = {
  width: 28,
  height: 24,
};

export default Twitter;
