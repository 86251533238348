import React, { useEffect, useState } from 'react';
import { Row } from 'components/ui/grid';
import 'focus-within-polyfill';

import { StyledHR, SubFooter } from 'components/shared/Footer/styles';
import { MARKETING_LINKS } from 'consts';

import Facebook from 'components/ui/icons/Logos/Facebook';
import Instagram from 'components/ui/icons/Logos/Instagram';
import LinkedIn from 'components/ui/icons/Logos/LinkedIn';
import Twitter from 'components/ui/icons/Logos/Twitter';
import { FooterComponent2024 } from './FooterComponent2024';

const Footer = () => {
  const [isHidden, setIsHidden] = useState(false);
  const currentYear = new Date().getFullYear();
  let location = window.location;

  useEffect(() => {
    // Prevent Footer from displaying within DTC
    // This was requested by the original designs
    if (
      location &&
      location.pathname &&
      location.pathname.indexOf('employer/clouds') > -1
    ) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [location, setIsHidden]);

  return (
    <>
      {isHidden ? null : (
        <>
          <FooterComponent2024 />
          <StyledHR />
          <SubFooter>
            <div className="container-md overflow-visible pt-5">
              <div className="d-flex flex-row justify-content-center">
                <ul className="list-logos-updated-footer mt-3 mb-0 ml-0 pl-0">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={MARKETING_LINKS.facebook}
                    >
                      <Facebook />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={MARKETING_LINKS.instagram}
                    >
                      <Instagram />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={MARKETING_LINKS.twitter}
                    >
                      <Twitter />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={MARKETING_LINKS.linkedin}
                    >
                      <LinkedIn />
                    </a>
                  </li>
                </ul>
              </div>
              <Row
                className="mt-2 text-dark justify-content-center"
                css={'gap:0.5rem'}
              >
                <div>&copy; {currentYear} The Mom Project</div>
                <div>All Rights Reserved</div>
              </Row>
            </div>
          </SubFooter>
        </>
      )}
    </>
  );
};

export default Footer;
