import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import UploadIcon from 'components/ui/icons/UploadIcon';
import { colors, withUnitsRecursive } from 'styleGuide';
import { getInitialsFirstAndLastName, getInitialsFullName } from 'utilities';

const sizes = {
  xxxs: {
    size: 30,
    fontSize: 16,
    lineHeight: 29,
    border: 1,
  },
  xxs: {
    size: 40,
    fontSize: 21,
    lineHeight: 39,
    border: 1,
  },
  45: {
    size: 45,
    fontSize: 18,
    lineHeight: 44,
    border: 1,
  },
  50: {
    size: 50,
    fontSize: 20,
    lineHeight: 49,
    border: 1,
  },
  xs: {
    size: 60,
    fontSize: 26,
    lineHeight: 59,
    border: 1,
  },
  sm: {
    size: 80,
    fontSize: 36,
    lineHeight: 84,
    border: 2,
  },
  md: {
    size: 100,
    fontSize: 46,
    lineHeight: 99,
    border: 3,
  },
  lg: {
    size: 120,
    fontSize: 56,
    lineHeight: 114,
    border: 4,
  },
};

const avatarSizes = withUnitsRecursive(sizes);

export const TalentAvatar = props => {
  const {
    borderColor,
    bordered,
    photoUrl,
    talentName,
    initials,
    firstName,
    lastName,
    size,
    isLogo,
  } = props;
  const config = avatarSizes[size];
  const altText = isLogo ? 'Logo' : 'Avatar';
  const renderAvatar = config => {
    if (photoUrl) {
      return (
        <ProfilePicture
          alt={altText}
          borderColor={borderColor}
          bordered={bordered}
          isLogo={isLogo}
          size={config.size}
          src={photoUrl}
        />
      );
    } else if (isLogo) {
      return (
        <UploadLogo>
          Upload Image&nbsp;
          <UploadIcon width="20px" />
        </UploadLogo>
      );
    } else {
      return (
        <div className="initials">
          {getAvatarInitials({ talentName, initials, firstName, lastName })}
        </div>
      );
    }
  };
  return (
    <StyledAvatarContainer
      avatarSize={config.size}
      border={bordered && config.border}
      fontSize={config.fontSize}
      lineHeight={config.lineHeight}
      {...props}
    >
      {renderAvatar(config)}
    </StyledAvatarContainer>
  );
};

// We'd like to pass the talent name in consistently, but currently it is provided in one of two ways.
// Eventually this condition will not be required.
const getAvatarInitials = ({ talentName, initials, firstName, lastName }) => {
  if (initials) {
    return initials;
  }
  if (firstName && lastName) {
    return getInitialsFirstAndLastName(firstName, lastName);
  }
  if (talentName) {
    return getInitialsFullName(talentName);
  }
  return '';
};

const getBorderStyles = props => {
  if (props.bordered || (props.photoUrl && props.photoUrl.length)) {
    return `border: 1px solid ${colors.blue.primary};`;
  } else {
    return '';
  }
};

const getDimensionStyles = size => {
  return `
    height: ${size};
    width: ${size};
  `;
};

const StyledAvatarContainer = styled.div`
  .initials {
    ${props => getDimensionStyles(props.avatarSize)};
    font-size: ${props => props.fontSize};
    line-height: ${props => props.lineHeight};
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
    background-image: linear-gradient(
      ${colors.blue.primary} 0%,
      ${colors.blue.dark} 100%
    );
    color: ${colors.white};
    border-radius: ${props => (props.isLogo ? '0' : '50%')};
    ${props => props.isLogo && 'display: flex; justify-content: flex-start;'}
    ${props => getBorderStyles(props)}
  }
  .header-avatar .initials {
    margin: 10px;
  }
`;

export const ProfilePicture = styled.img`
  ${props => !props.isLogo && getDimensionStyles(props.size)}
  ${props =>
    props.bordered ? 'border: 2px solid ' + props.borderColor + ';' : ''}
  display: block;
  border-radius: 50%;
  margin: 0;
  object-fit: cover;
  object-position: center;
  background-color: white;
`;

export const UploadLogo = styled.div(
  ({ theme }) => css`
    color: ${colors.blue.primary};
    padding: 1.5rem 0;
    width: 100%;
    height: 5rem;
    border: 1px solid ${colors.gray.light};
    text-transform: uppercase;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: ${theme.screenSizes.tablet}) {
      width: 21.44rem;
    }
  `,
);

TalentAvatar.propTypes = {
  borderColor: PropTypes.string,
  bordered: PropTypes.bool,
  firstName: PropTypes.string,
  initials: PropTypes.string,
  isLogo: PropTypes.bool,
  lastName: PropTypes.string,
  photoUrl: PropTypes.string,
  size: PropTypes.oneOf(['45', '50', 'xxxs', 'xxs', 'xs', 'sm', 'md', 'lg']),
  talentName: PropTypes.string,
};

TalentAvatar.defaultProps = {
  borderColor: colors.blue.primary,
  bordered: true,
  isLogo: false,
  size: 'sm',
};
