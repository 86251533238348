import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { TalentAvatar } from 'components/shared/TalentAvatar';
import useIdentity from './useIdentity';
import { VideoContext } from './VideoProvider';
import VideoTrack from './VideoTrack';
import AudioTrack from './AudioTrack';

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
`;

const LocalParticipant = () => {
  const [videoDisabled, setVideoDisabled] = useState(false);
  const { localVideoTrack, localAudioTrack, localParticipant } =
    useContext(VideoContext);
  const participantIdentity = useIdentity(localParticipant);

  useEffect(() => {
    const disableVideo = () => setVideoDisabled(true);
    const enableVideo = () => setVideoDisabled(false);

    localVideoTrack.on('disabled', disableVideo);
    localVideoTrack.on('enabled', enableVideo);

    return () => {
      localVideoTrack.off('disabled', disableVideo);
      localVideoTrack.off('enabled', enableVideo);
    };
  }, [localVideoTrack]);

  return (
    <>
      <AudioTrack track={localAudioTrack} />
      {videoDisabled ? (
        <AvatarContainer>
          <TalentAvatar talentName={participantIdentity} />
        </AvatarContainer>
      ) : (
        <VideoTrack track={localVideoTrack} isLocal />
      )}
    </>
  );
};

export default LocalParticipant;
