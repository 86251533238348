import React from 'react';

import Icon from '../Icon';

const Caret = props => {
  const title = `${[props.direction].map(
    d => d.charAt(0).toUpperCase() + d.substring(1),
  )} Caret`;
  const viewBox =
    props.direction === 'left' || props.direction === 'right'
      ? '0 0 10 15'
      : '0 0 15 15';
  return (
    <Icon title={title} viewBox={viewBox} {...props} width="auto">
      <path
        d="M3.622 14c-.284-.284-.284-.744 0-1.028l5.525-5.524-5.525-5.525c-.284-.285-.284-.745 0-1.028.284-.284.744-.284 1.028 0 0 0 4.53 4.53 6.056 6.058.275.275.272.719-.003.994L4.65 14c-.284.284-.744.284-1.028 0"
        fill={props.color}
      />
    </Icon>
  );
};

Caret.propTypes = { ...Icon.propTypes };
Caret.defaultProps = {
  ...Icon.defaultProps,
  direction: 'right',
};

export default Caret;
