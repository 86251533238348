import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/ui/Icon';

const Heart = ({ filled, ...props }) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M11.926 3.85c2.978-3.033 5.76-3.266 7.877-2.437 3.085 1.208 4.553 4.633 3.893 7.88-1.546 7.594-11.58 12.445-11.766 12.534v.005l-.004-.002a.026.026 0 0 0-.005.002v-.005c-.186-.089-10.22-4.94-11.765-12.534-.662-3.247.807-6.672 3.893-7.88C6.165.584 8.947.817 11.926 3.85Zm.003 15.566c.146-.058 7.655-3.828 9.437-9.079.695-2.048 1.096-5.049-1.683-6.638-3.094-1.771-5.676.941-7.548 2.908l-.209.219-.212-.222C9.84 4.638 7.26 1.93 4.167 3.7 1.39 5.288 1.79 8.289 2.485 10.337c1.782 5.251 9.29 9.021 9.436 9.079v.003h.002l.003-.001.002.001h.001v-.003Z"
        clipRule="evenodd"
      />
      <path
        fill={filled ? props.color : 'transparent'}
        d="M19.803 1.413c-2.117-.83-4.898-.595-7.877 2.437C8.948.818 6.166.584 4.049 1.413.964 2.62-.505 6.046.156 9.293c1.546 7.594 11.579 12.445 11.766 12.535v.003l.004-.001h.004v-.002c.187-.09 10.22-4.941 11.766-12.535.66-3.247-.808-6.672-3.893-7.88"
      />
    </Icon>
  );
};

Heart.propTypes = {
  filled: PropTypes.bool,
  ...Icon.propTypes,
};
Heart.defaultProps = { ...Icon.defaultProps };

export default Heart;
