import { useEffect, useState } from 'react';

const useIdentity = participant => {
  const [participantIdentity, setParticipantIdentity] = useState();

  useEffect(() => {
    setParticipantIdentity(participant.identity);
  }, [participant.identity]);

  return participantIdentity;
};

export default useIdentity;
