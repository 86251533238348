import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/talent_project_interests';
const memberRoute = `${collectionRoute}/:id`;
const employerCollectionRoute = ':prefix/employers/talent_project_interests';

class TalentProjectInterest extends Model {
  static routes = {
    index: collectionRoute,
    show: memberRoute,
    create: employerCollectionRoute,
    update: memberRoute,
  };

  static schema = {
    already_invited: Array,
    bid_id: Number,
    expiration_date: String,
    invited: Array,
    mom_archived: Boolean,
    not_interested: Date,
    project: Object,
    project_id: Number,
    project_slug: String,
    talent_ids: Array,
  };
}

export default ReactiveRecord.model(
  'TalentProjectInterest',
  TalentProjectInterest,
);
