import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import Dialog from 'components/ui/Dialog';
import ShowToggle from 'components/ui/ShowToggle';
import CloseProjectForm from './CloseProjectForm';
import { showMessage } from 'utilities';
import { PROJECT_CANCEL_REASONS } from 'consts';

const ProjectActions = ({
  copyPath,
  editPath,
  viewPath,
  showProjectRemoval,
  projectSlug,
  projectTitle,
}) => {
  const onCloseJob = ({ reason }) => {
    const message =
      reason === 'candidate_declined'
        ? `Request to close ${projectTitle} has been sent. Our team will reach out soon to support.`
        : `${projectTitle} has been closed!`;
    showMessage(message);
  };

  return (
    <div className="action-buttons col-lg-6 mt-2 mt-md-0 d-flex justify-content-end align-items-center">
      <a className="edit-link" href={copyPath}>
        <i className="fa fa-copy" />
        &nbsp;Copy job
      </a>
      {editPath && (
        <a className="edit-link" id={`edit-${projectSlug}`} href={editPath}>
          <i className="fa fa-pencil" />
          &nbsp;Edit job
        </a>
      )}
      <a className="view-link" href={`${viewPath}?from=jobPosts`}>
        <i className="fa fa-eye" />
        &nbsp;View job
      </a>
      {showProjectRemoval ? (
        <ShowToggle>
          {(show, toggle) => (
            <>
              <ButtonLink data-id="close-job-btn" onClick={toggle}>
                <i className="fa fa-trash-o" />
                &nbsp;Close job
              </ButtonLink>
              {show && (
                <Dialog show={show} onHide={toggle} xlarge>
                  <CloseProjectForm
                    slug={projectSlug}
                    onCancel={toggle}
                    onCloseJob={onCloseJob}
                    projectTitle={projectTitle}
                    cancelReasons={PROJECT_CANCEL_REASONS}
                  />
                </Dialog>
              )}
            </>
          )}
        </ShowToggle>
      ) : null}
    </div>
  );
};

ProjectActions.propTypes = {
  copyPath: PropTypes.string,
  editPath: PropTypes.string,
  viewPath: PropTypes.string,
  showProjectRemoval: PropTypes.bool,
  projectSlug: PropTypes.string.isRequired,
  projectTitle: PropTypes.string.isRequired,
};

export default ProjectActions;
