import ReactiveRecord, { Model } from 'reactiverecord';

class CloudMembership extends Model {
  static routes = {
    index: ':prefix/employers/cloud_memberships',
  };
  static store = {
    singleton: true,
  };
  static schema = {
    id: Number,
    cloud_id: Number,
    mom_id: Number,
  };
}

export default ReactiveRecord.model('CloudMembership', CloudMembership);
