import React from 'react';

const Email = props => {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient-email"
          x1="256"
          y1="512"
          x2="256"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f1f5ff" />
          <stop offset="1" stopColor="#aabcff" />
        </linearGradient>
      </defs>
      <g id="Layer_3" data-name="Layer 3">
        <circle fill="url(#linear-gradient-email)" cx="256" cy="256" r="256" />
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <rect fill="#4155bd" x="106" y="199.75" width="300" height="206.25" />
        <polygon
          fill="#fff"
          points="377.88 162.25 377.88 406 134.13 406 134.13 106 321.63 106 377.88 162.25"
        />
        <polygon
          fill="#6480ff"
          points="106 406 406 199.75 406 406 256 302.88 106 199.75 106 406"
        />
        <polygon
          fill="#d1d2d6"
          points="377.88 162.25 321.63 162.25 321.63 106 377.88 162.25"
        />
        <polygon fill="#9aafff" points="406 406 256 302.88 106 406 406 406" />
      </g>
    </svg>
  );
};

export default Email;
