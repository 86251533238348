import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Portal from 'components/ui/Portal';
import ModalIn from './Dialog/ModalIn';
import TrapFocus from './TrapFocus';

export const StyledPortal = styled(Portal)`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 206;
  transition: all 500ms;
`;
export const FilterDrawer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90vh;
  background-color: white;
  transition: all 500ms;
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
`;

const MobileDrawer = props => {
  let blurBehavior = props.closeModalOnBlur ? 'HIDE' : 'PREVENT';
  return props.show ? (
    <StyledPortal>
      <ModalIn />
      <TrapFocus blurBehavior={blurBehavior} onHide={props.onHide}>
        <FilterDrawer className="p-3">{props.children}</FilterDrawer>
      </TrapFocus>
    </StyledPortal>
  ) : null;
};

MobileDrawer.propTypes = {
  /** Controls whether the drawer is shown or not */
  show: PropTypes.bool.isRequired,
  /** Your content here */
  children: PropTypes.node.isRequired,
  /** Function should update parent state to change the show prop. */
  onHide: PropTypes.func.isRequired,
  closeModalOnBlur: PropTypes.bool,
  showDialogCloser: PropTypes.bool,
};

MobileDrawer.defaultProps = {
  showDialogCloser: true,
  closeModalOnBlur: true,
};

export default MobileDrawer;
