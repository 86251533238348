import React from 'react';

export default function Sparkles4Pattern(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 125.244 190.053"
      {...props}
    >
      <defs></defs>
      <g transform="translate(-367 -2503.36)">
        <path
          fill="#ffd4d2"
          d="M455.346,218.316c-5.911-18.808-16.468-31.056-32.713-37.768,16.2-6.788,26.825-18.952,32.673-37.7,5.86,18.549,16.375,30.841,32.656,37.655C471.892,187.346,461.2,199.429,455.346,218.316Z"
          transform="translate(-55.633 2443.156)"
        />
        <path
          fill="#6685ff"
          d="M475.47,213.884c3.15,10.367,8.16,17.248,16.238,21.219-8,4.153-13.12,10.882-16.226,21.326-3.124-10.4-8.2-17.187-16.156-21.274C467.249,231.054,472.363,224.314,475.47,213.884Z"
          transform="translate(-45.489 2289.476)"
        />
        <path
          fill="#b0eee3"
          d="M445.789,196.267c-4.184-13.313-11.657-21.983-23.156-26.734,11.465-4.805,18.988-13.415,23.128-26.689,4.148,13.13,11.591,21.831,23.116,26.654C457.5,174.345,449.93,182.9,445.789,196.267Z"
          transform="translate(23.367 2413.156)"
        />
        <path
          fill="#fecc7a"
          d="M492.413,192.34c6.751-2.995,11.169-7.971,13.739-15.891,2.638,7.7,6.912,12.933,13.892,15.847-6.82,3-11.253,8.05-13.852,15.961C503.621,200.407,499.224,195.316,492.413,192.34Z"
          transform="translate(-59.633 2485.156)"
        />
      </g>
    </svg>
  );
}
