import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import VideoTrack from './VideoTrack';
import AudioLevelIndicator from './AudioLevelIndicator';
import VideoControls from './VideoControls';
import VideoPreviewGreeting from './VideoPreviewGreeting/index';
import { VideoContext } from './VideoProvider';
import VideoParticipants from './VideoParticipants';
import VideoPermissionError from './VideoPermissionError';
import VideoNotFoundError from './VideoNotFoundError';
import VideoGenericError from './VideoGenericError';

const VideoPreview = props => {
  const { videoCall = {}, roomName, identity } = props;
  const {
    localAudioTrack,
    localVideoTrack,
    permissionError,
    notFoundError,
    genericError,
  } = useContext(VideoContext);

  if (permissionError) {
    return <VideoPermissionError />;
  }

  if (notFoundError) {
    return <VideoNotFoundError />;
  }

  if (genericError) {
    return <VideoGenericError />;
  }

  return localVideoTrack && localAudioTrack ? (
    <div className="pt-5 pb-5">
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <VideoTrack track={localVideoTrack} isLocal />
            <AudioLevelIndicator track={localAudioTrack} />
            <VideoControls
              preview
              audioTrack={localAudioTrack}
              videoTrack={localVideoTrack}
            />
          </Col>
          <Col md={4} className="d-flex">
            <div className="d-flex font-base flex-column justify-content-center mt-3 mt-md-0 w-100">
              <VideoParticipants where={{ room_name: roomName }}>
                {participants => (
                  <VideoPreviewGreeting
                    videoCall={videoCall}
                    roomParticipants={participants}
                    identity={identity}
                    roomName={roomName}
                  />
                )}
              </VideoParticipants>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};

VideoPreview.propTypes = {
  videoCall: PropTypes.object.isRequired,
  roomName: PropTypes.string.isRequired,
  identity: PropTypes.string.isRequired,
};

export default VideoPreview;
