import React, { useState, useContext } from 'react';

import AnalyticsContext from 'components/shared/analytics/AnalyticsContext';
import Options from 'components/shared/SelectableOptions';
import PropTypes from 'prop-types';
import SelectableButton from 'components/ui/buttons/SelectableButton';
import { MAX_PRIORITY_FUNCTIONS, ANALYTICS } from 'consts';

const TalentPrimarySuperpower = ({
  options,
  cols,
  helpText,
  selected,
  maxLength,
  onChange,
  stateKey,
  validate,
  label,
}) => {
  const { trackEvents } = useContext(AnalyticsContext);

  const functionsOther = options.filter(item => {
    if (item.priority > MAX_PRIORITY_FUNCTIONS) {
      return item;
    }
  });

  const functionInCollapsedOptions = selectedFunction => {
    let isInCollapsedOptions = false;
    functionsOther.map(item => {
      if (item.id === selectedFunction) {
        isInCollapsedOptions = true;
      }
    });
    return isInCollapsedOptions;
  };

  const getOtherOptions = (options, max) => {
    return options.filter(item => item.priority > max);
  };

  const getFeaturedOptions = (options, max) => {
    return options.filter(item => !item.priority || item.priority <= max);
  };

  const handleClickSelectSuperpower = action => {
    onChange(stateKey, action.id, maxLength);
    trackEvents(ANALYTICS.EVENT_TYPES.Click)({
      target: 'Superpower',
      user_type: ANALYTICS.USER_TYPES.Talent,
      form_value: action.title,
      event_category: 'Talent Onboarding',
      event_label: 1,
      mom_id: 1,
    });
  };

  const [showMore, setShowMore] = useState(false);
  const handleClickShowMore = () => {
    setShowMore(!showMore);
    trackEvents(ANALYTICS.EVENT_TYPES.Click)({
      target: 'Show More Superpowers',
      user_type: ANALYTICS.USER_TYPES.Talent,
      event_category: 'Talent Onboarding',
      mom_id: 1,
    });
  };

  return (
    <div className="row">
      <div className="form-group col-12 questionnaire">
        <div className="font-md font-medium" id="superpower-label">
          {label}
          {validate && <span className="required-mark">*</span>}{' '}
        </div>
        {helpText ? (
          <div className="text-alt font-sm mt-1 mb-3">{helpText}</div>
        ) : (
          <div className="text-alt font-sm mb-3" />
        )}
        <Options
          featured
          targetName="Superpower"
          options={getFeaturedOptions(options, MAX_PRIORITY_FUNCTIONS)}
          cols={cols}
          selected={selected}
          handleClick={handleClickSelectSuperpower}
        />
        {showMore || functionInCollapsedOptions(selected) ? (
          <Options
            targetName="Superpower"
            featured={false}
            options={getOtherOptions(options, MAX_PRIORITY_FUNCTIONS)}
            cols={cols}
            selected={selected}
            handleClick={handleClickSelectSuperpower}
          />
        ) : (
          <div className="col mt-2">
            <SelectableButton
              action={handleClickShowMore}
              data-id="show-more-superhero-btn"
              label="Show more..."
              selected={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

TalentPrimarySuperpower.propTypes = {
  options: PropTypes.array.isRequired,
  cols: PropTypes.number.isRequired,
  helpText: PropTypes.string,
  selected: PropTypes.any,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  stateKey: PropTypes.string.isRequired,
  validate: PropTypes.bool,
  label: PropTypes.string,
};

TalentPrimarySuperpower.defaultProps = {
  validate: false,
};

export default TalentPrimarySuperpower;
