import { useCallback, useEffect } from 'react';

/**
 * Given a condition, shows a prompt to the user whenever they are about to leave the page or reload
 * IMPORTANT: It is not guaranteed that the browser will display your message.
 * @param {boolean} condition Must be a boolean value indicating that the prompt should be displayed
 * @param {string} message String with the message
 */
const usePreventNavigation = (
  condition,
  message = 'You have unsaved changes, are you sure you want to leave?',
) => {
  const preventNavigation = useCallback(
    event => {
      if (condition) {
        event.returnValue = message;
      }
    },
    [condition, message],
  );
  useEffect(() => {
    window.addEventListener('beforeunload', preventNavigation, false);
    return () =>
      window.removeEventListener('beforeunload', preventNavigation, false);
  }, [preventNavigation]);
};

export default usePreventNavigation;
