const filtersConfig = {
  capabilitiesCategories: [],
  hoursPerWeek: [
    {
      id: 'oneToTwenty',
      label: '1-20 hours per week',
    },
    {
      id: 'twentyToThirty',
      label: '21-30 hours per week',
    },
    {
      id: 'thirtyToFourty',
      label: '31-40 hours per week',
    },
    {
      id: 'fourty',
      label: '40 hours per week',
    },
  ],
  experienceLevel: [
    {
      id: 'junior',
      label: 'Junior',
    },
    {
      id: 'midlevel',
      label: 'Mid-level',
    },
    {
      id: 'senior',
      label: 'Senior',
    },
  ],
  opportunityType: [
    {
      id: 'contract_with_benefits',
      label: 'Contract with benefits',
    },
    {
      id: 'contract_without_benefits',
      label: 'Contract without benefits',
    },
    {
      id: 'permanent',
      label: 'Permanent',
    },
    {
      id: 'maternityship',
      label: 'Temp to perm',
    },
  ],
  locationType: [
    {
      id: 'remote',
      label: 'Remote',
    },
    {
      id: 'combination',
      label: 'Hybrid',
    },
    {
      id: 'office',
      label: 'Onsite',
    },
  ],
  competitve: [
    {
      id: 'competitive',
      label: 'Competitive',
    },
  ],
};

export default filtersConfig;
