import ReactiveRecord from 'reactiverecord';
import Resume from './Resume';

class ResumeLatest extends Resume {
  static store = {
    singleton: true,
  };
  static routes = {
    only: ['index', 'update', 'destroy'],
    index: ':prefix/moms/resumes/latest',
    update: ':prefix/moms/resumes/:id',
    destroy: ':prefix/moms/resumes/:id',
  };
}

export default ReactiveRecord.model('ResumeLatest', ResumeLatest);
