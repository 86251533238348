import React from 'react';

export default function File(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49.04 66.63"
      {...props}
    >
      <path
        d="m1 65.63v-43.63s9-21 22.51-21h22.59s1.9-.95 1.9 1.16v63.47z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="m20.17 18.58c0 8.77-6.73 9.15-6.73 9.15h-13.44v-5.73s8.08.2 11.25-.06c1-.08 1.89-.53 2.34-3.11.6-3.5 2.06-17.47 13.83-17.81.75.03 3.58-.02 3.58-.02s-10.89.37-10.83 17.58z"
        fill="#464a59"
        fillRule="evenodd"
        opacity=".3"
      />
      <path
        d="m15 15.18 20.34.07m-21.34 4.93 28.35.07m-33.35 11.93 28.34.07m-28.34 6.25h29m-29 10h32m-32 8h25"
        fill="none"
        stroke="#464a59"
        strokeLinecap="round"
      />
      <g fillRule="evenodd">
        <path
          d="m30.5 33a12.5 12.5 0 1 1 -12.5 12.5 12.5 12.5 0 0 1 12.5-12.5"
          fill="#6685ff"
        />
        <path
          d="m26.31 48.31a1.11 1.11 0 0 1 1.53 0l3.16 3.11 3.15-3.11a1.11 1.11 0 0 1 1.53 0 1.05 1.05 0 0 1 0 1.51l-3.95 3.88a1.05 1.05 0 0 1 -1.47 0l-3.94-3.87a1.07 1.07 0 0 1 0-1.51"
          fill="#fff"
        />
        <path d="m32 53h-2v-14a1 1 0 0 1 2 0z" fill="#fff" />
        <path
          d="m46.54 0h-23.4c-13.14 0-21.64 17.89-22.87 20.5a6.12 6.12 0 0 0 -.27 1.93v42.62a1.58 1.58 0 0 0 1.58 1.58h45.88a1.58 1.58 0 0 0 1.54-1.58v-61.8c0-1.8-1.08-3.25-2.46-3.25zm-23.73 1.63c-5.4 2.39-8.74 8.23-9.57 17v.14a2.67 2.67 0 0 1 -2.76 2.58h-8.8a1 1 0 0 1 .08-.25c1.11-2.45 9.02-19.1 21.05-19.47zm24.6 61.79a1.58 1.58 0 0 1 -1.58 1.58h-42.63a1.59 1.59 0 0 1 -1.58-1.59v-40.82h8.85a3.86 3.86 0 0 0 4-3.7v-.15c.73-7.75 3.82-17 14.31-17.13h17.75.14.07l.12.09a2 2 0 0 1 .52 1.43v60.29z"
          fill="#464a59"
        />
      </g>
    </svg>
  );
}
