import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/ui/buttons/LinkButton';
import Caret from 'components/ui/icons/Caret';
import withTooltip from 'components/ui/withTooltip';
import styled from 'styled-components';

// history prop is array of objects that represent the breadcrumb path
// ie.
// [
//   {name: "Bids", path: '/employer/bids'},
//   {name: "Musician", path: '/employer/bids/123'},
//   {name: "David Bowie", path: ''}
// ]
// would show as Bids > Musician > David Bowie
// Bids and Musician are links and David Bowie is text only.
const TooltipContent = styled.div`
  min-width: 250px;
`;

const Breadcrumbs = ({ history = [] }) => {
  const Tooltip = withTooltip('div');

  const tooltip = (name, nameLength, nameLabel) => {
    const showTooltip = nameLength >= 21 || nameLabel === '...';
    return showTooltip ? (
      <Tooltip title={<TooltipContent>{name}</TooltipContent>}>
        {nameLabel}
      </Tooltip>
    ) : (
      name
    );
  };

  const breadCrumb = (name, path, index) => {
    const isFirst = index === 0;
    const isLast = index === history.length - 1;
    const isLastTwo = isLast || index === history.length - 2;
    const hasPath = path && path.length > 0;
    const historyLength = history.length;
    const nameLength = name.length;
    let nameLabel = name;

    if (historyLength >= 4 && !isFirst && !isLastTwo) {
      nameLabel = '...';
    } else if (nameLength > 21) {
      nameLabel = name.substring(0, 20) + '...';
    }

    return (
      <React.Fragment key={`breadcrumb-${name}-${path}`}>
        {hasPath && (
          <LinkButton
            data-id={`breadcrumb-${name}-${path}`}
            href={path}
            inline
            link
          >
            {tooltip(name, nameLength, nameLabel)}
          </LinkButton>
        )}
        {!hasPath && <>{tooltip(name, nameLength, nameLabel)}</>}
        {!isLast && <Caret className="mx-2" direction="right" />}
      </React.Fragment>
    );
  };

  return (
    <>
      {history.map(({ name, path }, index) => {
        return breadCrumb(name, path, index);
      })}
    </>
  );
};

Breadcrumbs.propTypes = {
  history: PropTypes.array.isRequired,
};

export default Breadcrumbs;
