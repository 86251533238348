import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import PlusIcon from '../../../../assets/svg/plus.svg';

const StyledFilterMobile = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 3rem;
  padding: 1rem;
  font-size: 1rem;
  font-weight: ${props => props.theme.fontWeights.fontHeavy};
  color: ${props => props.theme.primaryBlue};
  z-index: 3;
  text-align: center;
  cursor: pointer;
  background: ${props => props.theme.lightBlue} url(${PlusIcon}) no-repeat;
  background-position: left 1rem center;
`;

function FilterMobile({ filterCount, className, ...props }) {
  return (
    <StyledFilterMobile
      className={classNames('d-md-none', {
        [className]: className,
      })}
      {...props}
    >
      Filter ({filterCount})
    </StyledFilterMobile>
  );
}

FilterMobile.propTypes = {
  filterCount: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default FilterMobile;
