import {
  colors,
  containerWidth,
  fonts,
  fontSizes,
  fontWeight,
  screenSize,
} from 'styleGuide';

function lightenOrDarken(r, g, b, percent) {
  let R = parseInt((r * (100 + percent)) / 100);
  let G = parseInt((g * (100 + percent)) / 100);
  let B = parseInt((b * (100 + percent)) / 100);
  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;
  return [R, G, B];
}

class ThemeColor {
  constructor(color) {
    if (/rgba?\(/.test(color)) {
      const values = color.match(/\(([^)]+)\)/)[1].split(',');
      this.r = values[0];
      this.g = values[1];
      this.b = values[2];
      this.a = values[3];
    } else {
      this.r = parseInt(color.slice(1, 3), 16);
      this.g = parseInt(color.slice(3, 5), 16);
      this.b = parseInt(color.slice(5, 7), 16);
      this.a = 1;
    }
  }

  alpha(a) {
    return new ThemeColor(`rgba(${this.r},${this.g},${this.b},${a})`);
  }

  darken(amount) {
    const [R, G, B] = lightenOrDarken(
      this.r,
      this.g,
      this.b,
      parseFloat(`-${amount}`),
    );
    return new ThemeColor(`rgba(${R},${G},${B},${this.a})`);
  }

  lighten(amount) {
    const [R, G, B] = lightenOrDarken(
      this.r,
      this.g,
      this.b,
      parseFloat(amount),
    );
    return new ThemeColor(`rgba(${R},${G},${B},${this.a})`);
  }

  toString() {
    return 'rgba(' + [this.r, this.g, this.b, this.a].join(',') + ')';
  }
}

const black = new ThemeColor(colors.black);

export default {
  ThemeColor,
  em: 16,
  fonts,
  fontSizes,
  fontWeights: fontWeight,
  screenSizes: screenSize,
  lightOrange: new ThemeColor('#fecc7a'),
  lightTeal: new ThemeColor('#b0eee3'),
  lightBlue: new ThemeColor(colors.blue.light),
  paleBlue: new ThemeColor(colors.blue.pale),
  primaryBlue: new ThemeColor(colors.blue.primary),
  palePrimaryBlue: new ThemeColor(colors.blue.palePrimary),
  secondaryBlue: new ThemeColor(colors.blue.secondary),
  darkBlue: new ThemeColor(colors.blue.dark),
  sideBarColor: new ThemeColor(colors.gray.darkest),
  lightestGray: new ThemeColor(colors.gray.lightest),
  lighterGray: new ThemeColor(colors.gray.lighter),
  lightGray: new ThemeColor('#d3d4d8'),
  mediumGray: new ThemeColor('#8e9199'),
  darkGray: new ThemeColor(colors.gray.dark),
  darkerGray: new ThemeColor(colors.gray.darker),
  darkestGray: new ThemeColor(colors.gray.darkest),
  almostBlack: new ThemeColor('#22252F'),
  palePink: new ThemeColor(colors.pink.pale),
  lightPink: new ThemeColor(colors.pink.light),
  green: new ThemeColor('#189b84'),
  paleGreen: new ThemeColor(colors.green.pale),
  lightGreen: new ThemeColor(colors.green.light),
  mediumGreen: new ThemeColor(colors.green.medium),
  darkGreen: new ThemeColor(colors.green.dark),
  red: new ThemeColor(colors.error),
  black,
  white: new ThemeColor(colors.white),
  boxShadow100: `0 1px 4px 0 ${black.alpha('0.15')}`,
  bootstrapTransitionTime: 350,
  headerBackground: new ThemeColor(colors.gray.dark), //ORIGINAL
  headerBackgroundV2: new ThemeColor(colors.white), //COMMUNITY
  headerHeight: '56px',
  headerItemsAligned: 'space-between',
  companyLogoColor: new ThemeColor(colors.white), //ORIGINAL
  companyLogoColorBlue: new ThemeColor(colors.blue.primary), //COMMUNITY
  navToggler: new ThemeColor(colors.white), //ORIGINAL
  navTogglerBlue: new ThemeColor(colors.blue.primary), //COMMUNITY
  headerFontColor: new ThemeColor(colors.gray.medium), //ORIGINAL
  headerFontColorV2: new ThemeColor(colors.gray.dark), //COMMUNITY
  cyanLight: new ThemeColor(colors.cyan.light),
  mediumYellow: new ThemeColor(colors.yellow.medium),
  lightYellow: new ThemeColor(colors.yellow.light),
  darkYellow: new ThemeColor(colors.yellow.dark),
  containerWidths: containerWidth,
};
