import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/projects/expertises';
const memberRoute = `${collectionRoute}/:id`;

class ProjectExpertise extends Model {
  static attributesName = 'expertises_attributes';
  static routes = {
    index: collectionRoute,
    create: collectionRoute,
    show: memberRoute,
    update: memberRoute,
    destroy: memberRoute,
  };

  static schema = {
    label: { type: String, labelText: 'Type to Add' },
    emsi_skill_id: String,
    _destroy: Boolean,
  };
}

export default ReactiveRecord.model('ProjectExpertise', ProjectExpertise);
