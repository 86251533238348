import React from 'react';

export default function InfoCircle(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <line
        x1="10"
        y1="9"
        x2="10"
        y2="14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="10" cy="6" r="1" fill="currentColor" />
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
