import React from 'react';
import Button from 'components/ui/buttons/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function UseProfile({ useProfile, ...props }) {
  const classes = classNames('btn-profile-file', { selected: useProfile });

  return (
    <Button
      data-id="use-profile-resume-btn"
      className={classes}
      {...props}
      type="button"
      css={`
        line-height: 24px;
        font-weight: normal;
      `}
    >
      {useProfile && (
        <div
          css={`
            min-width: 20px;
          `}
          className="round-check-icon"
        ></div>
      )}
      <div className="text-overflow">Use {props.safeDisplayName}</div>
    </Button>
  );
}

UseProfile.propTypes = {
  useProfile: PropTypes.bool,
  safeDisplayName: PropTypes.string,
  onClick: PropTypes.func,
};

export default UseProfile;
