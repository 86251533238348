import React, {
  useRef,
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import Portal, { PortalContext } from 'components/ui/Portal';
import {
  denormalize,
  generateHandleBlur,
  generateHandleChange,
  generateHandleFocus,
  generateHandleKeyDown,
  generateHandleLabelClick,
  generateHandleLabelKeyDown,
  Input,
  Label,
  LabelContainer,
  List,
  ListItem,
  normalize,
  RemoveLabelHandle,
  Wrapper,
} from 'components/ui/inputs/MultiSelect/supporting';
import X from 'components/ui/icons/X';
import MagnifyingGlass from 'components/ui/icons/MagnifyingGlass';
import { PreventableEvent } from 'utilities';

const generateAdd = (
  usingStateValue,
  value,
  mergeState,
  onChange,
  inputRef,
  children,
  valueAccessor,
) => {
  return id => {
    value[id] = valueAccessor(children, id);
    inputRef.current.value = '';
    if (usingStateValue) {
      mergeState({ stateValue: value, ariaActiveDescendant: null });
    }
    setTimeout(() => {
      if (onChange) {
        onChange(new PreventableEvent(value));
      }
    }, 0);
  };
};

export function generateRemove(usingStateValue, value, mergeState, onChange) {
  return id => {
    delete value[id];
    if (usingStateValue) {
      mergeState({ stateValue: value });
    }
    setTimeout(() => {
      if (onChange) {
        onChange(new PreventableEvent(value));
      }
    }, 0);
  };
}

const MultiSelect = ({
  children,
  defaultValue,
  errors,
  filterResults,
  keysAccessor,
  labelText,
  labelAccessor,
  max,
  name,
  onBlur,
  onChange,
  onInputChange,
  onClick,
  onFocus,
  onKeyDown,
  touched,
  validating,
  value,
  valueAccessor,
  ...props
}) => {
  const portalContext = useContext(PortalContext);
  /* Automatically generate ID */
  const generatedId = useMemo(
    () => `multi-select-${MultiSelect.idCounter++}`,
    [],
  );
  const [state, setState] = useState({
    stateValue: normalize(value || defaultValue || [], false),
    searchTerm: null,
    multiSelectHasFocus: false,
    inputHasFocus: false,
    listStyle: {},
    portalStyle: {},
    ariaActiveDescendant: null,
  });

  const usingStateValue = typeof value === 'undefined';
  const realValue = usingStateValue
    ? state.stateValue
    : normalize(value, false);
  const id = props.id || generatedId;
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const wrapperRef = useRef(null);
  const labelContainerRef = useRef(null);
  const mergeState = useCallback(
    nextState => setState({ ...state, ...nextState }),
    [state],
  );
  // TODO: Fix these warnings
  /* Function to add result to state value */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const add = useCallback(
    generateAdd(
      usingStateValue,
      realValue,
      mergeState,
      onChange,
      inputRef,
      children,
      valueAccessor,
    ),
    [usingStateValue, realValue, mergeState, onChange, children],
  );
  /* Function to remove result from state value */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const remove = useCallback(
    generateRemove(usingStateValue, realValue, mergeState, onChange),
    [usingStateValue, realValue, mergeState, onChange],
  );
  /* onFocus for input */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFocus = useCallback(
    generateHandleFocus(
      onFocus,
      state,
      inputRef,
      setState,
      portalContext.isPortalDescendant,
    ),
    [onFocus, state],
  );
  /* onBlur for input and labels */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBlur = useCallback(
    generateHandleBlur(onBlur, state.inputHasFocus, mergeState, wrapperRef),
    [onBlur, state.inputHasFocus, mergeState],
  );
  /* onKeyDown for labels */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLabelKeyDown = useCallback(
    generateHandleLabelKeyDown(onKeyDown, remove),
    [onKeyDown, remove],
  );
  /* onClick for remove label buttons */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLabelClick = useCallback(
    generateHandleLabelClick(onClick, remove),
    [onClick, remove],
  );

  const renderedValue = denormalize(realValue);
  function listFilter(key) {
    if (state.stateValue[key]) {
      return false;
    }
    if (state.searchTerm) {
      return (
        labelAccessor(children, key)
          .toLowerCase()
          .indexOf(state.searchTerm.toLowerCase()) > -1
      );
    }
    return true;
  }
  /* onMouseDown for options */
  const handleMouseDown = useCallback(id => () => add(id), [add]);

  /* Options list */
  const ListWrapper = portalContext.isPortalDescendant ? 'div' : Portal;
  let optionMenu = null;
  let listId = `${id}-list`;
  const renderedOptions = [];
  const optionValueMapping = {};
  if (state.inputHasFocus) {
    const options = keysAccessor(children).filter(listFilter);
    if (options.length) {
      optionMenu = (
        <ListWrapper style={state.portalStyle}>
          <List
            id={listId}
            data-testid={listId}
            style={state.listStyle}
            role="listbox"
            ref={listRef}
          >
            {options.map(key => {
              const listItemId = `${listId}-${key}`;
              renderedOptions.push(listItemId);
              optionValueMapping[listItemId] = key;
              return (
                <ListItem
                  onMouseDown={handleMouseDown(key)}
                  id={listItemId}
                  aria-selected={state.ariaActiveDescendant === listItemId}
                  active={state.ariaActiveDescendant === listItemId}
                  key={key}
                >
                  {labelAccessor(children, key)}
                </ListItem>
              );
            })}
          </List>
        </ListWrapper>
      );
    }
  }
  /* onChange for input */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    generateHandleChange(
      onInputChange,
      children,
      state,
      setState,
      filterResults,
    ),
    [state, children],
  );
  /* onKeyDown for input */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyDown = useCallback(
    generateHandleKeyDown(
      onKeyDown,
      state,
      add,
      listRef,
      optionValueMapping,
      renderedOptions,
      setState,
      inputRef,
    ),
    [renderedOptions, onKeyDown, state, add, optionValueMapping],
  );

  const a11yInputProps = {};
  if (optionMenu) {
    a11yInputProps['aria-expanded'] = 'true';
    a11yInputProps['aria-owns'] = listId;
    a11yInputProps['aria-controls'] = listId;
    if (state.ariaActiveDescendant) {
      a11yInputProps['aria-activedescendant'] = state.ariaActiveDescendant;
    }
  }

  let reachedMax = false;
  if (max >= 0) {
    reachedMax = renderedValue.length >= max;
  }
  const isDisabled =
    (props.disabled || validating || reachedMax) && !state.inputHasFocus;
  const removeDisabled = props.disabled || validating;

  const handleWrapperClick = useCallback(
    event => {
      if (
        !isDisabled &&
        (event.target === wrapperRef.current ||
          event.target === labelContainerRef.current)
      ) {
        inputRef.current.focus();
      }
    },
    [isDisabled],
  );

  const hasError = touched && touched[name] && errors && errors[name];
  return (
    <>
      <label>{labelText}</label>
      <Wrapper
        ref={wrapperRef}
        isDisabled={isDisabled}
        onClick={handleWrapperClick}
      >
        <LabelContainer
          ref={labelContainerRef}
          data-testid="multiselect-label-container"
          className={classNames({ 'with-values': !!renderedValue.length })}
        >
          {renderedValue.length
            ? renderedValue
                .map(valueItem => {
                  const propsForRemoveLabelHandle = {};
                  if (!removeDisabled) {
                    propsForRemoveLabelHandle.onKeyDown =
                      handleLabelKeyDown(valueItem);
                    propsForRemoveLabelHandle.onClick =
                      handleLabelClick(valueItem);
                    propsForRemoveLabelHandle.title = 'Remove';
                    if (state.multiSelectHasFocus) {
                      propsForRemoveLabelHandle.onBlur = handleBlur;
                      propsForRemoveLabelHandle.tabIndex = '0';
                    }
                  }
                  return (
                    <Label key={valueItem}>
                      {labelAccessor(realValue, valueItem)}
                      <RemoveLabelHandle {...propsForRemoveLabelHandle}>
                        <X />
                      </RemoveLabelHandle>
                    </Label>
                  );
                })
                .reduce((prev, next) => [prev, ' ', next])
            : null}
        </LabelContainer>
        <MagnifyingGlass
          className="magnifying-glass"
          width="20px"
          height="20px"
        />
        <Input
          id={id}
          disabled={isDisabled}
          name={name}
          ref={inputRef}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          autoComplete="off"
          role="combobox"
          aria-autocomplete="list"
          {...a11yInputProps}
          {...props}
        />
        {optionMenu}
      </Wrapper>
      {hasError && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </>
  );
};

MultiSelect.propTypes = {
  children: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  defaultValue: PropTypes.array,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  filterResults: PropTypes.bool.isRequired,
  id: PropTypes.any,
  keysAccessor: PropTypes.func,
  labelAccessor: PropTypes.func,
  labelText: PropTypes.any,
  /** Optionally set a max length to allow checked. Disables others once the max is reached */
  max: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  touched: PropTypes.object,
  validating: PropTypes.bool,
  value: PropTypes.array,
  valueAccessor: PropTypes.func,
};
MultiSelect.defaultProps = {
  filterResults: true,
  keysAccessor: object => Object.keys(object),
  placeholder: 'Search or select',
  valueAccessor: (object, key) => object[key],
  labelAccessor: (object, key) => object[key] && object[key],
};
MultiSelect.idCounter = 0;
MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;
