import React from 'react';
import PropTypes from 'prop-types';
import { URLS } from 'consts';
import themed from 'components/hoc/themed';
import { Compensation, ProjectSummaryFlexFactors } from './supporting';
import {
  Description,
  Label,
  ProjectSummaryContainer,
} from './ProjectSummaryStyles';
import ProjectBadge from 'components/shared/projects/ProjectBadge';
import ProjectExpiration from './ProjectExpiration';
import Breadcrumbs from 'components/ui/Breadcrumbs';
import { Layout } from 'components/containers/shared/Layout';
import { getFormattedDate } from 'utilities';
import ProjectCompanyInformation from 'components/shared/projects/ProjectCompanyInformation';
import ProjectDetails from 'components/shared/projects/ProjectDetails';
import Accordion from 'components/ui/Accordion';

// We don't want draft projects to be edited from the /review screen for job_post_v2 because all fields
// are required on that screen. We want to allow them to save the draft without all fields - so we send
// them to the /edit step wizard instead.

const DATE_FORMAT_OPTIONS = {
  month: 'short',
  year: 'numeric',
};

const archivedStatuses = [
  'filled',
  'cancelled',
  'pending_removal',
  'placed',
  'onboarding',
  'opt_out',
];

const ProjectSummary = ({
  expirationLabel,
  hideSidebar,
  isInvited,
  isMatch,
  project,
  showEditLink,
  talentDisplay,
  useAccordion,
}) => {
  return (
    <Layout title={project.project_title}>
      {!talentDisplay && (
        <>
          <div className="align-items-center d-flex flex-row mt-3">
            <Breadcrumbs
              history={[
                { name: 'Job posts', path: URLS.employerProjects },
                { name: project.project_title, path: '' },
              ]}
            />
          </div>
          <div className="d-flex pt-3 pb-3 justify-content-end">
            <a
              className="edit-link mr-3"
              href={`${URLS.employerProjects}/${project.slug}/duplicate`}
            >
              <i className="fa fa-copy" />
              &nbsp;Copy job
            </a>
            {showEditLink && !archivedStatuses.includes(project.status) && (
              <a
                href={
                  project.status !== 'draft'
                    ? URLS.employerProjectReview(project.slug)
                    : `${URLS.employerProjects}/${project.slug}/edit`
                }
              >
                <i className="fa fa-pencil" />
                &nbsp;Edit job
              </a>
            )}
          </div>
        </>
      )}
      <ProjectSummaryContainer talentDisplay={talentDisplay}>
        <div className="project-summary__main-content mr-md-4 w-100">
          <h2>{project.project_title}</h2>
          <div className="d-flex my-3 align-items-center">
            {
              <ProjectBadge
                activatedRecently={project.activated_recently}
                updatedRecently={project.updated_recently}
                talentDisplay={talentDisplay}
                isInvited={isInvited}
                isMatch={isMatch}
              />
            }
            {talentDisplay && !!expirationLabel && (
              <ProjectExpiration expirationLabel={expirationLabel} />
            )}
          </div>
          <ProjectCompanyInformation employer={project.employer} />
          {useAccordion ? (
            <Accordion
              title="View full job description"
              expandedTitle="Hide full job description"
              showBottomAccordion
            >
              <ProjectDetails project={project} talentDisplay={talentDisplay} />
              <ProjectSummaryFlexFactors
                project={project}
                talentDisplay={talentDisplay}
              />
              <div className="mb-3 pt-4">
                <Label>Start date</Label>
                {project.start_date
                  ? getFormattedDate(
                      new Date(project.start_date + 'T00:00:00'),
                      DATE_FORMAT_OPTIONS,
                    )
                  : 'N/A'}
              </div>
              {!project.full_time && project.end_date && (
                <div className="mb-4">
                  <Label>End date</Label>
                  {getFormattedDate(
                    new Date(project.end_date + 'T00:00:00'),
                    DATE_FORMAT_OPTIONS,
                  ) || 'N/A'}
                </div>
              )}
              <Label>Salary</Label>
              <Compensation project={project} />
              <div className="project-summary__job-description mt-4 mt-md-0">
                <h2 className="font-primary-regular font-md mb-3 mt-4">
                  Job description
                </h2>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: project.sanitized_description,
                  }}
                />
              </div>
            </Accordion>
          ) : (
            <>
              <ProjectDetails project={project} talentDisplay={talentDisplay} />
              <div className="project-summary__job-description mt-4 mt-md-0">
                <p>
                  <em>
                    All applicants applying for U.S. job openings must be
                    legally authorized to work in the United States and are
                    required to have U.S. residency at the time of application.
                  </em>
                </p>
                <h2 className="font-primary-regular font-md mb-3 mt-4">
                  Job description
                </h2>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: project.sanitized_description,
                  }}
                />
              </div>
            </>
          )}
        </div>
        {!hideSidebar && (
          <div className="align-left">
            <Compensation project={project} />
            <div className="mb-3 pt-4">
              <Label>Start date</Label>
              {project.start_date
                ? getFormattedDate(
                    new Date(project.start_date + 'T00:00:00'),
                    DATE_FORMAT_OPTIONS,
                  )
                : 'N/A'}
            </div>
            {!project.full_time && project.end_date && (
              <div className="mb-4">
                <Label>End date</Label>
                {getFormattedDate(
                  new Date(project.end_date + 'T00:00:00'),
                  DATE_FORMAT_OPTIONS,
                ) || 'N/A'}
              </div>
            )}
            <ProjectSummaryFlexFactors
              project={project}
              talentDisplay={talentDisplay}
            />
          </div>
        )}
      </ProjectSummaryContainer>
    </Layout>
  );
};

ProjectSummary.propTypes = {
  expirationLabel: PropTypes.string,
  from: PropTypes.string,
  hideSidebar: PropTypes.bool,
  project: PropTypes.object.isRequired,
  showEditLink: PropTypes.bool,
  singleColumn: PropTypes.bool,
  talentDisplay: PropTypes.bool,
  useAccordion: PropTypes.bool,
  isInvited: PropTypes.bool,
  isMatch: PropTypes.bool,
};

ProjectSummary.defaultProps = {
  hideSidebar: false,
  showEditLink: false,
  singleColumn: false,
  talentDisplay: false,
  useAccordion: false,
  isMatch: false,
};

export default themed(ProjectSummary);
