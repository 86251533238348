import React from 'react';

const Badge = props => {
  return (
    <svg
      {...props}
      viewBox="0 0 46 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0678 15.6363C6.8638 26.633 6.63085 39.1899 23.3851 45.3373C40.1413 39.1899 39.9084 26.6328 39.7044 15.636C39.6824 14.4506 39.6608 13.2833 39.6608 12.1441C32.9189 12.1441 27.7521 10.1055 23.3851 5.61002C19.018 10.1055 13.8533 12.1441 7.11145 12.1441C7.11145 13.2834 7.08979 14.4508 7.0678 15.6363ZM24.7719 1.11039C29.4846 5.43439 34.9542 7.61311 41.7512 7.96021C42.8517 8.01457 43.7292 8.89484 43.7435 10.0218C43.7539 10.8131 43.7686 11.6131 43.7834 12.4196C44.0278 25.7305 44.3054 40.8521 25.899 48.9232C24.2953 49.6257 22.4728 49.6257 20.8712 48.9232C2.46677 40.8521 2.74442 25.7307 2.98882 12.4198C3.00363 11.6132 3.01832 10.8132 3.02872 10.0218C3.04304 8.89484 3.92054 8.01457 5.02304 7.96021C11.816 7.61311 17.2855 5.43439 21.9983 1.11039C22.798 0.378569 23.9721 0.378569 24.7719 1.11039ZM18.1669 23.9657C17.316 23.0938 15.9353 23.0938 15.0844 23.9657C14.2315 24.8355 14.2315 26.2469 15.0844 27.1167L20.1756 32.321C20.9733 33.1385 22.2701 33.1385 23.0678 32.321L33.199 21.9626C34.0519 21.0928 34.0519 19.6835 33.199 18.8116C32.3481 17.9418 30.9694 17.9418 30.1185 18.8116L21.6217 27.4972L18.1669 23.9657Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default Badge;
