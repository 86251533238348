import styled, { css } from 'styled-components';

import CollapsibleContainer from 'components/ui/CollapsibleContainer';
import { colors, fontWeight } from 'styleGuide';

export const ClickableWrapper = styled.button`
  all: unset;
  overflow: hidden;
`;

export const DetailsWrapper = styled.div`
  ${props => getDetailsStyles(props)};
`;

const getDetailsStyles = ({ isMobile, selectable }) => {
  const gridTemplateAreas = selectable ? 'check name menu' : 'name menu';
  const gridTemplateColumns = selectable
    ? 'min-content auto min-content'
    : 'auto min-content';
  if (isMobile) {
    return css`
      align-items: center;
      display: grid;
      gap: 16px;
      grid-auto-flow: row;
      grid-template-areas: ${gridTemplateAreas};
      grid-template-columns: ${gridTemplateColumns};
      margin: 25px -30%;
      padding: 0 35%;
    `;
  }
  return css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;
};

const styles = {
  border: `1px solid ${colors.gray.medium}`,
  transition: `all 300ms ease-in-out`,
};

export const MobileWrapper = styled.div`
  border-top: ${styles.border};
  margin: 0 -5vw;
  position: relative;
  transition: ${styles.transition};
  &:last-of-type {
    border-bottom: ${styles.border};
  }
  &::before,
  &::after {
    transition: ${styles.transition};
  }
  &.selected {
    background-color: ${colors.blue.light};
    font-weight: ${fontWeight.fontBolder};
    &::before,
    &::after {
      background-color: ${colors.blue.primary};
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }
  .check circle {
    transition: ${styles.transition};
  }
  .page-details {
    margin: 2rem auto;
    width: calc(100% - 10vw);
  }
`;

export const StyledCollapsibleContainer = styled(CollapsibleContainer)`
  position: absolute;
  right: 8px;
  top: 2rem;
  z-index: 50;
`;
