import React, { forwardRef, useRef, useEffect, useCallback } from 'react';
import { Row, Col } from 'components/ui/grid';
import Select from './Select';
import { MONTHS } from 'consts';
import { getLastYears, optionBuilder } from 'utilities';
import { validated } from 'reactiverecord';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import PropTypes from 'prop-types';
const monthOptions = MONTHS.map(optionBuilder);
const yearOptions = getLastYears(60).map(optionBuilder);
const defaultOption = <option value="">Select One</option>;

const ApproximateDateInput = forwardRef(
  (
    {
      defaultValue,
      disabled,
      errorText,
      labelText,
      onBlur,
      onChange,
      validating,
    },
    forwardedRef,
  ) => {
    const monthRef = useRef(null);
    const yearRef = useRef(null);
    const monthDefaultValue = defaultValue ? defaultValue.slice(5, 7) : null;
    const yearDefaultValue = defaultValue ? defaultValue.slice(0, 4) : null;
    const getValue = () => {
      if (
        monthRef.current &&
        monthRef.current.value &&
        yearRef.current &&
        yearRef.current.value
      ) {
        return `${yearRef.current.value}-${monthRef.current.value}-01`;
      }
      return '';
    };

    const handleChange = useCallback(() => {
      if (getValue()) {
        onChange();
      }
    }, [onChange]);

    const handleBlur = useCallback(() => {
      if (getValue()) {
        onBlur();
      }
    }, [onBlur]);

    useEffect(() => {
      forwardedRef({
        get value() {
          return getValue();
        },
      });
    }, [forwardedRef]);

    return (
      <Row>
        <Col className="mt-2" col={6}>
          <Select
            data-testid={`${labelText}-month`}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={monthDefaultValue}
            ref={monthRef}
            labelText={`${labelText} Month`}
            disabled={disabled || validating}
          >
            {defaultOption}
            {monthOptions}
          </Select>
        </Col>
        <Col className="mt-2" col={6}>
          <Select
            data-testid={`${labelText}-year`}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={yearDefaultValue}
            ref={yearRef}
            labelText={`${labelText} Year`}
            disabled={disabled || validating}
          >
            {defaultOption}
            {yearOptions}
          </Select>
        </Col>
        {errorText ? (
          <Col col={12}>
            <ErrorMessage>{errorText}</ErrorMessage>
          </Col>
        ) : null}
      </Row>
    );
  },
);

ApproximateDateInput.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  labelText: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  validating: PropTypes.bool,
};

ApproximateDateInput.displayName = 'ApproximateDateInput';

export default validated(ApproximateDateInput);
