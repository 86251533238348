import React from 'react';

export default function HideEye(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 24 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>HideEye</title>
      <g id="GUI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Icons"
          transform="translate(-631.000000, -156.000000)"
          fill="currentColor"
        >
          <g
            id="2-Icon/General/HideEye"
            transform="translate(632.000000, 152.000000)"
          >
            <g id="Group" transform="translate(-1.000000, 3.000000)">
              <g
                id="2-Icon/General/Eye"
                transform="translate(0.441670, 1.000000)"
                fillRule="nonzero"
              >
                <path
                  d="M11.5735,8.8817842e-16 C17.3045,0.029 21.0455,3.747 22.6465,5.752 C23.2335,6.486 23.2335,7.514 22.6465,8.248 C21.0455,10.253 17.3045,13.97 11.5735,13.997 L11.5735,13.997 L11.5735,14 L11.5435,13.998 L11.5435,13.998 L11.5135,14 C5.7815,13.97 2.0415,10.253 0.4395,8.248 C-0.1465,7.514 -0.1465,6.486 0.4395,5.752 C2.0415,3.747 5.7815,0.029 11.5135,0.003 L11.5135,0.003 C11.5235,8.8817842e-16 11.5335,0.002 11.5435,0.002 L11.5585,0.001 L11.5585,0.001 L11.5735,8.8817842e-16 Z M11.5675,2 L11.5195,2 C5.3055,2.028 2.1365,6.863 2.0515,6.994 L2.0515,6.994 C2.0435,6.994 2.0475,7 2.0475,7 L2.0475,7 L2.0435,7.006 C2.1365,7.137 5.3055,11.972 11.5195,11.998 L11.5195,11.998 L11.5195,12 L11.5435,11.999 L11.5435,11.999 L11.5675,12 C17.7815,11.972 20.9505,7.137 21.0355,7.006 L21.0355,7.006 L21.0435,7.006 C21.0395,7 21.0435,6.994 21.0435,6.994 L21.0435,6.994 C20.9505,6.863 17.7815,2.028 11.5675,2.002 L11.5675,2.002 L11.5675,2 Z M11.5435,3.0001 C13.7525,3.0001 15.5435,4.7911 15.5435,7.0001 C15.5435,9.2091 13.7525,11.0001 11.5435,11.0001 C9.3345,11.0001 7.5435,9.2091 7.5435,7.0001 C7.5435,4.7911 9.3345,3.0001 11.5435,3.0001 Z M11.5435,5.0001 C10.4395,5.0001 9.5435,5.8951 9.5435,7.0001 C9.5435,8.1041 10.4395,9.0001 11.5435,9.0001 C12.6475,9.0001 13.5435,8.1041 13.5435,7.0001 C13.5435,5.8951 12.6475,5.0001 11.5435,5.0001 Z"
                  id="Eye"
                ></path>
              </g>
              <path
                d="M1.13686838e-13,6.9660254 L24,6.9660254 C24.552,6.9660254 25,7.4140254 25,7.9660254 C25,8.5180254 24.552,8.9660254 24,8.9660254 L1.13686838e-13,8.9660254 C-0.552,8.9660254 -1,8.5180254 -1,7.9660254 C-1,7.4140254 -0.552,6.9660254 1.13686838e-13,6.9660254 Z"
                id="Subtract"
                fillRule="nonzero"
                transform="translate(12.000000, 7.966025) rotate(-30.000000) translate(-12.000000, -7.966025) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
