import React from 'react';
import PropTypes from 'prop-types';

import { HeaderButton } from './Header/PublicHeaderStyles';
import themed from 'components/hoc/themed';
import LoginModal from 'components/shared/Header/LoginModal';
import ShowToggle from 'components/ui/ShowToggle';

const LoginButton = ({ buttonLabel, location }) => {
  return (
    <ShowToggle preventDefault>
      {(show, toggle) => (
        <>
          <HeaderButton
            className={location}
            data-id={`${location}-login-btn`}
            onClick={toggle}
            secondary
          >
            {buttonLabel}
          </HeaderButton>
          <LoginModal initialType="login" show={show} toggle={toggle} />
        </>
      )}
    </ShowToggle>
  );
};

LoginButton.propTypes = {
  buttonLabel: PropTypes.string,
  location: PropTypes.oneOf(['header', 'homepage']),
};

LoginButton.defaultProps = {
  buttonLabel: 'Log in',
  location: 'header',
};

export default themed(LoginButton);
