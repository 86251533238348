import ReactiveRecord, { Model } from 'reactiverecord';
const route = ':prefix/employers/clouds';
class Cloud extends Model {
  static routes = {
    index: route,
    show: `${route}/:id`,
    create: route,
    update: `${route}/:id`,
  };
  static schema = {
    capabilities: Array,
    capabilities_category: Object,
    capabilities_category_id: Number,
    capability_ids: Array,
    cloud_memberships_count: Number,
    created_at: String,
    cloud_status_id: Number,
    employer_id: Number,
    employer_name: String,
    expertises: Object,
    expertises_labels: Array,
    expertises_attributes: Array,
    industries: Object,
    industry_ids: Array,
    is_remote: Number,
    is_stock_cloud: Number,
    locations: Array,
    match_last_updated_at: String,
    name: String,
    title_joins_attributes: Array,
    titles: Object,
    relevant_experience: Array,
    user_id: Number,
    user_full_name: String,
  };
}

export default ReactiveRecord.model('Cloud', Cloud);
