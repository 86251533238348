import styled, { css } from 'styled-components';
import { spinningAsyncAnimation, spinningAnimation } from 'styleGuide';

export const loaderSizes = { sm: '1.25rem', md: '2.5rem', lg: '5rem' };

export const Loader = styled.div(
  props => css`
    position: relative;
    height: ${loaderSizes[props.size]};
    width: ${loaderSizes[props.size]};
    outline: 2px solid ${({ theme }) => theme.primaryBlue};
    ${spinningAnimation()};
    z-index: 9;
    &:after {
      content: '';
      display: block;
      height: inherit;
      width: inherit;
      outline: inherit;
      ${spinningAsyncAnimation()};
    }
  `,
);
