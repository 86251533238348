import React from 'react';

const SelectOne = () => {
  return (
    <option data-testid="default" value="">
      Select One
    </option>
  );
};

const FieldsOptions = {
  SelectOne,
};

export default FieldsOptions;
