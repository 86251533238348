import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'components/ui/grid';
import styled, { css } from 'styled-components';
import { useMediaSet } from 'use-media-set';
import { mediaSetQueries } from 'styleGuide';
import momIcon from 'assets/illustrations/happy-mom.png';
import { URLS } from 'consts';
import VideoCallFinishedMom from './VideoCallFinishedMom';
import Flex from 'components/ui/Flex';
import LinkButton from 'components/ui/buttons/LinkButton';

const VideoCallFinished = ({ resourceType, videoCall }) => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const isMobile = mediaStates.has('mobile');

  const StyledRow = styled(Row)`
    align-items: center;
    display: flex;
    min-height: 75vh;
    text-align: center;
  `;

  const HappyMom = styled.div`
    height: 10rem;
    width: 10rem;
    margin: 0 auto;
    background: url(${momIcon}) no-repeat center center;
    background-size: contain;
    margin-bottom: 2rem;
  `;

  const Heading = styled.div(
    ({ theme }) => css`
      font-size: ${theme.fontSizes.md};
      font-weight: ${theme.fontWeights.fontHeavy};
    `,
  );

  const Paragraph = styled.p(
    ({ theme }) => css`
      font-size: ${theme.fontSizes.md};
      margin-bottom: 2.5rem;
    `,
  );

  const StyledLinkButton = styled(LinkButton)`
    width: ${isMobile ? '100%' : 'auto'};
  `;

  return (
    <Grid>
      <StyledRow>
        <Col col={12} md={8} className="offset-md-2">
          <HappyMom />
          {resourceType === 'Mom' ? (
            <VideoCallFinishedMom videoCall={videoCall} wrapButtons />
          ) : (
            <>
              <Heading className="mb-3">Your interview has concluded</Heading>
              <Paragraph>
                Thank you for your time - we will follow up with next steps.
              </Paragraph>
              <Flex
                direction={isMobile ? 'column' : 'row'}
                gap={16}
                justifyContent="center"
                reverse={isMobile}
                wrap="reverse"
              >
                <StyledLinkButton
                  id="go-to-dashboard"
                  href={URLS.employerHome}
                  secondary
                >
                  Go to dashboard
                </StyledLinkButton>
                <StyledLinkButton
                  id="view-more-applicants"
                  href={URLS.employerApplicantReview(videoCall.project_slug)}
                >
                  View more applicants
                </StyledLinkButton>
              </Flex>
            </>
          )}
        </Col>
      </StyledRow>
    </Grid>
  );
};

VideoCallFinished.propTypes = {
  resourceType: PropTypes.string.isRequired,
  videoCall: PropTypes.object.isRequired,
};

export default VideoCallFinished;
