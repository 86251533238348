import ReactiveRecord, { Model } from 'reactiverecord';

class Availability extends Model {
  static schema = {
    id: Number,
    label: String,
  };
}

export default ReactiveRecord.model('Availability', Availability);
