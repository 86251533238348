import AnalyticsProvider from '../components/shared/analytics/AnalyticsProvider';
import React from 'react';

export function triggerAnalytics({ action, ...props }, includeData = true) {
  if (window.Utils.isTrackingEnabled()) {
    if (includeData) {
      window.gtag('event', action, {
        timestamp: new Date().toString(),
        ...props,
      });
    } else {
      window.gtag('event', action);
    }
  }
}

export function withAnalytics(WrappedComponent, providerProps) {
  const ComponentWithProvider = function (props) {
    return (
      <AnalyticsProvider {...providerProps}>
        <WrappedComponent {...props} />
      </AnalyticsProvider>
    );
  };
  return ComponentWithProvider;
}
