import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Validate } from 'reactiverecord';
import SyntheticInput from './SyntheticInput';
import { PreventableEvent, without } from 'utilities';

/**
 * Returns an array value for a list of Checkboxes based on their checked value
 */
const CheckboxGroup = forwardRef(
  ({ children, invertChecked, onChange, max, ...props }, forwardedRef) => (
    <Validate {...without.call(props, 'onBlur')} ref={forwardedRef}>
      {(validateProps, errorText, disabled) => (
        <SyntheticInput disabled={disabled} {...validateProps}>
          {(value, setValue) => {
            let reachedMax = false;
            if (max >= 0) {
              reachedMax = value.length >= max;
            }
            function handleChange(originalValue) {
              return event => {
                let nextValue = value.slice();
                nextValue.push(originalValue);
                if (
                  invertChecked ? event.target.checked : !event.target.checked
                ) {
                  nextValue = nextValue.filter(item => item !== originalValue);
                }
                setValue(nextValue);
                setTimeout(() => {
                  if (onChange) {
                    onChange(new PreventableEvent(nextValue));
                  }
                }, 0);
              };
            }
            function register(givenValue) {
              const checked = value.indexOf(givenValue) >= 0;
              return {
                checked: invertChecked ? !checked : checked,
                value: givenValue,
                disabled: disabled || (reachedMax && !checked),
                onChange: handleChange(givenValue),
              };
            }
            return children(register, errorText);
          }}
        </SyntheticInput>
      )}
    </Validate>
  ),
);

CheckboxGroup.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  /** Optionally set a max length to allow checked. Disables others once the max is reached */
  max: PropTypes.number,
  /** Optionally invert how checkboxes are rendered. Checked are not included in value, unchecked are included in value */
  invertChecked: PropTypes.bool,
};
CheckboxGroup.displayName = 'CheckboxGroup';

export default CheckboxGroup;
