import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/ui/formik/Select';
import { Field } from 'formik';
import FieldsOptions from './SelectDefaultOptions';
import { EXPERIENCE_LEVEL_ARRAY } from 'consts';
import { optionBuilder } from 'utilities';

const options = EXPERIENCE_LEVEL_ARRAY.map(optionBuilder);

const FieldSeniorityLevel = ({ id, labelText, name, ...props }) => (
  <Field id={id} name={name} labelText={labelText} as={Select} {...props}>
    <FieldsOptions.SelectOne />
    {options}
  </Field>
);

FieldSeniorityLevel.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FieldSeniorityLevel;
