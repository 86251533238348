import React from 'react';
import PropTypes from 'prop-types';
import { URLS } from 'consts';
import Badge from 'components/ui/Badge';
import Flex from 'components/ui/Flex';

const ProjectTitle = ({ onProjectView, project }) => {
  const showBadges =
    project.activated_recently || project.updated_recently || project.is_match;
  return (
    <div className="mb-1">
      <div className="font-md font-bold d-flex flex-wrap mb-1">
        <a
          className="unstyled mr-2"
          href={URLS.project(project.slug)}
          onClick={onProjectView}
        >
          {project.project_title}
        </a>
        {showBadges && (
          <Flex gap={5}>
            {(project.activated_recently || project.updated_recently) && (
              <Badge>{project.activated_recently ? 'New' : 'Updated'}</Badge>
            )}
            {project.is_match && (
              <Badge color="primary" variant="outlined">
                Recommended
              </Badge>
            )}
          </Flex>
        )}
      </div>
      <div className="d-flex flex-wrap">
        {project.company_name ? (
          <>
            <div className="font-sm mr-1 text-alt">{project.company_name}</div>
          </>
        ) : (
          <>
            <div className="font-sm mr-1 text-alt">Our client</div>
          </>
        )}
        {project.employer_industry && (
          <>
            <div className="mr-1 text-alt">-</div>
            <div className="font-sm text-alt">
              {project.employer_industry} company
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ProjectTitle.propTypes = {
  onProjectView: PropTypes.func,
  project: PropTypes.object.isRequired,
};

export default ProjectTitle;
