import ReactiveRecord, { Model } from 'reactiverecord';

const routePrefix = '/api/v1/employers';
class EmployerService extends Model {
  static routes = {
    index: routePrefix,
  };

  static schema = {
    employers: Array,
  };
}

export default ReactiveRecord.model('EmployerService', EmployerService);
