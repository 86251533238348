import React from 'react';

export default function Check(props) {
  return (
    <svg viewBox="0 0 22 22" {...props}>
      <line
        x1="7"
        y1="11"
        x2="10"
        y2="14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="10"
        y1="14"
        x2="15"
        y2="8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
