import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';

const CloudStarOutline = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 15">
      <g fill="none" fillRule="evenodd" strokeLinecap="square">
        <g stroke={theme.lightGray}>
          <g>
            <g>
              <g>
                <path
                  d="M16.081 5.49l-5.083-.71c-.108-.015-.2-.08-.248-.173L8.477.177c-.12-.236-.47-.236-.59 0l-2.274 4.43c-.048.093-.14.158-.248.173l-5.083.71c-.27.038-.378.357-.182.54l3.678 3.45c.077.072.113.176.095.28l-.87 4.869c-.045.259.237.456.479.334l4.546-2.3c.096-.047.21-.047.307 0l4.546 2.3c.242.122.524-.075.478-.334l-.868-4.87c-.019-.103.017-.207.094-.28l3.679-3.448c.195-.184.087-.503-.183-.54"
                  transform="translate(-667 -607) translate(390 600) translate(250) translate(26.818 7)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CloudStarOutline.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

CloudStarOutline.defaultProps = {
  width: 16,
  height: 15,
};

export default CloudStarOutline;
