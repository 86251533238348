import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from 'components/ui/inputs/Input/styles';
import { getInputSharedStyles } from 'components/ui/inputs/InputSharedStyles';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import NumberFormat from 'react-number-format';
import { without } from 'utilities';

const StyledPhoneInput = styled(NumberFormat)`
  text-align: left;
  ${getInputSharedStyles()}
`;

const InputPhone = ({
  allowEmptyFormatting,
  labelText,
  touched,
  errors,
  format,
  mask,
  onChange,
  name,
  ...props
}) => {
  const generatedId = useMemo(
    () => `input-phone-${InputPhone.idCounter++}`,
    [],
  );
  const id = props.id || generatedId;

  return (
    <Label htmlFor={id}>
      {labelText ? <span className="label-text">{labelText}</span> : null}
      <div className="input-wrapper">
        <StyledPhoneInput
          id={id}
          type="tel"
          format={format}
          mask={mask}
          onValueChange={onChange}
          allowEmptyFormatting={allowEmptyFormatting}
          name={name}
          {...without.call(props, 'setFieldValue')}
        />
      </div>
      {touched && touched[name] && errors && errors[name] && (
        <ErrorMessage>{errors[name]}</ErrorMessage>
      )}
    </Label>
  );
};

InputPhone.propTypes = {
  allowEmptyFormatting: PropTypes.bool, // It shows the input format before focusing on the element.
  labelText: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.any,
  successText: PropTypes.string,
  format: PropTypes.string,
  mask: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
};

InputPhone.defaultProps = {
  allowEmptyFormatting: false,
  format: '(###) ###-####',
  mask: '_',
};

InputPhone.idCounter = 0;

export default InputPhone;
