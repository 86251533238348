import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/buttons/Button';

const ConfirmCall = props => {
  return (
    <div className="text-center">
      <p className="font-md">Are you sure you want to call this candidate?</p>
      <div className="d-flex flex-column">
        <Button
          id="call-btn"
          data-testid="call-btn"
          onClick={props.onConfirmCall}
        >
          Call
        </Button>
        <Button
          id="cancel-btn"
          data-testid="cancel-btn"
          onClick={props.handleOnClose}
          secondary
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

ConfirmCall.propTypes = {
  onConfirmCall: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
};

export default ConfirmCall;
