import { formatDateTime } from 'utilities';

export const isVideoTrack = track => track && track.kind === 'video';

export const formattedDateTime = (time, dateOption, timeOption) => {
  const dateTime = formatDateTime(time, dateOption, timeOption);
  return `${dateTime.date} <span class="text-nowrap">@ ${dateTime.time}</span>`;
};

export const getThankYouNoteEndDate = timestamp => {
  const date = new Date(timestamp);
  const laterDate = date.setDate(date.getDate() + 5);
  return new Date(laterDate).toLocaleDateString('en-us', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });
};

export const dateTimeOptions = {
  date: {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  },
  time: {
    timeStyle: 'short',
  },
};
