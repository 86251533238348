import React from 'react';
import { Field, useFormikContext } from 'formik';
import { optionBuilder } from 'utilities';
import Select from 'components/ui/formik/Select';
import { STATES } from 'consts';

const FieldLegalState = () => {
  const { errors, touched, setFieldValue } = useFormikContext();
  return (
    <Field
      id="legal_state"
      data-testid="legal_state"
      name="legal_state"
      labelText="Legal Company State"
      touched={touched}
      errors={errors}
      as={Select}
      onChange={({ target: { value } }) => setFieldValue('legal_state', value)}
    >
      <option value="">Select</option>
      {STATES.map(([state]) => optionBuilder([state, state]))}
    </Field>
  );
};

export default FieldLegalState;
