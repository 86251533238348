import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  SectionSubtitle,
  ListItem,
  DegreeTitle,
  SideSectionContainer,
} from './styles';
import MomContext from 'components/talent/MomContext';
import { URLS } from 'consts';
import ProfileContext from './ProfileContext';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import Caret from 'components/ui/icons/Caret';

const Title = ({ title }) =>
  title ? <DegreeTitle>{removeDegreeName(title)}</DegreeTitle> : null;

const Education = () => {
  const mom = useContext(MomContext);
  const profile = useContext(ProfileContext);
  const educationItems =
    mom.schools &&
    mom.schools.map(value => (
      <ListItem key={value.id}>
        <Title title={value.degree} />
        <div>{value.concentration}</div>
        <div>{value.school_name}</div>
      </ListItem>
    ));
  return (
    <SideSectionContainer>
      <SectionSubtitle className="mb-3">Education</SectionSubtitle>
      {educationItems && educationItems.length ? (
        <ul>{educationItems}</ul>
      ) : (
        <div>
          You don&apos;t have your education added.
          {mom.isBidSource
            ? ' Save this bid as a draft and '
            : ' Click here to '}
          <ButtonLink
            data-id="edit-education-btn"
            onClick={() => profile.editProfileAction(URLS.talentEducation)}
          >
            edit your education&nbsp;
            <Caret />
          </ButtonLink>
        </div>
      )}
    </SideSectionContainer>
  );
};

function removeDegreeName(degree) {
  return degree.replace(/\(.*\)/, '').trim();
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Education;
