import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components/ui/inputs/Input/styles';
import styled from 'styled-components';
import ChevronDown from 'components/ui/icons/ChevronDown';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import LabelWithTooltip from './LabelWithTooltip';
import { useFormikContext } from 'formik';

const StyledSelect = styled.select`
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 13px 48px 13px 15px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
  line-height: 24px;
  color: ${({ theme }) => theme.darkGray};
  cursor: pointer;
  background-color: ${({ white, theme }) =>
    white ? theme.white : theme.lightBlue};
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lightGray};
  -webkit-appearance: none;

  ${({ hasSuccess, theme }) =>
    hasSuccess && `box-shadow: inset 0 0 0 1px ${theme.green};`}
  ${({ hasError, theme }) =>
    hasError && `box-shadow: inset 0 0 0 1px ${theme.red};`}

  &:focus,
  .forceFocusState & {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primaryBlue};
    font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.lightGray};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lightGray};
  }
  option {
    font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
  }
`;

const StyledCaret = styled.i`
  bottom: 19.275px !important;
  ${props => (props.small ? 'right: 10px !important;' : '')}
`;

const Select = ({
  children,
  id,
  labelText,
  name,
  onChange,
  secondaryLabelText,
  white,
  tooltipText,
  ...props
}) => {
  const { errors, touched } = useFormikContext();
  const hasError = touched && touched[name] && errors && errors[name];
  return (
    <Label htmlFor={id}>
      <LabelWithTooltip tooltipText={tooltipText} labelText={labelText} />
      {secondaryLabelText && (
        <div className="secondary-label-text">{secondaryLabelText}</div>
      )}
      <div className="input-wrapper">
        <StyledCaret className="inline-icon" small={props.small}>
          <ChevronDown width={props.small ? 16 : 20} />
        </StyledCaret>
        <StyledSelect
          className={props.truncate ? 'text-overflow' : null}
          id={id}
          onChange={onChange}
          name={name}
          white={white}
          {...props}
        >
          {children}
        </StyledSelect>
      </div>
      {hasError && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </Label>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  secondaryLabelText: PropTypes.string,
  small: PropTypes.bool,
  truncate: PropTypes.bool,
  /** White input for rendering on non-white background */
  white: PropTypes.bool,
  tooltipText: PropTypes.string,
};

Select.displayName = 'Select';

export default Select;
