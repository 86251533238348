import React from 'react';
import PropTypes from 'prop-types';

import { useMediaSet } from 'use-media-set';
import { mediaSetQueries } from 'styleGuide';

import calendar from 'assets/illustrations/calendar.svg';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/FlexItem';
import styled from 'styled-components';
import AuthorizeNylasButton from 'components/employer/interviews/RequestInterview/AuthorizeNylasButton';
import Button from 'components/ui/buttons/Button';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import { BenefitsContent } from 'components/employer/interviews/RequestInterview/supporting';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  margin: 0 auto;
  padding-bottom: 3.125rem;
  gap: 1.5rem;
`;

const CalendarSyncModal = ({
  user,
  handleClose,
  handleDismissForever,
  from,
}) => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const isSmallScreen = mediaStates.has('mobileOrSmTablet');
  const fromInterviewsPage = from === 'Interviews';

  return (
    <div>
      <ContentWrapper>
        <img
          src={calendar}
          alt="calendar icon"
          width="120px"
          className="m-auto"
        />
        <h1 className="text-center">Schedule interviews the easy way</h1>
        <div>
          <BenefitsContent />
        </div>
      </ContentWrapper>
      <Flex
        gap={16}
        direction={isSmallScreen ? 'column' : 'row'}
        justifyContent="flex-end"
      >
        {!fromInterviewsPage && (
          <FlexItem
            order={isSmallScreen ? 3 : 2}
            className={isSmallScreen ? '' : 'mr-auto my-auto'}
            align="stretch"
          >
            <ButtonLink
              className="w-100"
              data-id="dismiss-calendar-modal-button"
              onClick={handleDismissForever}
            >
              <span className="font-heavy">Do not ask again</span>
            </ButtonLink>
          </FlexItem>
        )}
        <FlexItem order={2} align="stretch">
          <Button
            data-id="continue-scheduling-btn"
            onClick={handleClose}
            className={`${fromInterviewsPage && 'px-5'} w-100`}
            secondary
          >
            {fromInterviewsPage ? 'No thanks' : 'Remind me later'}
          </Button>
        </FlexItem>
        <FlexItem order={isSmallScreen ? 1 : 3} align="stretch">
          <AuthorizeNylasButton
            data-id="select-nylas-scheduler"
            user={user}
            className={fromInterviewsPage ? 'px-5 w-100' : 'w-100'}
            from={from}
          />
        </FlexItem>
      </Flex>
    </div>
  );
};

CalendarSyncModal.propTypes = {
  user: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  handleDismissForever: PropTypes.func,
  from: PropTypes.oneOf(['Dashboard', 'Interviews']).isRequired,
};

export default CalendarSyncModal;
