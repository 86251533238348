import { useState, useCallback, useEffect } from 'react';

function useRoveFocus(listLength, listRef) {
  const [currentFocus, setCurrentFocus] = useState(0);

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 40) {
        // Down Arrow
        e.preventDefault();
        setCurrentFocus(currentFocus === listLength - 1 ? 0 : currentFocus + 1);
      } else if (e.keyCode === 38) {
        // Up arrow
        e.preventDefault();
        setCurrentFocus(currentFocus === 0 ? listLength - 1 : currentFocus - 1);
      }
    },
    [listLength, currentFocus, setCurrentFocus],
  );

  useEffect(() => {
    const listReference = listRef.current;
    listReference.addEventListener('keydown', handleKeyDown, false);
    return () => {
      listReference.removeEventListener('keydown', handleKeyDown, false);
      // Resetting currentFocus if list is cleared
      if (listLength === 0) {
        setCurrentFocus(0);
      }
    };
  }, [handleKeyDown, listRef, listLength]);

  return [currentFocus, setCurrentFocus];
}

export default useRoveFocus;
