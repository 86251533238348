import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { activateLoader, deactivateLoader, showMessage } from 'utilities';
import { useCancelAppointmentMutation } from 'requests';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import Button from 'components/ui/buttons/Button';
import Modal from 'components/ui/Modal';
import AnalyticsContext from 'components/shared/analytics/AnalyticsContext';
import { ANALYTICS } from 'consts';
import InterviewContext from './InterviewContext';
import { colors } from 'styleGuide';

const CancelInterviewButton = props => {
  const { trackEvents } = useContext(AnalyticsContext);
  const dispatch = useContext(InterviewContext);
  const { mutate: cancelAppointment } = useCancelAppointmentMutation(
    props.cancelLink,
  );
  const cancelButton = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleCancelInterview = () => {
    trackEvents &&
      trackEvents(ANALYTICS.EVENT_TYPES.Custom)({ action: 'Cancel interview' });
    activateLoader();
    handleCloseModal();
    deactivateLoader();
    cancelAppointment(
      {},
      {
        onSuccess: data => {
          showMessage(data.message);
          handleCloseModal();
          dispatch({ type: 'cancel', interview: props.interview });
          deactivateLoader();
          cancelButton.current.closest('.interviewee').remove();
        },
        onError: error => {
          deactivateLoader();
          showMessage(`An error has occurred: ${error}`, true);
        },
      },
    );
  };
  return (
    <>
      <ButtonLink id="show-modal" onClick={handleShowModal} ref={cancelButton}>
        <i className="fa fa-remove" aria-hidden="true" /> Cancel
      </ButtonLink>
      <Modal onHide={handleCloseModal} show={showModal}>
        <div className="mt-5 pl-3 pr-3">
          <h2>Are you sure you want to cancel this interview?</h2>
          <p className="mt-3 mb-5">
            If you cancel the interview, we&apos;ll send an email to the
            candidate on your behalf.
          </p>
          <div className="d-flex flex-column flex-lg-row justify-content-between gap-2 w-100">
            <div className="flex-grow-1 flex-lg-grow-0 order-3 order-lg-1">
              <Button
                size="sm"
                className="px-xl-5 px-lg-4 py-3 py-lg-2 w-100"
                id="cancel"
                secondary={true}
                onClick={handleCloseModal}
              >
                Keep interview
              </Button>
            </div>

            <div className="flex-grow-1 flex-lg-grow-0 order-1 order-lg-3">
              <Button
                size="sm"
                className="px-xl-5 px-lg-4 py-3 py-lg-2 w-100"
                id="confirm"
                style={{ backgroundColor: colors.error }}
                onClick={handleCancelInterview}
              >
                Cancel interview
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

CancelInterviewButton.propTypes = {
  interview: PropTypes.object.isRequired,
  cancelLink: PropTypes.string.isRequired,
};

export default CancelInterviewButton;
