import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const getBackgroundColor = (theme, active, hover) => {
  if (active) {
    return theme.primaryBlue;
  }
  if (hover) {
    return theme.lightBlue;
  }
  return theme.white;
};

const getColor = (theme, active, hover) => {
  if (active) {
    return theme.white;
  }
  if (hover) {
    return theme.primaryBlue;
  }
  return theme.darkGray;
};

const CircleButton = styled.button(
  ({ theme, active, hover, isLast }) => css`
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid ${active || hover ? theme.primaryBlue : theme.darkGray};
    background-color: ${getBackgroundColor(theme, active, hover)};
    color: ${getColor(theme, active, hover)};
    margin-right: ${isLast ? '0' : '1rem'};
  `,
);

let idCounter = 1;

const NumericRating = ({ maxValue, onChange, defaultValue }) => {
  const [value, setValue] = React.useState(defaultValue);
  const [hoverLabel, setHoverLabel] = React.useState(null);
  const labels = [...Array(maxValue).keys()].map(i => i + 1);
  const id = useMemo(() => `rating-${idCounter++}`, []);
  const handleClick = value => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div>
      {labels.map((label, index) => {
        return (
          <span
            key={label}
            onMouseLeave={() => setHoverLabel(null)}
            onMouseEnter={() => setHoverLabel(label)}
          >
            <CircleButton
              type="button"
              id={`${id}-${label}`}
              onClick={() => handleClick(label)}
              active={label <= value}
              hover={label <= hoverLabel}
              isLast={index === labels.length - 1}
            >
              {label}
            </CircleButton>
          </span>
        );
      })}
    </div>
  );
};

NumericRating.propTypes = {
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.number,
};

export default NumericRating;
