import ReactiveRecord, { Model } from 'reactiverecord';

class CancellationReason extends Model {
  static routes = {
    only: 'index',
  };
  static schema = {
    label: String,
    user_group: String,
  };
}

export default ReactiveRecord.model('CancellationReason', CancellationReason);
