import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { ContentWrapper } from './styles';
import InterviewSchedulingPages from './InterviewSchedulingPages';
import InterviewSchedulingPageForm from './RequestInterview/InterviewSchedulingPageForm';
import InterviewSchedulingPageSuccess from './RequestInterview/InterviewSchedulingPageSuccess';
import ScreenSizeContext from 'components/shared/ScreenSizeContext';
import stored from 'components/hoc/stored';
import Breadcrumbs from 'components/ui/Breadcrumbs';
import Dialog from 'components/ui/Dialog';
import Flex from 'components/ui/Flex';
import { INTERVIEW_SCHEDULING_PAGE_FORM_TYPES, URLS } from 'consts';

const EDIT = INTERVIEW_SCHEDULING_PAGE_FORM_TYPES.edit;

const EditCreateSchedulingPage = ({ formType, schedulingPageId }) => {
  const { isSmallScreen } = useContext(ScreenSizeContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [savedAsNew, setSavedAsNew] = useState(false);

  const handleSuccess = saveAsNew => {
    setSavedAsNew(saveAsNew);
    setShowSuccess(true);
  };

  const goToPages = () => window.location.assign(URLS.schedulingPages);
  const getSchedulingPageForm = interviewSchedulingPage => {
    return (
      <InterviewSchedulingPageForm
        formType={formType}
        interviewSchedulingPage={interviewSchedulingPage}
        handleSuccess={handleSuccess}
        handleCancel={goToPages}
        handleOptOut={goToPages}
        errorButtonText="Go to scheduling pages"
        smallWidth
      />
    );
  };

  return (
    <ContentWrapper
      alignContent="stretch"
      direction="column"
      gap={24}
      isSmallScreen={isSmallScreen}
    >
      <Flex className="w-100 pt-4" direction="row" justifyContent="flex-start">
        <Breadcrumbs
          history={[
            { name: 'Interviews', path: URLS.employerAppointments },
            { name: 'My interview hours', path: URLS.schedulingPages },
            { name: 'Set up my interview hours', path: '' },
          ]}
        />
      </Flex>
      {formType === EDIT ? (
        <InterviewSchedulingPages
          buttonText="Go to scheduling pages"
          handleOptOut={() => window.location.assign(URLS.schedulingPages)}
          schedulingPageId={schedulingPageId}
        >
          {schedulingPages => {
            const interviewSchedulingPage = schedulingPages[0];
            return getSchedulingPageForm(interviewSchedulingPage);
          }}
        </InterviewSchedulingPages>
      ) : (
        <Flex className="w-100" direction="column" alignItems="stretch">
          {getSchedulingPageForm()}
        </Flex>
      )}

      <Dialog
        autoFocusBehavior={false}
        show={showSuccess}
        onHide={() => {
          setShowSuccess(false);
          window.location.assign(URLS.schedulingPages);
        }}
        large
      >
        <InterviewSchedulingPageSuccess
          buttonText="Got it"
          isEditing={!savedAsNew}
          handleContinue={() => window.location.assign(URLS.schedulingPages)}
        />
      </Dialog>
    </ContentWrapper>
  );
};

EditCreateSchedulingPage.propTypes = {
  formType: PropTypes.oneOf([
    INTERVIEW_SCHEDULING_PAGE_FORM_TYPES.create,
    INTERVIEW_SCHEDULING_PAGE_FORM_TYPES.edit,
  ]).isRequired,
  schedulingPageId: PropTypes.string,
};

export default stored(EditCreateSchedulingPage);
