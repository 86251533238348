import { FREE_EMAILS_ARRAY } from 'consts';

export default function applyCustomValidators(validatorInstance) {
  /**
   * EXAMPLE Async Validator
   *
   * Validator.validators.async.date_not_conflicting = async function(
   *   value,
   *   options,
   *   form,
   *   attribute,
   *   callback
   * ) {
   *   const isValid = await yourApiRequest(value)
   *   callback(isValid ? null : options.message)
   * }
   *
   */

  const EMAIL_MATCHER = /^[\w.-]+@([\w-]+\.)+[a-zA-Z]{2,}$/;
  validatorInstance.validators.sync.email = (value, options) => {
    return !!value.match(EMAIL_MATCHER) ? null : options.message;
  };

  validatorInstance.validators.sync.company_email = (value, options) => {
    return !FREE_EMAILS_ARRAY.some(domain => value.endsWith(domain))
      ? null
      : options.message;
  };

  validatorInstance.validators.sync.array_inclusion = function (
    value,
    options,
  ) {
    for (let i = 0; i < value.length; i++) {
      if (options.in.indexOf(value[i]) < 0) {
        return options.message;
      }
    }
  };

  const dateIsValid = value =>
    new Date(value).getTime() === new Date(value).getTime();

  validatorInstance.validators.sync.date = function (
    value,
    options,
    form,
    // eslint-disable-next-line no-unused-vars
    attribute,
  ) {
    if (options.before_or_equal_to) {
      const otherField = form.fields[options.before_or_equal_to];
      if (!otherField) {
        return;
      }
      const otherValue = otherField.value;
      if (!dateIsValid(value) || !dateIsValid(otherValue)) {
        return;
      }
      if (new Date(value) > new Date(otherValue)) {
        return options.message;
      }
      return;
    }
    if (!dateIsValid(value)) {
      return options.message;
    }
  };
}
