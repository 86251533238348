import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import styled from 'styled-components';
import CloseIcon from '../../../../assets/svg/menu-purple-x.svg';

const StyledClearFilters = styled.div`
  font-weight: ${props => props.theme.fontWeights.fontBold};
  color: ${props => props.theme.primaryBlue};
  white-space: nowrap;
  cursor: pointer;
  .close-x {
    width: 2rem;
    height: 2rem;
    background-image: url(${CloseIcon});
    background-repeat: no-repeat;
    background-position: center top;
    @media (min-width: ${props => props.theme.screenSizes.tablet}) {
      display: none !important;
    }
  }
`;

function ClearFilters({ onHide, onClear, filterCount, ...props }) {
  const filterText = `Clear Filters ${
    filterCount > 0 ? '(' + filterCount + ')' : ''
  }`;
  return (
    <StyledClearFilters className="text-right" {...props}>
      {onClear && (
        <ButtonLink
          id="clear-filters"
          data-testid="clear-filters"
          onClick={onClear}
        >
          {filterText}
        </ButtonLink>
      )}
      <button data-id="close-x" className="close-x btn ml-2" onClick={onHide} />
    </StyledClearFilters>
  );
}

ClearFilters.propTypes = {
  onHide: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  filterCount: PropTypes.number,
};

export default ClearFilters;
