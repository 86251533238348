import React from 'react';
import PropTypes from 'prop-types';
import QuestionMark from 'components/ui/icons/QuestionMark';
import withTooltip from 'components/ui/withTooltip';

const LinkWithTooltip = withTooltip('a');

const IconLinkTooltip = ({ Icon, title, trigger, ...props }) => {
  return (
    <LinkWithTooltip
      trigger={trigger}
      title={
        <ul
          css={`
            min-width: 270px;
            margin: 0;
          `}
        >
          {title}
        </ul>
      }
      href="#"
      css={`
        display: block;
        margin-bottom: 7px;
        vertical-align: middle;
        line-height: 1;
      `}
      {...props}
    >
      <Icon width="20" />
    </LinkWithTooltip>
  );
};

IconLinkTooltip.propTypes = {
  title: PropTypes.any,
  trigger: PropTypes.string,
  Icon: PropTypes.elementType,
};

IconLinkTooltip.defaultProps = {
  Icon: QuestionMark,
  trigger: 'CLICK',
};

export default IconLinkTooltip;
