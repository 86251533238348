import React from 'react';
import PropTypes from 'prop-types';
import SkillItem from './SkillItem';
import { capitalize } from 'utilities';

const BulletItems = props => {
  let bulletItems = props.items.map(value => (
    <SkillItem name={capitalize(value)} key={value} theme={props.theme} />
  ));
  return bulletItems;
};

BulletItems.propTypes = {
  items: PropTypes.array.isRequired,
  theme: PropTypes.oneOf(['defalt', 'blue']),
};

BulletItems.defaultProps = {
  theme: 'default',
};

export default BulletItems;
