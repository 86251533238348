import React from 'react';
import {
  Description,
  Header,
  Hero,
  Label,
  SparkleBox,
  SubTitle,
  Press,
  Contact,
  DownloadSection,
} from './styles';
import Layout from 'components/containers/shared/Layout';
import { Col } from 'react-bootstrap';
import { allison } from '../../../../assets/images';
import { NewYorkTimes } from 'components/ui/icons/Logos/NewYorkTimes';
import { Inc } from 'components/ui/icons/Logos/Inc';
import { TechCrunch } from 'components/ui/icons/Logos/TechCrunch';
import { Bloomberg } from 'components/ui/icons/Logos/Bloomberg';
import { Glamour } from 'components/ui/icons/Logos/Glamour';
import { CBSNews } from 'components/ui/icons/Logos/CBSNews';
import File from 'components/ui/icons/File';
import { MARKETING_LINKS } from 'consts';

const MediaKit = () => {
  return (
    <Layout
      title="Media Kit"
      subtitle="The Mom Project specializes in helping companies fill talent needs for direct hiring, return-to-work programs, diversity initiatives and more.
    "
    >
      <Header>
        <h1>Media Kit</h1>
        <DownloadSection>
          <a className="font-heavy" href={MARKETING_LINKS.mediaKit}>
            <File width="45" height="60" /> Download our logos
          </a>
        </DownloadSection>
        <Hero />
        <SparkleBox className="hero-box">
          <h3>Our Mission</h3>
          <p>
            Our Mission at The Mom Project is dedicated to building a better
            workplace for women, parents and the businesses they support.
          </p>
        </SparkleBox>
      </Header>
      <section>
        <Description>
          <Col xs={12} sm={3} className="p-0">
            <SubTitle>Overview</SubTitle>
            <hr />
            <Label>Employees</Label>
            <div>290 full-time employees in a fully-remote workforce</div>
            <hr />
            <Label>Total Funding</Label>
            <div>$116M</div>
            <div className="mt-3">$2.6M Seed Round, May 2018</div>
            <div className="font-italic">
              Led by Atlanta Seed Company, OCA Ventures, BBG Ventures, Wintrust
              Ventures, IrishAngels, and Engage VC
            </div>
            <div className="mt-3">$8M Series A Round, Dec 2018</div>
            <div className="font-italic">
              Led by Grotech Ventures, Initialized Capital, and Aspect Ventures
            </div>
            <div className="mt-3">$25M Series B Round, July 2020</div>
            <div className="font-italic">Led by 7GC</div>
            <div className="mt-3">$80M Series C Round, Oct 2021</div>
            <div className="font-italic">
              Led by Leeds Illuminate, 7GC, Initialized Capital, OCA Ventures,
              Citi, High Alpha, and Grotech Ventures alongside Silicon Valley
              Bank
            </div>
            <div className="headshot-container">
              <img src={allison} alt="" />
              <div className="img-footer">
                <Label>Allison Robinson</Label>
                <div>Founder and CEO</div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={8} className="p-0">
            <SubTitle>About us</SubTitle>
            <p>
              The Mom Project is rewriting the working mom narrative. We are a
              digital talent marketplace and community connecting professionally
              accomplished women, moms and parents with world-class companies.
            </p>
            <SubTitle>Our story</SubTitle>
            <p>
              After having her first child in 2015, founder Allison Robinson
              identified how universally challenging it can be for American
              mothers to juggle the demands of motherhood with a full-time
              career. She knew there had to be a better way to help women stay
              engaged in the workforce on their own terms. Since launching in
              2016, The Mom Project has grown its talent community to over 650K
              women and has supported over 3,000 companies across the United
              States, including Amazon, Etsy, Meta, Best Buy, Logitech, and
              more.
            </p>
            <SubTitle>What we do</SubTitle>
            <p>
              Through our digital talent platform, we connect women with the
              flexible job opportunities they need to thrive and provide
              companies with the experienced talent they are looking to hire.
            </p>
            <Label>
              We help companies fulfill talent needs across the following areas:
            </Label>
            <ul>
              <li>Return-to-work </li>
              <li>Maternityship</li>
              <li>Freelance & contingent</li>
              <li>Direct hiring</li>
              <li>Diversity Initiatives</li>
              <li>Returnships</li>
            </ul>
          </Col>
        </Description>
      </section>
      <Press>
        <SubTitle>Press</SubTitle>
        <div className="logos-container">
          <NewYorkTimes />
          <Inc />
          <TechCrunch />
          <Bloomberg />
          <Glamour />
          <CBSNews />
        </div>
      </Press>
      <Contact>
        <SubTitle>Media contact</SubTitle>
        <SparkleBox className="media-box">
          <div>For media inquiries contact</div>
          <div className="font-heavy text-uppercase text-primary-blue">
            press@themomproject.com
          </div>
        </SparkleBox>
      </Contact>
    </Layout>
  );
};

export default MediaKit;
