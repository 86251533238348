import React from 'react';
import PropTypes from 'prop-types';
import Location from './Location';
import EditUsers from 'containers/employer_application/job_posts/EditUsers';
import { getFormattedDate } from 'utilities';
import { SHORT_FORMAT_DATE_OPTIONS } from 'consts';
function PreviousPosition({ project }) {
  const {
    city_state_list,
    created_at,
    human_readable_status,
    posted_by,
    project_title,
    remote_percentage,
    status,
  } = project.attributes;
  const { view_project_link, duplicate_project_link } = project.links;
  const createdAt = getFormattedDate(
    new Date(created_at),
    SHORT_FORMAT_DATE_OPTIONS,
  );
  return (
    <div className="project">
      <div className="box box-md box-shadow-sm">
        <div className="row">
          <div className="basic-info col-lg-6">
            <div className="title font-md">{project_title}</div>
          </div>
          <div className="action-buttons d-block d-sm-flex text-left col-lg-6 mt-2 mt-md-0">
            <a
              className="view-link d-block text-nowrap"
              href={view_project_link}
            >
              <i className="fa fa-eye" />
              &nbsp; View Job
            </a>
          </div>
        </div>
        <div className="secondary-info row justify-content-between">
          <div className="col-md-2 text-left pl-0 pb-3 pb-lg-0">
            <span className="font-heavy d-block mb-1">Created</span>
            {createdAt}
          </div>
          <div className="col-md-2 text-left pl-0 pb-3 pb-lg-0">
            <span className="font-heavy d-block mb-1">Posted by</span>
            {posted_by}
          </div>
          <div className="col-md-2 text-left pl-0 pb-3 pb-lg-0">
            <span className="font-heavy d-block mb-1">Status</span>
            <span>
              {status === 'opt_out' ? 'Role closed' : human_readable_status}
            </span>
          </div>
          <Location
            cityStateList={city_state_list}
            remotePercentage={remote_percentage}
            containerClassnames="col-md-2"
          />
          <div className="edit-container col-md-2 pr-0">
            <a
              href={duplicate_project_link}
              className="tmp-btn lg btn-edit-job"
            >
              <i className="fa fa-copy" />
              &nbsp; Copy job
            </a>
          </div>
        </div>
        <div className="text-left mt-3">
          <EditUsers disabled={true} project={project.attributes} />
        </div>
      </div>
    </div>
  );
}

PreviousPosition.propTypes = {
  project: PropTypes.object.isRequired,
};

export default PreviousPosition;
