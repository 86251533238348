import ReactiveRecord, { reducer, middleware } from 'reactiverecord';
import { createStore, compose, applyMiddleware } from 'redux';
import { getCsrfToken } from 'utilities/http';
import 'utilities/validated-models.js.erb';

const storeEnhancers = [applyMiddleware(middleware.call(ReactiveRecord))];

if (
  process.env.NODE_ENV !== 'production' &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  storeEnhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
  reducer.call(ReactiveRecord),
  compose.apply(this, storeEnhancers),
);

ReactiveRecord.store = store;
ReactiveRecord.dispatch = store.dispatch;
const prefix =
  process.env.NODE_ENV === 'test' ? 'https://mockserver.com/api/v1' : '/api/v1';

ReactiveRecord.setAPI({
  prefix: prefix,
  headers: {
    'X-CSRF-Token': getCsrfToken(),
  },
});

export default store;
