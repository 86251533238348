import React, { useEffect, useState } from 'react';
import { useMediaSet } from 'use-media-set';

import { ContentWrapper } from './styles';
import { hasActiveInterviews, deletePage } from './supporting';
import EmptyInterviewSchedulingPages from './EmptyInterviewSchedulingPages';
import InterviewSchedulingPageCard from './SchedulingPage/InterviewSchedulingPageCard';
import InterviewSchedulingPages from 'components/employer/interviews/InterviewSchedulingPages';
import JustMakingSureSchedulingPage from './SchedulingPage/JustMakingSureSchedulingPage';
import ViewInterviewSchedulingPage from 'components/employer/interviews/RequestInterview/ViewInterviewSchedulingPage';
import Button from 'components/ui/buttons/Button';
import Breadcrumbs from 'components/ui/Breadcrumbs';
import Dialog from 'components/ui/Dialog';
import Layout from 'components/containers/shared/Layout';
import PlusCircleO from 'components/ui/icons/PlusCircleO';
import { SCHEDULING_PAGE_ACTION_TYPE, URLS } from 'consts';
import { mediaSetQueries } from 'styleGuide';

const ViewInterviewSchedulingPages = () => {
  const [modalType, setModalType] = useState(null);
  const [currentAction, setCurrentAction] = useState();
  const [deletionError, setDeletionError] = useState(false);
  const [selectedSchedulingPage, setSelectedSchedulingPage] = useState();
  const [submitting, setSubmitting] = useState(false);

  const mediaStates = useMediaSet(mediaSetQueries);
  const isSmallScreen = mediaStates.has('mobileOrSmTablet');
  const { EDIT, DELETE, VIEW } = SCHEDULING_PAGE_ACTION_TYPE;

  const clearState = () => {
    setCurrentAction(null);
    setSelectedSchedulingPage(null);
    setDeletionError(false);
    setModalType(null);
  };

  useEffect(() => {
    if (currentAction) {
      switch (currentAction) {
        case EDIT:
          if (!hasActiveInterviews(selectedSchedulingPage)) {
            clearState();
            window.location.assign(
              URLS.schedulingPageEdit(selectedSchedulingPage.id),
            );
          } else {
            setModalType('confirm');
          }
          break;
        case VIEW:
          setModalType('view');
          break;
        case DELETE:
          setModalType('confirm');
          break;
      }
    }
  }, [currentAction, selectedSchedulingPage, EDIT, DELETE, VIEW]);

  const handleAction = (type, page) => {
    setSelectedSchedulingPage(page);
    setCurrentAction(type);
  };

  const editOrDeletePage = async () => {
    if (currentAction === EDIT) {
      window.location.assign(
        URLS.schedulingPageEdit(selectedSchedulingPage.id),
      );
    } else {
      await deletePage(
        selectedSchedulingPage.id,
        setSubmitting,
        setDeletionError,
        () => window.location.reload(),
      );
    }
  };

  const mobileOnlyStyling = isSmallScreen
    ? ` > div { padding-top: 2rem !important; } `
    : '';

  return (
    <Layout title="My interview hours">
      <ContentWrapper
        alignContent="stretch"
        direction="column"
        gap={16}
        isSmallScreen={isSmallScreen}
      >
        <div className="pt-4 w-100">
          <Breadcrumbs
            history={[
              { name: 'Interviews', path: URLS.employerAppointments },
              { name: 'My interview hours', path: '' },
            ]}
          />
        </div>
        <div className="w-100">
          <h1>My interview hours</h1>
        </div>
        <InterviewSchedulingPages
          buttonText="Go to Interviews"
          handleOptOut={() => window.location.assign(URLS.employerAppointments)}
        >
          {schedulingPages => {
            return (
              <>
                {schedulingPages.length === 0 && (
                  <EmptyInterviewSchedulingPages
                    addNewSchedulingPage={() =>
                      window.location.assign(URLS.schedulingPageNew)
                    }
                  />
                )}
                {schedulingPages.length > 0 && (
                  <>
                    <div className="w-100 mb-4 d-flex flex-row justify-content-start justify-content-md-end">
                      <Button
                        data-id="new-scheduling-page-button"
                        inline
                        link
                        onClick={() =>
                          window.location.assign(URLS.schedulingPageNew)
                        }
                      >
                        <PlusCircleO size={20} />
                        <span className="ml-1 font-heavy">
                          Add new template
                        </span>
                      </Button>
                    </div>
                    {schedulingPages.map(page => (
                      <InterviewSchedulingPageCard
                        key={`scheduling-page-${page.id}`}
                        onAction={handleAction}
                        schedulingPage={page}
                      />
                    ))}
                  </>
                )}
                {selectedSchedulingPage && (
                  <>
                    <Dialog
                      autoFocusBehavior={false}
                      css={mobileOnlyStyling}
                      large
                      onHide={clearState}
                      show={modalType !== null}
                    >
                      {modalType === 'view' && (
                        <ViewInterviewSchedulingPage
                          schedulingPage={selectedSchedulingPage}
                          buttonText="Go to scheduling pages"
                          handleContinue={clearState}
                          handleEdit={() => {
                            handleAction(EDIT, selectedSchedulingPage);
                          }}
                        />
                      )}
                      {modalType === 'confirm' ? (
                        <JustMakingSureSchedulingPage
                          buttonText={currentAction === DELETE && 'Delete'}
                          cancelButtonText={
                            currentAction === DELETE && 'Cancel'
                          }
                          content={
                            currentAction === DELETE &&
                            'This will be removed permanently.'
                          }
                          schedulingPage={selectedSchedulingPage}
                          handleContinue={editOrDeletePage}
                          handleCancel={clearState}
                          showError={deletionError}
                          submitting={submitting}
                        />
                      ) : null}
                    </Dialog>
                  </>
                )}
              </>
            );
          }}
        </InterviewSchedulingPages>
      </ContentWrapper>
    </Layout>
  );
};

export default ViewInterviewSchedulingPages;
