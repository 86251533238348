import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import ShowToggle from 'components/ui/ShowToggle';
import withTooltip from 'components/ui/withTooltip';
import Button from 'components/ui/buttons/Button';
import Eye from 'components/ui/icons/Eye';
import HideEye from 'components/ui/icons/HideEye';

const TooltipButton = withTooltip(Button);

const InputPassword = props => {
  const generatedId = useMemo(
    () => `input-password-${InputPassword.idCounter++}`,
    [],
  );
  const id = props.id || generatedId;
  return (
    <ShowToggle>
      {(show, toggle) => (
        <Input
          {...props}
          type={show ? 'text' : 'password'}
          button={
            <EyeToggle id={`eye-toggle-${id}`} onToggle={toggle} show={show} />
          }
        />
      )}
    </ShowToggle>
  );
};

const EyeToggle = ({ id, onToggle, show }) => (
  <TooltipButton
    id={id}
    onClick={onToggle}
    type="button"
    title={show ? 'Hide Password' : 'Show Password'}
    css={`
      min-width: unset;
      padding: 0.81rem 1rem;
    `}
  >
    {show ? <HideEye width={24} height={14} /> : <Eye width={24} height={14} />}
  </TooltipButton>
);

EyeToggle.propTypes = {
  id: PropTypes.any.isRequired,
  onToggle: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

InputPassword.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string.isRequired,
};

InputPassword.idCounter = 0;

export default InputPassword;
