import React from 'react';
import PropTypes from 'prop-types';

const Star = ({ color, height, width, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 22"
      height={height}
      width={width}
      {...props}
    >
      <defs>
        <style></style>
      </defs>
      <path
        id="Path_2292"
        d="M265.45 197.463l-7.455-1.042a.481.481 0 0 1-.363-.254l-3.335-6.5a.491.491 0 0 0-.865 0l-3.335 6.5a.483.483 0 0 1-.363.254l-7.456 1.042a.46.46 0 0 0-.268.792l5.4 5.058a.454.454 0 0 1 .138.411l-1.273 7.142a.479.479 0 0 0 .7.49l6.668-3.372a.5.5 0 0 1 .45 0l6.668 3.372a.479.479 0 0 0 .7-.49l-1.274-7.142a.452.452 0 0 1 .139-.411l5.395-5.058a.46.46 0 0 0-.271-.792z"
        fill={color}
        data-name="Path 2292"
        transform="translate(-241.864 -189.41)"
      />
    </svg>
  );
};

Star.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Star.defaultProps = {
  color: 'currentColor',
  height: 22,
  width: 24,
};

export default Star;
