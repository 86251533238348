import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import VideoPreview from 'resources/video_calls/VideoPreview';
import VideoRoom from 'resources/video_calls/VideoRoom';
import VideoCallExited from './VideoCallExited';
import { VideoContext } from './VideoProvider';

const VideoApp = ({ resource, resourceType, videoCall }) => {
  let { name } = useParams();
  const { roomState, hasExitedRoom } = useContext(VideoContext);
  const resourceName = resource.full_name || resource.fullName;
  const isExitedRoomEnabled =
    resourceType === 'Mom' || resourceType === 'Employer';

  if (isExitedRoomEnabled && roomState === 'disconnected' && hasExitedRoom) {
    return (
      <VideoCallExited
        identity={resourceName}
        resourceType={resourceType}
        roomName={name}
        videoCall={videoCall}
      />
    );
  }

  if (roomState === 'disconnected') {
    return (
      <VideoPreview
        identity={resourceName}
        roomName={name}
        videoCall={videoCall}
      />
    );
  }

  return <VideoRoom />;
};

VideoApp.propTypes = {
  resource: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
  videoCall: PropTypes.object,
};

export default VideoApp;
