import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/ui/grid';
import moment from 'moment';
import {
  getAppointmentsFromRange,
  mergeTimeslots,
  useAvailableHours,
} from './supporting';
import SyntheticMultiSelectInput from 'components/ui/inputs/SyntheticMultiSelectInput';
import InterviewTimeSlots from './InterviewTimeSlots';
import { useHoursOptions } from './hooks';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import DateInput from 'components/ui/inputs/DateInput';
import Select from 'components/ui/inputs/Select';
import Button from 'components/ui/buttons/Button';

const TimeAndDateSection = ({ fields }) => {
  const [showErrorMessage, setShowErrorMessage] = useState();
  const [appointmentDate, setAppointmentDate] = useState(null);
  const availableTimes = useAvailableHours(appointmentDate);
  const handleDateChange = date => {
    setAppointmentDate(moment(date).format('YYYY-MM-DD'));
  };
  const [startTime, endTime, toHoursOptions, setStartTime, setEndTime] =
    useHoursOptions();

  return (
    <SyntheticMultiSelectInput {...fields.bid_schedule_date}>
      {({ value, onChange }, errorText) => {
        // Extract available dates. NB: dates in "value" are already in appointment.timezone timezone
        const availableDates = [...new Set(value.map(d => d.substring(0, 10)))];

        const handleAddClick = () => {
          if (startTime && endTime && appointmentDate) {
            let timeSlots = getAppointmentsFromRange(
              startTime,
              endTime,
              appointmentDate,
            );
            timeSlots = mergeTimeslots(timeSlots, value, appointmentDate);
            onChange(timeSlots);
            setShowErrorMessage(false);
          } else {
            setShowErrorMessage(true);
          }
        };
        return (
          <>
            <Row>
              <Col col={12} md={3}>
                <DateInput
                  labelText="Date"
                  minDate={new Date()}
                  value={appointmentDate}
                  onChange={handleDateChange}
                />
              </Col>
              <Col col={5} md={3}>
                <Select
                  data-testid="from-time-select"
                  labelText="Available times"
                  onChange={e => setStartTime(e.target.value)}
                  value={startTime}
                >
                  <option>Select</option>
                  {availableTimes}
                </Select>
              </Col>
              <Col
                col={1}
                className="align-items-center col-1 d-flex justify-content-center p-0"
              >
                <span
                  css={`
                    font-size: ${({ theme }) => theme.fontSizes.sm};
                    color: ${({ theme }) => theme.mediumGray};
                    text-transform: uppercase;
                  `}
                >
                  To
                </span>
              </Col>
              <Col className="interview-modal__end-date" col={5} md={3}>
                <Select
                  data-testid="to-time-select"
                  labelText="To"
                  value={endTime}
                  onChange={e => setEndTime(e.target.value)}
                >
                  <option>Select</option>
                  {toHoursOptions}
                </Select>
              </Col>
              <Col
                col={12}
                md={2}
                className="d-flex justify-content-end flex-column"
              >
                <Button
                  data-analyticsid="add-time"
                  className="w-100"
                  type="button"
                  css={`
                    min-width: unset;
                    margin-bottom: 0.5rem;
                  `}
                  onClick={handleAddClick}
                >
                  Add
                </Button>
              </Col>
              <Col col={12}>
                {showErrorMessage ? (
                  <ErrorMessage>
                    Please select a date along with a start and end time range.
                  </ErrorMessage>
                ) : null}
              </Col>
            </Row>

            {availableDates.map((fullDate, index) => {
              const date = fullDate.substring(0, 10);
              const dateTimes = value.filter(
                value => value.substring(0, 10) === date,
              );
              return dateTimes.length > 0 ? (
                <InterviewTimeSlots
                  date={date}
                  dateTimes={dateTimes}
                  key={index}
                  onChange={onChange}
                  value={value}
                />
              ) : null;
            })}
            {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
          </>
        );
      }}
    </SyntheticMultiSelectInput>
  );
};

TimeAndDateSection.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default TimeAndDateSection;
