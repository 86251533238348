import { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PreventableEvent } from 'utilities';

export function syntheticInputGenerator(setValueCallback) {
  // Disabling eslint rule since this component is ReactiveRecord and we will not invest into it further.
  // eslint-disable-next-line react/display-name
  return forwardRef((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { children, value, defaultValue, onChange, onBlur, disabled } = props;
    const [stateValue, setStateValue] = useState(defaultValue);
    const usingStateValue = typeof value === 'undefined';
    const usedValue = usingStateValue ? stateValue : value;
    const refInterface = useRef({ value: usedValue });
    refInterface.current.value = usedValue;
    useEffect(() => {
      if (ref) {
        ref({
          get value() {
            return refInterface.current.value;
          },
        });
      }
      return () => {
        if (ref) {
          ref(null);
        }
      };
    }, [ref]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setValue = useCallback(
      setValueCallback(
        setStateValue,
        disabled,
        onBlur,
        onChange,
        usingStateValue,
      ),
      [disabled, onBlur, onChange, usingStateValue],
    );
    return children(usedValue, setValue);
  });
}

const SyntheticInput = syntheticInputGenerator(
  (setStateValue, disabled, onBlur, onChange, usingStateValue) => {
    return newValue => {
      if (disabled) {
        return;
      }
      if (usingStateValue) {
        setStateValue(newValue);
      }
      if (onChange) {
        setTimeout(() => {
          onChange(new PreventableEvent(newValue));
        }, 0);
      }
    };
  },
);
SyntheticInput.propTypes = {
  children: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
};
SyntheticInput.displayName = 'SyntheticInput';

export default SyntheticInput;
