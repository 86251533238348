import React, {
  useRef,
  useCallback,
  useMemo,
  forwardRef,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { validated } from 'reactiverecord';
import classNames from 'classnames';
import ErrorMessage from '../../labels/ErrorMessage';
import Portal, { PortalContext } from 'components/ui/Portal';
import {
  denormalize,
  generateAdd,
  generateHandleBlur,
  generateHandleChange,
  generateHandleFocus,
  generateHandleKeyDown,
  generateHandleLabelClick,
  generateHandleLabelKeyDown,
  generateRemove,
  generateStoreInput,
  Input,
  Label,
  LabelContainer,
  List,
  ListItem,
  normalize,
  RemoveLabelHandle,
  Wrapper,
} from './supporting';
import X from '../../icons/X';
import MagnifyingGlass from '../../icons/MagnifyingGlass';

const MultiSelect = forwardRef(
  (
    {
      children,
      defaultValue,
      errorText,
      keysAccessor,
      labelText,
      max,
      onBlur,
      onChange,
      onInputChange,
      onClick,
      onFocus,
      onKeyDown,
      validating,
      value,
      valueAccessor,
      ...props
    },
    forwardedRef,
  ) => {
    const portalContext = useContext(PortalContext);
    /* Automatically generate ID */
    const generatedId = useMemo(
      () => `multi-select-${MultiSelect.idCounter++}`,
      [],
    );
    const [state, setState] = useState({
      stateValue: normalize(value || defaultValue || []),
      searchTerm: null,
      multiSelectHasFocus: false,
      inputHasFocus: false,
      listStyle: {},
      portalStyle: {},
      ariaActiveDescendant: null,
    });

    const usingStateValue = typeof value === 'undefined';
    const realValue = usingStateValue ? state.stateValue : normalize(value);
    const id = props.id || generatedId;
    const inputRef = useRef(null);
    const listRef = useRef(null);
    const wrapperRef = useRef(null);
    const labelContainerRef = useRef(null);
    const mergeState = useCallback(
      nextState => setState({ ...state, ...nextState }),
      [state],
    );
    // TODO: Fix these warnings
    /* Function to add result to state value */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const add = useCallback(
      generateAdd(usingStateValue, realValue, mergeState, onChange, inputRef),
      [usingStateValue, realValue, mergeState, onChange],
    );
    /* Function to remove result from state value */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const remove = useCallback(
      generateRemove(usingStateValue, realValue, mergeState, onChange),
      [usingStateValue, realValue, mergeState, onChange],
    );
    /* Function to manage ref and provide interface for form */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const storeInput = useCallback(
      generateStoreInput(realValue, forwardedRef, inputRef),
      [realValue, forwardedRef],
    );
    /* onFocus for input */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleFocus = useCallback(
      generateHandleFocus(
        onFocus,
        state,
        inputRef,
        setState,
        portalContext.isPortalDescendant,
      ),
      [onFocus, state],
    );
    /* onBlur for input and labels */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleBlur = useCallback(
      generateHandleBlur(onBlur, state.inputHasFocus, mergeState, wrapperRef),
      [onBlur, state.inputHasFocus, mergeState],
    );
    /* onKeyDown for labels */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLabelKeyDown = useCallback(
      generateHandleLabelKeyDown(onKeyDown, remove),
      [onKeyDown, remove],
    );
    /* onClick for remove label buttons */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLabelClick = useCallback(
      generateHandleLabelClick(onClick, remove),
      [onClick, remove],
    );

    const renderedValue = denormalize(realValue);
    function listFilter(key) {
      if (state.stateValue[key]) {
        return false;
      }
      if (state.searchTerm) {
        return (
          valueAccessor(children, key)
            .toLowerCase()
            .indexOf(state.searchTerm.toLowerCase()) > -1
        );
      }
      return true;
    }
    /* onMouseDown for options */
    const handleMouseDown = useCallback(id => () => add(id), [add]);

    /* Options list */
    const ListWrapper = portalContext.isPortalDescendant ? 'div' : Portal;
    let optionMenu = null;
    let listId = `${id}-list`;
    const renderedOptions = [];
    const optionValueMapping = {};
    if (state.inputHasFocus) {
      const options = keysAccessor(children).filter(listFilter);
      if (options.length) {
        optionMenu = (
          <ListWrapper style={state.portalStyle}>
            <List
              id={listId}
              data-testid={listId}
              style={state.listStyle}
              role="listbox"
              ref={listRef}
            >
              {options.map(key => {
                const listItemId = `${listId}-${key}`;
                renderedOptions.push(listItemId);
                optionValueMapping[listItemId] = key;
                return (
                  <ListItem
                    onMouseDown={handleMouseDown(key)}
                    id={listItemId}
                    aria-selected={state.ariaActiveDescendant === listItemId}
                    active={state.ariaActiveDescendant === listItemId}
                    key={key}
                  >
                    {valueAccessor(children, key)}
                  </ListItem>
                );
              })}
            </List>
          </ListWrapper>
        );
      }
    }
    /* onChange for input */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(
      generateHandleChange(onInputChange, children, state, setState, true),
      [state, children],
    );
    /* onKeyDown for input */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleKeyDown = useCallback(
      generateHandleKeyDown(
        onKeyDown,
        state,
        add,
        listRef,
        optionValueMapping,
        renderedOptions,
        setState,
        inputRef,
      ),
      [renderedOptions, onKeyDown, state, add, optionValueMapping],
    );

    const a11yInputProps = {};
    if (optionMenu) {
      a11yInputProps['aria-expanded'] = 'true';
      a11yInputProps['aria-owns'] = listId;
      a11yInputProps['aria-controls'] = listId;
      if (state.ariaActiveDescendant) {
        a11yInputProps['aria-activedescendant'] = state.ariaActiveDescendant;
      }
    }

    let reachedMax = false;
    if (max >= 0) {
      reachedMax = renderedValue.length >= max;
    }
    const isDisabled =
      (props.disabled || validating || reachedMax) && !state.inputHasFocus;
    const removeDisabled = props.disabled || validating;

    const handleWrapperClick = useCallback(
      event => {
        if (
          !isDisabled &&
          (event.target === wrapperRef.current ||
            event.target === labelContainerRef.current)
        ) {
          inputRef.current.focus();
        }
      },
      [isDisabled],
    );

    return (
      <>
        <label>{labelText}</label>
        <Wrapper
          ref={wrapperRef}
          isDisabled={isDisabled}
          onClick={handleWrapperClick}
        >
          <LabelContainer
            ref={labelContainerRef}
            data-testid="multiselect-label-container"
            className={classNames({ 'with-values': !!renderedValue.length })}
          >
            {renderedValue.length
              ? renderedValue
                  .map(valueItem => {
                    const propsForRemoveLabelHandle = {};
                    if (!removeDisabled) {
                      propsForRemoveLabelHandle.onKeyDown =
                        handleLabelKeyDown(valueItem);
                      propsForRemoveLabelHandle.onClick =
                        handleLabelClick(valueItem);
                      propsForRemoveLabelHandle.title = 'Remove';
                      if (state.multiSelectHasFocus) {
                        propsForRemoveLabelHandle.onBlur = handleBlur;
                        propsForRemoveLabelHandle.tabIndex = '0';
                      }
                    }
                    return (
                      <Label key={valueItem}>
                        {valueAccessor(children, valueItem)}
                        <RemoveLabelHandle {...propsForRemoveLabelHandle}>
                          <X />
                        </RemoveLabelHandle>
                      </Label>
                    );
                  })
                  .reduce((prev, next) => [prev, ' ', next])
              : null}
          </LabelContainer>
          <MagnifyingGlass
            className="magnifying-glass"
            width="20px"
            height="20px"
          />
          <Input
            id={id}
            disabled={isDisabled}
            ref={storeInput}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            autoComplete="off"
            role="combobox"
            aria-autocomplete="list"
            {...a11yInputProps}
            {...props}
          />
          {optionMenu}
        </Wrapper>
        {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
      </>
    );
  },
);

MultiSelect.propTypes = {
  children: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  defaultValue: PropTypes.array,
  disabled: PropTypes.bool,
  errorText: PropTypes.any,
  id: PropTypes.any,
  keysAccessor: PropTypes.func,
  labelText: PropTypes.any,
  /** Optionally set a max length to allow checked. Disables others once the max is reached */
  max: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  validating: PropTypes.bool,
  value: PropTypes.array,
  valueAccessor: PropTypes.func,
};
MultiSelect.defaultProps = {
  keysAccessor: object => Object.keys(object),
  placeholder: 'Search or select',
  valueAccessor: (object, key) => object[key],
};
MultiSelect.idCounter = 0;
MultiSelect.displayName = 'MultiSelect';

export default validated(MultiSelect);
