import React from 'react';
import theme from 'theme';

const EmployerSuccess = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="450"
      height="284"
      viewBox="0 0 450 284"
      {...props}
    >
      <defs>
        <path id="a" d="M.027.574h51.718v104.299H.027z" />
        <path id="c" d="M.544.574h51.719v104.299H.544z" />
        <path
          id="e"
          d="M.521 121.329c0 66.85 54.036 121.046 120.692 121.046 66.656 0 120.692-54.195 120.692-121.046C241.905 54.476 187.869.284 121.213.284 54.557.284.521 54.476.521 121.33z"
        />
        <path
          id="g"
          d="M.521 121.329c0 66.85 54.036 121.046 120.692 121.046 66.656 0 120.692-54.195 120.692-121.046C241.905 54.476 187.869.284 121.213.284 54.557.284.521 54.476.521 121.33z"
        />
        <path
          id="i"
          d="M.521 121.329c0 66.85 54.036 121.046 120.692 121.046 66.656 0 120.692-54.195 120.692-121.046C241.905 54.476 187.869.284 121.213.284 54.557.284.521 54.476.521 121.33z"
        />
        <path
          id="k"
          d="M.521 121.329c0 66.85 54.036 121.046 120.692 121.046 66.656 0 120.692-54.195 120.692-121.046C241.905 54.476 187.869.284 121.213.284 54.557.284.521 54.476.521 121.33z"
        />
        <path
          id="m"
          d="M.521 121.329c0 66.85 54.036 121.046 120.692 121.046 66.656 0 120.692-54.195 120.692-121.046C241.905 54.476 187.869.284 121.213.284 54.557.284.521 54.476.521 121.33z"
        />
        <path
          id="o"
          d="M.521 121.329c0 66.85 54.036 121.046 120.692 121.046 66.656 0 120.692-54.195 120.692-121.046C241.905 54.476 187.869.284 121.213.284 54.557.284.521 54.476.521 121.33"
        />
        <path id="q" d="M0 .415h33.51v38.68H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={theme.primaryBlue}
          d="M225.273 40.572c66.74 0 120.842 54.109 120.842 120.86 0 66.749-54.103 120.86-120.842 120.86-66.74 0-120.84-54.111-120.84-120.86 0-66.751 54.1-120.86 120.84-120.86"
        />
        <path
          fill={theme.darkGray}
          d="M226.591 64.075c-18.275 0-22.36 9.882-22.525 9.882-21.008 0-30.6 27.713-30.365 44.73.165 11.887 2.959 17.205 8.086 23.109 10.21 11.753-1.166 27.294-7.26 30.428l62.048-.902c6.988-.375 14.581-3.938 20.372-7.655 8.08-5.185 15.659-12.674 19.679-21.5 3.579-7.86 4.686-16.726 4.275-25.293-.618-12.847-4.586-26.293-12.636-36.487-7.508-9.512-18.406-14.109-30.162-15.626-3.816-.491-7.667-.686-11.512-.686"
        />
        <path
          fill={theme.palePink}
          d="M215.404 121.412c0 18.13 11.836 32.826 26.44 32.826 14.602 0 26.438-14.697 26.438-32.826s-12.01-36.376-26.611-36.376c-14.602 0-26.267 18.247-26.267 36.376"
        />
        <path
          fill={theme.palePink}
          d="M204.415 124.961a7.985 7.985 0 0 0 7.984 7.985 7.987 7.987 0 0 0 7.983-7.985c0-7.985-3.576-7.985-7.983-7.985a7.984 7.984 0 0 0-7.984 7.985"
        />
        <path
          fill={theme.darkGray}
          d="M227.995 101.11s-1.732 18.074-16.484 15.867l-10.644-17.745 12.418-19.518 30.943-6.561 28.386 21.291s-11.941 12.36-44.619 6.666"
        />
        <g transform="translate(101)">
          <mask id="b" fill={theme.white}>
            <use xlinkHref="#a" />
          </mask>
          <path
            fill={theme.palePink}
            d="M45.101 104.873L34.958 54.62a13.2 13.2 0 0 1 3.062-11.377c4.79-5.382 11.774-13.53 13.725-17.38 0 0-2.497-6.405-9.661 3.13l-2.632 3.347c-.939 1.192-2.834.797-3.217-.671a38.012 38.012 0 0 1-.956-14.266l1.504-12.237a2.04 2.04 0 1 0-3.992-.844l-3.48 18.798c-.268 1.033-1.762.947-1.903-.113l-2.4-20.427a2.317 2.317 0 0 0-4.607.477l1.986 20.323c.08 1.096-1.434 1.462-1.863.451L14.42 7.19a2.406 2.406 0 0 0-2.886-1.37 2.406 2.406 0 0 0-1.629 3l5.746 20.35c.266.88-.719 1.611-1.474 1.088-2-1.386-5.587-6.307-10.153-12.332a2.229 2.229 0 0 0-3.53 2.717l8.652 11.17a11.29 11.29 0 0 1 2.227 5.124c.602 3.705 2.059 10.358 5.184 14.59 1.033 1.4 1.805 2.977 2.05 4.7L25 100.857"
            mask="url(#b)"
          />
        </g>
        <path
          fill={theme.lightTeal}
          d="M225.354 165.6c-2.68.002-5.363.397-7.955 1.194l-11.154-.781c-7.79-5.159-14.614-3.372-51.245-38.816l-17.083-64.97h-18.04l8.816 74.58c1.68 5.89 6.594 12.068 11.091 16.771 10.292 10.775 20.469 21.66 30.229 32.919l5.26 6.067 7.364 76.186h42.717V165.6z"
        />
        <g transform="translate(297.255)">
          <mask id="d" fill={theme.white}>
            <use xlinkHref="#c" />
          </mask>
          <path
            fill={theme.palePink}
            d="M7.189 104.873L17.33 54.62a13.2 13.2 0 0 0-3.061-11.377C9.48 37.862 2.498 29.714.544 25.864c0 0 2.498-6.405 9.662 3.13l2.634 3.347c.936 1.192 2.832.797 3.214-.671a38.012 38.012 0 0 0 .957-14.266L15.506 5.167a2.04 2.04 0 1 1 3.992-.844l3.48 18.798c.268 1.033 1.763.947 1.906-.113l2.398-20.427A2.319 2.319 0 0 1 30.044.62a2.318 2.318 0 0 1 1.845 2.437l-1.986 20.323c-.079 1.096 1.434 1.462 1.863.451L37.868 7.19a2.406 2.406 0 0 1 2.886-1.37 2.406 2.406 0 0 1 1.63 3l-5.746 20.35c-.266.88.719 1.611 1.474 1.088 2-1.386 5.587-6.307 10.153-12.332a2.229 2.229 0 0 1 3.53 2.717l-8.652 11.17a11.29 11.29 0 0 0-2.228 5.124c-.602 3.705-2.058 10.358-5.184 14.59-1.033 1.4-1.804 2.977-2.05 4.7l-6.39 44.63"
            mask="url(#d)"
          />
        </g>
        <path
          fill={theme.lightTeal}
          d="M225.19 165.6c2.682.002 5.364.397 7.956 1.194l11.154-.781c7.79-5.159 14.614-3.372 51.245-38.816l17.083-64.97h18.04l-8.816 74.58c-1.68 5.89-6.594 12.068-11.091 16.771-10.292 10.775-20.469 21.66-30.229 32.919l-5.26 6.067-7.364 76.186h-42.717V165.6z"
        />
        <path
          fill={theme.white}
          d="M205.654 165.502c-1.732 4.788.317 9.578.317 9.578l19.395 44.913 19.395-44.913s3.061-6.688 1.327-9.496c0 0-11.222-1.98-19.969-2.062-8.747-.083-20.465 1.98-20.465 1.98"
        />
        <path
          fill={theme.palePink}
          d="M237.208 169.072l-11.842 29.586-11.84-29.586z"
        />
        <path
          fill={theme.palePink}
          d="M225.366 175.53c6.858 0 12.418-3.39 12.418-9.76V122.3h-24.837v43.47c0 6.37 5.561 9.76 12.419 9.76"
        />
        <path
          fill={theme.white}
          d="M207.964 134.275a3.99 3.99 0 1 0 7.983 0 3.991 3.991 0 0 0-3.994-3.99 3.989 3.989 0 0 0-3.99 3.99M256.054 135.19s-.858 7.149-9.613 7.755c-8.757.605-11.488-6.713-11.488-6.713l21.101-1.042z"
        />
        <g>
          <g transform="translate(104.62 40.643)">
            <mask id="f" fill={theme.white}>
              <use xlinkHref="#e" />
            </mask>
            <path
              fill={theme.lightOrange}
              d="M148.563 306.645L363.5 209.114l-197.17-5.247-299.985 28.762z"
              mask="url(#f)"
            />
          </g>
          <g transform="translate(104.62 40.643)">
            <mask id="h" fill={theme.white}>
              <use xlinkHref="#g" />
            </mask>
            <path
              fill={theme.lightBlue}
              d="M74.076 211.226l91.629-9.385 30.728 9.928-62.072 15.155z"
              mask="url(#h)"
            />
          </g>
          <g transform="translate(104.62 40.643)">
            <mask id="j" fill={theme.white}>
              <use xlinkHref="#i" />
            </mask>
            <path
              fill={theme.lightBlue}
              d="M133.282 231.796l11.655-75.92a5.32 5.32 0 0 1 4.98-4.514l86.663-4.496c2.328-.12 4.136 2.007 3.647 4.295l-14.107 66.02-92.838 14.615z"
              mask="url(#j)"
            />
          </g>
          <g transform="translate(104.62 40.643)">
            <mask id="l" fill={theme.white}>
              <use xlinkHref="#k" />
            </mask>
            <path
              fill={theme.primaryBlue}
              d="M133.282 231.796l1.45-8.118-60.657-12.451v6.494z"
              mask="url(#l)"
            />
          </g>
          <g transform="translate(104.62 40.643)">
            <mask id="n" fill={theme.white}>
              <use xlinkHref="#m" />
            </mask>
            <path
              fill={theme.primaryBlue}
              d="M105.215 213.12l31.416-2.95-1.301 8.865z"
              mask="url(#n)"
            />
          </g>
          <g transform="translate(104.62 40.643)">
            <mask id="p" fill={theme.white}>
              <use xlinkHref="#o" />
            </mask>
            <path
              fill={theme.primaryBlue}
              d="M195.4 187.514c-1.306 3.954-4.446 6.47-7.008 5.616-2.565-.853-3.585-4.749-2.275-8.703 1.307-3.954 4.445-6.47 7.008-5.616 2.565.851 3.582 4.749 2.275 8.703"
              mask="url(#p)"
            />
          </g>
        </g>
        <g>
          <path
            fill={theme.primaryBlue}
            d="M395.765 161.684c-3.032-9.64-8.447-15.917-16.78-19.357 8.308-3.479 13.76-9.713 16.76-19.324 3.006 9.507 8.4 15.807 16.75 19.299-8.244 3.51-13.73 9.703-16.73 19.382"
          />
          <path
            fill={theme.lightOrange}
            d="M429.55 158.84c4.893-2.168 8.094-5.771 9.957-11.506 1.911 5.576 5.008 9.364 10.066 11.474-4.942 2.172-8.154 5.829-10.037 11.557-1.864-5.684-5.05-9.37-9.985-11.525"
          />
          <path
            fill={theme.palePink}
            d="M413.855 174.439c1.616 5.313 4.185 8.84 8.329 10.875-4.102 2.129-6.73 5.575-8.323 10.933-1.602-5.33-4.208-8.809-8.287-10.903 4.064-2.105 6.687-5.56 8.28-10.905"
          />
          <g transform="translate(0 157.149)">
            <mask id="r" fill={theme.white}>
              <use xlinkHref="#q" />
            </mask>
            <path
              fill={theme.lightTeal}
              d="M16.78.415C13.748 10.054 8.333 16.33 0 19.77c8.308 3.48 13.76 9.713 16.76 19.324 3.005-9.506 8.399-15.806 16.75-19.298-8.243-3.51-13.73-9.704-16.73-19.382"
              mask="url(#r)"
            />
          </g>
          <path
            fill={theme.palePink}
            d="M50.565 160.407c4.892 2.169 8.094 5.771 9.956 11.506 1.912-5.575 5.009-9.364 10.067-11.474-4.942-2.172-8.155-5.829-10.038-11.557-1.863 5.684-5.05 9.37-9.985 11.525"
          />
          <path
            fill={theme.primaryBlue}
            d="M34.87 144.808c1.615-5.313 4.185-8.84 8.328-10.875-4.101-2.129-6.73-5.575-8.323-10.933-1.602 5.33-4.207 8.809-8.287 10.903 4.065 2.105 6.688 5.56 8.281 10.905"
          />
        </g>
      </g>
    </svg>
  );
};

export default EmployerSuccess;
