import ReactiveRecord, { Model } from 'reactiverecord';
const collectionRoute = ':prefix/moms/bids';
const memberRoute = `${collectionRoute}/:id`;

const sortByOrder = (a, b) => a.order - b.order;
class Bid extends Model {
  static routes = {
    index: collectionRoute,
    show: memberRoute,
  };
  static scopes = {
    sortByOrder(collection) {
      return collection.slice().sort(sortByOrder);
    },
  };
  static schema = {
    can_withdraw: Boolean,
    created_at: String,
    filter_type: String,
    has_appointments: Boolean,
    human_bid_status: String,
    mom_archived: Boolean,
    order: Number,
    project_link: String,
    project_status: String,
    project_title: String,
    slug: String,
    status: String,
    updated_at: String,
    applied_on_behalf: Boolean,
  };
}

export default ReactiveRecord.model('Bid', Bid);
