import React from 'react';

const Facebook = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.023 35.023"
    >
      <title>Facebook</title>
      <path
        fill="currentColor"
        d="M125.256,377.085v2.307h-2.7v3.127h2.7v8.027h3.23v-8.027h2.694l.4-3.127h-3.1v-2c0-.906.252-1.522,1.549-1.522l1.657,0v-2.8a22.184,22.184,0,0,0-2.413-.123A3.768,3.768,0,0,0,125.256,377.085Z"
        transform="translate(-109.611 -364.236)"
      />
      <path
        fill="currentColor"
        d="M131.936,367.477a17.512,17.512,0,1,0,17.512,17.512A17.512,17.512,0,0,0,131.936,367.477Zm0,31.84a14.328,14.328,0,1,1,14.328-14.328A14.329,14.329,0,0,1,131.936,399.316Z"
        transform="translate(-114.424 -367.477)"
      />
    </svg>
  );
};

export default Facebook;
