import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadioContainer, InputRadio } from './styles';

const RadioButton = forwardRef(
  ({ labelText, containerProps, classNames, ...props }, forwardedRef) => {
    const generatedId = useMemo(() => `radio-${RadioButton.idCounter++}`, []);
    const id = props.id || generatedId;
    return (
      <RadioContainer {...containerProps}>
        <InputRadio id={id} {...props} ref={forwardedRef} />
        <label htmlFor={id} className={classNames}>
          {labelText}
        </label>
      </RadioContainer>
    );
  },
);

RadioButton.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.any,
  containerProps: PropTypes.object,
  classNames: PropTypes.string,
};
RadioButton.idCounter = 0;
RadioButton.displayName = 'RadioButton';

export default RadioButton;
