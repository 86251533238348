import React from 'react';

const UploadIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19.7" {...props}>
      <title>Upload Icon</title>
      <path
        fill="currentColor"
        d="M19.1 12.7c.5 0 .9.4.9.9V17c0 1.5-1.2 2.7-2.7 2.7H2.7C1.2 19.7 0 18.5 0 17v-3.4c0-.5.4-.9.9-.9s.9.4.9.9v2.9c0 .8.6 1.4 1.4 1.4h13.6c.8 0 1.4-.6 1.4-1.4v-2.9c0-.5.4-.9.9-.9zM9.3.3c.4-.4 1-.4 1.3 0l.4.4.2.2.4.4L13.3 3l.2.2.1.1.4.3.1.1.1.1c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-1.9-1.9V15c0 .5-.4.9-.8.9H10c-.5 0-.9-.4-.9-.9V3.3L7.2 5.2c-.4.3-.9.3-1.3.1l-.1-.1-.1-.1c-.3-.4-.2-.9.1-1.3L9.3.3z"
      />
    </svg>
  );
};

export default UploadIcon;
