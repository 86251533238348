import styled from 'styled-components';
import { CheckboxContainer } from 'components/ui/inputs/Radio/styles';

export const FeedbackFormWrapper = styled.div`
  .radio-fieldset {
    display: grid;
    grid-template-columns: auto 16rem auto;
    justify-items: space-between;
    margin-bottom: 2rem;
    max-width: 35rem;
  }

  .inline-elements {
    display: flex;
    justify-content: space-between;

    ${CheckboxContainer} label {
      text-align: center;
      width: 25px;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-top: 25px;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenSizes.tablet}) {
    .feedback-button {
      width: 100%;
    }
    .radio-fieldset {
      grid-template-columns: auto auto;
      grid-template-rows: 2;
      max-width: 350px;
      .label_before,
      .label_after {
        grid-row: 2;
      }
      .label_before {
        grid-column: 1;
      }
      .label_after {
        grid-column: 2;
      }
      .inline-elements {
        grid-column: span 2;
        grid-row: 1;
        width: 100%;
      }
    }
  }
`;
