import styled from 'styled-components';
import { fonts } from 'styleGuide';

export const RadioContainer = styled.div`
  font-family: ${fonts.primary};
  outline: 0;
  display: table-cell;
  label {
    padding: 0.3rem 3vw;
    display: block;
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.lightGray};
    border-right: 0;
    cursor: pointer;
  }
  .first {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  .last {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-right: 1px solid ${({ theme }) => theme.lightGray};
  }
  input[type='radio']:checked + label {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primaryBlue};
  }
`;

export const InputRadio = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
`;
InputRadio.defaultProps = { type: 'radio' };
