import React from 'react';
import PropTypes from 'prop-types';
import RescheduleRequest from './RescheduleRequest';

const RescheduleRequests = props => {
  let requests = props.reschedule_requests.map(element => {
    return <RescheduleRequest key={element.id} request={element} />;
  });

  return (
    <div
      id="reschedule-requests"
      className="reschedule-requests page-section-row not-striped-rows"
    >
      <div className="sop-container">
        <h2>Reschedule Requests</h2>
        {requests}
      </div>
    </div>
  );
};

RescheduleRequests.propTypes = {
  reschedule_requests: PropTypes.array.isRequired,
};

export default RescheduleRequests;
