import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonsContainer,
  ModalSectionContainer,
} from 'components/shared/Header/PublicHeaderStyles';
import AnalyticsContext from 'components/shared/analytics/AnalyticsContext';
import LinkButton from 'components/ui/buttons/LinkButton';
import Dialog from 'components/ui/Dialog';
import { ANALYTICS, URLS } from 'consts';

const LoginModal = ({ show, toggle }) => {
  const { trackEvents } = useContext(AnalyticsContext);
  return (
    <Dialog onHide={toggle} show={show} title="Log in to your account" large>
      <ButtonsContainer data-id="header-btn-container">
        <ModalSectionContainer>
          <LinkButton
            className="mb-3 mt-3"
            data-id="header-individual-join-btn"
            data-testid="join-as-individual"
            href={URLS.talentLogin}
            size="xl"
            secondary
          >
            Talent log in
          </LinkButton>
        </ModalSectionContainer>
        <ModalSectionContainer>
          <LinkButton
            className="mb-3 mt-3"
            data-id="join-as-company-btn"
            href={URLS.employerLogin}
            onClick={() =>
              trackEvents(ANALYTICS.EVENT_TYPES.Click)({
                event_category: ANALYTICS.EVENT_CATEGORIES['Company_Login'],
                target: 'Login Button',
                userType: ANALYTICS.USER_TYPES.Employer,
              })
            }
            size="xl"
            secondary
          >
            Company log in
          </LinkButton>
        </ModalSectionContainer>
      </ButtonsContainer>
      <div className="text-center">
        New to The Mom Project?&nbsp;
        <a
          className="link-home-cta"
          data-testid="link-home-cta"
          href={URLS.join}
        >
          Join here
        </a>
      </div>
    </Dialog>
  );
};

LoginModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func,
};

export default LoginModal;
