import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from 'components/ui/inputs/RadioGroup';
import Radio from 'components/ui/inputs/Radio';
import FilterCount from './FilterCount';

const FilterBy = ({ onChange, filters, aggregations }) => {
  const counts = {};
  (aggregations || []).map(aggregation => {
    counts[aggregation.key] = aggregation.doc_count;
  });

  return (
    <>
      <RadioGroup onChange={onChange} noDefault={true}>
        {options => (
          <>
            <Radio
              {...options('all')}
              name="filter_by"
              value=""
              labelText={
                <>
                  All jobs
                  {aggregations && <FilterCount count={counts['all']} />}
                </>
              }
              checked={!filters.filter_by}
            />
            <Radio
              {...options('saved')}
              name="filter_by"
              data-testid="saved"
              value="saved"
              disabled={aggregations && !counts['saved']}
              labelText={
                <>
                  Saved jobs
                  {aggregations && <FilterCount count={counts['saved']} />}
                </>
              }
              checked={filters.filter_by === 'saved'}
            />
            <Radio
              {...options('matched')}
              name="filter_by"
              data-testid="matched"
              value="matched"
              disabled={aggregations && !counts['matched']}
              labelText={
                <>
                  Recommended
                  {aggregations && <FilterCount count={counts['matched']} />}
                </>
              }
              checked={filters.filter_by === 'matched'}
            />
            <Radio
              {...options('new')}
              name="filter_by"
              data-testid="new"
              value="new"
              disabled={aggregations && !counts['new']}
              labelText={
                <>
                  New jobs
                  {aggregations && <FilterCount count={counts['new']} />}
                </>
              }
              checked={filters.filter_by === 'new'}
            />
          </>
        )}
      </RadioGroup>
    </>
  );
};

FilterBy.propTypes = {
  filters: PropTypes.shape({
    filter_by: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  aggregations: PropTypes.array,
};

export default FilterBy;
