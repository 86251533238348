import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';

const CloudPinFilled = ({ fill, height, width, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      {...props}
    >
      <defs>
        <filter id="a" colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <filter
          id="b"
          width="210.5%"
          height="210.5%"
          x="-55.3%"
          y="-55.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40c5.523 0 10 4.477 10 10v40L0 0z" fill={fill} />
        <g filter="url(#a)">
          <g fill="#FFF" filter="url(#b)" transform="translate(27 4)">
            <path d="M3.209 8.22l.153-.154a2.009 2.009 0 012.73-.102l.11.103 4.802-4.803a1.472 1.472 0 012.505-.907l2.633 2.633a1.472 1.472 0 01-.907 2.506l-4.802 4.803.103.11c.68.788.645 1.98-.103 2.728l-.153.153-7.071-7.07z" />
            <path d="M6.24 11.25L.878 16.61l-.195 1.206 1.205-.195 5.36-5.36z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

CloudPinFilled.propTypes = {
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.number,
  width: PropTypes.number,
};

CloudPinFilled.defaultProps = {
  fill: theme.primaryBlue,
  height: 50,
  width: 50,
};

export default CloudPinFilled;
