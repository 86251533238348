import React, { useContext } from 'react';
import ProgressBar from 'components/ui/ProgressBar';
import MomContext from 'components/talent/MomContext';
import AvatarUpload from 'components/ui/AvatarUpload';
import {
  AvatarContainer,
  Header,
  Note,
  StyledDownloadResumeLink,
  SummaryContainer,
} from './styles';
import { URLS } from 'consts';
import LinkButton from 'components/ui/buttons/LinkButton';

const ProfileSummary = () => {
  const context = useContext(MomContext);
  const { mom, profileCompletion } = context;
  const mom_data = mom.data.attributes;
  const profileComplete = profileCompletion === 100;
  return (
    <>
      <SummaryContainer id="summary">
        <AvatarContainer>
          <AvatarUpload
            mode="mom"
            updateUrl={URLS.talentUpdate}
            avatar={mom_data.avatar}
            firstName={mom_data.first_name}
            lastName={mom_data.last_name}
          />
        </AvatarContainer>
        <Header>Welcome, {mom_data.first_name}</Header>
        <div className="profile-progress">
          <div>
            <span className="text-primary-blue font-heavy">PROFILE: </span>
            {profileComplete ? 'Complete!' : 'Keep going!'}
          </div>
          <ProgressBar percentage={profileCompletion} withCircle />
        </div>
        <LinkButton
          data-id="profile-summary-btn"
          href={URLS.talentProfile}
          size="md"
          css={`
            line-height: 20px;
          `}
          secondary
        >
          {profileComplete ? 'View/Edit' : 'Finish Profile'}
        </LinkButton>
        <Note>
          Profiles that are 100% complete receive 10x the interest from
          prospective employers.
        </Note>
        <StyledDownloadResumeLink />
      </SummaryContainer>
    </>
  );
};

export default ProfileSummary;
