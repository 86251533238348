import { useState, useEffect } from 'react';

const useParticipantStatus = (identity, roomParticipants) => {
  const [alreadyJoined, setAlreadyJoined] = useState(false);

  useEffect(() => {
    const joined = roomParticipants.some(participant => {
      return participant.name === identity;
    });
    setAlreadyJoined(joined);
  }, [identity, roomParticipants]);

  return alreadyJoined;
};

export default useParticipantStatus;
