import React from 'react';
import { Grid, Row, Col } from 'components/ui/grid';
import 'focus-within-polyfill';

import {
  FooterLink,
  FooterTitle,
  LinkLogo,
  RowFooterWrapper,
  StyledFooter,
} from 'components/shared/Footer/styles';
import { navItems } from 'components/shared/Header/NavItems';

import TMPLogoFull from 'components/ui/icons/Logos/TMPLogoFull';

export const FooterComponent2024 = () => {
  const handleCookieSettingsToggle = () => {
    /* eslint-disable no-undef */
    if (typeof OneTrust !== 'undefined') {
      OneTrust.ToggleInfoDisplay();
    }
    /* eslint-enable */
  };

  return (
    <StyledFooter className="bg-white">
      <Grid fluid={true} css="max-width: 1500px">
        <RowFooterWrapper>
          <Col className="mb-5" col={3} xs={12} md={6} lg={3}>
            <LinkLogo className="pt-3 pt-sm-0" href="/">
              <TMPLogoFull />
            </LinkLogo>
          </Col>
          <Col col={9} xs={12} md={12} lg={12}>
            <Row className="d-flex flex-xl-nowrap">
              <Col col={5} md={5} lg={2} className="pb-0 pb-sm-3 pb-lg-0">
                <FooterTitle>
                  {navItems.publicRoutes2024.general.label}
                </FooterTitle>
                <ul className="list-unstyled text-nowrap">
                  {navItems.publicRoutes2024.general.children.map(child => (
                    <li key={child.label}>
                      <FooterLink
                        href={child.href}
                        rel={child.rel}
                        target={child.target}
                      >
                        {child.label}
                      </FooterLink>
                    </li>
                  ))}
                  <li>
                    <FooterLink
                      role="button"
                      onClick={handleCookieSettingsToggle}
                    >
                      Cookie Settings
                    </FooterLink>
                  </li>
                </ul>
              </Col>
              <Col
                col={5}
                md={5}
                lg={2}
                className="mr-md-6 text-nowrap pb-0 pb-sm-3 pb-lg-0"
                style={{ marginRight: '4.5rem' }}
              >
                <FooterTitle>
                  {navItems.publicRoutes2024.talent.label}
                </FooterTitle>
                <ul className="list-unstyled text-nowrap">
                  {navItems.publicRoutes2024.talent.children.map(child => (
                    <li key={child.label}>
                      <FooterLink
                        href={child.href}
                        rel={child.rel}
                        target={child.target}
                      >
                        {child.label}
                      </FooterLink>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col col={5} md={5} lg={2} className="mr-md-3">
                <FooterTitle className="text-nowrap">
                  {navItems.publicRoutes2024.companies.label}
                </FooterTitle>
                <ul className="list-unstyled text-nowrap">
                  {navItems.publicRoutes2024.companies.children.map(child => (
                    <li key={child.label}>
                      <FooterLink
                        href={child.href}
                        rel={child.rel}
                        target={child.target}
                      >
                        {child.label}
                      </FooterLink>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
        </RowFooterWrapper>
      </Grid>
    </StyledFooter>
  );
};
