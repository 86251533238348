import React from 'react';
import Input from 'components/ui/formik/Input';
import { Field, useFormikContext } from 'formik';

const FieldLegalCompanyName = () => {
  const { errors, touched } = useFormikContext();
  return (
    <Field
      id="legal_company_name"
      data-testid="legal_company_name"
      name="legal_company_name"
      labelText="Legal Company Name"
      touched={touched}
      errors={errors}
      as={Input}
      tooltipText="What is your company's full legal name as shown on your tax return?"
    />
  );
};

export default FieldLegalCompanyName;
