import React from 'react';
import { groupBy, getTimeZoneFromBrowser } from 'utilities';

export const formValuesToSchedulingPage = formValues => {
  const currentValues = {
    ...formValues,
    timezone: { value: getTimeZoneFromBrowser() },
  };
  return Object.keys(currentValues).reduce((accum, key) => {
    const value = currentValues[key].value;
    if (key !== 'opening_hours') {
      accum[key] = value;
    } else {
      accum.opening_hours = openingHoursToParams(formValues);
    }
    return accum;
  }, {});
};

const openingHoursToParams = formValues => {
  const daysToNylasKey = {
    monday: 'M',
    tuesday: 'T',
    wednesday: 'W',
    thursday: 'R',
    friday: 'F',
  };
  const openingHours = { ...formValues.opening_hours };
  delete openingHours.error;
  return Object.keys(openingHours).reduce((accum, keyStr) => {
    const values = openingHours[keyStr].value;
    const transformedValues = values.map(({ startTime, endTime }) => {
      return {
        days: [daysToNylasKey[keyStr]],
        start: startTime,
        end: endTime,
      };
    });
    return [...accum, ...transformedValues];
  }, []);
};

export const schedulingPageToFormValues = interviewSchedulingPage => {
  return Object.entries(interviewSchedulingPage).reduce(
    (accum, [key, value]) => {
      if (key === 'opening_hours') {
        accum[key] = openingHoursToFormValues(interviewSchedulingPage);
      } else {
        accum[key] = { value, error: null };
      }
      return accum;
    },
    {},
  );
};

export const openingHoursToFormValues = params => {
  const nylasKeyToDays = {
    M: 'monday',
    T: 'tuesday',
    W: 'wednesday',
    R: 'thursday',
    F: 'friday',
  };

  const withSeparateDays = params.opening_hours.reduce((accum1, obj) => {
    obj.days.forEach(day => {
      const { start, end } = obj;
      accum1 = [...accum1, { days: [day], start, end }];
    });
    return accum1;
  }, []);
  const openingHours = groupBy(withSeparateDays, 'days');
  return Object.entries(openingHours).reduce(
    (accum, [key, value]) => {
      const newValues = value.map(val => ({
        startTime: val.start,
        endTime: val.end,
        error: null,
      }));
      const formKey = nylasKeyToDays[key];
      if (formKey) {
        accum[formKey].value = [...accum[formKey].value, ...newValues];
      }
      return accum;
    },
    {
      monday: { value: [] },
      tuesday: { value: [] },
      wednesday: { value: [] },
      thursday: { value: [] },
      friday: { value: [] },
      error: null,
    },
  );
};

// Form validations:
// 1. Validate that the name field exists.
// 2. Validate that at least one day is selected.
// 3. All of the available hour time ranges have a start and end time selected.
// 4. None of the available hour time ranges have errors (see TimeRangeSelects for validations).
export const validateForm = (formValues, setFormValues, setValidSubmit) => {
  const {
    name: { value: nameValue },
    opening_hours,
  } = formValues;

  // Validate there is a name
  let valid = true;
  if (!nameValue) {
    setFormValues(prevState => ({
      ...prevState,
      name: { value: nameValue, error: 'A name is required.' },
    }));
    valid = false;
  }

  // Ignore top level `error` key in opening_hours object
  const openingHours = { ...opening_hours };
  delete openingHours.error;

  // Validate at least one day is selected
  const selectedDays = selectedDaysFrom(openingHours);
  if (selectedDays.length === 0) {
    setFormValues(prevState => ({
      ...prevState,
      opening_hours: {
        ...prevState.opening_hours,
        error: 'Please select a weekday.',
      },
    }));
    valid = false;
  }
  // Validate all inputs have start and end times
  let addedError = false;
  const newOpeningHours = Object.keys(openingHours).reduce((accum, dayKey) => {
    const newValues = openingHours[dayKey].value.map(timeRange => {
      if (!timeRange.endTime || !timeRange.startTime) {
        timeRange.error = 'Please select a start and end time.';
        addedError = true;
      }
      return timeRange;
    });

    accum[dayKey] = { value: newValues };
    return accum;
  }, {});

  if (addedError) {
    setFormValues(prevState => ({
      ...prevState,
      opening_hours: newOpeningHours,
    }));
  }

  // Validate no available hour inputs have errors
  const daysWithErrors = errorsFrom(openingHours);
  if (daysWithErrors.length > 0) {
    valid = false;
  }
  setValidSubmit(valid);

  return valid;
};

const selectedDaysFrom = openingHours => {
  return Object.keys(openingHours).filter(dayStr => {
    const values = openingHours[dayStr].value;
    return values.length > 0;
  });
};

const errorsFrom = openingHours => {
  return Object.keys(openingHours).filter(dayStr => {
    const values = openingHours[dayStr].value;
    const hasError = values.filter(input => !!input.error).length > 0;
    return hasError;
  });
};

const isTypicalWorkingHours = openingHours => {
  const nineToFive =
    openingHours.filter(
      opening => opening.start !== '09:00' || opening.end !== '17:00',
    ).length === 0;
  const firstOpening = openingHours[0];
  const isDefaultMonToFri =
    openingHours.length === 1 && firstOpening.days.length === 5;
  const isCustomMonToFri =
    openingHours.reduce(
      (accum, opening) => {
        const currentDay = opening.days[0];
        if (accum.includes(currentDay)) {
          const indexOfDay = accum.indexOf(currentDay);
          accum.splice(indexOfDay, 1);
        }

        return accum;
      },
      ['M', 'T', 'W', 'R', 'F'],
    ).length === 0;

  return nineToFive && (isDefaultMonToFri || isCustomMonToFri);
};

export const openingHoursText = openingHours => {
  if (isTypicalWorkingHours(openingHours)) {
    return 'Mon - Fri, 9am - 5pm';
  }
  return 'Custom schedule';
};

export const BenefitsContent = () => (
  <>
    <p className="mb-2">
      <b>Connect your calendar so you can:</b>
    </p>
    <ul
      css="li {
      margin-left: 1rem;
    }"
    >
      <li>Add video and phone interviews</li>
      <li>Avoid double booking meetings</li>
      <li>Set up your available hours</li>
    </ul>
  </>
);
