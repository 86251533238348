import React, { useContext } from 'react';
import AnalyticsContext from './analytics/AnalyticsContext';
import SelectableButton from '../ui/buttons/SelectableButton';
import { ANALYTICS } from 'consts';
import PropTypes from 'prop-types';
import IconLinkTooltip from 'components/shared/IconLinkTooltip';
import { TooltipWrapper } from './styles';

const MAX_COLUMNS = 12;

function joinClassNames(classNames) {
  return classNames.join(' ');
}

function isSelected(selected, option) {
  return Array.isArray(selected)
    ? selected.includes(option.id)
    : selected === option.id;
}

function SelectableOptions({
  selected,
  cols,
  options,
  handleClick,
  featured,
  targetName,
  isSelectedFn = isSelected,
  className,
}) {
  const { trackEvents } = useContext(AnalyticsContext);
  const numCols = MAX_COLUMNS / cols;
  const renderedOptions = options.map(option => {
    const classNames = [
      `col-lg-${numCols}`,
      'd-flex',
      'align-items-stretch',
      'col-md-6',
      'col-12',
    ];
    if (featured) {
      classNames.push('featured-functions');
    }
    let tooltip = null;
    if (option.tooltip) {
      tooltip = (
        <TooltipWrapper className="ml-2 d-flex align-items-center">
          <IconLinkTooltip title={option.tooltip} trigger="MOUSEENTER" />
        </TooltipWrapper>
      );
    }
    // TODO: We need to decide on one format for "selected" rather than checking whether it is an array or a number.
    const isSelected = isSelectedFn(selected, option);
    return (
      <div className={joinClassNames(classNames)} key={option.id}>
        <SelectableButton
          data-id={`selectable-${option.id}-btn`}
          label={option.title}
          showImages={!!featured}
          value={option.id}
          selected={isSelected}
          action={() => {
            handleClick(option);
            // trackEvents might be undefined if there is no analytics provider
            trackEvents &&
              trackEvents(ANALYTICS.EVENT_TYPES.Click)({
                target: targetName,
                form_value: option.title,
              });
          }}
        />
        {tooltip}
      </div>
    );
  });
  return <div className={className || 'row'}>{renderedOptions}</div>;
}

SelectableOptions.propTypes = {
  className: PropTypes.string,
  targetName: PropTypes.string,
  options: PropTypes.array.isRequired,
  cols: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
  featured: PropTypes.bool,
  selected: PropTypes.any,
  isSelectedFn: PropTypes.func,
};

SelectableOptions.defaultProps = {
  cols: 2,
};

export default SelectableOptions;
