import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClearFilters from './ClearFilters';
import FilterBy from './FilterBy';
import CheckboxListFilter from './CheckboxListFilter';
import FilterMobile from './FilterMobile';
import PayRate from './PayRate';
import classNames from 'classnames';
import AnalyticsProvider from 'components/shared/analytics/AnalyticsProvider';
import themed from 'components/hoc/themed';
import BodyClassName from 'components/ui/BodyClassName';
import HtmlClassName from 'components/ui/HtmlClassName';
import Button from 'components/ui/buttons/Button';
import LocationPin from 'components/ui/icons/LocationPin';
import HideIntercomLauncher from 'components/shared/HideIntercomLauncher';
import { colors } from 'styleGuide';
import styled, { css } from 'styled-components';
import { useFilterContext } from './FilterContext';
import Flex from 'components/ui/Flex';
import { without } from 'utilities';
import KeywordSearch from './KeywordSearch';

const StyledFilter = styled.div(
  ({ theme }) => css`
    .filter-form {
      padding: 2rem 1.25rem;
    }

    @media (max-width: ${theme.screenSizes.tablet}) {
      .project-sidebar {
        &.editing-filters {
          position: fixed;
          top: 0;
          z-index: 200;
          width: 100%;
          height: 100%;
          overflow: scroll;
          background: ${theme.lightBlue};
        }

        .filter-form {
          margin-bottom: 60px;
        }

        &:not(.editing-filters) {
          display: none;
        }
      }
    }

    .form-wrapper {
      label:not(.selectable-button):not(.scrollable-list-item),
      .marketplace-label {
        display: block;
        font-size: ${theme.fontSizes.base};
      }
    }
  `,
);

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 16px;
  z-index: 3;
  background-color: ${props => props.theme.lightBlue};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
`;

const Filter = ({ logged_in, aggregations }) => {
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
  const { filters, setFilter, removeFilter, clearAll, filtersConfig } =
    useFilterContext();

  if (!filtersConfig) {
    return null;
  }

  const toggleFilters = () => {
    if (!isMobileFiltersOpen) {
      window.scrollTo(0, 0);
    }
    setIsMobileFiltersOpen(!isMobileFiltersOpen);
  };

  const handleSubmit = (e, allowDefault = false) => {
    if (e && 'preventDefault' in e && !allowDefault) {
      e.preventDefault();
    }
  };

  const handleLocationChanged = e => {
    setFilter('location', e.target.value);
  };

  const handleFilterChanged = e => {
    const { name, checked, value } = e.target;
    if (!checked || !value) {
      removeFilter(name);
    } else {
      setFilter(name, value);
    }
  };

  const handlePayRateOnChange = e => {
    const { name, value } = e.target;
    if (name === 'competitive') {
      handleFilterChanged(e);
      return;
    }
    const moneyValue = value.replace(/\./g, '').replace(/,/g, '');
    if (parseInt(moneyValue)) {
      setFilter(name, moneyValue);
    } else {
      removeFilter(name);
    }
  };

  const filterCount = Object.keys(without.call(filters, 'sort', 'page')).length;
  let capabilitiesAggregations;
  let experienceAggregations;
  if (aggregations) {
    capabilitiesAggregations = (aggregations.capability_categories || []).map(
      aggregation => {
        return {
          ...aggregation,
          key: `capabilities_category_ids[${aggregation.key}]`,
        };
      },
    );
    const mapExperienceAggregation = {
      jr: 'junior',
      mid: 'midlevel',
      sr: 'senior',
    };

    experienceAggregations = (
      aggregations.preferred_experience_level || []
    ).map(aggregation => {
      return {
        ...aggregation,
        key: mapExperienceAggregation[aggregation.key],
      };
    });
  }
  return (
    <AnalyticsProvider disableTracking>
      <HtmlClassName
        className={classNames({ 'no-scroll': isMobileFiltersOpen })}
      >
        <BodyClassName
          className={classNames({ 'no-scroll': isMobileFiltersOpen })}
        >
          <>
            <StyledFilter>
              <FilterMobile onClick={toggleFilters} filterCount={filterCount} />
              <div
                className={classNames('project-sidebar', {
                  'editing-filters': isMobileFiltersOpen,
                })}
              >
                <form
                  onSubmit={handleSubmit}
                  className="form-wrapper filter-form"
                >
                  <Flex justifyContent="space-between">
                    <h2 className="font-primary-regular font-md m-0">
                      Filter by
                    </h2>
                    {filterCount > 0 && isMobileFiltersOpen && (
                      <ClearFilters
                        filterCount={filterCount}
                        onHide={toggleFilters}
                        onClear={clearAll}
                      />
                    )}
                  </Flex>
                  <hr />
                  {logged_in && (
                    <FilterBy
                      aggregations={aggregations && aggregations.filter_by}
                      filters={filters}
                      onChange={handleFilterChanged}
                    />
                  )}
                  <KeywordSearch
                    name="location"
                    headerText="Location"
                    placeholder="City, State"
                    value={filters.location}
                    onChange={handleLocationChanged}
                    icon={
                      <LocationPin
                        width="22"
                        height="22"
                        color={colors.blue.primary}
                      />
                    }
                  />
                  <div className="mt-3">
                    <CheckboxListFilter
                      filters={filters}
                      aggregations={
                        aggregations && aggregations.project_location
                      }
                      options={filtersConfig['locationType']}
                      id="location-type"
                      onChange={handleFilterChanged}
                    />
                  </div>
                  <CheckboxListFilter
                    filters={filters}
                    aggregations={aggregations && aggregations.project_type}
                    options={filtersConfig['opportunityType']}
                    title="Opportunity type"
                    id="opportunity-type"
                    onChange={handleFilterChanged}
                  />
                  <CheckboxListFilter
                    filters={filters}
                    aggregations={capabilitiesAggregations}
                    options={filtersConfig['capabilitiesCategories']}
                    title="Superpower"
                    id="capabilities-category"
                    onChange={handleFilterChanged}
                  />
                  <CheckboxListFilter
                    filters={filters}
                    aggregations={experienceAggregations}
                    options={filtersConfig['experienceLevel']}
                    title="Experience level"
                    id="experience-level"
                    onChange={handleFilterChanged}
                  />
                  <CheckboxListFilter
                    filters={filters}
                    aggregations={aggregations && aggregations.hours_max}
                    options={filtersConfig['hoursPerWeek']}
                    title="Hours per week"
                    id="hours-per-week"
                    onChange={handleFilterChanged}
                  />
                  <PayRate onChange={handlePayRateOnChange} />
                </form>
                {isMobileFiltersOpen && (
                  <StyledFooter className="d-md-none">
                    <HideIntercomLauncher />
                    <Button
                      data-id="save-filters"
                      className="project-btn font-bold w-100"
                      onClick={toggleFilters}
                    >
                      Show results
                    </Button>
                  </StyledFooter>
                )}
              </div>
            </StyledFilter>
          </>
        </BodyClassName>
      </HtmlClassName>
    </AnalyticsProvider>
  );
};

Filter.propTypes = {
  logged_in: PropTypes.bool,
  aggregations: PropTypes.object,
};

export default themed(Filter);
