import React from 'react';
import { COMMUNITY_LINKS } from 'consts';

const ToSLabel = () => (
  <span>
    I agree to the&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={COMMUNITY_LINKS.termsOfService}
    >
      Terms of Service
    </a>
    &nbsp;and&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={COMMUNITY_LINKS.privacyPolicy}
    >
      Privacy Policy
    </a>
    .
  </span>
);

export default ToSLabel;
