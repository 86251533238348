import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styleGuide';
import { Col, Row } from 'react-bootstrap';
import { Box } from 'components/ui/Box';
import CheckboxGroup from 'components/ui/inputs/CheckboxGroup';
import CheckboxButton from 'components/ui/inputs/CheckboxButton';
import Dialog from 'components/ui/Dialog';
import PencilEditLink from 'components/ui/PencilEditLink';
import PlusCircleO from 'components/ui/icons/PlusCircleO';
import Experience from 'models/Experience';
import ExperienceForm from 'resources/experiences/ExperienceForm';
import ReactiveRecord from 'reactiverecord';

const LineClamp = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const SuggestedExperiences = ({ className }) => {
  const [selectedExperience, setSelectedExperience] = React.useState(null);
  const [experiencesFromResume, setExperiencesFromResume] = React.useState([]);
  const [confirmedExperiences, setConfirmedExperiences] = React.useState([]);
  const ExperienceModel = ReactiveRecord.model('Experience');

  useEffect(() => {
    ExperienceModel.all({
      include_extracted_from_resume: true,
    }).then(experiences => {
      setExperiencesFromResume(
        experiences.filter(
          experience => experience.extracted_from_parsed_resume,
        ),
      );
      setConfirmedExperiences(
        experiences.filter(experience => experience.user_confirmed),
      );
    });
  }, [ExperienceModel]);

  const handleSelectExperience = (experience, checked) => {
    if (checked) {
      setSelectedExperience(experience);
    } else {
      experience.updateAttributes({ user_confirmed: false });
      setConfirmedExperiences(
        confirmedExperiences.filter(item => item.id !== experience.id),
      );
    }
  };

  const handleExperienceAfterSave = () => {
    setConfirmedExperiences([
      ...confirmedExperiences.filter(item => item.id !== selectedExperience.id),
      selectedExperience,
    ]);
    setSelectedExperience(null);
  };

  const handleAddAnotherJob = () => {
    const newExperience = new Experience({ user_confirmed: true });
    setSelectedExperience(newExperience);
  };

  const handleDialogHide = () => setSelectedExperience(null);

  if (experiencesFromResume.length === 0) {
    return null;
  }

  return (
    <Box className={className} backgroundColor={colors.blue.light}>
      <b>We found these job titles on your resume.</b>
      <p>Highlight your experience by adding your work history.</p>
      <p>Which jobs would you like to add to your profile?</p>
      <CheckboxGroup>
        {() => (
          <Row>
            {experiencesFromResume.map(experience => {
              return (
                <Col className="mb-4" lg="4" md="6" xs="12" key={experience.id}>
                  <CheckboxButton
                    key={experience.id}
                    id={experience.id}
                    value={experience.id}
                    checked={confirmedExperiences.includes(experience)}
                    defaultChecked={false}
                    onChange={e => {
                      handleSelectExperience(experience, e.target.checked);
                    }}
                  >
                    <LineClamp>{experience.job_title}</LineClamp>
                  </CheckboxButton>
                </Col>
              );
            })}
          </Row>
        )}
      </CheckboxGroup>
      <hr className="mt-4 mt-4" />
      <div className="d-flex justify-content-center">
        <button
          type="button"
          id="experience-add-new"
          onClick={handleAddAnotherJob}
          className="btn btn-link"
        >
          <PlusCircleO size={20} className="mr-1" />
          Add another job
        </button>
      </div>
      {selectedExperience && (
        <Dialog
          large
          show={true}
          onHide={handleDialogHide}
          title="Work Experience"
        >
          <ExperienceForm
            for={selectedExperience}
            afterSave={handleExperienceAfterSave}
            onCancel={handleDialogHide}
          />
        </Dialog>
      )}
      {confirmedExperiences.length > 0 && (
        <div className="mt-4">
          {confirmedExperiences.map(experience => (
            <Box size="sm" key={experience.id}>
              <div
                className="d-flex flex-column flex-md-row justify-content-space-between"
                css="gap: 1rem"
              >
                <b>{experience.job_title}</b>
                <PencilEditLink
                  onClick={() => setSelectedExperience(experience)}
                  linkText="Edit job details"
                ></PencilEditLink>
              </div>
            </Box>
          ))}
        </div>
      )}
    </Box>
  );
};

SuggestedExperiences.propTypes = {
  className: PropTypes.string,
};

export default SuggestedExperiences;
