import React from 'react';

function AsteriskHelperText() {
  return (
    <div
      className="text-alt font-sm mt-1 mb-3"
      style={{ paddingBottom: '20px' }}
    >
      Questions marked with an asterisk (*) require an answer.
    </div>
  );
}

export default AsteriskHelperText;
