import ReactiveRecord, { Model } from 'reactiverecord';

const collectionRoute = ':prefix/moms/resumes';
const memberRoute = `${collectionRoute}/:id`;
class Resume extends Model {
  static routes = {
    only: ['index', 'create', 'destroy'],
    index: collectionRoute,
    create: collectionRoute,
    destroy: memberRoute,
  };

  static downloadAttribute = 'document_url';
  static nameAttribute = 'document_name';
  static uploaderAttribute = 'document';

  static schema = {
    bids_present: Boolean,
    display_name: { type: String, labelText: 'Resume file name' },
    document_name: String,
    document_url: String,
    profile_visible: Boolean,
    safe_display_name: String,
    thumb_url: String,
    _timestamps: true,
  };
}

export default ReactiveRecord.model('Resume', Resume);
