import PropTypes from 'prop-types';
import React from 'react';
import { ANALYTICS } from 'consts';
import { triggerAnalytics } from '../../../utilities/analytics';
import AnalyticsContext from './AnalyticsContext';
class AnalyticsProvider extends React.Component {
  events = {
    CLICK: ({ target, page, ...props }) => {
      const action = `Clicked ${target}${page ? ` from ${page}` : ''}`;
      this.triggerAnalytics({ action, ...props });
    },
    FOCUS: ({ target, ...props }) => {
      const action = `Clicked Into ${target}`;
      this.triggerAnalytics({ action, ...props });
    },
    SUCCESS: ({ event, ...props }) => {
      const action = `${event} Success`;
      this.triggerAnalytics({ action, ...props });
    },
    CONVERSION: ({ type, ...props }) => {
      let action;
      switch (type) {
        case 'SMB': {
          action = ANALYTICS.EVENT_TYPES.SMB_Conversion;
          break;
        }
        case 'Enterprise': {
          action = ANALYTICS.EVENT_TYPES.Enterprise_Conversion;
          break;
        }
        case 'Talent': {
          action = ANALYTICS.EVENT_TYPES.Talent_Conversion;
          break;
        }
      }
      // If event_category isn't provided via props, Event Category will default to "general" in GTM
      this.triggerAnalytics({ action, ...props });
    },
    CALCULATION: ({ target, ...props }) => {
      const action = `Calculated ${target}`;
      this.triggerAnalytics({ action, ...props });
    },
    CUSTOM: props => {
      this.triggerAnalytics(props);
    },
  };

  /** @param {string} eventType */
  trackEvents = eventType => {
    const trackedEvent = this.events[eventType];
    if (!trackedEvent) {
      throw new Error(`No tracked event for type: ${eventType}`);
    }
    return trackedEvent;
  };

  triggerAnalytics = props => {
    const addEmployerId =
      this.props.user && this.props.userType === ANALYTICS.USER_TYPES.Employer;
    if (!this.props.disableTracking) {
      const analyticsProps = {
        ...(this.props.user && { event_label: this.props.user.id }),
        ...(addEmployerId && { employer_id: this.props.user.id }),
        ...(this.props.userType && { user_type: this.props.userType }),
        ...(this.props.category && { event_category: this.props.category }),
        ...props,
      };
      if (this.props.debug) {
        console.log('Triggering analytics with:', analyticsProps);
      }
      this.props.triggerAnalytics(analyticsProps);
    }
  };

  triggerConversionAnalytics = props => {
    if (!this.props.disableTracking) {
      if (this.props.debug) {
        console.log('Triggering analytics with:', props);
      }
      this.props.triggerAnalytics(props, false);
    }
  };

  render() {
    return (
      <AnalyticsContext.Provider value={{ trackEvents: this.trackEvents }}>
        {this.props.children}
      </AnalyticsContext.Provider>
    );
  }
}

AnalyticsProvider.propTypes = {
  triggerAnalytics: PropTypes.func,
  user: PropTypes.object,
  category: PropTypes.string,
  userType: PropTypes.oneOf([
    ANALYTICS.USER_TYPES.Employer,
    ANALYTICS.USER_TYPES.Talent,
  ]),
  children: PropTypes.node,
  disableTracking: PropTypes.bool,
  debug: PropTypes.bool,
};

AnalyticsProvider.defaultProps = {
  triggerAnalytics: triggerAnalytics,
};

export default AnalyticsProvider;
