import withSideEffect from 'react-side-effect';

const Fragment = ({ children }) => children;

function reducePropsToState(propsList) {
  return propsList.map(({ className }) => className);
}
let originalClassName = null;
function handleStateChangeOnClient(className) {
  if (originalClassName === null) {
    originalClassName = document.documentElement.className || '';
  }
  if (className.length) {
    const nextClassName = [originalClassName, ...className]
      .filter(Boolean)
      .join(' ');
    if (document.documentElement.className !== nextClassName) {
      document.documentElement.className = nextClassName;
    }
    return;
  }
  document.documentElement.className = originalClassName;
}

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient,
)(Fragment);
