import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaSet } from 'use-media-set';

import { deletePage, hasActiveInterviews } from '../supporting';
import RescheduleNylasInterview from 'components/employer/interviews/RescheduleNylasInterview/RescheduleNylasInterview';
import InterviewRequestSent from './InterviewRequestSent';
import InterviewSchedulingPageAccountLoading from './InterviewSchedulingPageAccountLoading';
import InterviewSchedulingPageForm from './InterviewSchedulingPageForm';
import InterviewSchedulingPageSuccess from './InterviewSchedulingPageSuccess';
import SelectInterviewSchedulingPage from './SelectInterviewSchedulingPage';
import SelectSchedulingType from './SelectSchedulingType';
import ViewInterviewSchedulingPage from './ViewInterviewSchedulingPage';
import JustMakingSureSchedulingPage from '../SchedulingPage/JustMakingSureSchedulingPage';
import InterviewModal from 'components/employer/interviews/InterviewModal/InterviewModal';
import MaxInterviewsReached from './MaxInterviewsReached';
import stored from 'components/hoc/stored';
import ScreenSizeContext from 'components/shared/ScreenSizeContext';
import { INTERVIEW_SCHEDULING_PAGE_FORM_TYPES } from 'consts';
import CurrentUser from 'resources/current_user/CurrentUser';
import { mediaSetQueries } from 'styleGuide';

const RequestInterview = ({
  bid,
  handleOnCancel,
  handleOnClose,
  handleOnSuccess,
  interview,
  isReschedule,
  project,
  step,
  setStep,
}) => {
  const [currentPage, setCurrentPage] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [deletionError, setDeletionError] = useState(false);

  const rescheduleViaNylas = interview && interview.nylas_booking_url;
  const mediaStates = useMediaSet(mediaSetQueries);
  const isSmallScreen = mediaStates.has('mobileOrSmTablet');

  const deleteSchedulingPage = async () => {
    await deletePage(currentPage.id, setSubmitting, setDeletionError, () =>
      setStep('schedulingPage'),
    );
  };

  return (
    <CurrentUser>
      {(currentUser, loaded) => {
        return loaded ? (
          currentUser.reached_max_interviews_per_day ? (
            <MaxInterviewsReached handleOnClose={handleOnClose} />
          ) : (
            <ScreenSizeContext.Provider value={{ isSmallScreen }}>
              <div>
                {step === 'schedulingType' && (
                  <SelectSchedulingType
                    bid={bid}
                    handleCancel={handleOnCancel}
                    handleSelectManual={() => setStep('manualSelect')}
                    project={project}
                    user={currentUser}
                  />
                )}

                {step === 'nylasAccountSync' && (
                  <InterviewSchedulingPageAccountLoading
                    handleAccountSynced={() => setStep('schedulingPage')}
                    handleOptOut={() => setStep('manualSelect')}
                  />
                )}

                {step === 'schedulingPage' && (
                  <SelectInterviewSchedulingPage
                    bid={bid}
                    editedPageId={currentPage ? currentPage.id : null}
                    handleAccountNotYetSynced={() =>
                      setStep('nylasAccountSync')
                    }
                    handleCancel={handleOnCancel}
                    handleDeleteSchedulingPage={page => {
                      setCurrentPage(page);
                      setStep('deleteSchedulingPageConfirm');
                    }}
                    handleEditSchedulingPage={page => {
                      setCurrentPage(page);
                      hasActiveInterviews(page)
                        ? setStep('editSchedulingPageConfirm')
                        : setStep('editSchedulingPageForm');
                    }}
                    handleNewSchedulingPage={() =>
                      setStep('createSchedulingPageForm')
                    }
                    handleSelectManually={() => setStep('manualSelect')}
                    handleSendRequest={() => setStep('confirm')}
                    handleViewSchedulingPage={page => {
                      setCurrentPage(page);
                      setStep('viewInterviewSchedulingPage');
                    }}
                    momId={bid.mom.id}
                    secondaryButtonText="Go back"
                    user={currentUser}
                  />
                )}

                {step === 'viewInterviewSchedulingPage' && currentPage && (
                  <ViewInterviewSchedulingPage
                    handleBack={() => setStep('schedulingPage')}
                    handleContinue={() => setStep('schedulingPage')}
                    handleEdit={() => {
                      hasActiveInterviews(currentPage)
                        ? setStep('editSchedulingPageConfirm')
                        : setStep('editSchedulingPageForm');
                    }}
                    schedulingPage={currentPage}
                  />
                )}

                {step === 'createSchedulingPageForm' && (
                  <InterviewSchedulingPageForm
                    formType={INTERVIEW_SCHEDULING_PAGE_FORM_TYPES.create}
                    handleCancel={() => setStep('schedulingPage')}
                    handleOptOut={() => setStep('manualSelect')}
                    handleSuccess={page => {
                      setStep('createSchedulingPageSuccess');
                      setCurrentPage(page);
                    }}
                    secondaryButtonText="Go back"
                  />
                )}

                {step === 'editSchedulingPageConfirm' && currentPage && (
                  <JustMakingSureSchedulingPage
                    handleCancel={() => setStep('schedulingPage')}
                    handleContinue={() => setStep('editSchedulingPageForm')}
                    schedulingPage={currentPage}
                  />
                )}

                {step === 'deleteSchedulingPageConfirm' && (
                  <JustMakingSureSchedulingPage
                    buttonText="Delete"
                    content="Deleting this scheduling page will remove it permanently."
                    handleCancel={() => setStep('schedulingPage')}
                    handleContinue={deleteSchedulingPage}
                    schedulingPage={currentPage}
                    showError={deletionError}
                    submitting={submitting}
                  />
                )}

                {step === 'editSchedulingPageForm' && currentPage && (
                  <InterviewSchedulingPageForm
                    appointmentType={currentPage.interview_type}
                    formType={INTERVIEW_SCHEDULING_PAGE_FORM_TYPES.edit}
                    handleCancel={() => setStep('schedulingPage')}
                    handleOptOut={() => setStep('manualSelect')}
                    handleSuccess={isCreating => {
                      isCreating
                        ? setStep('createSchedulingPageSuccess')
                        : setStep('editSchedulingPageSuccess');
                    }}
                    interviewSchedulingPage={currentPage}
                    secondaryButtonText="Go back"
                  />
                )}

                {step === 'createSchedulingPageSuccess' ||
                step === 'editSchedulingPageSuccess' ? (
                  <InterviewSchedulingPageSuccess
                    handleContinue={() => setStep('schedulingPage')}
                    isEditing={step === 'editSchedulingPageSuccess'}
                  />
                ) : null}

                {step === 'reschedule' && rescheduleViaNylas && (
                  <RescheduleNylasInterview
                    handleOnClose={handleOnCancel}
                    handleOnSuccess={handleOnSuccess}
                    interview={interview}
                    mom={bid.mom}
                  />
                )}

                {step === 'confirm' && (
                  <InterviewRequestSent bid={bid} slug={project.slug} />
                )}

                {step === 'manualSelect' ||
                (step === 'reschedule' && !rescheduleViaNylas) ? (
                  <InterviewModal
                    bidId={bid.id}
                    handleOnCancel={handleOnCancel}
                    handleOnClose={handleOnClose}
                    handleOnSuccess={handleOnSuccess}
                    interview={interview}
                    isReschedule={isReschedule}
                    mom={bid.mom}
                    projectTitle={project.project_title}
                    secondaryButtonText="Go back"
                  />
                ) : null}
              </div>
            </ScreenSizeContext.Provider>
          )
        ) : null;
      }}
    </CurrentUser>
  );
};

RequestInterview.propTypes = {
  bid: PropTypes.object.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleOnSuccess: PropTypes.func.isRequired,
  interview: PropTypes.object,
  isReschedule: PropTypes.bool,
  project: PropTypes.object,
  setStep: PropTypes.func.isRequired,
  step: PropTypes.string,
  user: PropTypes.object,
};

export default stored(RequestInterview);
