import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CodeWrapper = styled.pre`
  padding: 16px;
  overflow: auto;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 1.45;
  background-color: ${({ theme }) => theme.lightBlue};
  border-radius: 3px;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
`;
/**
 * Renders given code in a code block with no highlighting
 * @param {object} props Component props
 * @param {string} props.text Code to be rendered
 */
export default function Code({ text, ...props }) {
  const lines = text.split(/\n/);
  const [line0] = lines.filter(Boolean);
  let trimChars = 0;
  if (line0[0] === ' ') {
    trimChars = line0.length - line0.replace(/^\s+/, '').length;
  }
  const __html = lines
    .map(line =>
      line
        .substring(trimChars)
        .replace(/[\u00A0-\u9999<>&]/gim, i => '&#' + i.charCodeAt(0) + ';'),
    )
    .join('\n')
    .trim();
  return <CodeWrapper {...props} dangerouslySetInnerHTML={{ __html }} />;
}

Code.propTypes = {
  text: PropTypes.string.isRequired,
};
