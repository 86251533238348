import styled from 'styled-components';

export const IconWrapper = styled.span(
  ({ theme, iconColor }) => `
    display: inline-block;
    color: ${iconColor || theme.darkGray};
    text-align: center;
    width: 30px;
    min-width: 30px;
    svg {
      margin-right: 0.5rem;
    }
  `,
);

export const StatusLabel = styled.div(
  ({ theme, backgroundColor }) => `
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    font-size: 1rem;
    font-weight: 700;
    color: ${theme.white};
    background-color: ${backgroundColor};
    border-radius: 0 5px 0 5px;
    text-align: center;
    padding: 8px;

    @media (max-width: ${theme.screenSizes.tablet}) {
      width: 100%;
      border-radius: 5px 5px 0 0;
    }
  `,
);

export const StyledProjectLogo = styled.div(
  ({ theme }) => `
    width: 97px;
    height: 97px;
    border-radius: 0 0 9px 0;
    border: 1px solid ${theme.lightGray};
  `,
);

export const StyledGenericInitialLogo = styled.div(
  ({ theme }) => `
    font-size: 2rem;
    color: ${theme.darkGray};
    background-color: ${theme.lightestGray};
  `,
);

export const StyledCompanyLogoImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
