import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaSet } from 'use-media-set';

import ResumeModal from './ResumeModal';
import themed from 'components/hoc/themed';
import Button from 'components/ui/buttons/Button';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/FlexItem';
import File from 'components/ui/icons/File';
import ShowToggle from 'components/ui/ShowToggle';
import { colors, mediaSetQueries } from 'styleGuide';

const StyledButton = styled(Button)`
  overflow: hidden;
  flex: 1 0 auto;
  ${props =>
    props.inProfile ? 'none' : 'box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15)'};
  .text-dark,
  .text-primary {
    transition: all 0.3s ease-in-out;
  }
  &:hover,
  &:focus {
    .text-dark {
      color: ${colors.black} !important;
    }
    .text-primary {
      color: ${colors.blue.secondary} !important;
    }
  }
  .file-icon {
    min-width: 50px;
    &.offset {
      margin: 0 -1rem -1rem 0;
    }
  }
`;

const DownloadResumeLink = props => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const onMobileOrSmTablet = mediaStates.has('mobileOrSmTablet');
  const smScreenOrProfile = props.inProfile || onMobileOrSmTablet;

  const FileIcon = () => {
    return (
      <File
        height={onMobileOrSmTablet ? 39 : 65}
        width={onMobileOrSmTablet ? 30 : 50}
        className={'file-icon ' + (smScreenOrProfile ? '' : 'offset')}
      />
    );
  };

  return (
    <ShowToggle>
      {(show, toggle) => (
        <>
          <StyledButton
            {...props}
            block
            color={colors.green.light}
            id="download-resume"
            onClick={toggle}
          >
            <Flex
              alignItems={smScreenOrProfile ? 'center' : 'flex-end'}
              className={!smScreenOrProfile && 'pr-2'}
              direction="row"
              full="width"
              gap={8}
              justifyContent="center"
            >
              {smScreenOrProfile && FileIcon()}
              <FlexItem
                className={
                  'text-left font-' + (smScreenOrProfile ? 'sm' : 'xs')
                }
                shrink={1}
              >
                <div className="font-heavy text-dark">Need a resume?</div>
                <div className="font-bolder text-primary text-wrap">
                  Download your profile as a PDF resume
                </div>
              </FlexItem>
              {!smScreenOrProfile && FileIcon()}
            </Flex>
          </StyledButton>
          {show && <ResumeModal handleClose={toggle} />}
        </>
      )}
    </ShowToggle>
  );
};

DownloadResumeLink.propTypes = {
  inProfile: PropTypes.bool,
};

export default themed(DownloadResumeLink);
