import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../Dialog';
import ShowToggle from '../ShowToggle';
import Button from './Button';

const ButtonDialog = ({
  buttonText,
  buttonId,
  title,
  size,
  children,
  ...props
}) => (
  <ShowToggle preventDefault>
    {(show, toggle) => (
      <>
        <Button onClick={toggle} size={size} secondary id={buttonId}>
          {buttonText}
        </Button>
        <Dialog large show={show} onHide={toggle} title={title} {...props}>
          {children(toggle)}
        </Dialog>
      </>
    )}
  </ShowToggle>
);

ButtonDialog.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonId: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  size: PropTypes.string,
  children: PropTypes.func.isRequired,
};

export default ButtonDialog;
