import React from 'react';
import PropTypes from 'prop-types';
import { COMMUNITY_LINKS } from 'consts';
import { useAgreements } from 'requests';
import { formatDateTime } from 'utilities';

const UserAgreements = ({ children }, talent) => {
  const { isLoading, isFetching, data: agreements } = useAgreements();
  const agreementDate = agreements => {
    const dateOption = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    const userAgreementDate = agreements[0].updated_at;
    return formatDateTime(userAgreementDate, dateOption).date;
  };
  const isNullState = isLoading || isFetching || agreements.length === 0;

  return (
    !isNullState && (
      <div>
        {children ? (
          children
        ) : (
          <p>
            <strong>Privacy and data</strong>
          </p>
        )}
        <p className="font-italic text-alt font-sm">
          {talent
            ? `You last agreed to our terms of service and privacy policy on ${agreementDate(
                agreements,
              )}`
            : `Agreed to terms of service and privacy policy on ${agreementDate(
                agreements,
              )}`}
        </p>
        <a
          className="font-heavy mr-5"
          href={COMMUNITY_LINKS.privacyPolicy}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          className="font-heavy"
          href={COMMUNITY_LINKS.termsOfService}
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </div>
    )
  );
};

UserAgreements.propTypes = {
  children: PropTypes.any,
  talent: PropTypes.bool,
};

export default UserAgreements;
