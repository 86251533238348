import React from 'react';
import PropTypes from 'prop-types';

const Instagram = ({ width, height, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Instagram</title>
      <rect
        x="1.70105"
        y="2"
        width="24"
        height="24"
        rx="5"
        stroke="currentColor"
        strokeWidth="3"
      />
      <circle
        cx="13.7012"
        cy="14"
        r="6"
        stroke="currentColor"
        strokeWidth="3"
      />
      <circle cx="21.201" cy="7.5" r="1.5" fill="currentColor" />
    </svg>
  );
};

Instagram.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Instagram.defaultProps = {
  width: 28,
  height: 28,
};

export default Instagram;
