import ReactiveRecord, { Model } from 'reactiverecord';

class BidSuggestionSearch extends Model {
  static routes = {
    only: 'show',
    show: '/api/v1/employers/projects/:project_id/bids/suggestions',
  };

  static schema = {
    first_name_last_initial: Array,
    expertises: Array,
    titles: Array,
  };
}

export default ReactiveRecord.model('BidSuggestionSearch', BidSuggestionSearch);
