import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import SuccessMessage from 'components/ui/labels/SuccessMessage';
import { Validate } from 'reactiverecord';
import { Label } from '../Input/styles';
import { StyledNumberFormat, InputWrapper } from './InputCurrencyStyles';

const InputCurrency = forwardRef(
  ({ labelText, successText, rateType, ...props }, forwardedRef) => {
    const generatedId = useMemo(
      () => `input-currency-${InputCurrency.idCounter++}`,
      [],
    );
    const id = props.id || generatedId;
    const storeValidate = useRef(null);
    const handleRef = useCallback(
      validateRef => ref => {
        const refInterface = ref
          ? {
              get isValid() {
                return storeValidate.current.isValid.bind(
                  storeValidate.current,
                );
              },
              get value() {
                return ref.state.numAsString;
              },
            }
          : null;
        validateRef(refInterface);
        if (forwardedRef) {
          forwardedRef(refInterface);
        }
      },
      [forwardedRef],
    );
    return (
      <Label htmlFor={id}>
        {labelText ? (
          <span className="label-text mb-2">{labelText}</span>
        ) : null}
        <Validate {...props} ref={storeValidate}>
          {({ ref: validateRef, ...inputProps }, errorText, validating) => (
            <>
              <InputWrapper hasError={!!errorText} rateType={rateType}>
                <StyledNumberFormat
                  id={id}
                  {...inputProps}
                  thousandSeparator={true}
                  ref={handleRef(validateRef)}
                  disabled={validating || inputProps.disabled}
                />
              </InputWrapper>
              {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
            </>
          )}
        </Validate>
        {successText ? <SuccessMessage>{successText}</SuccessMessage> : null}
      </Label>
    );
  },
);

InputCurrency.displayName = 'InputCurrency';

InputCurrency.propTypes = {
  labelText: PropTypes.string,
  decimalScale: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.any,
  rateType: PropTypes.string,
  successText: PropTypes.string,
};

InputCurrency.defaultProps = {
  decimalScale: 2,
};

InputCurrency.idCounter = 0;

export default InputCurrency;
