import React from 'react';
import PropTypes from 'prop-types';

const ReviewDocument = ({ width = 70, height = 67, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 70 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.52485C0 2.47356 2.47356 0 5.52485 0H40.5155C43.5668 0 46.0404 2.47356 46.0404 5.52485V31.2512C51.9414 33.3369 56.1693 38.9647 56.1693 45.58C56.1693 48.2656 55.4725 50.7884 54.2498 52.9776L68.6027 63.682C69.4181 64.2901 69.5861 65.4439 68.978 66.2593C68.37 67.0746 67.2161 67.2426 66.4008 66.6345L52.0778 55.9525C49.3049 58.919 45.357 60.7733 40.9759 60.7733C36.2508 60.7733 32.0295 58.6163 29.2429 55.2333C29.1652 55.2433 29.0859 55.2485 29.0054 55.2485H5.52485C2.47356 55.2485 0 52.7749 0 49.7236V5.52485ZM42.3572 5.52485V30.4486C41.9022 30.4076 41.4415 30.3867 40.9759 30.3867C39.5259 30.3867 38.1233 30.5898 36.795 30.9692C36.459 30.6106 35.9812 30.3867 35.4511 30.3867H11.0497C10.0326 30.3867 9.20808 31.2112 9.20808 32.2283C9.20808 33.2454 10.0326 34.0699 11.0497 34.0699H31.0581C29.0745 35.7807 27.5378 37.9955 26.6471 40.5155H11.0497C10.0326 40.5155 9.20808 41.3401 9.20808 42.3571C9.20808 43.3742 10.0326 44.1988 11.0497 44.1988H25.8445C25.8036 44.6537 25.7826 45.1144 25.7826 45.58C25.7826 47.7052 26.219 49.7285 27.007 51.5652H5.52485C4.50775 51.5652 3.68323 50.7407 3.68323 49.7236V5.52485C3.68323 4.50775 4.50775 3.68323 5.52485 3.68323H40.5155C41.5326 3.68323 42.3572 4.50775 42.3572 5.52485ZM9.20808 12.4309C9.20808 11.4138 10.0326 10.5893 11.0497 10.5893H35.4511C36.4682 10.5893 37.2927 11.4138 37.2927 12.4309C37.2927 13.448 36.4682 14.2725 35.4511 14.2725H11.0497C10.0326 14.2725 9.20808 13.448 9.20808 12.4309ZM11.0497 20.2578C10.0326 20.2578 9.20808 21.0823 9.20808 22.0994C9.20808 23.1165 10.0326 23.941 11.0497 23.941H35.4511C36.4682 23.941 37.2927 23.1165 37.2927 22.0994C37.2927 21.0823 36.4682 20.2578 35.4511 20.2578H11.0497ZM40.9759 57.0901C47.3328 57.0901 52.486 51.9368 52.486 45.58C52.486 39.2231 47.3328 34.0699 40.9759 34.0699C34.6191 34.0699 29.4658 39.2231 29.4658 45.58C29.4658 51.9368 34.6191 57.0901 40.9759 57.0901Z"
        fill="currentColor"
      />
    </svg>
  );
};

ReviewDocument.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default ReviewDocument;
