import React from 'react';
import Button from 'components/ui/buttons/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
function UseExisting({ useExisting, ...props }) {
  const classes = classNames('btn-existing-file', { selected: useExisting });

  return (
    <Button
      data-id="use-recent-resume-btn"
      className={classes}
      {...props}
      type="button"
      css={`
        line-height: 24px;
        font-weight: normal;
      `}
    >
      {useExisting && <div className="round-check-icon"></div>}
      Use resume from last bid
    </Button>
  );
}

UseExisting.propTypes = {
  useExisting: PropTypes.bool,
  onClick: PropTypes.func,
};

export default UseExisting;
