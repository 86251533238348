import { useEffect, useState } from 'react';
import ReactiveRecord from 'reactiverecord';

const useAnnouncement = position => {
  const AnnouncementModel = ReactiveRecord.model('Announcement');
  const [announcement, setAnnoucement] = useState(null);

  useEffect(() => {
    async function loadAnnouncements() {
      const announcements = await AnnouncementModel.all({
        position,
      });
      if (announcements.length > 0) {
        setAnnoucement(announcements[0]);
      }
    }
    loadAnnouncements();
  }, [AnnouncementModel, position]);

  const dismiss = async () => {
    await announcement.save();
    setAnnoucement(null);
  };

  return {
    announcement,
    dismiss,
  };
};

export default useAnnouncement;
