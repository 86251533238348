import styled from 'styled-components';
import PropTypes from 'prop-types';

/** Use as a child of AlignRow */
export const AlignRowItem = styled.div`
  display: table-cell;
  ${({ noWrap }) => noWrap && 'white-space: nowrap;'}
  ${({ grow }) =>
    grow &&
    `
    width: 100%;
  `}
  ${({ middle }) =>
    middle &&
    `
    vertical-align: middle;
  `}
  ${({ top }) =>
    top &&
    `
    vertical-align: top;
  `}
`;
AlignRowItem.propTypes = {
  /** Fills remaining horizontal space */
  grow: PropTypes.bool,
  /** Vertically centers the content */
  middle: PropTypes.bool,
  /** Aligns content to top on the horizontal axis */
  top: PropTypes.bool,
  /** Prevents text wrapping */
  noWrap: PropTypes.bool,
};

/** Aligns content on a horizontal axis */
export const AlignRow = styled.div`
  display: table;
  ${({ grow }) =>
    grow &&
    `
    width: 100%;
  `} ${({ borderShrink }) =>
    borderShrink &&
    `
    border-collapse: collapse;
  `};
`;
AlignRow.propTypes = {
  /** Fill horizontal space of container */
  grow: PropTypes.bool,
  /** Collapses borders within if placed inside a table */
  borderShrink: PropTypes.bool,
};
/** Aligns content on a horizontal axis, stacks when there is not enough
 * horizontal space for content.
 */
export const StackingAlignRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
/** Use as a child of StackingAlignRow */
export const StackingAlignRowItem = styled.div`
  flex-grow: 1;
  ${({ grow }) =>
    grow &&
    `
    flex-grow: 9999;
  `}
  ${({ middle }) =>
    middle &&
    `
    align-self: center;
  `}
  ${({ top }) =>
    top &&
    `
    align-self: flex-start;
  `}
`;
StackingAlignRowItem.propTypes = {
  /** Fills remaining horizontal space */
  grow: PropTypes.bool,
  /** Vertically centers the content */
  middle: PropTypes.bool,
  /** Aligns content to top on the horizontal axis */
  top: PropTypes.bool,
};
