import ReactiveRecord, { Model } from 'reactiverecord';
const collectionRoute = ':prefix/moms/actions';
const memberRoute = `${collectionRoute}/:id`;

class UserAction extends Model {
  static routes = {
    except: ['create', 'destroy'],
    index: collectionRoute,
    show: memberRoute,
  };

  static schema = {
    id: Number,
    action_id: Number,
    actionable_id: Number,
    actionable_type: String,
    is_completed: Boolean,
  };
}

export default ReactiveRecord.model('UserAction', UserAction);
