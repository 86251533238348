import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Check from '../icons/Check';

export const StyledSuccessMessage = styled.div`
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  color: ${({ theme }) => theme.green};
  font-weight: ${({ theme }) => theme.fontWeights.fontNormal};
  line-height: 1.5;
  font-size: ${({ theme }) => theme.fontSizes.base};
  display: flex;
  svg {
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px currentColor;
  }
`;

const ErrorMessage = props => {
  return (
    <StyledSuccessMessage>
      <Check width="22" height="22" />
      {props.children}
    </StyledSuccessMessage>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.any,
};

export default ErrorMessage;
