import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import themed from 'components/hoc/themed';

const CountNotificationBubble = ({ textLabel, count, children, ...props }) => {
  // if neither a textLabel or children are provided, only the notification bubble will be rendered
  return (
    <StyledLabel {...props}>
      {textLabel || children}{' '}
      {!!count && <StyledCount {...props}>{count}</StyledCount>}
    </StyledLabel>
  );
};

const StyledLabel = styled.span(
  ({ centerJustifyLabel }) => `
  display: flex;
  align-items: center;
  ${centerJustifyLabel ? 'justify-content: center' : ''};
`,
);

export const StyledCount = styled.div(
  ({ theme, superscript }) => `
  font-weight: 600;
  font-size: 0.75rem;
  color: ${theme.white};
  background-color: ${theme.red};
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  ${superscript ? 'margin-bottom: 1.25rem;' : 'margin-left: 0.75rem'};
`,
);

CountNotificationBubble.propTypes = {
  textLabel: PropTypes.string,
  count: PropTypes.number,
  superscript: PropTypes.bool,
  centerJustifyLabel: PropTypes.bool,
  children: PropTypes.array,
};

export default themed(CountNotificationBubble);
