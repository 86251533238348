import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaSet } from 'use-media-set';

import InterviewModalHeader from 'components/employer/interviews/InterviewModalHeader';
import stored from 'components/hoc/stored';
import Flex from 'components/ui/Flex';
import FlexItem from 'components/ui/FlexItem';
import LinkButton from 'components/ui/buttons/LinkButton';
import { URLS } from 'consts';
import { mediaSetQueries } from 'styleGuide';

const ContentWrapper = styled(Flex)`
  max-width: 500px;
  margin: 0 auto;
`;

const InterviewRequestSent = ({ bid, slug }) => {
  const mediaStates = useMediaSet(mediaSetQueries);
  const isMobile = mediaStates.has('mobile');
  return (
    <Flex alignItems="stretch" direction="column" gap={72}>
      <ContentWrapper alignItems="center" direction="column" gap={72}>
        <InterviewModalHeader
          centeredAndSpaced
          mom={bid.mom}
          heading="Invite sent!"
        />
        <div className="text-center">
          You&apos;ll receive an email when they choose a time. The interview
          will appear on your calendar.
        </div>
      </ContentWrapper>
      <Flex
        alignItems={isMobile ? 'stretch' : 'center'}
        className={!isMobile && 'mt-5'}
        data-id="confirm-request-sent-btns"
        direction={isMobile ? 'column' : 'row'}
        gap={isMobile ? 20 : 32}
        justifyContent="flex-end"
      >
        <FlexItem order={isMobile ? 2 : 1}>
          <LinkButton
            block={isMobile}
            data-id="got-it-btn"
            href={URLS.employerHome}
            secondary
          >
            Go to dashboard
          </LinkButton>
        </FlexItem>
        <FlexItem order={isMobile ? 1 : 2}>
          <LinkButton
            block={isMobile}
            data-id="view-applicants-btn"
            href={URLS.employerApplicantReview(slug)}
          >
            See more applicants
          </LinkButton>
        </FlexItem>
      </Flex>
    </Flex>
  );
};

InterviewRequestSent.propTypes = {
  bid: PropTypes.object,
  slug: PropTypes.string,
};

export default stored(InterviewRequestSent);
