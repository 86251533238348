import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import VideoCall from 'resources/video_calls/VideoCall';
import VideoApp from 'resources/video_calls/VideoApp';
import VideoNotFound from 'resources/video_calls/VideoNotFound';
import VideoCallFinished from 'resources/video_calls/VideoCallFinished';
import { VideoProvider } from 'resources/video_calls/VideoProvider';
import UnsupportedBrowserWarning from 'resources/video_calls/UnsupportedBrowserWarning';

const Interview = ({ resource, resourceType }) => {
  let { name } = useParams();

  return (
    <UnsupportedBrowserWarning>
      <VideoCall find={name}>
        {(videoCall, loaded, failed) => {
          if (failed) {
            return <VideoNotFound />;
          }

          if (!loaded) {
            return <LoadingOverlay />;
          }

          if (new Date(videoCall.end_time) < Date.now()) {
            return (
              <VideoCallFinished
                resourceType={resourceType}
                videoCall={videoCall}
              />
            );
          }
          return (
            <VideoProvider>
              <VideoApp
                videoCall={videoCall}
                loaded={loaded}
                failed={failed}
                resource={resource}
                resourceType={resourceType}
              />
            </VideoProvider>
          );
        }}
      </VideoCall>
    </UnsupportedBrowserWarning>
  );
};

Interview.propTypes = {
  resource: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default Interview;
