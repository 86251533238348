import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Box } from 'components/ui/Box';
import ProjectButtonByCondition from './ProjectButtonByCondition';
import Compensation from './Compensation';
import Location from './Location';
import ProjectCompanyBriefDescription from './ProjectCompanyBriefDescription';
import ProjectLogo from './ProjectLogo';
import ProjectTitle from './ProjectTitle';
import ProjectSave from './ProjectSave';
import ProjectType from './ProjectType';

const ProjectCard = ({
  isProjectSaveEnabled,
  onProjectSave,
  onProjectView,
  project,
}) => {
  return (
    <Box
      data-testid="project-card"
      highlight={project.is_match}
      className="mt-3 p-3 d-block d-sm-flex justify-content-between"
    >
      <div className="w-100">
        <div className="d-flex flex-direction-row ml-0 w-100 justify-content-between pl-0 pl-sm-3">
          <Row className="d-flex flex-sm-nowrap">
            <div className="ml-3 ml-sm-0 mb-2 d-block">
              <ProjectLogo
                companyName={project.company_name}
                logoUrl={project.employer_logo_url}
              />
            </div>
            <div className="ml-3 mb-2">
              <ProjectTitle project={project} onProjectView={onProjectView} />
              <ProjectCompanyBriefDescription
                briefDescription={project.employer_brief_description}
              />
            </div>
          </Row>
          <div>
            {isProjectSaveEnabled && (
              <ProjectSave
                className="d-block mx-0 my-0 px-0 py-0 rounded-0 justify-content-sm-end"
                iconOnly
                onProjectSave={onProjectSave}
                projectId={project.id}
                projectPin={project.pin}
              />
            )}
          </div>
        </div>
        <Row className="font-sm justify-content-between">
          <Col className="col-sm-4">
            <Location locations={project.location_remote_or_city_state_list} />
            <Compensation compensation={project.marketplace_compensation} />
            <ProjectType
              isFullTime={project.full_time}
              projectType={project.employment_status_human_readable}
            />
          </Col>
          <Col className="d-flex align-items-center align-items-sm-end justify-content-center justify-content-sm-end pt-4 pt-sm-0 d-flex flex-grow-1 flex-sm-grow-0">
            <ProjectButtonByCondition
              className="pt-4 pt-sm-0 d-flex flex-grow-1 flex-sm-grow-0"
              data-testid="projectButton"
              id="projectButton"
              onProjectView={onProjectView}
              slug={project.slug}
              talentBidStatus={project.talent_bid_status}
              talentBidAppliedOnBehalf={project.talent_bid_applied_on_behalf}
            />
          </Col>
        </Row>
      </div>
    </Box>
  );
};

ProjectCard.propTypes = {
  isProjectSaveEnabled: PropTypes.bool,
  onProjectSave: PropTypes.func,
  onProjectView: PropTypes.func,
  project: PropTypes.object.isRequired,
};

ProjectCard.defaultProps = {
  isProjectSaveEnabled: false,
};

export default ProjectCard;
