import React from 'react';

export default function ChevronDown(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 8.6" {...props}>
      <path
        d="M1 1l6.5 6.6l6.5 -6.6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
}
