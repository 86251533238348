import React from 'react';
import PropTypes from 'prop-types';
function EmptyBanner({ isSavedProjects = false }) {
  if (isSavedProjects) {
    return (
      <>
        <p className="text-primary">
          Hmm, it looks like you need to add saved jobs that are currently open
          for interviews.
        </p>
        <p className="text-primary">
          Use the heart icon next to opportunities you&apos;d like to save. Your
          favorites will appear here.
        </p>
      </>
    );
  }
  return (
    <p className="text-primary">
      Hmm, we weren&apos;t able to find any matches. You can find more jobs by
      choosing different search filters.
    </p>
  );
}

EmptyBanner.propTypes = {
  isSavedProjects: PropTypes.bool,
};

export default EmptyBanner;
