import PropTypes from 'prop-types';
import { PreventableEvent } from 'utilities';
import { syntheticInputGenerator } from './SyntheticInput';

const ValidatedSyntheticInput = syntheticInputGenerator(
  (setStateValue, disabled, onBlur, onChange, usingStateValue) => {
    return (newValue, triggerRemoteValidations = true) => {
      if (disabled) {
        return;
      }
      if (usingStateValue) {
        setStateValue(newValue);
      }
      setTimeout(() => {
        if (triggerRemoteValidations) {
          return onBlur(new PreventableEvent(newValue));
        }
        onChange(new PreventableEvent(newValue));
      }, 0);
    };
  },
);
ValidatedSyntheticInput.propTypes = {
  children: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
};
ValidatedSyntheticInput.displayName = 'ValidatedSyntheticInput';

export default ValidatedSyntheticInput;
