import ReactiveRecord, { Model } from 'reactiverecord';

class CurrentEmployer extends Model {
  static store = {
    singleton: true,
  };

  static routes = {
    index: ':prefix/employers/account',
    update: ':prefix/employers/account',
  };

  static schema = {
    address: { type: String, labelText: 'Company Address' },
    brief_description: { type: String, labelText: 'Company Description' },
    business_website: { type: String, labelText: 'Company Website' },
    city: { type: String, labelText: 'Company City' },
    company_name: String,
    email: String,
    enterprise: Boolean,
    first_name: String,
    formatted_brief_description: String,
    full_address: String,
    industry_id: Number,
    logo: String,
    logo_url: String,
    industry_name: String,
    is_subscriber: Boolean,
    last_name: String,
    number_of_employees: String,
    onboarding_complete: Boolean,
    phone: { labelText: 'Phone Number', type: String },
    referred_by: String,
    remove_logo: Boolean,
    slug: { type: String, labelText: 'Hosted Jobs Page URL' },
    state: { type: String, labelText: 'Company State' },
    zip: { type: String, labelText: 'Company Zip' },
  };

  get humanizedNumberOfEmployees() {
    return this.constructor.numberOfEmployeesOptions[this.number_of_employees];
  }
}

export default ReactiveRecord.model('CurrentEmployer', CurrentEmployer);
