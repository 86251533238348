import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Video = styled('video')(
  props => css`
    width: 100%;
    object-fit: fill;
    ${props.isLocal && 'transform: rotateY(180deg);'}
  `,
);

const VideoTrack = props => {
  const { track } = props;
  const ref = useRef(HTMLVideoElement);

  useEffect(() => {
    const el = ref.current;
    track.attach(el);

    return () => {
      track.detach(el);
    };
  }, [track]);

  return <Video ref={ref} {...props} />;
};

VideoTrack.propTypes = {
  track: PropTypes.object.isRequired,
  isLocal: PropTypes.bool,
};

export default VideoTrack;
