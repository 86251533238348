import React from 'react';
import VideoError from './VideoError';

const VideoGenericError = () => {
  return (
    <VideoError>
      <>
        <h4>
          There seems to be a problem with your video and/or microphone
          settings.
        </h4>
        <p>
          Please make sure you are using a recent browser and have allowed this
          page access to your camera and/or microphone.
        </p>
      </>
    </VideoError>
  );
};

export default VideoGenericError;
