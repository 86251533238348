import ReactiveRecord, { Model } from 'reactiverecord';
import {
  formattedDateTime,
  dateTimeOptions,
} from 'resources/video_calls/utilities';

class VideoCall extends Model {
  static routes = {
    show: ':prefix/video-calls/:id',
  };

  static schema = {
    bid_slug: String,
    end_time: String,
    project_slug: String,
    start_time: String,
  };

  get formattedStartTime() {
    return formattedDateTime(
      this.start_time,
      dateTimeOptions.date,
      dateTimeOptions.time,
    );
  }
}

export default ReactiveRecord.model('VideoCall', VideoCall);
