import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Location from './Location';
import ProjectActions from './ProjectActions';
import { getFormattedDate } from 'utilities';
import { SHORT_FORMAT_DATE_OPTIONS, URLS } from 'consts';
import { getCurrentStep } from './supporting';

// Component used on job posts view - projects with either `draft` or `pending_approval` status
function Draft(props) {
  const {
    city_state_list,
    id,
    project_title,
    posted_by,
    remote_percentage,
    start_date,
    status,
  } = props.draft.attributes;
  const { duplicate_project_link, view_project_link } = props.draft.links;
  const [stepName, setStepName] = useState('');

  const setCurrentStep = useCallback(async () => {
    const currentStep = await getCurrentStep(props.draft.attributes);
    setStepName(currentStep);
  }, [props.draft.attributes]);

  useEffect(() => {
    if (status === 'draft') {
      setCurrentStep();
    } else {
      setStepName('review');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentStep]);

  const editLink =
    status === 'draft' && stepName !== 'review'
      ? `${props.draft.links.edit_project_link}?step=${stepName}`
      : URLS.employerProjectReview(id);

  const startDateFormatted = start_date
    ? getFormattedDate(
        new Date(start_date + 'T00:00:00'),
        SHORT_FORMAT_DATE_OPTIONS,
      )
    : 'N/A';

  return (
    <div className="project">
      <div className="box box-md box-shadow-sm">
        <div className="row">
          <div className="basic-info col-lg-6">
            <div className="title font-md">{project_title}</div>
          </div>
          <ProjectActions
            copyPath={duplicate_project_link}
            viewPath={view_project_link}
            showProjectRemoval={true}
            projectSlug={id}
            projectTitle={project_title}
          />
        </div>
        <div className="secondary-info row justify-content-between">
          <div className="col-md-3 text-left pl-0 pb-3 pb-lg-0">
            <span className="font-heavy d-block mb-1">Start date</span>
            {startDateFormatted}
          </div>
          <div className="col-md-3 text-left pl-0 pb-3 pb-lg-0">
            <span className="font-heavy d-block mb-1">Created by</span>
            {posted_by}
          </div>
          <Location
            cityStateList={city_state_list}
            remotePercentage={remote_percentage}
            containerClassnames="col-md-3"
          />
          <div className="edit-container col-md-3 pr-0">
            <a
              id={`edit-${id}`}
              data-testid={`edit-link-${id}`}
              href={editLink}
              className="tmp-btn lg btn-edit-job"
            >
              <i className="fa fa-pencil" />
              &nbsp; Edit job
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
Draft.propTypes = {
  draft: PropTypes.object.isRequired,
};
export default Draft;
