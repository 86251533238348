import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import SuccessMessage from 'components/ui/labels/SuccessMessage';
import { Validate } from 'reactiverecord';
import { Label } from './Input/styles';
import { getInputSharedStyles } from './InputSharedStyles';
import NumberFormat from 'react-number-format';

const StyledPhoneInput = styled(NumberFormat)`
  text-align: left;
  ${getInputSharedStyles()}
`;

const InputPhone = forwardRef(
  (
    { allowEmptyFormatting, labelText, successText, format, mask, ...props },
    forwardedRef,
  ) => {
    const generatedId = useMemo(
      () => `input-phone-${InputPhone.idCounter++}`,
      [],
    );
    const id = props.id || generatedId;
    const storeValidate = useRef(null);
    const handleRef = useCallback(
      validateRef => ref => {
        const refInterface = ref
          ? {
              get isValid() {
                return storeValidate.current.isValid.bind(
                  storeValidate.current,
                );
              },
              get value() {
                return ref.state.numAsString;
              },
            }
          : null;
        validateRef(refInterface);
        if (forwardedRef) {
          forwardedRef(refInterface);
        }
      },
      [forwardedRef],
    );
    return (
      <Label htmlFor={id}>
        {labelText ? <span className="label-text">{labelText}</span> : null}
        <Validate {...props} ref={storeValidate}>
          {({ ref: validateRef, ...inputProps }, errorText, validating) => (
            <>
              <div className="input-wrapper">
                <StyledPhoneInput
                  id={id}
                  type="tel"
                  {...inputProps}
                  format={format}
                  ref={handleRef(validateRef)}
                  disabled={validating}
                  mask={mask}
                  allowEmptyFormatting={allowEmptyFormatting}
                />
              </div>
              {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
            </>
          )}
        </Validate>
        {successText ? <SuccessMessage>{successText}</SuccessMessage> : null}
      </Label>
    );
  },
);

InputPhone.displayName = 'InputPhone';

InputPhone.propTypes = {
  allowEmptyFormatting: PropTypes.bool, // It shows the input format before focusing on the element.
  labelText: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.any,
  successText: PropTypes.string,
  format: PropTypes.string,
  mask: PropTypes.string,
};

InputPhone.defaultProps = {
  allowEmptyFormatting: false,
  format: '(###) ###-####',
  mask: '_',
};

InputPhone.idCounter = 0;

export default InputPhone;
