import { useState, useReducer } from 'react';

export const ACTIONS = {
  Increment: 'increment',
  Decrement: 'decrement',
  Change: 'change',
};

function formStepReducer(state, action) {
  const defaultState = { index: state.index };
  switch (action.type) {
    case 'increment':
      return { index: state.index + 1 };
    case 'decrement':
      if (state.index > 0) {
        return { index: state.index - 1 };
      }
      return defaultState;
    case 'change':
      if (action.payload <= state.index) {
        return { index: action.payload };
      } else {
        return defaultState;
      }
    default:
      return defaultState;
  }
}

export function useMultiStepForm(state) {
  return useReducer(formStepReducer, state);
}

function MultiStepForm({ children, initialValues = {}, initialIndex = 0 }) {
  const [{ index }, formStepReducer] = useMultiStepForm({
    index: initialIndex,
  });
  const [values, updateValues] = useState(initialValues);
  const [saving, updateSaving] = useState(false);

  function onChange(key, value) {
    updateValues(currentValues => {
      return {
        ...currentValues,
        [index]: {
          ...currentValues[index],
          [key]: value,
        },
      };
    });
  }

  return children({
    incrementStep: () => formStepReducer({ type: 'increment' }),
    decrementStep: () => formStepReducer({ type: 'decrement' }),
    changeStep: step => formStepReducer({ type: 'change', payload: step }),
    values: values[index] || {},
    onChange,
    index,
    saving,
    updateSaving,
  });
}

export default MultiStepForm;
