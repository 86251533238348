import { useLayoutEffect, useState } from 'react';
import { scrollToFirstError } from 'utilities';

export const useErrorScroll = () => {
  const [validSubmit, setValidSubmit] = useState(true);
  useLayoutEffect(() => {
    if (!validSubmit) {
      scrollToFirstError();
      setValidSubmit(true);
    }
  }, [validSubmit]);
  return setValidSubmit;
};
