import React, { useContext } from 'react';
import MomContext from 'components/talent/MomContext';
import {
  ListItem,
  SectionSubtitle,
  SideSectionContainer,
  Description,
} from './styles';
import { URLS } from 'consts';
import ProfileContext from './ProfileContext';
import ButtonLink from 'components/ui/buttons/ButtonLink';
import AutoHellip from 'components/ui/AutoHellip';
import { AlignRow, AlignRowItem } from 'components/ui/AlignRow';
import { shortenMonthNames } from 'utilities';
import Caret from 'components/ui/icons/Caret';

const WorkExperience = () => {
  const mom = useContext(MomContext);
  const profile = useContext(ProfileContext);
  return (
    <SideSectionContainer>
      <SectionSubtitle className="mb-3">Work Experience</SectionSubtitle>
      {mom.experiences.length ? (
        <ul>
          {mom.experiences.map(experience => (
            <ListItem key={experience.id}>
              <AlignRow grow>
                <AlignRowItem grow middle>
                  <strong>
                    {experience.career_pause ? (
                      'Career pause'
                    ) : (
                      <AutoHellip>{experience.job_title}</AutoHellip>
                    )}
                  </strong>
                </AlignRowItem>
                {experience.date_summary ? (
                  <AlignRowItem middle css="white-space: nowrap;">
                    {shortenMonthNames(experience.date_summary)}
                  </AlignRowItem>
                ) : null}
              </AlignRow>
              {experience.career_pause ? null : (
                <AlignRow grow>
                  {experience.company_name ? (
                    <AlignRowItem grow middle>
                      <AutoHellip>{experience.company_name}</AutoHellip>
                    </AlignRowItem>
                  ) : null}
                  {experience.city_state ? (
                    <AlignRowItem css="white-space: nowrap;" middle>
                      {experience.city_state}
                    </AlignRowItem>
                  ) : null}
                </AlignRow>
              )}
              <Description
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html: experience.sanitized_description,
                }}
              />
            </ListItem>
          ))}
        </ul>
      ) : (
        <div className="inline">
          You don&apos;t have any work experience added.
          {mom.isBidSource
            ? ' Save this bid as a draft and '
            : ' Click here to '}
          <ButtonLink
            data-id="edit-career-story-btn"
            onClick={() => profile.editProfileAction(URLS.talentCareerStory)}
          >
            edit your career story&nbsp;
            <Caret />
          </ButtonLink>
        </div>
      )}
    </SideSectionContainer>
  );
};

export default WorkExperience;
