import React, {
  forwardRef,
  useMemo,
  useCallback,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import { Validate } from 'reactiverecord';
import { Label } from './Input/styles';
import styled from 'styled-components';

const paddingTB = 13;
const characterCounterPadding = 16;
const TextAreaWrapper = styled.div`
  position: relative;
  .characters-remaining {
    position: absolute;
    bottom: 8px;
    left: 12px;
    text-transform: none;
    line-height: 1;
    pointer-events: none;
  }
`;
const StyledTextArea = styled.textarea(
  ({ white, theme, characterCounter, css }) => `
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: ${theme.fontWeights.fontHeavy};
  line-height: 24px;
  color: ${theme.darkGray};
  background-color: ${white ? theme.white : theme.lightBlue};
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px ${theme.lightGray};
  -webkit-appearance: none;
  padding: ${paddingTB}px 12px${
    characterCounter ? ` ${paddingTB + characterCounterPadding}px` : ''
  };
  resize: none;

  &:focus,
  .forceFocusState & {
    box-shadow: inset 0 0 0 1px ${theme.primaryBlue};
  }
  &::-webkit-input-placeholder {
    color: ${theme.mediumGray};
    font-weight: ${theme.fontWeights.fontNormal};
  }
  &::-moz-placeholder {
    color: ${theme.mediumGray};
    font-weight: ${theme.fontWeights.fontNormal};
  }
  &:-ms-input-placeholder {
    color: ${theme.mediumGray};
    font-weight: ${theme.fontWeights.fontNormal};
  }

  &:disabled {
    background-color: ${theme.lightGray};
    box-shadow: inset 0 0 0 1px ${theme.lightGray};
    &::-webkit-input-placeholder {
      color: ${theme.mediumGray};
    }
    &::-moz-placeholder {
      color: ${theme.mediumGray};
    }
    &:-ms-input-placeholder {
      color: ${theme.mediumGray};
    }
  }
  ${css || ''}
`,
);

const TextArea = forwardRef(
  ({ labelText, minRows, ...props }, forwardedRef) => {
    const generatedId = useMemo(() => `textarea-${TextArea.idCounter++}`, []);
    const id = props.id || generatedId;
    const [characterCount, setCharacterCount] = useState(
      (props.value || props.defaultValue || '').length,
    );
    const countingCharacters = !!(props.validators && props.validators.length);
    const renderedTextArea = useRef(null);
    const renderedLineHeight = useRef(null);
    const handleHeight = useCallback(() => {
      renderedTextArea.current.rows = 1;
      const padding = countingCharacters
        ? paddingTB * 2 + characterCounterPadding
        : paddingTB * 2;
      const nextRows = Math.max(
        Math.ceil(
          (renderedTextArea.current.scrollHeight - padding) /
            renderedLineHeight.current,
        ),
        minRows,
      );
      renderedTextArea.current.rows = nextRows;
    }, [minRows, countingCharacters]);
    const handleRef = useCallback(
      validateRef => ref => {
        renderedTextArea.current = ref;
        if (ref) {
          renderedLineHeight.current = parseInt(
            getComputedStyle(ref).lineHeight,
          );
          handleHeight();
        }
        validateRef(ref);
      },
      [handleHeight],
    );
    const handleChange = useCallback(
      validateOnChange => event => {
        if (validateOnChange) {
          validateOnChange(event);
          if (event.defaultPrevented) {
            return;
          }
        }
        if (countingCharacters) {
          setCharacterCount(event.target.value.length);
        }
        handleHeight();
      },
      [handleHeight, countingCharacters],
    );

    let charactersRemaining = null;
    if (countingCharacters) {
      const [{ maximum } = {}] = props.validators.length;
      if (maximum) {
        const charactersRemainingCount =
          maximum - Math.min(characterCount, maximum);
        const character =
          charactersRemainingCount === 1 ? 'character' : 'characters';
        charactersRemaining = (
          <span className="characters-remaining">
            {charactersRemainingCount} {character} remaining
          </span>
        );
      }
    }

    return (
      <Label htmlFor={id}>
        {labelText ? <span className="label-text">{labelText}</span> : null}
        <Validate {...props} ref={forwardedRef}>
          {({ ref, onChange, ...inputProps }, errorText, validating) => (
            <>
              <div className="input-wrapper">
                <TextAreaWrapper>
                  <StyledTextArea
                    characterCounter={countingCharacters}
                    id={id}
                    disabled={validating}
                    {...inputProps}
                    onChange={handleChange(onChange)}
                    ref={handleRef(ref)}
                  />
                  {charactersRemaining}
                </TextAreaWrapper>
              </div>
              {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
            </>
          )}
        </Validate>
      </Label>
    );
  },
);

TextArea.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.any,
  minRows: PropTypes.number,
  onChange: PropTypes.func,
  validators: PropTypes.object,
  value: PropTypes.any,
  css: PropTypes.string,
  defaultValue: PropTypes.any,
};

TextArea.defaultProps = {
  rows: 1,
  minRows: 1,
};

TextArea.idCounter = 0;
TextArea.displayName = 'TextArea';

export default TextArea;
