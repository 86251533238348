import React, { useRef, useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MultiSelectWrapper } from './supporting';
import Caret from 'components/ui/icons/Caret';
import PlusCircleO from 'components/ui/icons/Plus';
import X from 'components/ui/icons/X';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import Checkbox from '../Checkbox';
import { isEmptyObject } from 'utilities';

// Will be used in 2.1 version when we will implement recommendations
// import { denormalize } from '../MultiSelect/supporting';

/**
 *
 * This MultiSelectCheckbox is picky about props.
 * Here's what it expects:
 * `options` should have database IDs accessible through `keysAccessor` and display names through `valueAccessor`
 * `value` should have the same shape as `options`
 * `onChange` needs to accept (values: Object, id: Number|String, name: String, shouldAdd: Boolean)
 *
 */
const MultiSelectCheckbox = ({
  // Will be used in 2.1 version when we will implement recommendations
  //pills,
  value,
  onBlur,
  onChange,
  options,
  keysAccessor,
  labelText,
  valueAccessor,
  placeholder,
  max,
  disabled,
  errors,
  name,
  touched,
}) => {
  const [hasErrors, setHasErrors] = useState(
    errors && errors[name] && touched && touched[name],
  );
  const [listOptions, setListOptions] = useState(Object.keys(options));
  const [listOpened, setListOpened] = useState(false);
  const [keyword, setKeyword] = useState('');

  const inputRef = useRef(null);
  const multiselectRef = useRef(null);

  useEffect(() => {
    setHasErrors(errors && errors[name] && touched && touched[name]);
  }, [errors, touched, name]);

  useEffect(() => {
    setListOptions(
      keysAccessor(options)
        .filter(listFilter)
        .map(key => parseInt(key)),
    );
  }, [options, keyword, keysAccessor, listFilter]);

  // Will be used in 2.1 version when we will implement recommendations
  // const renderedPills = denormalize(pills);

  /**
   * Adds a Listener that checks if the click was outside of the multiselect and closes the multiselect
   */
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        multiselectRef.current &&
        !multiselectRef.current.contains(event.target)
      ) {
        closeList();
        onBlur(name);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [multiselectRef, name, onBlur]);

  const openList = () => {
    setListOpened(true);
  };

  const closeList = () => {
    setListOpened(false);
  };

  const showList = () => {
    return listOpened;
  };

  const handleKeywordChange = e => {
    const searchTerm = e.target.value;
    setKeyword(searchTerm);
  };

  /**
   * Filters value using the search keyword
   */
  const listFilter = useCallback(
    key => {
      if (keyword) {
        return (
          valueAccessor(options, key)
            .toLowerCase()
            .indexOf(keyword.toLowerCase()) > -1
        );
      }
      return true;
    },
    [keyword, valueAccessor, options],
  );

  /**
   * Calculates max height of the list to insert it as style
   * Minimal maxHeight of the menu is 100px
   */
  function getMenuMaxHeight() {
    const { bottom } = inputRef.current.getBoundingClientRect();
    if (window.innerHeight - bottom < 100) {
      return { maxHeight: `100px` };
    }
    return { maxHeight: `calc(100vh - ${bottom + 10}px)` };
  }

  function isDisabled() {
    return disabled || max <= Object.values(value).length;
  }

  return (
    <MultiSelectWrapper>
      {labelText}
      <div className="multiselect-container" ref={multiselectRef}>
        {Object.values(value).length > 0 && (
          <div className="counter">{Object.values(value).length}</div>
        )}
        <Caret
          className="caret"
          size="16px"
          direction={listOpened ? 'up' : 'down'}
          onClick={listOpened ? closeList : openList}
        />
        <input
          id="multiselect-input"
          data-testid="multiselect-input"
          className={classNames({
            opened: listOpened,
            'multiselect-input': true,
            'input-error': hasErrors,
          })}
          ref={inputRef}
          placeholder={placeholder}
          value={keyword}
          onChange={e => handleKeywordChange(e)}
          onClick={!listOpened ? openList : null}
        />
        {showList() && (
          <div className="list-wrapper">
            <div className="multiselect-list" style={getMenuMaxHeight()}>
              {listOptions.length > 0 ? (
                listOptions.map((key, i) => {
                  return (
                    <div
                      role="button"
                      tabIndex={i}
                      className="multiselect-list-item"
                      data-testid={`multiselect-item-${key}`}
                      key={key}
                    >
                      <Checkbox
                        className="list-check-box"
                        labelText={valueAccessor(options, key)}
                        checked={!!value[key]}
                        name={name}
                        onChange={() =>
                          !!value[key]
                            ? onChange(value, key, value[key], false)
                            : onChange(value, key, options[key], true)
                        }
                        id={`list-check-box-${key}`}
                        labelTestId={`list-check-box-${key}`}
                        value={key}
                        disabled={!value[key] && isDisabled()}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="multiselect-list-item no-results">
                  No Results
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div
        data-testid="multiselect-label-container"
        className={classNames({
          'with-values': isEmptyObject(value),
          'pill-label-container': true,
        })}
      >
        {!isEmptyObject.call(value)
          ? Object.keys(value)
              .map((valueItem, i) => {
                return (
                  <span
                    role="button"
                    tabIndex={i}
                    key={valueItem}
                    className={classNames({
                      selected: !!value[valueItem],
                      'pill-label': true,
                    })}
                    onClick={
                      !!value[valueItem]
                        ? () =>
                            onChange(value, valueItem, value[valueItem], false)
                        : () =>
                            onChange(value, valueItem, options[valueItem], true)
                    }
                    onKeyDown={
                      !!value[valueItem]
                        ? () =>
                            onChange(value, valueItem, value[valueItem], false)
                        : () =>
                            onChange(value, valueItem, options[valueItem], true)
                    }
                  >
                    {valueAccessor(value, valueItem)}
                    <div className="label-icon">
                      {!!value[valueItem] ? (
                        <X className="x-icon" />
                      ) : (
                        <PlusCircleO className="plus-icon" />
                      )}
                    </div>
                  </span>
                );
              })
              .reduce((prev, next) => [prev, ' ', next])
          : null}
      </div>
      {hasErrors && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </MultiSelectWrapper>
  );
};

MultiSelectCheckbox.propTypes = {
  options: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  // Will be used in 2.1 version when we will implement recommendations
  //pills: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  defaultValue: PropTypes.array,
  id: PropTypes.any,
  keysAccessor: PropTypes.func,
  labelText: PropTypes.any,
  /** Optionally set a max length to allow checked. Disables others once the max is reached */
  max: PropTypes.number,
  validating: PropTypes.bool,
  valueAccessor: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  name: PropTypes.string,
  touched: PropTypes.object,
  value: PropTypes.objectOf(PropTypes.any.isRequired),
  onBlur: PropTypes.func,
};
MultiSelectCheckbox.defaultProps = {
  keysAccessor: object => Object.keys(object),
  placeholder: 'Search or select',
  valueAccessor: (object, key) => object[key],
};
MultiSelectCheckbox.idCounter = 0;

export default MultiSelectCheckbox;
