import ReactiveRecord, { Model } from 'reactiverecord';

class TitleJoin extends Model {
  static routes = {};
  static attributesName = 'title_joins_attributes';
  static schema = {
    id: Number,
    years_of_experience: Number,
    title: String,
    title_id: Number,
    _destroy: Boolean,
  };
}

export default ReactiveRecord.model('TitleJoin', TitleJoin);
