import React from 'react';
import PropTypes from 'prop-types';
import ReactiveRecord, { Collection } from 'reactiverecord';

const CertificationModel = ReactiveRecord.model('Certification');

let requestedAtLeastOnce = false;
let loadedAtLeastOnce = false;

const Certifications = ({ children, ...props }) => {
  return (
    <Collection
      for={CertificationModel}
      fetch={!requestedAtLeastOnce}
      {...props}
    >
      {certifications => {
        requestedAtLeastOnce = true;
        if (certifications._request.status === 200) {
          loadedAtLeastOnce = true;
        }
        return children(certifications, loadedAtLeastOnce);
      }}
    </Collection>
  );
};

Certifications.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Certifications;
