import ReactiveRecord, { Model } from 'reactiverecord';
class DemographicSurvey extends Model {
  static routes = {
    index: ':prefix/moms/demographic_surveys',
  };

  static schema = {
    id: String,
    title: String,
    questionType: String,
    options: Array,
  };
}

export default ReactiveRecord.model('DemographicSurvey', DemographicSurvey);
