import ReactiveRecord, { Model } from 'reactiverecord';

class CloudTalentSearch extends Model {
  static routes = {
    show: `/employer/clouds_talent_search`,
  };
  static schema = {
    talent: Array,
    total: Number,
  };
}

export default ReactiveRecord.model('CloudTalentSearch', CloudTalentSearch);
