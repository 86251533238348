import React from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from 'components/ui/icons/DownloadIcon';

const DownloadResume = props => {
  return (
    <a
      download
      id="download-resume"
      target="_blank"
      rel="noreferrer noopener nofollow"
      className="font-heavy mt-1"
      href={props.resume.document_url}
    >
      <DownloadIcon width={20} className="mr-2" /> {props.linkText}
    </a>
  );
};

DownloadResume.propTypes = {
  resume: PropTypes.object,
  linkText: PropTypes.string,
};
DownloadResume.defaultProps = {
  linkText: 'Download Resume',
};

export default DownloadResume;
