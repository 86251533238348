import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import PlusCircleO from '../icons/PlusCircleO';

const PlusCircleButton = props => {
  return (
    <Button
      data-id="plus-circle-btn"
      css={`
        min-width: unset;
        white-space: nowrap;
        font-weight: ${({ theme }) => theme.fontWeights.fontHeavy};
        svg {
          display: inline-block;
          vertical-align: sub;
          margin-right: 5px;
        }
        &:disabled {
          background: ${({ theme }) => theme.white};
          color: ${({ theme }) => theme.mediumGray};
          cursor: not-allowed;
        }
      `}
      secondary
      noBorder
      {...props}
    >
      <PlusCircleO size={20} />
      {props.children}
    </Button>
  );
};
PlusCircleButton.defaultProps = {
  children: <span className="sr-only"></span>,
};

PlusCircleButton.propTypes = {
  children: PropTypes.any,
};

export default PlusCircleButton;
