import React from 'react';
import PropTypes from 'prop-types';
import Check from 'components/ui/icons/Check';
import FadeInOutMount from './FadeInOutMount';
import styled from 'styled-components';

const Wrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.lightTeal};
    border-radius: 5px;
    max-width: 40rem;
    svg {
      border: 2px solid ${theme.darkGray};
      border-radius: 50%;
    }
  `,
);

const ConfirmationBanner = ({ timeout, in: inProp, children, ...props }) => {
  return (
    <FadeInOutMount timeout={timeout} in={inProp}>
      <Wrapper {...props} className="p-3 font-heavy mb-4">
        <Check width={25} className="check mr-2" />
        {children}
      </Wrapper>
    </FadeInOutMount>
  );
};

ConfirmationBanner.defaultProps = {
  timeout: 400,
};
ConfirmationBanner.propTypes = {
  children: PropTypes.any,
  timeout: FadeInOutMount.propTypes.timeout,
  in: FadeInOutMount.propTypes.in,
};

export default ConfirmationBanner;
