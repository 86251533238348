import React, { forwardRef, useMemo } from 'react';
import ErrorMessage from 'components/ui/labels/ErrorMessage';
import { Validate } from 'reactiverecord';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Label = styled.label(
  ({ theme, hasError }) => `
  display: block;
  width: 100%;
  .label-text {
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.fontNormal};
    line-height: 20px;
    color: ${theme.mediumGray};
    text-transform: uppercase;
  }
  .input-wrapper {
    display: flex;
    overflow: hidden;
    cursor: text;
    border: 1px solid ${theme.lightGray};
    border-radius: 5px;
    &:focus-within {
      border-color: ${theme.primaryBlue};
    }
    ${hasError && `border-color: ${theme.red};`}
  }
  .input-prefix {
    padding-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: ${theme.lightGray};
    direction: rtl;
  }
  .input-wrapper,
  input {
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeights.fontHeavy};
    line-height: 3rem;
    color: ${theme.darkGray};
  }
  input {
    flex-grow: 1;
    padding-right: 15px;
    background-color: ${theme.lightBlue};
    border: none;
    -webkit-appearance: none;
    &:focus {
      outline: none;
    }
    &:disabled {
      background-color: ${theme.lightGray};
    }
  }
`,
);

const SuffixInput = forwardRef(
  ({ prefix, labelText, ...props }, forwardedRef) => {
    const generatedId = useMemo(
      () => `suffix-input-${SuffixInput.idCounter++}`,
      [],
    );
    const id = props.id || generatedId;
    return (
      <Validate {...props} ref={forwardedRef}>
        {(inputProps, errorText, validating) => {
          const disabled = !!inputProps.disabled || validating;
          return (
            <Label htmlFor={id} disabled={disabled} hasError={!!errorText}>
              {labelText ? (
                <span className="label-text">{labelText}</span>
              ) : null}
              <div className="input-wrapper">
                <div className="input-prefix">{prefix}</div>
                <input id={id} disabled={disabled} {...inputProps} />
              </div>
              {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
            </Label>
          );
        }}
      </Validate>
    );
  },
);
SuffixInput.propTypes = {
  id: PropTypes.any,
  labelText: PropTypes.string,
  prefix: PropTypes.any,
};
SuffixInput.defaultProps = {
  autoComplete: 'off',
};
SuffixInput.idCounter = 0;
SuffixInput.displayName = 'SuffixInput';

export default SuffixInput;
