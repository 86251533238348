import React from 'react';
import PropTypes from 'prop-types';
import Questionnaire from '../../shared/Questionnaire';
import AsteriskHelperText from '../../shared/AsteriskHelperText';

const goals = {
  label: 'What can The Mom Project help you achieve?*',
  fieldType: 'checkbox',
  fieldName: 'goals',
  options: {
    employers: 'Engage with employers who "get it"',
    reentry: 'Re-enter the workplace',
    community: 'Join a community',
    flexibility: 'Transition to more flexible work',
  },
};

const current_work_status = {
  label: 'How would you describe your current employment status?*',
  fieldType: 'radio',
  fieldName: 'current_work_status',
  helpText: 'Select one that applies',
  options: {
    fulltime: 'Working for a company full-time',
    parttime: 'Working for a company part-time',
    freelance: 'Freelancing or working on temporary projects',
    unemployed: 'Not currently working',
  },
};

const goals_other_max_length = 40;

class AboutMe extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const props = this.props;
    props.setupZipcodeValidator('zip_code');
    return (
      <div className="container sop-container">
        <div className="row mb-5">
          <div className="col-12">
            <div className="form-group">
              <AsteriskHelperText />
              <div className="font-md font-medium mb-3">
                Where are you located?*
              </div>
              <input
                id="zip_code"
                type="text"
                maxLength={5}
                name="zip_code"
                placeholder="Zip Code..."
                defaultValue={props.state.zip_code}
                className="form-control allownumericwithoutdecimal"
                inputMode="numeric"
                onChange={evt => props.onChange('zip_code', evt.target.value)}
              />
            </div>
          </div>
        </div>
        <Questionnaire
          {...goals}
          selected={props.state.goals}
          onChange={props.onChange}
          cols={2}
          helpText="Select all that apply"
        />
        <div className="row mb-5">
          <div className="form-group col-12">
            <div className="row">
              <div className="form-group col-12">
                <input
                  type="text"
                  name="goals_other"
                  maxLength={goals_other_max_length}
                  defaultValue={props.state.goals_other}
                  placeholder="Other..."
                  className="form-control"
                  onChange={evt =>
                    props.onChange('goals_other', evt.target.value)
                  }
                />
                <small className="float-right pt-3">
                  {goals_other_max_length - props.state.goals_other.length}{' '}
                  Characters Remaining
                </small>
              </div>
            </div>
          </div>
        </div>
        <Questionnaire
          {...current_work_status}
          maxLength={1}
          selected={props.state.current_work_status}
          cols={2}
          onChange={props.onChange}
        />
      </div>
    );
  }
}

AboutMe.propTypes = {
  onChange: PropTypes.func.isRequired,
  setupZipcodeValidator: PropTypes.func,
  state: PropTypes.object,
};

AboutMe.defaultProps = {
  setupZipcodeValidator: window.Utils.setupZipcodeValidator,
};

export default AboutMe;
