import styled, { css } from 'styled-components';

export const Step = styled.li(
  ({ theme, complete = false }) => css`
    color: ${complete ? theme.primaryBlue : theme.mediumGray};
    font-size: 0.625rem;
    line-height: 1.4;
    list-style: none;
    &.is-active {
      color: ${theme.primaryBlue};
      &:before {
        border: 2px solid ${theme.primaryBlue};
      }
    }
    &:before {
      content: ' ';
      border-radius: 0.5rem;
      background-color: ${complete ? theme.primaryBlue : theme.white};
      border: 2px solid ${complete ? theme.primaryBlue : theme.lightGray};
      width: 1rem;
      height: 1rem;
      display: block;
      margin: 0 auto 0.5rem;
      position: relative;
      z-index: 1;
    }
    &:after {
      content: ' ';
      display: 'block';
      width: 100%;
      height: 2px;
      background-color: ${complete ? theme.primaryBlue : theme.lightGray};
      position: absolute;
      top: 0.4rem;
      left: 50%;
      right: -50%;
    }
    &:last-of-type:after {
      display: none;
    }
    @media (min-width: ${theme.screenSizes.tablet}) {
      font-size: ${theme.fontSizes.sm};
    }
  `,
);
