import React from 'react';
import PropTypes from 'prop-types';

const CloudStarFilled = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 15"
      {...props}
    >
      <defs>
        <filter
          id="3oqdonk4za"
          width="277.2%"
          height="293.3%"
          x="-88.6%"
          y="-90%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter
          id="4r9digrcnb"
          width="179.4%"
          height="186.7%"
          x="-39.7%"
          y="-36.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
        </filter>
        <path
          id="nfmsvvymdc"
          d="M16.081 5.49l-5.083-.71c-.108-.015-.2-.08-.248-.173L8.477.177c-.12-.236-.47-.236-.59 0l-2.274 4.43c-.048.093-.14.158-.248.173l-5.083.71c-.27.038-.378.357-.182.54l3.678 3.45c.077.072.113.176.095.28l-.87 4.869c-.045.259.237.456.479.334l4.546-2.3c.096-.047.21-.047.307 0l4.546 2.3c.242.122.524-.075.478-.334l-.868-4.87c-.019-.103.017-.207.094-.28l3.679-3.448c.195-.184.087-.503-.183-.54"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g
                filter="url(#3oqdonk4za)"
                transform="translate(-1327 -607) translate(1050 600) translate(250) translate(26.818 7)"
              >
                <g>
                  <use
                    fill="#000"
                    filter="url(#4r9digrcnb)"
                    xlinkHref="#nfmsvvymdc"
                  />
                  <use fill="#FFF" xlinkHref="#nfmsvvymdc" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CloudStarFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

CloudStarFilled.defaultProps = {
  width: 16,
  height: 15,
};

export default CloudStarFilled;
